import { useRef } from "react";
import Sheet from "react-modal-sheet";
import { Typewriter } from "react-simple-typewriter";

import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import Stories, { WithSeeMore } from "react-insta-stories";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  // Modal,
  Box,
  Avatar,
  ListItemIcon,
  Menu,
} from "@mui/material";
import { ModalStoryBox } from "../ModalStoryBox";
// const { WithSeeMore } from 'react-insta-stories';
import styled from "@emotion/styled";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "src/App";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  capitalizeInfo,
  colors,
  getActionsDB,
  getDataDB,
  getNewsRunnerContent,
  myColors,
  saveActionsDB,
  shuffle,
} from "src/api";
import {
  ArrowBack,
  ArrowCircleLeftSharp,
  AttachMoney,
  Chat,
  Close,
  Edit,
  Expand,
  Favorite,
  Flag,
  Home,
  RemoveRedEye,
  Share,
  ThumbDown,
  ThumbUp,
  VolumeMute,
  VolumeOff,
} from "@mui/icons-material";
import { RR } from "../Events";
import CommentsPlayerCard from "../CommentsPlayerCard";
import { UserStoryComments } from "../UserStoryComments";
import { template } from "lodash";
import { ShareButton } from "./AboutTabListItems";
import moment from "moment";
import { CloseButton } from "react-toastify/dist/components";
import { LotteryInfoComponent } from "../LotteryInfoComponent";
import StoryTemplate from "./StoryTemplate";

export const UserStoriesGeneral = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    host,
    hostTabs,
    eventInfo,
    myColabs,
    allAccess,
    lottery,
    storyResource
  } =
    myContext;
  const { editInfo, tab } = props;

  const resource = useMemo(() => {
    return storyResource?.resource;
  }, [storyResource?.resource]);
  const [openStory, setOpenStory] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const params: any = useParams<any>();

  const [allComments, setAllComments] = useState<any>([]);
  const [allActions, setAllActions] = useState<any>([]);
  const [modalContent, setModalContent] = useState<any>({});
  const [modalVisible, setModalVisible] = useState<any>(false);
  const [isComment, setIsComment] = useState<boolean>(false);

  const [isOpen, setOpen] = useState(false);

  const [dataRecord, setDataRecord] = useState<any>({});
  const [currentStoryIndex, setCurrentStoryIndex] = useState<number>(0);

  const location = useLocation();
  const navigate = useNavigate();

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostId = useMemo(() => {
    return params?.hostId;
  }, [params?.hostId]);

  const item = useMemo(() => {
    if (currentStoryIndex && dataRecord) {
      return dataRecord?.[currentStoryIndex];
    } else {
      return false;
    }
  }, [currentStoryIndex, dataRecord]);

  const saveAction = async (info: any) => {
    if (!myInfo?.handle) {
      alert(`Please sign in to continue`);
    }

    const { key, value } = info;
    const formData = {
      name: myInfo?.name || myInfo?.fullName,
      handle: myInfo?.handle,
      location: myInfo?.location || "usa",
      content_id: item?._id,
      title: item?.title || item?.description,
      [key]: value,
    };

    return await saveActionsDB(formData).then(async (res) => {
      return await getActionsDB({
        handle: myInfo?.handle,
      }).then((res) => {
        console.log("myStories", res);
        setAllActions(res);

        return "done";
      });
    });
  };

  useEffect(() => {
    getActionsDB({
      handle: myInfo?.handle,
    }).then((res) => {
      setAllActions(res);
    });
  }, [myInfo?.handle]);

  const [audioStatus, setAudioStatus] = useState<boolean>(false);
  const [itemInfo, setIemInfo] = useState<any>({});
  const [myFeeds, setMyFeeds] = useState<boolean>(false);

  const [isLottery, setIsLottery] = useState<boolean>(false);

  const menuItem = useMemo(() => {
    const shares = allActions?.filter(
      (res: any) => res.share == "yes" //&& res.urlLink == item?.urlLink,
    );
    const views = allActions?.filter(
      (res: any) => res.view == "yes" //&& res.urlLink == item?.urlLink,
    );
    const likes = allActions?.filter(
      (res: any) => res.like == "yes" //&& res.urlLink == item?.urlLink,
    );
    const comments = allComments;
    // ?.filter(
    //   (res: any) => res.urlLink == item?.urlLink,
    // );

    const myActions: any =
      allActions?.filter(
        (res: any) =>
          res.handle == myInfo?.handle && res?.content_id == item?._id
      )?.[0] || {};
    console.log("xxxxres", myActions?.["like"]);
    const result = [
      {
        icon: audioStatus ? <VolumeOff /> : <VolumeMute />,
        iconStyle: {
          color: comments?.filter(
            (res: any) => res.handle == myInfo?.handle
          )?.[0]
            ? colors.reject
            : "gray",
        },
        label: "",
        title: "Audio",
        onClick: (item: any) => {
          // alert(audioStatus)
          setAudioStatus(!audioStatus);
          // alert(3)
          // setIsComment(true)
        },
      },
      {
        icon: <Chat />,
        iconStyle: {
          color: comments?.filter(
            (res: any) => res.handle == myInfo?.handle
          )?.[0]
            ? colors.reject
            : "gray",
        },
        label: comments?.length > 0 ? comments?.length + "+" : "",
        title: "comments",
        onClick: (item: any) => {
          // alert(audioStatus)
          setIemInfo(item);
          setOpen(true);
          // alert(3)
          // setIsComment(true)
        },
      },
      {
        icon: <RemoveRedEye />,
        iconStyle: {
          color: views?.filter((res: any) => res.handle == myInfo?.handle)?.[0]
            ? colors.reject
            : "gray",
        },
        iconType: "fa",
        label: views?.length > 0 ? views?.length : "",
      },
      {
        icon: (
          <RR
            data={{
              text: "My GuestNet Profile",
              url: `https://www.myguestnet.com${location.pathname}`,
              title: `My GuestNet Profile`,
            }}
          >
            <Share />
          </RR>
        ),
        iconStyle: {
          color: shares?.filter((res: any) => res.handle == myInfo?.handle)?.[0]
            ? colors.reject
            : "gray",
        },
        label: shares?.length > 0 ? shares?.length : "",
        onClick: () => {
          <ShareButton>
            <RR
              data={{
                text: "My GuestNet Profile",
                url: `/story/${eventId}/${eventTab}`,
                title: `My GuestNet Profile`,
              }}
            >
              <Share />
            </RR>
          </ShareButton>;
          saveAction({
            key: "like",
            value: myActions?.["share"] == "yes" ? "no" : "yes",
          });
        },
      },
      {
        icon: (
          <Favorite
            style={{
              color: myActions?.["like"] == "yes" ? colors.reject : "gray",
            }}
          />
        ),
        iconStyle: {
          color: myActions?.["like"] == "yes" ? colors.reject : "gray",
        },
        label: likes?.length > 0 ? likes?.length : "",
        onClick: (item: any) => {
          // alert(audioStatus)
          setIemInfo(item);
          saveAction({
            key: "like",
            value: myActions?.["like"] == "yes" ? "no" : "yes",
          });
        },
      },
      {
        icon: (
          <AttachMoney
            style={{
              color: myActions?.["like"] == "yes" ? colors.reject : "gray",
            }}
          />
        ),
        iconStyle: {
          color: myActions?.["like"] == "yes" ? colors.reject : "gray",
        },
        label: likes?.length > 0 ? likes?.length : "",
        onClick: (item: any) => {
          // alert(audioStatus)
          setIsLottery(true);
        },
      },
      {
        icon: (
          <Edit
            style={{
              color: myActions?.["like"] == "yes" ? colors.reject : "gray",
            }}
          />
        ),
        iconStyle: {
          color: myActions?.["like"] == "yes" ? colors.reject : "gray",
        },
        label: likes?.length > 0 ? likes?.length : "",
        onClick: (item: any) => {
          // const link = (params?.eventId || '') + (params?.eventSubTab || '')
          // navigate(
          //   `/event/${eventId}/${link}`
          // );
          setMyContext((existing: any) => {
            return {
              ...existing,
              storyResource: false,
              editInfo: true,
            };
          });
        },
      },
    ];

    const conttrolBack = myFeeds
      ? [
        {
          icon: <ArrowCircleLeftSharp />,
          iconStyle: {
            color: "gray",
          },
          label: "",
          title: "home",
          onClick: (item: any) => {
            setMyFeeds(false);
            // window.open("https://mywebsite.url", "_blank");
          },
        },
      ]
      : [
        {
          icon: <Home />,
          iconStyle: {
            color: "gray",
          },
          label: "",
          title: "home",
          onClick: (item: any) => {
            // action("pause");
            navigate(`/event/${eventId}/${storyResource?.name}`);
            setMyContext((existing: any) => {
              return {
                ...existing,
                storyResource: false,
                scheduleSelect: {},
              };
            });

            // window.open("https://mywebsite.url", "_blank");
          },
        },
        // {
        //   icon: <Expand />,
        //   iconStyle: {
        //     color: "gray",
        //   },
        //   label: "",
        //   title: "home",
        //   onClick: (item: any) => {
        //     // action("pause");
        //     setMyFeeds(true);
        //     // window.open("https://mywebsite.url", "_blank");
        //   },
        // },
      ];

    return [...conttrolBack, ...result];
  }, [allActions, item?._id, myFeeds, params?.eventId, params?.eventSubTab]);

  function insertArrayAfterEveryNElements(
    arr: any,
    valuesToInsert: any,
    n: any
  ) {
    // function insertElementsOneAtATime(arr, valuesToInsert, n) {
    let resultArray = arr.slice(); // Create a copy of the original array to avoid mutating it
    let insertIndex = n; // Start index for insertion

    // Iterate over valuesToInsert and insert each element one at a time
    for (let i = 0; i < valuesToInsert.length; i++) {
      if (insertIndex > resultArray.length) {
        // If insertIndex is out of bounds, append the remaining values
        resultArray.push(valuesToInsert[i]);
      } else {
        // Insert the value at the calculated index
        resultArray.splice(insertIndex, 0, valuesToInsert[i]);
        // Update the index for the next insertion
        insertIndex += n + 1;
      }
    }
    return resultArray;
  }

  useEffect(() => {
    (async () => {
      // console.log('vvvbnnnn', eventId, hostId, myInfo?.handle)
      if ((eventId || hostId) && myInfo?.handle) {
        // alert(44)
        const data = {
          query: eventId ? { eventId: eventId } : { handle: hostId },
          resource: resource,
          check: ["handle", `${eventId ? "eventId" : "hostId"}`],
        };

        const subData: any = [];
        if (eventTab == "userStories") {
          await getDataDB(data)?.then((reg: any) => {
            // console.log('vvvvmmmmm', reg)
            if (reg?.[0]) {
              const ref = reg?.map((info: any) => {
                info?.stories?.map((res: any) => {
                  // console.log('vvvvmmmmm', res)
                  subData?.push(res);
                });
              });
              return ref;
            }
          });
        } else if (eventTab == "userjourney") {
          // console.log('vvvvmmmmm', reg)
          await getDataDB(data)?.then((reg: any) => {
            // console.log('vvvvmmmmm', reg)
            if (reg?.[0]) {
              const ref = reg?.map((info: any) => {
                info?.journeys?.map((journey: any) => {
                  journey?.steps?.map((res: any) => {
                    console.log("journeys", res);
                    subData?.push(res);
                  });
                });
              });
              return ref;
            }
          });
        } else if (storyResource?.subTab) {
          (await storyResource?.subTab) &&
            Promise.all(
              storyResource?.subTab?.map(async (ref: any) => {
                const data = {
                  query: eventId ? { eventId: eventId } : { handle: hostId },
                  resource: ref?.resource,
                  check: ["handle", `${eventId ? "eventId" : "hostId"}`],
                };
                return await getDataDB(data)?.then((reg) => {
                  // console.log('myNewsssssubData-reg', ref?.resource, reg)
                  return reg?.map((res: any) => {
                    return subData?.push(res);
                  });
                });
              })
            );
        }

        // console.log('myNewsssssubData', subData)

        const myNews = await (storyResource?.myNews
          ? storyResource?.myNews
          : subData?.[0]
            ? subData
            : getDataDB(data))

        const data2 = {
          query: {
            dddd: null,
          },
          resource: "calendar_event_advert",
          limit: 50,
        };
        const news =
          myNews?.map((res: any) => {
            return {
              // _id: result?._id,
              ...res,
            };
          }) || [];

        const ads =
          (await getDataDB(data2)?.then((ref) => {
            return ref?.map((reg: any) => {
              return {
                ...reg,
                sponsored: true,
              };
            });
          })) || [];

        const rep = insertArrayAfterEveryNElements(news || [], ads || [], 3);
        // console.log('myNewssss', rep)

        const rresult = {
          title: "main story",
          img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          stories: rep,
        };

        // console.log("hkjhkjhkjh", rresult);

        if (rresult) {
          const report: any = await rresult?.stories?.map((res: any) => {
            const myVideo =
              res?.video?.includes("undefined") || !res?.video?.includes("mp4")
                ? false
                : res?.video;
            const duration: any =
              myVideo &&
              getVideoDuration(myVideo, (error: any, durationInfo: any) => {
                if (error) {
                  console.error(`${myVideo} -- ${error.message}`);
                  return false;
                } else {
                  console.log(
                    `Duration of the video is ${myVideo} ${durationInfo} seconds.`
                  );
                  return durationInfo;
                }
              });
            return {
              ...res,
              myVideo: myVideo,
              duration: duration || 5000,
            };
          });
          setDataRecord(report);
        } else {
          setOpenStory(true);
          handleNoData("");
        }
        // });
      }
    })();
  }, [
    storyResource?.myNews,
    isComment,
    eventId,
    hostId,
    myInfo?.handle,
    resource,
  ]);

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  function getVideoDuration(videoUrl: any, callback: any) {
    // const {
    //   videoUrl, callback
    // } = props
    // Create a video element
    const video = document.createElement("video");

    // Set the video source
    video.src = videoUrl;

    // Set preload metadata to optimize loading
    video.preload = "metadata";

    // Load the video's metadata
    video.addEventListener(
      "loadedmetadata",
      () => {
        // Retrieve the duration in seconds
        const duration = video.duration;
        // Execute the callback function with the duration
        callback(null, duration);
      },
      { once: true }
    );

    // Handle errors in loading the video
    video.addEventListener(
      "error",
      (e) => {
        console.error("Error loading video:", e);
        callback(new Error("Failed to load video or retrieve duration"), null);
      },
      { once: true }
    );

    video.addEventListener("play", function () {
      video.muted = !video.muted;
    });

    // video.addEventListener('click', function () {
    //   video.muted = !video.muted;
    // });
  }

  const newsRunners = useRef<any>();

  const handleNoData = (temData: any) => {

    const dataRecordInfo = temData;

    if (!dataRecordInfo?.[0]) {
      return;
    }

    // console.log("durationbb", temData);

    const temp: any = [];
    dataRecordInfo?.[0] && dataRecordInfo?.map((res: any) => {
      // console.log("durationbb", res);
      temp.push({
        url: res?.img || res?.picture,
        duration: res?.duration || 5000,
        content: ({ action, isPaused }: any) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  top: 200,
                  right: 20,
                  zIndex: 9999,
                  position: "absolute",
                  flexDirection: "column",
                  color: "black",
                  gap: 20,
                  justifyContent: "center",
                  height: window?.innerHeight * 0.7,
                  overflowY: "auto",
                  alignItems: "center",
                  // backgroundColor: 'red'
                }}
              >
                {menuItem?.map((res: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        margin: 5,
                        borderRadius: 100,
                        height: 40,
                        width: 40,
                      }}
                      onClick={(e) => {
                        action(isPaused ? "play" : "pause");
                        res?.onClick(e);
                        // if (res?.title == 'Audio') {
                        //   alert(audioStatus)
                        //   setAudioStatus(!audioStatus)
                        // }
                      }}
                    >
                      {res?.icon}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  padding: 0,
                  marginTop: 60,
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <div
                  style={{
                    // backgroundColor: 'yellow',
                    marginTop: 140,
                    // margin: 0,
                    fontSize: "1.3em",
                    fontWeight: "bold",
                    // maxWidth: '80%'
                  }}
                >
                  {capitalizeInfo(`${res?.title || ""}`)}
                </div>
                <div
                  style={{
                    marginTop: 5,
                    display: "flex",
                    width: '70%',
                    justifyContent: "center",
                    // backgroundColor: 'orange',

                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "75%",
                      color: "gray",
                      justifyContent: "center",
                      textWrap: "wrap",
                      // padding: 20,
                    }}
                  >
                    <TypewriterComponent
                      textArray={[
                        // res?.title,
                        res?.description,
                      ]}
                    />
                    {/* <p>{res?.description || ""}</p> */}
                  </div>
                </div>
                <video
                  autoPlay
                  controls={res?.myVideo ? true : false}
                  // loop
                  // muted
                  poster={
                    res.img ||
                    res?.picture ||
                    "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                  }
                  style={{
                    // zIndex: 99,
                    maxHeight: 300,
                    padding: 4,
                    justifyContent: "center",
                    width: "100%",
                    background: "#00000084",
                    height: "auto",
                    objectFit: "contain",
                  }}
                >
                  <source src={res?.myVideo} type="video/mp4" />
                  <source src={res?.myVideo} type="video/ogg" />
                </video>

                {/* <div
                  style={{
                    display: "flex",
                    bottom: 5,
                    // right: 20,
                    zIndex: 1000,
                    position: "relative",
                    flexDirection: "row",
                    color: "#00000084",
                    // gap: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></div> */}
              </div >

              {!isPaused && isComment && (
                <div
                  style={{
                    display: "flex",
                    zIndex: 1001,
                    position: "absolute",
                    color: "white",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <UserStoryComments />
                </div>
              )
              }
            </>
          );
        },
      });
      setFormInput(temp);
    });
  };


  useEffect(() => {
    if (dataRecord?.[0]) {
      setOpenStory(true);
      handleNoData(dataRecord);
    }
  }, [dataRecord, menuItem]);

  const [isPaused, setIsPaused] = useState<any>(false);
  const intervalRef = useRef<any>(null);

  const handleStoryStart = (index: any) => {
    setCurrentStoryIndex(index);
    if (dataRecord[index]?.myVideo) {
      setIsPaused(true);
    } else {
      setIsPaused(false);
    }
  };

  useEffect(() => {
    if (isPaused) {
      clearInterval(intervalRef.current);
    } else {
      intervalRef.current = setInterval(() => {
        handleStoryEnd(currentStoryIndex);
      }, dataRecord[currentStoryIndex]?.duration || 5000);
    }
    return () => clearInterval(intervalRef.current);
  }, [isPaused, currentStoryIndex]);

  const handleStoryEnd = (index: any) => {
    if (index < dataRecord.length - 1) {
      setCurrentStoryIndex(index + 1);
    }
  };

  const handleAllStoriesEnd = () => {
    console.log("All stories have ended");
  };

  // console.log(`Story ss`, currentStoryIndex, dataRecord);

  return (
    <div style={{
      backgroundColor: "red",
      position: "fixed",
      inset: 0,
      zIndex: 9999,
      background: "rgba(0,0,0,0.2)",
      display: "flex",
      justifyContent: "center",
      minHeight: "auto",
      overflowY: "auto",
    }}>

      <ModalStoryBox
        style={{
          backgroundColor: "red",
          position: "fixed",
          inset: 0,
          zIndex: 9999,
          background: "rgba(0,0,0,0.2)",
          display: "flex",
          justifyContent: "center",
          minHeight: "auto",
          overflowY: "auto",
        }}
        openModal={openStory}
        fullScreen={true}
        onClose={() => {
          setOpenStory(false);
        }}
        storyResource={storyResource}
        setOpenStory={setOpenStory}
        handleClose={() => { }}
        headerText={""}
        currentStoryIndex={currentStoryIndex}
        item={dataRecord?.[currentStoryIndex]}
      ><>
          {myFeeds ? (
            <MyFeedComponent
              itemInfo={itemInfo}
              menuItem={menuItem}
              audioStatus={audioStatus}
              setMyFeeds={setMyFeeds}
              {...props}
            />
          ) : (
            formInput?.[0] && (
              <Stories
                currentIndex={currentStoryIndex}
                onStoryStart={handleStoryStart}
                onStoryEnd={handleStoryEnd}
                onAllStoriesEnd={handleAllStoriesEnd}
                stories={formInput}
                defaultInterval={5000}
                width={"100%"}
                height={"850px"}
              />
            )
          )}

          <Sheet isOpen={isLottery} onClose={() => setIsLottery(false)}>
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                  onClick={() => {
                    setIsLottery(false);
                  }}
                >
                  <Close
                    style={{
                      marginRight: 20,
                    }}
                  />
                </div>
                <LotteryInfoComponent {...props} />
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>
          <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Close
                    style={{
                      marginRight: 20,
                    }}
                  />
                </div>
                <CommentsPlayerCard {...props} editInfo={true} />
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>
        </>
      </ModalStoryBox>
    </div>
  );
};

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const StoriesWrapper = styled.div`
  img {
    width: 100%;
    height: 786px;
    object-fit: cover;
  }
`;

const ModalContent = styled(Box)({
  width: "80%",
  maxWidth: "600px",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
});

const customCollapsedComponent = ({ toggleMore, action }: any) => (
  <h2
    onClick={() => {
      action("pause");
      window.open("https://mywebsite.url", "_blank");
    }}
  >
    Go to Website
  </h2>
);

const MyFeedComponent = (props: any) => {
  const { itemInfo, menuItem, audioStatus, setMyFeeds } = props;

  const [newsRunners, setNewsRunners] = useState<any>([]);
  const [isControls, setIsControls] = useState<boolean>(false);

  useEffect(() => {
    getNewsRunnerContent({
      replysss: null,
    })?.then((res) => {
      console.log("newsRunners", res);
      setNewsRunners(res);
    });
  }, []);

  useEffect(() => {
    setIsControls(audioStatus);
  }, [audioStatus]);

  return (
    <div
      style={{
        background: "red",
        padding: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          marginTop: 80,
          display: "flex",
          flexDirection: "column",
          height: window.innerHeight * 0.9,
          overflowY: "auto",
          // backgroundColor: 'white',
          zIndex: 9,
          position: "relative",

          // marginTop: 30
        }}
      >
        {newsRunners?.map((res: any) => {
          return (
            <div
              className="items-center"
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
                position: "relative",
                justifyContent: "center",
                backgroundColor: "black",
                borderRadius: 20,
                padding: 10,
                margin: 10,
                zIndex: 2,
              }}
            >
              <ArrowCircleLeftSharp
                style={{
                  background: "white",
                  borderRadius: 100,
                }}
                onClick={() => {
                  setMyFeeds(false);
                }}
              />

              <video
                autoPlay={isControls && itemInfo?._id == res?._id}
                controls={isControls && itemInfo?._id == res?._id}
                // loop
                // muted
                src={res?.video || ""}
                poster={
                  res.img ||
                  res?.picture ||
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                }
                style={{
                  maxHeight: 300,
                  padding: 4,
                  justifyContent: "center",
                  width: "100%",
                  borderRadius: 30,
                  // background: "pink",
                  height: "auto",
                  objectFit: "contain",
                }}
              // src={item?.picture || item?.img || "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"}
              // className="w-9 h-9 rounded-full"
              />
              {!(isControls && itemInfo?._id == res?._id) && (
                <>
                  <div
                    className="ml-3"
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      position: "absolute",
                      bottom: 20,
                      zIndex: 4,
                      borderRadius: 20,
                      backgroundColor: "rgba(0,0,0,0.7)",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginBottom: 20,
                      padding: 15,
                      // width: '1005'
                    }}
                  >
                    <div
                      style={{
                        fontSize: "0.7em",
                        color: "white",
                      }}
                    >
                      {capitalizeInfo(
                        `${res?.author || res?.name || res?.author
                        }`
                      )}
                    </div>
                    <div
                      style={{
                        fontSize: "0.5em",
                        color: "white",
                      }}
                    >
                      {capitalizeInfo(`${res?.story || res?.description}`)}
                    </div>
                    <div className="font-archivo text-[14px] text-white/30 dark:text-white/30">
                      {moment(res?.created)?.fromNow()}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        // width: '100%'
                      }}
                    >
                      {menuItem?.map((info: any) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "white",
                              margin: 5,
                              borderRadius: 100,
                              height: 40,
                              width: 40,
                            }}
                            onClick={() => {
                              // action(isPaused ? "play" : "pause");
                              info?.onClick(res);
                              // if (res?.title == 'Audio') {
                              //   alert(audioStatus)
                              //   setAudioStatus(!audioStatus)
                              // }
                            }}
                          >
                            {info?.icon}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              <div
                style={{
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    marginLeft: 20,
                    backgroundColor: "rgba(0,0,0,0.7)",
                    borderRadius: 10,
                    padding: 5,
                    color: "white",
                    fontSize: "0.7em",
                  }}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.vote.keyVoteApp&hl=en_US",
                      "_blank"
                    );
                  }}
                >
                  <Home
                    style={{
                      color: "white",
                      fontSize: "1.5em",
                    }}
                  />
                  Android
                </div>

                <div
                  style={{
                    marginLeft: 20,
                    backgroundColor: "rgba(0,0,0,0.7)",
                    borderRadius: 10,
                    padding: 5,
                    color: "white",
                    fontSize: "0.7em",
                  }}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/vare/id1503031565?platform=iphone",
                      "_blank"
                    );
                  }}
                >
                  <Home
                    style={{
                      color: "white",
                      fontSize: "1.5em",
                    }}
                  />
                  IOS
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CustomStoryContent = ({ story, action }: any) => {
  return (
    <WithSeeMore
      story={story}
      action={action}
      customCollapsed={customCollapsedComponent}
    >
      <div>
        <h1>Hello!</h1>
        <p>
          This story would have a 'See More' link at the bottom and will open a
          URL in a new tab.
        </p>
      </div>
    </WithSeeMore>
  );
};

const TypewriterComponent = (props: any) => {
  const { textArray = ["Hello World!", "Welcome to React!", "Enjoy coding!"] } =
    props;
  return (
    <div style={{ fontSize: "24px", fontWeight: "bold" }}>
      <Typewriter
        words={textArray}
        loop={1}
        cursor
        cursorStyle="_"
        typeSpeed={70}
        deleteSpeed={50}
        delaySpeed={1000}
      />
    </div>
  );
};

export default TypewriterComponent;
