import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Breadcrumb, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";

import { gapi } from "gapi-script";
import moment from "moment";
import Calendar from "react-calendar";
import {
  getCalendarEvents,
  getCalendarUsers,
  getCalendarSchedule,
  saveAppointment,
  myColors,
} from "../api";
// import { Navbar } from "react-bootstrap";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import AdvancedForms from "./AdvancedForms/AdvancedForms";

import {
  BrowserRouter,
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Menubar from "./Menubar";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  Checkbox,
} from "@mui/material";
import Icon from "react-multi-date-picker/components/icon";
import { EventForm } from "./EventForm";
import { UserContext } from "src/App";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const colors = {
  lightBlue: "#c1d8ff",
};

export const SearchEventForm = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    eventId,
    allMyEvents,
    // host = "",
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    searchBy = "Event",
    // comments = [],
  } = myContext;
  const {
    host,
    setOpenEventForm,
    // setAllGuest,
    // setData,
    // data = {},
    // setGuest,
    // setHost,
    // setEventId,
    // setMyEvents,
    // setMyEventSchedule,
    // setAllMyEvents,
    // setMySchedule,
    formInput = {},
    setFormInput,
  } = props;

  const [noResult, setNoResult] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const refInfoValue = useRef<any>(null);
  // const [editMode, setEditMode] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<any>(false);
  const [showSearchMenu, setShowSearchMenu] = useState<boolean>(false);

  const [showHostMenu, setShowHostMenu] = useState<boolean>(false);

  // const [searchBy, setSearchBy] = useState<any>("Event");

  useEffect(() => {
    if (host?.handle && !eventId) {
      // alert(host?.handle + eventId)

      getCalendarEvents({ handle: host?.handle }).then((res) => {
        setMyContext((existing: any) => {
          return {
            ...existing,
            myEvents: res || [],
            allMyEvents: res || [],
            myEventSchedule: res || [],
          };
        });

        // return 'done'
      });
    } else {
      getCalendarEvents({ handlssse: null }).then((res) => {
        setMyContext((existing: any) => {
          return {
            ...existing,
            myEvents: res || [],
            allMyEvents: res || [],
            myEventSchedule: res || [],
          };
        });

        // return 'done'
      });
    }
  }, [eventId, host?.handle]);

  //when field is cleared
  const getCalendatData = () => {
    if (host?.handle)
      getCalendarUsers({ handlssse: null }).then((res) => {
        // setAllGuest(res)
        setMyContext((existing: any) => {
          return {
            ...existing,
            allGuest: res || [],
          };
        });
      });

    getCalendarEvents({ handlssse: null }).then((res) => {
      setMyContext((existing: any) => {
        return {
          ...existing,
          myEvents: res || [],
          allMyEvents: res || [],
          myEventSchedule: res || [],
        };
      });
    });
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: "2px solid red",
      }}
    >
      <nav
        className="navbar navbar-expand-lg navbar-light "
        style={{
          width: 350,
          marginBottom: 20,
        }}
      >
        <div style={{ backgroundColor: "white" }}>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <div
                className={`nav-link ${showMenu && "show"}`}
                // onClick={() => {
                //   setShowMenu(!showMenu)
                // }}
                // href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid gray",
                  borderRadius: 20,
                  margin: 10,
                  width: 300,
                  alignSelf: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  gap: "10px",
                  padding: "5px 10px 5px 15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "fit-content",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setShowMenu(showMenu == "search" ? "" : "search");
                  }}
                >
                  <SearchIcon />
                  <input
                    // className="form-control"
                    type="text"
                    // value={data?.title}
                    onChange={(e: any) =>
                      setFormInput({
                        ...formInput,
                        title: e.target?.value,
                      })
                    }
                    style={{
                      border: "none",
                      outlineWidth: 0,
                      backgroundColor: "transparent",
                    }}
                    placeholder={formInput?.title || "Search Event"}
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "fit-content",
                    alignItems: "center",
                    gap: 10,
                    // justifyContent: "center",
                  }}
                  onClick={() => {
                    setShowMenu(showMenu == "search" ? "" : "search");
                  }}
                >
                  {/* <span>Search By:</span> */}

                  <div
                    style={{
                      color: myColors.brown,
                    }}
                  >
                    {"Event"}
                  </div>

                  <KeyboardArrowDownIcon />
                </div>
              </div>

              <ul
                style={{
                  position: "sticky",
                }}
                className={`dropdown-menu ${showMenu == "search" && "show"}`}
                aria-labelledby="navbarDropdownMenuLink"
              >
                {!formInput?.title ? (
                  <>
                    <li key={"hhgsssghh"}>
                      <div
                        className="dropdown-item"
                        style={{
                          color: "red",
                        }}
                        onClick={() => {
                          // setOpenEventForm(true)
                          setShowMenu(false);
                          setOpenEventForm(false);
                          setMyContext((existing: any) => {
                            return {
                              ...existing,
                              host: {},
                              eventId: "",
                            };
                          });
                          setShowMenu(false);
                          setOpenEventForm(false);
                        }}
                      >
                        {"Close"}
                      </div>
                    </li>
                    <li
                      onClick={() => {
                        setMyContext((existing: any) => {
                          return {
                            ...existing,
                            host: {},
                            eventId: "",
                            allMyEvents: [],
                            openCreateEvent: true,
                          };
                        });
                        setShowMenu(false);
                        setOpenEventForm(false);
                      }}
                      key={"hhgsssghh"}
                    >
                      <div
                        className="dropdown-item"
                        style={{
                          color: myColors?.red,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div> {"Create Event / Project"}</div>{" "}
                        <i
                          className="fa fa-plus"
                          style={{
                            display: "flex",
                            padding: 5,
                            fontSize: 12,
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            paddingRight: 5,
                          }}
                        />
                      </div>
                    </li>

                    {myEvents?.map((option: any, index: number) => {
                      return (
                        <li key={"hhgsssghmkkkvvvh" + option?._id + index}>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              // setOpenEventForm(true)
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  eventId: option?._id,
                                };
                              });
                              setShowMenu(false);
                              setOpenEventForm(false);
                            }}
                          >
                            {option?.title}
                          </div>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <div>
                    {myEvents?.map((option: any, index: number) => {
                      return (
                        <li key={"hhgsssghh" + option?._id + index}>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              // setOpenEventForm(true)
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  eventId: option?._id,
                                };
                              });
                              setShowMenu(false);
                              setOpenEventForm(false);
                            }}
                          >
                            {option?.title}
                          </div>
                        </li>
                      );
                    })}
                  </div>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

const Modal = (props: any) => {
  const {
    openModal = true,
    setOpenModal,
    formInputs = {},
    children,
    headerText = "Add New ???",
  } = props;
  return (
    <Dialog
      maxWidth="xs"
      open={openModal || false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="form-dialog-title"
        style={{
          backgroundColor: "#00384e",
          color: "white",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          className="dialog-title"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "self-start",
          }}
        >
          <div id="">{headerText}</div>
          <Icon
            name={"times"}
            style={{ color: "white" }}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
