import { useDrag } from 'react-dnd';
import { styled } from './stitches.config'
import { RWebShare } from 'react-web-share';
import { deleteFollowupDB, myColors, saveFollowupDB } from 'src/api';
import { useContext, useState } from 'react';
import { UserContext } from 'src/App';

type CardProps = {
  item: any
  // {
  //   id: number;
  //   name: string;
  //   title: string;
  //   row: number;
  //   img: string;
  // };
};

export const Card = (props: any) => {
  const { item, column, setEditItem, editItem } = props;
  const { myContext, setMyContext } = useContext<any>(UserContext);

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const {
    myInfo,
    myInfo: { active = "2" },
    host,
    // people = data,
    allMyEvents,
    eventId,
    eventDetails = {},
    indexStop = false,
    contacts,
    followups,
    likedme,
    myLeads,
    allSelected = []
    // people = data || [],
    // likes = []
    // attendees
    // comments = [],
  } = myContext;

  const [, drag] = useDrag(() => ({
    type: 'card',
    item: item,
    end(item, monitor) {
      // console.log('Dropped!', item);
      if (item?.column != column) {
        delete item?._id
        saveFollowupDB({
          ...item,
          column: item?.column,
          handle: myInfo?.handle,
        }).then((ref: any) => {
          alert('saved!')
          setEditItem({})
        })
      }
    },
  }));
  return <StyledCard ref={drag} >
    <div style={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    }}>
      <div style={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        width: '100%',

      }}>
        <img src={item?.img || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'}
          style={{
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: 100,
            marginRight: 5
          }}
        />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start'
        }}>
          <div className='title' style={{
            // marginBottom:,
            fontSize: 12
          }}>{item.item}</div>
          <div className='title'
            style={{
              marginTop: -10,
              fontSize: 12
            }}
          > {item.name}
          </div>

        </div>

        <i
          onClick={() => {
            setOpenMenu(!openMenu)
          }}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            // paddingRight: 5,
            color: myColors.gray

          }}
          className={`fas fa-${openMenu ? 'times' : 'ellipsis-v'}`} ></i>
        {item?.mutualInterest && <i
          onClick={() => {
            alert('Guest is following you.')
          }}
          className="fa fa-heart"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            fontSize: 12,
            justifyContent: 'center',
            alignItems: 'center',
            // paddingRight: 5,
            color: 'red'
          }} />}
      </div>

      <div //className='title'
        style={{
          display: 'flex',
          marginTop: -10,
          paddingBottom: 10,
          textAlign: 'start',
          fontSize: 12,
          width: '100%'
        }}
      > {item.title}
      </div>

      {openMenu && <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'scroll',
        width: 400,
        top: 70,
        padding: 20,
        borderRadius: 50,
        position: 'absolute',
        backgroundColor: myColors.gray
      }}>
        <div style={{
          // margin: 10,
          // marginLeft: -40,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: 300,
          alignItems: 'center'
        }}>
          <div onClick={() => {
            // setReview('business')
            setEditItem(editItem?.name ? {} : { ...item, column })
            setOpenMenu(false)
          }}><i
              className="fa fa-edit"
              style={{
                display: 'flex',
                fontSize: 18,
                backgroundColor: myColors.brown,
                padding: 10,
                borderRadius: 100,
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                // paddingRight: 5,
                // color: 'black'
              }} />
            <div style={{
              color: 'white',
              fontSize: 9
            }}>Edit</div>
          </div>
          <div onClick={() => {
            alert('Action not allowed at this time, please try again later')
          }}>
            <i
              className="fa fa-comments"
              style={{
                display: 'flex',
                fontSize: 18,
                backgroundColor: myColors.brown,
                padding: 10,
                borderRadius: 100,
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
              }} />
            <div style={{
              color: 'white',
              fontSize: 9
            }}>
              Chat
            </div>
          </div>
          {item?.email && <div onClick={() => {
            alert('Action not allowed at this time, please try again later')
          }}>
            <i
              className="fa fa-envelope"
              style={{
                display: 'flex',
                fontSize: 18,
                backgroundColor: myColors.brown,
                padding: 10,
                borderRadius: 100,
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
              }} />
            <div style={{
              color: 'white',
              fontSize: 9
            }}>
              Email
            </div>
          </div>}
          {item?.phone && <div onClick={() => {
            alert('Action not allowed at this time, please try again later')
          }}>
            <i

              className="fa fa-phone"
              style={{
                display: 'flex',
                fontSize: 18,
                backgroundColor: myColors.brown,
                padding: 10,
                borderRadius: 100,
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',

              }} />
            <div style={{
              fontSize: 9,
              color: 'white'

            }}>Call</div>
          </div>}
          <div >
            <RWebShare
              data={{
                text: item?.name,
                url: `https://www.myguestnet.com/host/${item?.handle}`,
                title: `${item?.name}: ${item?.title}`
              }}

            >
              <i
                className="fa fa-share"
                style={{
                  display: 'flex',
                  fontSize: 18,
                  backgroundColor: myColors.brown,
                  padding: 10,
                  borderRadius: 100,
                  color: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                }} />
            </RWebShare>
            <div style={{
              fontSize: 9,
              color: 'white'

            }}>Share</div>
          </div>
          {item?.column != null && <div onClick={() => {
            let text = "Sure you want to delete?";
            if (window.confirm(text) == true) {
              deleteFollowupDB({
                id: item?._id
              }).then((ref: any) => {
                alert('Deleted!')
              })
              text = "You pressed OK!";
            } else {
              text = "You canceled!";
            }

          }}>
            <i
              className="fa fa-trash"
              style={{
                display: 'flex',
                fontSize: 18,
                backgroundColor: myColors.brown,
                padding: 10,
                borderRadius: 100,
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',

              }} />
            <div style={{
              fontSize: 9,
              color: 'white'

            }}>Delete</div>
          </div>}
        </div>
      </div>}
    </div>

  </StyledCard >;
};


export const StyledCard = styled('div', {
  display: 'flex',
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  backgroundColor: 'white',
  cursor: 'move',
  margin: '0.1rem',
});