

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, CardContent, Typography, TextField, Button, Rating } from '@mui/material';
import {
  deleteDataDB,
  jumpToReleventDiv,
  saveDataDB,
  myColors,
  getDataDB
} from "src/api";
import { UserContext } from "src/App";
import { useParams } from "react-router-dom";
import { ButtonL } from "./AppIndex";

export const ReviewComponent = (props: any) => {
  const {
    children,
    users,
    eventId,
    eventLink,
    title = '',
    resource = 'calendar_event_reviews',
    userInfo,
    skill
  } = props
  const { myContext, setMyContext } = useContext<any>(UserContext);

  const {
    myInfo,
    eventInfo,
    hostTabs
  } = myContext;
  const [formInput, setFormInput] = useState<any>({});
  const [submitted, setSubmitted] = useState(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [dataRecord, setDataRecord] = useState<any>([]);

  const params: any = useParams<any>();

  useEffect(() => {


    const data = {
      query: {
        eventId: eventId,
      },
      resource: resource,
      check: ["eventId"],
    };
    getDataDB(data).then((res) => {
      console.log('result-data', res)

      setDataRecord(res?.[0] ? res : []);
      setRefreshing(false);
    });

  }, [
    refreshing,
    eventId,
  ]);
  console.log('resultssss', userInfo)

  useEffect(() => {
    if (userInfo?.handle) {
      setFormInput({
        ...userInfo
      })
      jumpToReleventDiv('review-id')
      return
    }
    const result = dataRecord?.filter((res: any) => res.handle == myInfo?.handle)?.[0]
    if (result?.handle) {
      console.log('result', result)
      setFormInput({
        ...result
      })
    }
  }, [
    userInfo,
    dataRecord,
    myInfo?.handle
  ]
  )

  return (
    <Card
      id={'review-id'}
      style={{
        display: 'flex',
        maxWidth: '100%',
        margin: '20px auto',
        padding: '20px',
        borderRadius: '10px',
        justifyContent: 'center',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
      }}>
      <CardContent style={{
        width: '100%'
      }}>
        <Typography variant="h5" component="div" style={{ textAlign: 'center', marginBottom: '20px' }}>
          Give Your Feedback
        </Typography>

        {submitted ? (
          <Typography variant="body1" style={{ textAlign: 'center', color: 'green' }}>
            Thank you for your feedback!
          </Typography>
        ) : (
          <>
            <Typography component="legend" style={{ textAlign: 'center', marginBottom: '10px' }}>
              Rate By: {myInfo?.name}
            </Typography>
            <Rating
              name="feedback-rating"
              value={formInput?.rating * 1}
              onChange={(e: any) => {
                setFormInput({
                  ...formInput,
                  rating: e?.target?.value,
                })
              }}
              size="large"
              style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
            />
            <textarea
              rows={5}
              className="form-control"
              onChange={(e: any) => {
                const txt = e?.target?.value
                console.log('txt', txt)
                setFormInput({
                  ...formInput,
                  message: txt
                })
              }}
              style={{}}
              value={formInput?.message || ""}
              placeholder="Add feedback..."
            />

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: 20
            }}>
              {formInput?._id
                && <ButtonL style={{
                  margin: 0,
                  backgroundColor: 'orange'
                }} onClick={() => {
                  if (!myInfo?.handle) {
                    alert('Please login to continue')
                    return
                  }
                  // console.log('formDatacc', data)
                  alert(formInput?._id)
                  if (formInput?._id)
                    deleteDataDB({
                      id: formInput?._id,
                      resource: resource
                    }).then(res => {
                      alert('Review Deleted')
                    })

                }}>
                  Delete
                </ButtonL>}
              <Button
                style={{
                  marginTop: 10,
                  backgroundColor: formInput?.message?.length > 3 ? myColors.brown : 'lightgray',
                  color: formInput?.message?.length > 3 ? 'white' : 'gray'
                }}
                variant="contained"
                // color={formInput?.message?.length > 3 ? myColors.brown : "lightgray"}
                fullWidth
                onClick={() => {
                  if (!myInfo?.handle) {
                    alert('Please login to continue')
                    return
                  }
                  if (!formInput?.rating || !formInput?.message) {
                    alert('Rating and Feedback required')
                    return
                  }
                  if (formInput?._id) delete formInput?._id

                  const newFormInput: any = {
                    img: myInfo?.img,
                    name: myInfo?.name,
                    handle: myInfo?.handle,
                    ...formInput
                  };
                  const data = {
                    query: {
                      ...newFormInput,
                      eventId: eventId,
                      handle: myInfo?.handle
                    },
                    resource: resource,
                    check: ["handle", "eventId"],
                    myInfo,
                  };
                  // console.log('formDatacc', data)
                  saveDataDB(data).then((res) => {
                    // setRefreshing(true);
                    alert('Feedback sent')
                  });
                }}
                disabled={!formInput?.rating || !formInput?.message}
              >
                Submit Feedback
              </Button>
            </div>
          </>
        )}
      </CardContent>
    </Card >

  );
};

