import React, { useState } from "react";
import styled from "@emotion/styled";
import { ButtonL } from "./AppIndex";
import { ContentCopy } from "@mui/icons-material";

export const ShareWrapper = ({ children, users, eventLink }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [invitees, setInvitees] = useState<any[]>([]);

  const toggleModal = () => setIsOpen(!isOpen);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleAddInvitee = () => {
    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      const user = users?.find((user: any) => user.email === email) ?? email;
      const profileImg = user?.profileImg || "";
      setInvitees([...invitees, { email, profileImg }]);
      setEmail("");
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(eventLink).then(() => {
      alert("Link copied to clipboard!");
    });
  };

  const handleShare = () => {
    console.log(
      "Invited Emails:",
      invitees.map((invitee) => invitee.email)
    );
  };

  return (
    <>
      <div
        style={{ width: "fit-content", height: "fit-content" }}
        onClick={toggleModal}
      >
        {children}
      </div>
      {isOpen && (
        <>
          <Backdrop onClick={toggleModal} />
          <Modal>
            <ModalHead>
              <h2>Share this event</h2>
              <CopyLink onClick={copyToClipboard}>
                <ContentCopy />
                Copy link
              </CopyLink>
            </ModalHead>

            <div>
              <Input
                type="text"
                placeholder="Email, comma separated"
                value={email}
                onChange={handleEmailChange}
              />
              <ButtonL style={{ margin: 0 }} onClick={handleAddInvitee}>
                Invite
              </ButtonL>
            </div>
            {invitees.length > 0 ? (
              <InviteesList>
                {invitees.map((invitee, index) => (
                  <InviteeItem key={index}>
                    {invitee.profileImg ? (
                      <ProfileImage src={invitee.profileImg} alt="Profile" />
                    ) : (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "lightgray",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {invitee.email[0].toUpperCase()}
                      </div>
                    )}
                    <EmailText>{invitee.email}</EmailText>
                  </InviteeItem>
                ))}
              </InviteesList>
            ) : (
              <InviteesList>
                <span>Add Invitees for you event</span>
              </InviteesList>
            )}
            <ButtonWrapper>
              <ButtonL style={{ margin: 0 }} onClick={handleShare}>
                Share
              </ButtonL>
            </ButtonWrapper>
          </Modal>
        </>
      )}
    </>
  );
};
const Modal = styled.dialog`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1000;

  div {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
  }
`;

const ModalHead = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid gray;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
`;
const CopyLink = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  color: blue;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  margin: 0;
  svg {
    width: 15px;
    height: 15px;
    object-fit: contain;
    color: blue;
  }
`;
const Backdrop = styled.article`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const InviteesList = styled.div`
  margin-top: 20px;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const InviteeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

const EmailText = styled.span`
  font-size: 16px;
`;

// const CopyLinkButton = styled(FaRegCopy)`
//   cursor: pointer;
//   margin-left: 10px;
// `;
