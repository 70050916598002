import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";
import Chart from "react-apexcharts";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  groupByNow,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  DeleteOutline,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { AdvertListItems } from "./AdvertListItems";
import { MyTabListItems } from "./MyTabListItems";
import { AboutTabDetails } from "./AboutTabDetails";
import { AddNewContent } from "./CatalogListings";
import {
  DiscreteSliderMarks,
  DiscreteSliderMarksProps,
} from "./Rangesliderdata";
import ImageDragContainer from "../ImageDragContainer";
import { setDatasets } from "react-chartjs-2/dist/utils";
// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

const prodArray: any = [];

export const AssetTracker = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { resource } = props;
  // const { handleInfo } = props;
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [uploadMain, setUploadMain] = useState<boolean>(false);

  const [recordKey, setRecordKey] = useState<any>("");
  const [dataIndex, setDataIndex] = useState<number>(-1);

  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    editInfo,
    hostId,
    cart = [],
    selectedEvent,
    people,
    eventTabs = [],
  } = myContext;

  const location = useLocation();
  const [opacity, setOpacity] = useState(0);
  // const [reportNames, setReportNames] = useState<any>([]);
  const [reports, setReports] = useState([]);

  const [incident, setIncident] = useState<any>([]);
  const temp = {
    _id: "ssdsds",
    title: "Pain tracker",
    label: "Description",
    img: "https://varefiles.s3.us-east-2.amazonaws.com/person-image.jpg",
    level: 20,
  };
  const [recordData, setRecordData] = useState<any>(temp);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  useEffect(() => {
    if (!eventId) {
      return;
    }
    getDataDB({
      query: {
        eventId: eventId,
      },
      resource: resource,
    }).then((info) => {
      if (info?.[0]) {
        setIncident(info);
        // setRecordData(info?.[0])
      }
    });
  }, [eventId, editInfo]);

  useEffect(() => {
    if (recordKey && eventId) {
      // alert(3)
      // setFormInput({

      // })
      getDataDB({
        query: {
          eventId: eventId,
          title: recordKey,
        },
        resource: resource + "_report",
      }).then((info) => {
        if (info && info?.[0]) {
          // sortby "reportDate"  todo
          setReports(info);
          // setRecordData(info?.[0])

          // setReports(info)
        }
      });
    }
  }, [recordKey, eventId, editInfo]);

  useEffect(() => {
    // console.log('formInputxx', formInput)
    setOpacity((formInput?.level * 1) / 100);
  }, [formInput?.level]);

  const colorOptions = [
    myColors?.brown,
    myColors?.darkGreen,
    myColors?.gray,
    myColors?.green,
    myColors?.red,
  ];

  const myOption: any = useMemo(() => {
    if (!reports?.[0]) {
      return;
    }

    const categories = reports?.map((res: any) => {
      return moment(res?.date)?.format("YYYY-MM-DDThh:mm:ss");
    });

    const options = {
      chart: {
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            console.log(config.w.config.labels[config.dataPointIndex]);
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: categories,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      colors: categories?.map((res: any, i: number) => {
        return colorOptions[i];
      }),
    };

    const dataInfo: any = [
      {
        name: "series1",
        data: reports?.map((res: any) => {
          return res?.level;
        }),
      },
    ];

    const result = {
      options: options || false,
      dataInfo: dataInfo,
    };
    console.log("resultgg", result);

    return result;
  }, [reports]);

  // console.log("vvvxxxx", reports, incident);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
      >
        <ButtonL
          style={{
            backgroundColor:
              formInput?.title && formInput?.title != temp?.title
                ? myColors?.green
                : "",
          }}
          onClick={() => {
            setFormInput({});
            setRecordData(temp);
            setRecordKey("");
          }}
        >
          Default
        </ButtonL>
        {incident &&
          incident?.map((inc: any, index: number) => {
            const rep = reports?.filter((ref: any) => {
              return ref?.title == inc?.title;
            });
            console.log("formData", resource, inc?.title, inc);

            return (
              <ButtonL
                style={{
                  backgroundColor:
                    formInput?.title != inc?.title ? myColors?.green : "",
                }}
                onClick={() => {
                  setFormInput(inc);
                  setRecordKey(inc?.title);
                  setRecordData(rep?.[0]);
                }}
              >
                ({rep?.length || 1}) {inc?.title}
              </ButtonL>
            );
          })}
      </div>

      {editInfo ? (
        <>
          <div
            style={{
              marginTop: 5,
            }}
          >
            Title
          </div>
          <InputAgenda>
            <input
              type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  title: e.target?.value,
                })
              }
              value={formInput?.title || ""}
              placeholder={"Add title ..."}
              required
            />
          </InputAgenda>
          {formInput?.title && (
            <>
              <div>
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  Label
                </div>
                <InputAgenda onSubmit={(e: any) => { }}>
                  <input
                    type={"text"}
                    onChange={(e: any) =>
                      setFormInput({
                        ...formInput,
                        label: e.target?.value,
                      })
                    }
                    value={formInput?.label || ""}
                    placeholder={"Add description ..."}
                    required
                  />
                </InputAgenda>
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  Add File (optional)
                </div>
                {/* {(addType?.file || formInput?.file) && formInput?.title && ( */}
                <InputAgenda>
                  <MyFileUploader
                    {...props}
                    // refreshing={refreshing}
                    setRefreshing={setRefreshing}
                    setFormInput={setFormInput}
                    formInput={formInput}
                    uploadNow={uploadMain}
                    defaultImage={formInput.img}
                    addFiletoDatabase={(fileName: any) => {
                      setRefreshing(true);

                      if (fileName) {
                        formInput["img"] = fileName;
                        const data = {
                          query: {
                            ...formInput,
                            reportId: recordData?._id,
                            handle: myInfo?.handle,
                          },
                          resource: resource,
                          check: ["handle", "title"],
                          myInfo,
                        };
                        console.log("ffileloaded vv", data);

                        saveDataDB(data).then((res) => {
                          // setRefreshing(true);
                          setFormInput({});
                          setMyContext((existing: any) => {
                            return {
                              ...existing,
                              editInfo: false,
                            };
                          });
                          alert("Saved Incident");
                        });
                      }
                    }}
                    handleSaveImage={(e: any) => {
                      // setUploadNow(true);
                    }}
                    resource={resource}
                  />
                  {/* <MyFileUploader
                {...props}
                refreshing={refreshing}
                setRefreshing={setRefreshing}
                setFormInput={setFormInput}
                formInput={formInput}
                uploadNow={uploadMain}
                defaultImage={formInput.img}
                handleSaveImage={(e: any) => {
                  // setUploadNow(true);
                }}
                resource={resource}
              /> */}
                </InputAgenda>
                {/* )} */}
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  Add Link (optional)
                </div>
                {/* {(addType?.link || formInput?.link) && formInput?.title && ( */}
                <InputAgenda>
                  <input
                    type={"text"}
                    onChange={(e: any) =>
                      setFormInput({
                        ...formInput,
                        link: e.target?.value,
                      })
                    }
                    value={formInput?.link || ""}
                    placeholder={"Add Link ..."}
                    required
                  />
                </InputAgenda>
                {/* )} */}
              </div>

              <ButtonL
                style={{ margin: 10 }}
                onClick={() => {
                  const data = {
                    query: {
                      ...formInput,
                      eventId: eventId || params.eventId,
                      handle: myInfo?.handle,
                    },
                    resource: resource,
                    check: ["handle", "title"],
                    myInfo,
                  };
                  console.log("formData", data);
                  // setUploadMain(true);

                  saveDataDB(data).then((res) => {
                    // setFormInput({})
                    setUploadMain(true);
                    // setMyContext((existing: any) => {
                    //   return {
                    //     ...existing,
                    //     editInfo: false,
                    //   };
                    // });
                  });
                }}
              >
                {"Save Incident"}
              </ButtonL>
            </>
          )}
        </>
      ) : (
        <>
          <Row
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Col lg={reports?.[0] ? 8 : 6} md={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  // backgroundColor: 'green'
                  // height: 300,
                }}
              >
                {reports?.[0] && (
                  <Col lg={3} md={3}>
                    <div
                      style={{
                        paddingRight: 20,
                        width: "10%",
                        height: 300,
                        display: "flex",
                        // backgroundColor: 'red'

                        // flexDirection: 'column'
                      }}
                    >
                      <DiscreteSliderMarksProps
                        orientation={"vertical"}
                        handleChange={(e: any) => {
                          console.log("nn", e?.target?.value);
                          if (e?.target?.value >= 0) {
                            // console.log("vvvxxxx", incident);
                            // if (reports?.[e?.target?.value])
                            setFormInput(
                              reports?.[e?.target?.value] || incident[0]
                            );
                          }
                          // setRecordData(incident?.[e?.target?.value])
                        }}
                        // value={3}
                        defaultVal={0}
                        marks={reports?.map((reg: any, index: number) => {
                          return {
                            value: index,
                            label: `${moment(reg?.date)?.format("hh:mm")}`,
                          };
                        })}
                        step={1}
                        max={reports?.length}
                      />
                    </div>
                  </Col>
                )}
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <ImageDragContainer
                    {...props}
                    opacity={opacity}
                    setOpacity={setOpacity}
                    imgLink={formInput.img || recordData?.img}
                  />
                </div>
              </div>
            </Col>

            <Col
              lg={reports?.[0] ? 4 : 6}
              md={12}
              style={
                {
                  // display: 'flex',
                  // height: 300,
                  // width: '40%',
                  // flexDirection: 'column',
                  // padding: 20
                }
              }
            >
              <div>{moment().format("MM/DD/YY")}</div>
              <div>{recordData?.title}</div>
              <DiscreteSliderMarks
                handleChange={(e: any) => {
                  console.log("nn", e?.target?.value);
                  setFormInput({
                    ...formInput,
                    level: e?.target?.value,
                  });
                }}
                defaultVal={formInput?.level || 0}
              />
              <div>{recordData?.label}</div>
              <InputAgenda onSubmit={(e: any) => { }}>
                <input
                  type={"text"}
                  onChange={(e: any) =>
                    setFormInput({
                      ...formInput,
                      description: e.target?.value,
                    })
                  }
                  value={formInput?.description || ""}
                  placeholder={`Add ${recordData?.label}`}
                  required
                />
              </InputAgenda>

              <InputAgenda>
                <MyFileUploader
                  {...props}
                  // refreshing={refreshing}
                  setRefreshing={setRefreshing}
                  setFormInput={setFormInput}
                  formInput={formInput}
                  uploadNow={uploadNow}
                  defaultImage={formInput.img}
                  addFiletoDatabase={(fileName: any) => {
                    if (fileName) {
                      formInput["img"] = fileName;
                      const data = {
                        query: {
                          ...formInput,
                          reportId: recordData?._id,
                          handle: myInfo?.handle,
                        },
                        resource: resource + "_report",
                        check: ["handle", "reportId"],
                        myInfo,
                      };
                      console.log("ffileloaded vv", data);

                      saveDataDB(data).then((res) => {
                        // setRefreshing(true);
                        setFormInput({});
                        setMyContext((existing: any) => {
                          return {
                            ...existing,
                            editInfo: false,
                          };
                        });
                        alert("Saved");
                      });
                    }
                  }}
                  handleSaveImage={(e: any) => {
                    // setUploadNow(true);
                  }}
                  resource={resource}
                />
              </InputAgenda>

              {formInput?.description && (
                <ButtonL
                  onClick={() => {
                    const data = {
                      query: {
                        ...formInput,
                        reportId: recordData?._id,
                        img: recordData?.img,
                        label: recordData?.label,
                        title: recordKey,
                        eventId: eventId || params.eventId,
                        handle: myInfo?.handle,
                        reportDate: moment().format(),
                      },
                      resource: resource + "_report",
                      check: ["handle", "date"],
                      myInfo,
                    };
                    // console.log('formData', data)
                    saveDataDB(data).then((res) => {
                      setUploadNow(true);
                    });
                  }}
                >
                  Save Report
                </ButtonL>
              )}
            </Col>
            <Col
              lg={6}
              md={12}
              style={{
                paddingTop: 50,
              }}
            >
              {myOption && myOption?.options && myOption?.dataInfo ? (
                <>
                  <Chart
                    onClick={(e: any) => {
                      console.log("lllll2", e);
                    }}
                    options={myOption?.options}
                    series={myOption?.dataInfo}
                    type="area"
                    height="350"
                  />
                </>
              ) : (
                <Chart
                  onClick={(e: any) => {
                    console.log("lllll33", e);
                  }}
                  options={options}
                  series={dataInfo}
                  type="area"
                  height="350"
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const dataInfo: any = [
  {
    name: "series1",
    data: [31, 40, 28, 51, 42, 109, 100],
  },
  {
    name: "series2",
    data: [11, 32, 45, 32, 34, 52, 41],
  },
];

const options: any = {
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: [
      "2018-09-19T00:00:00",
      "2018-09-19T01:30:00",
      "2018-09-19T02:30:00",
      "2018-09-19T03:30:00",
      "2018-09-19T04:30:00",
      "2018-09-19T05:30:00",
      "2018-09-19T06:30:00",
    ],
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
  colors: [
    myColors?.brown,
    myColors?.darkGreen,
    myColors?.gray,
    myColors?.green,
    myColors?.red,
  ],
};

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
    max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
    margin: 0 10px;
    width: 100%;
    height: 90vh;
    background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;

    /* Scrollbar Styles */
    &::-webkit-scrollbar {
      width: 8px;
  }

    &::-webkit-scrollbar-thumb {
      background - color: #479d83;
    border-radius: 4px;
  }

    &::-webkit-scrollbar-track {
      background - color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
    `;

const SearchWrapper = styled.div`
  max-width: 930px;
  width: 100%;
  border: 1px solid #dfe0e4;
  border-radius: 10px;
  margin-top: -120px;
  position: relative;
  z-index: 10;
  background: #fff;
`;

const SearchBarContainer = styled.div`
  padding: 28px;
`;

const ToggleMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  border-bottom: 1px solid #dfe0e4;
`;

const ToggleButtons = styled.a<{ active: boolean }>`
  text-decoration: none;
  padding: 17px 0;
  color: #000;
  min-width: 50px;
  border-bottom: ${({ active }) => (active ? "2px solid #479d83" : "none")};
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

const EventImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 2px solid #dee2e6;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;

const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "White")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const GuestImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px 14px;
  margin-top: 14px;
`;

const GuestMediaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 140px !important;
    width: 140px !important;
    background-color: black;
    border-radius: 5px;
    position: relative;
    video,
    img {
      background - color: black;
    border-radius: 5px;
  }
    `;

const GuestMediaHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-size: 12px;
    color: white;
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    svg {
      width: 12px;
    max-width: 12px;
    height: 12px;
  }
    p {
      font - size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    width: 50%;
    color: white;
    margin: 0;
  }
    div {
      display: flex;
    gap: 4px;
    span {
      padding: 3px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    }
  }
    `;

const GuestMediaParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  max-height: 500px;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: white;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const ButtonF = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PostHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const MenuWrapperPosts = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const DropdownFilterTabsContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

const FilterChild = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    width: fit-content;
  }
`;

const DropdownFilterTabs = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  z-index: 9999;
`;

const ButtonFilterTabs = styled.button`
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 8px;
    margin: 0;
    cursor: pointer;

    svg {
      color: #333;
    width: 15px;
    height: 15px;
  }
    &:hover {
      background - color: #f9f9f9;
  }
    `;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonIndustry = styled.button`
    background-color: #ffffff;
    color: #333;
    padding: 2px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    min-width: 160px;
    gap: 8px;
    font-size: 14px;
    &:hover {
      background - color: #f9f9f9;
  }
    `;

const DropdownContentIndustry = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

const DropdownItemIndustry = styled.div<any>`
      padding: 10px;
      cursor: pointer;
      color: #333;
      font-size: 12px;
      &:hover {
        background - color: #f1f1f1;
  }

      ${(props) =>
    props.active &&
    `
    background-color: #ddd; 
    font-weight: bold;
  `}
      `;

const TopHeaderWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 5px;
  margin-top: -10px;
`;

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin-top: -20px; */
`;

const EditSectionButton = styled.button`
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding: 5px 10px;
        border: none;
        width: 100%;
        font-size: 14px;
        background: #479d83;
        border-radius: 5px;
        cursor: pointer;
        margin: 0;
        svg {
          color: #fff;
        width: 20px;
        height: 20px;
  }
        p {
          color: #fff;
        margin: 0;
  }
        &:hover {
          background - color: #57b397;
  }
        `;

const PriceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
`;

const Img = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const SupCardHeader = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  padding: 0px 10px;
`;

const CardLabelSub = styled.h3`
  margin: 0;
  font-size: 14px;
  color: gray;
  padding: 0px 10px;
`;

const CardPriceSub = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  padding: 0px 10px;
`;

const CardSupOffer = styled.div`
  margin: 0;
  font-size: 10px;
  color: gray;
  padding: 0px 10px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const HeroBannerWrapper = styled.div`
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 40px;
        background: #96c7b8;
        min-height: 500px;
        @media (max-width: 800px) {
          min - height: 450px;
  }
        @media (max-width: 500px) {
          min - height: 400px;
  }
        `;

const HeroBannerChild = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        width: 100%;
        @media (max-width: 800px) {
          justify - content: center;
  }
        `;

const HeroBannerTextWrapper = styled.div`
        display: flex;
        max-width: 400px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        color: white;

        h1 {
          font - size: 66px;
        line-height: 49px;
        font-weight: 900;
        margin: 0;
        text-align: start;
  }

        p {
          margin: 0;
        font-size: 20px;
        font-weight: 200;
        text-align: start;
        color: white;
  }

        @media (max-width: 800px) {
          align - items: center;
        text-align: center;

        h1 {
          text - align: center;
    }

        p {
          text - align: center;
    }
  }

        @media (max-width: 500px) {
          max - width: 280px;

        h1 {
          font - size: 40px;
        line-height: 30px;
    }

        p {
          font - size: 14px;
    }
  }
        `;
