import moment from "moment";

const TOKEN_KEY = "jwt";


export const capitalizeFirstLetter = (str) => {
  if (!str) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const containsChar = (s, search) => {
  if (s.length() == 0)
    return false;
  else
    return s.charAt(0) == search || containsChar(s.substring(1), search);
}

export const removeDuplicates = (a) => {
  if (!(a && Array.isArray(a))) {
    return []
  }
  return a?.filter(function (item, pos) {
    return a?.indexOf(item) == pos;
  })
}

export const removeDup = (data) => {
  if (!(data && Array.isArray(data))) {
    return []
  }
  const tempKeys = {}
  const newData = []
  data?.map(res => {
    const item = res._id  //JSON.stringify(res)
    tempKeys[res._id] = res
  })
  return Object.keys(tempKeys)?.map(res => {
    return tempKeys[res]
  })

}


// export const removeDup = (data) => {
//   if (!(data && Array.isArray(data))) {
//     return []
//   }
//   const tempKeys = []
//   const newData = []
//   data?.map(res => {
//     const item = JSON.stringify(res)
//     if (!(tempKeys?.includes(item))) {
//       tempKeys.push(item)
//       newData.push(res)
//     }
//   })
//   return newData
// }

export const login = () => {
  // const urlParams = parseURL(history.location.search);
  // cookie.save("vare", urlParams.app, {path: "/"});
  // localStorage.setItem(TOKEN_KEY, "TestLogin");
};

export const logout = () => {
  // cookie.remove("vare", {path: "/"});
  // localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
  // if (localStorage.getItem(TOKEN_KEY)) {
  // alert(cookie.load("vare"));
  // if (cookie.load("vare")) {
  //   return true;
  // }

  return false;
};



const likeChat = chat => {
  // const { } = chat
  // const parseLikes =
  //   chat.likes && Array.isArray(JSON.parse(chat.likes))
  //     ? JSON.parse(chat.likes)
  //     : "";
  // const pos =
  //   parseLikes &&
  //   parseLikes
  //     .map(function (e) {
  //       return e.user_id;
  //     })
  //     .indexOf(storeUser.user_id);
  // // console.log("pos", pos);

  // const newLike =
  //   pos && pos >= 0
  //     ? parseLikes.splice(0, pos)
  //     : parseLikes && parseLikes.length > 0
  //       ? parseLikes.push({
  //         name: storeUser.name,
  //         user_id: storeUser.user_id,
  //         img: storeUser.img
  //       })
  //       : [
  //         {
  //           name: storeUser.name,
  //           user_id: storeUser.user_id,
  //           img: storeUser.img
  //         }
  //       ];

  // chat["likes"] =
  //   newLike && Array.isArray(newLike) ? JSON.stringify(newLike) : "";
  // delete chat["comment"];
  // socketRef.current.emit("save chat", chat);
  // socketRef.current.on("meeting message", chat => {
  //   // const oldChats = msg.filter(rep => rep._id != chat._id)
  //   const newChats = chat && chat.length > 0 ? [...chat] : [...msg];
  //   newChats.sort((a, b) => {
  //     var dateA = new Date(a.date).getTime();
  //     var dateB = new Date(b.date).getTime();
  //     return dateA > dateB ? -1 : 1;
  //   });
  //   setMsg(newChats);
  //   // console.log('newChats',newChats)
  // });
};

export function groupBy({ data, key }) {
  if (!(data && data?.length > 0 && key) || !key) {
    return {}
  }
  let group =
    key &&
    data &&
    Array.isArray(data) &&
    data.reduce((r, a) => {
      r[a[key]] = [...(r[a[key]] || []), a];
      return r;
    }, {});
  return group;
}

export async function groupByAsync({ data, key }) {
  if (!(data && data?.length > 0 && key) || !key) {
    return {}
  }
  let group =
    key &&
    data &&
    Array.isArray(data) &&
    data.reduce((r, a) => {
      r[a[key]] = [...(r[a[key]] || []), a];
      return r;
    }, {});
  return await group;
}

export function groupByNow({ data, key, id }) {
  if (!(data && data?.length > 0 && key) || !key) {
    return {}
  }
  let group =
    key &&
    data &&
    Array.isArray(data) &&
    data.reduce((r, a) => {
      r[a[key]] = [...(r[a[key]] || []), a];
      return r;
    }, {});
  return group;
}

// export async function groupByAsync({ data, key }) {
//   let group =
//     key &&
//     data &&
//     Array.isArray(data) &&
//     data.reduce((r, a) => {
//       r[a[key]] = [...(r[a[key]] || []), a];
//       return r;
//     }, {});
//   return await group;
// }

export function parseURL(url) {
  // if(!url) url = location.search;
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function (part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function getDatePeriod(time) {
  const now = moment();
  const expiration = moment(time);

  // get the difference between the moments
  const diff = expiration.diff(now);

  //express as a duration
  const diffDuration = moment.duration(diff);

  // display
  // console.log("Days:", diffDuration.days());
  // console.log("Hours:", diffDuration.hours());
  // console.log("Minutes:", diffDuration.minutes());
  if (diffDuration.minutes() < 61) {
    return diffDuration.minutes() + " minutes";
  } else if (diffDuration.hours() < 25) {
    return diffDuration.hours() + " hours";
  } else if (diffDuration.days() < 8) {
    return diffDuration.days() + " days";
  } else if (diffDuration.weeks() < 5) {
    return diffDuration.weeks() + " weeks";
  } else if (diffDuration.months() < 12) {
    return diffDuration.months() + " months";
  }
}
