// import React, { useRef, useState } from 'react';
// import { PDFDocument, rgb } from 'pdf-lib';
// import SignatureCanvas from 'react-signature-canvas';

// const DigitalContract = () => {
//   const sigCanvas = useRef(null);
//   const [generatedPdf, setGeneratedPdf] = useState(null);

//   const handleGeneratePdf = async () => {
//     const pdfDoc = await PDFDocument.create();
//     const page = pdfDoc.addPage([600, 400]);

//     page.drawText('Digital Contract', {
//       x: 50,
//       y: 350,
//       size: 30,
//       color: rgb(0, 0.53, 0.71),
//     });

//     const signatureDataUrl = sigCanvas.current.toDataURL();
//     const signatureImageBytes = await fetch(signatureDataUrl).then(res => res.arrayBuffer());
//     const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

//     page.drawImage(signatureImage, {
//       x: 200,
//       y: 100,
//       width: 200,
//       height: 100,
//     });

//     const pdfBytes = await pdfDoc.save();
//     const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
//     const pdfUrl = URL.createObjectURL(pdfBlob);
//     setGeneratedPdf(pdfUrl);
//   };

//   const handleClearSignature = () => {
//     sigCanvas.current.clear();
//   };

//   return (
//     <div>
//       <h1>Digital Contract</h1>
//       <p>Please sign below:</p>
//       <SignatureCanvas
//         ref={sigCanvas}
//         penColor="black"
//         canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
//       />
//       <button onClick={handleClearSignature}>Clear</button>
//       <button onClick={handleGeneratePdf}>Generate PDF</button>
//       {generatedPdf && (
//         <div>
//           <h2>Generated PDF</h2>
//           <iframe src={generatedPdf} width="600" height="400" />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DigitalContract;


// NEWWWW

// import React, { useRef, useState } from 'react';
// import { PDFDocument, rgb } from 'pdf-lib';
// import SignatureCanvas from 'react-signature-canvas';

// const DigitalContract = () => {
//   const sigCanvas = useRef(null);
//   const [generatedPdf, setGeneratedPdf] = useState(null);
//   const [pdfFile, setPdfFile] = useState(null);

//   const handleFileChange = async (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const fileData = await file.arrayBuffer();
//       setPdfFile(fileData);
//     }
//   };

//   const handleGeneratePdf = async () => {
//     if (!pdfFile) {
//       alert('Please upload a PDF file first.');
//       return;
//     }

//     // Load the existing PDF document
//     const pdfDoc = await PDFDocument.load(pdfFile);
//     const page = pdfDoc.getPages()[0]; // Get the first page

//     // Draw the signature
//     const signatureDataUrl = sigCanvas.current.toDataURL();
//     const signatureImageBytes = await fetch(signatureDataUrl).then(res => res.arrayBuffer());
//     const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

//     page.drawImage(signatureImage, {
//       x: 50, // Position of the signature. Adjust as needed
//       y: page.getHeight() - 150, // Positioning the signature at the bottom of the page
//       width: 200,
//       height: 100,
//     });

//     // Serialize the PDFDocument to bytes
//     const pdfBytes = await pdfDoc.save();
//     const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
//     const pdfUrl = URL.createObjectURL(pdfBlob);
//     setGeneratedPdf(pdfUrl);
//   };

//   const handleClearSignature = () => {
//     sigCanvas.current.clear();
//   };

//   return (
//     <div>
//       <h1>Digital Contract</h1>
//       <input type="file" onChange={handleFileChange} accept="application/pdf" />
//       <p>Please sign below:</p>
//       <SignatureCanvas
//         ref={sigCanvas}
//         penColor="black"
//         canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
//       />
//       <button onClick={handleClearSignature}>Clear</button>
//       <button onClick={handleGeneratePdf}>Generate PDF</button>
//       {generatedPdf && (
//         <div>
//           <h2>Generated PDF</h2>
//           <iframe src={generatedPdf} title="Signed PDF" width="600" height="400" />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DigitalContract;

import React, { useRef, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const DigitalContract = () => {
  const sigCanvas = useRef(null);
  const [generatedPdf, setGeneratedPdf] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileBuffer, setPdfFileBuffer] = useState(null);
  const [signaturePosition, setSignaturePosition] = useState(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const viewerRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileData = await file.arrayBuffer();
      const blob = new Blob([fileData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setPdfFile(url);
      setPdfFileBuffer(fileData);  // Store the file data buffer
    }
  };

  const handleGeneratePdf = async () => {
    if (!pdfFileBuffer || !signaturePosition) {
      alert('Please upload a PDF file and place a signature.');
      return;
    }

    const pdfDoc = await PDFDocument.load(pdfFileBuffer);  // Use the file data buffer
    const page = pdfDoc.getPages()[0];

    const signatureDataUrl = sigCanvas.current.toDataURL();
    const signatureImageBytes = await fetch(signatureDataUrl).then(res => res.arrayBuffer());
    const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

    page.drawImage(signatureImage, {
      x: signaturePosition.x,
      y: page.getHeight() - signaturePosition.y,
      width: 200,
      height: 100,
    });

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setGeneratedPdf(pdfUrl);
  };

  const handleClearSignature = () => {
    sigCanvas.current.clear();
  };

  const handlePdfClick = (event) => {
    if (viewerRef.current) {
      const rect = viewerRef.current.getBoundingClientRect();
      const x = (event.clientX - rect.left) * (viewerRef.current.offsetWidth / rect.width);
      const y = (event.clientY - rect.top) * (viewerRef.current.offsetHeight / rect.height);
      console.log(`Signature position: x=${x}, y=${y}`);
      setSignaturePosition({ x, y });
    }
  };

  return (
    <div>
      <h1>Digital Contract</h1>
      <input type="file" onChange={handleFileChange} accept="application/pdf" />
      {pdfFile && (
        <div onClick={handlePdfClick} ref={viewerRef}>
          {/*TODO: Add highlighter to the pdf selection to select the area. Allow user to delete the highlighter. Also allow them to select either date or signature. And for dates it should always be the current date.  */}
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        </div>
      )}
      <p>Please sign below:</p>
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
      />
      <button onClick={handleClearSignature}>Clear</button>
      <button onClick={handleGeneratePdf}>Generate PDF</button>
      {generatedPdf && (
        <div>
          <h2>Generated PDF</h2>
          <iframe src={generatedPdf} title="Signed PDF" width="600" height="400" />
        </div>
      )}
    </div>
  );
};

export default DigitalContract;

