import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getDataDB, saveDataDB } from "src/api";
import { useParams } from "react-router-dom";
import { UserContext } from "src/App";
import { ButtonL } from "../AppIndex";
import {
  AddSkillSection,
  EditSkillsModalWrapper,
  InputAgenda,
  SkillChildWrapper,
} from "./TabListItems";
import { HighlightOff } from "@mui/icons-material";

const faqs = [
  {
    question: "What is the date and time of the event?",
    answer:
      "The event will be held on June 25, 2024, from 10:00 AM to 4:00 PM.",
  },
  {
    question: "Where is the event located?",
    answer:
      "The event will take place at the Grand Convention Center, 123 Main Street, Cityville.",
  },
  {
    question: "Is there a registration fee for the event?",
    answer:
      "Yes, the registration fee is $50 for early birds and $75 for regular attendees.",
  },
  {
    question: "What should I bring to the event?",
    answer:
      "Please bring your ticket (printed or digital), a valid ID, and business cards for networking.",
  },
  {
    question: "Will food and beverages be provided?",
    answer:
      "Yes, complimentary lunch, snacks, and beverages will be provided during the event.",
  },
  {
    question: "Are there any accommodation options nearby?",
    answer:
      "Yes, we have partnered with several hotels nearby. Please visit our website for more details and discounts.",
  },
  {
    question: "How can I contact the event organizers?",
    answer:
      "You can reach us at support@event.com or call us at (123) 456-7890 for any inquiries.",
  },
];

export const FAQ = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, type } = props;
  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { myInfo, host, eventId, hostTabs, allAccess } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const resource = useMemo(() => {
    const tab = eventTab || hostTab;
    const subTab = eventSubTab || hostSubTab;
    return `calendar_${params.eventId ? "event" : "host"}${
      tab ? "_" + tab : ""
    }${subTab ? "_" + subTab : ""}`;
  }, [eventTab, eventSubTab, hostSubTab, hostTab, params?.eventTab]);

  useEffect(() => {
    if (!eventId) {
      return;
    }
    const data = {
      query: {
        eventId: eventId,
      },
      resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
      check: ["handle"],
    };
    getDataDB(data).then((res: any) => {
      console.log("hostTabsxxccnnttt", res);
      if (res?.[0]?.faq) {
        setDataRecord([
          ...res?.[0]?.faq,
          // ...dataRecord
        ]);
      }
    });
  }, [eventId, refreshing]);

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      {editInfo && (
        <>
          <EditSkillsModalWrapper>
            <AddSkillSection>
              <h2>Add Faq</h2>
              <SkillChildWrapper>
                <InputAgenda onSubmit={(e: any) => {}}>
                  <input
                    type={"text"}
                    onChange={(e: any) =>
                      setFormInput({
                        ...formInput,
                        question: e.target?.value,
                      })
                    }
                    value={formInput?.question || ""}
                    placeholder={"Add question ..."}
                    required
                  />
                </InputAgenda>
              </SkillChildWrapper>
              <SkillChildWrapper>
                <InputAgenda onSubmit={(e: any) => {}}>
                  <input
                    type={"text"}
                    onChange={(e: any) =>
                      setFormInput({
                        ...formInput,
                        answer: e.target?.value,
                      })
                    }
                    value={formInput?.answer || ""}
                    placeholder={"Add answer ..."}
                    required
                  />
                </InputAgenda>
              </SkillChildWrapper>
            </AddSkillSection>

            <ButtonL
              disabled={
                !(formInput?.question && formInput?.answer)
                  ? // addType?.file ||
                    // addType?.link ||
                    // formInput?.file ||
                    // formInput?.link
                    true
                  : false
              }
              style={{ margin: 0 }}
              onClick={() => {
                if (!myInfo?.handle) {
                  alert("Please login");
                  return;
                }
                if (!(formInput?.question && formInput?.answer)) {
                  alert("Quest and Answer required");
                  return;
                }
                const data = {
                  query: {
                    faq: [
                      {
                        question: formInput?.question,
                        answer: formInput?.answer,
                      },
                      ...dataRecord?.filter(
                        (ref: any) => ref.question != formInput?.question
                      ),
                    ],
                    handle: myInfo?.handle,
                    eventId: eventId,
                  },
                  resource: resource,
                  check: ["handle", "eventId"],
                  myInfo,
                };
                // console.log('formData', data)
                saveDataDB(data).then((res) => {
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      editInfo: false,
                    };
                  });
                });
              }}
            >
              Add
            </ButtonL>
          </EditSkillsModalWrapper>
        </>
      )}
      <FAQContainer>
        {(dataRecord?.[0] ? dataRecord : faqs)?.map(
          (faq: any, index: number) => (
            <FAQItem key={index}>
              <Question>
                {allAccess && (
                  <HighlightOff
                    onClick={() => {
                      const data = {
                        query: {
                          faq: [
                            ...dataRecord?.filter(
                              (ref: any) => ref.question != faq?.question
                            ),
                          ],
                          handle: myInfo?.handle,
                          eventId: eventId,
                        },
                        resource: resource,
                        check: ["handle", "eventId"],
                        myInfo,
                      };
                      // console.log('formData', data)
                      saveDataDB(data).then((res) => {
                        //setRefreshing(true);
                        setMyContext((existing: any) => {
                          return {
                            ...existing,
                            editInfo: false,
                          };
                        });
                      });
                    }}
                  />
                )}
                <div onClick={() => toggleFAQ(index)}> {faq.question} </div>
                {openIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Question>
              <Answer isOpen={openIndex === index}>{faq.answer}</Answer>
            </FAQItem>
          )
        )}
      </FAQContainer>
    </>
  );
};

const FAQContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const FAQItem = styled.div`
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
`;

const Question = styled.div`
  background: #f7f7f7;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background: #e0e0e0;
  }
`;

const Answer = styled.p<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  overflow: hidden;
  text-align: left;
  margin: 0;
  transition: max-height 0.3s ease;
  padding: ${({ isOpen }) => (isOpen ? "15px" : "0 15px")};
  background: #fff;
`;
