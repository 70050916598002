import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getChatGPTResponse,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
  stringifyArrayToBullets,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  ArrowBackIos,
  ArrowForwardIos,
  Attachment,
  Cancel,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  Subscriptions,
  Sync,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { AdvertListItems } from "./AdvertListItems";
import { MyTabListItems } from "./MyTabListItems";
// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export const UserJourney = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, resource } = props;
  const params: any = useParams<any>();
  const { myInfo, host, hostTabs, eventInfo, allAccess } = myContext;

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [service, setService] = useState<any>("");

  const [dataRecord, setDataRecord] = useState<any>({
    journeys: [
      {
        title: "journey",
        img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
        steps: [
          {
            title: "scheduling",
            value: "scheduling",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "scheduling",
            value: "scheduling",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "notifications",
            value: "notifications",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "recurring event planning",
            value: "recurring event planning",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "lead management",
            value: "lead management",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "career management",
            value: "career management",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "colaboration",
            value: "colaboration",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
        ],
      },
      {
        title: "journey",
        img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
        steps: [
          {
            title: "scheduling",
            value: "scheduling",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "scheduling",
            value: "scheduling",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "notifications",
            value: "notifications",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "recurring event planning",
            value: "recurring event planning",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "lead management",
            value: "lead management",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "career management",
            value: "career management",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
          {
            title: "colaboration",
            value: "colaboration",
            link: "",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          },
        ],
      },
    ],
  });
  const [refreshing, setRefreshing] = useState<boolean>(false);
  // const [reset, setReset] = useState<boolean>(true);
  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [openMedia, setOpenMedia] = useState<any>(false);
  const [loadingAI, setLoadingAI] = useState<any>(false);


  // const handleReset = useCallback(() => {
  //   if (!myInfo?.handle) {
  //     alert("Please login");
  //     return;
  //   }
  //   if (!eventId) {
  //     return;
  //   }

  //   const data = {
  //     query: {
  //       ...dataRecord,
  //       handle: myInfo?.handle,
  //       eventId: eventId,
  //     },
  //     resource: resource,
  //     check: ["handle", "eventId"],
  //     myInfo,
  //   };
  // }, [
  //   eventId,
  //   myInfo?.handle,
  //   dataRecord
  // ]);

  useEffect(() => {
    if (eventId && myInfo?.handle) {
      const data = {
        query: {
          // handle: myInfo?.handle,
          eventId: eventId,
        },
        resource: resource,
        check: ["handle", "eventId"],
      };
      getDataDB(data).then((res) => {
        console.log("hkjhkjhkjh", res);
        if (res?.[0]) {
          setDataRecord(res?.[0] ? res?.[0] : {});
          setFormInput(res?.[0]);
          // setService(res?.[0]?.offerings || [])
        } else {
          setFormInput({
            eventId: eventId,
            // handle: myInfo?.handle,
            ...formInput,
            ...dataRecord,
          });
        }
        setRefreshing(false);
      });
    }

  }, [eventId, myInfo?.handle]);

  return (
    <>
      <div>
        <CardWrapper
          style={{
            display: "flex",
            flexDirection: "column",
            // width: 500,
          }}
        >
          {formInput?.journeys?.[0] &&
            formInput.journeys?.map((journey: any, index: number) => {
              return (
                <>
                  <PriceCardWrapper
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      overflowX: "auto",
                      // height: 300,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={
                          {
                            // marginTop: 80,
                            // backgroundColor: 'red'
                          }
                        }
                      >
                        Journey
                      </div>
                      <div
                        style={{
                          minWidth: 200,
                          position: "relative",
                          backgroundColor: "rgba(0,0,0,0.09)",
                          justifyContent: "flex-start",
                          borderRadius: 20,
                          height: 250,
                        }}
                      >
                        <div
                          style={{
                            // marginTop: 80,
                            fontSize: "0.9em",
                            // backgroundColor: 'red'
                          }}
                        >
                          {editInfo ? (
                            <InputAgenda>
                              <MyFileUploader
                                {...props}
                                refreshing={refreshing}
                                setRefreshing={setRefreshing}
                                setFormInput={setFormInput}
                                formInput={formInput}
                                uploadNow={uploadNow}
                                tempImage={journey.img}
                                allowAdd={false}
                                addFiletoDatabase={(fileName: any) => {
                                  if (fileName) {
                                    if (formInput?.journeys[index].img) {
                                      formInput.journeys[index]["img"] =
                                        fileName;
                                    } else {
                                      formInput["img"] = fileName;
                                    }
                                    const data = {
                                      query: {
                                        ...formInput,
                                        handle: myInfo?.handle,
                                      },
                                      resource: resource,
                                      check: ["handle", "title"],
                                      myInfo,
                                    };
                                    console.log("ffileloaded vv", data);

                                    saveDataDB(data).then((res) => {
                                      //setRefreshing(true);
                                      setMyContext((existing: any) => {
                                        return {
                                          ...existing,
                                          editInfo: false,
                                        };
                                      });
                                      // alert("Saved");
                                    });
                                  }
                                }}
                                handleSaveImage={(e: any) => {
                                  setUploadNow(true);
                                }}
                                resource={resource}
                              />
                            </InputAgenda>
                          ) : (
                            <Img
                              onClick={() => {
                                setOpenMedia(journey)
                              }}
                              src={
                                journey.img ||
                                "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                              }
                              alt={journey.title}
                            />
                          )}
                          <SupCardHeader
                            style={{
                              fontSize: "0.9em",
                              height: "100%",
                              width: 150,
                              overflowY: "auto",
                            }}
                          >
                            {editInfo ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div style={{}}>Journey Header</div>
                                <textarea
                                  style={{
                                    width: '100%'
                                  }}
                                  // value={data?.title}
                                  rows={5}
                                  className="form-control"
                                  // type="text"
                                  onChange={(e: any) => {
                                    const temp = { ...formInput };
                                    temp.journeys[index] = {
                                      ...formInput.journeys[index],
                                      title: e.target?.value,
                                    };
                                    console.log("ccccvvvv", temp);
                                    setFormInput({
                                      ...temp,
                                    });
                                  }}
                                  value={journey?.title || ""}
                                  // value={'Search field for what you wish to do'}
                                  placeholder="Company Name"
                                />
                              </div>
                            ) : (
                              <>{journey?.title || ""}</>
                            )}
                          </SupCardHeader>
                        </div>
                      </div>
                    </div>

                    {journey?.steps?.[0] &&
                      journey?.steps?.map((info: any, i: number) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{
                              display: "flex",
                              flexDirection: "row",
                            }}>
                              {/* {editInfo && (
                                <ArrowBackIos
                                  onClick={() => {
                                    const temp = { ...formInput };
                                    if (
                                      temp.journeys[index].steps &&
                                      temp.journeys[index].steps?.[i - 1]
                                    ) {
                                      temp.journeys[index].steps[i] =
                                        temp.journeys[index].steps?.splice(
                                          i - 1,
                                          0,
                                          temp.journeys[index].steps[i]
                                        );
                                      // console.log("ccccvvvvee", temp);
                                      setFormInput({
                                        ...temp,
                                      });
                                    }
                                  }}
                                />
                              )} */}
                              step {i + 1 || ""}{" "}
                              {/* {editInfo && (
                                <ArrowForwardIos
                                  onClick={() => {
                                    const temp = { ...formInput };
                                    if (
                                      temp.journeys[index].steps &&
                                      temp.journeys[index].steps?.[i + 1]
                                    ) {
                                      temp.journeys[index].steps[i] =
                                        temp.journeys[index].steps?.splice(
                                          i + 1,
                                          0,
                                          temp.journeys[index].steps[i]
                                        );
                                      // console.log("ccccvvvvee", temp);
                                      setFormInput({
                                        ...temp,
                                      });
                                    }
                                  }}
                                />

                              )} */}
                            </div>
                            <div
                              style={{
                                minWidth: 200,
                                position: "relative",
                                backgroundColor: "rgba(0,0,0,0.09)",
                                justifyContent: "flex-start",
                                borderRadius: 20,
                                height: editInfo ? 300 : 250,
                              }}
                            >
                              <div
                                style={{
                                  // marginTop: 80,
                                  fontSize: "0.9em",
                                  // backgroundColor: 'red'
                                }}
                              >
                                {editInfo && (
                                  <Cancel
                                    onClick={() => {
                                      const temp = { ...formInput };
                                      if (temp.journeys[index].steps) {
                                        temp.journeys[index].steps = [
                                          ...formInput.journeys?.[
                                            index
                                          ]?.steps?.filter(
                                            (re: any, t: number) => t != i
                                          ),
                                        ];
                                        // console.log("kkklll", temp);
                                        setFormInput({
                                          ...temp,
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </div>
                              {editInfo ? (
                                <InputAgenda>
                                  <MyFileUploader
                                    {...props}
                                    refreshing={refreshing}
                                    setRefreshing={setRefreshing}
                                    setFormInput={setFormInput}
                                    formInput={formInput}
                                    uploadNow={uploadNow}
                                    defaultImage={info.img}
                                    allowAdd={false}
                                    addFiletoDatabase={(fileName: any) => {
                                      if (fileName) {
                                        if (
                                          formInput?.journeys[index]?.steps?.[i]
                                        ) {
                                          formInput.journeys[index].steps[i][
                                            "img"
                                          ] = fileName;
                                        } else {
                                          formInput["img"] = fileName;
                                        }
                                        const data = {
                                          query: {
                                            ...formInput,
                                            handle: myInfo?.handle,
                                          },
                                          resource: resource,
                                          check: ["handle", "title"],
                                          myInfo,
                                        };
                                        // console.log("ffileloaded vv", data);

                                        saveDataDB(data).then((res) => {
                                          //setRefreshing(true);
                                          setMyContext((existing: any) => {
                                            return {
                                              ...existing,
                                              editInfo: false,
                                            };
                                          });
                                          // alert("Saved");
                                        });
                                      }
                                    }}
                                    handleSaveImage={(e: any) => {
                                      setUploadNow(true);
                                    }}
                                    resource={resource}
                                  />

                                </InputAgenda>
                              ) : (
                                <Img
                                  onClick={() => {
                                    if (info?.link) {
                                      window.open(info?.link, "_blank");
                                    }
                                    setOpenMedia(info)


                                  }}
                                  src={
                                    info.img ||
                                    "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                                  }
                                  alt={journey.title}
                                />
                              )}
                              {/* <Img src={info?.img || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'} alt={info?.title} /> */}
                              <div
                                style={{
                                  fontSize: "0.9em",
                                  overflowY: "auto",
                                  height: editInfo ? "100%" : "100%",
                                  maxHeight: editInfo ? 300 : 140,
                                }}
                              >
                                {editInfo ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <InputAgenda onSubmit={(e: any) => { }}>

                                      <input
                                        type={"text"}
                                        onChange={(e: any) => {
                                          const temp = { ...formInput };
                                          if (temp.journeys[index].steps) {
                                            temp.journeys[index].steps[i] = {
                                              ...info,
                                              title: e.target?.value,
                                            };

                                            setFormInput({
                                              ...temp,
                                            });
                                          }
                                        }}
                                        value={info?.title || ""}
                                        placeholder={`Add title`}
                                        required
                                      />
                                    </InputAgenda>
                                    <h6> Description</h6>
                                    <div
                                      onClick={() => {
                                        if (!info?.title) {
                                          alert('A title is needed to generate your description')
                                          return
                                        }
                                        setLoadingAI('description')
                                        getChatGPTResponse({
                                          myInfo: myInfo,
                                          prompt: `generate a 3 offerinngs my company offers in our ${info?.title} services`
                                        })
                                          .then((reply: any) => {
                                            setLoadingAI(false)
                                            if (reply) {
                                              const temp = { ...formInput };
                                              if (temp.journeys[index].steps) {
                                                temp.journeys[index].steps[i] = {
                                                  ...info,
                                                  description: stringifyArrayToBullets({ arr: reply }),
                                                  descriptionArray: reply
                                                };
                                                // console.log("ccccvvvvee", temp);
                                                setFormInput({
                                                  ...temp,
                                                });
                                                // setFormInput({
                                                //   ...formInput,
                                                //   description: stringifyArrayToBullets({ arr: reply }),
                                                //   descriptionArray: reply
                                                // })
                                              }
                                            }
                                          })
                                      }}
                                      style={{
                                        cursor: 'pointer',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'green',
                                        borderRadius: 100,
                                        color: 'white',
                                        paddingRight: 5,
                                        paddingLeft: 5,
                                        marginRight: 10,
                                      }}>{loadingAI ? 'loading..' : 'AI'}</div>
                                    <textarea
                                      // value={data?.title}
                                      rows={5}
                                      className="form-control"
                                      // type="text"
                                      onChange={(e: any) => {
                                        const temp = { ...formInput };
                                        if (temp.journeys[index].steps) {
                                          temp.journeys[index].steps[i] = {
                                            ...info,
                                            description: e.target?.value,
                                          };
                                          // console.log("ccccvvvvee", temp);
                                          setFormInput({
                                            ...temp,
                                          });
                                        }
                                      }}
                                      // value={
                                      //   info?.title ||
                                      //   "Search field for what you wish to do"
                                      // }
                                      style={
                                        {
                                          // marginBottom: 10,
                                          // width: "100%",
                                          // alignSelf: "center",
                                          // backgroundColor: 'gray'
                                        }
                                      }
                                      value={info?.description || ""}
                                      placeholder="Company Name"
                                    />
                                    <h6>   Link</h6>
                                    <InputAgenda onSubmit={(e: any) => { }}>
                                      <input
                                        type={"text"}
                                        onChange={(e: any) => {
                                          const temp = { ...formInput };
                                          if (temp.journeys[index].steps) {
                                            temp.journeys[index].steps[i] = {
                                              ...info,
                                              link: e.target?.value,
                                            };
                                            // console.log("ccccvvvvee", temp);
                                            setFormInput({
                                              ...temp,
                                            });
                                          }
                                        }}
                                        value={info?.link || ""}
                                        placeholder={`Add Link (optional)`}
                                        required
                                      />
                                    </InputAgenda>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      overflowWrap: "break-word",
                                      margin: 10,
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      // backgroundColor: 'red',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                        // fontWeight:'',
                                      }}
                                    >
                                      <div style={{ fontWeight: 'bold' }}> {"   "}</div>
                                      {info?.title || ""}
                                    </div>
                                    {info?.subTitle && <>
                                      <div style={{ fontWeight: 'bold' }}> {" Subtitle  "}</div>
                                      <div>{info?.subTitle || " "}</div>
                                    </>}
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                        );

                      })}
                    {allAccess && editInfo && (
                      <div
                        style={{
                          // width: 400,
                          // backgroundColor: 'red',
                          minWidth: 200,
                        }}
                        onClick={() => {
                          if (!(myInfo?.handle == host?.handle && editInfo)) {
                            return;
                          }
                          const temp = { ...formInput };
                          temp.journeys[temp.journeys?.length] = {
                            id: temp.journeys?.length + 1,
                            title: "title",
                            value: "tbd",
                            link: "",
                            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                          };
                          console.log("ccccvvvv", temp);
                          setFormInput({
                            ...temp,
                          });
                        }}
                      >
                        <div
                          style={{
                            fontSize: "0.9em",
                          }}
                        >
                          step {index} {editInfo && <Cancel />}
                        </div>
                        <Img
                          src={
                            "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                          }
                          alt={"na"}
                        />
                        <div
                          style={{
                            fontSize: "0.9em",
                            height: 60,
                            overflowY: "auto",
                          }}
                        >
                          <h5>{"Add Step +"}</h5>
                        </div>
                      </div>
                    )}
                  </PriceCardWrapper>
                  {editInfo && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 5,
                      }}
                    >
                      <ButtonL
                        style={{ margin: 5 }}
                        onClick={() => {
                          if (!myInfo?.handle) {
                            alert("Please login");
                            return;
                          }

                          const data = {
                            query: {
                              ...formInput,
                              handle: myInfo?.handle,
                              eventId: eventId,
                            },
                            resource: resource,
                            check: ["handle", "eventId"],
                            myInfo,
                          };
                          // console.log('formDatasss', data)
                          saveDataDB(data).then((res) => {
                            //setRefreshing(true);
                            setUploadNow(true);
                            setTimeout(() => {
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  editInfo: false,
                                };
                              });
                            }, 100);
                          });
                        }}
                      >
                        Save
                      </ButtonL>

                      <ButtonL
                        style={{ margin: 5 }}
                        onClick={() => {
                          const removed = formInput.journeys?.filter(
                            (re: any, i: number) => i != index
                          );
                          const temp = {
                            ...formInput,
                            journeys: removed,
                          };

                          console.log("kkklll", temp);
                          setFormInput({
                            ...temp,
                          });
                        }}
                      >
                        Remove
                      </ButtonL>
                    </div>
                  )}
                </>
              );
            })}
          {allAccess && (
            <PriceCardWrapper
              onClick={() => {
                if (!(myInfo?.handle == host?.handle && editInfo)) {
                  return;
                }

                const temp = { ...formInput };
                temp.journeys[temp.journeys?.length] = {
                  title: "new journey",
                  link: "",
                  img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                  steps: [
                    {
                      id: 1,
                      title: "title",
                      value: "",
                      link: "",
                      img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                    },
                  ],
                };
                console.log("ccccvvvv", temp);
                setFormInput({
                  ...temp,
                });
              }}
            >
              <Img
                src={
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                }
              />
              <div
                style={{
                  height: 50,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h2>Add Journey +</h2>
              </div>
            </PriceCardWrapper>
          )}
        </CardWrapper>
      </div>
      <Modal
        size={"lg"}
        style={{
          top: 10,
          display: "flex",
          position: openMedia?.doc ? "absolute" : "relative",
          overflowY: "scroll",
        }}
        show={openMedia}
        onHide={() => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              openLeadManage: false,
            };
          });
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: 30,
            right: 30,
            zIndex: 2,
          }}
        >
          <i
            className={`fa fa-times`}
            onClick={() => {
              setOpenMedia(false);
            }}
            style={{
              display: "flex",
              fontSize: 18,
              padding: 10,
              borderRadius: 100,
              backgroundColor: myColors?.brown,
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>

        <video
          controls={true}
          autoPlay
          loop
          muted
          poster={
            openMedia?.img ||
            openMedia?.image ||
            openMedia?.picture ||
            "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          }

          style={{
            // width: "100%",
            maxHeight: window.innerHeight * .5,
            objectFit: 'contain',
            borderRadius: 10,
          }}
        >
          <source src={openMedia?.video} type="video/mp4" />
          <source src={openMedia?.video} type="video/ogg" />
        </video>


        {openMedia?.link && (
          <ButtonL
            style={{
              display: "flex",
              position: "absolute",
              bottom: 20,
              right: 20,
              zIndex: 2,
            }}
            onClick={() => {
              window.open(openMedia?.link);
            }}
          >
            Go to Link
          </ButtonL>
        )}
      </Modal>
    </>
  );
};

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;

  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const SearchWrapper = styled.div`
  max-width: 930px;
  width: 100%;
  border: 1px solid #dfe0e4;
  border-radius: 10px;
  margin-top: -120px;
  position: relative;
  z-index: 10;
  background: #fff;
`;

const SearchBarContainer = styled.div`
  padding: 28px;
`;

const ToggleMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  border-bottom: 1px solid #dfe0e4;
`;

const ToggleButtons = styled.a<{ active: boolean }>`
  text-decoration: none;
  padding: 17px 0;
  color: #000;
  min-width: 50px;
  border-bottom: ${({ active }) => (active ? "2px solid #479d83" : "none")};
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

const EventImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 2px solid #dee2e6;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;

const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "White")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const GuestImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px 14px;
  margin-top: 14px;
`;

const GuestMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px !important;
  width: 140px !important;
  background-color: black;
  border-radius: 5px;
  position: relative;
  video,
  img {
    background-color: black;
    border-radius: 5px;
  }
`;

const GuestMediaHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 12px;
    max-width: 12px;
    height: 12px;
  }
  p {
    font-size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    width: 50%;
    color: white;
    margin: 0;
  }
  div {
    display: flex;
    gap: 4px;
    span {
      padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const GuestMediaParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  max-height: 500px;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: white;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const ButtonF = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PostHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const MenuWrapperPosts = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const DropdownFilterTabsContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

const FilterChild = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    width: fit-content;
  }
`;

const DropdownFilterTabs = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  z-index: 9999;
`;

const ButtonFilterTabs = styled.button`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 8px;
  margin: 0;
  cursor: pointer;

  svg {
    color: #333;
    width: 15px;
    height: 15px;
  }
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonIndustry = styled.button`
  background-color: #ffffff;
  color: #333;
  padding: 2px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-width: 160px;
  gap: 8px;
  font-size: 14px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContentIndustry = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

const DropdownItemIndustry = styled.div<any>`
  padding: 10px;
  cursor: pointer;
  color: #333;
  font-size: 12px;
  &:hover {
    background-color: #f1f1f1;
  }

  ${(props) =>
    props.active &&
    `
    background-color: #ddd; 
    font-weight: bold;
  `}
`;

const TopHeaderWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 5px;
  margin-top: -10px;
`;

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin-top: -20px; */
`;

const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const PriceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  width: 100%;
`;

const Img = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const SupCardHeader = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  padding: 0px 10px;
`;

const CardLabelSub = styled.h3`
  margin: 0;
  font-size: 14px;
  color: gray;
  padding: 0px 10px;
`;

const CardPriceSub = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  padding: 0px 10px;
`;

const CardSupOffer = styled.div`
  margin: 0;
  font-size: 10px;
  color: gray;
  padding: 0px 10px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const HeroBannerWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  background: #96c7b8;
  min-height: 500px;
  @media (max-width: 800px) {
    min-height: 450px;
  }
  @media (max-width: 500px) {
    min-height: 400px;
  }
`;

const HeroBannerChild = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;
