import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Breadcrumb, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { UserContext } from "../App";
import useLocalStorage from "use-local-storage";
import RESTCall from "../restApi";
import { Uploader } from "uploader"; // Installed by "react-uploader".
import { UploadButton } from "react-uploader";

import { gapi } from "gapi-script";
import moment from "moment";
import Calendar from "react-calendar";
import {
  getCalendarEvents,
  getCalendarUsers,
  getCalendarSchedule,
  saveAppointment,
  handleCalendar,
  saveEventDB,
  handleRecurringDate,
  groupByNow,
  getAppointment,
  getRecurringDates,
  saveCalendarUsersDB,
  handleLogin,
  handleRegisterUser,
  handleUpdateUser,
  myColors,
  resizeImage,
} from "../api";
// import { Navbar } from "react-bootstrap";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import AdvancedForms from "./AdvancedForms/AdvancedForms";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import {
  BrowserRouter,
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import Menubar from "./Menubar";
import { EventForm } from "./EventForm";
import { TimeCard } from "./TimeCard";
import { CalendarCard } from "./CalendarCard";
import { HeaderMenu } from "./HeaderMenu";
import { SearchEventForm } from "./SearchEventForm";
import { MyLinks } from "./MyLinks";
import { SkillsForm } from "./SkillsForm";
import { UploadFile } from "./UploadFile";
import axios from "axios";
import ImageUploader from "react-image-upload";
import { MultiSelect } from "react-multi-select-component";
import professions from "src/assets/data/professions";
import SocialMedia from "./SocialMedia";
import { ButtonL } from "./AppIndex";
import { Add, RemoveRedEye } from "@mui/icons-material";
import styled from "@emotion/styled";

// import { CheckinComponent } from "./CheckinComponent";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const SigninComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const [myStore, setMyStore] = useLocalStorage<any>("name", {});
  const navigate = useNavigate();

  const {
    myInfo,
    myInfo: { active = "2" },
    // openSignin
    // comments = [],
  } = myContext;
  const {
    setEventId,
    eventId,
    myEvents,
    myEventSchedule,
    setAllMyEvents,
    setMyEventSchedule,
    setMyEvents,
    resource: externalResource
    // setOpenSignin,
    // formInput,
    // setFormInput,
  } = props;

  const [formInput, setFormInput] = useState<any>({});
  const [newUser, setNewUser] = useState<any>("signin");
  const [editProfile, setEditProfile] = useState<any>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [showPW, setShowPW] = useState<boolean>(false);



  // console.log("fileImageData.current", myInfo);

  const checkInfo = (data: any) => {
    const { email, pw } = data;

    const info = {
      email: email,
      password: pw,
      externalUrl: externalResource
    };
    setMyStore({});
    delete formInput?._id;
    console.log('hereeee', info)
    handleLogin(info).then((res: any) => {
      // console.log('eeeee', res)
      // setAttendees(res)
      setEditProfile(false);
      console.log("xf", res);
      if (res?.token) {
        alert("Welcome.");
        setMyStore({
          ...myStore,
          myInfo: res,
        });
        setFormInput({});
        // setOpenSignin(false);
        navigate('/')

        // setMyContext((existing: any) => {
        //   return {
        //     ...existing,
        //     openSignin: false,
        //   }
        // })
      } else if (res?.message) {
        alert("Please verify your email and PW.  " + (res?.message || ""));
      } else {
        alert("Please verify your email and PW");
      }
    });
  };

  const fileImageName = useRef("");
  const fileImageData = useRef("");
  const fileImageMime = useRef("");
  const [url, setImageURL] = useState(
    "https://media.varehall.com/upload/myUpload"
  );
  const [progress, setProgress] = useState("getUpload");
  const [errorMessage, setErrorMessage] = useState("");

  const registerNewUser = (data: any) => {
    const { name, email, handle, company, title, pw, verifypw, admin, privacy } = data;

    const info: any = {
      name: name,
      fullName: name,
      email: email,
      handle: handle,
      company: company,
      title: title,
      password: pw,
      admin: admin,
      privacy: privacy
    };

    if (!handle) {
      alert("Please add your handle");
      return;
    }
    delete formInput?._id;
    if (!email) {
      alert("Email doesnt exist!");
      return;
    }

    handleLogin({
      email: info?.email,
      password: info?.password,
    }).then(async (res: any) => {
      // setAttendees(res)

      const fileName = `calendar_app_user${fileImageName.current.toLowerCase()}`;

      if (fileImageData.current) {
        setRefreshing(true);
        const imageUploader = await axios
          .post(url, {
            mime: fileImageMime.current,
            name: fileName,
            image: fileImageData.current,
          })
          .then((img: any) => {
            info["img"] = `https://varefiles.s3.us-east-2.amazonaws.com/${img.data && img.data.key ? img.data.key : fileName
              }`;
            return true;
          })
          .catch((err) => {
            return true;
          });
        if (imageUploader)
          RESTCall.axiosQuery(info)
            .then((response) => {
              setRefreshing(false);
              // window.location.reload();
            })
            .catch((error) => {
              console.log("error", error);
              return error;
            });
      }

      setEditProfile(false);
      if (!res?.token) {
        console.log('bbbbb', info)
        if (!(info?.password && info?.password == formInput?.verifypw)) {
          alert('Password must match Verify PW')
          return
        }
        handleRegisterUser({
          ...info,
        }).then((res: any) => {
          checkInfo({
            email: info?.email,
            password: info?.password,
          });

          // setAttendees(res)
          if (res?.message) {
            alert(res?.message ? res?.message : "Completed");
            if (!res?.message?.includes('successfully')) {
              return
            }
            setNewUser("signin");
          } else {
            alert("Please try again");
          }
        });
      } else {
        delete info?.password;
        handleUpdateUser({
          ...info,
        }).then((res: any) => {
          checkInfo({
            email: info?.email,
            password: info?.password,
          });
          setNewUser("signin");
          alert("Existing user updated");
          // console.log("resssss", res);
        });
      }
    });
  };

  const myFile = useRef<any>();
  const [selectedImage, setSelectedImage] = useState<any>(null);
  var openFile = async (file: any) => {
    var input = file.target;
    if (file) {
      var reader = new FileReader();
      reader.onload = async () => {
        var dataURL = reader.result;
        // var output: any = document.getElementById("output");
        // output.src = dataURL ? dataURL : "";
        setSelectedImage(dataURL);

        // console.log('fileImageName', input?.files[0]?.name)

        const successImages: any = reader.result;

        if (!url) {
          setErrorMessage("missing a url to upload to");
          setProgress("uploadError");
          return;
        }
        setProgress("uploading");
        try {
          const name: any = input?.files[0]?.name;

          const imageString = await successImages // resizeImage(successImages, 200, 200);
          const image =
            name &&
              (name.includes(".png") ||
                name.includes(".jpg") ||
                name.includes(".jpeg"))
              ? await [imageString]
              : successImages;

          const parts = successImages.split(";");
          const mime = parts[0].split(":")[1];

          // console.log('successImages', parts, mime)
          fileImageName.current = name;
          fileImageData.current = image[0];
          fileImageMime.current = mime;
          setProgress("uploaded");
        } catch (error) {
          console.log("error in upload", error);
          // @ts-ignore
          setErrorMessage(error.message);
          setProgress("uploadError");
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  // const resizeImage = (base64Str: any, maxWidth = 400, maxHeight = 350) => {
  //   return new Promise(resolve => {
  //     let img = new Image();
  //     img.src = base64Str;
  //     img.onload = () => {
  //       let canvas = document.createElement("canvas");
  //       const MAX_WIDTH = maxWidth;
  //       const MAX_HEIGHT = maxHeight;
  //       let width = img.width;
  //       let height = img.height;

  //       if (width > height) {
  //         if (width > MAX_WIDTH) {
  //           height *= MAX_WIDTH / width;
  //           width = MAX_WIDTH;
  //         }
  //       } else {
  //         if (height > MAX_HEIGHT) {
  //           width *= MAX_HEIGHT / height;
  //           height = MAX_HEIGHT;
  //         }
  //       }
  //       canvas.width = width;
  //       canvas.height = height;
  //       let ctx: any = canvas.getContext("2d");
  //       ctx.drawImage(img, 0, 0, width, height);
  //       resolve(canvas.toDataURL());
  //     };
  //   });
  // };
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone: string) => {
    // Simple phone number regex to validate numbers, dashes, parentheses, and spaces
    const phoneRegex = /^\+?(\d.*){10,}$/; // Minimum 10 digits, can include + for international numbers
    return phoneRegex.test(phone);
  };



  useEffect(() => {
    if (myInfo?.token) setNewUser("register");
  }, [myInfo?.token]);

  useEffect(() => {
    if (myContext?.myInfo?.token)
      setFormInput({
        ...myContext?.myInfo,
      });
  }, [myInfo?.token]);

  console.log("bbbb", myContext?.myInfo, myContext?.myInfo?.handle);

  const imgref = useRef<HTMLInputElement>();
  // if (myInfo?.token) {
  //   return <div style={{
  //     display: 'flex',
  //     flexDirection: 'column',
  //     justifyContent: 'center',
  //     alignItems: 'center'
  //   }}>
  //     <div
  //       onClick={() => {
  //         setOpenSignin(false)
  //         setMyContext((existing: any) => {
  //           return {
  //             ...existing,
  //             host: {},
  //             eventId: '',
  //             myInfo: {}
  //           }
  //         })
  //       }}
  //       className="title"
  //       style={{
  //         alignSelf: 'center',
  //         display: 'flex',
  //         height: 40,
  //         width: 250,
  //         color: 'white',
  //         backgroundColor: myColors.red,
  //         borderRadius: 10,
  //         justifyContent: 'center',
  //         alignItems: 'center'
  //       }}> Sign Out </div>
  //     <div
  //       onClick={() => {
  //         setNewUser('register')
  //       }}
  //       className="title"
  //       style={{
  //         alignSelf: 'center',
  //         display: 'flex',
  //         height: 40,
  //         width: 250,
  //         color: 'white',
  //         backgroundColor: myColors.red,
  //         borderRadius: 10,
  //         justifyContent: 'center',
  //         alignItems: 'center'
  //       }}> Edit Profile </div>
  //   </div>
  // }
  const handleImageClick = () => {
    if (myFile.current) {
      myFile.current.click();
    }
  };

  return (
    <>
      <FormWrapper style={{
        width: 300,
      }}>
        {newUser == "register" && (
          <>
            <ImgWrapper>
              {(myInfo && myInfo.img) || selectedImage ? (
                <img
                  onClick={() => {
                    handleImageClick();
                    console.log("clicked");
                  }}
                  id="output"
                  className="uploader__file"
                  src={selectedImage ? selectedImage : myInfo.img}
                />
              ) : (
                <UploadImg onClick={handleImageClick}>
                  <Add />
                </UploadImg>
              )}
              <input
                ref={myFile}
                className="form-control"
                type="file"
                name={"file"}
                onChange={(e: any) => {
                  openFile(e);
                }}
              />
            </ImgWrapper>

            <div style={{ marginBottom: -10 }}>Name</div>
            <input
              className="form-control"
              type="text"
              name={"name"}
              // value={data?.title}
              onChange={(e: any) => {
                setFormInput({
                  ...formInput,
                  name: e.target?.value,
                });
              }}
              style={{
                width: 300,
                alignSelf: "center",
              }}
              {...(formInput?.name ? { value: formInput?.name } : {})}
              required
              placeholder="Your Name"
            />
          </>
        )}
        {/* email */}
        <div style={{ marginBottom: -10 }}>Email</div>
        <input
          className="form-control"
          type="email"
          name={"email"}
          onBlur={() => {
            if (!validateEmail(formInput?.email)) {
              setEmailError("Please enter a valid email address.");
              return
            } else {
              setEmailError("");
            }
          }}
          onChange={(e: any) => {
            setFormInput({
              ...formInput,
              email: e.target?.value,
            });
          }}
          style={{
            width: 300,
            alignSelf: "center",
          }}
          required
          placeholder="Your Email"
          {...(formInput?.email ? { value: formInput?.email } : {})}
        />
        {emailError && <p style={{ color: "red" }}>{emailError}</p>}


        {newUser == "register" && (
          <>
            {/* phone */}
            <div style={{ marginBottom: -10 }}>Phone# (optional)</div>
            <input
              className="form-control"
              type="phone"
              name={"phone"}
              onBlur={() => {
                if (!validatePhoneNumber(formInput?.phone)) {
                  setPhoneError("Please enter a valid phone number.");
                } else {
                  setPhoneError("");
                }
              }}
              onChange={(e: any) => {
                const phone = e.target.value;
                setFormInput({
                  ...formInput,
                  phone,
                });

              }}
              style={{
                width: 300,
                alignSelf: "center",
              }}
              required
              placeholder="Phone number"
              {...(formInput?.phone ? { value: formInput?.phone } : {})}
            />
            {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}

            {/* handle */}
            <div style={{ marginBottom: -10 }}>Handle (Preferred username)</div>
            <input
              className="form-control"
              type="text"
              name={"handle"}
              // value={''}
              onChange={(e: any) => {
                // console.log("vvvv", e.target?.value);
                setFormInput({
                  ...formInput,
                  handle: e.target?.value,
                });
              }}
              style={{
                width: '100%',
                alignSelf: "center",
                // backgroundColor: 'gray'
              }}
              value={formInput?.handle ? formInput?.handle : ""}
              // {...(formInput?.handle ? { value: formInput?.handle } : {})}
              required
              placeholder="Handle e.g @myusername"
            />

            <div style={{ marginBottom: -10 }}>Industry (Keywords)</div>
            <MultiSelect

              className={"text"}
              value={formInput?.industries || []}
              onChange={(e: any) => {
                // console.log('ddddd', e)
                setFormInput({
                  ...formInput,
                  industries: e,
                })
              }}
              overrideStrings={{ selectSomeItems: "Select Industries" }}
              labelledBy={"Industries"}
              options={professions?.map((res) => {
                return { label: res, value: res };
              })}
              isCreatable={true}
            />

            {/* Short Bio */}
            <div style={{ marginBottom: -10 }}>Bio (Optional)</div>
            <input
              className="form-control"
              type="text"
              name={"title"}
              // value={''}
              onChange={(e: any) => {
                // console.log('vvvv', e.target?.value)
                setFormInput({
                  ...formInput,
                  title: e.target?.value,
                });
              }}
              style={{
                width: '100%',
                alignSelf: "center",
                // backgroundColor: 'gray'
              }}
              value={formInput?.title || ""}
              // {...(formInput?.title ? { value: formInput?.title } : {})}
              placeholder="Short Bio or title (optional)"
            />
            {/* Company name */}
            <div style={{ marginBottom: -10 }}>Company name</div>

            <input
              className="form-control"
              type="text"
              name={"company"}
              // value={''}
              onChange={(e: any) => {
                // console.log('vvvv', e.target?.value)
                setFormInput({
                  ...formInput,
                  company: e.target?.value,
                });
              }}
              style={{
                width: 300,
                alignSelf: "center",
                // backgroundColor: 'gray'
              }}
              value={formInput?.company || ""}
              // placeholder={formInput?.company || 'Company Name (optional)'}
              // {...(formInput?.company ? { value: formInput?.company } : {})}

              placeholder="Company Name (optional)"
            />
          </>
        )}

        {/* Password */}
        <div style={{ marginBottom: -10 }}>Password</div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <input
            className="form-control"
            type={showPW ? "text" : "password"}
            name={"password"}

            // value={data?.title}
            onChange={(e: any) => {
              setFormInput({
                ...formInput,
                pw: e.target?.value,
              });
            }}
            style={{
              width: 300,
              alignSelf: "center",
            }}
            required
            placeholder="Create Password"
          />
          {formInput?.pw?.length > 0 && <RemoveRedEye
            onClick={() => {
              setShowPW(!showPW)
            }}
            style={{
              color: showPW ? 'red' : 'blue',
              marginLeft: 5
            }} />}
        </div>
        {newUser == "register" && (
          <>
            <div style={{ marginBottom: -10 }}>Verify Password</div>

            <input
              className="form-control"
              type="password"
              name={"verifypw"}
              // value={data?.title}
              onChange={(e: any) => {
                setFormInput({
                  ...formInput,
                  verifypw: e.target?.value,
                });
              }}
              style={{
                width: 300,
                alignSelf: "center",
                // backgroundColor: 'gray'
              }}
              // value={formInput?.pw || ''}
              // required
              placeholder="Verify Password"
            />
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SocialMedia />

          <ButtonL
            onClick={() => {
              if (!formInput?.email) {
                alert("Email required");
                return;
              }
              if (newUser == "register" && !formInput?.name) {
                alert("Name required");
                return;
              }

              if (newUser == "signin" || !newUser) {
                checkInfo({ ...formInput });
              } else {
                registerNewUser({ ...formInput });
              }
            }}
            style={{
              height: 40,
              padding: refreshing ? "0px" : "5px 10px",
              backgroundColor: refreshing ? "#57b397" : "#479d83",
            }}
            disabled={refreshing ? true : false}
          >
            {refreshing ? (
              <div>
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: 20, height: 20 }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              "Submit"
            )}
          </ButtonL>
          <a
            className="title"
            onClick={() => {
              // setNewUser('register')
              if (myInfo?.token) {
                setNewUser("signin");
              } else if (newUser == "register") {
                setNewUser("signin");
              } else {
                setNewUser("register");
              }
              if (myInfo?.token) {
                setMyStore({});
                setMyContext({
                  myInfo: {},
                });
              }
            }}
            style={{
              cursor: "pointer",
            }}
          >
            {!newUser || newUser == "signin"
              ? "Register / Update Profile"
              : !myInfo?.token
                ? "signin"
                : "Sign Out"}
          </a>
        </div>
      </FormWrapper>
    </>
  );
};
const UploadImg = styled.div`
 
    background: #a0ddcb;
    width:100px;
    height:100px;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    svg{
      color:white;
      width:40px;
      height:40px;
    }
    input{
      display:none;
    }
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    display: none;
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
`;
const FormWrapper = styled(FormGroup)`
  display: flex;
  min-width: 300px;
  flex-direction: column;
  gap: 10px;
`;

// {
//   refreshing ? (
//     <div>
//       <div className="spinner-border" role="status">
//         <span className="sr-only">Loading...</span>
//       </div>
//     </div>
//   ) : null;
// }
