import styled from "@emotion/styled";
import { Add, ExpandMore, RemoveCircleOutline } from "@mui/icons-material";
import React, { useState } from "react";
import { ButtonL } from "./AppIndex";
import { myColors } from "src/api";

function FormTypeCreationOptionsOutput({
  formEntity,
  handleOptionChange,
  handleOptionEdit,
  index,
  sameAsLable,
  handlePlaceholderChange,
  handleOptionRemove,
  formInput,
  setFormInput,
}: any) {
  const [openMenu, setOpenMenu] = useState(false);
  const [option, setOption] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const options = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const toggleOption = (option: any) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  return (
    <>
      {/* For Radio */}
      {formEntity?.name == "radio" ? (
        <RadioWrapper>
          <label>{formEntity?.label}</label>
          <OptionsRadioWrapper>
            {formEntity?.options?.map((opt: any, index: any) => (
              <RadioOpt key={opt}>
                <input
                  type="radio"
                  checked={opt === option}
                  onChange={() => {
                    setOption(opt);
                    console.log(option);
                  }}
                />
                <span>{opt}</span>
              </RadioOpt>
            ))}
          </OptionsRadioWrapper>
        </RadioWrapper>
      ) : formEntity?.name == "select" ? (
        <RadioWrapper style={{ width: "100%" }}>
          <label>{formEntity?.label}</label>
          <Select name="opt" id="opt">
            {formEntity?.options?.map((opt: any, index: any) => (
              <option value={opt}>{opt}</option>
            ))}
          </Select>
        </RadioWrapper>
      ) : (
        // <OptionWrapper>
        //   <OptionsTitle>
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "row",
        //         width: "100%",
        //         justifyContent: "space-between",
        //       }}
        //     >
        //       <div>
        //         {formEntity.sameAsLabel
        //           ? formEntity.label
        //           : formEntity.placeholder}
        //       </div>
        //       <ExpandMore onClick={() => setOpenMenu(!openMenu)} />
        //     </div>
        //   </OptionsTitle>
        //   {openMenu && (
        //     <Options>
        //       {formEntity?.options?.map(
        //         (formOption: any, optionIndex: number) => {
        //           return (
        //             <div
        //               style={{
        //                 display: "flex",
        //                 flexDirection: "row",
        //                 width: "100%",
        //                 alignItems: "center",
        //                 gap: 10,
        //               }}
        //               onClick={() => {
        //                 setFormInput({
        //                   ...formInput,
        //                   [formEntity.sameAsLabel
        //                     ? formEntity.label
        //                     : formEntity.placeholder]: formOption,
        //                 });
        //               }}
        //             >
        //               <div
        //                 className="form-control"
        //                 style={{
        //                   backgroundColor:
        //                     formInput?.[
        //                       formEntity.sameAsLabel
        //                         ? formEntity.label
        //                         : formEntity.placeholder
        //                     ] == formOption
        //                       ? myColors?.green
        //                       : "",
        //                   color:
        //                     formInput?.[
        //                       formEntity.sameAsLabel
        //                         ? formEntity.label
        //                         : formEntity.placeholder
        //                     ] == formOption
        //                       ? "white"
        //                       : "",
        //                 }}
        //               >
        //                 {formOption}
        //               </div>
        //             </div>
        //           );
        //         }
        //       )}
        //     </Options>
        //   )}
        // </OptionWrapper>
        <MultiSelectWrapper>
          <label>{formEntity?.label}</label>
          <SelectWrapper>
            <SelectLabel onClick={() => setOpenMenu((prev) => !prev)}>
              {selectedOptions.length > 0
                ? selectedOptions.join(", ")
                : formEntity?.label}
            </SelectLabel>
            <ExpandMore onClick={() => setOpenMenu((prev) => !prev)} />
            {openMenu && (
              <SelectDropdown>
                {formEntity?.options?.map((opt: any, index: any) => (
                  <OptionLabel key={opt}>
                    {opt}
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes(opt)}
                      onChange={() => toggleOption(opt)}
                    />
                  </OptionLabel>
                ))}
              </SelectDropdown>
            )}
          </SelectWrapper>
        </MultiSelectWrapper>
      )}
    </>
  );
}

export default FormTypeCreationOptionsOutput;

const OptionWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const OptionAddWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  input {
    outline: none;
    border: 1px solid gray;
  }
`;
const OptionsTitle = styled.div`
  padding: 3px;
  border: 1px solid gray;
  background: white;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  lable {
  }
  input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    &::placeholder {
      color: black;
    }
  }
  button {
    border: none;
    outline: none;
    background: none;
    display: flex;
    justify-content: center;
    max-width: 50px;
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
const Options = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background-color: white;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 10px;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    width: 100%;
  }
  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 16px;
    margin: 0;
    width: fit-content;
    margin-bottom: 10px;
  }
`;
const RadioOpt = styled.label`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const OptionsRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const Select = styled.select`
  border: 1px solid gray;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  outline: none;
  option {
    padding: 10px;
  }
`;
const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  background: white;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  label {
    margin: 0;
    padding-left: 5px;
  }
  svg {
    width: 22px;
    height: auto;
    color: black;
    margin-right: -5px;
  }
`;

const SelectLabel = styled.label`
  margin-bottom: 5px;
  display: block;
  width: 100%;
`;

const SelectDropdown = styled.div`
  border: 1px solid gray;
  border-radius: 4px;
  background: white;
  padding: 8px;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 36px;
`;

const OptionLabel = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px 0px;
  transition: background 0.3s;
  cursor: pointer;
  &:hover {
    background: gray;
  }
  input {
    width: 20px;
    height: auto;
  }
`;
const MultiSelectWrapper = styled.label`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
