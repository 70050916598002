import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const PptViewer = (props: any) => {
  const { pptUrl } = props
  alert(pptUrl)
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAndConvertPpt = async () => {
      setLoading(true);
      try {
        // Fetch the PPT file from the URL
        const response = await axios.get(pptUrl, {
          responseType: 'arraybuffer',
        });

        const fileBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        });

        const formData = new FormData();
        formData.append('file', fileBlob, 'presentation.pptx');

        // Replace with your actual API endpoint for converting PPT to images
        const convertResponse = await axios.post('/api/convert-ppt-to-images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setImages(convertResponse.data.images); // Assuming the API returns an array of image URLs
      } catch (error) {
        console.error('Error converting PPT to images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndConvertPpt();
  }, [pptUrl]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      <div>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Slide ${index + 1}`} style={{ width: '100%', marginBottom: '10px' }} />
        ))}
      </div>
    </div>
  );
};

