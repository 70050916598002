import styled from "@emotion/styled";
import { Close, Person, Search } from "@mui/icons-material";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { getDataDB, getDataWildDB, myColors } from "../api";
import { UserContext } from "../App";
import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { Col, Row } from "react-bootstrap";
const _ = require("lodash");


function MobMenu({
  page,
  eventTab,
  setMobMenu,
  setOpenMenu,
  eventId,
  type = "event",
  mobMenuColor,
}: any) {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },

    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventInfo = {},
    allAccess,
    eventTabs,
    fullScreen = false,
  } = myContext;
  const navigate = useNavigate();
  const [tabval, setTabval] = useState("Apps");
  const [searchInput, setSearchInput] = useState<any>("");
  const [dataRecord, setDataRecord] = useState<any>([]);

  console.log('eventTabs', eventTabs)




  useEffect(
    _.debounce(() => {
      if (!(searchInput?.length > 0)) {
        return;
      }

      // console.log('formFieldxx44', formInput?.title)
      if (tabval == 'Guest') {
        const data = {
          key: "name",
          request: "wildsearch",
          searchVal: searchInput,
          resource: `calendar_users`,
          limit: 400,
        };
        getDataWildDB(data).then((res: any) => {
          // console.log('formFieldxx44', res)
          if (res?.[0]) {
            const result = res
              ?.map((person: any) => {
                return {
                  active: true,
                  link: `https://www.myguestnet.com/host/${person?.handle}`,
                  img: person.img || person?.picture || '',
                  // "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                  title: person.name,
                  name: person.name,
                  handle: person?.handle,
                };
              })
              ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
            setDataRecord([...result]);
          }
        });
      } else if (tabval == 'Enterprise') {
        const data = {
          key: "title",
          request: "wildsearch",
          searchVal: searchInput,
          resource: `calendar_events`,
          limit: 400,
        };

        getDataWildDB(data).then((res: any) => {
          console.log('formFieldxx44', res)
          if (res?.[0]) {
            const result = res
              ?.map((person: any) => {
                return {
                  ...person,
                  active: true,
                  link: `https://www.myguestnet.com/host/${person?.handle}`,
                  img: person.img || person?.picture || '',

                  // title: person.name,
                  // name: person.name,
                  handle: person?.handle,
                };
              })
            // ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
            setDataRecord([...result]);
          }
        });
      } else {
        setDataRecord([])
      }
    }, 2000),
    [searchInput]
  );

  useEffect(() => {
    setDataRecord([])


    if (tabval == 'Events') {
      // alert(5)
      getDataDB({
        query: {
          ssdsdsd: null
        },
        resource: 'calendar_event_schedule'
      }).then(res => {
        if (res?.[0]) {
          const result = res
            ?.map((person: any) => {
              return {
                ...person,
                active: true,
                link: `https://www.myguestnet.com/host/${person?.handle}`,
                img: person.img || person?.picture || '',
                // title: person.name,
                // name: person.name,
                handle: person?.handle,
              };
            })
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
          setDataRecord([...result]);
        } else {
          setDataRecord([])
        }
      })
    }
    else if (tabval == 'Enterprise') {
      getDataDB({
        query: {
          ssdsdsd: null
        },
        resource: 'calendar_events'
      }).then(res => {
        if (res?.[0]) {
          const result = res
            ?.map((person: any) => {
              return {
                ...person,
                active: true,
                link: `https://www.myguestnet.com/host/${person?.handle}`,
                img: person.img || person?.picture || '',

                // title: person.name,
                // name: person.name,
                handle: person?.handle,
              };
            })
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
          setDataRecord([...result]);
        } else {
          setDataRecord([])
        }
      })
    } else if (tabval == 'Guest') {
      getDataDB({
        query: {
          ssdsdsd: null
        },
        resource: 'calendar_users'
      }).then(res => {
        if (res?.[0]) {
          const result = res
            ?.map((person: any) => {
              return {
                ...person,
                active: true,
                link: `https://www.myguestnet.com/host/${person?.handle}`,
                img: person.img || person?.picture || '',

                // title: person.name,
                // name: person.name,
                handle: person?.handle,
              };
            })
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
          setDataRecord([...result]);
        } else {
          setDataRecord([])
        }
      })
    }

  }, [
    tabval
  ])

  const modernColors = [
    "#5f4b8b", // Purple
    "#36a2ac", // Teal
    "#e84a5f", // Coral
    "#ff847c", // Salmon
    "#1abc9c", // Turquoise
    "#2ecc71", // Emerald
    "#3498db", // Peter River
    "#9b59b6", // Amethyst
    "#f39c12", // Orange
    "#e67e22", // Carrot
    "#e74c3c", // Alizarin
    "#2c3e50", // Midnight Blue
    "#16a085", // Green Sea
    "#27ae60", // Nephritis
    "#2980b9", // Belize Hole
    "#8e44ad", // Wisteria
    "#f1c40f", // Sun Flower
    "#d35400", // Pumpkin
    "#c0392b", // Pomegranate
    "#34495e", // Wet Asphalt
  ];
  const tabs = [
    "Apps",
    "Events",
    "Enterprise",
    "Guest"];



  return (
    <Row>
      <Col sm={12} md={12} lg={12}>
        <div style={{
          alignSelf: 'center',
          borderRadius: 5,
          // margin: 30,
          // width: '100%',
          padding: 10,
          height: window.innerHeight * .95,
          backgroundColor: '#96c7b8'
        }}>
          <MenuHeader>
            <h4>Guest Apps</h4>

            <CloseIcon onClick={() => {
              setMobMenu(false)
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  mobMenu: false,
                };
              });
            }}>
              <Close />
            </CloseIcon>
          </MenuHeader>
          {/* <div style={{
            width: '100%',
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            gap: 10,
            padding: 10
          }}>
            {tabs?.map((item, key) => {
              return <Tab
                onClick={() => setTabval(item)}
                key={key}
                style={{
                  color: "black",
                  borderRadius: 10,
                  backgroundColor: item === tabval ? "white" : "transparent",
                }}
              >
                {item}
              </Tab>
            })}
          </div> */}
          <div style={{
            marginTop: 10
          }}>
            <SearchBar>
              <Search />
              <input
                type="Search..."
                // type={"text"}
                onChange={(e: any) => {
                  // console.log("nnnnn", data);
                  setSearchInput(e.target.value);
                }}
                value={searchInput || ""}
                placeholder={"Search..."}
              />
            </SearchBar>
          </div>
          <MenuIconsWrapper style={{
            // backgroundColor: 'white',
            borderRadius: 20,
            // ...(isMobile ? { minWidth: 400 } : {}),
            overflowY: 'auto',
            height: 300,
            padding: 10,
            gap: 10
          }}>
            {/* <div style={{ marginTop: 20 }} /> */}

            {eventTabs?.map((tab: any, index: any) => {
              if (page != 'landing') {
                if ((mobMenuColor && mobMenuColor?.id != tab?.category)) {
                  return;
                }
              }
              if (searchInput?.length > 0 && !JSON.stringify(tab)
                ?.toLowerCase()
                ?.includes(searchInput)) {
                return null
              }
              return (
                <IconWrapper
                  onClick={() => {
                    setOpenMenu(false);
                    navigate(
                      `/${type}/${eventId}/${tab?.subTab?.[0]?.name
                        ? tab.name + "/" + tab?.subTab?.[0]?.name
                        : tab.name
                      }`
                    );
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });
                    setMobMenu(false);
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        mobMenu: false,
                      };
                    });
                  }}
                  key={index}
                  style={{
                    backgroundColor:
                      eventTab === tab.name ? "#479d83" : "transparent",
                  }}
                >
                  {/* Icon */}
                  <i
                    className={tab.icon}
                    style={{
                      color: eventTab === tab.name ? "white" : modernColors[index],
                    }}
                  />
                  <label
                    style={{
                      color: eventTab === tab.name ? "white" : "#000",
                      fontSize: '1.5vh',
                    }}
                  >
                    {tab.title}
                  </label>
                </IconWrapper>
              );
            })
            }

          </MenuIconsWrapper>
        </div>
      </Col>
    </Row>
  );
}

export default MobMenu;
const MenuWrapper = styled.div`
  position: fixed;
  padding-top: 80px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 50px;
  margin-top: 20px;
  background-color: #ebebeb;
`;
const MenuIconsWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: black;
  width: 100%;
  max-width: 756px;
  margin-top: 20px;
  @media (max-width: 756px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 0px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  justify-self: center;

  label {
    font-size: 12px;
    font-weight: bold;
  }
  i {
    font-size: 56px;
  }
`;
const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* 
  position: absolute;
  top: 10px;
  right: 10px; */
`;
const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  h4 {
    margin: 0;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const Tab = styled.div`
  padding: 10px 15px;
  font-size: 14px;
  border: 2px solid white;
`;
const SearchBar = styled.div`
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: white;
  width: 100%;
  svg {
    width: 24px;
    height: 24px;
  }
  input {
    border: none;
    background-color: transparent;
    outline: none;
    width: 100%;
  }
`;
