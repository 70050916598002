import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { ButtonL } from "./AppIndex";
import { ContentCopy, Edit } from "@mui/icons-material";
import { getDataDB, jumpToReleventDiv, saveDataDB } from "src/api";
import { UserContext } from "src/App";
import { useParams } from "react-router-dom";
import { Rating } from "@mui/material";
import { Calendar } from "react-multi-date-picker";
import { EndorsementEdit } from "./EndorsementEdit";
import { ReviewComponent } from "./ReviewComponent";
import ListReviewItems from "./ListReviewItems";
// import QRCode from "react-qr-code";

export const EndorsementReview = (props: any) => {
  const {
    children,
    users,
    eventLink,
    title = '',
    resource,
    company,
    skill
  } = props
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const [allLikes, setAllLikes] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [invitees, setInvitees] = useState<any[]>([]);
  const [dataRecord, setDataRecord] = useState<any>([]);
  const {
    myInfo,
    eventInfo,
    hostTabs
  } = myContext;
  const [formInput, setFormInput] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const params: any = useParams<any>();

  const [editReview, setEditReview] = useState<any>({});
  const [userInfo, setUserInfo] = useState<any>({});



  const toggleModal = () => setIsOpen(!isOpen);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleAddInvitee = () => {
    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      const user = users?.find((user: any) => user.email === email) ?? email;
      const profileImg = user?.profileImg || "";
      setInvitees([...invitees, { email, profileImg }]);
      setEmail("");
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(eventLink).then(() => {
      alert("Link copied to clipboard!");
    });
  };


  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const handleShare = () => {
    console.log(
      "Invited Emails:",
      invitees.map((invitee) => invitee.email)
    );
  };

  useEffect(() => {
    const data = {
      query: {
        eventId: params?.eventId,
      },
      resource: resource,
      check: ["eventId"],
    };
    getDataDB(data).then((res) => {
      setDataRecord(res?.[0] ? res : []);
      setRefreshing(false);
      // setUserInfo({})
    });

  }, [
    refreshing,
    params?.advertId,
  ]);

  return (
    <>
      <ReviewComponent
        {...props}
        editInfo={true}
        eventId={eventId}
        tab={props.tab}
        userInfo={userInfo}
        resource={resource}
        editReview={editReview}
        refreshing={refreshing}
        setRefreshing={setRefreshing}
      />
      <div onClick={toggleModal}>{children}</div>

      <>
        <div>

          <div >
            <ListReviewItems
              dataRecord={dataRecord}
              handleEditRating={(e: any) => {
                setUserInfo(e)
              }}
              {...props}
            />
            {/* {dataRecord.length > 0 ? (
              <InviteesList style={{
                overflowY: 'auto',
                height: 300
              }}>
                {dataRecord.map((andorse: any, index: number) => (
                  <div key={index}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: 'rgba(0,0,0,0.08)',
                      padding: 20,
                      borderRadius: 10

                    }}>



                      <div>
                        <div style={{
                          display: 'flex',
                          backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: 100,
                          width: 250,
                          flexDirection: 'row',
                          alignItems: 'center'

                          // justifyContent: 'space-between'

                        }}>

                          <ProfileImage src={
                            andorse.img || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'
                          } alt="Profile"
                          />
                          <div style={{
                            display: 'flex',
                            //  backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: 100,
                            //  width: '100%',
                            flexDirection: 'column',
                          }}>
                            {andorse?.name?.toUpperCase() || 'Guest'}

                          </div>
                        </div>

                        {andorse.message}
                        <div style={{
                          display: 'flex',
                          textAlign: 'right',
                          color: 'green'
                        }}
                        >

                        </div>
                      </div>

                      <div style={{
                        display: 'flex',
                        justifyContent: 'end'
                      }}>
                        <Rating name="size-small"
                          disabled={andorse?.handle != myInfo?.handle}
                          // defaultValue={andorse?.rating || 0} 
                          value={andorse?.rating || 0}
                          size="small"
                          onChange={(e: any) => {
                            // console.log('vvv', e?.target?.value)

                            const data = {
                              query: {
                                rating: e?.target?.value,
                                ...formInput,
                                eventId: params?.eventId,
                                name: myInfo?.name,
                                handle: myInfo?.handle
                              },
                              resource: resource,
                              check: ["handle", "eventId"],
                              myInfo,
                            };
                            // console.log('formDatacc', data)
                            saveDataDB(data).then((res) => {
                              // setRefreshing(true);
                            });

                          }}
                        />
                      </div>
                      <Edit
                        onClick={() => {
                          jumpToReleventDiv("updateReview");
                          setEditReview(andorse)
                        }}
                      />
                    </div>



                  </div>
                ))}
              </InviteesList>
            ) : null} */}
          </div>
        </div>
      </>

    </>
  );
};
const Modal = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1000;

  div {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
  }
`;

const ModalHead = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid gray;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
`;
const CopyLink = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  color: blue;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  margin: 0;
  svg {
    width: 15px;
    height: 15px;
    object-fit: contain;
    color: blue;
  }
`;
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const InviteesList = styled.div`
  margin-top: 20px;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const InviteeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

const EmailText = styled.span`
  font-size: 16px;
`;

// const CopyLinkButton = styled(FaRegCopy)`
//   cursor: pointer;
//   margin-left: 10px;
// `;
