import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import RESTCall from "../restApi";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import { IconButtonProps } from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { eventTabs, UserContext } from "src/App";
import {
  getGuestLikesDB,
  handleSaveLikes,
  jumpToReleventDiv,
  myColors,
} from "src/api";
import moment from "moment";
import {
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { RR } from "./FunnelComponent";
import { ButtonL } from "./AppIndex";
import { Modal } from "react-bootstrap";
import { PptViewer } from "./MediaSlider/PptViewer";
import ExcelViewer from "./ExcelViewer";
import { useNavigate, useParams } from "react-router-dom";
import { Camera } from "@mui/icons-material";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const ListCard = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { myInfo, allAccess } = myContext;
  const params: any = useParams<any>();
  const {
    setFormInput,
    formInput,
    item,
    refreshing, setRefreshing,
    margin } = props;

  // const {
  //   // img,
  //   // title,
  //   // description,
  //   // video
  // } = item
  // const item: any = props
  const [anchorEl, setAnchorEl] = useState(null);
  const [myLike, setMyLike] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);



  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // console.log("Guest info in the card", item);
    getGuestLikesDB({
      handle: myInfo?.handle,
      itemId: item?.handle,
    }).then((res) => {
      console.log("myLike", myLike);
      setMyLike(res?.[0]?.like ? true : false);
      setRefreshing(false);
    });

  }, [item, myInfo?.handle]);

  const menuInfo = useMemo(() => {

    const data: any = [
      {
        label: 'Share',
        icon: 'share',
        onClick: () => { }
      },
      {
        label: 'Like',
        icon: 'heart',
        bgColor: myLike ? 'orange' : '',
        onClick: () => {
          if (!myInfo?.token) {
            alert("Please login");
            return;
          }
          handleSaveLikes({
            like: !myLike,
            type: "guest",
            ...item,
            myInfo,
          }).then((res) => {
            alert("Saved Like");
            setMyLike(!myLike);
            //setRefreshing(true);
          });
          // console.log('nnnnnn', item)
          // return
        }
      },
      {
        label: 'Funnel',
        icon: 'filter',
        // bgColor: myLike ? 'orange' : '',
        onClick: () => {
          if (!myInfo?.token) {
            alert("Please login");
            return;
          }
          setMyContext((existing: any) => {
            return {
              ...existing,
              funnelItem: item,
            };
          });

        }
      },


    ]

    if (allAccess) {
      data.push({
        label: 'Edit',
        icon: 'edit',
        onClick: () => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              editInfo: true,
            };
          });

          console.log(item, 'mmmmmm')
          setFormInput({
            ...formInput,
            ...item,
            view: "carroussel",
          })
          setTimeout(() => {
            jumpToReleventDiv('formInput')

          }, 100);
        }
      })
    }

    if (eventTab == 'todo' && allAccess) {
      data.push({
        label: 'Status',
        icon: 'flag',
        bgColor: item?.status ? 'orange' : '',
        onClick: async () => {

          const formData = {
            request: "insert",
            query: {
              status: !item?.status,
              title: item?.title,
              handle: myInfo?.handle,
              date: moment().format(),
            },
            resource: "calendar_event_todo",
            check: ["title", "handle"],
          };
          return await RESTCall.axiosQuery(formData)
            .then((response) => {
              setRefreshing(true);

            })
            .catch((error) => {
              return error;
            });
        }
      })
    }

    return data

  }, [
    allAccess,
    myLike,
    item
  ])

  const [openMedia, setOpenMedia] = useState<boolean>(false)

  console.log('itemxxxxxx', item)

  return (<>
    <Modal
      size={'lg'}
      style={{
        top: 10,
        display: 'flex',
        position: 'relative',
        overflowY: 'scroll'
      }}
      show={openMedia}
      onHide={() => {
        setMyContext((existing: any) => {
          return {
            ...existing,
            openLeadManage: false,
          };
        });
      }}
    >
      <div style={{
        display: 'flex',
        position: 'absolute',
        top: 30,
        right: 30,
        zIndex: 2
      }}>
        <i
          className={`fa fa-times`}
          onClick={() => {
            setOpenMedia(false)

          }}
          style={{
            display: "flex",
            fontSize: 18,
            padding: 10,
            borderRadius: 100,
            backgroundColor: myColors?.brown,
            color: "white",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      {item?.doc ?
        <>
          {item?.doc?.includes("pdf") ? (
            <div>
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
              >
                <Viewer fileUrl={item?.doc} />
              </Worker>
            </div>
          ) : item?.doc?.includes("xls") ? (
            <div>
              <ExcelViewer fileUrl={item?.doc} />
            </div>
          ) : (
            <PptViewer pptUrl={item?.doc} />
          )}

        </>
        : <video
          autoPlay
          loop
          muted
          poster={
            item?.img ||
            item?.image ||
            item?.picture ||
            "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          }
          style={{
            width: '100%',
            // height: '100%',
            objectFit: "cover",
            borderRadius: 10,
          }}
        >
          <source src={item?.video} type="video/mp4" />
          <source src={item?.video} type="video/ogg" />
        </video>
      }

      {item?.link && <ButtonL
        style={{
          display: 'flex',
          position: 'absolute',
          bottom: 20,
          right: 20,
          zIndex: 2
        }}
        onClick={() => {
          window.open(item?.link)
        }}
      >
        Go to Link
      </ButtonL>}

    </Modal>
    <div style={{
      display: 'flex', alignItems: 'center', padding: '10px', width: '100%',
      flexDirection: 'row'
    }}
    >
      <video
        onClick={() => {
          setOpenMedia(true)
        }}
        autoPlay
        loop
        muted
        poster={
          item?.img ||
          "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
        }
        style={{
          width: 100,
          height: 100,
          objectFit: "cover",
          borderRadius: 10,
        }}
      >
        <source src={item?.video} type="video/mp4" />
        <source src={item?.video} type="video/ogg" />
      </video>
      <CardContent sx={{ flex: '1 0 auto' }}>

        {item?.name && <Typography component="div" variant="h6" style={{
          ...(item?.status ? { textDecoration: 'line-through' } : {})
        }}>
          {item?.name}
        </Typography>}
        <Typography component="div" variant="h6" style={{
          ...(item?.status ? { textDecoration: 'line-through' } : {})
        }}>
          {item?.title}
        </Typography>
        {item?.description && <Typography variant="subtitle1" color="text.secondary" component="div">
          {item?.description}
        </Typography>}
        <Typography variant="subtitle1" color="text.secondary" component="div">
          {moment(item?.date)?.fromNow()}
        </Typography>
      </CardContent>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuInfo?.map((res: any) => <MenuItem onClick={handleClose}>
          <div>
            {res?.icon == 'share'
              ? <RR
                data={{
                  text: item?.name,
                  url: `https://www.myguestnet.com/host/${item?.handle}`,
                  title: `${item?.name}: ${item?.title}`,
                }}
              >
                <i
                  onClick={() => {
                    res?.onClick()
                  }}
                  className={`fa fa-${res?.icon}`}
                  style={{
                    display: "flex",
                    fontSize: 18,
                    //   backgroundColor: myColors.brown,
                    padding: 10,
                    borderRadius: 100,
                    backgroundColor: "#479d83",
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </RR>
              : <i
                onClick={() => {
                  res?.onClick()
                }}
                className={`fa fa-${res?.icon}`}
                style={{
                  display: "flex",
                  fontSize: 18,
                  //   backgroundColor: myColors.brown,
                  padding: 10,
                  borderRadius: 100,
                  backgroundColor: res?.bgColor || myColors?.brown,
                  color: "white",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />}
            {res?.label}</div>
        </MenuItem>)}
        {/* <MenuItem onClick={handleClose}>Option 2</MenuItem>
        <MenuItem onClick={handleClose}>Option 3</MenuItem> */}
      </Menu>
    </div>
  </>);
};
