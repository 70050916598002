import React, { useContext, useMemo, useState } from "react";
import Select from "react-select";
import { ButtonL } from "./AppIndex";
import { InputAgenda, SkillChildWrapper } from "./MediaSlider/TabListItems";
import { saveDataDB } from "src/api";
import { useParams } from "react-router-dom";
import { UserContext } from "src/App";

const paymentOptions = [
  { value: "stripe", label: "Stripe" },
  { value: "paypal", label: "PayPal" },
  { value: "square", label: "Square" },
  { value: "razorpay", label: "Razorpay" },
];

const PaymentSetupPage = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  const {
    myInfo,
  } = myContext;
  const { setFormInput, formInput, setInputField, resource,
    setRefreshing
  } = props;
  const [myListItems, setMyListItems] = useState<any>([])

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>(null);
  const [accountDetails, setAccountDetails] = useState({ apiKey: "", secretKey: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Handle payment provider change
  const handlePaymentChange = (selectedOption: any) => {
    setSelectedPaymentOption(selectedOption);
    setAccountDetails({ apiKey: "", secretKey: "" }); // Reset account details when switching payment providers
  };

  // Handle input change for account details (API Key, Secret Key)
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setAccountDetails({
      ...accountDetails,
      [name]: value,
    });
  };

  // Handle form submission based on the selected payment provider
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    try {
      if (selectedPaymentOption.value === "stripe") {
        await setupStripePayment(accountDetails.apiKey, 1000, "usd");
        setSuccess("Stripe payment setup successful!");
      } else if (selectedPaymentOption.value === "paypal") {
        const accessToken = await getPayPalAccessToken(accountDetails.apiKey, accountDetails.secretKey);
        await createPayPalPayment(accessToken, "10.00", "USD");
        setSuccess("PayPal payment setup successful!");
      } else if (selectedPaymentOption.value === "square") {
        await createSquarePayment(accountDetails.apiKey, 100, "USD", "some-source-id");
        setSuccess("Square payment setup successful!");
      } else if (selectedPaymentOption.value === "razorpay") {
        await createRazorpayOrder(accountDetails.apiKey, accountDetails.secretKey, 1000, "INR");
        setSuccess("Razorpay payment setup successful!");
      } else {
        setError("Invalid payment provider selected.");
      }
    } catch (err) {
      // @ts-ignore
      setError(`Error setting up payment: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "500px", margin: "0 auto" }}>

      {/* Payment provider selection */}
      <Select
        options={paymentOptions}
        placeholder="Select Payment Provider"
        value={selectedPaymentOption}
        onChange={handlePaymentChange}
      />

      {selectedPaymentOption && (
        <div >
          <div style={{
            marginTop: 20,
            color: 'gray'
          }}>{`Setup ${selectedPaymentOption.label} Account`}</div>

          {/* Input fields for API Key and Secret Key */}
          <div style={{
            margin: "10px 0",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>API Key</div>
            <SkillChildWrapper>
              <InputAgenda onSubmit={(e: any) => { }}>
                <input
                  type="text"
                  name="apiKey"
                  value={accountDetails.apiKey}
                  onChange={handleInputChange}
                  placeholder="Enter your API Key"
                  required
                  style={{ width: "100%", padding: "8px" }}
                />
              </InputAgenda>
            </SkillChildWrapper>
          </div>

          {selectedPaymentOption.value !== "stripe" && (
            <div style={{
              margin: "10px 0",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}>
                Secret Key
              </div>
              <SkillChildWrapper>
                <InputAgenda onSubmit={(e: any) => { }}>
                  <input
                    type="text"
                    name="secretKey"
                    value={accountDetails.secretKey}
                    onChange={handleInputChange}
                    placeholder="Enter your Secret Key"
                    required
                    style={{ width: "100%", padding: "8px" }}
                  />
                </InputAgenda>
              </SkillChildWrapper>
            </div>

          )

          }

          {/* Submit button */}
          <ButtonL
            onClick={(e: any) => {
              if (!myInfo?.handle) {
                alert("Please login to complete this action");
                return
              }
              if (accountDetails?.apiKey && selectedPaymentOption) {
                alert('Please add key')
                return
              }


              saveDataDB({
                query: {
                  option: selectedPaymentOption,
                  handle: myInfo?.handle,
                  eventId: eventId,
                  key: accountDetails.apiKey,
                  secret: accountDetails.secretKey,
                },
                resource: resource,
                check: ["option", "eventId"],
                myInfo,
              }).then((res: any) => {
                alert('Saved')
                // setRefreshing(true);
              });
              // handleSubmit(e) todo use for transactions

            }}
            type="submit"
            disabled={loading}
            style={!accountDetails?.apiKey
              ? {
                background: "lightgray",
                color: "white"
              } : {}
            }
          >
            {loading ? "Setting up..." : "Submit"}
          </ButtonL>
        </div>
      )}

      {/* Display error or success messages */}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
    </div>
  );
};

export default PaymentSetupPage;


const setupStripePayment = async (apiKey: string, amount: number, currency: string) => {
  try {
    const response = await fetch("https://api.stripe.com/v1/payment_intents", {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${apiKey}`, // Stripe secret key
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        amount: amount.toString(),  // Amount in cents (for $10: 1000)
        currency: currency,         // Currency, e.g., "usd"
      }),
    });

    const result = await response.json();
    if (response.ok) {
      console.log("Stripe Payment Intent Created:", result);
      return result;
    } else {
      throw new Error(result.error.message);
    }
  } catch (error) {
    console.error("Error with Stripe Payment:", error);
  }
};


const getPayPalAccessToken = async (clientId: string, secret: string) => {
  const credentials = btoa(`${clientId}:${secret}`);
  try {
    const response = await fetch("https://api-m.sandbox.paypal.com/v1/oauth2/token", {
      method: "POST",
      headers: {
        "Authorization": `Basic ${credentials}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "grant_type=client_credentials",
    });

    const data = await response.json();
    if (response.ok) {
      return data.access_token;
    } else {
      throw new Error(data.error_description);
    }
  } catch (error) {
    console.error("Error fetching PayPal access token:", error);
  }
};

const createPayPalPayment = async (accessToken: string, amount: string, currency: string) => {
  try {
    const response = await fetch("https://api-m.sandbox.paypal.com/v1/payments/payment", {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        intent: "sale",
        payer: {
          payment_method: "paypal",
        },
        transactions: [
          {
            amount: {
              total: amount,
              currency: currency,
            },
          },
        ],
        redirect_urls: {
          return_url: "https://example.com/return",
          cancel_url: "https://example.com/cancel",
        },
      }),
    });

    const result = await response.json();
    if (response.ok) {
      console.log("PayPal Payment Created:", result);
      return result;
    } else {
      throw new Error(result.message);
    }
  } catch (error) {
    console.error("Error with PayPal Payment:", error);
  }
};


const createSquarePayment = async (accessToken: string, amount: number, currency: string, sourceId: string) => {
  try {
    const response = await fetch("https://connect.squareup.com/v2/payments", {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`, // Square access token
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idempotency_key: new Date().getTime().toString(), // Unique key for idempotency
        amount_money: {
          amount: amount * 100, // Amount in cents
          currency: currency,
        },
        source_id: sourceId, // Tokenized card or payment method from Square
      }),
    });

    const result = await response.json();
    if (response.ok) {
      console.log("Square Payment Created:", result);
      return result;
    } else {
      throw new Error(result.errors[0].detail);
    }
  } catch (error) {
    console.error("Error with Square Payment:", error);
  }
};

const createRazorpayOrder = async (apiKey: string, secretKey: string, amount: number, currency: string) => {
  const credentials = btoa(`${apiKey}:${secretKey}`);
  try {
    const response = await fetch("https://api.razorpay.com/v1/orders", {
      method: "POST",
      headers: {
        "Authorization": `Basic ${credentials}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: amount * 100,  // Razorpay expects amount in paisa (for INR)
        currency: currency,    // E.g., "INR"
      }),
    });

    const result = await response.json();
    if (response.ok) {
      console.log("Razorpay Order Created:", result);
      return result;
    } else {
      throw new Error(result.error.description);
    }
  } catch (error) {
    console.error("Error with Razorpay Order:", error);
  }
};

