import styled from "@emotion/styled";
import React from "react";
import { relatedPict } from "src/api";
import { eventTabs } from "src/App";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
function TabSlider({ setOpenTabModal }) {
  const imgs = [...relatedPict];
  const config = {
    loop: true,
    speed: 800,
    // centeredSlides: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",

    spaceBetween: 20,
    pagination: {
      clickable: true,
      el: ".custom-swiper-pagination",
    },

    navigation: {
      nextEl: ".custom-swiper-button-next",
      prevEl: ".custom-swiper-button-prev",
    },

    modules: [Navigation, Pagination, Autoplay],
  };
  return (
    <ModalWrapper>
      <SwiperWrapper>
        <CloseButton onClick={() => setOpenTabModal(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </CloseButton>
        <Swiper {...config}>
          {eventTabs.map((rev, index) => (
            <SwiperSlide key={index} style={{ maxWidth: 300 }}>
              <TebCardContainer
                style={{
                  backgroundColor: "#d64949",
                  backgroundImage: imgs[index],
                }}
              >
                <BadgeWrapper onClick={() => console.log(imgs[index])}>
                  <span />
                  {rev?.name}
                </BadgeWrapper>
                <TabHeadInfo>
                  <h6> {rev?.title}</h6>
                  <p
                    style={{
                      color: "white",
                    }}
                  >
                    {rev?.description}
                  </p>
                </TabHeadInfo>
                <button>
                  Get Started
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                    />
                  </svg>
                </button>
              </TebCardContainer>
            </SwiperSlide>
          ))}
        </Swiper>
        <ArrowsWrapper className="flex gap-3 sm:absolute bottom-0 right-10 sm:justify-normal justify-end">
          <div className="custom-swiper-button-prev">
            <LeftArrow />
          </div>
          <div className="custom-swiper-button-next">
            <RightArrow />
          </div>
        </ArrowsWrapper>
      </SwiperWrapper>
    </ModalWrapper>
  );
}

export default TabSlider;
const RightArrow = () => (
  <ArrowsContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
      />
    </svg>
  </ArrowsContainer>
);

const LeftArrow = () => (
  <ArrowsContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
      />
    </svg>
  </ArrowsContainer>
);

const ArrowsContainer = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 30px;
    height: auto;
    color: white;
  }
  background-color: #479d83;
`;

const BadgeWrapper = styled.div`
  background: #00000039;
  display: flex;
  gap: 10px;
  color: white;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top: 30px;
  left: 30px;
  align-items: center;
  span {
    width: 8px;
    height: 8px;
    background-color: #04ff04;
    border-radius: 50%;
  }
  font-size: 12px;
  color: white;
`;
const TabHeadInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: white;
  align-items: flex-start;
  h6 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    text-align: left;
  }
`;
const TebCardContainer = styled.div`
  max-width: 300px;
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px;
  gap: 40px;
  color: white;

  button {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    margin: 0;
    background-color: none;
    border: none;
    outline: none;
    svg {
      height: auto;
      width: 20px;
      color: white;
    }
  }
`;
const SwiperWrapper = styled.div`
  background: white;
  padding: 50px 20px;
  padding-bottom: 90px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 900px;
  position: relative;
  width: 100%;
`;
const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  background: #00000044;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;
const ArrowsWrapper = styled.div`
  position: absolute;
  bottom: 20px !important;
  right: 20px;
`;
const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  svg {
    width: 30px;
    height: 30px;
    color: black;
  }
  position: absolute;
  top: 10px;
  right: 10px;
`;
