import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import styled from "@emotion/styled";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
  useLayoutEffect,
} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { eventTabs, UserContext } from "../App";
import classes from "./styles";

import { gapi } from "gapi-script";
import moment from "moment";
import Calendar from "react-calendar";
import {
  getCalendarEvents,
  getCalendarUsers,
  getCalendarSchedule,
  saveAppointment,
  handleCalendar,
  saveEventDB,
  handleRecurringDate,
  groupByNow,
  getAppointment,
  getRecurringDates,
  getEventPicsDB,
  myColors,
  saveShareDB,
  getParticipantsDB,
  getDataDB,
} from "../api";
// import { Navbar } from "react-bootstrap";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import AdvancedForms from "./AdvancedForms/AdvancedForms";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import {
  BrowserRouter,
  Link,
  Route,
  useParams,
  BrowserRouter as Router,
  Routes,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Menubar from "./Menubar";
import { EventForm } from "./EventForm";
import { TimeCard } from "./TimeCard";
import { CalendarCard } from "./CalendarCard";
import { HeaderMenu } from "./HeaderMenu";
import { SearchEventForm } from "./SearchEventForm";
import { SkillsForm } from "./SkillsForm";

import useLocalStorage from "use-local-storage";
import { SigninComponent } from "./SigninComponent";
import { ScheduleFormComponent } from "./ScheduleFormComponent";
import MediaSlider from "./MediaSlider";
import PictureSlider from "./PictureSlider";
import { ModalFormComponent } from "./ModalFormComponent";
import { RWebShare } from "react-web-share";
import { LikesComponet } from "./LikesComponet";
import { QRCodeScanner } from "./QRCodeScanner";
import { EventFormModal } from "./EventFormModal";
import SupportersList, { ListMesages } from "./SupportersList";
import runOneSignal from "src/helpers/oneSignal";
import CommentsPlayerCard from "./CommentsPlayerCard";
import AdvertDetailsCard from "./AdvertDetailsCard";
import { Close, List, ShoppingCart } from "@mui/icons-material";
// import { CheckinComponent } from "./CheckinComponent";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const AppIndex = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  const location = useLocation()

  const navigate = useNavigate();

  // const [foo, setFoo] = useQueryState("foo")
  const {
    myInfo = {},
    host,
    allMyEvents,
    cart,
    myEvents,
    action = "checkin",
    indexStop,
    openForm,
    contextFormInput = {},
    contextFormValue = {},
    openFile,
    formProperties = [],
    shares = [],
    openLeadManager = false,
    openLeadManage = false,
    openAccessControl = false,
    openQRManage = false,
    openCreateEvent = false,
    openDirectMessage = false,
    sliderValue = 0,
    eventInfo = {},
    isSidebarOpen = false,
    // openSignin = false,
    openSigninNew = false
    // comments = [],
  } = myContext;
  // const { isSidebarOpen, setIsSidebarOpen } = props;

  const [formInput, setFormInput] = useState<any>({});
  // const [action, setAction] = useState<any>('attend')
  const [myStore, setMyStore] = useLocalStorage<any>("name", {});

  const [myAppointment, setMyAppointment] = useState<any>({});
  const [calendar, setCalendar] = useState<any>([]);
  const [eventPics, setEventPics] = useState<any>([]);
  const [creatEvent, setCreatEvent] = useState<boolean>(false);
  const [openCart, setOpenCart] = useState<boolean>(false);

  // const [openSignin, setOpenSignin] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [openEventForm, setOpenEventForm] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const myFile = useRef<any>();
  const fileImageName = useRef("");
  const fileImageData = useRef("");
  const fileImageMime = useRef("");
  const [url, setImageURL] = useState(
    "https://media.varehall.com/upload/myUpload"
  );

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);


  useEffect(() => {
    // alert(myStore?.myInfo?.token)
    if (!myStore?.myInfo) {
      return;
    }
    const myI = myStore?.myInfo?.token
      ? {
        ...myStore?.myInfo,
        handle: myStore?.myInfo?.handle || myStore?.myInfo?.email,
      }
      : {};

    setMyContext({
      ...myContext,
      myInfo: { ...myI },
    });
  }, [myStore?.myInfo]);

  useEffect(() => {
    if (!myInfo?.handle) {
      return;
    }
    const data = {
      query: {
        handle: myInfo?.handle,
      },
      resource: `calendar_skills_record`,
      check: ["handle"],
    };
    getDataDB(data).then((res) => {
      if (res?.[0] && res?.[0]?.industry) {
        // console.log('vvvv', res?.[0]?.industry)
        setMyContext({
          ...myContext,
          // myInfo: {
          //   ...myInfo,
          //   industry: res?.[0]?.industry,
          // },
        });
      }
    });
  }, [myInfo?.handle]);

  useEffect(() => {
    setMyContext((existing: any) => {
      return {
        ...existing,
        myInfo: { ...myStore?.myInfo },
      };
    });
    setMyStore({
      ...myStore,
      openInfo: true,
    });
  }, []);

  useLayoutEffect(() => {
    if (params?.hostId) {
      getCalendarUsers({
        handle: params?.hostId,
      }).then((res: any) => {
        console.log("searchxxx", params, res);
        if (res?.[0])
          setMyContext((existing: any) => {
            return {
              ...existing,
              host: res?.[0],
            };
          });
      });
    } else if (params?.eventId) {
      // setMyContext((existing: any) => {
      //   return {
      //     ...existing,
      //     eventId: params?.eventId,
      //   };
      // });
    }
  }, []);

  //define all access code
  useEffect(() => {
    const access =
      (myInfo?.handle && myInfo?.handle == eventInfo?.handle) ||
      myInfo?.handle == host?.handle ||
      eventInfo?.handles?.includes(myInfo?.handle) || myInfo?.handle == "@mplaunch";
    // console.log("vvvvxxbbbzzz", host, eventInfo, access);
    setMyContext((existing: any) => {
      return {
        ...existing,
        allAccess: access ? true : false,
      };
    });
  }, [host?.handle, eventInfo?.handle, eventInfo?.handles, myInfo?.handle]);

  useEffect(() => {
    // console.log('eventIdrr', eventId)
    if (eventId) {
      getParticipantsDB({ eventId: eventId }).then((team) => {
        // console.log("vvvvvvteam", team);
        const emails = team?.map((ref: any) => ref.email);
        // setHost(myEvent)
        setMyContext((existing: any) => {
          return {
            ...existing,
            team: team || [],
            teamEmails: emails,
          };
        });
      });
    }
  }, [eventId]);

  useEffect(() => {
    (async () => {
      // console.log('eventIdrr', eventId)
      if (eventId) {
        await getEventPicsDB({ eventId: eventId }).then((attendees: any) => {
          if (attendees?.[0]) {
            if (attendees?.[0]) {
              const info = attendees?.map((res: any, index: number) => {
                return {
                  id: index,
                  image:
                    res?.img ||
                    "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                  name: res?.name || "Private",
                  title: res?.name || "NA",
                  quote: res?.name || "NA",
                  link: res?.link || "", //Todo make this the route to there account
                  handle: res?.handle || "",
                };
              });
              if (info?.[0]) {
                // console.log('attendees', info, data)
                setEventPics([...info]);
              }
            }
          }
        });
      }
    })();
  }, [eventId]);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      runOneSignal().then(() => setInitialized(true));
    }
  });



  return (
    <div style={{}}>
      {/* form upload  */}
      <Modal
        show={openDirectMessage}
        onHide={() => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              openDirectMessage: false,
            };
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>MyGuestNet</Modal.Title>
        </Modal.Header>
        <Modal.Body> Direct Messaging</Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            width: "80% !important",
            // justifyContent: 'center',
            // alignItems: 'center',
          }}
        >
          <ListMesages {...props} directMsgGuest={myInfo} />
        </Modal.Footer>
      </Modal>

      <Modal
        show={openCreateEvent}
        onHide={() => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              openCreateEvent: false,
            };
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>MyGuestNet</Modal.Title>
        </Modal.Header>
        <Modal.Body> Create or Update Enterprise </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EventFormModal {...props} />
        </Modal.Footer>
      </Modal>

      <Modal
        show={openQRManage}
        onHide={() => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              openQRManage: false,
            };
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>MyGuestNet</Modal.Title>
        </Modal.Header>
        <Modal.Body> Scan Contact's QR Code</Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCodeScanner />
        </Modal.Footer>
      </Modal>

      <Modal
        show={openLeadManage}
        onHide={() => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              openLeadManage: false,
            };
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>MyGuestNet</Modal.Title>
        </Modal.Header>
        <Modal.Body> Connect and Manage Contacts</Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LikesComponet {...props} />
        </Modal.Footer>
      </Modal>

      <Modal
        show={
          // myStore?.openInfo
          false
        }
        onHide={() => {
          setMyStore({
            ...myStore,
            openInfo: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>MyGuestNet</Modal.Title>
        </Modal.Header>
        <Modal.Body>MyGuestNet Features</Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <video
            style={{
              // marginTop: -50
              paddingBottom: 20,
            }}
            width={400}
            height={200}
            controls
          // autoPlay
          >
            <source
              src={"https://varefiles.s3.us-east-2.amazonaws.com/guestnet.mp4"}
            />
          </video>
          <ul
            style={{
              padding: 5,
            }}
          >
            <li
              style={{
                padding: 5,
              }}
            >
              1. Share files, presentation, products, survey, etc. with host and
              guest of a given event.
            </li>
            <li
              style={{
                padding: 5,
              }}
            >
              2. See other guest and their profile
            </li>
            <li
              style={{
                padding: 5,
              }}
            >
              3. Chat or leave message for other event guest
            </li>
            <li
              style={{
                padding: 5,
              }}
            >
              4. Engage with the guest without having to join their social media
              immediately
            </li>
            <li
              style={{
                padding: 5,
              }}
            >
              {" "}
              5. Visit other guest linked resources to the event
            </li>
            <li
              style={{
                padding: 5,
              }}
            >
              6. Access event agenda, survey and feedback
            </li>
            <li
              style={{
                padding: 5,
              }}
            >
              {" "}
              7. Start collaborating with other guest on prospects
            </li>
            <li
              style={{
                padding: 5,
              }}
            >
              {" "}
              8. Transition from networking to collaboaration
            </li>
          </ul>
        </Modal.Footer>
      </Modal>

      <Modal

        show={openEventForm}
        // show={true}
        onHide={() => {
          if (host?.name && myEvents?.length > 0 && !eventId) {
            alert("Please select an event before closing");
            return;
          }
          setOpenEventForm(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Guest Networking</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please select an event by person on event name!</Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchEventForm
            setOpenEventForm={setOpenEventForm}
            formInput={formInput}
            setFormInput={setFormInput}
            myInfo={myInfo}
            eventId={eventId}
            host={host}
            // setEventId={setEventId}
            {...props}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={location?.pathname == '/login'}
        onHide={() => {
          navigate('/')
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Guest Networking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!myInfo?.token
            ? "Please sign in to continue!"
            : "What will you like to do?"}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SigninComponent
            {...props}
          // openSignin={openSignin}
          />
        </Modal.Footer>
      </Modal>

      <Header>

        <Link style={{
          textDecoration: "none", color: "white", display: 'flex', flexDirection: 'row',
          gap: 5
        }} to="/">
          <h4>GuestNet</h4>
        </Link>

        <HeaderButtons>
          <CartWrapper>
            <CartIcon onClick={() => setOpenCart(true)}>
              <ShoppingCart />
              {cart?.length > 0 && <span> {cart?.length}</span>}
            </CartIcon>
            {openCart && (
              <CartModal>
                <CloseButton onClick={() => setOpenCart(false)}>
                  <Close />
                </CloseButton>
                <h1>Your Cart</h1>
                <CardItemWrapper>
                  {cart?.length > 0 ? (
                    <CardItemWrapper>
                      {cart?.map((item: any, index: any) => (
                        <CartItem key={index}>
                          <img src={item.img} alt="" />
                          <p>{item.name}</p>
                          <span>{item.price}</span>
                          <button
                            onClick={() =>
                              setMyContext({
                                ...myContext,
                                cart: cart?.filter(
                                  (cartItem: any) => item !== cartItem
                                ),
                              })
                            }
                          >
                            <Close />
                          </button>
                        </CartItem>
                      ))}
                    </CardItemWrapper>
                  ) : (
                    <h2>No Items in Card</h2>
                  )}
                </CardItemWrapper>
                <ButtonL style={{ margin: 0 }}>Proceed to Checkout</ButtonL>
              </CartModal>
            )}
          </CartWrapper>
          <i onClick={() => { }} className={`fa fa-${"bell"}`} />
          {myInfo?.token && (
            <img
              onClick={() => {
                navigate(`/host/${myInfo.handle}/about`);
              }}
              id="output"
              className="uploader__file"
              src={
                myInfo && myInfo.img
                  ? myInfo.img
                  : "https://varefiles.s3.us-east-2.amazonaws.com/calendar_mgn_logo.png"

              }
            />
          )}
          <ButtonL
            onClick={() => {
              // setIsSidebarOpen(!isSidebarOpen)
              let text = "Sure you want to logout?";

              if (!myInfo?.token) {
                navigate(`/login`);

                return;
              }
              if (window.confirm(text) == true) {
                navigate(`/login`);
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    host: {},
                    eventId: "",
                    myInfo: {},
                  };
                });
                setMyStore({
                  ...myStore,
                  myInfo: {},
                });
                text = "You pressed OK!";
              } else {
                text = "You canceled!";
                navigate(`/`);

              }
            }}
          >
            <i className={`fa fa-${myInfo?.token ? "unlock" : "lock"}`} />
            <span>{myInfo?.token ? "" : ""}</span>
          </ButtonL>
        </HeaderButtons>
      </Header>

      <div style={{ overflow: "scroll", height: "100vh" }}>
        <Col
          style={{
            width: "100%",
            justifyContent: "center !important",
            alignItems: "center",
            alignSelf: "center",
            marginBottom: 30,
          }}
        >
          <MediaSlider
            {...props}
            // setOpenSignin={setOpenSignin}
            setOpenEventForm={setOpenEventForm}
            openEventForm={openEventForm}
            setCreatEvent={setCreatEvent}
          />
        </Col>

        <div
          style={{
            paddingBottom: 80,
          }}
        ></div>
      </div>
    </div>
  );
};

export const ShareHost = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  // const [foo, setFoo] = useQueryState("foo")
  const {
    myInfo = {},
    host,
    shares = [],
    // comments = [],
  } = myContext;
  const { id, type } = props;

  return (
    <RWebShare
      data={{
        text: host?.name,
        url: `https://www.myguestnet.com/${type}/${id}`,
        title: `${host?.title}`,
      }}
      onClick={() => {
        // shareItem(real);
      }}
    >
      <i
        onClick={() => {
          if (!myInfo?.handle) {
            alert("Please login to continue");
            return;
          }
          saveShareDB({
            ...host,
            share:
              shares?.filter((rex: any) => {
                return rex.item == host?.handle && rex.handle == myInfo?.handle;
              })?.[0]?.share == "yes"
                ? "no"
                : "yes",
            item: host?.handle,
            handle: myInfo?.handle,
          }).then((res: any) => {
            alert("Saved");
          });
        }}
        className="fa fa-share"
        style={{
          color:
            shares?.filter((rex: any) => {
              return rex.item == host?.handle && rex.handle == myInfo?.handle;
            })?.[0]?.share == "yes"
              ? "red"
              : "rgba(0,0,0,0.3)",
          display: "flex",
          fontSize: 20,
          justifyContent: "center",
          alignItems: "center",
          // paddingRight: 5
        }}
      />
    </RWebShare>
  );
};

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100vw;
  background-color: #1b1b1b;
  padding: 9px 40px;
  h4 {
    font-weight: 400;
    font-size: 24px;
    margin: 0;
  }
  @media (max-width: 755px) {
    padding: 9px 10px;
    h4 {
      font-weight: 600;
      font-size: 18px;
    }
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  i {
    font-size: 12px;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
`;
export const ButtonL = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #479d83;
  padding: 5px 10px;
  gap: 5px;
  border-radius: 5px;
  transition: background 0.3s, opacity 0.3s;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: #57b397;
  }

  span {
    font-size: 14px;
  }
`;
const CartWrapper = styled.div`
  position: relative;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CartModal = styled.div`
  position: absolute;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  z-index: 99999;
  min-width: 400px;
  left: -200px;

  /* Media Query to center on smaller screens */
  @media (max-width: 755px) {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 90%;
  }
`;
const CardItemWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const CartItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  img {
    width: 20%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0;
  }
  p {
    width: 50%;
    font-size: 14px;
    margin: 0;
  }
  span {
    width: 20%;
    font-size: 16px;
    font-weight: 900;
    margin: 0;
  }
  button {
    width: 10%;
    border: none;
    background: transparent;
    outline: none;
    margin: 0;
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background-color: transparent;
  outline: none;
  svg {
    width: 15px;
    height: 15px;
  }
`;
const CartIcon = styled.button`
  position: relative;
  background: transparent;
  border: none;
  color: white;
  svg {
    width: 20px;
    height: 20px;
  }
  span {
    position: absolute;
    top: -2px;
    right: -5px;
    width: 15px;
    height: 15px;
    background: #479d83;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    border-radius: 50%;
  }
`;
