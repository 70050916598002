import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "src/App";



export const LotteryInfoComponent = (props: any) => {
  const {
    names = ['Alice', 'Bob', 'Charlie', 'Dave', 'Eve']
  } = props
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { myInfo, host, hostTabs, eventInfo, myColabs,
    allAccess,
    lottery
  } = myContext;



  const [shuffledNames, setShuffledNames] = useState([]);
  const [winner, setWinner] = useState<any>('');
  const [isShuffling, setIsShuffling] = useState<boolean>(false);

  useEffect(() => {
    setIsShuffling(true)
  }, [

  ])

  const shuffleNames: any = (namesList: any) => {
    let array = [...namesList];
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleShuffle = () => {
    setIsShuffling(true);
    setShuffledNames(shuffleNames(names));
    setWinner('');
    setTimeout(() => {
      setIsShuffling(false);
    }, 3000); // Duration of the animation
  };

  const pickWinner = () => {
    if (shuffledNames.length > 0) {
      const randomIndex = Math.floor(Math.random() * shuffledNames.length);
      setWinner(shuffledNames[randomIndex]);
    }
  };

  useEffect(() => {
    let interval: any;
    if (isShuffling) {
      interval = setInterval(() => {
        setShuffledNames(shuffleNames(names));
      }, 100); // Speed of shuffling
    }
    return () => clearInterval(interval);
  }, [isShuffling, names]);

  return (<>
    <div style={{
      padding: 20
    }}>

      <h1>Raffle Component</h1>
      <div>
        Welcome to MyGuestNet Lottery Likes.  By liking this content you have also been entered into the raffle to each the gift amount donated by the page owner.      </div>
      {allAccess && <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
      }}>
        <button style={{
          backgroundColor: 'rgba(0,0,0.3)',
          color: 'white',
          borderRadius: 5,
          padding: 5
        }} onClick={handleShuffle} disabled={isShuffling}>Shuffle Names</button>
        <button style={{
          backgroundColor: 'rgba(0,0,0.3)',
          color: 'white',
          borderRadius: 5,
          padding: 5

        }} onClick={pickWinner} disabled={isShuffling || shuffledNames.length === 0}>Pick Winner</button>
      </div>}
      <div className="names-box">
        <div
          style={{
            // height: 20,
            display: "flex",
            width: '100%',
            backgroundColor: 'rgb(0,0,0,0.9)',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 10,
            padding: 10,
            fontSize: '1.5em',
            fontWeight: 'bold',
            color: 'red'
          }}>
          Winner Announce End of Event Month
        </div>
        {shuffledNames.map((name, index) => (
          <div key={index} className={`name ${isShuffling ? 'shuffling' : ''}`}
            style={{
              // height: 20,
              display: "flex",
              width: '100%',
              backgroundColor: 'rgb(0,0,0,0.9)',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 10,
              padding: 10,
              fontSize: '1.5em',
              fontWeight: 'bold',
              color: 'white'
            }}>
            {name}
          </div>
        ))}
        <div>
          {winner && (
            <div>
              <h2>Winner:</h2>
              <p>{winner}</p>
            </div>
          )}
        </div>
      </div>

    </div>
  </>
  );
};



const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const IteraterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  span {
    padding: 4px 10px;
    background: white;
    border-radius: 40px;
    border: 1px solid #e4e7eb;
    color: #212121;
  }
`;

