import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  Lock,
  LockOpen,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
// import { IconButton } from 'material-ui';




export const ItemListInfo = (props: any) => {
  const {
    label = 'My tabs',
    info = "",
    data,
    type = "",
    parentItem,
    accesInvites = [],
    // selectedTab,
    // setSelectedTab
  } = props;
  const [formInput, setFormInput] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<any>("");

  // console.log("private", parentItem);

  return (
    <InviteesList
      style={{
        overflowY: "auto",
        height: 300,
      }}
    >
      <div>
        <div>
          <EmailText
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={
                {
                  // backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: 100
                }
              }
            >
              {"My Guest"?.toUpperCase() || "Guest"}
            </div>

            {/* {item.message} */}
            <div
              style={{
                display: "flex",
                textAlign: "right",
                color: "green",
              }}
            >
              Access
            </div>
          </EmailText>
        </div>
      </div>
      {data?.map((item: any, index: number) => {
        return (
          <SwitchData
            {...props}
            item={item}
            index={index}
            accesInvites={accesInvites}
            type={type}
            parentItem={parentItem}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        );
      })}
    </InviteesList>
  );
};

const SwitchData = (props: any) => {
  const {
    item,
    index,
    accesInvites = [],
    type,
    parentItem,
    setSelectedTab,
    selectedTab,
    setDataRecord
  } = props;

  const navigate = useNavigate();
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  const {
    // eventTab,
    myInfo,
    dataRecordAccess = {}
  } = myContext;

  const [switchStatus, setSwitchStatus] = useState<boolean>(false);
  // const [dataRecordAccess, setDataRecord] = useState<any>({});

  // useEffect(() => {
  //   setDataRecord(dataRecordA)
  // }, [
  //   dataRecordA
  // ])

  useEffect(() => {
    console.log("dataRecordAccess-111xxxvv", item);
  }, [
    dataRecordAccess
  ])




  const status = type && dataRecordAccess?.[type]?.[item?.name]?.private;
  // !type && parentItem?.name && dataRecordAccess?.[type]?.[parentItem?.name]?.emails;
  console.log("private", parentItem);

  const emails: any = useMemo(() => {
    return dataRecordAccess?.[type]?.[parentItem?.name]?.emails || []
  }, [
    parentItem?.name,
    type,
    dataRecordAccess?.[type]
  ])

  useEffect(() => {
    if (dataRecordAccess[type]?.[parentItem?.name]
      && dataRecordAccess[type]?.[parentItem?.name]?.length > 0) {
      setSwitchStatus(true)
    }
  }, [
    dataRecordAccess,
    type,
    parentItem
  ])


  return (
    <div key={index}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <EmailText
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            onClick={() => {
              if (!parentItem)
                setSelectedTab(selectedTab == item?.name ? "" : item?.name);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              // backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: 100
            }}
          >
            {item.icon ? (
              <i
                className={item.icon}
                style={{
                  display: "flex",
                  fontSize: 12,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: 5,
                }}
              />
            ) : item?.img ? (
              <ProfileImage
                src={
                  item?.img ||
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                }
                alt="Profile"
              />
            ) : null}
            <span>{item?.name || "Guest"}</span>
          </div>

          {/* {item.message} */}
          <div
            style={{
              display: "flex",
              textAlign: "right",
              color: "green",
            }}
          >
            {parentItem
              && <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Off</Typography>
                <AntSwitch
                  // defaultChecked
                  checked={switchStatus}
                  inputProps={{ "aria-label": "ant design" }}
                  onChange={(e: any) => {
                    const switchVal = e.target?.checked;

                    setSwitchStatus(switchVal);

                    if (!myInfo?.handle) {
                      return;
                    }
                    // console.log("dataRecordAccess-parentItem", emails);

                    const name = parentItem?.name

                    const emailInfo = (switchVal
                      && emails?.includes(item?.email))
                      ? emails
                      : !switchVal
                        && emails?.includes(item?.email)
                        ? emails?.filter((ref: any) => ref != item?.email)
                        : item?.email
                          ? [item?.email]
                          : []

                    const privacyInfo = emailInfo?.length > 0

                    // console.log("dataRecordAccess-111item", item);

                    dataRecordAccess[type] = {
                      ...dataRecordAccess?.[type],
                      [name]: emailInfo

                    }

                    dataRecordAccess['privacy'] = [
                      ...((dataRecordAccess?.['privacy'] && emailInfo?.length > 0)
                        ? dataRecordAccess?.['privacy']?.filter((res: any) => res != item?.tab)
                        // : (dataRecordAccess[type]?.['privacy'] && !(emailInfo?.length > 0))
                        //   ? dataRecordAccess[type]?.['privacy']
                        : dataRecordAccess[item?.tab]?.['privacy']
                          ? dataRecordAccess[item?.tab]?.['privacy']
                          : []
                      ),
                      ...(emailInfo?.length > 0 ? [item?.tab] : [])
                    ]
                    // console.log("dataRecordAccess-111", dataRecordAccess);

                    delete dataRecordAccess?._id

                    const dataTemp = {
                      query: {
                        ...dataRecordAccess,
                        handle: myInfo?.handle,
                      },
                      resource: `calendar_user_access`,
                      check: ["handle"],
                      myInfo
                    };
                    // console.log(
                    //   "dataRecordAccess-222",
                    //   dataTemp,
                    // );
                    saveDataDB(dataTemp)
                      .then((res) => {
                        // setRefreshing(true);
                        const data = {
                          query: {
                            handle: myInfo?.handle,
                          },
                          resource: `calendar_user_access`,
                          check: ["handle"],
                        };
                        getDataDB(data).then((res) => {
                          // console.log("hostTabsxxggg22", res);

                          setMyContext((existing: any) => {
                            return {
                              ...existing,
                              dataRecordAccess: res?.[0] ? res?.[0] : {
                                handle: myInfo?.handle
                              }
                            };
                          })
                        })

                      })
                  }}
                />
                <Typography>On</Typography>
              </Stack>}
            {!parentItem && <>
              {dataRecordAccess?.[type]?.[item?.name]
                ? <Lock /> : <LockOpen />
              }
            </>}
          </div>
        </EmailText>
      </div>
      {item?.name == selectedTab && (
        <ItemListInfo
          dataRecordAccess={dataRecordAccess}
          parentItem={item}
          type={type}
          data={[
            // {
            //   name: 'myemail@h.com',
            //   email: 'myemail@hhh.com',

            // },
            ...accesInvites?.map((email: any) => {
              return {
                tab: item?.name,
                name: email,
                email: email,
              };
            }),
          ]}
        />
      )}
    </div>
  );
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#177ddc",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(255,255,255,.35)",
    boxSizing: "border-box",
  },
}));


const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

const EmailText = styled.span`
  font-size: 16px;
`;

const InviteesList = styled.div`
  margin-top: 20px;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;


