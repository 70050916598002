import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RESTCall from "../restApi";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import DatePicker from "react-multi-date-picker";
import styled from "@emotion/styled";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteAgendaDB,
  deleteLinkDB,
  getAgendaDB,
  getCalendarEvents,
  getDataDB,
  getLinksDB,
  groupByNow,
  myColors,
  saveAgendaDB,
  saveDataDB,
  saveEventDB,
} from "src/api";
import { UserContext } from "src/App";
import { AgendaFormComponent, InputAgenda } from "./AgendaFormComponent";
import { CommentsFormComponent } from "./CommentsFormComponent.";
import { FileFormComponent } from "./FileFormComponent";
import { ModalBox } from "./ModalBox";
import { TaggingComponent } from "./TaggingComponent";
import { ScheduleFormComponent } from "./ScheduleFormComponent";
import { ParticipantFormComponent } from "./ParticipantFormComponent";
import { SurveyFormComponent } from "./SurveyFormComponent";
import axios from "axios";
import { ListItemsMedia } from "./ListItemsMedia";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddSkillSection,
  SkillChildWrapper,
  TabListItems,
} from "./MediaSlider/TabListItems";
import {
  AddCircleOutline,
  EditNote,
  ExpandLess,
  ExpandMore,
  ModeEdit,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { SubTabListItems } from "./MediaSlider/SubTabListItems";
import { EventFormModal } from "./EventFormModal";
import { DetailModal, ModalIntro } from "./MediaSlider";
import { FormTypeItem } from "./FormTypeItem";
import { ButtonL } from "./AppIndex";
import { FormTypeCreate } from "./FormTypeCreate";
import { FormTypeOutput } from "./FormTypeOutput";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const EventFormCreator = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    agendas,
    allMyEvents,
    // eventId,
    data = {},
    host,
    // editInfo,
    eventDetailsTabs = [],
    eventInfo = {},
    editInfo,
  } = myContext;
  const navigate = useNavigate();

  const { selectedEvent, resource } = props;
  const params: any = useParams<any>();

  const [noResult, setNoResult] = useState<boolean>(false);
  const [calType, setCalType] = useState<any>("rec");
  // const [editInfo, setEditInfo] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [tagType, setTagType] = useState<any>("");
  const location = useLocation();
  // const [eventDetails, setEventDetails] = useState<any>("rec");
  const [formField, setFormField] = useState<any>({});
  const [parentFormFields, setParentFormFields] = useState<any>({});

  const [formInput, setFormInput] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>({});
  const [eventForms, setEventForms] = useState<any>({});

  const [formEditType, setFormEditType] = useState<any>("input");
  const [formEntities, setFormEntities] = useState<any>([]);
  const [formOptions, setFormOptions] = useState<any>([
    // {
    //   name: 'Option 1',
    // }
  ]);

  const [formTitle, setFormTitle] = useState<any>();

  const myDateBs: any = [
    [new Date("01-01-24"), new Date("01-04-24")],
    [new Date("01-09-24")],
  ];

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);
  console.log("eventDetailsTab", params);

  const valuetext = (value: number) => {
    return `${value}°C`;
  };

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  useEffect(() => {
    if (host?.handle && eventId) {
      getDataDB({
        query: {
          eventId: eventId,
        },
        resource: resource,
      }).then((res) => {
        // console.log("vvvvvvvgg3333", res);
        const free = res?.filter((reg: any) => reg.eventId == eventId);
        if (free?.[0]) {
          const groupUtil = groupByNow({
            data: free,
            key: "title",
            id: 2,
          });
          setEventForms(groupUtil);
        }
      });
    }
  }, [host?.handle, myInfo?.handle, eventId, refreshing, refreshing]);

  const activeStyle = {
    borderBottom: "solid",
    borderBottomWidth: 2,
    borderBottomColor: "gray",
  };

  const formTypes = [
    {
      name: "input",
      title: "Input Field",
      icon: "fa fa-info",
      placeholder: "",
      label: "",
      sameAsLabel: false,
      type: "",
      isRequired: false,
    },
    {
      name: "radio",
      title: "Radio Select",
      icon: "fa fa-calendar-check",
      placeholder: "",
      label: "",
      sameAsLabel: false,
      type: "",
      options: [],
      isRequired: false,
    },
    {
      name: "select",
      title: "Dropdown Select",
      icon: "fa fa-user-friends",
      placeholder: "",
      label: "",
      sameAsLabel: false,
      type: "",
      options: [],
      isRequired: false,
    },
    {
      name: "multiple",
      title: "Multi Select",
      icon: "fa fa-user-friends",
      placeholder: "",
      label: "",
      sameAsLabel: false,
      type: "",
      options: [],
      isRequired: false,
    },
    {
      name: "file",
      title: "File Upload",
      icon: "fa fa-camera-retro",
      placeholder: "",
      label: "",
      sameAsLabel: false,
      type: "",
      isRequired: false,
    },
    {
      name: "date",
      title: "Date Time",
      icon: "fa fa-camera-retro",
      placeholder: "",
      label: "",
      sameAsLabel: false,
      type: "",
      isRequired: false,
    },
    {
      name: "geolocation",
      title: "Geo Location",
      icon: "fa fa-camera-retro",
      placeholder: "",
      label: "",
      sameAsLabel: false,
      type: "",
      isRequired: false,
    },
  ];

  useEffect(() => {
    setParentFormFields("");
  }, [editInfo]);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            width: "100%",
          }}
        >
          {Object.keys(eventForms)?.map((title: any, index: number) => {
            return (
              <ButtonL
                style={{
                  backgroundColor: formTitle == title ? "red" : "",
                }}
                onClick={() => {
                  setFormTitle(
                    parentFormFields == eventForms[title] ? "" : title
                  );
                  setParentFormFields(
                    parentFormFields == eventForms[title]
                      ? {}
                      : eventForms[title]
                  );
                  setRefreshing(false);
                  setFormInput({});
                }}
              >
                ({index + 1}) {title}
              </ButtonL>
            );
          })}
        </div>

        {editInfo && (
          <>
            <AddSkillSection style={{ marginBottom: 12 }}>
              <h2>Form Title</h2>
              <SkillChildWrapper>
                <InputAgenda onSubmit={(e: any) => { }}>
                  <input
                    type={"text"}
                    onChange={(e: any) => {
                      setFormTitle(e.target?.value);
                    }}
                    value={formTitle || ""}
                    placeholder={"Add title ..."}
                    required
                  />
                </InputAgenda>
              </SkillChildWrapper>
            </AddSkillSection>

            {/*menu list of form types to create an editable field type*/}
            <AddSkillSection>
              <h2>Add Form Entries</h2>
              <EventFormWrapper>
                {formTypes?.map((res, index) => {
                  return (
                    <>
                      {res.icon && (
                        <ButtonL
                          onClick={() => {
                            setFormEntities([...formEntities, res]);
                            // setFormEditType(
                            //   formEditType == res?.name ? "" : res?.name
                            // );
                            setFormField({
                              label: "TBD",
                              placeholder: "TBD",
                              required: false,
                              type: res?.name,
                              name: "TBD",
                              defaultValue: "TBD",
                            });
                          }}
                          style={{
                            whiteSpace: "nowrap",
                            width: "100%",
                            backgroundColor:
                              formEditType == res?.name ? "red" : "",
                            marginBottom: 10,
                            fontSize: 12,
                          }}
                        >
                          <i
                            className={res.icon}
                            style={{
                              display: "flex",
                              fontSize: 12,
                              justifyContent: "space-evenly",
                              // alignItems: "center",
                              paddingRight: 5,
                            }}
                          />
                          <div>{res?.title}</div>
                        </ButtonL>
                      )}
                    </>
                  );
                })}
              </EventFormWrapper>
            </AddSkillSection>
          </>
        )}
      </div>

      {formTitle && (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {formTitle && (
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "2px solid red",
                }}
              >
                <FormTypeCreate
                  {...props}
                  formInput={formInput}
                  setFormInput={setFormInput}
                  formEditType={formEditType}
                  type={formEditType}
                  formEntities={parentFormFields?.[0]?.form || formEntities}
                  setFormEntities={setFormEntities}
                  label={"title"}
                  // formInput={formInput}
                  handleInfo={(e: any) => {
                    // console.log("gggg", e);
                    // setFormInput({
                    //   ...e,
                    // });
                  }}
                />
              </div>
            )}
            {editInfo && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                  gap: 10,
                }}
              >
                <LableSameAsLableWrapper>
                  <LableSameAsLable htmlFor="location">
                    Add Location required
                    <input
                      type="checkbox"
                      name="location"
                      id="location"
                      style={{ width: 30 }}
                    />
                  </LableSameAsLable>
                </LableSameAsLableWrapper>
                <ButtonL
                  style={{
                    width: 100,
                    backgroundColor: "rgba(0,0,0,0.3)",
                  }}
                  onClick={() => {
                    setFormTitle("");
                    setParentFormFields({});
                    setRefreshing(false);
                    setFormInput({});
                    // setFormFields()
                  }}
                >
                  Cancel
                </ButtonL>
                <ButtonL
                  style={{
                    width: 100,
                  }}
                  onClick={() => {
                    console.log("formEntities", formEntities);
                    const data = {
                      query: {
                        ...formInput,
                        title: formTitle,
                        form: formEntities,
                        eventId: eventId || params.eventId,
                        handle: myInfo?.handle,
                      },
                      resource: resource,
                      check: ["handle", "title"],
                      myInfo,
                    };
                    saveDataDB(data).then((res) => {
                      // setRefreshing(true);
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          editInfo: false,
                        };
                      });
                    });
                    // const result = {
                    //   ...(formEditType == "radio" ||
                    //   formEditType == "select" ||
                    //   formEditType == "multiple"
                    //     ? {
                    //         label: formInput?.label,
                    //         placeholder: formInput?.placeholder,
                    //         type: formEditType,
                    //         options: formInput?.options?.filter(
                    //           (res: any) => res.label != null
                    //         ),
                    //       }
                    //     : {
                    //         label: formInput?.label,
                    //         // placeholder: 'TBD',
                    //         required: false,
                    //         type: formEditType,
                    //         name: formInput?.label,
                    //         defaultValue: "",
                    //       }),
                    // };

                    // if (!formTitle) {
                    //   alert("Title is required");
                    //   return;
                    // }

                    // const newFormInput: any = {
                    //   ...result,
                    //   eventId: eventId,
                    //   title: formTitle,
                    //   handle: myInfo?.handle,
                    // };
                    // const data = {
                    //   query: {
                    //     ...newFormInput,
                    //   },
                    //   resource: `calendar_event_form`,
                    //   check: ["handle", "label"],
                    //   myInfo,
                    // };
                    // console.log("FormFields", data);

                    // saveDataDB(data).then((res) => {
                    //   setRefreshing(true);
                    // });
                    // setFormFields()
                  }}
                >
                  Save
                </ButtonL>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          ></div>
        </div>
      )}
      {!editInfo && (
        <div
          style={{
            marginTop: 20,
            backgroundColor: "rgba(0,0,0,0.03)",
            padding: 30,
            border: "2px solid green",
          }}
        >
          <>
            <FormTypeOutput
              handleInfo={() => { }}
              formEditType={formEditType}
              formEntities={parentFormFields?.[0]?.form}
              setFormEntities={setFormEntities}
              formInput={formInput}
              setFormInput={setFormInput}
            />
            {parentFormFields?.[0]?.form && (
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                  gap: 10,
                }}
              >
                <LableSameAsLableWrapper>
                  <LableSameAsLable htmlFor="location">
                    Add Location required
                    <input
                      type="checkbox"
                      name="location"
                      id="location"
                      style={{ width: 30 }}
                    />
                  </LableSameAsLable>
                </LableSameAsLableWrapper>
                <ButtonL
                  style={{
                    width: 100,
                    backgroundColor: "rgba(0,0,0,0.3)",
                  }}
                  onClick={() => {
                    setFormInput({});
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });
                    // setFormFields()
                  }}
                >
                  Cancel
                </ButtonL>
                <ButtonL
                  style={{
                    width: 100,
                  }}
                  onClick={() => {
                    console.log("formEntities", formEntities);
                    const data = {
                      query: {
                        ...formInput,
                        title: formTitle,
                        // form: formEntities,
                        eventId: eventId || params.eventId,
                        handle: myInfo?.handle,
                      },
                      resource: resource + "_report",
                      check: ["handle", "title"],
                      myInfo,
                    };

                    console.log("bbbbbcccc", data);
                    saveDataDB(data).then((res) => {
                      setRefreshing(true);
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          editInfo: false,
                        };
                      });
                    });
                  }}
                >
                  Save
                </ButtonL>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: -40px;
`;
const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;
const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "rgba(0,0,0,0.05)")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const EventFormWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  gap: 10px;
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const LableSameAsLable = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  input {
    max-width: 20px;
    width: 100%;
  }
`;
const LableSameAsLableWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
`;
