import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FaBars, FaTimes } from 'react-icons/fa';
import { UserContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { Add, Search } from '@mui/icons-material';
import colors from 'react-multi-date-picker/plugins/colors';
import { myColors } from 'src/api';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  color: #fff;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const SearchContainer = styled.div`
  flex-grow: 1;
  margin-left: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  min-width: 300px;
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
`;

const SearchInputDiv = styled.div`
  width: 100%;
  min-width: 300px;
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
`;




const MenuIcon = styled.div`
  display: none;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;



const MenuList = styled.ul`
  display: flex;
  list-style: none;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MenuItem = styled.li`
  margin: 0 15px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const MenuLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
`;

const HeaderBar = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    setMode,
    mode,
    children,
    setMobMenu
  } = props
  const {
    myInfo = {},
    host,
    shares = [],
    // comments = [],
  } = myContext;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (<MainadgeContainer style={{
    width: windowWidth,
  }}>
    <Header style={{
      width: '100%'
    }}>
      <Search
        style={{
          color: 'black',
          width: 20,
          marginRight: 5
        }}
        onClick={() => {
          setMobMenu(true)
        }}
      />
      <ScrollableBadgeList
        mode={mode}
        badges={[
          {
            name: 'Enterprise',
            key: 'events',
            onClick: () => {
              setMode("events")
            }
          },
          {
            name: 'Guest',
            key: 'guests',
            onClick: () => {
              setMode("guests")
            }
          },
          {
            name: 'Sponsors',
            key: 'adverts',
            onClick: () => {
              setMode("adverts");
              navigate(`/advert/${"na"}`);
            }
          },
        
        ]} />
      <div style={{
        marginLeft: 5
      }}>
        <Add
          style={{
            color: 'black',
            // width: 5,
          }}
          onClick={() => {
            if (!(myInfo?.handle)) {
              let text = "Please login to continue";
              if (window.confirm(text) == true) {
                navigate(`/login`);
              } else {
                text = "You canceled!";
                return;
              }
              return;
            }
            setMyContext((existing: any) => {
              return {
                ...existing,
                openCreateEvent: true,
              };
            });
          }}
        />
      </div>
    </Header>

  </MainadgeContainer>
  );
};

export default HeaderBar;



const ScrollableBadgeList = ({ badges, mode }: any) => {
  return (
    <BadgeContainer style={{
      width: window.innerWidth * .85
    }}>
      {badges.map((badge: any, index: number) => (
        <Badge key={index}
          onClick={() => badge?.onClick()}
          style={{
            ...(badge?.key?.toLowerCase() == mode ? { backgroundColor: '#3f3d56' } : {})
          }}
        >{badge?.name}</Badge>
      ))}
    </BadgeContainer>
  );
};

const MainadgeContainer = styled.div`
  display: flex;
  white-space: nowrap;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
`;

const BadgeContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
`;

const Badge = styled.div`
  display: inline-block;
  background-color: #479d83;
  color: #fff;
  padding: 8px 12px;
  margin-right: 10px;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #0056b3;
  }

  &:last-child {
    margin-right: 0;
  }
`;
