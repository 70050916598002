import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  Subscriptions,
  Sync,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { AdvertListItems } from "./AdvertListItems";
import { MyTabListItems } from "./MyTabListItems";
// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export const SubscriptionItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, resource } = props;
  const params: any = useParams<any>();
  const { myInfo, host, hostTabs, eventInfo, allAccess } = myContext;

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const valuetext = (value: number) => {
    return `${value}°C`;
  };

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const temp: any = useMemo(() => {
    return {
      eventId: eventId,
      handle: host?.handle,
      offerings: [
        "scheduling",
        "notifications",
        "recurring event planning",
        "lead management",
        "career management",
        "colaboration",
        "idiation",
        "business automation",
        "monitization",
      ],
      subscription: [
        {
          label: "GuestNet",
          title: "Event Platform",
          price: 100,
          frequency: "monthly",
          file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          offerings: {
            scheduling: true,
            notifications: true,
            "recurring event planning": true,
            "lead management": true,
            "career management": true,
            colaboration: true,
            idiation: true,
            "business automation": true,
            monitization: true,
          },
        },
        {
          label: "Calendly",
          title: "Event Platform",
          price: 120,
          frequency: "monthly",
          file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          offerings: {
            scheduling: true,
            notifications: true,
            "recurring event planning": true,
            "lead management": false,
            "career management": false,
            colaboration: false,
            idiation: false,
            "business automation": false,
            monitization: false,
          },
        },
        {
          label: "eventbrite",
          title: "Event Platform",
          price: 150,
          frequency: "monthly",
          file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          offerings: {
            scheduling: false,
            notifications: true,
            "recurring event planning": true,
            "lead management": false,
            "career management": false,
            colaboration: false,
            idiation: false,
            "business automation": false,
            monitization: false,
          },
        },
      ],
    }
  }, [
    eventId,
    host?.handle
  ])

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [service, setService] = useState<any>("");
  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [allDataRecord, setAllDataRecord] = useState<any>([])
  const [dataRecord, setDataRecord] = useState<any>(temp);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  // const [reset, setReset] = useState<boolean>(true);
  const [myRequest, setMyRequest] = useState<any>({});

  const handleReset = useCallback(() => {
    if (!myInfo?.handle) {
      alert("Please login");
      return;
    }
    if (!eventId) {
      return;
    }

    const data = {
      query: {
        ...dataRecord,
        handle: myInfo?.handle,
        eventId: eventId,
      },
      resource: resource,
      check: ["eventId", "title"],
      myInfo,
    };
    saveDataDB(data).then((res) => {
      setRefreshing(true);
    });
  }, [eventId, myInfo?.handle, dataRecord]);

  useEffect(() => {
    // sendEmail({
    //   to: 'deeajibola@gmail.com',
    //   text: 'hello dee',
    //   html: '',
    //   subject: "Hello Modupe"
    // }).then(res => {
    //   console.log('xxxxv', res)
    // })
    if (!myInfo?.handle) {
      return;
    }
    if (!eventId) {
      return;
    }

    const data = {
      query: {
        handle: myInfo?.handle,
        eventId: eventId,
      },
      resource: resource,
      check: ["eventId", "title"],
    };
    getDataDB(data).then((res) => {
      console.log("hkjhkjhkjh", res);
      if (res?.[0]) {
        setDataRecord(res?.[0] ? res?.[0] : {});
        setFormInput(res?.[0]);
        // setService(res?.[0]?.offerings || [])
      } else {
        dataRecord["title"] = eventInfo?.title;
        setFormInput(dataRecord);
      }
      setRefreshing(false);
    });
  }, [
    editInfo,
    eventSubTab,
    eventTab,
    eventInfo,
    myInfo?.handle,
    params.eventId,
    refreshing,
  ]);

  useEffect(() => {
    setFormInput({
      eventId: eventId,
      ...formInput,
      ...dataRecord,
    });
  }, [eventId]);

  useEffect(() => {
    // sendEmail({
    //   to: 'deeajibola@gmail.com',
    //   text: 'hello dee',
    //   html: '',
    //   subject: "Hello Modupe"
    // }).then(res => {
    //   console.log('xxxxv', res)
    // })

    // const data = {
    //   query: {
    //     eventId: eventId,
    //   },
    //   resource: resource,
    //   check: ["eventId", "title"],
    // };
    // getDataDB(data).then((res) => {
    //   // console.log("hostTabsxxcc", res);
    //   if (res?.[0]) {
    //     setDataRecord(res?.[0] ? res?.[0] : {});
    //     setFormInput({});
    //   }
    //   setRefreshing(false);
    // });

    //get guest action
    const dataB = {
      query: {
        eventId: eventId,
        handle: myInfo?.handle,
      },
      resource: resource + "_request",
      check: ["eventId", "title"],
    };
    getDataDB(dataB).then((res) => {
      // console.log("hostTabsxxccbb", res);
      if (res?.[0]) {
        setMyRequest(res?.[0] ? res?.[0] : {});
      }
    });
  }, [
    editInfo,
    eventSubTab,
    myInfo?.handle,
    params.eventId,
    refreshing]);

  useEffect(() => {
    // sendEmail({
    //   to: 'deeajibola@gmail.com',
    //   text: 'hello dee',
    //   html: '',
    //   subject: "Hello Modupe"
    // }).then(res => {
    //   console.log('xxxxv', res)
    // })

    if (!eventId) {
      return;
    }

    const dataAll = {
      query: {
        eventId: eventId,
      },
      resource: resource,
      check: ["eventId"],
    };
    getDataDB(dataAll).then((res) => {
      console.log("hkjhkjhkjh", res);
      if (res?.[0]) {
        setAllDataRecord(res?.[0] ? res : {});
        setDataRecord(res?.[0] ? res : []);
        // setFormInput(res?.[0]);
        // setService(res?.[0]?.offerings || [])
      } else {
        dataRecord["title"] = eventInfo?.title;
        setFormInput(dataRecord);
      }
      setRefreshing(false);
    });
  }, [
    eventInfo,
    eventId,
    refreshing
  ]);

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 5
      }}>
        <ButtonL
          style={{
            backgroundColor: dataRecord?.title != temp?.title ? myColors?.green : '',
            marginBottom: 20
          }}
          onClick={() => {
            // setFormInput({})
            setDataRecord(temp)
            setFormInput(temp)
            // setDataRecord(temp)
            // setRecordKey('')
          }}>
          Default
        </ButtonL>
        {allDataRecord && allDataRecord?.map((res: any, index: number) => {
          // const rep = allDataRecord?.filter((ref: any) => {
          //   return ref?.title == res?.title
          // })
          console.log('vvvvdee', res)
          return <ButtonL
            style={{
              whiteSpace: 'none',
              backgroundColor: dataRecord?.title != res?.title ? myColors?.green : ''
            }}
          >
            <div onClick={() => {
              setFormInput(res)
              // setRecordKey(res?.title)
              setDataRecord(res)
            }}> {res?.title}</div>
            {allAccess && <RemoveCircleOutline
              style={{
                marginLeft: 10
              }}
              onClick={() => {
                let text = "Sure you want to delete?";
                if (window.confirm(text) == true) {
                  deleteDataDB({
                    id: res?._id,
                    resource: resource
                  }).then(res => {
                    setRefreshing(true)
                  })
                  text = "You pressed OK!";
                } else {
                  text = "You canceled!";
                }
              }} />}
          </ButtonL>
        })}
      </div>
      <div>
        {/* <Sync
          onClick={() => {
            handleReset();
          }}
        /> */}
        {editInfo && (
          <>
            <h5
              style={{
                color: "orange",
              }}
            >
              {formInput?.title || ""} Benchmark
            </h5>
            <div>
              <InputAgenda onSubmit={(e: any) => { }}>
                <input
                  type={"text"}
                  onChange={(e: any) =>
                    setFormInput({
                      ...formInput,
                      title: e.target?.value,
                    })
                  }
                  value={formInput?.title || ""}
                  placeholder={"Add title ..."}
                  required
                // readOnly={true}
                />
                <Add
                  onClick={() => {
                    if (!formInput?.title) {
                      alert("You must add title first");
                      return;
                    }
                    const data = {
                      query: {
                        ...formInput,
                        handle: myInfo?.handle,
                        eventId: eventId,
                      },
                      resource: resource,
                      check: ["eventId", "title"],
                      myInfo,
                    };
                    // console.log('formDatasss', data)
                    saveDataDB(data).then((res) => {
                      setUploadNow(true)
                      setTimeout(() => {
                        setRefreshing(true);
                        alert('Saved')
                      }, 200)
                    });
                  }}
                />
              </InputAgenda>
              <div style={{
                backgroundColor: myColors.green,
                borderRadius: 10,
                marginTop: 10,
                padding: 20,
                marginBottom: 10
              }}>
                <h6 style={{
                  color: 'black',
                  fontWeight: 'bold'
                }}>Edit or Add services to your offerings</h6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    overflowX: "auto",
                    padding: 5,
                    gap: 3,
                  }}
                >
                  {formInput?.offerings?.map((res: any) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "rgba(0,0,0,0.09)",
                          whiteSpace: "nowrap",
                          padding: 5,
                          borderRadius: 10,
                        }}
                      >
                        {res}
                        <RemoveCircleOutline
                          onClick={() => {
                            const dataInfo = {
                              ...formInput,
                              offerings: [
                                ...(formInput?.offerings?.filter(
                                  (reg: any) => reg != res
                                ) || []),
                              ],
                            };
                            setFormInput(dataInfo);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <InputAgenda>
                  <input
                    type={"text"}
                    onChange={(e: any) => {
                      if (!formInput?.title) {
                        alert("You must add title first");
                        return;
                      }
                      setService(e.target?.value);
                    }}
                    value={service || ""}
                    placeholder={"Add Service ..."}
                    required
                  />
                  <div
                    onClick={() => {
                      if (!myInfo?.handle) {
                        alert("Please login");
                        return;
                      }

                      if (!formInput?.title) {
                        alert("You must add title first");
                        return;
                      }

                      const dataInfo = {
                        ...formInput,
                        offerings: [
                          service,
                          ...(formInput?.offerings?.filter(
                            (reg: any) => reg != service
                          ) || []),
                        ],
                      };
                      setFormInput(dataInfo);
                      setService("");

                      const data = {
                        query: {
                          ...dataInfo,
                          handle: myInfo?.handle,
                          eventId: eventId,
                        },
                        resource: resource,
                        check: ["eventId", "title"],
                        myInfo,
                      };

                      // console.log("databbb", dataInfo);

                      // console.log("formData444", data);
                      saveDataDB(data).then((res) => {
                        setRefreshing(true);
                      });
                    }}
                  >
                    <Add />
                  </div>
                </InputAgenda>
              </div>
              {/* <input
                  type={"text"}
                  onChange={(e: any) => {
                    if (!formInput?.title) {
                      alert("You must add title first");
                      return;
                    }

                    setService(e.target?.value);
                  }}
                  value={service || ""}
                  placeholder={"Add Service ..."}
                  required
                /> */}
            </div>
          </>
        )}

        <CardWrapper>
          {formInput?.subscription &&
            formInput?.subscription?.map((res: any, index: number) => {
              const { offerings } = res;
              return (
                <PriceCardWrapper>
                  {editInfo ? (
                    <InputAgenda>
                      <MyFileUploader
                        {...props}
                        refreshing={refreshing}
                        setRefreshing={setRefreshing}
                        setFormInput={setFormInput}
                        formInput={formInput}
                        uploadNow={uploadNow}
                        defaultImage={res.img}
                        addFiletoDatabase={(fileName: any) => {
                          if (fileName) {
                            if (formInput?.subscription) {
                              formInput.subscription[index]["img"] = fileName;
                            }
                            // else {
                            //   formInput["img"] = fileName
                            // }

                            const data = {
                              query: {
                                ...formInput,
                                handle: myInfo?.handle,
                                eventId: eventId,
                              },
                              resource: resource,
                              check: ["eventId", "title"],
                              myInfo,
                            };
                            console.log("ffileloaded vv", data);

                            saveDataDB(data).then((res) => {
                              //setRefreshing(true);
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  editInfo: false,
                                };
                              });
                              alert("Saved");
                            });
                          }
                        }}
                        handleSaveImage={(e: any) => {
                          // setUploadNow(true);
                        }}
                        resource={resource}
                      />
                    </InputAgenda>
                  ) : (
                    <Img
                      src={
                        res.img ||
                        "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                      }
                      alt={res.name}
                    />
                  )}
                  {/* <Img src={res.img || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'} alt={res.name} /> */}
                  <div>
                    {myInfo?.handle == host?.handle && editInfo ? (
                      <input
                        style={{
                          width: "100%",
                        }}
                        type={"text"}
                        onChange={(e: any) => {
                          if (!formInput?.title) {
                            alert("You must add title first");
                            return;
                          }

                          const subInfo =
                            e?.target?.value &&
                            formInput?.subscription?.map(
                              (reg: any, i: number) => {
                                if (i == index) {
                                  reg["title"] = e?.target?.value;
                                }
                                return reg;
                              }
                            );
                          // console.log("gggg", subInfo);

                          setFormInput({
                            ...formInput,
                            subscription: subInfo,
                          });
                        }}
                        value={formInput?.subscription?.[index]?.title || ""}
                        placeholder={"Title"}
                        required
                      />
                    ) : (
                      <SupCardHeader>{res?.title || ""}</SupCardHeader>
                    )}
                  </div>
                  <div>
                    {myInfo?.handle == host?.handle && editInfo ? (
                      <input
                        style={{
                          width: "100%",
                        }}
                        type={"text"}
                        onChange={(e: any) => {
                          if (!formInput?.title) {
                            alert("You must add title first");
                            return;
                          }

                          const subInfo =
                            e?.target?.value &&
                            formInput?.subscription?.map(
                              (reg: any, i: number) => {
                                if (i == index) {
                                  reg["label"] = e?.target?.value;
                                }
                                return reg;
                              }
                            );
                          // console.log("gggg", subInfo);

                          setFormInput({
                            ...formInput,
                            subscription: subInfo,
                          });
                        }}
                        value={formInput?.subscription?.[index]?.label || ""}
                        placeholder={"Level Label"}
                        required
                      />
                    ) : (
                      <CardLabelSub>{res?.label || ""}</CardLabelSub>
                    )}
                  </div>
                  {eventTab != "ebenchmark" && (
                    <div>
                      {myInfo?.handle == host?.handle && editInfo ? (
                        <input
                          style={{
                            width: "100%",
                          }}
                          type={"text"}
                          onChange={(e: any) => {
                            if (!formInput?.title) {
                              alert("You must add title first");
                              return;
                            }

                            const subInfo =
                              e?.target?.value &&
                              formInput?.subscription?.map(
                                (reg: any, i: number) => {
                                  if (i == index) {
                                    reg["price"] = e?.target?.value;
                                  }
                                  return reg;
                                }
                              );
                            // console.log('gggg', subInfo)

                            setFormInput({
                              ...formInput,
                              subscription: subInfo,
                            });
                          }}
                          value={formInput?.subscription?.[index]?.price || ""}
                          placeholder={"Level Price"}
                          required
                        />
                      ) : (
                        <CardPriceSub> ${res?.price || ""}</CardPriceSub>
                      )}
                    </div>
                  )}
                  {formInput?.offerings?.map((info: any) => {
                    const offer = info;
                    return (
                      <div
                        onClick={() => {
                          if (!(myInfo?.handle == host?.handle && editInfo)) {
                            return;
                          }
                          res["offerings"] = {
                            ...(res?.offerings || {}),
                            [offer]: res?.offerings?.[offer] ? false : true,
                          };
                          // console.log(
                          //   "vvvxxxxx",
                          //   myInfo?.handle,
                          //   host?.handle,
                          //   res["offerings"]
                          // );
                          const subInfo =
                            res["offerings"] &&
                            formInput?.subscription?.map(
                              (reg: any, i: number) => {
                                if (i == index) {
                                  reg["offerings"] = res["offerings"];
                                }
                                return reg;
                              }
                            );
                          // console.log("gggg", subInfo);

                          setFormInput({
                            ...formInput,
                            subscription: subInfo,
                          });

                          // setFormInput(data)
                        }}
                      >
                        <CardSupOffer>
                          {offer}{" "}
                          {res?.offerings?.[offer] ? (
                            <CheckCircle />
                          ) : (
                            <CheckCircleOutline />
                          )}
                        </CardSupOffer>
                      </div>
                    );
                  })}
                  {
                    <>
                      {!editInfo && eventSubTab == "subscription" ? (
                        <ButtonL
                          onClick={() => {
                            // console.log('formInput', res)

                            const data = {
                              query: {
                                ...res,
                                eventId: eventId,
                                request: !myRequest?.request,
                              },
                              resource: resource + "_request",
                              check: ["handle", "title"],
                              myInfo,
                            };
                            console.log("hostTabsxxccbbbb vv", data);

                            saveDataDB(data).then((res) => {
                              //setRefreshing(true);
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  editInfo: false,
                                };
                              });
                            });
                          }}
                          style={{
                            margin: 0,
                            backgroundColor:
                              myRequest?.label == res?.label &&
                                myRequest?.request
                                ? "orange"
                                : "",
                          }}
                        >
                          {myRequest?.label == res?.label && myRequest?.request
                            ? "Subscribed"
                            : "Subscribe"}
                        </ButtonL>
                      ) : !editInfo && eventSubTab == "ticket" ? (
                        <ButtonL
                          onClick={() => {
                            // console.log('formInput', res)

                            const data = {
                              query: {
                                ...res,
                                eventId: eventId,
                                request: !myRequest?.request,
                              },
                              resource: resource + "_request",
                              check: ["eventId", "title"],
                              myInfo,
                            };
                            console.log("hostTabsxxccbbbb vv", data);

                            saveDataDB(data).then((res) => {
                              //setRefreshing(true);
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  editInfo: false,
                                };
                              });
                            });
                          }}
                          style={{
                            margin: 0,
                            backgroundColor:
                              myRequest?.label == res?.label &&
                                myRequest?.request
                                ? "orange"
                                : "",
                          }}
                        >
                          {myRequest?.label == res?.label && myRequest?.request
                            ? "Ordered"
                            : "Buy"}
                        </ButtonL>
                      ) : !editInfo && eventSubTab == "coupon" ? (
                        <ButtonL
                          style={{
                            margin: 0,
                            backgroundColor:
                              myRequest?.label == res?.label &&
                                myRequest?.request
                                ? "orange"
                                : "",
                          }}
                          onClick={() => {
                            // console.log('formInput', res)

                            const data = {
                              query: {
                                ...res,
                                request: !myRequest?.request,
                                eventId: eventId,
                              },
                              resource: resource + "_request",
                              check: ["eventId", "title"],
                              myInfo,
                            };
                            console.log("hostTabsxxccbbbb vv", data);

                            saveDataDB(data).then((res) => {
                              //setRefreshing(true);
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  editInfo: false,
                                };
                              });
                            });
                          }}
                        >
                          {myRequest?.label == res?.label && myRequest?.request
                            ? "Added"
                            : "Get"}
                        </ButtonL>
                      ) : // eventSubTab == "subscription" ? (
                        //   <ButtonL style={{ margin: 0 }}>Subscribe</ButtonL>
                        // ) : eventSubTab == "ticket" ? (
                        //   <ButtonL style={{ margin: 0 }}>Buy</ButtonL>
                        // ) : eventSubTab == "coupon" ? (
                        //   <ButtonL style={{ margin: 0 }}>Get</ButtonL>
                        // )
                        null}
                      {allAccess && editInfo && (
                        <>
                          <ButtonL
                            style={{ margin: 0 }}
                            onClick={() => {
                              if (!formInput?.title) {
                                alert("You must add title first");
                                return;
                              }
                              const data = {
                                query: {
                                  ...formInput,
                                  handle: myInfo?.handle,
                                  eventId: eventId,
                                },
                                resource: resource,
                                check: ["eventId", "title"],
                                myInfo,
                              };
                              // console.log('formDatasss', data)
                              saveDataDB(data).then((res) => {
                                setUploadNow(true)
                                setTimeout(() => {
                                  setRefreshing(true);
                                  // alert(33)
                                }, 100)
                              });
                            }}
                          >
                            Save
                          </ButtonL>

                          <ButtonL
                            style={{ margin: 0 }}
                            onClick={() => {
                              if (!formInput?.title) {
                                alert("You must add title first");
                                return;
                              }

                              const record = {
                                ...formInput,
                                subscription: [
                                  ...dataRecord?.subscription?.filter(
                                    (ref: any, i: number) => index != i
                                  ),
                                ],
                              };
                              setFormInput(record);
                              const data = {
                                query: {
                                  ...record,
                                  handle: myInfo?.handle,
                                  eventId: eventId,
                                },
                                resource: resource,
                                check: ["eventId", "title"],
                                myInfo,
                              };
                              // console.log('formDatasss', data)
                              saveDataDB(data).then((res) => {
                                setRefreshing(true);
                              });
                            }}
                          >
                            Remove
                          </ButtonL>
                        </>
                      )}
                    </>
                  }
                </PriceCardWrapper>
              );
            })}
          {allAccess && (
            <PriceCardWrapper
              onClick={() => {
                const record = {
                  ...dataRecord,
                  subscription: [
                    ...dataRecord?.subscription,
                    {
                      ...dataRecord?.subscription?.[0],
                    },
                  ],
                };
                const data = {
                  query: {
                    ...record,
                    handle: myInfo?.handle,
                    eventId: eventId,
                  },
                  resource: resource,
                  check: ["eventId", "title"],
                  myInfo,
                };
                setFormInput(data);
                saveDataDB(data).then((res) => {
                  setRefreshing(true);
                });
              }}
            >
              <Img
                src={
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                }
              />
              <div
                style={{
                  height: 50,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h2>Add Card +</h2>
              </div>
            </PriceCardWrapper>
          )}
        </CardWrapper>
      </div>
    </>
  );
};

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;

  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const SearchWrapper = styled.div`
  max-width: 930px;
  width: 100%;
  border: 1px solid #dfe0e4;
  border-radius: 10px;
  margin-top: -120px;
  position: relative;
  z-index: 10;
  background: #fff;
`;

const SearchBarContainer = styled.div`
  padding: 28px;
`;

const ToggleMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  border-bottom: 1px solid #dfe0e4;
`;

const ToggleButtons = styled.a<{ active: boolean }>`
  text-decoration: none;
  padding: 17px 0;
  color: #000;
  min-width: 50px;
  border-bottom: ${({ active }) => (active ? "2px solid #479d83" : "none")};
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

const EventImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 2px solid #dee2e6;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;

const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "White")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const GuestImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px 14px;
  margin-top: 14px;
`;

const GuestMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px !important;
  width: 140px !important;
  background-color: black;
  border-radius: 5px;
  position: relative;
  video,
  img {
    background-color: black;
    border-radius: 5px;
  }
`;

const GuestMediaHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 12px;
    max-width: 12px;
    height: 12px;
  }
  p {
    font-size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    width: 50%;
    color: white;
    margin: 0;
  }
  div {
    display: flex;
    gap: 4px;
    span {
      padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const GuestMediaParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  max-height: 500px;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: white;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const ButtonF = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PostHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const MenuWrapperPosts = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const DropdownFilterTabsContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

const FilterChild = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    width: fit-content;
  }
`;

const DropdownFilterTabs = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  z-index: 9999;
`;

const ButtonFilterTabs = styled.button`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 8px;
  margin: 0;
  cursor: pointer;

  svg {
    color: #333;
    width: 15px;
    height: 15px;
  }
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonIndustry = styled.button`
  background-color: #ffffff;
  color: #333;
  padding: 2px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-width: 160px;
  gap: 8px;
  font-size: 14px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContentIndustry = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

const DropdownItemIndustry = styled.div<any>`
  padding: 10px;
  cursor: pointer;
  color: #333;
  font-size: 12px;
  &:hover {
    background-color: #f1f1f1;
  }

  ${(props) =>
    props.active &&
    `
    background-color: #ddd; 
    font-weight: bold;
  `}
`;

const TopHeaderWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 5px;
  margin-top: -10px;
`;

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin-top: -20px; */
`;

const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const PriceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  max-width: 220px;
`;

const Img = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const SupCardHeader = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  padding: 0px 10px;
`;

const CardLabelSub = styled.h3`
  margin: 0;
  font-size: 14px;
  color: gray;
  padding: 0px 10px;
`;

const CardPriceSub = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  padding: 0px 10px;
`;

const CardSupOffer = styled.div`
  margin: 0;
  font-size: 10px;
  color: gray;
  padding: 0px 10px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const HeroBannerWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  background: #96c7b8;
  min-height: 500px;
  @media (max-width: 800px) {
    min-height: 450px;
  }
  @media (max-width: 500px) {
    min-height: 400px;
  }
`;

const HeroBannerChild = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;
