import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select/creatable";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getDataWildDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  groupByNow,
  handleReaction,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  AssignmentInd,
  Attachment,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  Edit,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterAlt,
  FilterList,
  Folder,
  InsertDriveFile,
  Mic,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  UploadFile,
  X,
} from "@mui/icons-material";
// import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { MyFileUploader } from "./MyFileUploader";

// import { IconButton } from 'material-ui';
const _ = require("lodash");

export const MyTabListItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    type,
    resource,
    selectedItem,
    setSelectedItem
  } = props;
  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const {
    editInfo,
    myInfo,
    host,
    eventId,
    hostTabs,
    allAccess,
    funnel = {} } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  // const resource = useMemo(() => {
  //   const tab = eventTab || hostTab;
  //   const subTab = eventSubTab || hostSubTab;
  //   return `calendar_${params.eventId ? "event" : "host"}${tab ? "_" + tab : ""
  //     }${subTab ? "_" + subTab : ""}`;
  // }, [eventTab, eventSubTab, hostSubTab, hostTab, params?.eventTab]);

  useEffect(() => {
    // if (!dataRecord?.[0]) {
    //   return
    // }
    if (host?.handle || myInfo?.handle) {
      const info = eventId
        ? {
          eventId: eventId,
        }
        : host?.handle
          ? {
            handle: host?.handle,
          } : {}

      if (!(info && Object.keys(info)?.[0])) {
        return
      }
      const data = {
        query: {
          ...(info),

        },
        resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
        check: ["handle"],
      };
      getDataDB(data).then((res) => {
        // console.log("vsss", res);
        setDataRecord(res?.[0] ? res : []);

        setRefreshing(false); //todo-101
        // setFormInput({});
      });
    }
  }, [
    host?.handle,
    eventId,
    eventTab,
    myInfo?.handle,
    refreshing
  ]);

  useEffect(() => {
    // console.log("addType", addType);
  }, [addType]);

  // const [selectedItem, setSelectedItem] = useState<any>('')

  const groupedData = useMemo(() => {
    const result = [
      ...(dataRecord
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title
          };
        }) || []),
    ];

    return groupByNow({
      data: result,
      key: "item",
      id: 2,
    }) || {}
  }, [
    dataRecord
  ]);


  const myData = useMemo(() => {
    // console.log('xxxxxxxx', selectedItem, dataRecord)
    const result = [
      ...(groupedData?.[selectedItem]
        // ?.filter((res: any) => res.item == selectedItem)
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title,
            // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          };
        }) || []),
    ];

    const top = result?.filter((res) => !res.status);
    const bottom = result?.filter((res) => res.status);
    return [...top, ...bottom];
  }, [
    selectedItem,
    groupedData
  ]);




  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    // Define allowed file types for images and videos
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

    if (
      file &&
      (allowedImageTypes.includes(file.type) ||
        allowedVideoTypes.includes(file.type)) &&
      file.size <= 2097152
    ) {
      setFile(file);
      setFormInput({
        ...formInput,
        file,
      });
      setAddType({
        ...addType,
        file,
      });
    } else {
      alert("Please upload an image or video file smaller than 2MB.");
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];

    // Define allowed file types for images and videos
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

    if (
      file &&
      (allowedImageTypes.includes(file.type) ||
        allowedVideoTypes.includes(file.type)) &&
      file.size <= 2097152
    ) {
      setFile(file);
      setFormInput({
        ...formInput,
        file,
      });
      setAddType({
        ...addType,
        file,
      });
    } else {
      alert("Please upload an image or video file smaller than 2MB.");
    }
  };

  const ChangeFile = () => {
    setFile(null);
    setFormInput({
      ...formInput,
      file: null,
    });
    setAddType({
      ...addType,
      file: null,
    });
  };

  const [selected, setSelected] = useState<any>([])
  const [options, setOptions] = useState<any>([
    // ...(formInput?.guest || [])
    // { value: "foo", label: "Foo" },
    // { value: "bar", label: "Bar" },
  ])
  const [inputField, setInputField] = useState<any>('')

  useEffect(
    _.debounce(() => {
      if (!(inputField?.length > 0)) {
        return;
      }
      const data = {
        key: "name",
        request: "wildsearch",
        searchVal: inputField,
        resource: `calendar_users`,
        limit: 400,
      };
      // console.log('formFieldxx44', formInput?.title)

      getDataWildDB(data).then((res: any) => {
        console.log('formFieldxx44', res)
        if (res?.[0]) {
          const result = res
            ?.map((person: any) => {
              return {
                // ...person,
                // active: true,
                // link: `https://www.myguestnet.com/host/${person?.handle}`,
                // img:
                //   person.img ||
                //   "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                // title: person.name,
                // name: person.name,
                label: person.name,
                value: person?.email,
                // handle: person?.handle,
              };
            })
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle);
          setOptions([...options, ...result]);
        }
      });
    }, 500),
    [inputField]
  );

  useEffect(() => {
    if (selected?.[0]) {
      setFormInput({
        ...formInput,
        guest: selected, //todo- send emails
      })
    }
  }, [
    selected
  ])

  useEffect(() => {


    // console.log('formInputxxx', formInput, selectedItem)
  }, [
    selectedItem,
    formInput
  ])

  // console.log('formInput', formInput)

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            width: "100%",
          }}
        >
          <ButtonL
            style={{
              backgroundColor: !(selectedItem && formInput?.item) ? "red" : "",
            }}
            onClick={() => {
              setRefreshing(false);
              setFormInput({});
            }}
          >
            {'Default'}
          </ButtonL>
          {groupedData
            && Object.keys(groupedData)?.[0]
            && Object.keys(groupedData)?.map((item: any, index: number) => {
              if (!item || item == 'undefined') {
                return
              }
              return (
                <ButtonL
                  style={{
                    backgroundColor: selectedItem == item ? "red" : "",
                    whiteSpace: 'none',
                    textWrap: 'nowrap'
                  }}
                  onClick={() => {
                    setSelectedItem(item)
                    // setFormInput(groupedData[item]);
                    setRefreshing(false);
                  }}
                >
                  {item}
                </ButtonL>
              );
            })}
        </div>


        {((editInfo && (selectedItem || formInput?.item)) || editInfo) && (
          <>
            <EditSkillsModalWrapper>
              <AddSkillSection>
                <h2>Add Item</h2>
                <SkillChildWrapper>
                  <InputAgenda onSubmit={(e: any) => { }}>
                    <input
                      type={"text"}
                      onChange={(e: any) => {
                        setFormInput({
                          ...formInput,
                          item: e.target?.value,
                        })
                      }}
                      value={formInput?.item || selectedItem || ""}
                      placeholder={"Add Item..."}
                      required
                    />
                  </InputAgenda>
                </SkillChildWrapper>
              </AddSkillSection>

              {selectedItem && (
                <>
                  <AddSkillSection>
                    <h2>Add Title</h2>
                    <SkillChildWrapper>
                      <InputAgenda onSubmit={(e: any) => { }}>
                        <input
                          type={"text"}
                          onChange={(e: any) =>
                            setFormInput({
                              ...formInput,
                              title: e.target?.value,
                            })
                          }
                          value={formInput?.title || ""}
                          placeholder={"Add title ..."}
                          required
                        />
                      </InputAgenda>
                    </SkillChildWrapper>
                  </AddSkillSection>
                  <AddSkillSection>
                    <h2>Speech to Audio Notes</h2>
                    <SkillChildWrapper>
                      <InputAgenda>
                        <Mic />
                      </InputAgenda>
                    </SkillChildWrapper>
                    <h2>Upload Image/Video</h2>
                    {file ? (
                      <UploadedResume>
                        <div>
                          <InsertDriveFile />
                          <p>{file?.name}</p>
                        </div>
                        <div>
                          <button onClick={ChangeFile}>Change File</button>
                        </div>
                      </UploadedResume>
                    ) : (
                      <UploadResumeFile
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                        htmlFor="fileInput"
                      >
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <UploadFile />
                        <h3>Click Here or Drop Your Image/Video In here</h3>
                        <p
                          style={{
                            display: "flex",
                            gap: 3,
                          }}
                        >
                          Media in <b> .jpg, .png, .mp4, etc </b> only.{" "}
                          <b>Max 120mb</b> file size
                        </p>
                      </UploadResumeFile>
                    )}
                  </AddSkillSection>
                  <AddSkillSection>
                    <h2>Add Link</h2>
                    <SkillChildWrapper>
                      <InputAgenda>
                        <input
                          type={"text"}
                          onChange={(e: any) =>
                            setFormInput({
                              ...formInput,
                              link: e.target?.value,
                            })
                          }
                          value={formInput?.link || ""}
                          placeholder={"Add Link ..."}
                          required
                        />
                      </InputAgenda>
                    </SkillChildWrapper>
                  </AddSkillSection>
                  <AddSkillSection>
                    <h2>Due Date</h2>
                    <InputAgenda onSubmit={(e: any) => { }}>
                      <input
                        type={"datetime-local"}
                        onChange={(e: any) => {
                          setFormInput({
                            ...formInput,
                            datetime: e.target?.value,
                          });
                        }}
                        value={formInput?.datetime || ""}
                        placeholder={"Add title ..."}
                        required
                      />
                    </InputAgenda>
                  </AddSkillSection>

                  <AddSkillSection>
                    <h2>Add Viewers (optional)</h2>
                    <Select
                      styles={{
                        control: styles => ({ ...styles, width: 400 }),
                      }}
                      onInputChange={(e: any) => {
                        // console.log('personinfobb', e)
                        setInputField(e)
                      }}
                      defaultValue={formInput?.guest || selected
                        ? [...(formInput?.guest || []), ...selected]
                        : [{
                          label: 'Anyone',
                          value: 'Anyone'
                        }]}
                      onChange={(e: any) => {
                        console.log('personinfo', e)
                        setSelected(e);
                      }}
                      isMulti
                      isClearable
                      options={options}
                      placeholder="Select or Invite by Email"
                      classNamePrefix="selectform"
                    />
                  </AddSkillSection>
                </>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonL
                  disabled={
                    formInput?.title
                      ? // addType?.file ||
                      // addType?.link ||
                      // formInput?.file ||
                      // formInput?.link
                      false
                      : true
                  }
                  style={{ margin: 0, backgroundColor: "gray" }}
                  onClick={() => {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });
                  }}
                >
                  Cancel
                </ButtonL>
                <ButtonL
                  disabled={
                    formInput?.title
                      ? // addType?.file ||
                      // addType?.link ||
                      // formInput?.file ||
                      // formInput?.link
                      false
                      : true
                  }
                  style={{ margin: 0, marginLeft: 10 }}
                  onClick={() => {
                    const data = {
                      query: {
                        ...formInput,
                        handle: myInfo?.handle,
                        eventId: eventId,
                      },
                      resource: resource, // `calendar_${params.eventId ? "event" : "host"
                      //   }_${eventTab}`,
                      check: ["handle", "title"],
                      myInfo,
                    };
                    // console.log('formData', data)
                    saveDataDB(data).then((res) => {
                      //setRefreshing(true);
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          editInfo: false,
                        };
                      });
                    });
                  }}
                >
                  Add
                </ButtonL>
              </div>
            </EditSkillsModalWrapper>
          </>
        )}

        <div
          style={{
            paddingTop: 50,
            paddingBottom: 20,
          }}
        >
          {(formInput?.title ||
            myData?.length > 0) && (
              <InputAgenda>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: 300,
                  }}
                >
                  <input
                    onClick={() => {
                      setFormInput({
                        ...formInput,
                        view: "list",
                      });
                    }}
                    type="radio"
                    id="view1"
                    name="view"
                    value="list"
                    {...(formInput?.view == "list"
                      ? { checked: true }
                      : {})}
                  />
                  <label>List</label>
                  {/* <br />
                  <input
                    onClick={() => {
                      setFormInput({
                        ...formInput,
                        view: "card",
                      });
                    }}
                    type="radio"
                    id="view2"
                    name="view"
                    value="card"
                  />
                  <label>Cards</label> */}
                  <br />
                  <input
                    onClick={() => {
                      setFormInput({
                        ...formInput,
                        view: "carroussel",
                      });
                    }}
                    type="radio"
                    id="view2"
                    name="view"
                    value="card"
                  />
                  <label>Carroussel</label>
                  <br />
                </form>
              </InputAgenda>
            )}
          {(formInput?.view == "carroussel" || !formInput?.view) ? (
            <div
              style={{
                marginTop: 20,
              }}
            >
              <CarrousselCard
                onViewClick={(item: any) => {
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      action: false,
                      storyResource: {
                        ...props?.tab,
                        myNews: [item]

                      }
                    };
                  });
                }}
                isFunnel={false}
                people={myData}
                isSmall={true}
              >
                {(item: any) => {
                  return (
                    <>
                      {allAccess && <div>
                        <Edit
                          style={{
                            color: 'white'
                          }}
                          onClick={() => {
                            console.log(item, 'mmmmmm')


                            // setMyContext((existing: any) => {
                            //   return {
                            //     ...existing,
                            //     editInfo: true,
                            //   };
                            // });
                            setFormInput({
                              ...item
                            })
                            // setSelectedItem(item?.item)

                          }}
                        />
                      </div>}
                      {hostTab == "todo" || eventTab == "todo" ? (
                        <div
                          style={{
                            gap: 15,
                            display: "flex",
                            border: "1px solid #dee2e6",
                            padding: 5,
                            borderRadius: 5,
                            backgroundColor: "rgba(0,0,0,0.4)",
                            flexDirection: "row",
                            height: 40,
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              color: "white",
                            }}
                          >
                            {item?.datetime &&
                              moment(item?.datetime)?.fromNow()}
                          </div>
                          <FilterAlt
                            onClick={() => {
                              let text = "Add to lead funnel";
                              if (window.confirm(text) == true) {
                                text = "You pressed OK!";

                                if (funnel?.[item?.handle]) {
                                  deleteDataDB({
                                    id: funnel?.[item?.handle]?.[0]?._id,
                                    resource: "calendar_lead_funnel",
                                  });
                                  return;
                                }
                                const data = {
                                  query: {
                                    itemId: item?._id,
                                    item: item,
                                    handle: myInfo?.handle,
                                  },
                                  resource: `calendar_lead_funnel`,
                                  check: ["handle", "itemId"],
                                  myInfo,
                                };
                                // console.log("FormFields-person", data);
                                saveDataDB(data).then((ref) => {
                                  setRefreshing(true);
                                });

                                return;
                              } else {
                                text = "You canceled!";
                                // setOpenSignin(true)
                                return;
                              }
                            }}
                            style={{
                              display: "flex",
                              left: 10,
                              top: 10,
                              // color: 'orange',
                              // position: "absolute",
                              alignItems: "center",
                              color: !funnel?.[item?.handle]
                                ? "gray"
                                : "orange",
                            }}
                          />
                          <div>
                            <div
                              style={{
                                // backgroundColor: 'black',
                                borderRadius: 100,
                                // borderStyle: 'solid',
                              }}
                              onClick={() => {
                                // console.log("xxxxvvv", e.target.value);
                                const data = {
                                  query: {
                                    title: item?.title,
                                    status: !item?.status,
                                    handle: myInfo?.handle,
                                  },
                                  resource: resource, // `calendar_${params.eventId ? "event" : "host"
                                  check: ["handle", "title"],
                                  myInfo,
                                };
                                // console.log('formData', data)
                                saveDataDB(data).then((res) => {
                                  //setRefreshing(true);
                                });
                              }}
                            >
                              {item?.status ? (
                                <CheckBox
                                  style={{
                                    borderStyle: "none",
                                    color: "white",
                                  }}
                                />
                              ) : (
                                <CheckBoxOutlineBlank
                                  style={{
                                    borderStyle: "none",
                                    color: "white",
                                  }}
                                />
                              )}
                            </div>
                            {/* <label htmlFor="no">Done</label> */}
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                }}
              </CarrousselCard>
            </div>
          ) : (
            <ListItems
              editButton={allAccess ? true : false}
              {...props}
              display={"title"}
              source={
                //"files"
                eventTab
              }
              // model={hostTabs['model']}
              formInput={formInput}
              items={myData || []}
              // setTagType={setTagType}
              // tagType={tagType}
              handleSetItem={(item: any) => {
                // setEditFile({
                //   ...item,
                // });
                // alert(item?._id)
                setFormInput({
                  ...formInput,
                  ...item,
                });
              }}
              handleDeleteItem={(item: any) => {
                deleteDataDB({
                  id: item?._id,
                  resource: resource,
                }).then((rep: any) => {
                  alert("Deleted");
                  //setRefreshing(true);
                });
              }}
            >
              {(item: any) => {
                return (
                  <>
                    {hostTab == "todo" || eventTab == "todo" ? (
                      <div
                        style={{
                          border: "1px solid #dee2e6",
                          padding: 5,
                          borderRadius: 5,
                        }}
                      >
                        <div
                          style={{
                            gap: 15,
                          }}
                        >
                          <div>
                            {item?.datetime &&
                              moment(item?.datetime)?.fromNow()}
                          </div>
                          <FilterAlt
                            onClick={() => {
                              let text = "Add to lead funnel";
                              if (window.confirm(text) == true) {
                                text = "You pressed OK!";

                                if (funnel?.[item?.handle]) {
                                  deleteDataDB({
                                    id: funnel?.[item?.handle]?.[0]?._id,
                                    resource: "calendar_lead_funnel",
                                  });
                                  return;
                                }
                                const data = {
                                  query: {
                                    itemId: item?._id,
                                    item: item,
                                    handle: myInfo?.handle,
                                  },
                                  resource: `calendar_lead_funnel`,
                                  check: ["handle", "itemId"],
                                  myInfo,
                                };
                                // console.log("FormFields-person", data);
                                saveDataDB(data).then((ref) => {
                                  setRefreshing(true);
                                });

                                return;
                              } else {
                                text = "You canceled!";
                                // setOpenSignin(true)
                                return;
                              }
                            }}
                            style={{
                              display: "flex",
                              left: 10,
                              top: 10,
                              // color: 'orange',
                              // position: "absolute",
                              alignItems: "center",
                              color: !funnel?.[item?.handle]
                                ? "gray"
                                : "orange",
                            }}
                          />
                          <div>
                            <div
                              onClick={() => {
                                // console.log("xxxxvvv", e.target.value);
                                const data = {
                                  query: {
                                    title: item?.title,
                                    status: !item?.status,
                                    handle: myInfo?.handle,
                                  },
                                  resource: resource, // `calendar_${params.eventId ? "event" : "host"
                                  check: ["handle", "title"],
                                  myInfo,
                                };
                                // console.log('formData', data)
                                saveDataDB(data).then((res) => {
                                  //setRefreshing(true);
                                });
                              }}
                            >
                              {item?.status ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}
                            </div>
                            {/* <label htmlFor="no">Done</label> */}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              }}
            </ListItems>
          )}
        </div>
      </div>
    </>
  );
};

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

export const LinkParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

export const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
export const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
`;

export const AddSkillSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

const UploadedResume = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  p {
    font-size: 12px;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    width: 15px;
    height: 100%;
    color: #479d83;
  }
  button {
    border: 1px solid #479d83;
    padding: 3px 8px;
    background: #fff;
    font-size: 12px;
    transition: background 0.3s;
    margin: 0;
    &:hover {
      background: #479d832b;
    }
  }
`;

const UploadResumeFile = styled.label`
  background: #479d832b;
  border: 1px dashed gray;
  width: 100%;
  padding: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 10px;
    color: gray;
    margin: 0;
  }
  svg {
    width: 40px;
    color: #479d83;
    height: 100%;
  }
`;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
