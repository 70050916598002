import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column } from './Column';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { globalStyles } from './stitches.config';
import { TitleInput } from './TitleInput';
import { styled } from './stitches.config';

// import { StyledColumn } from './Column.styles';

export type ItemsType = {
  id: string;
  title: string;
  column: number | string | null;
};

export type ColumnsType = {
  id: number | string | null;
  title: string;
};

const MyKanbanBoard = (props: any) => {
  const {
    editItem,
    setEditItem,
    children,
    setItems,
    items = [
      {
        id: 'string',
        name: 'string',
        title: 'string',
        column: null
      },
      {
        id: 'string',
        name: 'string',
        title: 'string',
        column: '102a'
      },
      {
        id: 'strsssing',
        name: 'string',
        title: 'string',
        column: '103a'
      }
    ]
  } = props
  globalStyles();

  const [columns, setColumns] = React.useState<any>([
    {
      id: '102a',
      title: 'In Progress'
    },
    {
      id: '103a',
      title: 'Completed'
    },
    {
      id: '103a',
      title: 'Got Result/Feedback'
    }
  ]);
  // const [editItem, setEditItem] = React.useState<any>({})

  const onColumnChange = (id: string, columnId: number) => {
    setItems((prevItems: any) =>
      prevItems.map((item: any) => {
        if (item.id === id) {
          item.column = columnId;
        }
        return item;
      })
    );
  };



  return (
    <DndProvider backend={HTML5Backend}>
      <div className="">
        <main
          style={{
            display: 'flex',
          }}
        >
          <Column
            onColumnChange={onColumnChange}
            items={items?.filter((item: any) => item.column === null)}
            title={'Actionable Contacts'}
            id={null}
            setEditItem={setEditItem}
            onNewItem={(title: string) => {
              // setItems([...items, { id: uuid(), title, column: null }]);
            }}
          >
            {children({})}
          </Column>

          {columns.map((column: any) => {
            return (<>
              <Column
                key={column.id}
                onColumnChange={onColumnChange}
                items={items.filter((item: any) => item.column === column.id)}
                title={column.title}
                id={column.id}
                onNewItem={(title: string) => {
                  setItems([
                    ...items,
                    { id: uuid(), title, column: column.id },
                  ]);
                }}
                setEditItem={setEditItem}
                editItem={editItem}
                children={(e: any) => {
                  // console.log('formInputcccdada', e)
                  return <>{editItem?.name && editItem?.column == column.id && children(
                    editItem?.name ? editItem : e
                  )}</>
                }}
              />


            </>);
          })}
        </main>
      </div>
    </DndProvider>
  );
}


export default MyKanbanBoard;


export const StyledColumn = styled('div', {
  border: '1px dashed gray',
  margin: '1rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 250,

  variants: {
    layout: {
      sm: {
        minWidth: 250,
      },
    },
  },
});

export const StyledColumnTitle = styled('h2', {
  marginTop: 0,
});