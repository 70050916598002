import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { ButtonL } from "./AppIndex";
import { ContentCopy } from "@mui/icons-material";
import { getDataDB, saveDataDB } from "src/api";
import { UserContext } from "src/App";
import { useParams } from "react-router-dom";
import { Rating } from "@mui/material";
import { Calendar } from "react-multi-date-picker";

export const SponsorEndorsementModal = (props: any) => {
  const {
    children,
    users,
    eventLink,
    title = '',
    resource = 'calendar_advert_endorsement',
    company,
    skill
  } = props
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const [allLikes, setAllLikes] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [invitees, setInvitees] = useState<any[]>([]);
  const [dataRecord, setDataRecord] = useState<any>([]);
  const { myInfo, host, eventTab, hostTabs } = myContext;
  const [formInput, setFormInput] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const params: any = useParams<any>();


  const toggleModal = () => setIsOpen(!isOpen);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleAddInvitee = () => {
    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      const user = users?.find((user: any) => user.email === email) ?? email;
      const profileImg = user?.profileImg || "";
      setInvitees([...invitees, { email, profileImg }]);
      setEmail("");
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(eventLink).then(() => {
      alert("Link copied to clipboard!");
    });
  };


  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const model: any = useMemo(() => {
    return hostTabs?.filter((ref: any) => ref.name == eventTab)?.[0]?.model;
  }, [eventTab, hostTabs]);

  const handleShare = () => {
    console.log(
      "Invited Emails:",
      invitees.map((invitee) => invitee.email)
    );
  };


  useEffect(() => {
    const data = {
      query: {
        advertId: params?.advertId,
        handle: myInfo?.handle
      },
      resource: 'calendar_advert_endorsement',
      check: ["advertId"],
    };
    getDataDB(data).then((res) => {
      // console.log("hostTabsxxggg22", res);
      setDataRecord(res?.[0] ? res : []);
      setRefreshing(false);
    });

  }, [
    refreshing,
    params?.advertId,
  ]);

  return (
    <>
      <div onClick={toggleModal}>{children}</div>
      {isOpen && (
        <>
          <Backdrop onClick={toggleModal} />
          <Modal>
            <ModalHead>
              <h5>{title}</h5>
              <CopyLink onClick={copyToClipboard}>
                <ContentCopy />
                Copy link
              </CopyLink>
            </ModalHead>

            <div style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div>Your endorsement could earn you store credits or coupons from any of our sponsors.</div>
              <h5>{`${company}`}</h5>
              <textarea
                style={{
                  width: '100%'
                }}
                maxLength={210}
                onChange={(e: any) => {
                  const txt = e?.target?.value
                  console.log('txt', txt)
                  setFormInput({
                    ...formInput,
                    message: txt
                  })

                }}
                placeholder={`Describe why you think ${company} is great product or solution`}
                // value={'text'}
                rows={5}
              // onChange={handleEmailChange}
              />
              <Rating name="size-small" defaultValue={0} size="small"
                disabled={!myInfo?.handle}
                onChange={(e: any) => {
                  console.log('vvv', e?.target?.value)
                  setFormInput({
                    ...formInput,
                    rating: e?.target?.value,
                  })
                }}
              />
              <ButtonL style={{ margin: 0 }} onClick={() => {
                if (!myInfo?.handle) {
                  alert('Please login to continue')
                  return
                }

                const newFormInput: any = {
                  name: myInfo?.name,
                  handle: myInfo?.handle,
                  ...formInput
                };
                const data = {
                  query: {
                    ...newFormInput,
                    advertId: params?.advertId,
                    handle: myInfo?.handle
                  },
                  resource: resource,
                  check: ["handle", "advertId"],
                  myInfo,
                };
                // console.log('formDatacc', data)
                saveDataDB(data).then((res) => {
                  // setRefreshing(true);
                });

              }}>
                Endorse
              </ButtonL>
            </div>
            <div >
              {dataRecord.length > 0 ? (
                <InviteesList style={{
                  overflowY: 'auto',
                  height: 300
                }}>
                  {dataRecord.map((andorse: any, index: number) => (
                    <div key={index}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>


                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          // justifyContent: 'center',
                          // alignItems: 'center'
                        }}>
                        </div>
                        <EmailText>
                          <div style={{
                            backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: 100
                          }}>
                            <ProfileImage src={
                              andorse.img || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'
                            } alt="Profile"
                            />
                            {andorse?.hostName?.toUpperCase() || 'Guest'}
                          </div>

                          {andorse.message}
                          <div style={{
                            display: 'flex',
                            textAlign: 'right',
                            color: 'green'
                          }}
                          >
                            <Rating name="size-small"
                              disabled={andorse?.handle != myInfo?.handle}
                              // defaultValue={andorse?.rating || 0} 
                              value={andorse?.rating || 0}
                              size="small"
                              onChange={(e: any) => {
                                // console.log('vvv', e?.target?.value)

                                const data = {
                                  query: {
                                    rating: e?.target?.value,
                                    ...formInput,
                                    advertId: params?.advertId,
                                    name: myInfo?.name,
                                    handle: myInfo?.handle
                                  },
                                  resource: resource,
                                  check: ["handle", "advertId"],
                                  myInfo,
                                };
                                // console.log('formDatacc', data)
                                saveDataDB(data).then((res) => {
                                  // setRefreshing(true);
                                });

                              }}
                            />
                          </div>
                        </EmailText>
                      </div>




                    </div>
                  ))}
                </InviteesList>
              ) : null}
            </div>
          </Modal>
        </>
      )
      }
    </>
  );
};
const Modal = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1000;

  div {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
  }
`;

const ModalHead = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid gray;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
`;
const CopyLink = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  color: blue;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  margin: 0;
  svg {
    width: 15px;
    height: 15px;
    object-fit: contain;
    color: blue;
  }
`;
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const InviteesList = styled.div`
  margin-top: 20px;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const InviteeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

const EmailText = styled.span`
  font-size: 16px;
`;

// const CopyLinkButton = styled(FaRegCopy)`
//   cursor: pointer;
//   margin-left: 10px;
// `;
