import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RESTCall from "../restApi";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import DatePicker from "react-multi-date-picker";
import styled from "@emotion/styled";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteAgendaDB,
  deleteDataDB,
  deleteLinkDB,
  getAgendaDB,
  getCalendarEvents,
  getDataDB,
  getLinksDB,
  groupByNow,
  jumpToReleventDiv,
  myColors,
  saveAgendaDB,
  saveDataDB,
  saveEventDB,
} from "src/api";
import { UserContext } from "src/App";
import { AgendaFormComponent, InputAgenda } from "./AgendaFormComponent";
import { CommentsFormComponent } from "./CommentsFormComponent.";
import { FileFormComponent } from "./FileFormComponent";
import { ModalBox } from "./ModalBox";
import { TaggingComponent } from "./TaggingComponent";
import { ScheduleFormComponent } from "./ScheduleFormComponent";
import { ParticipantFormComponent } from "./ParticipantFormComponent";
import { SurveyFormComponent } from "./SurveyFormComponent";
import axios from "axios";
import { ListItemsMedia } from "./ListItemsMedia";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LinkC, LinkParent, TabListItems } from "./MediaSlider/TabListItems";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronLeft,
  ChevronRight,
  Edit,
  ExpandLess,
  ExpandMore,
  FilterAlt,
  RemoveRedEye,
} from "@mui/icons-material";
import { SubTabListItems } from "./MediaSlider/SubTabListItems";
import { EventFormModal } from "./EventFormModal";
import { DetailModal, ModalIntro } from "./MediaSlider";
import { EventFormCreator } from "./EventFormCreator";
import { ScheduleFormModal } from "./ScheduleFormModal";

import { ListItems } from "./ListItems";
import CarrousselCard, { DetailButton } from "./CarrousselCard";
import { ListCard } from "./ListCard";
import CarrousselListCard from "./CarrousselListCard";
import { MyAlert } from "./MyAlert";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const EventForm = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    agendas,
    allMyEvents,
    // eventId,
    data = {},
    host,
    // editInfo,
    eventDetailsTabs = [],
    eventInfo = {},
    editInfo,
    allAccess = false,
    funnel,
    myLikes,
    editEvent,
    scheduleId, //for edit form
    scheduleSelect,
    alertOn = false
  } = myContext;
  const navigate = useNavigate();

  const { selectedEvent, resource } = props;
  const params: any = useParams<any>();
  const [isList, setIsList] = useState<boolean>(false)

  const [noResult, setNoResult] = useState<boolean>(false);
  const [calType, setCalType] = useState<any>("rec");
  // const [editInfo, setEditInfo] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [tagType, setTagType] = useState<any>("");
  const location = useLocation();

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [formInput, setFormInput] = useState<any>({});
  const [slots, setSlots] = useState<number>(1);
  const [scheduleInfo, setScheduleInfo] = useState<any>({}); //for viee scheduled calendar
  const [schIndex, setSchIndex] = useState<any>(0);

  const myDateBs: any = [
    [new Date("01-01-24"), new Date("01-04-24")],
    [new Date("01-09-24")],
  ];

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);
  console.log("eventDetailsTab", params);

  const valuetext = (value: number) => {
    return `${value}°C`;
  };

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const [dataTopic, setDataTopic] = useState<any>([]);


  useEffect(() => {
    if (!eventId) {
      return;
    }

    getDataDB({
      query: { eventId: eventId },
      resource: `calendar_event_schedule`,
      limit: 50,
    }).then((res: any) => {
      // const parentFormInput = res?.[0];
      // const data = handleScheduleInfo({ parentFormInput: res?.[0] })
      // console.log("allMyEvents", data);
      res?.map((ref: any) => {
        return {
          ...ref,
          ...(!ref?.topic
            ? { topic: ref?.title }
            : {}),
        }
      })
      setDataRecord(res); //list data for all schedules
      const grouped = groupByNow({
        data: res,
        key: "topic",
        id: 2,
      });
      const groupedInfo = grouped && Object.keys(grouped);
      // console.log("hostTabsxxccnn", groupedInfo);

      groupedInfo?.[0] && setDataTopic(groupedInfo);
      setSchIndex(0);
    });

    // setFormInput(data);
  }, [
    location?.pathname,
    eventId
  ]);

  const handleScheduleInfo = (props: any) => {
    const { parentFormInput } = props;
    console.log("parentFormInput", parentFormInput);

    if (!parentFormInput) {
      return;
    }
    const days =
      typeof parentFormInput?.days == "string"
        ? JSON.parse(parentFormInput?.days)
        : parentFormInput?.days;

    const dates =
      parentFormInput?.dates == "string"
        ? JSON.parse(parentFormInput?.dates)
        : parentFormInput?.dates;

    return {
      ...parentFormInput,
      ...formInput,
      days: days || [],
      dates: dates || days || [],
      ...(parentFormInput?.recurring
        ? { recurring: parentFormInput?.recurring }
        : {
          recurring: "non",
        }),
      ...(parentFormInput?.type
        ? { type: parentFormInput?.type }
        : {
          type: "meeting",
        }),
    };
  };

  const myData = useMemo(() => {
    const result: any =
      dataRecord?.length > 0
        ? [
          ...dataRecord
            // ?.filter((res: any) => res.title != formInput?.title)
            ?.map((red: any) => {
              const info: any = {};
              if (red.file) {
                info["file"] = red.file;
              }
              if (red.link) {
                info["link"] = red.link;
              }
              if (red.img) {
                info["img"] = red.img;
              }
              return {
                ...red,
                _id: red?._id,
                title: red?.title,
                // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
              };
            }),
        ]
        : [];

    // console.log("eventDetailsTabhh", result, dataRecord);
    const top = result?.filter((res: any) => !res.status);
    const bottom = result?.filter((res: any) => res.status);
    return [...top, ...bottom];
  }, [dataRecord]);

  // console.log("eventDetailsTabhh", editInfo);

  useEffect(() => {
    (async () => {
      if (myInfo?.handle && eventId)
        await getDataDB({
          query: {
            handle: myInfo?.handle,
            eventId: eventId,
          },
          resource: "calendar_attendance",
        }).then((res) => {
          // console.log("vvvvvvvgg3333", res);
          // setPeople(res)
          setMyContext((existing: any) => {
            return {
              ...existing,
              allMyEvents: res || [],
            };
          });
        });
      // getCalendarEvents({
      //     handle: myInfo?.handle,
      //     eventId: eventId
      //   }).then((res) => {
      //     console.log('setMyEvents22', res)
      //     // setAllMyEvents(res || [])
      //     setMyContext((existing: any) => {
      //       return {
      //         ...existing,
      //         allMyEvents: res || [],
      //       };
      //     });
      //     // return 'done'
      //   });
    })();
  }, [myInfo?.handle, eventId]);

  const activeStyle = {
    borderBottom: "solid",
    borderBottomWidth: 2,
    borderBottomColor: "gray",
  };

  useEffect(() => {
    if (schIndex >= 0) {
      if (myData?.length >= 0 && myData?.[schIndex]) {
        // alert(44)
        const data = handleScheduleInfo({
          parentFormInput: myData?.[schIndex],
        });
        setScheduleInfo(data);
      }
    }
  }, [myData, schIndex]);

  useEffect(() => {
    if (eventSubTab >= 0) {
      if (myData?.length >= 0 && myData?.[eventSubTab]) {
        // alert(44)
        const data = handleScheduleInfo({
          parentFormInput: myData?.[eventSubTab],
        });
        setScheduleInfo(data);
        setTimeout(() => {
          jumpToReleventDiv("myscheduleId");
        }, 200)
      }
    }
  }, [myData, eventSubTab]);



  useEffect(() => {
    if (scheduleSelect?.index) {
      setSchIndex(scheduleSelect?.index);
      jumpToReleventDiv("myscheduleId");
    }

  }, [
    scheduleSelect
  ])



  return (
    <>
      {scheduleInfo?.title && (
        <div
          id={"myscheduleId"}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {myData?.[schIndex - 1] && (
            <ChevronLeft
              style={{
                fontSize: 30,
                marginBottom: 10,
                color: myColors?.darkGreen,
              }}
              onClick={() => {
                setSchIndex(schIndex - 1);
              }}
            />
          )}
          <h5
            style={{
              color: myColors?.darkGreen,
            }}
          >
            {`${scheduleInfo?.title} Schedule`}
          </h5>
          {myData?.[schIndex + 1] && (
            <ChevronRight
              style={{
                fontSize: 30,
                marginBottom: 10,
                color: myColors?.darkGreen,
              }}
              onClick={() => {
                setSchIndex(schIndex + 1);
              }}
            />
          )}
        </div>
      )}
      <ScheduleFormComponent
        {...props}
        scheduleInfo={scheduleInfo}
      />
      <ModalBox
        openModal={tagType?.tag ? true : false}
        headerText={
          tagType?.tag == "Agenda"
            ? "Tag to Agenda"
            : tagType?.tag == "File"
              ? "Tag to Files"
              : tagType?.tag == "Comments"
                ? "Tag to Comment"
                : null
        }
        closeModal={() => { }}
      >

      </ModalBox>
      <div>
        <div>
          {eventTab && (
            <>
              <Modal
                show={editEvent || editInfo}
                onHide={() => {
                  // console.log('bbbbb', location?.state?.backUrl)
                  if (location?.state?.backUrl && editInfo) {
                    navigate(
                      location?.state?.backUrl || `/event/${eventId}/schedule`
                    );
                  } else {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                        editEvent: false,
                      };
                    });
                  }
                }}
              >
                <>
                  <Modal.Header closeButton>
                    <Modal.Title>MyGuestNet</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {" "}
                    Manage {editEvent == "event" ? "Event" : "Schedule"}
                  </Modal.Body>
                  <Modal.Footer
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {editEvent == "schedule" ? (<>
                      <ScheduleFormModal
                        {...props}
                        scheduleInfo={scheduleInfo}
                        refreshing={refreshing}
                        setRefreshing={setRefreshing}
                        dataTopic={dataTopic}
                      />
                      {/* <ScheduleFormModalNew
                        {...props}
                        scheduleInfo={scheduleInfo}
                      /> */}
                    </>
                    ) : editEvent == "event" ? (
                      <EventFormModal {...props} scheduleInfo={scheduleInfo} />
                    ) : (
                      <ScheduleFormModal {...props} scheduleInfo={{}} />
                      // <ScheduleFormModalOld {...props} scheduleInfo={{}} />
                    )}
                  </Modal.Footer>
                </>
              </Modal>
            </>
          )}

          <div
            style={{
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <div style={{}}>{`${eventTab || ''} Records`.toUpperCase()}</div>
            <div style={{
              marginBottom: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.09)',
              borderRadius: 20,
              padding: 10,
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {formInput?.title ||
                (myData?.length > 0 && (
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: 300,
                    }}
                  >
                    <input
                      onClick={() => {
                        setIsList(true);
                      }}
                      type="radio"
                      id="view1"
                      name="view"
                      value="list"
                      {...(formInput?.view == "list"
                        ? { checked: true }
                        : {})}
                    />
                    <label style={{
                      marginLeft: -40
                    }}>List</label>
                    <br />
                    <input
                      onClick={() => {
                        setIsList(false);
                        // setFormInput({
                        //   ...formInput,
                        //   view: "carroussel",
                        // });
                      }}
                      type="radio"
                      id="view2"
                      name="view"
                      value="card"
                    />
                    <label style={{
                      marginLeft: -40
                    }}>Carroussel</label>
                    <br />
                  </form>
                ))}
            </div>
            {!isList ? (
              <div
                style={{
                  // marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  // margin: 20,
                  flexWrap: 'wrap',
                  justifyContent: 'space-around'
                }}
              >
                {myData?.map(item => {
                  return <div style={{
                    margin: 5
                  }}>
                    <CarrousselListCard
                      {...props}
                      {...item}
                      item={item}
                      setFormInput={setFormInput}
                      formInput={formInput}
                    />
                  </div>
                })}
              </div>
            ) : (<div style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 20
            }}>
              {myData?.map(item => {
                return <div style={{
                  marginBottom: 10
                }}>
                  <ListCard
                    {...props}
                    {...item}
                    setFormInput={setFormInput}
                    formInput={formInput}
                  />
                </div>
              })}
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: -20px;
`;
const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;
const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "rgba(0,0,0,0.05)")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;
