import { createStitches } from '@stitches/react';

export const info = createStitches({
    theme: {
        colors: {},
    },
    media: {
        xs: '(min-width: 576px)',
        sm: '(min-width: 768px)',
        md: '(min-width: 992px)',
        lg: '(min-width: 1200px)',
    },
    utils: {
        marginHorizontal: (value) => ({
            marginLeft: value,
            marginRight: value,
        }),
        marginVertical: (value) => ({
            marginTop: value,
            marginBottom: value,
        }),
        paddingHorizontal: (value) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        paddingVertical: (value) => ({
            paddingTop: value,
            paddingBottom: value,
        }),
    },
});

export const {
    styled,
    css,
    globalCss,
    keyframes,
    getCssText,
    theme,
    createTheme,
    config,
} = info

export const globalStyles = globalCss({});