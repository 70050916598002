import styled from "@emotion/styled";
import { Close, Person, Search, X } from "@mui/icons-material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { useNavigate, useParams } from "react-router-dom";
import { getDataDB, getDataWildDB, myColors } from "../api";
import { UserContext } from "../App";
import { Avatar, AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Tag } from "reactstrap";
import { ButtonL } from "./AppIndex";
import { useLatest } from "react-use";
const _ = require("lodash");

function MobMenu({
  page,
  eventTab,
  setMobMenu = () => {},
  setOpenMenu,
  type = "event",
  mobMenuColor,
}: any) {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    mySchEvents,
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    // eventInfo = {},
    allAccess,
    eventTabs,
    fullScreen = false,
    eventInfo,
  } = myContext;
  const navigate = useNavigate();
  const [tabval, setTabval] = useState("Apps");
  const [searchInput, setSearchInput] = useState<any>("");
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<any>({});

  const params: any = useParams<any>();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);
  // console.log('eventTabs', eventTabs)

  useEffect(
    _.debounce(() => {
      if (!(searchInput?.length > 0)) {
        return;
      }

      // console.log('formFieldxx44', formInput?.title)
      if (tabval == "Guest") {
        const data = {
          key: "name",
          request: "wildsearch",
          searchVal: searchInput,
          resource: `calendar_users`,
          limit: 400,
        };
        getDataWildDB(data).then((res: any) => {
          // console.log('formFieldxx44', res)
          if (res?.[0]) {
            const result = res
              ?.map((person: any) => {
                return {
                  active: true,
                  link: `/host/${person?.handle}`,
                  img: person.img || person?.picture || "",
                  // "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                  title: person.name,
                  name: person.name,
                  handle: person?.handle,
                };
              })
              ?.filter(
                (ref: any) => ref?.handle != myInfo?.handle || allAccess
              );
            setDataRecord([...result]);
          }
        });
      } else if (tabval == "Enterprise") {
        const data = {
          key: "title",
          request: "wildsearch",
          searchVal: searchInput,
          resource: `calendar_events`,
          limit: 400,
        };

        getDataWildDB(data).then((res: any) => {
          // console.log('formFieldxx44', res)
          if (res?.[0]) {
            const result = res?.map((events: any) => {
              return {
                ...events,
                active: true,
                link: `/event/${events?._id}/schedule`,
                img: events.img || events?.picture || "",

                // title: person.name,
                // name: person.name,
                handle: events?.handle,
              };
            });

            // ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
            setDataRecord([...result]);
          }
        });
      }
    }, 2000),
    [searchInput]
  );

  useEffect(() => {
    (async () => {
      if (tabval == "Events") {
        setDataRecord([]);
        // alert(5)
        getDataDB({
          query: {
            ...(eventId
              ? { eventId: eventId }
              : {
                  ssdsdsd: null,
                }),
          },
          resource: "calendar_event_schedule",
        }).then(async (res) => {
          if (res?.[0]) {
            const result = res?.map((event: any) => {
              return {
                ...event,
                active: true,
                link: `/event/${event?._id}/schedule`,
                // img: event.img || event?.picture || '',
                handle: event?.handle,
              };
            });

            const allResult: any = [];
            result?.[0] &&
              (await Promise.all(
                result?.map(async (sch: any) => {
                  return await getDataDB({
                    query: {
                      _id: sch?.eventId,
                    },
                    resource: "calendar_events",
                  }).then((res) => {
                    if (res?.[0]) {
                      const rep = {
                        event: res?.[0],
                        ...sch,
                      };
                      // console.log('vbbbbb', res?.[0], rep)
                      return allResult?.push(rep);
                    } else {
                      return allResult?.push(sch);
                    }
                  });
                })
              ));
            // ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
            setDataRecord([...(allResult?.[0] ? allResult : [])]);
          }
        });
      } else if (tabval == "Enterprise") {
        setDataRecord([]);
        getDataDB({
          query: {
            ssdsdsd: null,
          },
          resource: "calendar_events",
        }).then((res) => {
          if (res?.[0]) {
            const result = res?.map((events: any) => {
              return {
                ...events,
                active: true,
                link: `/event/${events?._id}/schedule`,
                img: events.img || events?.picture || "",

                // title: person.name,
                // name: person.name,
                handle: events?.handle,
              };
            });
            // ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
            setDataRecord([...result]);
          }
        });
      } else if (tabval == "Guest") {
        setDataRecord([]);

        getDataDB({
          query: {
            ...(eventId
              ? { eventId: eventId }
              : {
                  ssdsdsd: null,
                }),
          },
          resource: "calendar_users",
        }).then((res) => {
          if (res?.[0]) {
            const result = res?.map((person: any) => {
              return {
                ...person,
                active: true,
                link: `/host/${person?.handle}`,
                img: person.img || person?.picture || "",

                // title: person.name,
                // name: person.name,
                handle: person?.handle,
              };
            });
            // ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
            setDataRecord([...result]);
          }
        });
      }
    })();
  }, [tabval, eventId]);

  const modernColors = [
    "#5f4b8b", // Purple
    "#36a2ac", // Teal
    "#e84a5f", // Coral
    "#ff847c", // Salmon
    "#1abc9c", // Turquoise
    "#2ecc71", // Emerald
    "#3498db", // Peter River
    "#9b59b6", // Amethyst
    "#f39c12", // Orange
    "#e67e22", // Carrot
    "#e74c3c", // Alizarin
    "#2c3e50", // Midnight Blue
    "#16a085", // Green Sea
    "#27ae60", // Nephritis
    "#2980b9", // Belize Hole
    "#8e44ad", // Wisteria
    "#f1c40f", // Sun Flower
    "#d35400", // Pumpkin
    "#c0392b", // Pomegranate
    "#34495e", // Wet Asphalt
    "#5f4b8b", // Purple
    "#36a2ac", // Teal
    "#e84a5f", // Coral
    "#ff847c", // Salmon
    "#1abc9c", // Turquoise
    "#2ecc71", // Emerald
    "#3498db", // Peter River
    "#9b59b6", // Amethyst
    "#f39c12", // Orange
    "#e67e22", // Carrot
    "#e74c3c", // Alizarin
    "#2c3e50", // Midnight Blue
    "#16a085", // Green Sea
    "#27ae60", // Nephritis
    "#2980b9", // Belize Hole
    "#8e44ad", // Wisteria
    "#f1c40f", // Sun Flower
    "#d35400", // Pumpkin
    "#c0392b", // Pomegranate
    "#34495e", // Wet Asphalt
  ];
  const tabs = ["Enterprise", "Events", "Apps", "Guest"];

  useEffect(() => {
    setSelectedEnterprise(eventInfo?.title ? eventInfo : mySchEvents?.[0]);
  }, [eventInfo, mySchEvents]);

  // console.log('mySchEvents', eventInfo, mySchEvents)
  // console.log('formFieldxx44', dataRecord)

  const [dataInfo, setDataInfo] = useState<any>([]);
  useLayoutEffect(() => {
    getDataDB({
      query: {
        handle: myInfo?.handle,
      },
      resource: "calendar_events",
    }).then((res) => {
      // console.log('cvvvvv', res)
      // alert(myInfo?.handle)

      setDataInfo(res);
      if (!eventId) {
        setTabval("Enterprise");
        if (res?.[0])
          setMyContext((existing: any) => {
            return {
              ...existing,
              eventInfo: res?.[0],
            };
          });
      }
    });
  }, [eventId, myInfo?.handle]);

  useEffect(() => {
    // console.log('cvvvvv', dataInfo)
  }, [dataInfo]);

  return (
    <Row>
      <Col sm={12} md={12} lg={12}>
        <div
          style={{
            alignSelf: "center",
            borderRadius: "10px",
            padding: "20px",
            backgroundColor: "#ffffff",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
            border: "none",
            margin: "0 auto",
            position: "relative",
          }}
        >
          <MenuHeader>
            <h4>Explore Apps</h4>

            <CloseIcon
              onClick={() => {
                setMobMenu(false);
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    mobMenu: false,
                  };
                });
              }}
            >
              <Close />
            </CloseIcon>
          </MenuHeader>
          <SearchBar>
            <Search />
            <input
              autoCapitalize="none"
              type="Search..."
              // type={"text"}
              onChange={(e: any) => {
                // console.log("nnnnn", data);
                setSearchInput(e.target.value);
              }}
              value={searchInput || ""}
              placeholder={"Search..."}
            />
          </SearchBar>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              overflowY: "hidden",
              paddingBottom: 10,
            }}
          >
            {tabs?.map((item, key) => {
              return (
                <span
                  onClick={() => setTabval(item)}
                  key={key}
                  style={{
                    width: "100%",
                    padding: "14px 20px",
                    color: item === tabval ? "rgb(71, 157, 131)" : "lightgray",
                    borderBottom:
                      item === tabval ? "3px solid rgb(71, 157, 131)" : "1px solid lightgray",
                    cursor: "pointer",
                    textAlign:"center"
                  }}
                >
                  {item}
                </span>
              );
            })}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                position: "relative",
                width: "100%",
              }}
            >
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "scroll",
                  overflow: "hidden",
                  // width: window.innerWidth * .8,
                  gap: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: 'red',
                  flexWrap: "nowrap",
                }}
              >
                {eventInfo?.title && (
                  <div
                    onClick={() => {
                      let text =
                        "You sure you want to leave this Enteprise Page";
                      if (window.confirm(text) == true) {
                        navigate("/");
                        text = "You pressed OK!";
                      }
                    }}
                    style={{
                      display: "flex",
                      backgroundColor: myColors?.brown,
                      color:"white",
                      alignItems:"center",
                      borderRadius: 5,
                      padding: "3px 5px",
                      fontSize: 12,
                      whiteSpace: "nowrap",
                      flexDirection: "row",
                      gap: 3,
                    }}
                  >
                    <Close
                      style={{
                        fontSize: 12,
                      }}
                    />
                    {eventInfo?.title}
                  </div>
                )}
              </div>
            </div>
            {!myInfo?.token && (
              <div>
                <div style={{ fontSize: 14, color: "gray" }}>
                  Please log in or create your Enterprise account to join our
                  Marketplace and take full advantage of our business
                  development collaboration apps.{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 12,
                    marginBottom: 20,
                  }}
                >
                  <ButtonL
                    style={{
                      margin: 0,
                    }}
                    onClick={() => {
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          openCreateEvent: true,
                        };
                      });
                    }}
                  >
                    Create Enterprise
                  </ButtonL>

                  <ButtonL
                    style={{
                      margin: 0,
                    }}
                    onClick={() => {
                      navigate(`/login`);
                    }}
                  >
                    Login
                  </ButtonL>
                </div>
              </div>
            )}
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Col lg={12} md={12}>
                <ContentContainer
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    borderRadius: 20,
                    overflowY: "auto",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 10,
                  }}
                >
                  {/* <div style={{ marginTop: 20 }} /> */}

                  {tabval == "Apps"
                    ? eventTabs?.map((tab: any, index: any) => {
                        if (
                          searchInput?.length > 0 &&
                          !JSON.stringify(tab)
                            ?.toLowerCase()
                            ?.includes(searchInput)
                        ) {
                          return null;
                        }
                        return (
                          <IconWrapper
                            style={{                              
                              backgroundColor:
                                eventTab === tab.name ? "#479d83" : "",
                              ...(!selectedEnterprise?.title
                                ? { opacity: 0.2 }
                                : {}),
                            }}
                            onClick={() => {
                              if (!selectedEnterprise?.title) {
                                return;
                              }
                              if (!myInfo?.handle) {
                                alert("Please login to continue");
                                return;
                              }
                              if (setOpenMenu) {
                                setOpenMenu(false);
                              }
                              navigate(
                                `/${type}/${selectedEnterprise?._id}/${
                                  tab?.name
                                }${
                                  tab?.subTab?.[0]?.name
                                    ? "/" + tab?.subTab?.[0]?.name
                                    : ""
                                }`
                              );
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  editInfo: false,
                                };
                              });
                              setMobMenu(false);
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  mobMenu: false,
                                };
                              });
                            }}
                            key={index}
                          >
                            {/* Icon */}
                            <i
                              className={tab.icon}
                              style={{
                                color:
                                  eventTab === tab.name
                                    ? "white"
                                    : modernColors[index],
                              }}
                            />
                            <label
                              style={{
                                color: eventTab === tab.name ? "white" : "#000",
                              }}
                            >
                              {tab.title}
                            </label>
                          </IconWrapper>
                        );
                      })
                    : tabval == "Events" ||
                      tabval == "Enterprise" ||
                      tabval == "Guest"
                    ? dataRecord?.map((tab: any, index: any) => {
                        // console.log('bbbbcxxxx', tab)
                        if (
                          searchInput?.length > 0 &&
                          !JSON.stringify(tab)
                            ?.toLowerCase()
                            ?.includes(searchInput)
                        ) {
                          return null;
                        }
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                              overflowY: "auto",
                              width: "100%",
                              borderRadius: 5,
                              padding:10,
                              backgroundColor:
                                eventTab === tab.name && tabval == "Enterprise"
                                  ? "#edfff0"
                                  : dataInfo?.filter(
                                      (ref: any) => ref?.title == tab.name
                                    )?.[0]
                                  ? "#ffeded"
                                  : "#eeedff", //'rgba(0,0,0,0.5)',
                            }}
                            onClick={() => {
                              if (tabval == "Enterprise") {
                                return;
                              }
                              if (tabval == "Guest") {
                                navigate(`/host/${tab.handle}/about`);
                              } else {
                                tab?.link && navigate(tab?.link);
                              }

                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  editInfo: false,
                                };
                              });
                              setMobMenu(false);
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  mobMenu: false,
                                };
                              });
                            }}
                            key={index}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: 100,
                              }}
                            >
                              {tab.icon ? (
                                <i
                                  className={tab.icon}
                                  style={{
                                    color:
                                      eventTab === tab.name
                                        ? "white"
                                        : modernColors[index],
                                  }}
                                />
                              ) : (
                                <Avatar
                                  alt={tab.title || tab.name}
                                  src={
                                    (tabval == "Guest"
                                      ? tab.img
                                      : tab.picture) ||
                                    tab?.event?.picture ||
                                    "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                                  }
                                  sx={{ width: 30, height: 30 }}
                                  style={{
                                    height: 60,
                                    width: "100%",
                                    marginRight: -10,
                                    backgroundColor: "gray",
                                    borderRadius: 5,
                                    borderWidth: 2,
                                  }}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                paddingLeft:10,
                                borderRadius: 5,
                                color:"black"
                              }}
                            >
                              {tab?.event?.title && (
                                <div
                                  style={{
                                    backgroundColor: "rgba(0,0,0,0.04)",
                                    // width: 100,
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    borderRadius: 10,
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {tab?.event?.title || ""}
                                </div>
                              )}

                              <div
                                style={{
                                  textAlign: "start",
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  color:"black",
                                  paddingLeft:5
                             
                                }}
                              >
                                {tab.title || tab.name}
                              </div>

                              {tabval == "Enterprise" && (
                                <div
                                  style={{
                                    marginTop: 5,
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "flex-end",
                                    gap:10
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      navigate(`/guestsite/${tab?.title}`);
                                      setMyContext((existing: any) => {
                                        return {
                                          ...existing,
                                          editInfo: false,
                                        };
                                      });
                                      setMobMenu(false);
                                      setMyContext((existing: any) => {
                                        return {
                                          ...existing,
                                          mobMenu: false,
                                        };
                                      });
                                    }}
                                    style={{
                                      padding: 5,
                                      fontSize: 12,
                                      width: 40,
                                      backgroundColor: "#479d83",
                                      borderRadius: 5,
                                      cursor:"pointer",
                                      color:"white",
                                      display:"flex",
                                      alignItems:"center",
                                      justifyContent:"center"
                                    }}
                                  >
                                    Site
                                  </div>
                                  <div
                                    onClick={() => {
                                      tab?.link && navigate(tab?.link);
                                      setMyContext((existing: any) => {
                                        return {
                                          ...existing,
                                          editInfo: false,
                                        };
                                      });
                                      setMobMenu(false);
                                      setMyContext((existing: any) => {
                                        return {
                                          ...existing,
                                          mobMenu: false,
                                        };
                                      });
                                    }}
                                    style={{
                                      padding: 5,
                                      fontSize: 12,
                                      width: 40,
                                      backgroundColor: "#479d83",
                                      borderRadius: 5,
                                      cursor:"pointer",
                                      color:"white",
                                      display:"flex",
                                      alignItems:"center",
                                      justifyContent:"center"
                                    }}
                                  >
                                    Apps
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    : null}
                </ContentContainer>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export const AlertWithOptions = ({
  show,
  onHide,
  onConfirm,
  onCancel,
}: any) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to proceed?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MobMenu;
const MenuWrapper = styled.div`
  position: fixed;
  padding-top: 80px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 50px;
  margin-top: 20px;
  background-color: #ebebeb;
`;
const MenuIconsWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: black;
  width: 100%;
  max-width: 756px;
  margin-top: 20px;
  @media (max-width: 756px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 0px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90px;
  height: 100%;
  border-radius: 5;
  justify-self: center;
  margin: 25px 0px;
  label {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  i {
    font-size: 36px;

  }
`;
const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* 
  position: absolute;
  top: 10px;
  right: 10px; */
`;
const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  h4 {
    margin: 0;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const Tab = styled.div`
  padding: 10px 15px;
  font-size: 14px;
  border: 2px solid white;
`;
const SearchBar = styled.div`
  border: 1px solid gray;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: white;
  width: 100%;
  svg {
    width: 24px;
    height: 24px;
  }
  input {
    border: none;
    background-color: transparent;
    outline: none;
    width: 100%;
  }
`;
const ContentContainer = styled.div`
display:flex;
width:100%;
margin:0 10px;
padding:10px;
overflow-y:auto;
max-height:300px;
/* Custom Scrollbar Styling */
&::-webkit-scrollbar {
  width: 8px; /* Narrow scrollbar width */
}

&::-webkit-scrollbar-thumb {
  background-color: #479d83; /* Scrollbar color */
  border-radius: 10px; /* Roundness for scrollbar */
}

&::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar track color */
}
`;
