import Carousel from "react-spring-3d-carousel";
import { useState, useEffect, useMemo, useContext } from "react";
import { config } from "react-spring";
import { useSpring, animated } from "react-spring";
// import './CarrousselCard.css'
// import Styles from "./CarrousselStyle.css";
import { v4 as uuidv4 } from "uuid";
import {
  deleteDataDB,
  getCalendarUsers,
  getDataDB,
  getGuestLikesDB,
  groupByNow,
  handleReaction,
  handleSaveLikes,
  myColors,
  saveDataDB,
} from "src/api";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { FilterAlt, ThumbUp } from "@mui/icons-material";
import { Modal } from "react-bootstrap";
import { PlannerFunnelModal } from "./MediaSlider/PlannerFunnelModal";


export interface CarrousselType {
  imagen?: any;
  item?: any;
  viewDetails?: any;
  index?: number;
  isFunnel?: boolean;
  people?: any;
  subDisplay?: any;
  width?: number;
  height?: number;
  children?: any;
  margin?: any;
  isSmall?: boolean;
  showArrows?: boolean;
  offset?: number;
  searchKeywordAttendees?: any;
  onViewClick?: any

}

export const Carroussel = (props: CarrousselType) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { eventId } = myContext;
  const {
    isSmall = false,
    people,
    subDisplay,
    width,
    height = 500,
    children,
    margin } = props;

  console.log('vvvvpeople', props)


  const cardsProps = useMemo(() => {
    //  console.log('vvvvpeople', people)

    return people?.map((res: any) => {
      return {
        key: uuidv4(),
        content: (
          <Card
            {...props}
            imagen={
              res?.image ||
              res?.img ||
              "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
            }
            item={res}
            isSmall={isSmall}
          />
        ),
      };
    });
  }, [people]);

  const cards = useMemo(() => {
    return cardsProps?.map((element: any, index: number) => {
      return { ...element, onClick: () => setGoToSlide(index) };
    });
  }, [cardsProps]);

  const [offsetRadius, setOffsetRadius] = useState<any>(2);
  const [showArrows, setShowArrows] = useState<any>(false);
  const [goToSlide, setGoToSlide] = useState<any>(null);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
    console.log(cards, "<-cards");
  }, [props.offset, props.showArrows]);

  return (
    <CardsContainer isSmall={isSmall}>
      {people?.map((res: any, index: number) => (
        <Card
          key={index}
          {...props}
          imagen={
            res?.img ||
            res?.image ||
            "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          }
          index={index}
          item={res}
        />
      ))}
    </CardsContainer>
  );
};

const Card = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    allMyEvents,
    // host = "",
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    funnel = {},
    funnelItem = {},
    searchBy = "Event",
    // comments = [],
  } = myContext;
  const {
    imagen,
    item,
    handleInfo,
    setLocalAction,
    host,
    searchKeywordAttendees,
    children,
    myReaction,
    subDisplay,
    viewDetails,
    index,
    onViewClick,
    isFunnel = true,
    isSmall = false
  } = props;
  const navigate = useNavigate();
  const [show, setShown] = useState(false);
  const [peopleData, setPeopleData] = useState(false);
  const [myLike, setMyLike] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  useEffect(() => {
    // console.log("Guest info in the card", item);
    getGuestLikesDB({
      handle: myInfo?.handle,
      itemId: item?.handle,
    }).then((res) => {
      console.log("myLike", myLike);
      setMyLike(res?.[0]?.like ? true : false);
      setRefreshing(false);
    });

    // getDataDB({
    //   query: {
    //     handle: myInfo?.handle,
    //   },
    //   resource: "calendar_lead_funnel",
    // }).then((res) => {
    //   const groupUtil = groupByNow({
    //     data: res,
    //     key: "itemId",
    //     id: 2,
    //   });
    //   // console.log("myLike", res);
    //   // setFunnel(groupUtil)
    //   setMyContext((existing: any) => {
    //     return {
    //       ...existing,
    //       funnel: groupUtil,
    //     };
    //   });
    //   setRefreshing(false)
    // });
  }, [item, refreshing, myInfo?.handle]);

  return (<>
    <CardWrapperParent isSmall={isSmall}>
      <ImgContainer
        isSmall={props.isSmall}
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.05)",
            borderRadius: 10,
          }}
        >
          <video
            autoPlay
            loop
            muted
            poster={
              imagen ||
              "https://varefiles.s3.us-east-2.amazonaws.com/myguestnet.mp4"
            }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 10,
            }}
          >
            <source src={item?.video} type="video/mp4" />
            <source src={item?.video} type="video/ogg" />
          </video>
        </div>
        {isFunnel && <FilterAlt
          onClick={() => {
            let text = "Add to lead funnel";
            setMyContext((existing: any) => {
              return {
                ...existing,
                funnelItem: item,
              };
            });
            // if (window.confirm(text) == true) {
            //   text = "You pressed OK!";
            //   if (funnel?.[item?.handle]) {
            //     deleteDataDB({
            //       id: funnel?.[item?.handle]?.[0]?._id,
            //       resource: "calendar_lead_funnel",
            //     });
            //     return;
            //   }
            //   const data = {
            //     query: {
            //       itemId: item?.handle,
            //       item: item,
            //       handle: myInfo?.handle,
            //     },
            //     resource: `calendar_lead_funnel`,
            //     check: ["handle", "itemId"],
            //     myInfo,
            //   };
            //   // console.log("FormFields-person", data);
            //   saveDataDB(data).then((ref) => {
            //     // setRefreshing(true)
            //   });
            //   return;
            // } else {
            //   text = "You canceled!";
            //   // setOpenSignin(true)
            //   return;
            // }
          }}
          style={{
            display: "flex",
            left: 10,
            top: 10,
            // color: 'orange',
            position: "absolute",
            alignItems: "center",
            color: !funnel?.[item?.handle] ? "gray" : "orange",
          }}
        />}
        <RR
          data={{
            text: item?.name,
            url: `https://www.myguestnet.com/host/${item?.handle}`,
            title: `${item?.name}: ${item?.title}`,
          }}
        >
          <i
            className="fa fa-share"
            style={{
              display: "flex",
              fontSize: 18,
              //   backgroundColor: myColors.brown,
              padding: 10,
              borderRadius: 100,
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </RR>
        <div
          onClick={() => {
            if (!myInfo?.token) {
              alert("Please login");
              return;
            }
            handleSaveLikes({
              like: !myLike,
              type: "guest",
              ...item,
              myInfo,
            }).then((res) => {
              alert("Saved Like");
              setMyLike(!myLike);
              //setRefreshing(true);
            });
            // console.log('nnnnnn', item)
            // return
          }}
          style={{
            display: "flex",
            right: 50,
            top: 0,
            position: "absolute",
            alignItems: "center",
          }}
        >
          <i
            className="fa fa-heart"
            style={{
              display: "flex",
              fontSize: 18,
              //   backgroundColor: myColors.brown,
              padding: 10,
              borderRadius: 100,
              backgroundColor: myLike ? "#479d83" : "raga(0,0,0,0)",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>
        {children && (
          <div
            style={{
              display: "flex",
              right: 0,
              bottom: 0,
              width: "100%",
              position: "absolute",
              alignItems: "center",
            }}
          >
            {children(item)}
          </div>
        )}
      </ImgContainer>
      <CardInfo>
        <CardHead isSmall={props.isSmall}>{item?.name}</CardHead>
        <CardSubHead isSmall={props.isSmall}>
          {item?.[subDisplay] || item?.title}
        </CardSubHead>
      </CardInfo>
      {/* <div>{item?.quote}</div> */}
      {viewDetails ? (
        viewDetails({ item, index })
      ) : (
        <DetailButton
          isSmall={props.isSmall}
          onClick={() => {
            if (onViewClick) {
              onViewClick(item)
              return
            }
            setMyContext((existing: any) => {
              return {
                ...existing,
                action: false,
              };
            });
            // handleInfo(item.handle);
            // setLocalAction("detail");
            if (item?.link) {
              window.open(`https://www.myguestnet.com${item?.link}`, "_blank");
              return;
            }
            navigate(`/host/${item.handle}/about`);
          }}
        >
          View Details
        </DetailButton>
      )
      }
    </CardWrapperParent >
    {funnelItem?._id == item?._id && <Modal
      show={Object.keys(funnelItem)?.[0] ? true : false}
      onHide={() => {
        setMyContext((existing: any) => {
          return {
            ...existing,
            funnelItem: {},
          };
        });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>MyGuestNet</Modal.Title>
      </Modal.Header>
      <Modal.Body> Add {item?.title || 'Title'} to funnel</Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PlannerFunnelModal
          item={{
            id: uuidv4(),
            title: "Google Adwords best practices",
            badges: [],
            notifications: 0,
            // avatars: [2, 3],
            ...funnelItem,
          }}
        // funnelTopic={funnelItem?.topic}
        />
      </Modal.Footer>
    </Modal>}
  </>);
};

export default Carroussel;

const CardWrapperParent = styled.div<{ isSmall: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isSmall }) => (isSmall ? "5px" : " 10px")};
  font-family: "Poppins", sans-serif;
  h1,
  div {
    margin: 0;
  }
`;
const ImgContainer = styled.div<{ isSmall: boolean }>`
  position: relative;
  height: ${({ isSmall }) => (isSmall ? "200px" : " 290px")};
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
const RR = styled(RWebShare)`
  border: 12px solid green;
`;

const CardHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "16px" : "22px")};
  font-style: normal;
  font-weight: 600;
`;
const CardSubHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const DetailButton = styled.button<{ isSmall: boolean }>`
  color: #479d83;
  text-align: center;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "16px")};
  font-weight: 500;
  width: 100%;

  padding: ${({ isSmall }) => (isSmall ? "6px" : "9px")};
  border-radius: 60px;
  border: 1px solid #479d83;
  background: #fff;
  transition: background 0.3s;
  margin: 0;
  margin-top: ${({ isSmall }) => (isSmall ? "4px" : "8px")};
  &:hover {
    background: #f5fffc;
  }
`;
const CardsContainer = styled.div<{ isSmall: boolean }>`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isSmall }) =>
    isSmall ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};
  gap: ${({ isSmall }) => (isSmall ? "10px" : "24px")};
  justify-content: center;
  margin: ${({ isSmall }) => (isSmall ? "0px" : "50px 20px")};
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

// These Buttons are not necessary for the same card according to this UI design because we have a single button of view details and user can get every thing there
