import Carousel from "react-spring-3d-carousel";
import { useState, useEffect, useMemo, useContext } from "react";
import { config } from "react-spring";
import { useSpring, animated } from "react-spring";
// import './CarrousselCard.css'
// import Styles from "./CarrousselStyle.css";
// import { v4 as uuidv4 } from "@types/uuid";
import { v4 as uuidv4 } from 'uuid';

import {
  deleteDataDB,
  getCalendarUsers,
  getDataDB,
  getGuestLikesDB,
  groupByNow,
  handleReaction,
  handleSaveLikes,
  myColors,
  saveDataDB,
} from "src/api";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonL } from "./AppIndex";
import { AssignmentInd, FilterAlt } from "@mui/icons-material";

export const AddItemCarrousselCard = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { eventId } = myContext;
  const { people, width, height = 500, margin } = props;

  const cardsProps = useMemo(() => {
    // console.log('vvvvpeople', people)

    return people && people?.[0]
      ? people?.map((res: any, index: number) => {
        return {
          key: uuidv4(),
          content: (
            <Card
              {...props}
              imagen={
                res?.image ||
                res?.img ||
                "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
              }
              item={res}
              isSmall={props.isSmall}
            />
          ),
        };
      }) : []
  }, [people]);

  const cards = useMemo(() => {
    return cardsProps?.map((element: any, index: number) => {
      return { ...element, onClick: () => setGoToSlide(index) };
    });
  }, [cardsProps]);

  const [offsetRadius, setOffsetRadius] = useState<any>(2);
  const [showArrows, setShowArrows] = useState<any>(false);
  const [goToSlide, setGoToSlide] = useState<any>(null);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
    console.log(cards, "<-cards");
  }, [props.offset, props.showArrows]);

  return (
    <CardsContainer isSmall={props.isSmall}>
      {people?.map((res: any, index: number) => (
        <Card
          key={index}
          {...props}
          imagen={
            res?.image ||
            res?.img ||
            "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
          }
          item={res}
        />
      ))}
    </CardsContainer>
  );
};

const Card = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    allMyEvents,
    // host = "",
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    searchBy = "Event",
    allAccess,
    funnels = {},
    leads = {}
    // comments = [],
  } = myContext;
  const {
    imagen,
    item,
    handleInfo,
    handleViewDetails,
    handleRemove,
    handleAdd,
    setLocalAction,
    host,
    searchKeywordAttendees,
  } = props;
  const navigate = useNavigate();
  const [show, setShown] = useState(false);
  const [peopleData, setPeopleData] = useState(false);
  const [myLike, setMyLike] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  // const [leads, setFunnel] = useState<any>({});


  const params: any = useParams<any>();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);


  useEffect(() => {
    // console.log("Guest info in the card", item);
    if (myInfo?.handle && item?.handle) {
      getGuestLikesDB({
        handle: myInfo?.handle,
        itemId: item?.handle,
      }).then((res) => {
        // console.log("myLike", myLike);
        setMyLike(res?.[0]?.like ? true : false);
        setRefreshing(false);
      });


      getDataDB({
        query: {
          handle: myInfo?.handle,
        },
        resource: "calendar_lead_funnel",
      }).then((res) => {
        const groupUtil = groupByNow({
          data: res,
          key: "itemId",
          id: 2,
        });
        console.log("myLike", res);
        // setFunnel(groupUtil)
        setMyContext((existing: any) => {
          return {
            ...existing,
            leads: groupUtil,
          };
        });
        setRefreshing(false)
      });

    }

  }, [item, refreshing]);

  return (
    <CardWrapperParent isSmall={props.isSmall}>
      <ImgContainer isSmall={props.isSmall}>
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.05)",
            borderRadius: 10,
          }}
        >
          <video
            autoPlay
            loop
            muted
            poster={
              imagen ||
              "https://varefiles.s3.us-east-2.amazonaws.com/myguestnet.mp4"
            }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 10,
            }}
          >
            <source src={item?.video} type="video/mp4" />
            <source src={item?.video} type="video/ogg" />
          </video>
        </div>


        <FilterAlt
          onClick={() => {
            let text = "Add to lead leads";
            if (window.confirm(text) == true) {
              text = "You pressed OK!";

              if (leads?.[item?.handle]) {

                deleteDataDB({
                  id: leads?.[item?.handle]?.[0]?._id,
                  resource: 'calendar_lead_funnel'
                })
                return

              }
              const data = {
                query: {
                  eventId: eventId,
                  itemId: item?.handle,
                  item: item,
                  handle: myInfo?.handle
                  // status: !funnels[eventId ? eventId : item?.handle],
                  // itemId: eventId ? eventId : item?.handle,
                  // type: eventId ? 'event' : 'guest',
                  // link: `https://www.myguestnet.com/${eventId ? 'event' : 'host'}/${eventId ? eventId : item.handle}/${eventId ? 'details' : 'about'}`,
                },
                resource: `calendar_lead_funnel`,
                check: ["handle", "itemId"],
                myInfo,
              };
              // console.log("FormFields-person", data);
              saveDataDB(data).then(ref => {
                // setRefreshing(true)
              })


              return;
            } else {
              text = "You canceled!";
              // setOpenSignin(true)
              return;
            }
          }}
          style={{
            display: "flex",
            left: 10,
            top: 10,
            // color: 'orange',
            position: "absolute",
            alignItems: "center",
            color: !leads?.[item?.handle] ? 'gray' : 'orange'
          }}
        />

        <div
          style={{
            display: "flex",
            right: 0,
            bottom: 0,
            position: "absolute",
            alignItems: "center",
          }}
        >
          {item?.active && handleRemove
            ? <ButtonL
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                fontSize: 10
              }}
              onClick={() => {
                handleRemove(item)
              }}
            >
              Remove
            </ButtonL>
            : handleAdd ? <ButtonL
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)'
              }}
              onClick={() => {
                handleAdd(item)
              }}
            >
              Add
            </ButtonL> : null}
        </div>
      </ImgContainer>
      <CardInfo>
        <CardHead isSmall={props.isSmall}>{item?.name}</CardHead>
        <CardSubHead isSmall={props.isSmall}>{item?.title}</CardSubHead>
      </CardInfo>
      {/* <div>{item?.quote}</div> */}
      <DetailButton
        isSmall={props.isSmall}
        onClick={() => {
          // if (searchBy == "Event") {
          handleViewDetails(item)
        }}
      >
        View Details
      </DetailButton>
    </CardWrapperParent >
  );
};

const CardWrapperParent = styled.div<{ isSmall: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isSmall }) => (isSmall ? "5px" : " 10px")};
  font-family: "Poppins", sans-serif;
  h1,
  div {
    margin: 0;
  }
`;
const ImgContainer = styled.div<{ isSmall: boolean }>`
  position: relative;
  height: ${({ isSmall }) => (isSmall ? "200px" : " 290px")};
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
const RR = styled(RWebShare)`
  border: 12px solid green;
`;

const CardHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "16px" : "22px")};
  font-style: normal;
  font-weight: 600;
`;
const CardSubHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const DetailButton = styled.button<{ isSmall: boolean }>`
  color: #479d83;
  text-align: center;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "16px")};
  font-weight: 500;
  width: 100%;

  padding: ${({ isSmall }) => (isSmall ? "6px" : "9px")};
  border-radius: 60px;
  border: 1px solid #479d83;
  background: #fff;
  transition: background 0.3s;
  margin: 0;
  margin-top: ${({ isSmall }) => (isSmall ? "4px" : "8px")};
  &:hover {
    background: #f5fffc;
  }
`;
const CardsContainer = styled.div<{ isSmall: boolean }>`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isSmall }) =>
    isSmall ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};
  gap: ${({ isSmall }) => (isSmall ? "10px" : "24px")};
  justify-content: center;
  margin: ${({ isSmall }) => (isSmall ? "0px" : "50px 20px")};
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

// These Buttons are not necessary for the same card according to this UI design because we have a single button of view details and user can get every thing there
