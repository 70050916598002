import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  getUserInfoByHandle,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  Fullscreen,
  FullscreenExit,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  Sync,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { AdvertListItems } from "./AdvertListItems";
import { MyTabListItems } from "./MyTabListItems";
import { AboutTabDetails } from "./AboutTabDetails";
// import QRCode from "react-qr-code";
import { MyColabTabListItems } from "./MyColabTabListItems";
import { LikesComponet } from "../LikesComponet";
import { ActionManager } from "../ActionManager";
import { ResourceTree } from "../ResourceTree";
import { EventFunnel } from "../EventFunnel";
import { Brainstorm } from "./Brainstorm";
import { BenchmarkListItem } from "./BenchmarkListItem";
import { HostFunnel } from "../HostFunnel";
import { UserStoriesCreate } from "./UserStoriesCreate";
import MobMenu from "../MobMenu";
import UserStoriesGeneral from "./UserStoriesGeneral";
// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export const HostEditor = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  // const { handleInfo } = props;
  const params: any = useParams<any>();
  const { host } = myContext;
  const navigate = useNavigate();

  const [selectedGuestProfileCategory, setSelectedGuestProfileCategory] =
    useState<string>("");

  const [isVisible, setIsVisible] = useState(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>("");

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [hiddenTabs, setHiddenTabs] = useState<string[]>(
    host?.hiddenTabs || []
  );

  const [guestProfileCategoresState, setGuestProfileCategoresState] =
    useState<any>([]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    cart = [],
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    fullscreen = false,
    openMainMenu,
  } = myContext;

  const location = useLocation();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const model: any = useMemo(() => {
    return hostTabs?.filter((ref: any) => ref.name == eventTab)?.[0]?.model;
  }, [eventTab, hostTabs]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const hostId = useMemo(() => {
    return params?.hostId;
  }, [params?.hostId]);

  const [mobMenu, setMobMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    setMobMenu(openMainMenu);
    setMyContext((existing: any) => {
      return {
        ...existing,
        mobMenu: openMainMenu,
      };
    });
  }, [openMainMenu]);

  useEffect(() => {
    if (!(hostId && hostTab)) {
      return;
    }
    const data = {
      query: {
        handle: hostId,
      },
      resource: `calendar_host_about`,
      check: ["handle"],
    };
    getDataDB(data).then((res: any) => {
      const result: any = res?.[0]?.industry && Object.keys(res?.[0]?.industry);

      if (result) {
        console.log("hostTabsxxccvvv2", result);
        setGuestProfileCategoresState(result || []);
        setSelectedGuestProfileCategory(result[0]);
      }

      setRefreshing(false);
    });

    getUserInfoByHandle({
      handle: hostId,
    }).then((ref: any) => {
      setMyContext((existing: any) => {
        return {
          ...existing,
          host: ref?.[0],
        };
      });
    });
  }, [hostId]);

  const handleFilterCheckboxChange = (tabName: string, isChecked: boolean) => {
    const updatedActiveTabs = isChecked
      ? [...hiddenTabs, tabName]
      : hiddenTabs.filter((t) => t !== tabName);

    // Update local state immediately for UI responsiveness
    setHiddenTabs(updatedActiveTabs);

    // Prepare data for DB update
    const data = {
      query: {
        handle: hostId,
        // hiddenTabs: updatedActiveTabs,
        hiddenTabs: updatedActiveTabs,
      },
      resource: "calendar_users",
      check: ["handle"],
      myInfo,
    };

    // Update database in the background
    saveDataDB(data)
      .then((res) => {
        // console.log("Database update response:", res);
        setRefreshing(true);
      })
      .catch((error) => {
        console.error("Failed to update database", error);
        // Optionally revert UI changes or notify user
        setHiddenTabs(host?.hiddenTabs || []);
      });
  };

  // const [editInfo, setEditInfo] = useState<boolean>(false);
  const ModalContent = (props: any) => {
    const { myContext, setMyContext } = useContext<any>(UserContext);
    const [mobMenu, setMobMenu] = useState(false);
    const {
      // eventTab,
      myInfo,
      myInfo: { active = "2" },
      // host,
      // eventId,
      hostId,
      selectedEvent,
      people,
      editInfo,
      hostTabs = [],
      eventTabs = [],
      eventInfo = {},
      allAccess,
      fullScreen = false,
    } = myContext;

    return (
      <>
        {hostTab &&
          hostTabs?.map((res: any) => {
            return (
              <div>
                {res.name == hostTab && res.subTab && (
                  <>
                    <div>
                      <div
                        style={{
                          // flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          width: 400,
                          overflowX: "auto",
                          marginBottom: 10,
                        }}
                      >
                        {!fullscreen ? (
                          <Fullscreen
                            onClick={() => {
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  fullscreen: true,
                                };
                              });
                            }}
                          />
                        ) : (
                          <FullscreenExit
                            onClick={() => {
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  fullscreen: false,
                                };
                              });
                            }}
                          />
                        )}
                        {res.name == hostTab &&
                          res.subTab?.map((sub: any) => {
                            if (
                              sub?.hostOnly &&
                              myInfo?.handle &&
                              hostId != myInfo?.handle
                            ) {
                              return;
                            }
                            return (
                              <TabEvent
                                selected={hostSubTab == sub.name ? true : false}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  margin: 0,
                                  fontSize: 12,
                                  padding: 3,
                                  marginLeft: 10,
                                  whiteSpace: "nowrap",
                                }}
                                onClick={() => {
                                  navigate(
                                    `/host/${hostId}/${hostTab}/${sub.name}`,
                                    {
                                      state: {
                                        backUrl: location?.pathname,
                                      },
                                    }
                                  );
                                  setMyContext((existing: any) => {
                                    return {
                                      ...existing,
                                      editInfo: false,
                                    };
                                  });
                                }}
                              >
                                {sub.icon && (
                                  <i
                                    className={res.icon}
                                    style={{
                                      display: "flex",
                                      fontSize: 12,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      paddingRight: 5,
                                    }}
                                  />
                                )}
                                {sub?.title || ""}
                              </TabEvent>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
                {/* {res.name == hostTab && (
                <h4
                  style={{
                    fontSize: 20,
                    fontWeight: 900,
                    // margin:"10px 0",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {res?.description || ""}
                </h4>
              )} */}

                {res.name == hostTab && (
                  <>
                    <ModalHeader
                      style={{
                        marginBottom: 10,
                        backgroundColor: "rgba(0,0,0,0.09)",
                        borderRadius: 25,
                        padding: 5,
                      }}
                    >
                      <div>
                        <Sync
                          onClick={() => {
                            // handleReset();
                          }}
                        />
                      </div>
                      <span>{res?.description || ""}</span>
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                          alignItems: "center",
                        }}
                      >
                        {!fullScreen ? (
                          <Fullscreen
                            onClick={() => {
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  fullScreen: true,
                                };
                              });
                            }}
                          />
                        ) : (
                          <FullscreenExit
                            onClick={() => {
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  fullScreen: false,
                                };
                              });
                            }}
                          />
                        )}
                      </div>
                    </ModalHeader>
                  </>
                )}

                {myInfo?.handle == "@mplaunch" || (myInfo?.handle &&
                  myInfo?.handle == hostId &&
                  res.name == hostTab &&
                  res?.editButton) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 20,
                      }}
                    >
                      <EditSectionWrapper>
                        <EditSectionButton
                          onClick={() => {
                            // setEditInfo(!editInfo)
                            setMyContext((existing: any) => {
                              return {
                                ...existing,
                                editInfo: !editInfo,
                              };
                            });
                          }}
                        >
                          <p>{`Add ${res.title}`}</p>
                          {!editInfo ? <ExpandMore /> : <ExpandLess />}
                        </EditSectionButton>
                      </EditSectionWrapper>
                    </div>
                  )}

                <>
                  {res.name == hostTab && hostTab == "about" ? (
                    <div>
                      <>
                        {res?.subTab?.map((sub: any) => {
                          if (
                            sub?.hostOnly &&
                            myInfo?.handle &&
                            hostId != myInfo?.handle
                          ) {
                            return;
                          }
                          return (
                            <>
                              {!hostSubTab ||
                                (sub?.name == hostSubTab &&
                                  hostSubTab == "view") ? (
                                <AboutTabListItems
                                  {...props}
                                  editInfo={editInfo}
                                  resource={res.resource}
                                />
                              ) : sub?.name == hostSubTab &&
                                hostSubTab == "details" ? (
                                <AboutTabDetails
                                  {...props}
                                  editInfo={editInfo}
                                  resource={sub.resource}
                                />
                              ) : null}
                            </>
                          );
                        })}
                      </>
                    </div>
                  ) : res.name == hostTab && hostTab == "catalog" ? (
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {null}
                    </Col>
                  ) : hostTab == "planner" ? (
                    <>
                      {res?.subTab?.map((sub: any) => {
                        if (
                          sub?.hostOnly &&
                          myInfo?.handle &&
                          hostId != myInfo?.handle
                        ) {
                          return;
                        }
                        return (
                          <>
                            {!hostSubTab ||
                              (hostSubTab == "todo" &&
                                sub?.name == hostSubTab) ? (
                              <MyTabListItems
                                {...props}
                                // editInfo={editInfo}
                                // editButton={sub?.editButton}
                                // type={"host"}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                                tab={sub}
                                resource={sub.resource}
                              />
                            ) : sub?.name == hostSubTab &&
                              hostSubTab == "stories" ? (
                              <ActionManager
                                {...props}
                                editInfo={editInfo}
                                editButton={sub?.editButton}
                                type={"host"}
                                tab={sub}
                                resource={sub.resource}
                              />
                            ) : sub.name == "funnel" &&
                              hostSubTab == "funnel" ? (
                              <div>
                                <HostFunnel
                                  {...props}
                                  editInfo={editInfo}
                                  tab={sub}
                                  resource={sub?.resource}
                                />
                              </div>
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : res.name == hostTab && res.name == "userStories" ? (
                    <>
                      {res?.subTab?.map((sub: any) => {
                        if (
                          sub?.hostOnly &&
                          myInfo?.handle &&
                          hostId != myInfo?.handle
                        ) {
                          return;
                        }
                        return (
                          <>
                            {" "}
                            {!hostSubTab ||
                              (sub.name == "create" && hostSubTab == "create") ? (
                              <div>
                                <UserStoriesCreate
                                  {...props}
                                  editInfo={editInfo}
                                  tab={res}
                                  resource={res?.resource}
                                />
                              </div>
                            ) : sub.name == "stories" &&
                              hostSubTab == "stories" ? (
                              <div>
                                <UserStoriesGeneral
                                  {...props}
                                  editInfo={editInfo}
                                  tab={res}
                                  resource={res?.resource}
                                />
                              </div>
                            ) : // <UserStories
                              //   {...props}
                              //   editInfo={editInfo}
                              //   resource={res?.resource}
                              // />
                              null}
                          </>
                        );
                      })}
                    </>
                  ) : res.name == hostTab && res.name == "myMarkets" ? (
                    <>
                      {res?.subTab?.map((sub: any) => {
                        if (
                          sub?.hostOnly &&
                          myInfo?.handle &&
                          hostId != myInfo?.handle
                        ) {
                          return;
                        }
                        return (
                          <>
                            {(sub?.name == hostSubTab &&
                              hostSubTab == "myTikets") ||
                              (sub?.name == hostSubTab && !hostSubTab) ? (
                              <MyColabTabListItems
                                {...props}
                                editInfo={editInfo}
                                editButton={sub?.editButton}
                                type={"host"}
                                tab={sub}
                                resource={sub.resource}
                              />
                            ) : sub?.name == hostSubTab &&
                              hostSubTab == "mySubscription" ? (
                              <MyColabTabListItems
                                {...props}
                                editInfo={editInfo}
                                editButton={sub?.editButton}
                                type={"host"}
                                tab={sub}
                                resource={sub.resource}
                              />
                            ) : sub?.name == hostSubTab &&
                              hostSubTab == "myCoupons" ? (
                              <MyColabTabListItems
                                {...props}
                                editInfo={editInfo}
                                editButton={sub?.editButton}
                                type={"host"}
                                tab={sub}
                                resource={sub.resource}
                              />
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : res.name == hostTab && res.name == "myActivities" ? (
                    <>
                      {res?.subTab?.map((sub: any) => {
                        if (
                          sub?.hostOnly &&
                          myInfo?.handle &&
                          hostId != myInfo?.handle
                        ) {
                          return;
                        }
                        return (
                          <>
                            {(sub?.name == hostSubTab &&
                              hostSubTab == "myEvents") ||
                              (sub?.name == hostSubTab && !hostSubTab) ? (
                              <MyColabTabListItems
                                {...props}
                                editInfo={editInfo}
                                editButton={sub?.editButton}
                                type={"host"}
                                tab={sub}
                                resource={sub.resource}
                              />
                            ) : sub?.name == hostSubTab &&
                              hostSubTab == "myCollaborators" ? (
                              <MyColabTabListItems
                                {...props}
                                editInfo={editInfo}
                                editButton={sub?.editButton}
                                type={"host"}
                                tab={sub}
                                resource={sub.resource}
                              />
                            ) : sub?.name == hostSubTab &&
                              hostSubTab == "myMemberships" ? (
                              <MyColabTabListItems
                                {...props}
                                editInfo={editInfo}
                                editButton={sub?.editButton}
                                type={"host"}
                                tab={sub}
                                resource={sub.resource}
                              />
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : res.name == hostTab && res.resource ? (
                    <MyTabListItems
                      {...props}
                      editInfo={editInfo}
                      editButton={res?.editButton}
                      type={"host"}
                      tab={res}
                      resource={res.resource}
                    />
                  ) : res.name == hostTab ? (
                    <TabListItems
                      {...props}
                      tab={res}
                      resource={res.resource}
                      editInfo={editInfo}
                      type={"host"}
                    />
                  ) : null}
                </>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      {mobMenu && (
        <Modal show={mobMenu}>
          <MobMenu
            eventTabs={hostTabs}
            eventTab={hostTab}
            setMobMenu={setMobMenu}
            setOpenMenu={setOpenMenu}
            eventId={hostId}
            type={"host"}
          />
        </Modal>
      )}
      {hostTab && (
        <>
          <TopHeaderWrapper>
            {host?.id && hostId == myInfo?.handle && (
              <DropdownFilterTabsContainer>
                <ButtonFilterTabs
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <FilterList />
                </ButtonFilterTabs>
                {showDropdown && (
                  <DropdownFilterTabs>
                    Filter Visible Tabs
                    {hostTabs.map((tab: any) => (
                      <FilterChild key={tab.name}>
                        <input
                          type="checkbox"
                          checked={hiddenTabs.includes(tab.name)}
                          onChange={(e) =>
                            handleFilterCheckboxChange(
                              tab.name,
                              e.target.checked
                            )
                          }
                        />
                        <span>{tab.name}</span>
                      </FilterChild>
                    ))}
                  </DropdownFilterTabs>
                )}
              </DropdownFilterTabsContainer>
            )}
            {hostTab && (
              <DropdownContainer>
                <ButtonIndustry onClick={() => setIsVisible(!isVisible)}>
                  {selectedGuestProfileCategory
                    ? selectedGuestProfileCategory
                    : "Industries"}{" "}
                  <ExpandMore style={{ color: "#333" }} />
                </ButtonIndustry>
                {isVisible && (
                  <DropdownContentIndustry>
                    {guestProfileCategoresState.map(
                      (industry: any, index: number) => (
                        <DropdownItemIndustry
                          key={industry + "##" + index}
                          active={
                            industry === selectedGuestProfileCategory
                              ? true
                              : false
                          }
                          onClick={() => {
                            setSelectedGuestProfileCategory(industry);
                            setIsVisible(false);
                          }}
                        >
                          {industry}
                        </DropdownItemIndustry>
                      )
                    )}
                  </DropdownContentIndustry>
                )}
              </DropdownContainer>
            )}
          </TopHeaderWrapper>
          <div
            style={{
              justifyContent: "center",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 64,
                  width: "100%",
                }}
              >
                {/* <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`https://www.mycalendar_comment.com/host/${(hostId)}`}
              viewBox={`0 0 256 256`}
            /> */}
              </div>

              <GuestImage
                src={
                  host?.img ||
                  host?.image ||
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                }
                onClick={() => {
                  //  console.log(host)
                }}
              />
            </div>
            <h2>{host?.name || ""}</h2>
            <p>{host?.title || ""}</p>
            <ButtonL
              style={{
                margin: 0,
                fontSize: 12,
              }}
              onClick={() => {
                setMobMenu(true);
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    openMainMenu: true,
                  };
                });
              }}
            >
              Take a Tour
            </ButtonL>
          </div>
        </>
      )}

      <div>
        <TabListEvent>
          {hostTab &&
            hostTabs?.map((res: any) => {
              if (res?.hostOnly && myInfo?.handle && hostId != myInfo?.handle) {
                return;
              }
              return (
                <>
                  <TabEvent
                    selected={hostTab == res.name ? true : false}
                    onClick={() => {
                      // setAction('schedule')
                      navigate(
                        `/host/${hostId}/${res?.subTab?.[0]?.name
                          ? res.name + "/" + res?.subTab?.[0]?.name
                          : res.name
                        }`
                      );
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          editInfo: false,
                        };
                      });
                    }}
                  >
                    <p className="title">
                      {res.icon && (
                        <i
                          className={res.icon}
                          style={{
                            display: "flex",
                            fontSize: 12,
                            justifyContent: "center",
                            alignItems: "center",
                            paddingRight: 5,
                          }}
                        />
                      )}{" "}
                      {res.title}
                    </p>
                  </TabEvent>
                </>
              );
            })}
        </TabListEvent>
        {fullscreen ? (
          <Modal
            show={true}
            fullscreen={true}
            onHide={() => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  fullScreen: false,
                };
              });
            }}
          >
            <ModalContent {...props} />
          </Modal>
        ) : (
          <ModalContent {...props} />
        )}
      </div>
    </>
  );
};

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;

  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const SearchWrapper = styled.div`
  max-width: 930px;
  width: 100%;
  border: 1px solid #dfe0e4;
  border-radius: 10px;
  margin-top: -120px;
  position: relative;
  z-index: 10;
  background: #fff;
`;

const SearchBarContainer = styled.div`
  padding: 28px;
`;

const ToggleMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  border-bottom: 1px solid #dfe0e4;
`;

const ToggleButtons = styled.a<{ active: boolean }>`
  text-decoration: none;
  padding: 17px 0;
  color: #000;
  min-width: 50px;
  border-bottom: ${({ active }) => (active ? "2px solid #479d83" : "none")};
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

const EventImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 2px solid #dee2e6;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;

const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "White")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const GuestImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px auto;
  margin-top: 14px;
`;

const GuestMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px !important;
  width: 140px !important;
  background-color: black;
  border-radius: 5px;
  position: relative;
  video,
  img {
    background-color: black;
    border-radius: 5px;
  }
`;

const GuestMediaHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 12px;
    max-width: 12px;
    height: 12px;
  }
  p {
    font-size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    width: 50%;
    color: white;
    margin: 0;
  }
  div {
    display: flex;
    gap: 4px;
    span {
      padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const GuestMediaParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  max-height: 500px;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: white;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const ButtonF = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PostHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const MenuWrapperPosts = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const DropdownFilterTabsContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

const FilterChild = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    width: fit-content;
  }
`;

const DropdownFilterTabs = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  z-index: 9999;
`;

const ButtonFilterTabs = styled.button`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 8px;
  margin: 0;
  cursor: pointer;

  svg {
    color: #333;
    width: 15px;
    height: 15px;
  }
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonIndustry = styled.button`
  background-color: #ffffff;
  color: #333;
  padding: 2px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-width: 160px;
  gap: 8px;
  font-size: 14px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContentIndustry = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

const DropdownItemIndustry = styled.div<any>`
  padding: 10px;
  cursor: pointer;
  color: #333;
  font-size: 12px;
  &:hover {
    background-color: #f1f1f1;
  }

  ${(props) =>
    props.active &&
    `
    background-color: #ddd; 
    font-weight: bold;
  `}
`;

const TopHeaderWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 5px;
  margin-top: -10px;
`;

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin-top: -20px; */
`;

const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const PriceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
`;

const Img = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const SupCardHeader = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  padding: 0px 10px;
`;

const CardLabelSub = styled.h3`
  margin: 0;
  font-size: 14px;
  color: gray;
  padding: 0px 10px;
`;

const CardPriceSub = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  padding: 0px 10px;
`;

const CardSupOffer = styled.div`
  margin: 0;
  font-size: 10px;
  color: gray;
  padding: 0px 10px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const HeroBannerWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  background: #96c7b8;
  min-height: 500px;
  @media (max-width: 800px) {
    min-height: 450px;
  }
  @media (max-width: 500px) {
    min-height: 400px;
  }
`;

const HeroBannerChild = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const HeroBannerTextWrapper = styled.div`
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: white;

  h1 {
    font-size: 66px;
    line-height: 49px;
    font-weight: 900;
    margin: 0;
    text-align: start;
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: 200;
    text-align: start;
    color: white;
  }

  @media (max-width: 800px) {
    align-items: center;
    text-align: center;

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 500px) {
    max-width: 280px;

    h1 {
      font-size: 40px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
    }
  }
`;
