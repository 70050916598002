import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext
} from "react";
import { Breadcrumb, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import DatePicker from "react-multi-date-picker";

import { gapi } from "gapi-script";
import moment from 'moment'
import Calendar from "react-calendar";
import {
  getCalendarEvents,
  getCalendarUsers,
  getCalendarSchedule,
  saveAppointment
} from '../api'
// import { Navbar } from "react-bootstrap";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import AdvancedForms from "./AdvancedForms/AdvancedForms";


import {
  BrowserRouter,
  Link,
  Route,
  BrowserRouter as Router, Routes,
} from "react-router-dom";
import Menubar from "./Menubar";
import {
  Autocomplete, Box, Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider
} from "@mui/material";
import Icon from "react-multi-date-picker/components/icon";
import { EventForm } from "./EventForm";
import { UserContext } from "src/App";


const colors = {
  lightBlue: '#c1d8ff'
}



export const ModalBox = (props: any) => {
  const {
    openModal = true,
    closeModal,
    formInputs = {},
    children,
    headerText = 'Add New ???'
  } = props
  return <Dialog
    maxWidth="xs"
    open={openModal || false}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle
      id="form-dialog-title"
      style={{
        backgroundColor: '#00384e',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}
    >
      <div className="dialog-title" style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'self-start'
      }}>
        <div id="">{headerText}</div>
        <Icon
          name={'times'}
          style={{ color: 'white' }}
          onClick={() => {
            closeModal()
          }}
        />
      </div>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {children}
      </DialogContentText>
    </DialogContent>
  </Dialog>
}