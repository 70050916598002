import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
  useLayoutEffect,
} from "react";
import "./Sidebar.scss";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";
import { UserContext } from "../../App";
import { Close, Info, List, ShoppingCart } from "@mui/icons-material";

import { NavLink, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "src/helpers/Utils";
import { myColors } from "src/api";

/**
 * Responsible for displaying the Sidebar
 */
export default function Sidebar(props) {
  const { myContext, setMyContext } = useContext(UserContext);
  const {
    isSidebarOpen = false,
    myInfo = {},
    host,
    shares = [],
    eventTabs,
    hostTabs,
    // comments = [],
  } = myContext;
  const {
    // isSidebarOpen = false,
    // setIsSidebarOpen
  } = props;
  const { pathname } = useLocation();
  var learningPaths = [
    "/learning-center/all",
    "/learning-center/procurement",
    "/learning-center/energy",
    "/learning-center/installation",
    "/learning-center/maintenance",
    "/learning-center/other",
  ];

  // useEffect(() => {
  //     alert(isSidebarOpen)
  // }, [isSidebarOpen])

  return (
    <div
      style={{
        marginTop: -45,
        // zIndex: 100000,
        backgroundColor: myColors?.green,
      }}
      onMouseLeave={() => {
        setMyContext((existing) => {
          return {
            ...existing,
            isSidebarOpen: false,
          };
        });
      }}
      onTouchEnd={() => {
        setMyContext((existing) => {
          return {
            ...existing,
            isSidebarOpen: false,
          };
        });
      }}
    >
      <div
        className={
          isSidebarOpen ? "sidebar-active sidebar-wrapper" : "sidebar-wrapper"
        }
      >
        <CDBSidebar
          open={true}
          textColor="#fff"
          backgroundColor="#00384e"
          id="sidebar-inner-wrapper"
          style={{
            backgroundColor: myColors?.darkGreen,
          }}
        >
          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <div className="sidebar-menu-wrapper">
                {!myInfo?.token && (
                  <NavLink to="/private" activeClassName="activeClicked">
                    <CDBSidebarMenuItem
                      icon="chart-bar"
                      iconClassName="sidebar-menu-icon"
                    >
                      <span className="sidebar-item-label">Create Account</span>
                    </CDBSidebarMenuItem>
                  </NavLink>
                )}
                <NavLink
                  to="/"
                  // isActive={() => learningPaths.includes(pathname)}
                  activeClassName="activeClicked"
                  onClick={() => {
                    setMyContext((existing) => {
                      return {
                        ...existing,
                        openCreateEvent: true,
                      };
                    });
                  }}
                >
                  <CDBSidebarMenuItem
                    icon="book"
                    iconClassName="sidebar-menu-icon"
                  >
                    <span className="sidebar-item-label">Add Enterprise</span>{" "}
                    <Info
                      onClick={() => {
                        alert(
                          "An Enterprise is your Business, Project, Event, etc..."
                        );
                      }}
                    />
                  </CDBSidebarMenuItem>
                </NavLink>
                {/* <NavLink
                                    to="/messages"
                                    // isActive={() => learningPaths.includes(pathname)}
                                    activeClassName="activeClicked"

                                >
                                    <CDBSidebarMenuItem
                                        icon="book"
                                        iconClassName="sidebar-menu-icon"
                                    >
                                        <span className="sidebar-item-label">
                                            Chat
                                        </span>
                                    </CDBSidebarMenuItem>
                                </NavLink> */}
                {/* <NavLink
                                    // to="/"
                                    // isActive={() => learningPaths.includes(pathname)}
                                    activeClassName="activeClicked"
                                    onClick={() => {

                                        setMyContext((existing) => {
                                            return {
                                                ...existing,
                                                openAccessControl: true
                                            };
                                        });
                                    }}
                                >
                                    <CDBSidebarMenuItem
                                        icon="book"
                                        iconClassName="sidebar-menu-icon"
                                    >
                                        <span className="sidebar-item-label">
                                            Access
                                        </span>
                                    </CDBSidebarMenuItem>
                                </NavLink> */}

                <h5
                  style={{
                    marginLeft: 20,
                  }}
                >
                  Event Tabs
                </h5>
                <div style={{ border: "2px solid red" }}>
                  {eventTabs?.map((tab) => {
                    return (
                      <NavLink
                        to={`/event/65a496c667bd6f09e17beeae/${tab?.name}`}
                        // isActive={() => learningPaths.includes(pathname)}
                        activeClassName="activeClicked"
                      >
                        <CDBSidebarMenuItem
                          icon={tab?.icon || "book"}
                          iconClassName="sidebar-menu-icon"
                        >
                          <span className="sidebar-item-label">
                            {tab?.name
                              ? capitalizeFirstLetter(tab?.title)
                              : "NA"}
                          </span>
                        </CDBSidebarMenuItem>
                      </NavLink>
                    );
                  })}
                </div>
                <h5
                  style={{
                    marginLeft: 20,
                  }}
                >
                  Host Tabs
                </h5>
                {hostTabs?.map((tab) => {
                  return (
                    <NavLink
                      to={`/host/${myInfo?.handle}/${tab?.name}`}
                      // isActive={() => learningPaths.includes(pathname)}
                      activeClassName="activeClicked"
                    >
                      <CDBSidebarMenuItem
                        icon={tab?.icon || "book"}
                        iconClassName="sidebar-menu-icon"
                      >
                        <span className="sidebar-item-label">
                          {tab?.name ? capitalizeFirstLetter(tab?.title) : "NA"}
                        </span>
                      </CDBSidebarMenuItem>
                    </NavLink>
                  );
                })}
                {/*  <NavLink
                                    to="/jobsite-visual"
                                    activeClassName="activeClicked"
                                >
                                    <CDBSidebarMenuItem
                                        icon="map"
                                        iconClassName="sidebar-menu-icon"
                                    >
                                        <span className="sidebar-item-label">
                                            My Meetings
                                        </span>
                                    </CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink
                                    to="/meterics-and-formulas"
                                    activeClassName="activeClicked"
                                >
                                    <CDBSidebarMenuItem icon="handshake">
                                        <span className="sidebar-item-label">
                                            My Contacts
                                        </span>
                                    </CDBSidebarMenuItem>
                                </NavLink> */}
              </div>
            </CDBSidebarMenu>
          </CDBSidebarContent>
          <CDBSidebarFooter>
            <div style={{ paddingBottom: "100px" }}>
              <CDBSidebarMenu>
                <NavLink to="/feedback" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="comments">
                    <span
                      className="sidebar-item-label"
                      style={{ paddingRight: "100px" }}
                    >
                      Feedback
                    </span>
                  </CDBSidebarMenuItem>
                </NavLink>
              </CDBSidebarMenu>
            </div>
          </CDBSidebarFooter>
        </CDBSidebar>
      </div>
    </div>
  );
}
