import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
  useLayoutEffect,
} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import DatePicker from "react-multi-date-picker";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
  Radio,
  Avatar,
  Grid,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteAgendaDB,
  getAgendaDB,
  getAppointment,
  getAttendanceDB,
  getCalendarEvents,
  getRecurringDates,
  groupByNow,
  myColors,
  relatedPict,
  removeDuplicateObjects,
  saveAgendaDB,
  saveAppointment,
  saveEventDB,
} from "src/api";
import { UserContext } from "src/App";
import { CalendarCard } from "./CalendarCard";
import { EventForm } from "./EventForm";
import { TimeCard } from "./TimeCard";
import { useNavigate, useParams } from "react-router-dom";
import { TabListItems } from "./MediaSlider/TabListItems";
import { ButtonL } from "./AppIndex";
import {
  Beenhere,
  ChevronLeft,
  ChevronRight,
  DeleteOutline,
  EditCalendar,
} from "@mui/icons-material";
import { AttendeesComponent } from "./MediaSlider/AttendeesComponent";
import { ButtonFilterTabs } from "./MediaSlider/EventEditor";
import styled from "@emotion/styled";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const ScheduleFormComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    host,
    // myDates,
    data,
    appointments,
    // myEventSchedule,
    myEvents,
    action,
    dataInfo,
    allAccess,
    scheduleId,
  } = myContext;
  const {
    formInput,
    setFormInput,
    handleMydata,
    setMyEvents,
    setOpenSignin,
    scheduleInfo = {},
    editInfo,
  } = props;

  // const [dataInfo, setDataInfo] = useState<any>({})

  const [stepNumber, setStepNumber] = useState<number>(0);
  const refStartTime = useRef<any>("");
  const refEndTime = useRef<any>("");
  const [attendance, setAttendance] = useState<any>([]);
  const navigate = useNavigate();

  const params: any = useParams<any>();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  useEffect(() => {
    loadApp();
  }, [host?.handle, eventId]);

  const loadApp = useCallback(() => {
    (async () => {
      await getAttendanceDB({
        eventId: eventId,

        // handle: myInfo?.handle
      }).then((res: any) => {
        setAttendance(res);
      });
    })();
  }, [myInfo?.handle, host?.handle, eventId]);

  const [timeSeriesTemp, setTimeSeriesTemp] = useState<any>();

  const myDates = useMemo(() => {
    return scheduleInfo?.dates;
  }, [scheduleInfo]);

  useEffect(() => {
    var i = 0;
    const eventDate =
      formInput?.date && moment(formInput?.date)?.format("MM/DD/YY"); //datetimeTemp && datetimeTemp?.substring(0, 10)
    const myDates: any = [];
    const allDates: any = [];
    const myEventDates: any = [];
    const dedupeEventArray: any = [];
    scheduleInfo?.handle &&
      [scheduleInfo]?.map((rev: any) => {
        if (rev?.days) {
          const temp =
            rev?.days && typeof rev?.days == "string"
              ? JSON.parse(rev?.days)
              : rev?.days;
          // console.log('datesxxccx', temp)
          Promise.all(
            temp?.map((date: any) => {
              const data = getRecurringDates({
                start: date,
                end: moment(rev?.endDate)?.format("YYYY-MM-DD"),
              });
              data?.map((ref: any) => {
                const dat = moment(ref)?.format("MM/DD/YY");

                if (!allDates?.includes(dat)) {
                  allDates.push(dat);
                  myDates.push({
                    // ...myColor,
                    ...rev,
                    eventDate,
                    timeAm: dat, //moment(myTime)?.add(30 * i, 'minutes')?.format('hh:mm A')?.toString(),
                    time: rev?.startTime,
                    backgroundColor: "white",
                    color: "black",
                    disabled: false,
                  });

                  if (!dedupeEventArray?.includes(rev?.title)) {
                    dedupeEventArray.push(rev?.title);
                    myEventDates.push({
                      ...rev,
                      // timeAm: dat,//moment(myTime)?.add(30 * i, 'minutes')?.format('hh:mm A')?.toString(),
                      // time: rev?.startTime,
                      // backgroundColor: 'white',
                      // color: 'black',
                      // disabled: false
                    });
                  }
                }
              });
            })
          );
        }
      });

    const report = myDates?.filter((res: any) => res?.timeAm == eventDate);
    setTimeSeriesTemp({
      report,
      allDates,
      myEventDates,
    });
    // console.log("ccceeee", report);

    //used to filter event times based on dates
    return;
  }, [formInput?.date, attendance]);

  useLayoutEffect(() => {
    //use by dropdown menu to set list of event dates for a given user
    if (timeSeriesTemp?.allDates)
      setMyContext((existing: any) => {
        return {
          ...existing,
          myDates: timeSeriesTemp?.allDates,
        };
      });

    //use by dropdown menu to list events
    if (timeSeriesTemp?.myEventDates)
      setMyContext((existing: any) => {
        return {
          ...existing,
          myEvents: timeSeriesTemp?.myEventDates,
        };
      });
  }, [timeSeriesTemp]);

  const timeSeries = useMemo(() => {
    return timeSeriesTemp?.report;
  }, [timeSeriesTemp]);

  // console.log('timeSeries')
  if (!scheduleInfo?.name) {
    return (
      <div>{`  ${host?.name}'s has no public meetings at this time.`}</div>
    );
  }
  console.log("getAttendanceDBvvvv", editInfo);

  if (!scheduleInfo?.title) {
    return (
      <h5
        style={{
          color: myColors?.darkGreen,
        }}
      >{`${scheduleInfo?.title || ""} ${scheduleInfo?.title ? "Schedule" : "No meeting scheduled"
        }`}</h5>
    );
  }

  return (
    <div>
      {eventId ? (
        <>
          {allAccess && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonL
                style={{
                  margin: 0,
                }}
                onClick={() => {
                  if (scheduleInfo?.title) {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: true,
                        editEvent: "schedule",
                      };
                    });
                  } else {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: true,
                        // editEvent: 'schedule',
                      };
                    });
                  }
                }}
              >
                Edit Schedule
                <EditCalendar />
              </ButtonL>{" "}
            </div>
          )}
          {/* row */}
          <div>
            {myDates?.length > 0 && (
              // Col
              <div>
                {myDates?.length > 0 && (
                  <StyledCard>
                    <h4 style={{
                      color: 'gray'
                    }}>Reserve Your Spot</h4>
                    <Grid container spacing={6}>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        {/* <CardWrapper style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}> */}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <h5 style={{
                            textAlign: 'center',
                            color: 'gray'
                          }}>Select From Available Dates*</h5>
                          <CalendarCard
                            myDates={myDates}
                            onClick={(e: any) => {
                              if (moment().diff(e.dat.date, "day") > 0) {
                                let text = "Event over";
                                if (window.confirm(text) == true) {
                                  text = "You pressed OK!";
                                  return;
                                } else {
                                  text = "You canceled!";
                                  return;
                                }
                              }
                              setFormInput({
                                ...formInput,
                                date: e.dat.date,
                              });
                            }}
                            {...props}
                            startDay={""}
                            eventId={eventId}
                            setFormInput={setFormInput}
                            formInput={formInput}
                          />
                        </div>
                      </Grid>

                      <Grid item lg={6} md={12} sm={12} xs={12}>

                        {timeSeries?.[0] && (
                          <TimeCard
                            {...props}
                            dataInfo={dataInfo}
                            scheduleInfo={scheduleInfo}
                            // setDataInfo={setDataInfo}
                            data={data}
                            // handleClick={handleClick}
                            myInfo={myInfo}
                            setFormInput={setFormInput}
                            formInput={formInput}
                            eventId={eventId}
                            appointments={appointments}
                            timeSeries={timeSeries}
                          />
                        )}
                      </Grid>

                      {/* </CardWrapper> */}
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <h6 style={{
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}>Related Media</h6>
                        <CardWrapper style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          // padding: 20,
                          flexDirection: 'column'
                        }}>
                          {scheduleInfo?.picture && <>
                            <img
                              src={scheduleInfo?.picture}
                              alt="Example"
                              style={{
                                // padding: 20,
                                display: 'flex',
                                alignItems: 'center',
                                height: 300,
                                width: 300,
                              }}
                            />
                          </>}
                        </CardWrapper>
                      </Grid>
                    </Grid>
                  </StyledCard>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {(action == "schedule" || action == "edit") && (
                    <EventForm
                      {...props}
                      eventId={eventId}
                      setFormInput={setFormInput}
                      data={formInput}
                    />
                  )}
                </div>
              </div>
            )}
            {/* Col */}
            {attendance?.[0] && <div>
              <StyledCard>
                <h2>{"Attendees"}</h2>
                <div
                  onClick={() => {
                    navigate(`/host/${myInfo.handle}/about`);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    flexWrap: "wrap",
                    height: 200,
                    overflowY: "auto",
                    gap: 10,
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      alt={"NA"}
                      src={
                        myInfo?.img ||
                        "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                      }
                      sx={{ width: 50, height: 50 }}
                      style={{
                        backgroundColor: "#3399cc",
                      }}
                    />
                    <div
                      style={{
                        fontSize: 9,
                      }}
                    >
                      {myInfo?.name?.length >= 6
                        ? myInfo?.name?.substring(0, 6)
                        : myInfo?.name}
                    </div>
                  </div> */}

                  {attendance?.[0] && removeDuplicateObjects({ arr: attendance, key: 'name' })
                    ?.filter((ref: any) => ref?.hanlde != myInfo?.handle)
                    ?.map((res: any, index: number) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Avatar
                            alt={res?.name || "NA"}
                            src={
                              res.img ||
                              relatedPict[index] ||
                              "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                            }
                            sx={{ width: 50, height: 50 }}
                            style={{
                              backgroundColor: "#3399cc",
                            }}
                          />
                          <div
                            style={{
                              fontSize: 9,
                            }}
                          >
                            {res?.name?.length >= 6
                              ? res?.name?.substring(0, 6)
                              : res?.name}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </StyledCard>
            </div>}
          </div>
        </>
      ) : null}
    </div>
  );
};

const StyledCard = styled.div`
  width: "100%";
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fff;
`;
const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    margin: 0;
    margin-left: 20px;
    margin-bottom: -10px;
    text-align: start;
    @media (max-width: 756px) {
      margin-left: 0px;
      text-align: center;
    }
  }

  @media (max-width: 756px) {
    flex-direction: column;
  }
`;
