import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";
import { useRef } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import _ from "lodash";
import GoogleMapReact from "google-map-react";
import ReactDOM from "react-dom";
// import { useTranslation } from "react-i18next";

import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import {

  deleteDataDB,
  getDataDB,
  groupByNow,
  jumpToReleventDiv,
  myColors,
  saveDataDB,
} from "src/api";
import moment from "moment";
import { Assessment, CalendarMonth, CheckBox, CheckBoxOutlineBlank, CheckCircle, Close, Edit, GppGood, HowToReg, PersonOff, PersonRounded, RemoveCircleOutline, Share, X } from "@mui/icons-material";
import { ShareButton } from "./AdvertDetailsCard";
import { UserContext } from "src/App";
import { RR } from "./Events";
import { ButtonL } from "./AppIndex";
import { useParams } from "react-router-dom";
import { ImportDataComponent } from "./MediaSlider/ImportDataComponent";
import { AddSkillSection, EditSkillsModalWrapper, MediaCardGrid, PostViaLinkComponent, SkillChildWrapper } from "./MediaSlider/TabListItems";
import { InputAgenda, MyVideoUploader } from "./MediaSlider/MyVideoUploader";
import Select from "react-select/creatable";
import { MyFileUploader } from "./MediaSlider/MyFileUploader";
import { ListItems } from "./ListItems";
import CarrousselCard from "./CarrousselCard";
import { ListCard } from "./ListCard";
import CarrousselListCard from "./CarrousselListCard";

// const daterangeInfo = getDateRange({
//   firstDate: dateRange?.startDate,
//   lastDate: dateRange?.endDate,
// })

function WorldMap(props: any) {

  const {
    handleDataArray,
    resource
    // dataArray, setDataArray
  } = props;
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    contextRefresh = false,
    facilityFilterId,
    infoByDate = {},
    infoAll = [],
    // dataArray = [],
    dateRange = {},
    selectedInfo = {},
    eventInfo = {},
    myInfo = {},
    editInfo,
    host,
    allAccess
  } = myContext;
  const { dataInfo = [], groupBy } = myContext;
  const panoramaRef = React.createRef<any>();
  const [zoomVal, setZoomVal] = useState(15);

  const [showFormManager, setShowFormManager] = useState<boolean>(false);
  const [myLatLng, setMyLatLng] = useState<any>('nbnnbn');
  const [formInput, setFormInput] = useState<any>({})
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [checkedInStatus, setCheckedInStatus] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState<any>(null);

  const [selected, setSelected] = useState<any>([])
  const [inputField, setInputField] = useState<any>('')
  const [isList, setIsList] = useState<boolean>(false)
  const [addCheckin, setAddCheckin] = useState<boolean>(true)



  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [typeFile, setTypeFile] = useState<any>("upload");


  const [myMarkers, setMyMarkers] = useState<any>([]);
  const [allAddress, setAllAddress] = useState<any>([]);
  const [dataArray, setDataArray] = useState<any>([]);

  const [groupByFacility, setGroupByFacility] = useState<any>({});
  const [dataTopic, setDataTopic] = useState<any>([])
  const [attendance, setAttendance] = useState<any>([])


  const params: any = useParams<any>();

  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [allDataRecord, setAllDataRecord] = useState<any>([])
  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);


  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const apiKey: any = {
    key: "AIzaSyACYhltJ6rt1RRKcrgCKCobmKrLE-qT5L4", // Your API key here.
    language: "en", // Default language for responses.
    region: "es", // Default region for responses.
  };
  setDefaults(apiKey);

  const [options, setOptions] = useState<any>([
    {
      latLng: [29.734170, -95.514120],
      address: "11509 north lamer blvd Austin, TX 78753",
      title: eventInfo?.title || 'Host Location',
      img: eventInfo?.img
    }
  ]);
  console.log("resultxxx", eventInfo);


  useEffect(() => {
    (async () => {
      if (eventInfo?.address) {

        const resultInfo: any = await fromAddress(
          eventInfo?.address
        ).then(({ results }: any) => {
          const { lat, lng } = results[0].geometry.location;
          const result = {
            latLng: [lat, lng],
            address: "11509 north lamer blvd Austin, TX 78753",
            name: eventInfo?.addres,
            // capacity: eventInfo.capacity,
            title: eventInfo?.title || 'sfdsfds',
            img: eventInfo?.img || eventInfo?.picture || eventInfo?.iamge || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'
          };
          // console.log("resultsss", result);
          return result;
        });

        setOptions([
          ...resultInfo
        ])
      }
      // else if (eventInfo?.title) {
      //   setOptions([
      //     ...options,
      //     {
      //       latLng: [29.734170, -95.514120],
      //       address: "11509 north lamer blvd Austin, TX 78753",
      //       title: eventInfo?.title,
      //       img: eventInfo?.img
      //     }
      //   ])
      // }


    })();
  }, [
    eventInfo?.title
  ]);

  const defaultProps = useMemo(() => {
    return {
      center: {
        lat: options?.[0]?.latLng?.[0] || 42.345573,
        lng: options?.[0]?.latLng?.[1] || -71.098326,
      },
      // zoom: -100,
      zoom: 12,

    };
  }, [options]);

  const myData = useMemo(() => {
    return [
      ...(formInput?.title
        ? [
          {
            file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            ...formInput,
          },
        ]
        : []),
      ...dataRecord
        ?.filter((res: any) => res.title != formInput?.title)
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title,
            // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          };
        }),
    ];
  }, [
    // formInput?.title,
    dataRecord
  ]);

  // console.log('defaultProps', defaultProps)

  const AnyReactComponent = ({ text }: any) => {
    const [showDetails, setShowDetails] = useState<any>(false);
    const item = text;
    console.log('vxxxx', text)

    return (
      <div
        style={{
          position: "relative",
        }}
      >
        {showDetails && (
          <ModalWrapper
            onMouseLeave={() => {
              setShowDetails(false);
            }}
          >


          </ModalWrapper>
        )}
        <div
          onMouseEnter={() => {
            setShowDetails(true);
          }}
          style={{
            display: 'flex',
            // height: 60,
            width: 160,
            backgroundColor: 'white',
            borderRadius: 100,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img style={{
            width: 70,
            borderRadius: 40
          }}
            src={text?.img || text?.picture || text?.iamge || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'}
            alt="Event Logo"
          />
          {text?.title}
          <CheckCircle style={{
            color: '#407ad6',
            fontSize: 40
          }} />
          <div>


          </div>



        </div>
      </div>
    );
  };

  // define the function that finds the users geolocation
  const getUserLocation = useCallback(() => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          // save the geolocation coordinates in two variables
          const {
            latitude,
            longitude
          } = position?.coords;;
          // update the value of userlocation variable
          if (latitude && longitude) {
            setUserLocation({ latitude, longitude });
            setOptions([
              ...options,
              {
                latLng: [latitude, longitude],
                address: "11509 north lamer blvd Austin, TX 78753",
                title: myInfo?.name,
                img: eventInfo?.img || eventInfo?.picture || eventInfo?.iamge || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'
              }
            ])
          }
        },
        // if there was an error getting the users location
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, [
    eventInfo?.title
  ])

  useEffect(() => {
    getUserLocation()
    getDataDB({
      query: {
        eventId: eventId,
        topic: formInput?.topic
        // handle: myInfo?.handle
      },
      resource: "calendar_attendance",
    }).then(res => {
      setAttendance(res?.[0] ? res : [])
      const filterUser = res?.filter((ref: any) => {
        return ref.handle == myInfo?.handle
      })?.[0]
      setCheckedInStatus(filterUser ? true : false)
      setRefreshing(false)
    })

  }, [
    refreshing,
    formInput?.topic
  ])

  const uploadTypes = [
    {
      label: 'Upload',
      value: 'upload',
      onclick: (type: any) => {
        setTypeFile(type)
      }
    },
    {
      label: 'link',
      value: 'link',
      onclick: (type: any) => {
        setTypeFile(type)
      }
    },
    {
      label: 'search',
      value: 'search',
      onclick: (type: any) => {
        setTypeFile(type)
      }
    },

    // {
    //   label: 'API',
    //   value: 'api',
    //   onclick: (type: any) => {
    //     setTypeFile(type)
    //   }
    // }, {
    //   label: 'AI',
    //   value: 'ai',
    //   onclick: (type: any) => {
    //     setTypeFile(type)
    //   }
    // },
    // , {
    //   label: 'Import',
    //   value: 'import',
    //   onclick: (type: any) => {
    //     setTypeFile(type)
    //   }
    // }
  ]

  useEffect(() => {
    // alert(resource)

    const data = {
      query: {
        ...(eventId
          ? {
            eventId: eventId,
          }
          : {}),
        handle: host?.handle,
      },
      resource: resource,//'calendar_event_user_checkin', 
      check: ["handle"],
    };
    getDataDB(data).then((res) => {
      // console.log("hostTabsxxccnn", eventSubTab, resource, eventId, res);
      setDataRecord(res?.[0] ? res : []);
      setAllDataRecord(res?.[0] ? res : []);
      const grouped = groupByNow({
        data: res,
        key: "topic",
        id: 2,
      });
      const groupedInfo = grouped && Object.keys(grouped)
      console.log("hostTabsxxccnn", groupedInfo)

      groupedInfo?.[0] && setDataTopic(groupedInfo)
      setRefreshing(false);
      setFormInput({});
    });



  }, [
    host?.handle,
    eventId,
    eventTab,
    eventSubTab,
    myInfo?.handle,
    params.hostId,
    refreshing,
  ]);

  const handleSaveCheckin = useCallback(() => {
    const data = {
      query: {
        // ...eventInfo,
        ...formInput,
        accept: true,
        eventId: eventId,
        topic: formInput?.topic,
        title: formInput?.title,
        img: myInfo?.img,
        name: myInfo?.name,
        picture: formInput?.img || formInput?.picture,
        handle: myInfo?.handle
      },
      resource: `calendar_attendance`,
      check: ["handle", "topic"],
      myInfo,
    };

    saveDataDB(data).then((res: any) => {
      setRefreshing(true);
    });

  }, [
    formInput?.topic,
    myInfo?.handle,
    formInput?.title
  ])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log('userLocation', options)

  return (
    <div>
      <div
        id={'checkedInStatus'}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
        }}
      >
        {addCheckin && (
          <>
            {!editInfo && <div>
              {checkedInStatus ? <div

                className="title" style={{
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  padding: 10,
                  borderRadius: 20
                }}>
                You are checkedin to {formInput?.title} <HowToReg />
              </div>
                : <div className="title">
                  Pending Checkin to {formInput?.title} <PersonOff />
                </div>
              }
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',

              }}
              >
                <Close
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    top: 10,
                    right: 20
                  }}
                  onClick={() => {
                    setFormInput({})
                    setAddCheckin(false)
                  }} />
                <div
                  // role="button"
                  onClick={() => {
                    if (checkedInStatus) {
                      setFormInput({})
                      setAddCheckin(false)
                      return
                    }
                    let text = "Checkin now?";
                    if (window.confirm(text) == true) {
                      handleSaveCheckin()

                      text = "You pressed OK!";
                    } else {
                      text = "You canceled!";
                    }
                  }}
                  role="button"

                  style={{
                    backgroundColor: checkedInStatus ? 'lightgray' : 'lightblue',
                    borderRadius: 100,
                    width: 150,
                    height: 150,
                    // margin: 20,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                  }}>
                  <PersonOff style={{
                    textAlign: 'center',
                    fontSize: '5.9em'
                  }} />
                  <div>
                    {checkedInStatus ? 'Checked in' : 'Check in'}
                  </div>
                </div>
              </div>
            </div>}
            {!editInfo && formInput?.topic && !myInfo?.token && <>
              <div style={{
              }}>
                <InputAgenda style={{
                  padding: 10,
                  margin: 10
                }}>
                  <form
                    style={{
                      width: windowWidth,
                      justifyContent: 'space-between',
                      display: "flex",
                      flexDirection: "row",
                      // margin: 20
                      // width: 300,
                    }}
                  >
                    {!checkedInStatus && uploadTypes?.map((res: any) => {
                      return <>
                        <input
                          onClick={() => {
                            // setTypeFile('upload')
                            res?.onclick(res?.value)
                          }}
                          type="radio"
                          id="view2"
                          name="view"
                          value={res?.value}
                          checked={typeFile == res?.value || !typeFile}
                        // {...(typeFile
                        //   ? { checked: true }
                        //   : {})}
                        />
                        <label>{res?.label}</label>
                        <br />
                      </>
                    })}

                  </form>
                </InputAgenda>
              </div>

              {typeFile != 'import'
                ? <>
                  <EditSkillsModalWrapper id={'formInput'}>

                    <AddSkillSection>
                      <h2>Full Name</h2>
                      <SkillChildWrapper>
                        <InputAgenda onSubmit={(e: any) => { }}>
                          <input
                            type={"text"}
                            onChange={(e: any) =>
                              setFormInput({
                                ...formInput,
                                title: e.target?.value,
                              })
                            }
                            value={formInput?.title || ""}
                            placeholder={"Add Full Name ..."}
                            required
                          />
                        </InputAgenda>
                      </SkillChildWrapper>
                    </AddSkillSection>
                    <>
                      <AddSkillSection>
                        <h2>Email</h2>
                        <SkillChildWrapper>
                          <InputAgenda onSubmit={(e: any) => { }}>
                            <input
                              type={"text"}
                              onChange={(e: any) =>
                                setFormInput({
                                  ...formInput,
                                  email: e.target?.value,
                                })
                              }
                              value={formInput?.email || ""}
                              placeholder={"Add Email ..."}
                              required
                            />
                          </InputAgenda>
                        </SkillChildWrapper>
                      </AddSkillSection>


                      {typeFile == "link"
                        ? <PostViaLinkComponent
                          {...props}
                        />

                        : typeFile == "upload"
                          ? <>
                            <AddSkillSection>
                              <h2>Add Image (optional)</h2>
                              <MyFileUploader
                                {...props}
                                allowAdd={false}
                                defaultImage={formInput?.img}
                                refreshing={refreshing}
                                setRefreshing={setRefreshing}
                                setFormInput={setFormInput}
                                formInput={formInput}
                                uploadNow={uploadNow}
                                handleSaveImage={(e: any) => {
                                  setUploadNow(true);
                                }}
                              />
                            </AddSkillSection>
                            <AddSkillSection>
                              <h2>Add Video (optional)</h2>
                              <MyVideoUploader
                                {...props}
                                defaultImage={formInput?.video || ''}
                                refreshing={refreshing}
                                setRefreshing={setRefreshing}
                                setFormInput={setFormInput}
                                formInput={formInput}
                                videoKey={'video'}
                                uploadNow={uploadNow}
                                handleSaveImage={(e: any) => {
                                  setUploadNow(true);
                                }}
                                resource={resource}
                              />
                            </AddSkillSection>
                          </>
                          : typeFile == "api" && myInfo?.handle == eventInfo?.handle
                            ? <PostViaLinkComponent
                              {...props}
                            />
                            : typeFile == 'ai' && myInfo?.handle == eventInfo?.handle
                              ? <PostViaLinkComponent
                                {...props}
                              />
                              : typeFile == "search" && myInfo?.handle == eventInfo?.handle
                                ? <AddSkillSection>
                                  <h2>Add Viewers (optional)</h2>
                                  <Select
                                    styles={{
                                      control: styles => ({ ...styles, width: 400 }),
                                    }}
                                    onInputChange={(e: any) => {
                                      // console.log('personinfobb', e)
                                      setInputField(e)
                                    }}
                                    defaultValue={formInput?.guest || selected
                                      ? [...(formInput?.guest || []), ...selected]
                                      : [{
                                        label: 'Default',
                                        value: 'Default'
                                      }]}
                                    onChange={(e: any) => {
                                      console.log('personinfo', e)
                                      setSelected(e);
                                      setFormInput({
                                        ...formInput,
                                        ...e
                                      })
                                    }}
                                    isMulti
                                    isClearable
                                    options={options}
                                    placeholder="Select or Invite by Email"
                                    classNamePrefix="selectform"
                                  />
                                </AddSkillSection> : null}

                    </>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {formInput?._id && <ButtonL
                        disabled={
                          formInput?.title
                            ? // addType?.file ||
                            // addType?.link ||
                            // formInput?.file ||
                            // formInput?.link
                            false
                            : true
                        }
                        style={{ margin: 0, marginRight: 20 }}
                        onClick={() => {
                          if (!myInfo?.handle) {
                            alert("Please login");
                            return;
                          }

                          let text = "Are you sure you wand to delete";
                          if (window.confirm(text) == true) {
                            text = "You pressed OK!";;
                          } else {
                            text = "You canceled!";
                            return
                          }
                          const data = {
                            id: formInput?._id,
                            resource: resource,
                          };
                          // console.log('formData', data)
                          deleteDataDB(data).then((res) => {
                            setUploadNow(true);
                          });
                        }}
                      >
                        Delete
                      </ButtonL>}
                      <ButtonL
                        disabled={
                          (formInput?.title && formInput?.topic)
                            ? false
                            : true
                        }
                        style={{ margin: 0 }}
                        onClick={() => {
                          if (!formInput?.topic) {
                            alert('Please make sure to add a Topic')
                            return
                          }
                          if (!myInfo?.handle) {
                            alert("Please login");
                            return;
                          }
                          if (!formInput?.title) {
                            alert("Title required");
                            return;
                          }
                          const data = {
                            query: {
                              ...formInput,
                              handle: myInfo?.handle,
                              eventId: eventId,
                            },
                            resource: resource, //'calendar_event_user_checkin',
                            check: ["handle", "title"],
                            myInfo,
                          };
                          // console.log('formData', data)
                          saveDataDB(data).then((res) => {
                            setUploadNow(true);
                          });
                        }}
                      >
                        Add
                      </ButtonL>
                    </div>
                  </EditSkillsModalWrapper>
                </> : typeFile == "import"
                  ? <div style={{
                    margin: 10
                  }}>
                    <ImportDataComponent
                      {...props}
                      resource={resource}
                      topic={formInput?.topic}
                    />
                  </div> : null}

            </>}
          </>
        )}
        {!editInfo && <div
          style={{
            marginTop: 20,
            marginBottom: 40,
          }}
        >
          {!isList ? (
            <MediaCardGrid>
              {allDataRecord?.map((item: any) => {
                const checkedInStatusInfo = attendance?.filter((res: any) => {
                  return res?.accept == true
                    && res?.title == item?.title
                    && res?.handle == myInfo?.handle
                })?.[0] ? true : false

                return (
                  <CarrousselListCard
                    {...props}
                    {...item}
                    item={item}
                    setFormInput={setFormInput}
                    formInput={formInput}
                    menuProps={
                      [
                        {
                          label: "Person",
                          icon: checkedInStatusInfo ? "user" : "user-slash",
                          bgColor: checkedInStatusInfo ? 'green' : 'red',
                          iconText: checkedInStatusInfo ? 'Checked In' : 'Checkin',
                          iconSize: 24,
                          onClick: () => {
                            jumpToReleventDiv("checkedInStatus");
                            setAddCheckin(true)

                            // setFormInput(res)
                            // setRecordKey(res?.title)
                            const data = [...allDataRecord]?.filter(
                              (reh) => reh.topic == item?.topic
                            );
                            console.log('dataxxxxx', data)
                            setDataRecord(data);
                            setFormInput({
                              // ...formInput,
                              ...item,
                              topic: item?.topic || item?.title,
                            });


                          },
                        }
                      ]
                    }
                  />
                );
              })}
            </MediaCardGrid>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 20,
              }}
            >
              {allDataRecord?.map((item: any) => {
                return (
                  <div
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <ListCard
                      {...props}
                      {...item}
                      item={item}
                      setFormInput={setFormInput}
                      formInput={formInput}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>}

        {/* {dataTopic?.[0] &&
          dataTopic?.map((topic: any, index: number) => {
            // const rep = allDataRecord?.filter((ref: any) => {
            //   return ref?.title == res?.title
            // })
            // console.log('vvvvdee', res)
            return (
              <ButtonL
                style={{
                  whiteSpace: "none",
                  backgroundColor:
                    formInput?.topic == topic
                      ? myColors?.brown
                      : myColors?.green,
                }}
              >
                <div
                  onClick={() => {
                    // setFormInput(res)
                    // setRecordKey(res?.title)
                    const data = [...allDataRecord]?.filter(
                      (reh) => reh.topic == topic
                    );
                    console.log('dataxxxxx', data)
                    setDataRecord(data);
                    setFormInput({
                      // ...formInput,
                      topic: topic,
                    });
                  }}
                >
                  {" "}
                  {!topic || topic == "undefined" ? "Default" : topic}
                </div>
                {allAccess && topic && (
                  <RemoveCircleOutline
                    style={{
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      let text = "Sure you want to delete?";
                      const item = allDataRecord?.filter(
                        (reh: any) => reh.topic == topic
                      )?.[0];

                      if (window.confirm(text) == true) {
                        deleteDataDB({
                          id: item?._id,
                          resource: resource,
                        }).then((res) => {
                          setRefreshing(true);
                        });
                        text = "You pressed OK!";
                      } else {
                        text = "You canceled!";
                      }
                    }}
                  />
                )}
              </ButtonL>
            );
          })} */}
      </div>
      {/* {editInfo && (
        <>

          <AddSkillSection>
            <h2>Create or Select Topic <span style={{ color: 'red' }}>(Required)</span></h2>
            <SkillChildWrapper>
              <Select
                styles={{
                  control: styles => ({ ...styles, width: 400 }),
                }}
                defaultValue={formInput?.topic
                  ? [
                    {
                      label: formInput?.topic,
                      value: formInput?.topic,
                    }
                  ]
                  : [{
                    label: 'Default',
                    value: 'Default'
                  }]}
                onChange={(e: any) => {
                  console.log('personinfo2', e)
                  // setSelectedTopic(e);
                  if (e?.value)
                    setFormInput({
                      ...formInput,
                      topic: e.value,
                    })
                }}
                isClearable
                options={(dataTopic && dataTopic?.[0] != 'undefined')
                  ? dataTopic?.map((res: any) => {
                    return {
                      label: res,
                      value: res
                    }
                  })
                  : []}
                placeholder="Select or Add"
                classNamePrefix="selectform"
              />
            </SkillChildWrapper>
          </AddSkillSection>
          {formInput?.topic && <div style={{
          }}>
            <InputAgenda style={{
              padding: 10,
              margin: 10
            }}>
              <form
                style={{
                  width: window.innerWidth,
                  justifyContent: 'space-between',
                  display: "flex",
                  flexDirection: "row",
                  // margin: 20
                  // width: 300,
                }}
              >
                {uploadTypes?.map((res: any) => {
                  return <>
                    <input
                      onClick={() => {
                        // setTypeFile('upload')
                        res?.onclick(res?.value)
                      }}
                      type="radio"
                      id="view2"
                      name="view"
                      value={res?.value}
                      checked={typeFile == res?.value || !typeFile}
                    // {...(typeFile
                    //   ? { checked: true }
                    //   : {})}
                    />
                    <label>{res?.label}</label>
                    <br />
                  </>
                })}

              </form>
            </InputAgenda>
          </div>}

          {formInput?.topic && <>
            {typeFile != 'import'
              ? <>
                <EditSkillsModalWrapper id={'formInput'}>
                  <AddSkillSection>
                    <h2>Add New Item Title</h2>
                    <SkillChildWrapper>
                      <InputAgenda onSubmit={(e: any) => { }}>
                        <input
                          type={"text"}
                          onChange={(e: any) =>
                            setFormInput({
                              ...formInput,
                              title: e.target?.value,
                            })
                          }
                          value={formInput?.title || ""}
                          placeholder={"Add title ..."}
                          required
                        />
                      </InputAgenda>
                    </SkillChildWrapper>
                  </AddSkillSection>
                  <>

                    <AddSkillSection>
                      <h2>Add Description / Details (Optional)</h2>
                      <SkillChildWrapper>
                        <InputAgenda onSubmit={(e: any) => { }}>
                          <input
                            type={"text"}
                            onChange={(e: any) =>
                              setFormInput({
                                ...formInput,
                                description: e.target?.value,
                              })
                            }
                            value={formInput?.description || ""}
                            placeholder={"Add title ..."}
                            required
                          />
                        </InputAgenda>
                      </SkillChildWrapper>
                    </AddSkillSection>

                    <AddSkillSection>
                      <h2>Add Location (Required)</h2>
                      <SkillChildWrapper>
                        <InputAgenda onSubmit={(e: any) => { }}>
                          <input
                            type={"text"}
                            onChange={(e: any) =>
                              setFormInput({
                                ...formInput,
                                location: e.target?.value,
                              })
                            }
                            value={formInput?.location || ""}
                            placeholder={"Add Meetme link or address ..."}
                            required
                          />
                        </InputAgenda>
                      </SkillChildWrapper>
                    </AddSkillSection>


                    {typeFile == "link"
                      ? <PostViaLinkComponent
                        {...props}
                      />

                      : typeFile == "upload"
                        ? <>
                          <AddSkillSection>
                            <h2>Add Image (optional)</h2>
                            <MyFileUploader
                              {...props}
                              allowAdd={false}
                              defaultImage={formInput?.img}
                              refreshing={refreshing}
                              setRefreshing={setRefreshing}
                              setFormInput={setFormInput}
                              formInput={formInput}
                              uploadNow={uploadNow}
                              handleSaveImage={(e: any) => {
                                setUploadNow(true);
                              }}
                            />
                          </AddSkillSection>
                          <AddSkillSection>
                            <h2>Add Video (optional)</h2>
                            <MyVideoUploader
                              {...props}
                              defaultImage={formInput?.video || ''}
                              refreshing={refreshing}
                              setRefreshing={setRefreshing}
                              setFormInput={setFormInput}
                              formInput={formInput}
                              uploadNow={uploadNow}
                              handleSaveImage={(e: any) => {
                                setUploadNow(true);
                              }}
                              resource={resource}
                            />
                          </AddSkillSection>
                        </>
                        : typeFile == "api"
                          ? <PostViaLinkComponent
                            {...props}
                          />
                          : typeFile == 'ai'
                            ? <PostViaLinkComponent
                              {...props}
                            />
                            : null}

                  </>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {formInput?._id && <ButtonL
                      disabled={
                        formInput?.title
                          ? // addType?.file ||
                          // addType?.link ||
                          // formInput?.file ||
                          // formInput?.link
                          false
                          : true
                      }
                      style={{ margin: 0, marginRight: 20 }}
                      onClick={() => {
                        if (!myInfo?.handle) {
                          alert("Please login");
                          return;
                        }

                        let text = "Are you sure you wand to delete";
                        if (window.confirm(text) == true) {
                          text = "You pressed OK!";;
                        } else {
                          text = "You canceled!";
                          return
                        }
                        const data = {
                          id: formInput?._id,
                          resource: resource,
                        };
                        // console.log('formData', data)
                        deleteDataDB(data).then((res) => {
                          setUploadNow(true);
                        });
                      }}
                    >
                      Delete
                    </ButtonL>}
                    <ButtonL
                      disabled={
                        (formInput?.title && formInput?.topic)
                          ? false
                          : true
                      }
                      style={{ margin: 0 }}
                      onClick={() => {
                        if (!formInput?.topic) {
                          alert('Please make sure to add a Topic')
                          return
                        }
                        if (!myInfo?.handle) {
                          alert("Please login");
                          return;
                        }
                        if (!formInput?.title) {
                          alert("Title required");
                          return;
                        }
                        const data = {
                          query: {
                            ...formInput,
                            handle: myInfo?.handle,
                            eventId: eventId,
                          },
                          resource: resource,
                          check: ["handle", "title"],
                          myInfo,
                        };
                        // console.log('formData', data)
                        saveDataDB(data).then((res) => {
                          setUploadNow(true);
                        });
                      }}
                    >
                      Add
                    </ButtonL>
                  </div>
                </EditSkillsModalWrapper>
              </> : typeFile == "import"
                ? <div style={{
                  margin: 10
                }}>
                  <ImportDataComponent
                    {...props}
                    resource={resource}
                    topic={formInput?.topic}
                  />
                </div> : null}
          </>}
        </>
      )} */}





      {
        showFormManager && myLatLng && (<div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: 10,

        }}>
          <input
            className="form-control"
            type="text"
            // value={data?.title}
            onChange={(e: any) => {
              setFormInput({
                ...formInput,
                email: e.target?.value
              })
            }
            }
            style={{
              width: 300,
              alignSelf: 'center',
              alignItems: 'center'
              // backgroundColor: 'gray'
            }}
            value={formInput?.email || ''}
            required
            placeholder="Email"
          />
          <ButtonL
            onClick={() => {
              if (formInput?.email) {
                getDataDB({
                  query: {
                    handle: formInput?.email,
                  },
                  resource: "calendar_users",
                }).then(res => {
                  if (res?.[0]) {
                    const data = {
                      query: {
                        eventId: eventId,
                        ...formInput,
                        handle: myInfo?.handle
                      },
                      resource: `calendar_event_checkin`,
                      check: ["handle", "eventId"],
                      myInfo,
                    };
                    // console.log("formDatacc", data);
                    saveDataDB(data).then((res) => {
                      setFormInput({})
                      alert('Thank you, checkin completed')
                      // setRefreshing(true);
                    });
                  } else {
                    alert('Email doesnt exist in the event database, please rsvp with you GuestNet account or your email')

                  }

                }).catch(err => {
                  alert('err')
                })
              } else {
                alert('Email doesnt exist in the event database, please rsvp with you GuestNet account or your email')
              }
            }}>
            Checkin
          </ButtonL>
          <ButtonL
            onClick={() => {
              if (myInfo?.handle) {

                handleSaveCheckin()
              } else {
                alert('This account is not allowed at the given event.')
              }


            }}>
            GuestNet
          </ButtonL>
        </div>
        )
      }
      <div style={{ height: "93vh", width: "100%" }}>
        {formInput?.location && options?.[0] && defaultProps.center && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyACYhltJ6rt1RRKcrgCKCobmKrLE-qT5L4",
            }}
            defaultCenter={defaultProps.center}
            // zoom state
            // defaultZoom={-100}
            defaultZoom={12}
            zoom={zoomVal}
            onClick={(e: any) => {
              console.log('kkkkk', e)
              setMyLatLng(`${e.lat}|${e.lng}`)
              setTimeout(() => {
                setShowFormManager(true)

              }, 200)

            }}
          >
            {/* {userLocation && userLocation?.latitude && <AnyReactComponent
              key={"ssddd"}
              lat={userLocation?.latitude}
              lng={userLocation?.longitude}
              text={{
name:
              }}

            />} */}
            {options?.map((res: any, index: any) => {
              // console.log('options',options)
              return (
                <AnyReactComponent
                  key={index + "sdsfsf"}
                  lat={res?.latLng?.[0]}
                  lng={res?.latLng?.[1]}
                  text={res}

                />
              );
            })}
          </GoogleMapReact>
        )}
      </div>
    </div >
  );
}

const ModalWrapper = styled.div`
      padding: 10px;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: -40px;
      left: 30px;
      z-index: 2;
      max-height: 300px;
      overflow-y: auto;
      width: fit-content;
      font-size: 9px;
      border-radius: 10px;
      background: white;
      color: black;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      &::-webkit-scrollbar {
        width: 4px;
  }
      &::-webkit-scrollbar-thumb {
        background - color: #376fd0;
      border-radius: 2px;
  }
      &::-webkit-scrollbar-track {
        background - color: #dfe8f6;
  }
      &::-webkit-scrollbar-corner,
      &::-webkit-scrollbar-button {
        display: none;
  }
      `;
const PopupTableHeader = styled.div`
      font-size: 10px;
      font-weight: 900;
      color: black;
      `;
const PopupHeading = styled.div`
      font-size: 12px;
      font-weight: 900;
      position: relative;
      width: fit-content;
      `;

export default withTheme(WorldMap);
