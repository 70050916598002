import styled from "@emotion/styled";
import { Add, ExpandMore, RemoveCircleOutline } from "@mui/icons-material";
import React, { useState } from "react";
import { ButtonL } from "./AppIndex";

function FormTypeCreationOptions({
  formInput,
  handleOptionChange,
  handleOptionEdit,
  index,
  sameAsLable,
  handlePlaceholderChange,
  handleOptionRemove,
}: any) {
  const [openMenu, setOpenMenu] = useState(false);
  const [option, setOption] = useState("");
  return (
    <OptionWrapper>
      <OptionsTitle>
        <input
          type="text"
          placeholder="Placeholder (Optional)"
          value={
            formInput.sameAsLabel ? formInput.label : formInput.placeholder
          }
          onChange={(e: any) => {
            if (sameAsLable) {
              handlePlaceholderChange(index, formInput.label);
            } else {
              handlePlaceholderChange(index, e.target.value);
            }
          }}
        />
        <ExpandMore onClick={() => setOpenMenu(!openMenu)} />
      </OptionsTitle>
      {openMenu && (
        <Options>
          <OptionAddWrapper>
            <input
              type="text"
              value={option}
              onChange={(e) => setOption(e.target.value)}
              placeholder="Add option"
            />
            <ButtonL
              style={{ margin: 0 }}
              onClick={(e) => {
                e.preventDefault();
                handleOptionChange(index, option);
                setOption("");
              }}
            >
              <Add />
            </ButtonL>
          </OptionAddWrapper>
          {formInput?.options?.map((formOption: any, optionIndex: number) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <input
                  className="form-control"
                  type={"text"}
                  value={formOption}
                  onChange={(e) => {
                    handleOptionEdit(index, e.target.value, optionIndex);
                  }}
                />
                <RemoveCircleOutline
                  onClick={() => {
                    handleOptionRemove(index, optionIndex);
                  }}
                />
              </div>
            );
          })}
        </Options>
      )}
    </OptionWrapper>
  );
}

export default FormTypeCreationOptions;

const OptionWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const OptionAddWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  input {
    outline: none;
    border: 1px solid gray;
  }
`;
const OptionsTitle = styled.div`
  padding: 3px;
  border: 1px solid gray;
  background: white;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  lable {
  }
  input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    &::placeholder {
      color: black;
    }
  }
  button {
    border: none;
    outline: none;
    background: none;
    display: flex;
    justify-content: center;
    max-width: 50px;
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
const Options = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background-color: white;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 10px;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    width: 100%;
  }
  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;
