import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { MyVideoUploader } from "./MyVideoUploader";
// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export const AdvertListItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    editInfo,
    type,
    searchKeywordAttendees,
    setSearchKeywordAttendees,
    resource,
  } = props;
  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { myInfo, host, hostTabs } = myContext;

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const model: any = useMemo(() => {
    return hostTabs?.filter((ref: any) => ref.name == eventTab)?.[0]?.model;
  }, [eventTab, hostTabs, hostTab]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  useEffect(() => {
    // alert(eventId)
    console.log("hostTabsxxc22c");
    if (!eventId) {
      return;
    }
    const data = {
      query: {
        eventId: eventId,
        // handle: host?.handle,
      },
      resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
      check: ["handle"],
    };
    getDataDB(data).then((res) => {
      console.log("hostTabsxxcc", res);

      setDataRecord(res?.[0] ? res : []);

      setRefreshing(false);
      setFormInput({});
    });
  }, [
    eventId,
    // host?.handle,
    // eventTab,
    // myInfo?.handle,
    // params.eventId,
    // params.hostId,
    refreshing,
    resource,
  ]);

  const [skillsIndustry, setSkillsIndustry] = useState<any>([]);
  const [singleselect, setSingleselect] = useState<any>("");

  useEffect(() => {
    const rep: any = [];
    const reg: any = skills_industry;
    skills_industry &&
      Object.keys(skills_industry)?.map((res: any) => {
        [res, ...reg?.[res]]?.map((ret) => {
          rep.push(ret);
        });
        return rep;
      });
    setSkillsIndustry(rep);
  }, [skills_industry]);

  const myData = useMemo(() => {
    return [
      ...(formInput?.title
        ? [
          {
            file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            ...formInput,
          },
        ]
        : []),
      ...dataRecord
        ?.filter((res: any) => res.title != formInput?.title)
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title,
            // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          };
        }),
    ];
  }, [
    // formInput?.title, 
    eventId, dataRecord, resource]);

  useEffect(() => {
    console.log("addTypeccc", myData);
  }, [myData]);

  const [uploadNow, setUploadNow] = useState<boolean>(false);

  return (
    <>
      <div>
        {editInfo && (
          <>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {[
                  {
                    name: "file",
                    title: "Upload File",
                  },
                  {
                    name: "video",
                    title: "Upload Video",
                  },
                  {
                    name: "link",
                    title: "Add Link",
                  },
                ]?.map((res) => {
                  return (
                    <ButtonL
                      onClick={() => {
                        if (!formInput?.title) {
                          alert("Please add title first");
                          return;
                        }
                        setAddType({
                          ...addType,
                          [res?.name]: !addType?.[res?.name] ? true : false,
                        });
                      }}
                      key={res?.name}
                      style={{
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   marginRight: 30,
                        //   padding: 3,
                        //   border: "2px solid red",
                        //   borderRadius: 25,
                        //   backgroundColor: addType?.[res?.name]
                        //     ? "green"
                        //     : "rgba(0,0,0,0)",
                      }}
                    >
                      <div>{res?.title}</div>{" "}
                      <div >
                        {res?.name == "file" ? (
                          <Folder />
                        ) : res?.name == "link" ? (
                          <Attachment />
                        ) : (
                          <FilterList />
                        )}
                      </div>
                    </ButtonL>
                  );
                })}
              </div>
            </div> */}
            <div
              style={{
                marginTop: 5,
              }}
            >
              Title
            </div>
            <InputAgenda>
              <input
                type={"text"}
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    title: e.target?.value,
                  })
                }
                value={formInput?.title || ""}
                placeholder={"Add title ..."}
                required
              />
            </InputAgenda>
            {formInput?.title && (
              <>
                <div>
                  {/* <InputAgenda onSubmit={(e: any) => { }}>

                  {!(
                    addType?.file ||
                    addType?.link ||
                    formInput?.file ||
                    formInput?.link
                  ) && (
                      <div
                        onClick={() => {
                          const data = {
                            query: {
                              ...formInput,
                              eventId: eventId,
                            },
                            resource: `calendar_${params.eventId ? "event" : "host"
                              }_${eventTab}`,
                            check: formInput?._id ? ["_id"] : ["handle", "title"],
                            myInfo,
                          };
                          // console.log('formData', data)
                          saveDataDB(data).then((res) => {
               //setRefreshing(true);
                          });
                        }}
                      >
                        <Add />
                      </div>
                    )}
                </InputAgenda> */}
                  <div
                    style={{
                      marginTop: 5,
                    }}
                  >
                    Description
                  </div>
                  <InputAgenda onSubmit={(e: any) => { }}>
                    <input
                      type={"text"}
                      onChange={(e: any) =>
                        setFormInput({
                          ...formInput,
                          description: e.target?.value,
                        })
                      }
                      value={formInput?.description || ""}
                      placeholder={"Add description ..."}
                      required
                    />
                  </InputAgenda>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "75%",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 5,
                          alignSelf: "flex-start",
                        }}
                      >
                        Rate
                      </div>

                      <InputAgenda>
                        <input
                          type={"number"}
                          onChange={(e: any) =>
                            setFormInput({
                              ...formInput,
                              rate: e.target?.value,
                            })
                          }
                          value={formInput?.rate || ""}
                          placeholder={"1.10"}
                          required
                        />
                        <div
                          style={{
                            width: 100,
                          }}
                        ></div>
                      </InputAgenda>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 5,
                          alignSelf: "flex-start",
                        }}
                      >
                        Rate based
                      </div>
                      <Select
                        value={formInput?.rateType || ""}
                        defaultValue={singleselect}
                        onChange={(e: any) => {
                          console.log("handleChange", e);

                          setFormInput({
                            ...formInput,
                            rateType: e,
                          });
                        }}
                        options={[
                          { value: "Impression", label: "Impression" },
                          { value: "Click", label: "Click" },
                        ]}
                        placeholder="Rate based on"
                        classNamePrefix="selectform"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 5,
                    }}
                  >
                    Target Audience
                  </div>
                  <MultiSelect
                    {...props}
                    options={
                      skillsIndustry && skillsIndustry?.[0]
                        ? skillsIndustry?.map((res: any) => {
                          // console.log('skills_industryxx', res)
                          return {
                            value: res,
                            label: res,
                          };
                        })
                        : []
                    }
                    onMenuToggle={(e: any) => {
                      // setIsOpen(e)
                    }}
                    label={"bvbvbvbvbv"}
                    // selected={selected}
                    // setFormInput={setFormInput}
                    value={formInput?.targets || []}
                    style={{
                      display: "flex",
                      position: "absolute",
                      zIndex: 100,
                    }}
                    onChange={(e: any) => {
                      console.log("handleChange", e);
                      if (!myInfo?.handle) {
                        alert("Please login");
                        return;
                      }
                      setFormInput({
                        ...formInput,
                        targets: e,
                      });
                    }}
                  />

                  <div
                    style={{
                      marginTop: 5,
                    }}
                  >
                    Add File (optional)
                  </div>
                  {/* {(addType?.file || formInput?.file) && formInput?.title && ( */}
                  <InputAgenda>
                    <MyFileUploader
                      {...props}
                      refreshing={refreshing}
                      setRefreshing={setRefreshing}
                      setFormInput={setFormInput}
                      formInput={formInput}
                      uploadNow={uploadNow}
                      handleSaveImage={(e: any) => {
                        setUploadNow(true);
                      }}
                      resource={resource}
                    />
                  </InputAgenda>
                  {/* )} */}

                  <div
                    style={{
                      marginTop: 5,
                    }}
                  >
                    Add Video (optional)
                  </div>
                  {/* {(addType?.video || formInput?.video) && formInput?.title && ( */}
                  <InputAgenda>
                    <MyVideoUploader
                      {...props}
                      refreshing={refreshing}
                      setRefreshing={setRefreshing}
                      setFormInput={setFormInput}
                      formInput={formInput}
                      videoKey={'video'}
                      uploadNow={uploadNow}
                      handleSaveImage={(e: any) => {
                        setUploadNow(true);
                      }}
                      resource={resource}
                    />
                  </InputAgenda>
                  {/* )} */}

                  <div
                    style={{
                      marginTop: 5,
                    }}
                  >
                    Add Link (optional)
                  </div>
                  {/* {(addType?.link || formInput?.link) && formInput?.title && ( */}
                  <InputAgenda>
                    <input
                      type={"text"}
                      onChange={(e: any) =>
                        setFormInput({
                          ...formInput,
                          link: e.target?.value,
                        })
                      }
                      value={formInput?.link || ""}
                      placeholder={"Add Link ..."}
                      required
                    />
                  </InputAgenda>
                  {/* )} */}
                </div>

                <ButtonL
                  style={{ margin: 0 }}
                  onClick={() => {
                    setUploadNow(true);
                    const data = {
                      query: {
                        ...formInput,
                        eventId: eventId || params.eventId,
                        handle: myInfo?.handle,
                      },
                      resource: resource,
                      check:
                        // formInput?._id ? ["_id"] :
                        ["handle", "title"],
                      myInfo,
                    };
                    // console.log('formData', data)
                    saveDataDB(data).then((res) => {
                      // setRefreshing(true);
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          editInfo: false,
                        };
                      });
                    });
                  }}
                >
                  {"Save"}
                </ButtonL>
              </>
            )}
          </>
        )}

        <div
          style={{
            paddingTop: 50,
          }}
        >
          <AdvertCarroussel
            {...props}
            data={myData || []}
            handleEdit={(e: any) => {
              console.log("editsss", e);
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  editInfo: true,
                };
              });
              setTimeout(() => {
                setFormInput({
                  ...formInput,
                  ...e,
                });
              }, 200);
            }}
            searchKeywordAttendees={searchKeywordAttendees}
            setSearchKeywordAttendees={setSearchKeywordAttendees}
            handleSetItem={(item: any) => {
              setFormInput({
                ...formInput,
                ...item,
              });
            }}
            handleDeleteItem={(item: any) => {
              deleteDataDB({
                id: item?._id,
                resource: resource,
              }).then((rep: any) => {
                alert("Deleted");
                //setRefreshing(true);
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;
