const people = [
  {
    id: 1,
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959131/person-2_ipcjws.jpg",
    name: "maria ferguson",
    title: "office manager",
    quote: "Private Bioasdasda",
    handle: "@maria",
    media: [
      { title: "profile_pic.jpg", file: "file1", thumbnails: "tumb.jpg" },
      "file2",
    ],
    profileCategoriess: {
      singing: {
        softSkills: ["Communication", "Teamwork", "Time Management"],
        techSkills: ["Microsoft Office", "Google Workspace", "CRM Software"],
        languages: ["English", "Spanish", "French"],
      },
      frontend_development: {
        softSkills: ["Communication", "Teamwork", "Time Management"],
        techSkills: ["Microsoft Office", "Google Workspace", "CRM Software"],
        languages: ["English", "Spanish", "French"],
      },
    },
  },
  // Current Schema
  {
    _id: "65a6988c99504f002ad7e660",
    email: "deeajibola@gmail.com",
    company: "Vare Tech",
    date: "2024-01-22T23:54:25-06:00",
    handle: "@mplaunch",
    installId: "web",
    name: "Modupe Ajibola",
    password: "$2a$10$CUWgQtUr.hJCMtolLw7QieLFP3Bvj8WKMXvyoD9YC9DteveIAIViS",
    title: "CTO",
    user_id: "1ec23c4d777c",
    fullName: "Modupe Ajibola",
    img: "https://varefiles.s3.us-east-2.amazonaws.com/calendar_app_20231226_155555.jpg",
    profileCategoriess: {
      singing: {
        softSkills: ["Communication", "Teamwork", "Time Management"],
        techSkills: ["Microsoft Office", "Google Workspace", "CRM Software"],
        languages: ["English", "Spanish", "French"],
        
      },
      frontend_development: {
        softSkills: ["Communication", "Teamwork", "Time Management"],
        techSkills: ["Microsoft Office", "Google Workspace", "CRM Software"],
        languages: ["English", "Spanish", "French"],
      },
    },
  },
  {
    id: 2,
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg",
    name: "john doe",
    title: "regular guy",
    quote: "Private Bio",
    handle: "@john",
    softSkills: ["Adaptability", "Problem Solving", "Leadership"],
    techSkills: ["JavaScript", "HTML/CSS", "React"],
    languages: ["English", "German", "Chinese"],
  },
  {
    id: 3,
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959121/person-1_aufeoq.jpg",
    name: "peter smith",
    title: "product designer",
    quote: "Private Bio",
    handle: "@peter",
    softSkills: ["Creativity", "Attention to Detail", "Critical Thinking"],
    techSkills: ["Adobe Creative Suite", "Sketch", "UI/UX Design"],
    languages: ["English", "Italian", "Japanese"],
  },
  {
    id: 4,
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg",
    name: "susan andersen",
    title: "the boss",
    quote: "Private Bio",
    handle: "@susan",
    softSkills: [
      "Decision Making",
      "Strategic Planning",
      "Conflict Resolution",
    ],
    techSkills: ["Project Management", "Budgeting", "Data Analysis"],
    languages: ["English", "Portuguese", "Russian"],
  },
];

export default people;
