import styled from "@emotion/styled";
import { AddAPhoto, Description, FilterAlt, Search } from "@mui/icons-material";
import { animated, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import Sheet from "react-modal-sheet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import YouTube from 'react-youtube';
import { cleanAndTrimText, colors, getActionsDB, getDataDB, handleAIQuerySearch, myColors, relatedPict, saveActionsDB, saveDataDB } from "src/api";
import CommentsPlayerCard from "../CommentsPlayerCard";
import { LotteryInfoComponent } from "../LotteryInfoComponent";

import {
  ArrowBack,
  ArrowCircleLeftSharp,
  AttachMoney,
  Chat,
  Close,
  Edit,
  Expand,
  Favorite,
  Flag,
  Home,
  RemoveRedEye,
  Share,
  ThumbDown,
  ThumbUp,
  VolumeMute,
  VolumeOff,
} from "@mui/icons-material";
import moment from "moment";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { RR } from "../Events";
import { ShareButton } from "./AboutTabListItems";

import { UserContext } from "src/App";
// import { SearchEventAndGuestAvatars } from "../SearchEventAndGuestAvatars";
import { Avatar } from "@mui/material";
import { setTextMatrix } from "pdf-lib";
import { title } from "process";
import CommentsCard from "../CommentsCard";
import { GuestListIcons } from "../GuestListIcons";
import MobMenu from "../MobMenu";
import { QuizComponent } from "./EventEditor";
import InstagramStory from "./StoryCaption";
import { MediaForm } from "./TabListItems";

type Props = {};

function StoryTemplate(props: any) {


  const {
    data,
    page,
    handleCloseStory,
    messenger = {
      caption: "lorem ilpsum as aks d ic as ndatastorage.goolorem ilpsum as aks d ic as ndatastorage.goolpsum as aks d ic as ndatastorage.goolorem ilpsum as aks d ic as ndatastorage.goolpsum as aks d ic as ndatastorage.goolorem ilpsum as aks d ic as ndatastorage.goolpsum as aks d ic as ndatastorage.goolorem ilpsum as aks d ic as ndatastorage.goolpsum as aks d ic as ndatastorage.goolorem ilpsum as aks d ic as ndatastorage.goolpsum as aks d ic as ndatastorage.goolorem ilpsum as aks d ic as ndatastorage.goolpsum as aks d ic as ndatastorage.goolorem ilpsum as aks d ic as ndatastorage.goo",
      audio: "https://commondatastorage.googleapis.com/codeskulptor-assets/jump.ogg",

    }
  } = props

  const tempData = [
    {
      handle: '@guest',
      topic: 'About Vare',
      title: 'Vare App',
      description: 'Description',
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW4AdHQ5lVM_cFZYu4TogVtmEfYH4iHSGwYQ&s",
      picture: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRmHAXZKbvjmhUMLEL0SOzQlTHVjvT36TE2w&s",
      video: "https://varefiles.s3.us-east-2.amazonaws.com/Vare+App.mp4", //"https://videos.pexels.com/video-files/19312249/19312249-hd_1080_1920_30fps.mp4",
      followers: [
        {
          name: 'john',
          img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW4AdHQ5lVM_cFZYu4TogVtmEfYH4iHSGwYQ&s",

        }, {
          name: 'stacy',
          img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW4AdHQ5lVM_cFZYu4TogVtmEfYH4iHSGwYQ&s",

        },
        {
          name: 'Joseph',
          img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW4AdHQ5lVM_cFZYu4TogVtmEfYH4iHSGwYQ&s",

        }
      ]
    },
  ]

  const [dataRecord, setDataRecord] = useState<any>([]);
  const [mode, setMode] = useState<boolean>(false);
  const [duration, setDuration] = useState<any>(0);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isLottery, setIsLottery] = useState<boolean>(false);
  const [hideMenu, setHideMenu] = useState<boolean>(false);

  const videoRef = useRef<any>(null);

  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    host,
    hostTabs,
    eventInfo,
    myColabs,
    allAccess,
    lottery,
    storyResource,
    myStory,
    eventTabs = [],
    mobMenuColor,
    editInfo,
    openQuiz
  } = myContext;
  const { tab } = props;


  const [openStory, setOpenStory] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [allComments, setAllComments] = useState<any>([]);
  const [allActions, setAllActions] = useState<any>([]);
  const [modalContent, setModalContent] = useState<any>({});
  const [modalVisible, setModalVisible] = useState<any>(false);
  const [isComment, setIsComment] = useState<boolean>(false);
  const [audioStatus, setAudioStatus] = useState<boolean>(false);
  const [myFeeds, setMyFeeds] = useState<boolean>(false);

  const [isOpen, setOpen] = useState<any>(false);

  const [currentStoryIndex, setCurrentStoryIndex] = useState<number>(0);
  const params: any = useParams<any>();

  const location = useLocation();
  const navigate = useNavigate();

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  // const eventSubTab = useMemo(() => {
  //   return params?.eventSubTab;
  // }, [params?.eventSubTab]);



  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostId = useMemo(() => {
    return params?.hostId;
  }, [params?.hostId]);



  useEffect(() => {
    (async () => {

      if (eventId) {
        const event = await getDataDB({
          query: {
            _id: eventId,
          },
          resource: 'calendar_events', //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
          check: ["title"],
        }).then((res) => {
          // alert(JSON.stringify(res?.[0]))
          setMyContext((existing: any) => {
            return {
              ...existing,
              eventInfo: res?.[0],
              pageInfo: res?.[0],
            };
          });
        })
      }
    })()

  }, [
    eventId
  ])

  const resource = useMemo(() => {
    return eventTabs?.filter((res: any) => res.name == eventTab)?.[0]?.resource
  }, [
    eventTab,
    eventTabs,
    eventInfo
    // storyResource?.resource
  ]);



  useEffect(() => {
    (async () => {

      // console.log('vvvbnnnn', eventId, hostId, myInfo?.handle)
      if (eventId) {
        // alert(44)
        const data = {
          query: {
            // ...(eventTab
            //   ? { _id: eventTab }
            //   : {
            eventId: eventId
            // }),
          },
          resource: resource,
          check: ["handle", `${eventId ? "eventId" : "hostId"}`],
        };
        const subData: any = [];
        if (eventTab == "userStories") {
          await getDataDB(data)?.then((reg: any) => {
            // console.log('vvvvmmmmm', reg)
            if (reg?.[0]) {
              const ref = reg?.map((info: any) => {
                info?.stories?.map((res: any) => {
                  // console.log('vvvvmmmmm', res)
                  subData?.push(res);
                });
              });
              return ref;
            }
          });
        } else if (eventTab == "userjourney") {
          // console.log('vvvvmmmmm', reg)
          await getDataDB(data)?.then((reg: any) => {
            // console.log('vvvvmmmmm', reg)
            if (reg?.[0]) {
              const ref = reg?.map((info: any) => {
                info?.journeys?.map((journey: any) => {
                  journey?.steps?.map((res: any) => {
                    // console.log("journeys", res);
                    subData?.push(res);
                  });
                });
              });
              return ref;
            }
          });
        } else if (eventTab && resource) {

          // console.log('vvvvmmmmm', reg)
          await getDataDB(data)?.then((reg: any) => {
            // console.log('vvvvmmmmm', reg)
            if (reg?.[0]) {
              const ref = reg?.map((info: any) => {
                if (resource == 'calendar_attendance') {
                  subData?.push({
                    ...info,
                    title: info?.name,
                    picture: info?.img

                  });
                } else {
                  subData?.push(info);
                }
              });
              return ref;
            }
          });
        }
        // else if (storyResource?.subTab) {
        //   (await storyResource?.subTab) &&
        //     Promise.all(
        //       storyResource?.subTab?.map(async (ref: any) => {
        //         const data = {
        //           query: eventId ? { eventId: eventId } : { handle: hostId },
        //           resource: ref?.resource,
        //           check: ["handle", `${eventId ? "eventId" : "hostId"}`],
        //         };
        //         return await getDataDB(data)?.then((reg) => {
        //           // console.log('myNewsssssubData-reg', ref?.resource, reg)
        //           return reg?.map((res: any) => {
        //             return subData?.push(res);
        //           });
        //         });
        //       })
        //     );
        // }

        // console.log('myNewsssssubData', myStory, storyResource?.myNews)

        const myNews = await subData?.[0]
          ? subData
          : []//getDataDB(data))

        const data2 = {
          query: {
            dddd: null,
          },
          resource: "calendar_event_advert",
          limit: 50,
        };
        const news =
          myNews?.map((res: any) => {
            return {
              // _id: result?._id,
              ...res,
            };
          }) || [];

        const ads =
          (await getDataDB(data2)?.then((ref) => {
            return ref?.map((reg: any) => {
              return {
                ...reg,
                sponsored: true,
              };
            });
          })) || [];

        const rep = insertArrayAfterEveryNElements(news || [], ads || [], 3);
        // console.log('myNewssss', rep)

        const rresult = {
          title: "main story",
          img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
          stories: rep,
        };

        // console.log("hkjhkjhkjh", rresult);

        if (rresult) {
          const report: any = await rresult?.stories?.map((res: any) => {
            const myVideo =
              res?.video?.includes("undefined") || !res?.video?.includes("mp4")
                ? false
                : res?.video;
            return {
              ...res,
              myVideo: myVideo,
              // duration: duration || 5000,
            };
          });
          setDataRecord([...(report?.[0] ? report : []), ...tempData]);

        } else {
          setOpenStory(true);
        }
        // });
      } else {

        const vareTechVotes = await getDataDB({
          query: { _id: '66589e226355a846e21cf010' },
          resource: 'calendar_event_media',
          // check: ["handle"],
        })?.then(async (report) => {
          return report

        })


        const data = {
          query: { sssss: null },
          resource: 'calendar_event_media',
          // check: ["handle"],
        };
        return await getDataDB(data)?.then(async (report) => {
          // console.log('myNewsssssubData-reg', report)
          const reply = await Promise.all([...(report?.[0] ? report : [])]?.map(async (res, i) => {
            const getHost = await getDataDB({
              query: { handle: res?.handle },
              resource: 'calendar_users',
              // check: ["handle"],
            })?.then((report) => {
              return report?.[0]
            })
            return {
              ...getHost,
              ...res,
              // ...(!res?.img ? { 'img': relatedPict[i] } : {})
            }
          }))


          setDataRecord([
            ...(vareTechVotes || [])
            , ...reply
          ]);

        });

      }
    })();
  }, [
    eventSubTab,
    eventId,
    resource,
    eventTab,
    eventInfo,
    storyResource?.myNews,
    storyResource?.myStory,
    isComment,

    hostId,
    myInfo?.handle,
    ,
  ]);



  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error: any) => {
          console.error('Error attempting to play the video:', error);
        });
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleStop = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const handleLoad = (newSrc: any) => {
    if (videoRef.current) {
      videoRef.current.src = newSrc;
      videoRef.current.load();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (isPaused) {
      videoRef.current.pause();
    }

  }, [
    isPaused
  ])

  const handleLoadedMetadata = (event: any) => {
    const videoDuration = event.target.duration;
    // alert(videoDuration)
    setDuration(videoDuration);
  };


  const item = useMemo(() => {
    // alert(JSON.stringify(dataRecord?.[0]))
    if (currentStoryIndex >= 0 && dataRecord?.[0]) {
      return dataRecord?.[currentStoryIndex];
    } else {
      return false;
    }
  }, [
    currentStoryIndex,
    dataRecord,

  ]);

  const saveAction = async (info: any) => {
    if (!myInfo?.handle) {
      alert(`Please sign in to continue`);
    }

    const { key, value } = info;
    const formData = {
      name: myInfo?.name || myInfo?.fullName,
      handle: myInfo?.handle,
      location: myInfo?.location || "usa",
      content_id: item?._id,
      title: item?.title || item?.description,
      [key]: value,
    };

    return await saveActionsDB(formData).then(async (res) => {
      return await getActionsDB({
        handle: myInfo?.handle,
      }).then((res: any) => {
        // console.log("myStories", res);
        setAllActions(res);

        return "done";
      });
    });
  };

  useEffect(() => {
    getActionsDB({
      handle: myInfo?.handle,
    }).then((res) => {
      setAllActions(res);
    });
  }, [myInfo?.handle])

  const menuItem = useMemo(() => {
    const shares = allActions?.filter(
      (res: any) => res.share == "yes" //&& res.urlLink == item?.urlLink,
    );
    const views = allActions?.filter(
      (res: any) => res.view == "yes" //&& res.urlLink == item?.urlLink,
    );
    const likes = allActions?.filter(
      (res: any) => res.like == "yes" //&& res.urlLink == item?.urlLink,
    );
    const comments = allComments;
    // ?.filter(
    //   (res: any) => res.urlLink == item?.urlLink,
    // );

    const myActions: any =
      allActions?.filter(
        (res: any) =>
          res.handle == myInfo?.handle && res?.content_id == item?._id
      )?.[0] || {};
    // console.log("xxxxres", myActions?.["like"]);
    const result = [
      {
        icon: (
          <RR
            data={{
              text: "My GuestNet Profile",
              url: `https://www.myguestnet.com${location.pathname}`,
              title: `My GuestNet Profile`,
            }}
          >
            <Share />
          </RR>
        ),
        iconStyle: {
          color: shares?.filter((res: any) => res.handle == myInfo?.handle)?.[0]
            ? colors.reject
            : "gray",
        },
        label: shares?.length > 0 ? shares?.length : "",
        onClick: () => {
          <ShareButton>
            <RR
              data={{
                text: "My GuestNet Profile",
                url: `/story/${eventId}/${eventTab}`,
                title: `My GuestNet Profile`,
              }}
            >
              <Share />
            </RR>
          </ShareButton>;
          saveAction({
            key: "like",
            value: myActions?.["share"] == "yes" ? "no" : "yes",
          });
        },
      },
      {
        icon: <Chat />,
        iconStyle: {
          color: "white",
        },
        label: comments?.length > 0 ? comments?.length + "+" : "",
        title: "comments",
        onClick: (item: any) => {
          // alert(audioStatus)
          // setIemInfo(item);
          setOpen('comments');
          // alert(3)
          // setIsComment(true)
        },
      },
      {
        icon: (
          <Favorite
            style={{
              color: myActions?.["like"] == "yes" ? colors.reject : "gray",
            }}
          />
        ),
        iconStyle: {
          color: myActions?.["like"] == "yes" ? colors.reject : "gray",
        },
        label: likes?.length > 0 ? likes?.length : "",
        onClick: (item: any) => {
          // alert(audioStatus)
          // setIemInfo(item);
          saveAction({
            key: "like",
            value: myActions?.["like"] == "yes" ? "no" : "yes",
          });
        },
      },
      ...[myInfo?.hanle ? {
        label: "Funnel",
        icon: <FilterAlt />,
        // bgColor: myLike ? 'orange' : '',
        onClick: () => {
          if (!myInfo?.token) {
            alert("Please login");
            return;
          }
          setMyContext((existing: any) => {
            return {
              ...existing,
              funnelItem: item,
            };
          });
        },
      } : {}],
      {
        label: "Picture",
        icon: <AddAPhoto />,
        // bgColor: myLike ? 'orange' : '',
        onClick: () => {
          if (!myInfo?.token) {
            alert("Please login");
            return;
          }
          setMyContext((existing: any) => {
            return {
              ...existing,
              editInfo: true,
            };
          });
        },
      },

      {
        icon: (
          <AttachMoney
            style={{
              color: colors.reject,
            }}
          />
        ),
        iconStyle: {
          color: 'white'
        },
        label: "win",
        onClick: (item: any) => {
          setOpen('lottery');
        },
      },

    ];

    return [
      // ...conttrolBack, 
      ...(item?.video ? [
        {
          icon: audioStatus ? <VolumeOff /> : <VolumeMute />,
          iconStyle: {
            color: comments?.filter(
              (res: any) => res.handle == myInfo?.handle
            )?.[0]
              ? colors.reject
              : "gray",
          },
          label: "",
          title: "Audio",
          onClick: (item: any) => {
            // alert(audioStatus)
            // setIemInfo(item);
            setAudioStatus(!audioStatus);
            // alert(3)
            // setIsComment(true)
          },
        },
      ] : []),
      ...result,
      ...(item?.survey ? [
        {
          icon: (
            <ThumbUp
              style={{
                color: "white",

              }}
            />
          ),
          iconStyle: {
            color: myActions?.["vote"] == "yes" ? colors.reject : "gray",
          },
          label: "yes",
          onClick: (item: any) => {
            // alert(audioStatus)
            // setIemInfo(item);
            saveAction({
              key: "vote",
              value: myActions?.["vote"] == "yes" ? "no" : "yes",
            });
          },
        },
        {
          icon: (
            <ThumbDown
              style={{
                color: "white",

              }}
            />
          ),
          iconStyle: {
            color: myActions?.["vote"] == "yes" ? colors.reject : "gray",
          },
          label: "no",
          onClick: (item: any) => {
            // alert(audioStatus)
            // setIemInfo(item);
            saveAction({
              key: "vote",
              value: myActions?.["vote"] == "yes" ? "no" : "yes",
            });
          },
        }
      ]
        : []),
      ...(myInfo?.handle == item?.handle ? [{
        icon: (
          <Home
            style={{
              color: "white"
            }}
          />
        ),
        iconStyle: {
          color: "white"
        },
        label: "",
        onClick: (item: any) => {
          // const link = (params?.eventId || '') + (params?.eventSubTab || '')
          navigate(
            `/event/${eventId}/${eventTab}${eventSubTab ? '/' + eventSubTab : ''}`
          );

        },
      }
      ] : []),
    ];
  }, [allActions, item?._id, myFeeds, params?.eventId, params?.eventSubTab]);


  const [{ y }, api] = useSpring(() => ({ y: 0 }));

  const bind = useGesture(
    {
      onDrag: ({ down, movement: [_, my] }: any) => {
        // my is the movement in the y direction
        if (!down) {
          if (my < 0) {
            if (dataRecord[currentStoryIndex + 1]) {
              setCurrentStoryIndex(currentStoryIndex + 1)
            }
            // console.log('Dragging up');

          } else if (my > 0) {
            if (currentStoryIndex - 1 >= 0 && dataRecord[currentStoryIndex - 1]) {
              setCurrentStoryIndex(currentStoryIndex - 1)
            }
            // console.log('Dragging down');

          }
          api.start({ y: 0 });
        } else {
          api.start({ y: my });
        }
      },
    },
    {
      drag: { axis: 'y' },
    }
  );

  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [eventFollowers, setEventFollowers] = useState<any>([]);

  useEffect(() => {
    if (eventSubTab) {
      let tep = 0
      dataRecord?.map((res: any, index: number) => {
        if (eventSubTab == res?._id) {
          // alert(tep)
          tep = index
        }
      })

      setCurrentStoryIndex(tep)
    }

  }, [
    eventSubTab
  ])


  useEffect(() => {
    updateFollower()
  }, [
    eventId,
    host?.handle,
    myInfo?.handle
  ])

  // console.log('eventTab', item, eventTab)



  const updateFollower = useCallback(async () => {

    if (eventId || host?.handle) {
      //get followers
      const dataFollower = {
        query: {
          eventId: eventId,
        },
        resource: 'calendar_enterprise_followers',
      };
      getDataDB(dataFollower).then(async (storyFollowers) => {


        // console.log('storyFollowers', storyFollowers)

        const info = await storyFollowers?.filter((ref: any) => ref.handle == myInfo?.handle)
        setIsFollowing(info?.[0]?.follow ? true : false)

        const getPerson = await Promise.all(info?.map(async (reg: any) => {
          return await getDataDB({
            query: {
              handle: info?.handle,
            },
            resource: 'calendar_users',
          }).then(res => {
            return res?.[0]
          })
        }))

        if (getPerson?.[0]) {
          setEventFollowers(getPerson)
        }
      })
    }

  }, [
    eventId,
    host?.handle,
    myInfo?.handle
  ]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleSaveFollower = useCallback(() => {
    if (!(eventId || host?.handle)) {
      return
    }
    const data = {
      query: {
        eventId: eventId,
        hostId: host?.handle,
        handle: myInfo?.handle,
        follow: !isFollowing
      },
      resource: `calendar_enterprise_followers`,
      check: ["eventId", "handle"],
      myInfo,
    };
    // console.log("FormFields", data);

    saveDataDB(data).then((res: any) => {
      updateFollower()
    });

  }, [
    isFollowing,
    myInfo?.handle,
    eventId,
    host?.handle
  ])

  const handleRsvp = useCallback(() => {
    // if (currentStoryIndex) {

    setMyContext((existing: any) => {
      return {
        ...existing,
        scheduleSelect: {
          index: currentStoryIndex || -1,
        },
        storyResource: false,
      };
    });

    // }

  }, [
    currentStoryIndex
  ])

  // console.log('item', item, host)
  const opts = {
    // marginTop: 30,
    height: 200,
    width: isMobile ? windowWidth * .8 : 450,
    playerVars: {
      autoplay: 0, // Prevents autoplay
      controls: 1, // Show player controls
      rel: 0, // Prevent showing related videos at the end
    },
  };

  const onPlayerReady = (event: any) => {
    event.target.pauseVideo();
  };
  // console.log('xxxxxss', item)
  const youtubeKey = useMemo(() => {
    const info = (item?.video || item?.link)?.includes('youtube') && item?.link?.split('=')
    return info?.[info?.length - 1]
  }, [
    item?.video,
    item?.link
  ])

  const handleStateChange = (event: any) => {
    // Event data returns a number indicating the state
    // -1 (unstarted), 0 (ended), 1 (playing), 2 (paused), 3 (buffering), 5 (video cued).
    switch (event.data) {
      case 1: // playing
        setIsPlaying(true);
        break;
      case 2: // paused
      case 0: // ended
        setIsPlaying(false);
        break;
      default:
        break;
    }
  };
  const [billInfo, setBillInfo] = useState<any>({});
  const [billData, setBillData] = useState<any>([]);





  useEffect(() => {
    if (item?.eventId && !eventTab) {
      getDataDB({
        query: {
          _id: item?.eventId,
        },
        resource: 'calendar_events',
      }).then(async (storyFollowers) => {
        // console.log('cccccv', storyFollowers)
        if (storyFollowers?.[0])
          setMyContext((existing: any) => {
            return {
              ...existing,
              eventInfo: storyFollowers?.[0],
              pageInfo: storyFollowers?.[0],
            };
          });
      })
    }
    if (item?.description) {



      handleAIQuerySearch({
        query: `return JSON formated result with the following attributes [{bill_id: '',description:'', source:''}], of US Bill ID that best fits the following description ${cleanAndTrimText({ inputText: item?.description, maxLength: 200 })}`,
        myInfo: myInfo
      })
        .then(async (rep: any) => {
          console.log('handleAIQuerySearchxx', rep)

          if (Array.isArray(rep) && rep?.[0]) {
            setBillInfo(rep?.[0])
            const result = await rep?.map((reply: any) => {
              return {
                ...reply,
                url: item?.link,
                urlToImage: item?.poster || item?.img || item?.picture,
                title: reply?.description,
                content: item?.description,
                description: item?.description
              }
            })
            console.log('handleAIQuerySearch22', result)
            if (result?.[0]) {
              setBillData(result)
            }

          } else if (rep?.bill_id) {
            setBillInfo(rep)
            const result: any = [{
              ...rep,
              url: item?.link,
              urlToImage: item?.poster || item?.img || item?.picture,
              title: rep?.description,
              content: item?.description,
              description: item?.description
            }]
            setBillData(result)
          }
        })
    }

  }, [
    item?.description
  ])

  const myPicture = useMemo(() => {
    return (item?.img?.includes('jpg') || item?.img?.includes('jpeg'))
      ? item?.img
      : (item?.picture?.includes('jpg') || item?.picture?.includes('jpeg'))
        ? item?.picture
        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2dV7i6tPkgxE6oWMLQB02BSL0dzWzJV5R_w&s"
  }, [
    item?.img,
    item?.picture,
    currentStoryIndex
  ])

  // console.log('handleAIQuerySearch', billInfo)

  // console.log('xxvvvvvv', item, eventInfo)

  return (<div
    className="App"

    style={{
      position: 'absolute',
      // top: 0,
      marginTop: 0,
      marginLeft: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: window?.innerHeight,
      width: '100%',
      backgroundColor: 'black',
    }}>
    <Modal
      show={editInfo ? true : false}
      onHide={() => {
        setMyContext((existing: any) => {
          return {
            ...existing,
            editInfo: false,
          };
        });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{item?.topic}</Modal.Title>
      </Modal.Header>
      <Modal.Body> Add to media</Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MediaForm
          {...props}
          editInfo={editInfo}
          dataTopic={[{ ...item }]}
          setDataTopic={() => { }}
          setFormInput={setFormInput}
          formInput={{
            ...item,
          }}
          resource={resource}
          setInputField={() => { }}
          refreshing={refreshing}
          setRefreshing={setRefreshing}
          openCalendar={() => { }}
          setOpenCalendar={() => { }}
        />
      </Modal.Footer>
    </Modal >

    <Modal
      show={isOpen == 'match' ? true : false}
      onHide={() => {
        setOpen(false)
        // setMyContext((existing: any) => {
        //   return {
        //     ...existing,
        //     openQuiz: false,
        //   };
        // });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{item?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body> Fun Quiz by Content Host</Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {item?.handle == myInfo?.hanle
          ? <MatchInfoComponent {...props}
            refreshing={refreshing}
            setRefreshing={setRefreshing}
            billInfo={{
              ...item,
              ...(billInfo ? billInfo : {})
            }}
            setOpen={setOpen}
          />
          : <QuizComponent
            {...props}
          />}
      </Modal.Footer>
    </Modal >



    <Helmet>
      <meta name="description" content={item?.description || ''} />
      <meta property="og:title" content={eventInfo?.title || 'MyGuestNet'} />
      <meta property="og:description" content={item?.description || ''} />
      <meta property="og:image" content={item?.picture || eventInfo?.picture || "https://minorityproductlaunch.com/wp-content/uploads/2023/12/20231212_135738_0000.png"} />
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={item?.title || 'MyGuestNet'} />
      <meta property="twitter:description" content={item?.description || ''} />
      <meta property="twitter:image" content={item?.picture || item?.img || eventInfo?.picture || "https://minorityproductlaunch.com/wp-content/uploads/2023/12/20231212_135738_0000.png"} />
      <title>{item?.title || 'MyGuestNet page'}</title>
    </Helmet>
    <div>
      <div
        // backdrop="static"
        // size={'xl'}
        style={{
          backgroundColor: 'black',
          // zIndex: 99999999,
          display: 'flex',
          height: window?.innerHeight,
          width: isMobile ? window.innerWidth : 450,
          justifyContent: 'center',
          alignItems: 'center',
          overflowX: 'hidden'
        }}
      // show={storyResource}
      >
        <div onClick={() => {
          if (isPaused) {
            handlePlay()
          }
          setIsPaused(!isPaused)
        }}>
          <ProgressBar duration={duration} isPaused={isPaused} />
        </div>
        {isOpen == 'search'
          ? <MobMenu
            mobMenuColor={mobMenuColor}
            // setMobMenuColor={setMobMenuColor}
            eventTabs={eventTabs}
            eventTab={eventTab}
            setMobMenu={() => {
              setOpen(false)
            }}
            // setOpenMenu={setOpenMenu}
            eventId={eventId}
          />
          : <>
            <animated.div
              {...bind()}
              style={{
                touchAction: 'none',
                y,
                background: 'black',
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
                flex: 1,
                overflowX: 'hidden',
                userSelect: 'none',
              }}
            >
              <div style={{
                marginTop: 10,
                // backgroundColor: 'red',
                marginBottom: 10,
                height: 60,
                // height: window.innerHeight * .5,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                position: 'relative'
              }}>

                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  position: 'relative'
                }}>
                  <StoryButton style={{
                    justifyContent: 'space-between',
                    borderStyle: 'none',
                    // zIndex: 2,
                    // top: 10,
                    width: '100%',
                    paddingRight: 20,
                    paddingLeft: 10,
                    flexDirection: 'row'
                  }}>
                    <div
                      onClick={() => {
                        navigate(-1)
                      }}
                      style={{
                        marginLeft: 10,
                        // backgroundColor: 'white',
                        // borderRadius: 100,
                        // width: 0,
                        color: 'lightcyan',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        borderRadius: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 4,
                        fontSize: 16
                      }}
                    >
                      <ArrowBack />
                    </div>
                    <UserInfo>
                      <img
                        src={item?.handle == host?.handle ? host?.img : (eventInfo?.picture || eventInfo?.img ||
                          "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg")
                        }
                        alt=""
                      />
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        fontSize: 10,
                        padding: 5
                      }}>
                        <div style={{
                          whiteSpace: 'none'
                        }}>
                          {item?.name || ''}  {eventInfo?.handle || ''}
                        </div>

                        <div style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          whiteSpace: 'none'
                        }}>{item?.title || ''}</div>
                        <div style={{
                          fontSize: 10
                        }}>
                          {moment(item?.created || item?.date)?.fromNow()}
                        </div>
                      </div>
                    </UserInfo>
                    <StoryButtonItem
                      style={{
                        color: 'lightcyan',
                        // right: window.innerWidth * .5,
                        backgroundColor: isFollowing ? myColors?.brown : "rgba(255, 255, 255, 0.3)",

                      }}
                      onClick={() => {
                        handleSaveFollower()
                      }}

                    >
                      {isFollowing ? 'Following' : 'Follow'}
                    </StoryButtonItem>

                    {/* <div style={{
                      marginLeft: 10,
                      // backgroundColor: 'white',
                      // borderRadius: 100,
                      // width: 0,
                      color: 'white',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      borderRadius: 2,
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 4
                    }}
                      onClick={() => {
                        setOpen('search')

                      }}
                    >
                      <Search
                        style={{
                          color: 'white',
                        }}
                      />
                    </div> */}

                  </StoryButton>
                </div>
              </div>
              <div
                style={{
                  // marginTop: 30,
                  display: 'flex',
                  position: 'relative',
                  // justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 999,
                  flexDirection: 'column',
                  // overflowY: 'auto',
                  // backgroundColor: 'red',
                  height: window.innerHeight * .8,
                  // minWidth: 400
                  width: '100%'
                }}>
                {item?.movie
                  ? <>
                    {youtubeKey ?
                      <YouTube
                        ref={videoRef}
                        videoId={youtubeKey}
                        opts={opts}
                        onReady={onPlayerReady}
                        onStateChange={handleStateChange}
                      />
                      : <video
                        onClick={() => {
                          setIsPaused(!isPaused)
                        }}
                        ref={videoRef}
                        autoPlay={!isPaused}
                        controls={item?.video && !isPaused ? true : false}
                        loop
                        src={item?.video || ''}
                        poster={item?.picture || item?.img
                          || "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"

                        }
                        onLoadedMetadata={handleLoadedMetadata}
                        style={{
                          objectFit: mode ? "cover" : "contain",
                          // backgroundColor: 'green',
                          // height: window.innerHeight * .3
                          // marginTop: -100
                          width: '90%',
                          height: 200,

                        }}
                      />}

                    {isPlaying
                      ? <div style={{
                        display: "flex",
                        height: window.innerHeight * .4,
                        overflowY: 'auto',
                        // justifyContent: "flex-end",
                        flexDirection: 'column',
                        width: '100%'

                      }}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            // height: "100%",
                            // overflowY: 'auto',
                            justifyContent: "flex-end",
                          }}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <Close
                            style={{
                              marginRight: 20,
                            }}
                          />
                        </div>
                        <CommentsCard {...props} editInfo={true} />
                      </div>
                      : <StoryInfoWrapper style={{
                        height: 200,
                      }}>
                        <UserAndStories style={{
                          flexDirection: 'column'
                        }}>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            gap: 10
                          }}>

                            {eventTab == 'schedule'
                              && <StoryButtonItem
                                style={{
                                  color: 'lightcyan',
                                  // right: window.innerWidth * .5,
                                }}
                                onClick={() => {
                                  handleRsvp()
                                }}
                              >
                                RSVP
                              </StoryButtonItem>}
                            {item?.link && <StoryButtonItem
                              style={{
                                color: 'lightcyan',
                                right: 20
                              }}
                              onClick={() => {
                                // setMode(!mode)
                                if (item?.link) {
                                  window.open(item?.link)
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                                />
                              </svg>
                              More..
                            </StoryButtonItem>}

                          </div>

                          <CaptionWrapper
                            style={{
                              marginTop: -5,
                              marginBottom: -5
                            }}>
                            <Caption style={{
                              backgroundColor: 'rgba(0,0,0,0.05)',
                              borderRadius: 10
                            }}>{`${eventInfo?.title || item?.author || ''}: ${item?.description &&
                              item?.description?.length > 150
                              ? item?.description?.substring(0, 150) + '...'
                              : (item?.description || '')}`}...</Caption>
                            <SubCaption style={{
                              marginTop: -10
                            }}>
                              {item?.description ? '12 responses' : ''} <span />
                              {item?.name}
                            </SubCaption>
                          </CaptionWrapper>
                          <Followings>
                            <ImgWrapper>
                              <GuestListIcons
                                {...props}
                                item={item}
                              />
                            </ImgWrapper>

                            <span>{item?.followers?.[0] ? [...item?.followers, ...eventFollowers]?.map((info: any) => { return <>{info?.name},</> }) : null}</span>
                          </Followings>

                          <StoryInfo style={{
                            width: '100%',
                            paddingBottom: 20,
                          }}>
                            <Badge onClick={() => {
                              setOpen('match')

                            }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                                />
                              </svg>
                              {'Match maker'}
                            </Badge>
                            {[{
                              name: 'Related Bills',
                              // handle: '@handle'
                            }, ...billData]?.map((res: any) => {
                              return <>
                                {res?.bill_id && <Badge onClick={() => {
                                  if (res.bill_id) {
                                    setBillInfo(res)
                                    setOpen('bill')
                                  }
                                }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                                    />
                                  </svg>
                                  {res?.bill_id}
                                </Badge>}
                              </>
                            })}
                          </StoryInfo>
                        </UserAndStories>
                        <StoryActions style={{
                          marginBottom: 60
                        }}>
                          {!hideMenu && menuItem?.map((res: any) => {
                            return (
                              <Action onClick={(e) => {
                                res?.onClick(e);
                              }}>
                                {res?.icon}
                                <div style={{
                                  // zIndex: 5,
                                  display: 'flex',
                                  fontSize: '0.5em'
                                }}>{res?.label || ''}</div>
                              </Action>
                            );
                          })}
                          <Action onClick={() => {
                            setHideMenu(!hideMenu)
                          }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              color={hideMenu ? colors.reject : "white"}
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                              />
                            </svg>
                          </Action>
                        </StoryActions>
                      </StoryInfoWrapper>}
                  </>
                  : <div>
                    <InstagramStory
                      item={item}
                      caption={item?.description || "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Culpa mollitia, deleniti iusto quae qui provident omnis ab, eius, fugiat modi maiores dicta sunt ad facere quia asperiores! Tempore harum magni deserunt ipsa nesciunt ullam at sapiente, est, nihil mollitia accusantium. This is a test caption that scrolls as audio plays. The background is dynamic (image or video)."}
                      audioSrc="https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3"
                      // img={item?.img || item?.picture || "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"}
                      img={myPicture}
                      video={item?.video || "https://www.w3schools.com/html/mov_bbb.mp4"}
                      useImg={item?.video ? false : true} // Switch between true for image, false for video
                      useAudioSrc={true} // Custom audio if true
                      interval={10} // 10 seconds for video duration
                    />
                    <StoryActions style={{
                      zIndex: 1,
                      position: 'absolute',
                      right: 10,
                      bottom: 0
                      // marginBottom: 60
                    }}>
                      {!hideMenu && menuItem?.map((res: any) => {
                        return (
                          <Action onClick={(e) => {
                            res?.onClick(e);
                          }}>
                            {res?.icon}
                            <div style={{
                              // zIndex: 5,
                              display: 'flex',
                              fontSize: '0.5em'
                            }}>{res?.label || ''}</div>
                          </Action>
                        );
                      })}
                      <Action onClick={() => {
                        setHideMenu(!hideMenu)
                      }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          color={hideMenu ? colors.reject : "white"}
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                          />
                        </svg>
                      </Action>
                    </StoryActions>
                  </div>}
              </div>
            </animated.div>
          </>}

        {/* <Sheet key={'match'} isOpen={isOpen == 'match'} onClose={() => setOpen(false)}>
          <Sheet.Container style={{
            backgroundColor: 'black'

          }}>
            <Sheet.Header />
            <Sheet.Content style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'black'
            }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
                onClick={() => {
                  // setIsLottery(false);
                  setOpen(false);

                }}
              >
                <Close
                  style={{
                    color: 'white',
                    marginRight: 20,
                  }}
                />
              </div>
              <MatchInfoComponent {...props}
                refreshing={refreshing}
                setRefreshing={setRefreshing}
                billInfo={{
                  ...item,
                  ...(billInfo ? billInfo : {})
                }}
                setOpen={setOpen}
              />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet> */}

        <Sheet key={'bill'} isOpen={isOpen == 'bill'} onClose={() => setOpen(false)}>
          <Sheet.Container style={{
            backgroundColor: 'black'

          }}>
            <Sheet.Header />
            <Sheet.Content style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'black'
            }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
                onClick={() => {
                  // setIsLottery(false);
                  setOpen(false);

                }}
              >
                <Close
                  style={{
                    color: 'white',
                    marginRight: 20,
                  }}
                />
              </div>
              <BillInfoComponent {...props}
                refreshing={refreshing}
                setRefreshing={setRefreshing}
                billInfo={{
                  ...item,
                  ...(billInfo ? billInfo : {})
                }}
                myInfo={myInfo}
              />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
        <Sheet key={'lottery'} isOpen={isOpen == 'lottery'} onClose={() => setOpen(false)}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
                onClick={() => {
                  // setIsLottery(false);
                  setOpen(false);

                }}
              >
                <Close
                  style={{
                    marginRight: 20,
                  }}
                />
              </div>
              <LotteryInfoComponent {...props} />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
        <Sheet key={'comments'} isOpen={isOpen == 'comments'} onClose={() => setOpen(false)}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Close
                  style={{
                    marginRight: 20,
                  }}
                />
              </div>
              <CommentsPlayerCard {...props} editInfo={true} />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

      </div>
    </div>
  </div>
  );
}


export const MatchInfoComponent = (props: any) => {
  const {
    setOpen
  } = props
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },

  } = myContext;
  const params: any = useParams<any>();

  const location = useLocation();
  const navigate = useNavigate();

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);


  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizObject, setQuizObject] = useState<any>({});
  const [formInput, setFormInput] = useState<any>({});


  const quizObjectTemp = {
    title: 'Who knows me best',
    description: 'Answer the following questions about the individual to prove you know them',
    quiz: [
      {
        question: 'What is my middle name?',
        options: ['John', 'Larry', 'Fred'],
        answer: 'Larry'
      },
      {
        question: 'What do I like doing for fun?',
        options: ['Movies', 'Eating Out', 'Clubing'],
        answer: 'Movies'
      },
      {
        question: 'Who is my favorite artist?',
        options: ['JZ', 'DMX', 'MJ'],
        answer: 'DMX'
      }
    ]
  };

  useEffect(() => {

    getDataDB({
      query: {
        eventId: eventId,
        eventTab: eventTab
      },
      resource: "calendar_match_quiz",
    }).then(async (res) => {

      if (res?.[0]) {
        // console.log('resssss', res)
        setQuizObject({
          ...res?.[0]
        })
      }
    })

    if (myInfo?.handle)
      getDataDB({
        query: {
          eventId: eventId,
          handle: myInfo?.handle,
        },
        resource: "calendar_match_quiz_score",
      }).then(async (res) => {

        if (res?.[0]?.score) {
          // console.log('resssss', res)
          setScore(res?.[0]?.score)
        }
      })

  }, [
    eventId,
    eventTab,
    myInfo?.handle,

  ])

  const currentQuestion = quizObject?.quiz?.[currentQuestionIndex];

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
  };

  const handleNextClick = () => {
    if (selectedOption === currentQuestion?.answer) {
      setScore(score + 1);
    }
    setSelectedOption(null);
    if (currentQuestionIndex < quizObject?.quiz.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      alert(`Quiz completed! Your score: ${score + (selectedOption === currentQuestion?.answer ? 1 : 0)}/${quizObject.quiz.length}`);
      // console.log("FormFields", data);

      saveDataDB({
        query: {
          eventId: eventId,
          quizObject: quizObject,
          handle: myInfo?.handle,
          score: `${(score + (selectedOption === currentQuestion?.answer ? 1 : 0)) * 1 / quizObject.quiz.length}`
        },
        resource: `calendar_match_quiz_score`,
        check: ["eventId", "handle"],
        myInfo,
      }).then((res: any) => {
        alert('Score Saved')
        setOpen(false)
      });
    }
  };

  if (!quizObject?.quiz) {
    return <div style={{
      textAlign: 'center',
      padding: '20px',
      backgroundColor: 'lightgray'
    }}>
      No quiz available at this time.
    </div>
  }

  if (score) {
    return <div style={{
      textAlign: 'center',
      padding: '20px',
      backgroundColor: 'lightgray'
    }}>
      You scored {score} on this test.
    </div>
  }


  return (
    <div style={{
      textAlign: 'center',
      padding: '20px',
      backgroundColor: 'lightgray'
    }}>
      <h1 style={{ fontSize: '24px', marginBottom: '10px' }}>
        {quizObject.title}

      </h1>
      <p style={{ fontSize: '16px', marginBottom: '20px' }}>
        {quizObject.description}
      </p>
      <div>
        <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>
          {currentQuestion?.question}
        </h2>
        <div style={{
          marginBottom: '20px', display: 'flex', flexDirection: 'column', width: 300,
          borderRadius: 20
        }}>
          {currentQuestion?.options.map((option: any, index: number) => (
            <button
              key={index}
              onClick={() => handleOptionClick(option)}
              style={{
                padding: '10px 20px',
                margin: '5px',
                backgroundColor: selectedOption === option ? 'lightblue' : 'white',
                border: '1px solid #ccc',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              {option}
            </button>
          ))}
        </div>
        <button
          onClick={handleNextClick}
          style={{
            padding: '10px 20px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Next
        </button>
      </div>
    </div>
  )


}


const BillInfoComponent = (props: any) => {
  const {
    billInfo = {},
    myInfo = {},
    refreshing,
    setRefreshing
  } = props

  const [voteCount, setVoteCount] = useState<any>({});

  useEffect(() => {
    if (billInfo?.bill_id) {
      // setItemBillId(reportId)
      getDataDB({
        query: {
          item_id: billInfo?.bill_id,
        },
        resource: 'vare_vote',
      }).then((result) => {
        if (result?.length) {
          const voteYes = result?.filter((res: any) => res.vote == "yes")
          const voteNo = result?.filter((res: any) => res.vote == "no")
          setVoteCount({
            yes: voteYes?.length,
            no: voteNo?.length
          })
          setRefreshing(false)
        }
      })
    }
  }, [
    refreshing,
    billInfo?.bill_id
  ])

  return <div style={{
    display: 'flex',
    borderRadius: 20,
    flexDirection: 'column',
    gap: 10,
    overflowY: 'auto',
    padding: 50,
    margin: 50,
    paddingBottom: 100,
    width: 300,
    backgroundColor: 'lightgray'
  }}>
    <img
      src={billInfo?.poster || billInfo?.img || billInfo?.picture ||
        "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
      }
      alt=""
    />
    <div>{billInfo?.title}</div>
    <div>{billInfo?.description}</div>
    <div style={{ padding: 20 }}>
      {billInfo?.bill_id ?
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          backgroundColor: 'white',
          borderRadius: 10,
          padding: 5,
          width: 150
        }}>
          <ThumbUp
            onClick={() => {
              const data = {
                query: {
                  ...billInfo,
                  vote: "yes",
                  item_id: billInfo?.bill_id,
                  handle: myInfo?.handle
                },
                resource: `vare_vote`,
                check: ['item_id', 'handle'],
                myInfo,
              };
              saveDataDB(data).then((res: any) => {
                // updateVoteCount()
                setRefreshing(true)
              });
            }}
            children={'dd'}
            style={{
              color: "green",
            }}
          />
          <span style={{
            fontSize: "1em",
            color: 'green'
          }}>{voteCount?.yes || 'Support'}</span>
          <span style={{
            color: 'red',
            fontSize: "1em",

          }}>{voteCount?.no || 'Reject'}</span>
          <ThumbDown
            onClick={() => {
              const data = {
                query: {
                  ...billInfo,
                  vote: "no",
                  item_id: billInfo?.bill_id,
                  handle: myInfo?.handle
                },
                resource: `vare_vote`,
                check: ['item_id', 'handle'],
                myInfo,
              };
              saveDataDB(data).then((res: any) => {
                setRefreshing(true)
              });
            }}
            style={{
              color: "red",
            }}
          />
        </div> : null}
    </div>
  </div>

}

const TypewriterComponent = (props: any) => {
  const { textArray = ["Hello World!", "Welcome to React!", "Enjoy coding!"] } =
    props;
  return (
    <div style={{ fontSize: "24px", fontWeight: "bold" }}>
      <Typewriter
        words={textArray}
        loop={1}
        cursor
        cursorStyle="_"
        typeSpeed={70}
        deleteSpeed={50}
        delaySpeed={1000}
      />
    </div>
  );
};

// const StoryWrapperB = styled.div`
//   width: 100%;
//   background-color: #ddd;
//   border-radius: 5px;
//   overflow: hidden;
//   position: relative;
//   height: 10px;
// `;



const ProgressBarContainer = styled.div`
  width: 100%;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 2px 0;
  border-radius: 2px;
  overflow: hidden;
`;

const Filler = styled.div`
  height: 100%;
  background-color: red;
  transition: width 0.1s linear;
`;

const ProgressBar = ({ duration, isPaused }: any) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer: any;
    if (!isPaused) {
      timer = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) {
            return prev + (100 / duration) * 0.1;
          } else {
            clearInterval(timer);
            return 100;
          }
        });
      }, 100);
    }
    return () => clearInterval(timer);
  }, [duration, isPaused]);

  return (
    <ProgressBarContainer>
      <Filler style={{ width: `${progress}%` }} />
    </ProgressBarContainer>
  );
};



export default StoryTemplate;


export function insertArrayAfterEveryNElements(
  arr: any,
  valuesToInsert: any,
  n: any
) {
  // function insertElementsOneAtATime(arr, valuesToInsert, n) {
  let resultArray = arr.slice(); // Create a copy of the original array to avoid mutating it
  let insertIndex = n; // Start index for insertion

  // Iterate over valuesToInsert and insert each element one at a time
  for (let i = 0; i < valuesToInsert.length; i++) {
    if (insertIndex > resultArray.length) {
      // If insertIndex is out of bounds, append the remaining values
      resultArray.push(valuesToInsert[i]);
    } else {
      // Insert the value at the calculated index
      resultArray.splice(insertIndex, 0, valuesToInsert[i]);
      // Update the index for the next insertion
      insertIndex += n + 1;
    }
  }
  return resultArray;
}



export const StoryButtonItem = styled.button`

  font-size: 14px;
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  svg {
    width: 15px;
    height: 15px;
  }
`;


const StoryButton = styled.button`
  position: absolute;
  font-size: 14px;
  border: 1px solid white;
  border-radius: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  color: white;
  svg {
    width: 15px;
    height: 15px;
  }
`;
// const StoryWrapper = styled.div`
//   position: fixed;
//   background-color: #00000060;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 99999;
//   min-width: 100vw;
//   min-height: 100vh;
// `;
const ModeButtons = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  gap: 20px;
  color: white;
  button {
    font-size: 14px;
    border: 1px solid white;
    padding: 5px 10px;
    border-radius: 10px;
    margin: 0;
  }
`;

const StoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const StoryVideo = styled.video`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  object-fit: cover;
`;

const StoryModal = styled.div`
  max-width: 450px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  background-color: orange;
`;

const StoryInfoWrapper = styled.div`
  position: absolute;
   z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  color: white;
  padding: 15px;
  gap: 20px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6502976190476191) 18%,
    rgba(255, 255, 255, 0) 94%
  );
`;
const StoryInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a1a1a1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;
const UserAndStories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 85%;
`;
const UserInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid pink;
  }
  span {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
  button {
    font-size: 14px;
    border: 1px solid white;
    padding: 5px 10px;
    border-radius: 10px;
    margin: 0;
  }
`;
const CaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Caption = styled.p`
  font-size: 14px;
  color: white;
  margin: 0;
`;
const SubCaption = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  text-align: left;
  font-size: 14px;
  color: #ffffffb2;
  margin: 0;
  span {
    width: 5px;
    height: 5px;
    background: #ffffffb2;
    border-radius: 50%;
  }
`;
const Followings = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #ffffffb2;
  font-size: 14px;
`;
const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: -10px;
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
`;
const Badge = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 5px;
  border-radius: 10px;
  background-color: #00000055;
  color: white;
  white-space: nowrap;
  svg {
    width: 15px;
    height: 15px;
    color: white;
  }
`;
const StoryActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  svg {
    width: 30px;
    height: 30px;
  }
`;
const Music = styled.div`
  width: 35px;
  height: 35px;
  background-color: #010233;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    color: white;
  }
`;
