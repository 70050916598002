import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import skills_industry from "src/assets/data/skills_industry";
import { InputAgenda } from "../AgendaFormComponent";
import Select from "react-select";
import { colors } from "../MyLinks";
import { useEffect, useMemo, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { UserContext } from "src/App";
import { deleteDataDB, getDataDB, saveDataDB } from "src/api";
import {
  Add,
  CloudUpload,
  Edit,
  Favorite,
  Handshake,
  InsertDriveFile,
  RemoveCircleOutline,
  Save,
  Share,
  Star,
  StarBorder,
  X,
} from "@mui/icons-material";
import { ShareWrapper } from "../Share";
import { BasicMutipleSelect } from "./BasicMutipleSelect";
import { RatingPopUpModal } from "../RatingPopUpModal";
import { LinkC, LinkParent, TabListItems } from "./TabListItems";
import CarrousselCard from "../CarrousselCard";
import { ListItems } from "../ListItems";
import _ from "lodash";

export const AboutTabListItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, resource } = props;
  const params: any = useParams<any>();
  const { myInfo, host, eventTab, hostTabs } = myContext;

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [addSkill, setAddSkill] = useState<any>("");
  const [allLikes, setAllLikes] = useState<any>([]);
  const [allShares, setAllShares] = useState<any>([]);
  const [allMySkills, setAllMySkills] = useState<any>([]);
  const [Singleselect, setSingleselect] = useState("");
  const [selected, setSelected] = useState<any>([]);

  const skillsIndustry: any = useMemo(() => {
    return skills_industry;
  }, [skills_industry]);

  const options: any = useMemo(() => {
    return Object.keys(skills_industry)?.map((res: any) => {
      return {
        value: res,
        label: res,
      };
    });
  }, [skills_industry]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  useEffect(() => {
    if (!host?.handle) {
      return;
    }
    const data = {
      query: {
        handle: host?.handle,
      },
      resource: resource, //`calendar_skills_record`,
      check: ["handle"],
    };
    getDataDB(data).then((res) => {
      console.log("hostTabsxxggg", res);

      setDataRecord(res?.[0]?.industry ? res?.[0]?.industry : {});
      setRefreshing(false);
    });

    ///get my likes
    const dataLikes = {
      query: {
        handle: host?.handle,
      },
      resource: `calendar_${params.eventId ? "event" : "host"
        }_${hostTab}_likes`,
      check: ["handle"],
    };

    // console.log('formDatacc', data)
    getDataDB(dataLikes).then((res) => {
      console.log("hostTabsxx", res?.[0]?.industry);
      setAllLikes(res);
    });

    const dataShares = {
      query: {
        handle: host?.handle,
      },
      resource: `calendar_${params.eventId ? "event" : "host"
        }_${hostTab}_shares`,
      check: ["handle"],
    };

    getDataDB(dataShares).then((res) => {
      console.log("hostTabsxx", res?.[0]?.industry);
      setAllShares(res);
    });

    const allMy = {
      query: {
        handle: myInfo?.handle,
      },
      resource: resource, // `calendar_skills_record`,
      check: ["handle"],
    };

    getDataDB(allMy).then((res) => {
      console.log("setAllMySkills", res?.[0]?.industry);
      setAllMySkills(res?.[0]?.industry);
    });
  }, [refreshing, host?.handle]);

  const myLike = useMemo(() => {
    return allLikes?.filter((res: any) => res?.handle == myInfo?.handle)?.[0];
  }, [allLikes, myInfo?.handle]);

  const myShare = useMemo(() => {
    return allLikes?.filter((res: any) => res?.handle == myInfo?.handle)?.[0];
  }, [allLikes, myInfo?.handle]);

  useEffect(() => {
    console.log("addType", addType);
  }, [addType]);

  useEffect(() => {
    if (!selected?.industry) {
      return;
    }

    const newFormInput: any = {
      ...formInput,
      industry: {
        ...(dataRecord || {}),
      },
    };
    // console.log("setSelected11", newFormInput);

    newFormInput["industry"][selected?.industry] = [
      ...(selected?.skills || []),
    ];
    if (!(newFormInput && selected?.industry)) {
      return;
    }

    console.log("setSelected22", newFormInput);

    const data = {
      query: {
        ...newFormInput,
      },
      resource: resource, //`calendar_skills_record`,
      check: ["handle"],
      myInfo,
    };
    // console.log("formDatacc", data);

    saveDataDB(data).then((res) => {
      // setRefreshing(true);
    });
  }, [selected]);

  const [file, setFile] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf" && file.size <= 2097152) {
      setFile(file);
      // Uploading Logic Here PLease
    } else {
      alert("Please upload a PDF file smaller than 2MB.");
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file && file.type === "application/pdf" && file.size <= 2097152) {
      setFile(file);
      // Upload logic here
    } else {
      alert("Please upload a PDF file smaller than 2MB.");
    }
  };

  return (
    <>
      <div>
        {editInfo && (
          <>
            <EditSkillsModalWrapper>
              <AddSkillSection>
                <h2>Add from Resume</h2>
                {file ? (
                  <UploadedResume>
                    <div>
                      <InsertDriveFile />
                      <p>{file?.name}</p>
                    </div>
                    <div>
                      <button onClick={() => setFile(null)}>Change File</button>
                      <button>Upload</button>
                    </div>
                  </UploadedResume>
                ) : (
                  <UploadResumeFile
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                    htmlFor="fileInput"
                  >
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <CloudUpload />
                    <h3>Click Here or Drop Your Resume In here</h3>
                    <p
                      style={{
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      English Resumes in <b> PDF </b> only. <b>Max 2mb</b> file
                      size
                    </p>
                  </UploadResumeFile>
                )}
              </AddSkillSection>
              <AddSkillSection>
                <h2>Add Skills Manually</h2>
                <SkillChildWrapper>
                  <SkillAddWrapper>
                    <Select
                      defaultValue={Singleselect}
                      onChange={(e: any) => {
                        const data = {
                          ...selected,
                          industry: e?.value,
                          skills: selected?.skills || [],
                        };

                        console.log("nnnnn", data);

                        setSelected(data);
                      }}
                      options={options}
                      placeholder="Select Industy"
                      classNamePrefix="selectform"
                    />

                    {skillsIndustry?.[selected?.industry] && (
                      <BasicMutipleSelect
                        {...props}
                        options={skillsIndustry?.[selected?.industry]?.map(
                          (res: any) => {
                            return {
                              value: res,
                              label: res,
                            };
                          }
                        )}
                        selected={selected}
                        setFormInput={setFormInput}
                        handleChange={(e: any) => {
                          console.log("handleChange", e);
                          const data = {
                            ...selected,
                            skills: e?.map((res: any) => res.value),
                          };
                          console.log("nnnnn", data);
                          setSelected(data);
                        }}
                      />
                    )}
                  </SkillAddWrapper>
                  {skillsIndustry?.[selected?.industry] && (
                    <InputAgenda onSubmit={(e: any) => { }}>
                      <input
                        type={"text"}
                        onChange={(e: any) => {
                          console.log("nnnnn", data);
                          setAddSkill(e.target.value);
                        }}
                        value={addSkill || ""}
                        placeholder={"Add Skill ..."}
                        required
                      />

                      {addSkill?.length > 0 && (
                        <div
                          onClick={() => {
                            if (!addSkill) {
                              return;
                            }
                            const data = {
                              ...selected,
                              skills: [...(selected?.skills || []), addSkill],
                            };
                            console.log("bvbvbv", data);
                            setSelected(data);
                          }}
                        >
                          <Add />
                        </div>
                      )}
                    </InputAgenda>
                  )}
                </SkillChildWrapper>
              </AddSkillSection>
            </EditSkillsModalWrapper>
          </>
        )}
        <SkillTags
          {...props}
          dataRecord={dataRecord}
          editInfo={editInfo}
          formInput={formInput}
          myLike={myLike}
          myShare={myShare}
          allShares={allShares}
          allLikes={allLikes}
          allMySkills={allMySkills}
        />
        <SpecialListings
          {...props}
          resource={"calendar_members_record"}
          query={{
            handle: host?.handle,
          }}
        />
      </div>
    </>
  );
};

export const SpecialListings = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, editButton = false, resource = [], query = {} } = props;

  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { myInfo, host, eventId, hostTabs, allAccess } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  // const resource = useMemo(() => {
  //   const tab = eventTab || hostTab;
  //   const subTab = eventSubTab || hostSubTab;
  //   return `calendar_${params.eventId ? "event" : "host"}${tab ? "_" + tab : ""
  //     }${subTab ? "_" + subTab : ""}`;
  // }, [eventTab, eventSubTab, hostSubTab, hostTab, params?.eventTab]);

  useEffect(() => { }, [eventId, host?.handle]);

  useEffect(() => {
    (async () => {
      const colabs: any = [];
      const data = {
        query: query,
        resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
        check: ["handle"],
      };
      getDataDB(data).then(async (res) => {
        // console.log("hostTabsxxccnnvv", res);
        if (res?.[0]) {
          const colabs = await Promise.all(
            res?.map(async (hand: any) => {
              const rating = await getDataDB({
                query: {
                  host: hand?.handle,
                },
                resource: "calendar_host_about_endorsement",
              }).then((info) => {
                if (info?.[0]) {
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      skillRating: info,
                    };
                  });
                  const rating = _.sumBy(info, function (o: any) {
                    return o.rating;
                  });
                  const avgRating = rating / (info?.length * 5);
                  return Math.round(avgRating);
                }
              });
              // console.log("vvvvvyyys", rating);

              const user = await getDataDB({
                query: {
                  handle: hand?.handle,
                },
                resource: "calendar_users", //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
                check: ["handle"],
              })?.then((rag) => rag?.[0]);
              // console.log("hostTabsxxccnnvv", user);
              const label = eventTab ? "" : " (" + res?.[0]?.title + ")";

              return {
                ...user,
                eventId: res?.[0]?._id,
                title: user?.name + label,
                description: user?.name,
                handle: user?.handle,
                rating: rating,
              };
              // return user
            })
          );

          // console.log("hostTabsxxccnngg", colabs);

          setDataRecord(colabs?.[0] ? colabs : []);
          setRefreshing(false);
          setFormInput({});
        }
      });
    })();
  }, [
    eventTab,
    myInfo?.handle,
    host?.handle,
    params.eventId,
    params.hostId,
    refreshing,
  ]);

  const myData = useMemo(() => {
    return [
      ...(formInput?.title
        ? [
          {
            file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            ...formInput,
          },
        ]
        : []),
      ...dataRecord
        ?.filter((res: any) => res.title != formInput?.title)
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title,
            // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          };
        }),
    ];
  }, [
    // formInput?.title, 
    dataRecord
  ]);

  console.log("hostTabsxxccnngg", myData);

  if (!(myData?.length > 0)) {
    return null;
  }

  return (
    <>
      <div
        style={{
          paddingBottom: 100,
          paddingTop: 50,
        }}
      >
        <h5>Organization</h5>
        {formInput?.view == "card" ? (
          <LinkParent>
            {myData?.map((res: any) => {
              return (
                <LinkC
                  href={"#"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_55_8225)">
                      <rect width="80" height="80" rx="6" fill="#404040" />
                      <path
                        d="M60.8334 40C60.8334 28.5 51.5001 19.1667 40.0001 19.1667C28.5001 19.1667 19.1667 28.5 19.1667 40C19.1667 50.0833 26.3334 58.4792 35.8334 60.4167V46.25H31.6667V40H35.8334V34.7917C35.8334 30.7708 39.1042 27.5 43.1251 27.5H48.3334V33.75H44.1667C43.0209 33.75 42.0834 34.6875 42.0834 35.8333V40H48.3334V46.25H42.0834V60.7292C52.6043 59.6875 60.8334 50.8125 60.8334 40Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_55_8225">
                        <rect width="80" height="80" rx="6" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div>{res?.title}</div>
                </LinkC>
              );
            })}
          </LinkParent>
        ) : formInput?.view == "carroussel" ? (
          <CarrousselCard //used for user profile on main guest page
            // onViewClick={(item: any) => {
            //   setMyContext((existing: any) => {
            //     return {
            //       ...existing,
            //       action: false,
            //       storyResource: {
            //         ...props?.tab,
            //         myNews: [item]

            //       }
            //     };
            //   });
            // }}
            people={myData}
            isSmall={true}
          />
        ) : (
          <ListItems
            editButton={allAccess ? true : false}
            {...props}
            display={"title"}
            source={
              //"files"
              eventTab
            }
            // model={hostTabs['model']}
            formInput={formInput}
            items={myData || []}
            // setTagType={setTagType}
            // tagType={tagType}
            handleSetItem={(item: any) => {
              // setEditFile({
              //   ...item,
              // });
              // alert(item?._id)
              setFormInput({
                ...formInput,
                ...item,
              });
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  editInfo: true,
                };
              });
            }}
            handleDeleteItem={(item: any) => {
              deleteDataDB({
                id: item?._id,
                resource: resource,
                // resource: `calendar_${params.eventId ? "event" : "host"
                // }_${eventTab}`,
              }).then((rep: any) => {
                alert("Deleted");
                //setRefreshing(true);
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    editInfo: false,
                  };
                });
              });
            }}
          />
        )}
      </div>
    </>
  );
};

export const SkillTags = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    resource,
    dataRecord,
    editInfo,
    formInput = {},
    setFormInput,
    myLike,
    myShare,
    allShares,
    allLikes,
    allMySkills,
  } = props;
  const params: any = useParams<any>();

  // const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { myInfo, host, hostTabs, allAccess, skillRating } = myContext;

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);
  const [editSkillIndex, setEditSkillIndex] = useState<any>(-1);
  // console.log('vvvvv', dataRecord)

  return (
    <>
      {dataRecord &&
        Object.keys(dataRecord) &&
        Array.isArray(Object.keys(dataRecord)) &&
        Object.keys(dataRecord)?.length > 0 &&
        Object.keys(dataRecord)?.map((industry: any) => {
          if (!(industry && dataRecord?.[industry]?.length > 0)) {
            return;
          }
          return (
            <GuestDetailsWrapper onClick={() => console.log(host)}>
              <GuestDetailSection>
                <GuestDetailIndustryWrapper>
                  <h1>{industry}</h1>
                  {myInfo?.handle == formInput?.handle && editInfo ? (
                    <RemoveCircleOutline
                      onClick={() => {
                        // delete dataRecord?.[industry]
                        const newFormInput: any = {
                          ...formInput,
                          industry: {
                            ...(dataRecord || {}),
                            [industry]: {},
                          },
                        };
                        const data = {
                          query: {
                            ...newFormInput,
                          },
                          resource: resource, // `calendar_skills_record`,
                          check: ["handle"],
                          myInfo,
                        };
                        saveDataDB(data).then((res) => {
                          // setRefreshing(true);
                        });
                      }}
                    />
                  ) : null}
                </GuestDetailIndustryWrapper>
                <IteraterWrapper>
                  {dataRecord &&
                    industry &&
                    dataRecord?.[industry] &&
                    Array.isArray(dataRecord?.[industry]) &&
                    dataRecord?.[industry]?.map((skill: any, index: any) => {
                      const likeInfo: any =
                        myLike && myLike?.skill == skill ? myLike?.like : false;
                      const likeList: any = allLikes?.filter((like: any) => {
                        return like.skill == skill;
                      });

                      const shareInfo: any =
                        myShare && myShare?.skill == skill
                          ? myShare?.like
                          : false;
                      const shareList: any = allShares?.filter((like: any) => {
                        return like.skill == skill;
                      });

                      const matchInfo: any =
                        industry && allMySkills && allMySkills?.[industry];

                      const skillEndorsement = skillRating?.filter(
                        (ref: any) => ref?.skill == skill
                      )?.[0];

                      return (
                        <SkillContainer
                          key={index + "nghsssdsgfgf"}
                          data-tooltip-id="my-desc-tooltip"
                          editDescription={
                            editSkillIndex == index ? true : false
                          }
                        >
                          {myInfo?.handle !== host?.handle && (
                            <SkillToolKit
                              id="my-desc-tooltip"
                              place="bottom"
                              variant="light"
                              border={"1px solid gray"}
                              style={{
                                maxWidth: 220,
                                fontSize: 12,
                                padding: 5,
                                textAlign: "left",
                              }}
                            >
                              <h2>Skill Description: </h2>
                              <p>
                                {/* use this false as the description of the skill from database of guest */}
                                {false ||
                                  " Host didn't add any description for this skill."}
                              </p>
                            </SkillToolKit>
                          )}
                          <SkillActions>
                            <SkillAction
                              onClick={() => {
                                const newFormInput: any = {
                                  share: !shareInfo,
                                  industry,
                                  skill,
                                  handle: myInfo?.handle,
                                };
                                const data = {
                                  query: {
                                    ...newFormInput,
                                  },
                                  resource: `calendar_${params.eventId ? "event" : "host"
                                    }_${hostTab}_shares`,
                                  check: ["handle", "skill"],
                                  myInfo,
                                };
                                saveDataDB(data).then((res) => {
                                  // setRefreshing(true);
                                });
                              }}
                            >
                              <ShareButton>
                                <ShareWrapper
                                  color={shareInfo || ""}
                                  message={"Refer this requirement to a friend"}
                                  eventLink={`https://www.myguestnet.com/event/${hostTab
                                    ? hostTab +
                                    (hostSubTab ? "/" + hostSubTab : " ")
                                    : " "
                                    }`}
                                  users={[]}
                                >
                                  <Share
                                    style={{
                                      color: shareInfo ? "green" : "black",
                                    }}
                                  />
                                </ShareWrapper>
                              </ShareButton>

                              {shareList?.length > 0 && (
                                <p>{shareList?.length}</p>
                              )}
                            </SkillAction>

                            <SkillAction>
                              <Favorite
                                style={{
                                  color: likeInfo ? "green" : "black",
                                }}
                                onClick={() => {
                                  // delete dataRecord?.[industry]
                                  const newFormInput: any = {
                                    like: !likeInfo,
                                    industry,
                                    skill,
                                    handle: myInfo?.handle,
                                  };
                                  const data = {
                                    query: {
                                      ...newFormInput,
                                    },
                                    resource: `calendar_${params.eventId ? "event" : "host"
                                      }_${hostTab}_likes`,
                                    check: ["handle", "skill"],
                                    myInfo,
                                  };
                                  // console.log('formDatacc', data)
                                  saveDataDB(data).then((res) => {
                                    // setRefreshing(true);
                                  });
                                }}
                              />
                              {likeList?.length > 0 && (
                                <p>{likeList?.length}</p>
                              )}
                            </SkillAction>
                            <SkillAction>
                              <ShareButton
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {skillEndorsement
                                  ? skillEndorsement?.rating
                                  : ""}
                                <RatingPopUpModal
                                  industry={industry}
                                  skill={skill}
                                  resource={`calendar_${params.eventId ? "event" : "host"
                                    }_${hostTab}_endorsement`}
                                  title={"Skill Endorsement"}
                                  eventLink={`https://www.myguestnet.com/event/${hostTab
                                    ? hostTab +
                                    (hostSubTab ? "/" + hostSubTab : " ")
                                    : " "
                                    }`}
                                  color={shareInfo || ""}
                                  users={[]}
                                >
                                  <StarBorder
                                    style={{
                                      color: shareInfo ? "green" : "black",
                                    }}
                                  />
                                </RatingPopUpModal>
                              </ShareButton>
                            </SkillAction>

                            {myInfo?.handle == hostTab && editInfo && (
                              <div>
                                <RemoveCircleOutline
                                  onClick={() => {
                                    const newFormInput: any = {
                                      ...formInput,
                                      industry: {
                                        ...(dataRecord || {}),
                                        [industry]: dataRecord?.[industry]
                                          ? dataRecord?.[industry]?.filter(
                                            (res: any) => res != skill
                                          )
                                          : [],
                                      },
                                    };
                                    const data = {
                                      query: {
                                        ...newFormInput,
                                      },
                                      resource: resource, //`calendar_skills_record`,
                                      check: ["handle"],
                                      myInfo,
                                    };
                                    // console.log('formDatacc', data)
                                    saveDataDB(data).then((res) => {
                                      // setRefreshing(true);
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </SkillActions>
                          {editSkillIndex == index &&
                            myInfo?.handle &&
                            myInfo?.handle == host?.handle ? (
                            <SkillEditDescriptionWrapper>
                              <Save onClick={() => setEditSkillIndex(-1)} />
                              <h5>Skill Description</h5>
                              <textarea
                                style={{
                                  width: "100%",
                                }}
                                maxLength={1000}
                                onChange={(e: any) => {
                                  const txt = e?.target?.value;
                                  setFormInput({
                                    ...formInput,
                                    description: txt,
                                  });
                                }}
                                placeholder={
                                  false ||
                                  `Description not available at this time.`
                                }
                                rows={5}
                              />
                            </SkillEditDescriptionWrapper>
                          ) : null}
                          <SkillTabContainer
                            {...props}
                            myInfo={myInfo}
                            host={host}
                            setEditSkillIndex={setEditSkillIndex}
                            editSkillIndex={editSkillIndex}
                            index={index}
                            match={
                              matchInfo && matchInfo?.includes(skill)
                                ? true
                                : false
                            }
                            skill={skill}
                          />
                        </SkillContainer>
                      );
                    })}
                </IteraterWrapper>
              </GuestDetailSection>
            </GuestDetailsWrapper>
          );
        })}
    </>
  );
};

export const SkillTabContainer = (props: any) => {
  const { match, skill, views, children } = props;
  return (
    <SkillHolder
      isMatched={match}
      style={{
        whiteSpace: "nowrap",
      }}
    >
      {children || <Handshake />}
      {skill}
      {props.myInfo?.handle && props.myInfo?.handle == props.host?.handle && (
        <Edit
          onClick={() => {
            props.setEditSkillIndex(
              props.editSkillIndex == props.index ? -1 : props.index
            );
          }}
        />
      )}
      {views && (
        <div
          style={{
            backgroundColor: "gray",
            borderRadius: 100,
            color: "white",
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          {views}
        </div>
      )}
    </SkillHolder>
  );
};

const UploadedResume = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  p {
    font-size: 12px;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    width: 15px;
    height: 100%;
    color: #479d83;
  }
  button {
    border: 1px solid #479d83;
    padding: 3px 8px;
    background: #fff;
    font-size: 12px;
    transition: background 0.3s;
    margin: 0;
    &:hover {
      background: #479d832b;
    }
  }
`;

const UploadResumeFile = styled.label`
  background: #479d832b;
  border: 1px dashed gray;
  width: 100%;
  padding: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 10px;
    color: gray;
    margin: 0;
  }
  svg {
    width: 40px;
    color: #479d83;
    height: 100%;
  }
`;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const GuestDetailsWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const GuestDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const GuestDetailIndustryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  h1 {
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    color: #475063;
    margin: 0;
    text-align: left;
  }
  svg {
    width: 15px;
    height: 15px;
    cursor: pointer;
    color: red;
  }
`;

const IteraterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
`;

const AddSkillSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

export const ShareButton = styled.div`
  svg {
    width: 15px;
    height: 15px;
    color: #479d83;
  }
`;

export const SkillContainer = styled.button<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  border: none;
  background: transparent;
  z-index: ${(props: any) => (props.editDescription ? "none" : "9")};
  &:hover {
    form {
      top: -31px;
    }
  }
  cursor: pointer;
`;

export const SkillHolder = styled.div<{ isMatched: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 12px;
  color: black;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => (props.isMatched ? "#c4e3da" : "#fff")};
  border: ${(props) => (props.isMatched ? "none" : "1px solid gray")};
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const SkillActions = styled.form`
  display: flex;
  padding: 5px;
  padding-bottom: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  gap: 20px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  top: 0px;
  z-index: -1;
  transition: all 0.3s;
`;
export const SkillAction = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  svg {
    width: 100%;
    height: 100%;
  }
  p {
    margin: 0;
    position: absolute;
    width: 15px !important;
    max-width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: 600;
    background-color: white;
    border: 1px solid gray;
    border-radius: 50%;
    top: -3px;
    right: -8px;
  }
`;
const SkillToolKit = styled(ReactTooltip)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  color: #000;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-size: 12px;
    margin: 0;
    color: #000;
  }
`;
const SkillEditDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  min-width: 180px;
  width: 100%;
  border-radius: 2px;
  z-index: 999;
  position: absolute;
  bottom: -112px;
  left: 50%;
  transform: translateX(-50%);
  h5 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
  textarea {
    resize: none;
    font-size: 12px;
    width: 100%;
    height: 70px;
    border: 1px solid gray;
    border-radius: 2px;
    padding: 5px;
    outline: none;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #479d83;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
      border-radius: 4px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
  }
  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000;
    width: 15px;
    height: 15px;
  }
`;
