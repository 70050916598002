import { useState, useEffect, useMemo, useContext } from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import DateRange from "@mui/icons-material/DateRange";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Share from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { ButtonL } from "./AppIndex";
import {
  deleteDataDB,
  getAttendanceDB,
  getDataDB,
  getEventLikesDB,
  getUserInfoByHandle,
  groupByNow,
  handleSaveEventLikes,
  relatedPict,
  removeDuplicateObjects,
  saveDataDB,
} from "src/api";
// import { Avatar } from "@mui/material";
import {
  FilterAlt,
  LockOpen,
  LockPerson,
  PushPin,
  RemoveRedEye,
  Verified,
} from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FunnelComponent } from "./FunnelComponent";



export const GuestListIcons = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const navigate = useNavigate();
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    allMyEvents,
    host = "",
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    searchBy = "Event",
  } = myContext;
  const { imagen, item } = props;

  const params: any = useParams<any>();
  const [attendance, setAttendance] = useState<any>([]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostId = useMemo(() => {
    return params?.hostId;
  }, [params?.hostId]);

  useEffect(() => {
    if (eventId)
      getAttendanceDB({
        eventId: eventId,
        // handle: myInfo?.handle
      }).then(async (res: any) => {
        if (res?.[0]) {
          console.log("hostTabsxxccnn", res);
          const dedupe = removeDuplicateObjects({
            arr: res, key: 'img'
          })
          if (dedupe?.[0]) {
            setAttendance(dedupe || []);
          }
        }
      });
  }, [
    myInfo?.handle,
    host?.handle,
    eventId
  ]);




  // console.log("hostTabs", hostTabs, eventTab, model);

  // useEffect(() => {
  //   if (item?._id)
  //     getAttendanceDB({
  //       eventId: item?._id,
  //       // handle: myInfo?.handle
  //     }).then(async (res: any) => {
  //       const event =
  //         res &&
  //         res?.[0] &&
  //         groupByNow({
  //           data: res,
  //           key: "handle",
  //           id: 2,
  //         });
  //       const guestInfo: any =
  //         event &&
  //         Object.keys(event)?.length > 0 &&
  //         (await Promise.all(
  //           Object.keys(event)?.map(async (hand: any) => {
  //             return await getUserInfoByHandle(hand).then((user: any) => {
  //               console.log("hostTabsxxccnn", user);

  //               if (!user) {
  //                 return {};
  //               }
  //               return {
  //                 ...user,
  //                 active: true,
  //                 eventInfo: event,
  //                 eventId: event?.[0]?._id,
  //                 title: user?.name,
  //                 description: event?.[0]?.title,
  //                 handle: user?.handle,
  //                 link: `/host/${user?.handle}/about`,
  //                 role: "collaborator",
  //               };
  //             });
  //           })
  //         ));
  //       console.log("getAttendanceDBuser", item?._id, guestInfo);

  //       setAttendance(guestInfo || []);
  //     });
  // }, [myInfo?.handle, host?.handle, item?._id]);



  return (
    <div
      onClick={() => {
        navigate(`/host/${item.handle}/about`);
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 5,
        width: '100%',
        height: 50,
        marginBottom: -10
      }}
    > <AvatarainContainer style={{
      flexDirection: 'column'
    }}>
        <Avatar
          alt={"NA"}
          src={"https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"}
          sx={{ width: 30, height: 30 }}
          style={{
            height: 40,
            width: 40,
            marginRight: -10,
            backgroundColor: 'gray',
            borderRadius: 25,
            borderWidth: 2,
          }}
        />
        {/* <div style={{
          fontSize: '1.2vh'
        }}>
          {'Watchers'}
        </div> */}
      </AvatarainContainer>

      {attendance?.map((res: any, index: number) => {
        if (index < 3)
          return (
            <AvatarainContainer
              onClick={() => {
                navigate(`/host/${item.handle}/about`);
              }}
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Avatar
                alt={res?.name || "NA"}
                src={
                  res.img ||
                  relatedPict[index] ||
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                }
                sx={{ width: 30, height: 30 }}
                style={{
                  height: 40,
                  width: 40,
                  marginRight: -10,
                  backgroundColor: 'gray',
                  borderRadius: 25,
                  borderWidth: 2,
                  borderColor: 'white',
                }}
              />
              {/* <div style={{
              fontSize: '1.2vh',
              maxWidth: 40,
              textAlign: 'center',
              marginLeft: 5
            }}>
              {res?.name?.length >= 7 ? res?.name?.substring(0, 7) : res?.name}
            </div> */}
            </AvatarainContainer>
          );
      })}
    </div>
  );
};


const AvatarainContainer = styled.div`
   shadow-color: #999
  shadow-offset: { width: 0, height: 8 }
  shadow-opacity: 0.3
  shadow-radius: 15px
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin: 20px 20px;
  font-family: "Poppins", sans-serif;
  span {
    width: 100%;
    max-width: 930px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    span {
      color: #828282;
    }
  }
`;
const CardWrapperParent = styled.div`
  max-width: 930px;
  height: 245px;
  width: 100%;
  padding: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  border-radius: 16px;
  gap: 16px;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 756px) {
    flex-direction: column;
    height: auto; /* Reset height to auto for vertical stacking */
  }
`;
const ImgContainer = styled.div`
  position: relative;
  height: 100%;
  max-width: 280px;
  width: 100%;
  @media screen and (max-width: 756px) {
    max-width: none;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
export const RR = styled(RWebShare)`
  border: 12px solid green;
`;

const CardHead = styled.h1`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;
const HeadIcons = styled.h1`
  display: flex;
  gap: 5px;
`;
const CardSubHead = styled.h1`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 350px;
  text-align: left;
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;
const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;
  button {
    border: none;
    background: none;
    color: #bcbcbc;
    transition: all 0.3s;
  }
`;

const PageNumber = styled.div<{ active: boolean; isPage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: ${(props) => (props.active ? "#fff" : "#000")};
  background: ${(props) => (props.active ? "#000" : "#fff")};
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: ${(props) =>
    props.active ? "#000" : props.isPage ? "#fff" : "#dbdbdb"};
  }
`;
const CardInfoHead = styled.div`
  width: 100%;
  text-align: left;
`;
const EventDate = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    color: #717171;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 756px) {
      font-size: 12px;
      text-align: left;
    }
  }
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;
const EventLocation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    b {
      color: #2b2e39;
      font-size: 18px;
      font-weight: 700;
    }
    a {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;
const ButtonE = styled.button`
  display: flex;
  gap: 12px;
  border: none;
  background: none;
  outline: none;
  color: #2b2e39;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s;
  &:hover {
    text-decoration: underline;
  }
`;
const CardHeadChild = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  svg {
    width: 32px;
    height: 32px;
    color: gray;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: black;
    }
  }
`;
const ButtonC = styled.div`
  border: none;
  background-color: #479d83;
  padding: 5px 10px;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: #57b397;
  }
`;

export const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;


const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: 10px;
  }
`;


const TaskAvatars = styled.div`
  margin-top: 10px;
`;
const DropDownFilter = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  ul {
    background: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-decoration: none;
    margin: 0;
    position: absolute;
    top: 15px;
    left: 15px;
    max-height: 150px;
    min-width: 150px;
    padding: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #479d83;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
      border-radius: 4px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      text-decoration: none;
      padding: 5px 10px;
      cursor: pointer;
      background: white;
      transition: background 0.3s;
      justify-content: space-between;
      &:hover {
        background-color: #80808071;
      }
      input {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
