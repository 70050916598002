import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
// import { IconButton } from 'material-ui';


export const BasicMutipleSelect = (props: any) => {
  const { options, handleChange, selected: parentSelect = {} } = props;
  const [selected, setSelected] = useState<any>([]);

  useEffect(() => {
    console.log("selectedxxx", selected);
    handleChange(selected);
  }, [selected]);

  useEffect(
    () => {
      // const info: any = parentSelect?.skills && [
      //   ...(parentSelect?.skills?.map((res: any) => {
      //     return { value: res, label: res }
      //   }))
      // ]
      // console.log('selectedxxx', info, (parentSelect?.skills))
      // if (parentSelect?.skills?.[0]) {
      //   setSelected(info)
      // }
    },
    [
      // parentSelect?.skills
    ]
  );
  // const options = [
  //   { value: "Audi", label: "Audi" },
  //   { value: "BMW", label: "BMW" },
  //   { value: "volkswagen", label: "volkswagen" },
  //   { value: "Aston Martin", label: "Aston Martin" },
  //   { value: "mitsubishi", label: "mitsubishi" },
  //   { value: "hyundai", label: "hyundai" },
  //   { value: "fiat", label: "fiat" },
  // ];
  return (
    <MultiSelect
      value={selected}
      onChange={setSelected}
      labelledBy={"Select Skills"}
      options={options}
      disableSearch
      className=""
    />
  );
};

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const LinkParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;