import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import styled from "@emotion/styled";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Add from "@mui/icons-material/Add";
import DatePicker from "react-multi-date-picker";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteAgendaDB,
  getAgendaDB,
  getCalendarEvents,
  myColors,
  saveAgendaDB,
  saveEventDB,
} from "src/api";
import { UserContext } from "src/App";
import { ListItems } from "./ListItems";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const AgendaFormComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    agendas = [],
    host,
    allAccess
    // comments = [],
  } = myContext;
  const {
    eventId,
    // agendas = [],
    setAgendas,
    setTagType,
    tagType,
    // formInput,
    // setFormInput
  } = props;
  const [editAgenda, setEditAgenda] = useState<any>("");
  const [formInput, setFormInput] = useState<any>({});
  const [openTag, setOpenTag] = useState<any>("");

  useEffect(() => {
    if (eventId && myInfo?.handle) {
      getAgendaDB({
        eventId: eventId,
        handle: myInfo?.handle,
      }).then((res) => {
        setMyContext((existing: any) => {
          return {
            ...existing,
            agendas: [...(res || [])],
          };
        });
      });
    } else {
      setEditAgenda({});
    }
  }, [eventId, myInfo?.handle]);

  const handleMyAgendaData = (info: any) => {
    console.log("loaderMyData", info);

    if (!(myInfo?.handle && info?.eventId && info?.title)) {
      alert("Please make sure you complete all fields");
      return;
    }

    const dataProps = {
      ...info,
      handle: myInfo?.handle,
      name: myInfo?.name,
    };
    delete dataProps?._id;
    saveAgendaDB(dataProps).then((rep: any) => {
      alert("Saved");
      getAgendaDB({
        eventId: eventId, // '65934efd0b5d57a5b7857eae',
        handle: myInfo?.handle,
      }).then((res) => {
        // console.log("vvvvv", res);
        setAgendas([...(res || [])]);
        setMyContext((existing: any) => {
          return {
            ...existing,
            agendas: res,
          };
        });
      });
    });
  };

  // useEffect(() => {
  //   getAgendaDB({
  //     eventId: eventId,
  //     handle: myInfo?.handle,
  //   })
  // }, [])

  const dragEnded = (param: any) => {
    const { source, destination } = param;
    let _arr = [...agendas];
    //extracting the source item from the list
    const _item = _arr.splice(source.index, 1)[0];
    //inserting it at the destination index.
    _arr.splice(destination.index, 0, _item);
    // setAgendas(_arr);
    setMyContext((existing: any) => {
      return {
        ...existing,
        agendas: _arr,
      };
    });
  };

  return (
    <div>
      {host?.handle == myInfo?.handle ||
        myContext?.teamEmails?.includes(myInfo?.email) ? (
        <InputAgenda
          onSubmit={(e: any) => {
            console.log("ssss", e);
            if (!(formInput?.title?.length > 0)) {
              alert("Please add agenda");
              return;
            }
            handleMyAgendaData({
              eventId: eventId,
              title: formInput?.title,
            });
          }}
        >
          <input
            // className="form-control"
            type="text"
            // value={data?.title}
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                title: e.target?.value,
              })
            }
            placeholder={editAgenda?.title || "Add Agenda ..."}
            required
          // placeholder="Meeting Link"
          />
          <button type="submit">
            <Add />
          </button>
        </InputAgenda>
      ) : (
        <div>No agenda added by host at this time</div>
      )}

      <ListItems editButton={allAccess ? true : false}
        {...props}
        items={agendas}
        setTagType={setTagType}
        tagType={tagType}
        source={"agendas"}
        handleSetItem={(item: any) => {
          setEditAgenda({
            ...item,
          });
        }}
        handleDeleteItem={(item: any) => {
          deleteAgendaDB({
            id: item?._id,
          }).then((rep: any) => {
            alert("Deleted");
            getAgendaDB({
              eventId: eventId,
              handle: myInfo?.handle,
            }).then((res) => {
              // console.log("vvvvv", res);
              if (res?.[0])
                // setAgendas(res)
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    agendas: res,
                  };
                });
            });
          });
        }}
      />
    </div>
  );
};
export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;
