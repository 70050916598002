import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  saveDataDB,
  getDataDB,
  getDataWildDB,
  saveFunnelLeadsDB,
  groupByNow
} from "src/api";
import {
  AddSkillSection,
  EditSkillsModalWrapper,
  SkillChildWrapper,
  TabListItems,
} from "../MediaSlider/TabListItems";
import { Modal } from "react-bootstrap";
import { PlannerFunnelModal } from "../MediaSlider/PlannerFunnelModal";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { AgendaFormComponent, InputAgenda } from "../AgendaFormComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "src/App";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Select from "react-select/creatable";

// import { MessageCircle } from "react-feather";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { orange, green, blue } from "@mui/material/colors";
import {
  Add as AddIcon,
  ControlPoint,
  RemoveCircleOutline,
  Close,
  Add
} from "@mui/icons-material";

const _ = require("lodash");

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: 10px;
  }
`;

export const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid;
  margin-bottom: 10px;
  cursor: grab;

  &:hover {
    background: gray;
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: 10px;
  }
`;

export const TaskAvatars = styled.div`
  margin-top: 10px;
`;

// const MessageCircleIcon = styled(MessageCircle)`
//   color: ${(props) => props.theme.palette.grey[500]};
//   vertical-align: middle;
// `;

const TaskBadge = styled.div`
  background: gray;
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const TaskNotificationsAmount = styled.div`
  color: gray;
  font-weight: 600;
  margin-right: 10px;
  line-height: 1.75;
`;

const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: 10px;
`;


const onDragEnd = ({
  result,
  columns,
  setColumns,
  resource,
  myInfo,
  eventId,
  host
}) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    const newColumns = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    }
    // console.log('newDatabaseColumns', newColumns, destination.droppableId)

    setColumns(newColumns);
    const reply = { ...(destItems?.[0] || {}) }
    const newDatabaseColumns = {
      ...reply,
      task: destination.droppableId
    }

    // console.log('newDatabaseColumnsxx', destItems?.[0], reply, newDatabaseColumns)

    loadNewColumn({
      newColumns: newDatabaseColumns,
      resource,
      myInfo,
      eventId,
      host
    })
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    const newColumns = {
      ...columns,
      [source.droppableId]: {
        task: source.droppableId,
        ...column,
        items: copiedItems,
      },
    }

    // const newDatabaseColumns = {
    //   ...columns,
    //   task: source.droppableId,
    //   ...column,
    //   ...copiedItems
    // }
    setColumns(newColumns);

    const reply = { ...(copiedItems?.[0] || {}) }
    const newDatabaseColumns = {
      ...reply,
      task: destination.droppableId
    }

    // console.log("FormFields-personsss", reply);

    loadNewColumn({
      newColumns: newDatabaseColumns,
      resource,
      myInfo,
      eventId,
      host
    })
  };
}

const loadNewColumn = (prop) => {
  const {
    newColumns,
    resource,
    myInfo,
    eventId,
    host
  } = prop

  if (Object.keys(newColumns)?.[0]) {
    delete newColumns?._id
    const data = {
      query: {
        ...newColumns,
        handle: myInfo?.handle,
        ...(eventId
          ? {
            eventId: eventId,
          }
          : {}),
        host: host?.handle,
      },
      resource: resource,
      check: ["handle", "id"],
      myInfo,
    };

    // console.log("FormFields-person", data);
    saveDataDB(data).then((ref) => {
    });
  }
}



const Lane = (props) => {
  const {
    column,
    children,
    setTaskModel,
    formInput = {},
    taskModel = {},
    resource,
    setRefreshing,
    eventId,
    host,
    setFormInput
  } = props
  const { myContext, setMyContext } = useContext(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    // isAddMenu = true
  } = myContext;

  const [openForm, setOpenForm] = useState(false);
  const [formInfo, setFormInfo] = useState();

  const saveOpenForm = useCallback(() => {
    if (formInfo) {
      const newInfo = {
        id: uuidv4(),
        title: formInfo,
        badges: [green[600], orange[600]],
        notifications: 2,
        // avatars: [1, 2, 3, 4],
      }

      const report = {
        // ...taskModel,
        ...formInput,
        ...newInfo,
        task: "Backlog",

        // ...formInput?.["Backlog"]?.items,
        // items: [
        //   ...formInput?.["Backlog"]?.items,
        //   newInfo
        // ],
      }

      // console.log('reportsss', report)
      const data = {
        query: {
          ...report,
          handle: myInfo?.handle,
          ...(eventId
            ? {
              eventId: eventId,
            }
            : {}),
          host: host?.handle,
        },
        resource: resource,
        check: ["handle", "id"],
        myInfo,
      };

      console.log("FormFields-person", data);
      saveDataDB(data).then((ref) => {
        setFormInfo('')
        setOpenForm(false)
        setRefreshing(true)

      });


      // setTaskModel(report)


      // setFormInfo('')
    }
  }, [
    column.title,
    openForm,
    formInfo
  ])

  return (
    <Grid item xs={12} lg={4} xl={4}>
      <Card mb={6}>
        <CardContent pb={0}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
          {openForm
            ? <AddSkillSection>
              <h2>Add Description / Details (Optional)</h2>
              <SkillChildWrapper style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
                <InputAgenda onSubmit={(e) => { }}>
                  <input
                    type={"text"}
                    onChange={(e) => {
                      setFormInfo(e?.target?.value)
                    }}
                    // value={formInput?.description || ""}
                    placeholder={"Add task ..."}
                    required
                  />
                  <Close onClick={() => {
                    setFormInfo('')
                    // setOpenForm(false)
                  }} />
                </InputAgenda>
                <Add onClick={() => {
                  saveOpenForm()

                  // setOpenForm(false)
                }} />
              </SkillChildWrapper>
            </AddSkillSection>
            : "Backlog" == column.title
              ? <Button color="primary" variant="contained" fullWidth
                onClick={() => {
                  setOpenForm(true)
                  // setMyContext((existing: any) => {
                  //   return {
                  //     ...existing,
                  //     editInfo: false,
                  //   };
                  // });
                }}>
                <AddIcon />
                Add new task
              </Button> : null}
        </CardContent>
      </Card>
    </Grid>
  );
};

const Task = (props) => {
  const {
    item,
    column,
    children,
    setTaskModel,
    taskModel,
    resource = 'calendar_event_kanban',
    setRefreshing,
    refreshing
  } = props
  const { myContext, setMyContext } = useContext(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    funnelItemKanban = {}
  } = myContext;

  const [openForm, setOpenForm] = useState(false);
  // const [formInfo, setFormInfo] = useState();
  // const [selected, setSelected] = useState([])
  // const [inputFieldFunnel, setInputFieldFunnel] = useState();
  // const [selectedFunnel, setSelectedFunnel] = useState([])

  const [priority, setPriority] = useState();
  const [inputField, setInputField] = useState('')
  const [options, setOptions] = useState([])
  const params = useParams();

  // useEffect(() => {
  //   setMyContext((existing) => {
  //     return {
  //       ...existing,
  //       isAddMenu: !openForm,
  //     };
  //   });
  // }, [
  //   openForm
  // ])


  useEffect(
    _.debounce(() => {
      if (!(inputField?.length > 0)) {
        return;
      }
      const data = {
        key: "name",
        request: "wildsearch",
        searchVal: inputField,
        resource: `calendar_users`,
        limit: 400,
      };
      // console.log('formFieldxx44', formInput?.title)

      getDataWildDB(data).then((res) => {
        // console.log('formFieldxx44', res)
        if (res?.[0]) {
          const result = res
            ?.map((person) => {
              return {
                // ...person,
                // active: true,
                // link: `https://www.myguestnet.com/host/${person?.handle}`,
                // img:
                //   person.img ||
                //   "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                // title: person.name,
                // name: person.name,
                label: person.name,
                value: person?.email,
                handle: person?.handle,
                img: person?.img
                // handle: person?.handle,
              };
            })
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle);
          setOptions([...options, ...result]);
        }
      });
    }, 500),
    [inputField]
  );

  const apiRequestTypes = [
    { label: 'High', value: 'high' },
    { label: 'Medium', value: 'medium' },
    { label: 'Low', value: 'low' },
  ];

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);


  const [funnelData, setFunnelData] = useState({})


  useEffect(() => {
    getDataDB({
      query: {
        handle: myInfo?.handle,
        eventId: eventId
      },
      resource: 'calendar_event_funnel',
    }).then((res) => {
      // console.log('vvbbbb', res?.[0])
      setFunnelData(res?.[0])
    })

  }, [
    myInfo?.handle
  ])

  // console.log('itemxxxttt', item)
  return (<>
    <TaskWrapper mb={4}>
      <TaskWrapperContent onDoubleClick={() => {
        // setOpenForm(true)
        setMyContext((existing) => {
          return {
            ...existing,
            funnelItemKanban: {
              ...item,
              // _id: item?.id
            },
          };
        });
      }}>

        {item.badges &&
          item.badges.map((color, i) => <TaskBadge

            style={{
              backgroundColor: item?.priority == 'high'
                ? 'red'
                : item?.priority == 'medium'
                  ? 'green'
                  : item?.priority == 'low'
                    ? 'yellow'
                    : 'gray'
            }}
            key={i} />)}

        <TaskTitle variant="body1" gutterBottom>
          {item.title}
        </TaskTitle>

        <TaskAvatars>
          <AvatarGroup max={3}>
            {!!item.assign &&
              item.assign.map((avatar, i) => {
                return <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                  <Avatar
                    src={avatar?.img || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'}
                    key={i}
                  />
                  <div style={{
                    fontSize: '0.3em'
                  }}>
                    {avatar?.label}
                  </div>
                </div>
              })}
          </AvatarGroup>
        </TaskAvatars>

        {!!item.notifications && item.notifications > 0 && (
          <TaskNotifications>
            <TaskNotificationsAmount>
              {item.notifications}
            </TaskNotificationsAmount>
            <ControlPoint />
          </TaskNotifications>
        )}
      </TaskWrapperContent>
    </TaskWrapper>
    <Modal
      show={funnelItemKanban && Object.keys(funnelItemKanban)?.[0] ? true : false}
      onHide={() => {
        // setOpenForm(false)
        setMyContext((existing) => {
          return {
            ...existing,
            funnelItemKanban: {},
          };
        });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>MyGuestNet</Modal.Title>
      </Modal.Header>
      <Modal.Body> Add {item.title || 'Title'} to funnel</Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PlannerFunnelModal
          item={{
            // id: uuidv4(),
            // badges: [],
            // notifications: 0,
            // avatars: [2, 3]
            ...funnelItemKanban,
          }}
          mainTopic={item.topic}
          refreshing={refreshing}
          setRefreshing={setRefreshing}
          plannerTopic={funnelItemKanban?.topic}
        />
      </Modal.Footer>
    </Modal>
  </>
  );
};

function KanbanTask(props) {
  const {
    resource,
    setFormInput,
    formInput = {}
  } = props
  const { myContext, setMyContext } = useContext(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    agendas,
    allMyEvents,
    // eventId,
    data = {},
    host,
    // editInfo,
    eventDetailsTabs = [],
    eventInfo = {},
    editInfo,
    leadFunnel
  } = myContext;
  const navigate = useNavigate();
  const [columns, setColumns] = useState({});
  const [documentReady, setDocumentReady] = useState(false);

  // console.log("resource", resource)


  const [refreshing, setRefreshing] = useState(false);

  const [taskModel, setTaskModel] = useState({
    ["Backlog"]: {
      title: "Backlog",
      description: "",
      items: [] //mockItems1,
    },
    ["In Progress"]: {
      title: "In Progress",
      description: "",
      items: [] //mockItems2,
    },
    ["Completed"]: {
      title: "Completed",
      description: "",
      items: [] //mockItems3,
    },
  });


  const params = useParams();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);
  // console.log("eventDetailsTab", params);

  const valuetext = (value) => {
    return `${value}°C`;
  };

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);


  useEffect(() => {
    setDocumentReady(true);
  }, []);

  useEffect(() => {

    if (myInfo?.handle && formInput?.topic) {
      getDataDB({
        query: {
          handle: myInfo?.handle,
          topic: formInput?.topic

        },
        resource: resource,
      }).then((res) => {
        if (res?.[0]) {
          const grouped = groupByNow({
            data: res,
            key: "task",
            id: 2,
          });
          const info = {
            ...taskModel
          }
          Object.keys(grouped)?.map(type => {
            if (info[type]?.items) {
              info[type].items = grouped[type]
            }
          })
          // console.log('funnelLeads33', info)

          setTaskModel(info)

          // setTaskModel(res?.[0]);
        }
      });

    }

  }, [
    myInfo?.handle,
    formInput?.topic,
    refreshing
  ]);



  const handleTemplate = (props) => {
    const { formData = {}, leadFunnel = [] } = props

    // console.log('columnssssss', columns)

    if (columns?.Backlog) {
      // console.log('columnsmm', formInput)
      setTaskModel(columns);
    }

  }


  useEffect(() => {
    if (Object.keys(taskModel)?.[0]) {
      setColumns(taskModel);
    }
  }, [
    taskModel
  ]);

  if (!formInput?.topic) {
    return null
  }


  return (
    <div>
      <Grid
        container
        spacing={6}
        style={{
          // backgroundColor: "red",
        }}
      >
        {columns && Object.entries(columns)?.[0] && !!documentReady && (
          <DragDropContext
            onDragEnd={(result) => onDragEnd({
              result,
              columns,
              setColumns,
              resource,
              myInfo,
              eventId,
              host
            })}
          >
            {Object.entries(columns)?.map(([columnId, column]) => (
              <Lane
                {...props}
                key={columnId}
                column={column}
                eventId={eventId}
                host={host}
                formInput={formInput}
                setFormInput={setFormInput}
                setTaskModel={setTaskModel}
                taskModel={taskModel}
                setRefreshing={setRefreshing}
              >
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          minHeight: 50,

                          // backgroundColor: "green",
                        }}
                      >
                        {column?.items?.map((item, index) => {
                          // console.log('newInfoxxxx', item)

                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Task
                                      {...props}
                                      item={item}
                                      column={column}
                                      setTaskModel={setTaskModel}
                                      taskModel={taskModel}
                                      setRefreshing={setRefreshing}
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </Lane>
            ))}
          </DragDropContext>
        )}
      </Grid>
    </div>
  );
}

export default KanbanTask;
