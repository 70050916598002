import React, {
  useMemo,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import moment from "moment";
import SearchForm from "./SearchForm";

import {
  Link,
  useSearchParams,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";

import {
  getEngagementRecord,
  getEngagementAndPrivacy,
  saveBlockedGuest,
  getBlockedRecord,
  getDirectMessage,
  saveDirectMessageDB,
  deleteDirectMessageDB,
  getSearch,
  databaseSaveEngagementAccept,
  databaseSaveBlockedUser,
  getBlockedUser,
} from "../apiComment";
import { groupBy, removeDuplicates, removeDup } from "../helpers/Utils";
import {
  Button,
  Card,
  Col,
  Dropdown,
  ListGroup,
  ListGroupItem,
  Modal,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DirectMessageComponent from "./DirectMessageComponent";
import DMInputField from "./DMInputField";
import LoaderComponent from "./LoaderComponent";

import {
  getBillSupportVoteMatches,
  getMyVote,
  getContent,
  getChats,
} from "../apiComment";
// import { Container, Content } from './styles';
import {
  ArrowBackIos,
  ArrowLeft,
  AssistWalkerTwoTone,
  Close,
  HighlightOff,
  Home,
  MoreVert,
  Search,
  X,
} from "@mui/icons-material";
import useLocalStorage from "use-local-storage";
import { useAsyncMemo } from "use-async-memo";

import { UserContext } from "../App";
import { getCalendarUsers } from "src/api";
import styled from "@emotion/styled";
import { ButtonL } from "./AppIndex";

const SupportersList = (props: any) => {
  const { setOpenChat } = props;
  let navigate = useNavigate();
  const handle = useParams();
  const {
    feed,
    policy,
    bill,
    member,
    meeting,
    news,
    share,
    influencer,
    comment,
    engagement,
    voter,
  } = handle;
  const [showTool, setShowTool] = useState<any>(false);
  // const [engagedVoter, setEngagedVoter] = useState<any>([])
  const [allDirectMessages, setAllDirectMessages] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [influencedVoter, setInfluencedVoter] = useState<any>({});
  const [menuOption, setMenuOption] = useState<any>("engaged");
  const [selectInfluencer, setSelectInfluencer] = useState<any>(-1);
  // const [selectEngagement, setSelectEngagement] = useState<any>(-1)
  // const [directMsgGuest, setDirectMsgGuest] = useState<any>(false)
  const [engagementStatus, setEngagementStatus] = useState<any>("myEng");

  // const [myMessage, setMyMessage] = useState<any>(false)
  const [mySearch, setMySearch] = useState<any>(false);
  const [openSearchField, setOpenSearchField] = useState<any>(false);

  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const { matches, myInfo = {}, urlParam } = localStore;
  const [filteredDataSet, setFilteredDataSet] = useState<any>(false);
  const contextData: any = useContext(UserContext);
  const {
    myContext: {
      hostId,
      selectAvatar = {},
      directMsgGuest = {},
      chatInfo: chatInfoTemp,
      myGuestEngagement = {},
      allEngagementVotes = {},
      openChat,
      activeChatUser,
    },
    setMyContext,
  } = contextData;

  const fullWidth = window.screen.width;
  const fullHeight = window.screen.height;

  const {
    selectedHanle,
    main,
    showContacts,
    // myDirectMessages = [],
    // setDirectMsgGuest = () => { },
  } = props;

  const chatInfo = main ? showContacts : chatInfoTemp;

  useEffect(() => {
    if (!selectedHanle) {
      return;
    }
    getMyVote({
      host: selectedHanle,
    }).then((myvoteInfoB) => {
      if (myvoteInfoB?.length > 0) {
        const info = myvoteInfoB?.map((rex: any) => {
          return {
            ...rex,
            name: rex?.fullName,
            group: "voter",
            handle: rex?.handle,
            picture: rex?.img,
            item_id: rex?.item_id,
          };
        });
        const groupBill =
          info &&
          groupBy({
            data: info,
            key: "handle",
          });
        setInfluencedVoter(groupBill);
      }
    });
  }, [selectedHanle]);

  //
  const myEngagements: any = useAsyncMemo(async () => {
    // return await getEngagementAndPrivacy({
    //   handle: myInfo?.handle
    return await getCalendarUsers({
      handleff: null,
    })?.then(async (rex: any) => {
      console.log("nnnnnn");
      // return rex?.engagement?.map((act: any) => {
      return rex?.map((act: any) => {
        // const host = act.host && JSON.parse(act.host)
        // host['host_accepted'] = act.host_accepted
        return {
          ...act,
        };
      });
    });
  }, [loading]);

  const guestEngagements: any = useAsyncMemo(async () => {
    // return await getEngagementAndPrivacy({
    //   item_id: myInfo?.handle
    return await getCalendarUsers({
      handleff: null,
    })?.then(async (rex: any) => {
      // return rex?.engagement?.map((act: any) => {
      return rex?.map((act: any) => {
        // const host = act.host && JSON.parse(act.host)
        // host['host_accepted'] = act.host_accepted
        return {
          ...act,
        };
      });
    });
  }, [loading]);

  const searchHandle = () => {
    if (mySearch) {
      alert("Please add handle to search");
      return;
    }
    const formDataSenate: any = {
      request: "search",
      query: {
        // bioguide: member_id
      },
      resource: "vare_senate_votes",
      sortBy: "voted_at",
      orderBy: "desc",
      id: "",
    };

    formDataSenate["search"] = mySearch;
    formDataSenate["key"] = ["fullName", "handle"];

    getSearch(formDataSenate);
  };

  const params: any = useParams<any>();

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab, hostId, myInfo?.handle]);

  return (
    <>
      <Close
        onClick={() => {
          setMyContext((existingItems: {}) => {
            return {
              ...existingItems,
              openChat: false,
            };
          });
        }}
      />
      <MessengerWrapper>
        <ChatModalHeaderWrapper>
          <h1>My Guest Messenger</h1>
          <button
            onClick={() => {
              if (chatInfo) {
                setMyContext((existingItems: {}) => {
                  return {
                    ...existingItems,
                    chatInfo: false,
                  };
                });

                setMyContext((existingItems: {}) => {
                  return {
                    ...existingItems,
                    directMsgGuest: voter,
                  };
                });
                setEngagementStatus("myEng");
              } else {
                setMyContext((existingItems: {}) => {
                  return {
                    ...existingItems,
                    openChat: false,
                  };
                });
              }
              //
              console.log(openChat, activeChatUser);
            }}
          >
            <ArrowBackIos />
            Back
          </button>
        </ChatModalHeaderWrapper>

        <div>
          {directMsgGuest && Object.keys(directMsgGuest)?.length > 0 && (
            <ChatHeaderWrapper>
              <UserInfoWrapper>
                <ChatInfoImgWrapper>
                  <img
                    alt=""
                    src={
                      directMsgGuest?.img ||
                      `https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg`
                    }
                  />
                </ChatInfoImgWrapper>
                <UserChatInfoWrapper>
                  <h6>{directMsgGuest?.name}</h6>
                  <p>Last seen: 2 minutes ago</p>
                </UserChatInfoWrapper>
              </UserInfoWrapper>

              <ButtonL
                style={{
                  margin: 0,
                  fontSize: 10,
                  background: "red",
                }}
              // onClick={() => {
              //   blockUserMessage({
              //     item_id: messenger?.handle,
              //     handle: myInfo?.handle,
              //     block: !messenger?.block,
              //   });
              // }}
              >
                Block
              </ButtonL>
            </ChatHeaderWrapper>
          )}

          {chatInfo ? (
            <ListMesages
              directMsgGuest={directMsgGuest}
              loading={loading}
              setLoading={setLoading}
            />
          ) : engagementStatus == "myEng" ? (
            <ListEngagements
              loading={loading}
              setLoading={setLoading}
              engagedVoter={myEngagements}
              myEng={true}
              directMsgGuest={directMsgGuest}
            />
          ) : engagementStatus == "guestEng" ? (
            <ListEngagements
              loading={loading}
              setLoading={setLoading}
              engagedVoter={guestEngagements}
              directMsgGuest={directMsgGuest}
            />
          ) : null}
        </div>
      </MessengerWrapper>
    </>
  );
};

export const ListMesages = ({
  directMsgGuest,
  loading = false,
  setLoading,
}: any) => {
  const [directMessages, setDirectMessages] = useState<any>([]);
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const contextData: any = useContext(UserContext);
  const {
    myContext: {
      myInfo,
      myBlockedUsers,
      chatInfo,
      myGuestEngagement = {},
      allEngagementVotes = {},
    },
    setMyContext,
  } = contextData;

  const blockUserMessage = (info: any) => {
    databaseSaveBlockedUser(info).then((res: any) => {
      alert("User is now blocked");
      getBlockedUser({
        handle: myInfo?.handle,
      }).then((info: any) => {
        // console.log('xxxxblockedusers', info)
        setMyContext((existingItems: {}) => {
          return {
            ...existingItems,
            myBlockedUsers: info,
          };
        });
      });
    });
  };

  const deletDirectMessage = (id: any) => {
    deleteDirectMessageDB(id)?.then((res: any) => {
      const msgs = directMessages?.filter((del: any) => {
        return del?._id != id;
      });
      setDirectMessages((existing: any) => {
        return removeDuplicates([
          // ...existing,
          ...msgs,
        ]);
      });
    });

    // setMsg([...msg?.filter((del: any) => del._id == id) || []])
  };

  const myDirectMessages = useAsyncMemo(async () => {
    return await getDirectMessage({
      handle: myInfo?.handle,
    })
      .then((info: any) => {
        return info && Array.isArray(info) && info?.length > 0 ? info : [];
      })
      .catch((err: any) => {
        return [];
      });
  }, []);

  const groupedDirectMessages = useMemo(() => {
    setDirectMessages((existing: any) => {
      return removeDup(myDirectMessages);
    });
    return (
      myDirectMessages &&
      groupBy({
        data: myDirectMessages,
        key: "handle",
      })
    );
  }, [myDirectMessages]);

  console.log("directMsgGuest", groupedDirectMessages);

  return (
    <>
      {directMsgGuest?.handle ? (
        <DirectMessageComponent
          deletDirectMessage={deletDirectMessage}
          directMessages={directMessages
            ?.filter((res: any) => {
              return (
                res.handle == directMsgGuest?.handle ||
                res.item_id == directMsgGuest?.handle
              );
            })
            ?.sort((left: any, right: any) => {
              return moment.utc(left.date).diff(moment.utc(right.date));
            })}
          directMsgGuest={directMsgGuest}
        />
      ) : groupedDirectMessages &&
        Object.keys(groupedDirectMessages)?.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 300,
            overflowY: "scroll",
          }}
        >
          <div className="main-contacts-list" id="mainContactList">
            {groupedDirectMessages &&
              Object.keys(groupedDirectMessages)?.length > 0 ? (
              Object.keys(groupedDirectMessages)?.map(
                (item: any, i: number) => {
                  const messages = groupedDirectMessages[item];
                  const messenger =
                    messages &&
                    groupedDirectMessages[item][0] &&
                    groupedDirectMessages[item][0];
                  // ?.host
                  // && JSON.parse(groupedDirectMessages[item][0]?.host)

                  if (messenger?.handle == myInfo?.handle) {
                    return null;
                  }

                  return (
                    <div
                      key={i + "ggfgvg"}
                      style={{
                        width: "100%",
                        backgroundColor: "red",
                      }}
                    >
                      <div className="main-contact-item">
                        <div className="main-img-user online">
                          {messenger?.img ? (
                            <div className="main-img-user1 avatar-sm">
                              <img
                                alt="avatar"
                                className="rounded-circle"
                                src={messenger?.img}
                              />
                              <span className="badge rounded-pill bg-success number-badge">
                                {messages?.length || 0}
                              </span>
                            </div>
                          ) : (
                            <div className="main-img-user1 avatar-sm">
                              <img
                                alt="avatar"
                                className="rounded-circle"
                                src={`https://ui-avatars.com/api/name=${messenger?.name || "A"
                                  }&background=random`}
                              />
                              <span className="badge rounded-pill bg-success number-badge">
                                {messages?.length || 0}
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          className="main-contact-body"
                          onClick={() => {
                            // setSelectEngagement(selectEngagement == voter?.handle ? -1 : voter?.handle)
                            setMyContext((existingItems: {}) => {
                              return {
                                ...existingItems,
                                directMsgGuest: messenger,
                              };
                            });
                            // setDirectMsgGuest({
                            //   ...messenger,
                            // })
                          }}
                        >
                          <h6>{messenger?.name}</h6>
                          <span>{messenger?.handle}</span>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <LoaderComponent
                message={
                  "Content not available at this time.  Please start voting, engaging and Influencing to add to your list.."
                }
              />
            )}
          </div>
        </div>
      ) : (
        <LoaderComponent
          message={
            "Content available at this time.  Please start voting, engaging and Influencing to add to your list.."
          }
        />
      )}
      {directMsgGuest &&
        Object.keys(directMsgGuest) &&
        Object.keys(directMsgGuest)?.length > 0 && (
          <DMInputField
            loading={loading}
            setLoading={setLoading}
            directMsgGuest={directMsgGuest}
            setDirectMessages={setDirectMessages}
          // selectedHanle
          />
        )}
    </>
  );
};

const ListEngagements = ({
  loading,
  setLoading,
  engagedVoter = [],
  myEng,
}: any) => {
  let navigate = useNavigate();
  const handle = useParams();
  const {
    feed,
    policy,
    bill,
    member,
    meeting,
    news,
    share,
    influencer,
    comment,
    engagement,
    voter,
  } = handle;
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const [selectEngagement, setSelectEngagement] = useState<any>(-1);

  const { matches, myInfo = {}, urlParam } = localStore;
  const contextData: any = useContext(UserContext);
  const {
    myContext: {
      myBlockedUsers,
      chatInfo,
      myGuestEngagement = {},
      allEngagementVotes = {},
    },
    setMyContext,
  } = contextData;

  const allBlockedUsers = useAsyncMemo(async () => {
    // console.log('myBlockedUsersss', myBlockedUsers)
    return myBlockedUsers
      ?.filter((res: any) => {
        return res?.block;
      })
      ?.map((res: any) => {
        return res?.item_id;
      });
  }, [myBlockedUsers]);

  const updateEngagementState = async (record: any) => {
    //TODO
    setLoading(true);
    record["myInfo"] = myInfo;
    databaseSaveEngagementAccept(record).then((rep: any) => {
      // const result = rep?.data[0]
      setLoading(false);
    });
  };
  const [searchKeyword, setSearchKeyword] = useState("");

  return (
    <>
      <SearchWrapperChat>
        <input
          type="text"
          placeholder="Search..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
        <Search />
      </SearchWrapperChat>

      <div>
        {engagedVoter &&
          engagedVoter?.length > 0 &&
          engagedVoter
            ?.filter((user: any) =>
              user?.name?.toLowerCase().includes(searchKeyword.toLowerCase())
            )
            .map((voter: any, i: number) => {
              const allRelatedVoters =
                voter?.handle &&
                allEngagementVotes &&
                allEngagementVotes[voter?.handle]?.filter((rex: any) => {
                  return (
                    rex?.host == myInfo?.handle || rex?.share == myInfo?.handle
                  );
                });
              return (
                <UserChatWrapper
                  key={i + "ggfgvg"}
                  onClick={() => {
                    setMyContext((existingItems: {}) => {
                      return {
                        ...existingItems,
                        chatInfo: true,
                      };
                    });

                    setMyContext((existingItems: {}) => {
                      return {
                        ...existingItems,
                        directMsgGuest: voter,
                      };
                    });
                  }}
                >
                  {/* User Img */}

                  <UserInfoWrapper>
                    <div>
                      {voter?.img ? (
                        <ChatInfoImgWrapper>
                          <img alt="avatar" src={voter?.img} />
                          {/* Need to add a logic to determine the user is logged in or not */}
                          {true && <div />}
                        </ChatInfoImgWrapper>
                      ) : (
                        <ChatInfoImgWrapper>
                          <img
                            alt="av"
                            src={`https://ui-avatars.com/api/name=${voter?.name || "O"
                              }&background=random`}
                          />
                          {/* Need to add a logic to determine the user is logged in or not */}
                          {true && <div />}
                        </ChatInfoImgWrapper>
                      )}
                    </div>
                    <UserChatInfoWrapper>
                      <h6>{voter?.name || "Anonymous users"} </h6>
                      <p>{voter?.handle}</p>
                    </UserChatInfoWrapper>
                  </UserInfoWrapper>
                  <UserMessageCount>
                    {allRelatedVoters?.length || 0}
                  </UserMessageCount>
                </UserChatWrapper>
              );
            })}
      </div>
    </>
  );
};

export default SupportersList;
const MessengerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const ChatModalHeaderWrapper = styled.div`
  display: flex;
  color: white;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background: #96c7b8;
  h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: gray;
    margin: 0;
    transition: color 0.3s;
    &:hover {
      color: black;
      svg {
        transform: translateX(-5px);
      }
    }
    svg {
      height: 100%;
      width: 15px;
      transition: transform 0.3s;
    }
  }
`;

const ChatInfoImgWrapper = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;

  img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    background: #20c997;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    right: 2px;
  }
`;

const UserChatInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h6 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: gray;
    margin: 0;
  }
`;
const UserChatWrapper = styled.div`
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  transition: background 0.4s;
  cursor: pointer;
  &:hover {
    background: #d8e8dc;
  }
`;
const ChatHeaderWrapper = styled.div`
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const UserMessageCount = styled.div`
  background: #479d83;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SearchWrapperChat = styled.div`
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin-bottom: 10px;
  input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
  }
  svg {
    width: 22px;
    height: 100%;
    object-fit: cover;
    color: gray;
  }
`;
