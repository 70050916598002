import Carousel from "react-spring-3d-carousel";
import { useState, useEffect, useMemo, useContext, useRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { config } from "react-spring";
import { useSpring, animated } from "react-spring";
// import './CarrousselCard.css'
// import Styles from "./CarrousselStyle.css";
import { v4 as uuidv4 } from "uuid";
import { Progress } from "reactstrap";
import {
  getCalendarUsers,
  getDataDB,
  getGuestLikesDB,
  handleReaction,
  handleSaveLikes,
  myColors,
  saveDataDB,
} from "src/api";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";
import styled from "@emotion/styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { useAsyncMemo } from "use-async-memo";
import {
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { socket } from "./socket";
import moment from "moment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Select from "react-select";
import { capitalizeFirstLetter, groupByAsync } from "src/helpers/Utils";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { BasicMutipleSelect } from "./MediaSlider/BasicMutipleSelect";
import skills_industry from "src/assets/data/skills_industry";
import { MultiSelect } from "react-multi-select-component";
import {
  CheckBox,
  Delete,
  Description,
  RemoveCircleOutline,
  Replay,
  ReplayRounded,
  ReplyRounded,
  Send,
  ShoppingBag,
} from "@mui/icons-material";
import { colors } from "./MyLinks";
import { ButtonL } from "./AppIndex";

const _ = require("lodash");

export const UserStoryComments = (props: any) => {
  const location = useLocation();

  const {
    openModal,
    // commentInfo = {},
    setOpenModal,
    // setCommentInfo,
    selectAvatar,
    setSelectAvatar,
    // msgInfo = [],
    openBill = false,
    setSupporters,
    supporters,
    editInfo,
  } = props;
  const contextData: any = useContext(UserContext);
  const fullWidth = window.screen.width;
  const fullHeight = window.screen.height;

  const handle = useParams();
  const urlParam = handle;
  let navigate = useNavigate();
  const {
    feed,
    policy,
    bill,
    member,
    share,
    influencer,
    comment: commentUrl,
  } = handle;
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const { myInfo = {} } = localStore;
  const [replyContact, setReplyContact] = useState<any>(false);
  const [comment, setComment] = useState<any>("jjjj");
  const [saveComment, setSaveComment] = useState<boolean>(false);

  const [joinRoom, setJoinRoom] = useState<any>(false);
  const { feedsById } = localStore;
  const {
    myContext,
    myContext: { msgInfo = {}, myBlockedUsers, isApp, eventId, host, hostTabs },
    setMyContext,
  } = contextData;
  const [openNews, setOpenNews] = useState<any>(false);
  const [showMoreComments, setShowMoreComments] = useState<boolean>(false);
  const [seeBlockedUsers, setSeeBlockedUsers] = useState<boolean>(false);
  const [commentInfo, setCommentInfo] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>({});

  const params: any = useParams<any>();

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const model: any = useMemo(() => {
    return hostTabs?.filter((ref: any) => ref.name == eventTab)?.[0]?.model;
  }, [eventTab, hostTabs]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const commentsTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.commentsTab]);

  const resource = useMemo(() => {
    return `calendar_comments`;
  }, []);

  // console.log("hostTabsxxc22c", resource);

  useEffect(() => {
    // console.log("hostTabsxxc22c");

    const data = {
      query: {
        ...(eventTab ? { eventId: eventId } : { handle: host?.handle }),
      },
      resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
      check: ["handle"],
    };
    host?.handle &&
      getDataDB(data).then((res) => {
        console.log("hostTabsxxcc44", res);

        setDataRecord(res?.[0] ? res?.[0] : []);
      });
  }, [
    host?.handle,
    eventTab,
    myInfo?.handle,
    params.eventId,
    params.hostId,
    resource,
  ]);

  useEffect(() => {
    const info: any = {
      ...dataRecord,
      picture:
        dataRecord?.image ||
        dataRecord?.img ||
        "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg",
    };

    if (!info?.description && info?.title) {
      info["description"] = info?.title;
    }
    const infoV = {
      ...info,
      item_id: info?._id,
    };
    // console.log("cccv", infoV);

    setCommentInfo(infoV);
  }, [dataRecord]);

  // console.log("cccv", location?.pathname);

  const [myLike, setMyLike] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  useEffect(() => {
    // console.log("Guest info in the card", item);
    getGuestLikesDB({
      handle: myInfo?.handle,
      itemId: commentInfo?.handle,
    }).then((res) => {
      // console.log('myLike', myLike)
      setMyLike(res?.[0]?.like ? true : false);
    });
  }, [commentInfo, refreshing]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        border: "2px solid red",
      }}
    >
      <CommentsComponent
        urlParam={urlParam}
        comment={comment}
        setComment={setComment}
        saveComment={saveComment}
        setSaveComment={setSaveComment}
        commentInfo={commentInfo}
        setReplyContact={setReplyContact}
        replyContact={replyContact}
      />
    </div>
  );
};

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const IteraterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  span {
    padding: 4px 10px;
    background: white;
    border-radius: 40px;
    border: 1px solid #e4e7eb;
    color: #212121;
  }
`;

const CommentsComponent = (props: any) => {
  const {
    // urlParam,
    commentInfo = {},
    setReplyContact,
    replyContact,
    comment,
    setComment,
    setSaveComment,
    saveComment,
    allBlockedUsers,
  } = props;
  const contextData: any = useContext(UserContext);
  const { myContext, setMyContext } = contextData;
  const params: any = useParams<any>();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const contentId: any = eventId;
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const [msg, setMsg] = useState<any>([]);
  const [showAll, setShowAll] = useState<boolean>(true);

  // console.log('props', props)
  //socket-io
  const [isConnected, setIsConnected] = useState<any>(socket.connected);
  const [fooEvents, setFooEvents] = useState<any>([]);
  const [createEvents, setCreateEvents] = useState<any>([]);
  const [updateEvents, setUpdateEvents] = useState<any>([]);
  const [deleteEvents, setDeleteEvents] = useState<any>([]);
  const [readEvents, setReadEvents] = useState<any>([]);
  const [showMoreComments, setShowMoreComments] = useState<boolean>(false);
  const [seeBlockedUsers, setSeeBlockedUsers] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<any>([]);
  const [commentText, setCommentText] = useState("");

  const [replyMsg, setReplyMsg] = useState<any>(false);
  // const [myInfo, setMyInfo] = useState<any>({ });
  const { allMsg = {} } = myContext;
  const { myInfo = {}, myInfo: myInfoSource } = localStore;

  const socketRef = useRef<any>();
  const textChatInput = useRef<any>();

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const resource = useMemo(() => {
    return `calendar_comments`;
  }, []);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    socketRef.current = socket;
    socketRef.current.on("connect", onConnect);
    console.log("commentInfoxxxx", eventId);

    socketRef.current.emit("calendar_comment read", [{ content_id: eventId }]);
    socketRef.current.on("calendar_comment read", (msgs: any) => {
      setMsg(msgs);
      console.log("chatMessages", msgs);
    });

    return () => {
      socketRef.current.off("connect", onConnect);
      socketRef.current.off("disconnect", onDisconnect);
    };
  }, [eventId, eventSubTab]);

  // useEffect(() => {
  //   setMyContext((existingItems: {}) => {
  //     return {
  //       ...existingItems,
  //       msgInfo: {
  //         messages: msg,
  //         commentInfo
  //       }
  //     }
  //   })
  // }, [
  //   msg,
  //   commentInfo]);

  useEffect(() => {
    let controller = new AbortController();
    if (comment && saveComment) {
      // console.log('save comment xxx')

      const dbVote = {
        content_id: eventId,
        comment_id: eventId,
        reply: replyContact?.comment_id ? true : false,
        date: moment().format(),
        comment: comment,
        createdAt: moment().format(),
        img: myInfo?.img,
        name: myInfo?.name,
        text: comment,
        user_id: myInfo?.user_id,
        handle: myInfo?.handle,
        sex: myInfo.age,
        age: myInfo.age,
        race: myInfo.race,
        party: myInfo.party,
        influencer_id: myInfo.influencer_id,
        source: commentInfo.source,
        sourceCaller: commentInfo.sourceCaller,
      };
      console.log("dbVote", dbVote);

      socketRef.current.emit("calendar_comment save", { ...dbVote });

      setSaveComment(false);
    }
  }, [saveComment]);

  const deletComment = (id: any) => {
    socketRef.current.emit("calendar_comment delete", {
      _id: id,
      content_id: contentId,
    });
    // deleteCommentDB(id)
    setMsg(
      msg
        ?.filter((del: any) => del._id != id)
        ?.sort((a: any, b: any) => {
          var dateA = new Date(a.date).getTime();
          var dateB = new Date(b.date).getTime();
          return dateA > dateB ? -1 : 1;
        })
    );
  };
  if (!(msg && msg?.length > 0)) {
    return null;
  }

  console.log("msgssss", msg);

  return (
    <>
      <div>
        {/* <h2>{myInfo?.name}</h2> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderRadius: 20,
            backgroundColor: "gray",
            height: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={myInfo.img}
            style={{
              padding: 5,
              height: 40,
              width: 40,
              borderRadius: "100%",
            }}
          />
          <CommentTextArea
            placeholder="Whats on you mind?"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
          <Send
            style={{
              color: myColors?.darkGreen,
            }}
          />
        </div>
        <div
          style={{
            paddingTop: 5,
            marginBottom: -15,
            display: "flex",
            flexDirection: "row",
            width: "100%",
            // backgroundColor: 'red',
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {allBlockedUsers?.length > 0 && (
            <i
              className={`fa fa-x fa-user-slash ${
                seeBlockedUsers ? "text-success" : ""
              }`}
              onClick={() => {
                setSeeBlockedUsers(!seeBlockedUsers);
              }}
              style={{
                paddingLeft: 70,
                // backgroundColor: seeBlockedUsers ? 'red' : '',
              }}
            >
              {!seeBlockedUsers ? " See Blocked Users" : " See Messages"}
            </i>
          )}
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 20,
          }}
        >
          <InfiniteScroll
            dataLength={msg?.length || 0} //This is important field to render the next data
            next={() => {}}
            hasMore={true}
            loader={<h6></h6>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            // below props only if you need pull down functionality
            refreshFunction={() => {}}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8595; Pull down to refresh
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            <div>
              {msg &&
                msg?.length > 0 &&
                msg?.map((res: any, i: number) => {
                  // vare_blocked_users  //todo
                  return (
                    <div
                      key={i + "msg"}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <CommentMessage
                        {...res}
                        setDeleted={setDeleted}
                        deletComment={deletComment}
                        // replyMsgAction={replyMsgAction}
                        userId={res?.userId || "na"}
                        guestHandle={res.handle}
                        comId={res.comId}
                        fullName={res.fullName}
                        userProfile={res.userProfile}
                        text={res.text}
                        avatarUrl={res.img}
                        replyCount={res?.replies?.length}
                        index={i}
                      />
                      {res?.replies?.map((reply: any) => {
                        return (
                          <div key={i + "reply"}>
                            <CommentMessage
                              {...res}
                              index={i}
                              setDeleted={setDeleted}
                              deletComment={deletComment}
                              userId={res?.userId || "na"}
                              guestHandle={res.handle}
                              comId={reply.comId}
                              fullName={reply.fullName}
                              userProfile={reply.userProfile}
                              text={reply.text}
                              avatarUrl={reply.img}
                              replyCount={res?.replies?.length}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

const CommentMessage = (props: any) => {
  const {
    replyCount,
    replyMsgAction,
    // userId = "",
    comId,
    fullName,
    userProfile,
    text,
    avatarUrl,
    user_id = "",
    deletComment,
    deleted,
    setDeleted,
    guestHandle,
  } = props;
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const { myInfo = {} } = localStore;
  const handle = useParams();
  const { feed, policy } = handle;

  const userId = myInfo?.userr_id;

  // const blockUserMessage = (info: any) => {
  //   if (guestHandle) {
  //     databaseSaveBlockedUser(info).then((res: any) => {
  //       alert('User is now blocked')
  //       getBlockedUser({
  //         handle: myInfo?.handle
  //       }).then((info: any) => {
  //         // console.log('xxxxblockedusers', info)
  //         setMyContext((existingItems: {}) => {
  //           return {
  //             ...existingItems,
  //             myBlockedUsers: info
  //           }
  //         })
  //       })
  //     })
  //   }
  // }; TODO

  return (
    <div style={{ marginBottom: 20 }}>
      <div>
        <CommentHead onClick={() => {}}>
          {avatarUrl ? (
            <img alt="avatar" src={avatarUrl} />
          ) : (
            <img
              alt="avatar"
              className="rounded-circle"
              src={`https://ui-avatars.com/api/name=${
                fullName || "O"
              }&background=random`}
            />
          )}
          <h2>{fullName || "Anonymous User"}</h2>
        </CommentHead>
        <CommentActions style={{ border: "2px solid red" }}>
          <ReplyRounded
            onClick={() => {
              replyMsgAction(props);
            }}
            data-tooltip-id={`my-comment-${props.index}-tooltip`}
          />
          <ReactTooltip
            id={`my-comment-${props.index}-tooltip`}
            place="bottom"
            style={{ border: "1px solid gray" }}
            variant="dark"
            content="Reply"
          />
          {myInfo?.handle == guestHandle && (
            <>
              <Delete
                onClick={() => {
                  deletComment(props?._id);
                }}
                data-tooltip-id={`my-delete-${props.index}-tooltip`}
              />
              <ReactTooltip
                id={`my-delete-${props.index}-tooltip`}
                place="bottom"
                style={{ border: "1px solid gray" }}
                variant="light"
                content="Reply"
              />
            </>
          )}
        </CommentActions>
      </div>
      <CommentText>{text}</CommentText>
    </div>
  );
};

const CardWrapperParent = styled.div<{ isSmall: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isSmall }) => (isSmall ? "5px" : " 10px")};
  font-family: "Poppins", sans-serif;
  h1,
  div {
    margin: 0;
  }
`;
const ImgContainer = styled.div<{ isSmall: boolean }>`
  position: relative;
  height: ${({ isSmall }) => (isSmall ? "200px" : " 290px")};
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
const RR = styled(RWebShare)`
  border: 12px solid green;
`;

const CardHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "16px" : "22px")};
  font-style: normal;
  font-weight: 600;
`;
const CardSubHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;
const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
const AddCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid #479d83;
  border-radius: 5px;
`;
const CommentsTextWrapper = styled(AddCommentWrapper)`
  width: "100%";
  border: none;
  padding: 0;
`;
const CommentHead = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  h2 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
`;
const CommentTextArea = styled.textarea`
  font-size: 16px;
  color: black;
  padding: 10px 0;
  border: none;
  outline: none;
  resize: none;
  height: 100%;
  width: 100%;
`;
const CommentText = styled.p`
  font-size: 16px;
  padding: 0px 0;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
const CommentHeadParent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
const CommentActions = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  svg {
    color: #479d83;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
