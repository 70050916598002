import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select/creatable";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  apiFetchData,
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getDataWildDB,
  getGuestLikesDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  groupByNow,
  handleAIQuerySearch,
  handleAISearch,
  handleSaveLikes,
  jumpToReleventDiv,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";

import {
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Add,
  AttachFile,
  Attachment,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  Edit,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterAlt,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  UploadFile,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import { MyFileUploader } from "./MyFileUploader";
import { MyAllFileTypeUploader } from "./MyAllFileTypeUploader";
import { MyVideoUploader } from "./MyVideoUploader";
import { ImportDataComponent } from "./ImportDataComponent";
import { BiPlusCircle } from "react-icons/bi";
import { PptViewer } from "./PptViewer";
import CarrousselListCard from "../CarrousselListCard";
import { ListCard } from "../ListCard";
import { PlannerFunnelModal } from "./PlannerFunnelModal";
import { v4 as uuidv4 } from "uuid";

// import { faker } from "@faker-js/faker";
// import { IconButton } from 'material-ui';
const _ = require("lodash");

export const WebsiteListItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, type, resource, item, query } = props;
  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({
  });
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [allDataRecord, setAllDataRecord] = useState<any>([]);

  const {
    myInfo,
    host,
    hostTabs,
    allAccess,
    funnel = {},
    funnelItem = {},
  } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);
  const [dataTopic, setDataTopic] = useState<any>([]);

  useEffect(() => {
    if (!resource) {
      return
    }

    const data = {
      query: query || {
        ...(eventId
          ? {
            eventId: eventId,
          }
          : {}),
        handle: host?.handle,
      },
      resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
      check: ["handle"],
    };
    getDataDB(data).then(async (res) => {
      // console.log("hostTabsxxccnn", query, res);
      const apiData: any = []
      if (!res?.[0]) {
        return
      }
      await Promise.all(res?.map(async (reg: any) => {
        if (reg?.apiRoute) {
          // console.log("apiDataxxxxvvvv", reg?.apiPostType);

          return apiFetchData({
            endPoint: reg?.apiRoute,
            apiPostType: res?.apiPostType || 'GET',
            skip: 0,
            limit: 10
          }).then((reh: any) => {
            // console.log("apiDataxxxxvvvv", reh);

            return reh?.map((red: any) => {
              const temp: any = {}

              Object.keys(reg)?.map(ref => {
                if (reg[ref]) {
                  temp[ref] = reg[ref]
                }
              })
              Object.keys(red)?.map(ref => {
                if (red[ref]) {
                  temp[ref] = red[ref]
                }
              })

              apiData.push({
                ...temp,
                ...(host?.img ? { img: host?.img } : {})

              })
            })
          })
          // apiRoute
        }
      }))
      // console.log("apiDataxxxx", apiData);

      const resultData = [...(apiData || []), ...(res?.[0] ? res : [])]
      setDataRecord(resultData);
      setAllDataRecord(resultData);
      const grouped = groupByNow({
        data: resultData,
        key: "topic",
        id: 2,
      });
      const groupedInfo = grouped && Object.keys(grouped);
      // console.log("hostTabsxxccnn", groupedInfo);

      groupedInfo?.[0] && setDataTopic(groupedInfo);
      setRefreshing(false);
      // setFormInput({});
    });
  }, [
    host?.handle,
    eventId,
    eventTab,
    eventSubTab,
    myInfo?.handle,
    params.hostId,
    refreshing,
    resource
  ]);

  // useEffect(() => {
  //     setFormInput({
  //       ...formInput,
  //       topic: 'default',
  //     });
  // }, []);

  const myData = useMemo(() => {
    return [
      ...(formInput?.title
        ? [
          {
            file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            ...formInput,
          },
        ]
        : []),
      ...dataRecord
        ?.filter((res: any) => res.title != formInput?.title)
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title,
            // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          };
        }),
    ];
  }, [dataRecord]);

  useEffect(() => {
    if (myData?.[0])
      setMyContext((existing: any) => {
        return {
          ...existing,
          myStory: myData
        };
      });
  },
    [myData])

  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [typeFile, setTypeFile] = useState<any>("upload");

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    // Define allowed file types for images and videos
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

    if (
      file &&
      (allowedImageTypes.includes(file.type) ||
        allowedVideoTypes.includes(file.type)) &&
      file.size <= 2097152
    ) {
      setFile(file);
      setFormInput({
        ...formInput,
        file,
      });
      setAddType({
        ...addType,
        file,
      });
    } else {
      alert("Please upload an image or video file smaller than 2MB.");
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];

    // Define allowed file types for images and videos
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

    if (
      file &&
      (allowedImageTypes.includes(file.type) ||
        allowedVideoTypes.includes(file.type)) &&
      file.size <= 2097152
    ) {
      setFile(file);
      setFormInput({
        ...formInput,
        file,
      });
      setAddType({
        ...addType,
        file,
      });
    } else {
      alert("Please upload an image or video file smaller than 2MB.");
    }
  };

  const ChangeFile = () => {
    setFile(null);
    setFormInput({
      ...formInput,
      file: null,
    });
    setAddType({
      ...addType,
      file: null,
    });
  };

  const [selectedTopic, setSelectedTopic] = useState<any>("");

  const [options, setOptions] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [inputField, setInputField] = useState<any>("");
  const [isList, setIsList] = useState<boolean>(false);

  useEffect(
    _.debounce(() => {
      if (!(inputField?.length > 0)) {
        return;
      }
      const data = {
        key: "name",
        request: "wildsearch",
        searchVal: inputField,
        resource: `calendar_users`,
        limit: 400,
      };
      // console.log('formFieldxx44', formInput?.title)

      getDataWildDB(data).then((res: any) => {
        // console.log("formFieldxx44", res);
        if (res?.[0]) {
          const result = res?.map((person: any) => {
            return {
              // ...person,
              // active: true,
              // link: `https://www.myguestnet.com/host/${person?.handle}`,
              // img:
              //   person.img ||
              //   "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
              // title: person.name,
              // name: person.name,
              label: person.name,
              value: person?.email,
              // handle: person?.handle,
            };
          });
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle);
          setOptions([...options, ...result]);
        }
      });
    }, 500),
    [inputField]
  );

  useEffect(() => {
    if (selected?.[0]) {
      setFormInput({
        ...formInput,
        guest: selected, //todo- send emails
      });
    }
  }, [selected]);

  useEffect(() => {
    if (typeFile) {
      setFormInput({
        ...formInput,
        typeFile: typeFile, //todo- send emails
      });
    }
  }, [typeFile]);

  const uploadTypes = [
    {
      label: "Upload",
      value: "upload",
      onclick: (type: any) => {
        setTypeFile(type);
      },
    },
    {
      label: "link",
      value: "link",
      onclick: (type: any) => {
        setTypeFile(type);
      },
    },
    {
      label: "API",
      value: "api",
      onclick: (type: any) => {
        setTypeFile(type);
      },
    },
    {
      label: "AI",
      value: "ai",
      onclick: (type: any) => {
        setTypeFile(type);
      },
    },
    ,
    {
      label: "Import",
      value: "import",
      onclick: (type: any) => {
        setTypeFile(type);
      },
    },
  ];

  useEffect(() => {
    if (formInput?.typeFile) {
      setTypeFile(formInput?.typeFile)
    }
  }, [
    formInput?.typeFile
  ])



  // console.log("groupdataTopicedInfo", formInput);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
      >
        <ButtonL
          style={{
            backgroundColor: !formInput?.topic
              ? myColors?.brown
              : myColors?.green,
            marginBottom: 20,
          }}
          onClick={() => {
            setDataRecord(allDataRecord);
            setFormInput({
              // ...formInput,
              topic: "",
            });
          }}
        >
          All
        </ButtonL>

        {dataTopic?.[0] &&
          dataTopic?.map((topic: any, index: number) => {
            // const rep = allDataRecord?.filter((ref: any) => {
            //   return ref?.title == res?.title
            // })
            // console.log('vvvvdee', res)
            return (
              <ButtonL
                style={{
                  whiteSpace: "none",
                  backgroundColor:
                    formInput?.topic == topic
                      ? myColors?.brown
                      : myColors?.green,
                }}
              >
                <div
                  onClick={() => {
                    // setFormInput(res)
                    // setRecordKey(res?.title)
                    const data = [...allDataRecord]?.filter(
                      (reh) => reh.topic == topic
                    );
                    setDataRecord(data);
                    setFormInput({
                      // ...formInput,
                      topic: topic,
                    });
                  }}
                >
                  {" "}
                  {!topic || topic == "undefined" ? "Default" : topic}
                </div>
                {allAccess && topic && (
                  <RemoveCircleOutline
                    style={{
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      let text = "Sure you want to delete?";
                      const item = allDataRecord?.filter(
                        (reh: any) => reh.topic == topic
                      )?.[0];

                      if (window.confirm(text) == true) {
                        deleteDataDB({
                          id: item?._id,
                          resource: resource,
                        }).then((res) => {
                          setRefreshing(true);
                        });
                        text = "You pressed OK!";
                      } else {
                        text = "You canceled!";
                      }
                    }}
                  />
                )}
              </ButtonL>
            );
          })}
      </div>
      <div>
        {editInfo && (
          <>
            <div>
              <form
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row",
                  padding: 10,
                  margin: 10,
                  border: "1px solid gray",
                }}
              >
                {uploadTypes?.map((res: any) => {
                  return (
                    <div
                      style={{ display: "flex", gap: 10, alignItems: "center" }}
                    >
                      <input
                        onClick={() => {
                          res?.onclick(res?.value);
                        }}
                        type="radio"
                        id="view2"
                        name="view"
                        value={res?.value}
                        checked={typeFile == res?.value || !typeFile}
                      />
                      <label>{res?.label}</label>
                    </div>
                  );
                })}
              </form>
            </div>
            {typeFile != "import" ? (
              <>
                <EditSkillsModalWrapper id={"formInput"}>
                  {!formInput?.topic && (
                    <AddSkillSection>
                      <h2>
                        Create or Select Topic{" "}
                        <span style={{ color: "red" }}>(Required)</span>
                      </h2>
                      <SkillChildWrapper>
                        <Select
                          styles={{
                            control: (styles) => ({ ...styles, width: 400 }),
                          }}
                          defaultValue={
                            formInput?.topic
                              ? [
                                {
                                  label: formInput?.topic,
                                  value: formInput?.topic,
                                },
                              ]
                              : [
                                {
                                  label: "Default",
                                  value: "Default",
                                },
                              ]
                          }
                          onChange={(e: any) => {
                            console.log("personinfo2", e);
                            // setSelectedTopic(e);
                            if (e?.value)
                              setFormInput({
                                ...formInput,
                                topic: e.value,
                              });
                          }}
                          isClearable
                          options={
                            dataTopic && dataTopic?.[0] != "undefined"
                              ? dataTopic?.map((res: any) => {
                                return {
                                  label: res,
                                  value: res,
                                };
                              })
                              : []
                          }
                          placeholder="Select or Add"
                          classNamePrefix="selectform"
                        />
                      </SkillChildWrapper>
                    </AddSkillSection>
                  )}
                  {typeFile != "ai" && <AddSkillSection>
                    <h2>Add New Item Title</h2>
                    <SkillChildWrapper>
                      <InputAgenda onSubmit={(e: any) => { }}>
                        <input
                          type={"text"}
                          onChange={(e: any) =>
                            setFormInput({
                              ...formInput,
                              title: e.target?.value,
                            })
                          }
                          value={formInput?.title || ""}
                          placeholder={"Add title ..."}
                          required
                        />
                      </InputAgenda>
                    </SkillChildWrapper>
                  </AddSkillSection>}
                  {formInput?.topic && (
                    <>
                      {typeFile != "ai" && <AddSkillSection>
                        <h2>Add Description / Details (Optional)</h2>
                        <SkillChildWrapper>
                          <InputAgenda onSubmit={(e: any) => { }}>
                            <textarea
                              // type={"text"}
                              rows={5}
                              onChange={(e: any) =>
                                setFormInput({
                                  ...formInput,
                                  description: e.target?.value,
                                })
                              }
                              value={formInput?.description || ""}
                              placeholder={"Add title ..."}
                              required
                            />
                          </InputAgenda>
                        </SkillChildWrapper>
                      </AddSkillSection>}

                      {typeFile == "link" ? (
                        <PostViaLinkComponent {...props}
                          setFormInput={setFormInput}
                          formInput={formInput}
                        />
                      ) : typeFile == "upload" ? (
                        <>
                          <AddSkillSection>
                            <h2>Add Image (optional)</h2>
                            <MyFileUploader
                              {...props}
                              allowAdd={false}
                              defaultImage={formInput?.img}
                              refreshing={refreshing}
                              setRefreshing={setRefreshing}
                              setFormInput={setFormInput}
                              formInput={formInput}
                              uploadNow={uploadNow}
                              handleSaveImage={(e: any) => {
                                setUploadNow(true);
                              }}
                            />
                          </AddSkillSection>
                          <AddSkillSection>
                            <h2>Add Video (optional)</h2>
                            <MyVideoUploader
                              {...props}
                              defaultImage={formInput?.video || ""}
                              refreshing={refreshing}
                              setRefreshing={setRefreshing}
                              setFormInput={setFormInput}
                              formInput={formInput}
                              videoKey={'video'}
                              uploadNow={uploadNow}
                              handleSaveImage={(e: any) => {
                                setUploadNow(true);
                              }}
                              resource={resource}
                            />
                          </AddSkillSection>
                        </>
                      ) : typeFile == "api" ? (
                        <PostViaAPIComponent {...props}
                          setFormInput={setFormInput}
                          formInput={formInput}
                        />
                      ) : typeFile == "ai" ? (
                        <PostViaAIComponent {...props}
                          resource={resource}
                          setFormInput={setFormInput}
                          formInput={formInput}
                        />
                      ) : null}
                      <AddSkillSection>
                        <h2>Add Viewers (optional)</h2>
                        <Select
                          styles={{
                            control: (styles) => ({ ...styles, width: 400 }),
                          }}
                          onInputChange={(e: any) => {
                            // console.log('personinfobb', e)
                            setInputField(e);
                          }}
                          defaultValue={
                            formInput?.guest || selected
                              ? [...(formInput?.guest || []), ...selected]
                              : [
                                {
                                  label: "Default",
                                  value: "Default",
                                },
                              ]
                          }
                          onChange={(e: any) => {
                            console.log("personinfo", e);
                            setSelected(e);
                          }}
                          isMulti
                          isClearable
                          options={options}
                          placeholder="Select or Invite by Email"
                          classNamePrefix="selectform"
                        />
                      </AddSkillSection>
                    </>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {formInput?._id && (
                      <ButtonL
                        disabled={
                          formInput?.title
                            ? // addType?.file ||
                            // addType?.link ||
                            // formInput?.file ||
                            // formInput?.link
                            false
                            : true
                        }
                        style={{ margin: 0, marginRight: 20 }}
                        onClick={() => {
                          if (!myInfo?.handle) {
                            alert("Please login");
                            return;
                          }

                          let text = "Are you sure you wand to delete";
                          if (window.confirm(text) == true) {
                            text = "You pressed OK!";
                          } else {
                            text = "You canceled!";
                            return;
                          }
                          const data = {
                            id: formInput?._id,
                            resource: resource,
                          };
                          // console.log('formData', data)
                          deleteDataDB(data).then((res) => {
                            setUploadNow(true);
                            setMyContext((existing: any) => {
                              return {
                                ...existing,
                                editInfo: false,
                              };
                            });
                          });
                        }}
                      >
                        Delete
                      </ButtonL>
                    )}
                    {typeFile != "ai" && <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 20
                    }}>
                      <ButtonL

                        style={{
                          margin: 0,
                        }}
                        onClick={() => {
                          setMyContext((existing: any) => {
                            return {
                              ...existing,
                              editInfo: false,
                            };
                          });

                        }}
                      >
                        Close
                      </ButtonL>
                      <ButtonL

                        style={{
                          margin: 0,

                        }}
                        onClick={() => {

                          if (!formInput?.topic) {
                            alert("Please make sure to add a Topic");
                            return;
                          }
                          if (!myInfo?.handle) {
                            alert("Please login");
                            return;
                          }
                          if (!formInput?.title) {
                            alert("Title required");
                            return;
                          }
                          const data = {
                            query: {
                              ...formInput,
                              handle: myInfo?.handle,
                              eventId: eventId,
                            },
                            resource: resource,
                            check: ["handle", "title"],
                            myInfo,
                          };
                          // console.log('formData', data)
                          saveDataDB(data).then((res) => {
                            setUploadNow(true);
                            setRefreshing(true)
                            setMyContext((existing: any) => {
                              return {
                                ...existing,
                                editInfo: false,
                              };
                            });

                          });
                        }}
                      >
                        Add
                      </ButtonL>
                    </div>}
                  </div>
                </EditSkillsModalWrapper>
              </>
            ) : typeFile == "import" ? (
              <div
                style={{
                  margin: 10,
                }}
              >
                <ImportDataComponent
                  {...props}
                  resource={resource}
                  topic={formInput?.topic}
                  setFormInput={setFormInput}
                  formInput={formInput}
                />
              </div>
            ) : null}
          </>
        )}

        <div
          style={{
            paddingTop: 50,
            paddingBottom: 20,
          }}
        >
          {formInput?.title ||
            (myData?.length > 0 && (
              <form
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 50,
                  border: "1px solid gray",
                  padding: 10,
                  marginBottom: 50,
                }}
              >
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <input
                    onClick={() => {
                      setIsList(true);
                    }}
                    type="radio"
                    id="view1"
                    name="view"
                    value="list"
                    {...(formInput?.view == "list" ? { checked: true } : {})}
                  />
                  <label>List</label>
                </div>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <input
                    onClick={() => {
                      setIsList(false);
                      // setFormInput({
                      //   ...formInput,
                      //   view: "carroussel",
                      // });
                    }}
                    type="radio"
                    id="view2"
                    name="view"
                    value="card"
                  />
                  <label>Carroussel</label>
                </div>
              </form>
            ))}
          {!isList ? (
            <MediaCardGrid>
              {myData?.map((item) => {
                return (
                  <CarrousselListCard
                    {...props}
                    {...item}
                    item={item}
                    setFormInput={setFormInput}
                    formInput={formInput}
                    resource={resource}

                  />
                );
              })}
            </MediaCardGrid>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 20,
              }}
            >
              {myData?.map((item) => {
                return (
                  <div
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <ListCard
                      {...props}
                      {...item}
                      item={item}
                      setFormInput={setFormInput}
                      formInput={formInput}
                      refreshing={refreshing}
                      setRefreshing={setRefreshing}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {funnelItem?._id == item?._id && (
        <Modal
          show={Object.keys(funnelItem)?.[0] ? true : false}
          onHide={() => {
            setMyContext((existing: any) => {
              return {
                ...existing,
                funnelItem: {},
              };
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>MyGuestNet</Modal.Title>
          </Modal.Header>
          <Modal.Body> Add {item?.title || "Title"} to funnel</Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlannerFunnelModal
              item={{
                id: uuidv4(),
                title: "Google Adwords best practices",
                badges: [],
                notifications: 0,
                ...(funnelItem || {}),
              }}
            // funnelTopic={funnelItem?.topic}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export const PostViaLinkComponent = (props: any) => {
  const { setFormInput, formInput, setInputField } = props;

  return (
    <>
      <AddSkillSection>
        <h2>Add Link (Optional)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <input
              type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  link: e.target?.value,
                })
              }
              value={formInput?.link || ""}
              placeholder={"Add Link ..."}
              required
            />
          </InputAgenda>
        </SkillChildWrapper>
      </AddSkillSection>
    </>
  );
};

const PostViaAIComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  const {
    myInfo,
  } = myContext;
  const { setFormInput, formInput, setInputField, resource } = props;
  const [myListItems, setMyListItems] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);


  const apiRequestTypes = [
    { label: "article", value: "article" },
    { label: "news", value: "news" },
    { label: "story", value: "story" },
  ];
  return (
    <>
      <AddSkillSection>
        <h2>Select AI Category (Optional)</h2>
        <Select
          styles={{
            control: (styles) => ({ ...styles, width: 400 }),
          }}
          // onInputChange={(e: any) => {
          //   // console.log('personinfobb', e)
          // }}
          defaultValue={
            formInput?.aiCategory
              ? [
                {
                  label: formInput?.aiCategory,
                  value: formInput?.aiCategory,
                },
              ]
              : [
                {
                  label: "News",
                  value: "news",
                },
              ]
          }
          onChange={(e: any) => {
            // console.log('personinfo', e)
            setFormInput({
              ...formInput,
              aiCategory: e.target?.value,
            });
          }}
          // isMulti
          isClearable
          options={apiRequestTypes}
          placeholder="Select or Invite by Email"
          classNamePrefix="selectform"
        />
      </AddSkillSection>
      <AddSkillSection>
        <h2>Add AI Request (Required)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <textarea
              rows={4}
              style={{
                width: '100%'
              }}
              // type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  aiRequest: e.target?.value,
                })
              }
              value={formInput?.aiRequest || ""}
              placeholder={"Type of paste your notes in here to generate a list of cards"}
              required
            />


          </InputAgenda>
          <div style={{
            display: 'flex',
            gap: 10, flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}>
            {myListItems?.map((res: any, index: number) => {
              return <div style={{

              }}>
                ({index + 1}) {res?.title}
              </div>
            })}
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10
            }}>
              {!myListItems?.[0]
                ? <ButtonL onClick={() => {
                  if (formInput?.aiRequest?.length < 9) {
                    alert('You must add more than 9 characters')
                    return
                  }
                  setLoading(true)
                  handleAIQuerySearch({ query: `convert the following to array of object and return the data in JSON format with the following attributes {topic: '', title:'',description:'', img:'', source:''}" ${formInput?.aiRequest}` })
                    .then((reply: any) => {
                      console.log('hhhhhhhh', reply)
                      const report: any = reply?.map((res: any) => {
                        return {
                          ...res,
                          topic: formInput?.topic || 'default'
                        }
                      })
                      setMyListItems(report)
                    })

                }}>
                  {loading ? 'loading...' : 'Add'}
                </ButtonL>
                : <>
                  <ButtonL onClick={() => {
                    setMyListItems([])
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });
                  }}>
                    Cancel
                  </ButtonL>
                  <ButtonL onClick={async () => {

                    await Promise.all(myListItems?.map(async (res: any) => {
                      const data = {
                        query: {
                          ...formInput,
                          ...res,
                          handle: myInfo?.handle,
                          eventId: eventId,
                        },
                        resource: resource,
                        check: ["handle", "title"],
                        myInfo,
                      };
                      // console.log('formData', data)
                      return await saveDataDB(data)
                    })).then((rec: any) => {
                      // console.log('donnnnnn')
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          editInfo: false,
                        };
                      });
                    })

                  }} >
                    Save All Task
                  </ButtonL>
                </>}
            </div>
          </div>
        </SkillChildWrapper>
      </AddSkillSection>
    </>
  );
};

const PostViaAPIComponent = (props: any) => {
  const { setFormInput, formInput, setInputField } = props;

  const apiRequestTypes = [
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "DELETE", value: "DELETE" },
    { label: "PATCH", value: "PATCH" },
    { label: "OPTIONS", value: "OPTIONS" },
    { label: "HEAD", value: "HEAD" },
  ];
  useEffect(() => {
    setFormInput({
      ...formInput,
      apiPostType: 'GET'
    })
  }, [
  ])

  return (
    <>
      <AddSkillSection>
        <h2>Add API Request Type (Required)</h2>
        <Select
          styles={{
            control: (styles) => ({ ...styles, width: 400 }),
          }}
          // onInputChange={(e: any) => {
          //   // console.log('personinfobb', e)
          // }}
          defaultValue={
            formInput?.apiPostType
              ? [
                {
                  label: formInput?.apiPostType,
                  value: formInput?.apiPostType,
                },
              ]
              : [
                {
                  label: "Get",
                  value: "get",
                },
              ]
          }
          onChange={(e: any) => {
            // console.log('personinfo', e)
            setFormInput({
              ...formInput,
              apiPostType: e.target?.value,
            });
          }}
          // isMulti
          isClearable
          options={apiRequestTypes}
          placeholder="Select or Invite by Email"
          classNamePrefix="selectform"
        />
      </AddSkillSection>
      <AddSkillSection>
        <h2>Add API Route(Required)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <input
              type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  apiRoute: e.target?.value,
                })
              }
              value={formInput?.apiRoute || ""}
              placeholder={"Add End Point ..."}
              required
            />
          </InputAgenda>
        </SkillChildWrapper>
      </AddSkillSection>
      <AddSkillSection>
        <h2>Add API Token (Optional)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <input
              type={"text"}
              onPaste={(e: any) => {
                setFormInput({
                  ...formInput,
                  apiKey: e.target?.value,
                })
              }}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  apiKey: e.target?.value,
                })
              }
              value={formInput?.link || ""}
              placeholder={"Add Link ..."}
              required
            />
          </InputAgenda>
        </SkillChildWrapper>
      </AddSkillSection>
      <AddSkillSection>
        <h2>Add API Query (Optional)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <input
              type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  apiQuery: e.target?.value,
                })
              }
              value={formInput?.link || ""}
              placeholder={"Add Link ..."}
              required
            />
          </InputAgenda>
        </SkillChildWrapper>
      </AddSkillSection>
    </>
  );
};


export const RR = styled(RWebShare)`
  border: 12px solid green;
`;

export const InputAgendaList = styled.form`
                display: flex;
                align - items: center;
                justify-content: space-between;
                max-width:100px;
                padding: 5px;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                background: #fff;
                input {
                  outline: none;
                border: none;
                font-size: 14px;
                width: 100%;
  }
                button {
                  border: none;
                background: transparent;
                outline: none;
                color: gray;
                transition: color 0.3s;
                svg {
                  width: 20px;
                height: 20px;
    }
                &:hover {
                  color: #dee2e6;
    }
  }
                `;

export const InputAgenda = styled.form`
                display: flex;
                align - items: center;
                justify-content: space-between;
                width: 100%;
                padding: 5px;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                background: #fff;
                input {
                  outline: none;
                border: none;
                font-size: 14px;
                width: 100%;
  }
                button {
                  border: none;
                background: transparent;
                outline: none;
                color: gray;
                transition: color 0.3s;
                svg {
                  width: 20px;
                height: 20px;
    }
                &:hover {
                  color: #dee2e6;
    }
  }
                `;

export const LinkParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

export const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
export const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
`;

export const AddSkillSection = styled.div`
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: flex-start;
                h2 {
                  text - align: start;
                font-size: 14px;
                font-weight: bold;
                margin: 0;
  }
                `;

const UploadedResume = styled.div`
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                padding: 10px 0px;
                p {
                  font - size: 12px;
                margin: 0;
  }
                div {
                  display: flex;
                align-items: center;
                gap: 5px;
  }
                svg {
                  width: 15px;
                height: 100%;
                color: #479d83;
  }
                button {
                  border: 1px solid #479d83;
                padding: 3px 8px;
                background: #fff;
                font-size: 12px;
                transition: background 0.3s;
                margin: 0;
                &:hover {
                  background: #479d832b;
    }
  }
                `;

const UploadResumeFile = styled.label`
                background: #479d832b;
                border: 1px dashed gray;
                width: 100%;
                padding: 8px;
                padding-bottom: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;
                h3 {
                  font - size: 12px;
                font-weight: bold;
                color: #000;
                margin: 0;
  }
                p {
                  font - size: 10px;
                color: gray;
                margin: 0;
  }
                svg {
                  width: 40px;
                color: #479d83;
                height: 100%;
  }
                `;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
export const MediaCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
