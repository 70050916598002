//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useLayoutEffect,
} from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
  Table,
} from "react-bootstrap";

import { gapi } from "gapi-script";
import moment from "moment";
import { daysOfGivenWeek } from "src/helpers/dateFunctions";
import { checkDateSpan, getDateRange, myColors } from "src/api";
// import Calendar from "react-calendar";
// import { dataSource, getDataDB, myColors } from "../api";
// import { daysOfGivenWeek } from "./helpers/dateFunctions";

const colors = {
  lightBlue: "#c1d8ff",
};

export const CalendarCardCreate = (props: any) => {
  const {
    setData,
    data,
    startDay = "3-7-24",
    myDates = [], //MM/DD/YY
    onClick = (e: any) => { },
    formInput = {},
    setFormInput,
    // endDay = '1-27-24'
  } = props;

  const refDate = useRef<any>();
  const [calendar, setCalendar] = useState<any>([]);
  const [myYear, setMyYear] = useState<any>();
  const [myDate, setMyDate] = useState<any>();
  const [myStart, setMyStart] = useState<any>();
  const [myEnd, setMyEnd] = useState<any>(
    moment().clone().endOf("month").endOf("week")
  );
  const [mySelectedDates, setMySelectedDates] = useState<any>(myDates);

  useEffect(() => {
    setMyStart(startDay);
  }, [startDay]);

  useEffect(() => {
    setMyStart(moment(myDate));
    setMyEnd(moment(myDate).endOf("month"));
  }, [myDate]);

  useEffect(() => {
    if (!(myStart && myEnd)) {
      return;
    }
    var calend = [];
    var index = moment(myStart)
      .clone()
      .startOf("month")
      .startOf("week")
      .clone();
    console.log("index", index);

    while (
      index.isBefore(moment(myEnd).clone().endOf("month").endOf("week"), "day")
    ) {
      calend.push(
        new Array(7).fill(0).map(function (n, i) {
          const temp = index.add(1, "day");
          return {
            number: temp.date(),
            date: temp?.format("MM/DD/YY"),
            day: index.format("dddd"),
          };
        })
      );
    }
    const month = moment(myStart)?.format("MMMM");
    const year = moment(myStart)?.year();
    setCalendar(calend);
  }, [myStart]);

  useEffect(() => {
    if (formInput?.days?.[0]) {
      const sortedDate: any = formInput?.days?.sort((a: any, b: any) =>
        moment(a).diff(moment(b))
      );
      const result = {
        ...formInput,
        start: sortedDate?.[0] && moment(sortedDate?.[0]).format("MM/DD/YY"),
        //  end: moment('09/24/24').format("MM/DD/YY"),
        // sortedDate?.length > 1
        //   ? moment(sortedDate?.[sortedDate?.length - 1]).format("MM/DD/YY")
        //   : moment(moment(sortedDate?.[0]), "MM/DD/YY")
        //     .add(`1`, "weeks")
        //     .format("MM/DD/YY"),
      };
      console.log("xxxresult", result);
      setFormInput(result);
    }
  }, [
    formInput?.days,
    formInput?.recurring,
    formInput?.end,

    // formInput
  ]);

  useEffect(() => {
    console.log("formInputccccnnnn", formInput);

    if (formInput?.dates?.[0] && !formInput?.days?.[0]) {
      // console.log('formInputccccnnnn', formInput)
      setFormInput({
        ...formInput,
        days: formInput?.dates,
      });
    }
  }, [
    formInput?.dates,

    // formInput
  ]);

  useEffect(() => {
    if (
      formInput?.recurring == "weekly" &&
      formInput?.end &&
      formInput?.start
    ) {
      const tempDates: any = [];
      const numberWeeks = moment(formInput?.end).diff(
        formInput?.start,
        "weeks"
      );
      formInput?.days?.map((date: any) => {
        if (!tempDates?.includes(date)) {
          tempDates.push(date);
          for (let i = 1; i < numberWeeks; i++) {
            const addAWeek = moment(moment(date), "MM/DD/YY").add(
              `${i}`,
              "weeks"
            );
            console.log("addAWeek", addAWeek);
            tempDates.push(moment(addAWeek).format("MM/DD/YY"));
          }
        }
      });
      const sortedDate: any = tempDates.sort((a: any, b: any) =>
        moment(a).diff(moment(b))
      );

      if (
        formInput?.recurring &&
        formInput?.end &&
        formInput?.start &&
        sortedDate?.length > 0
      ) {
        setFormInput({
          ...formInput,
          dates: sortedDate,
        });
      }
    } else if (
      formInput?.recurring == "monthly" &&
      formInput?.end &&
      formInput?.start
    ) {
      const tempDates: any = [];
      const numberWeeks = moment(formInput?.end)?.diff(
        formInput?.start,
        "months"
      );
      // const tempDates: any = []
      formInput?.days?.map((date: any) => {
        if (!tempDates?.includes(date)) {
          tempDates.push(date);
          for (let i = 1; i < numberWeeks; i++) {
            const addAWeek = moment(moment(date), "MM/DD/YY").add(
              `${i}`,
              "months"
            );
            tempDates.push(moment(addAWeek).format("MM/DD/YY"));
          }
        }
      });
      const sortedDate: any = tempDates.sort((a: any, b: any) =>
        moment(a).diff(moment(b))
      );

      if (
        formInput?.recurring &&
        formInput?.end &&
        formInput?.start &&
        sortedDate?.length > 0
      ) {
        setFormInput({
          ...formInput,
          dates: sortedDate,
        });
      }
    } else if (
      formInput?.recurring == "yearly" &&
      formInput?.end &&
      formInput?.start
    ) {
      const tempDates: any = [];
      const numberWeeks = moment(formInput?.end)?.diff(
        formInput?.start,
        "years"
      );
      // const tempDates: any = []
      formInput?.days?.map((date: any) => {
        if (!tempDates?.includes(date)) {
          tempDates.push(date);
          for (let i = 1; i < numberWeeks; i++) {
            const addAWeek = moment(moment(date), "MM/DD/YY").add(
              `${i}`,
              "years"
            );
            tempDates.push(moment(addAWeek).format("MM/DD/YY"));
          }
        }
      });
      const sortedDate: any = tempDates.sort((a: any, b: any) =>
        moment(a).diff(moment(b))
      );

      if (
        formInput?.recurring &&
        formInput?.end &&
        formInput?.start &&
        sortedDate?.length > 0
      ) {
        setFormInput({
          ...formInput,
          dates: sortedDate,
        });
      }
    } else if (formInput?.recurring == "non") {
      setFormInput({
        ...formInput,
        dates: formInput?.days,
      });
    }
  }, [formInput?.recurring, formInput?.end, formInput?.start, formInput?.days]);

  const recurringWeekOf: any = useMemo(() => {
    if (!formInput?.days?.[0]) {
      return {};
    }
    const report: any = [formInput?.days?.[0]];
    formInput?.days?.[0] &&
      daysOfGivenWeek({
        current: formInput?.days?.[0],
      }).map((data: any) => {
        const checkdup =
          formInput?.days?.[0] &&
          [...formInput?.days]?.filter(
            (ref: any) =>
              moment(ref).format("MM/DD/YY") !=
              moment(data?.date).format("MM/DD/YY")
          );
        // const reportDedupe = report?.filter((ref: any) => moment(ref).format('MM/DD/YY') == moment(data?.date).format('MM/DD/YY'))

        if (
          checkdup?.[0] &&
          !report?.includes(moment(data?.date).format("MM/DD/YY"))
        ) {
          report.push(moment(data?.date).format("MM/DD/YY"));
        }
      });

    const weekofList =
      report?.[0] &&
      formInput?.days?.[0] &&
      [...report]
        ?.map((res) => {
          return moment(res).format("MM/DD/YY");
        })
        ?.sort((a: any, b: any) => moment(a).diff(moment(b)));

    const outsideDates = formInput?.days?.filter((date: any) => {
      return !weekofList?.includes(moment(date).format("MM/DD/YY"));
    });

    return {
      weekDates: weekofList,
      otherDates: outsideDates,
    };
  }, [formInput?.days, formInput?.recurring]);

  useEffect(() => {
    if (formInput?.days?.[0] && formInput?.recurring != "non") {
      setFormInput({
        ...formInput,
        days: [],
      });
    } else if (formInput?.recurring != "non") {
      setFormInput({
        ...formInput,
        days: formInput?.days?.filter((res: any) => {
          return ![
            ...(recurringWeekOf?.otherDates?.[0]
              ? recurringWeekOf?.otherDates
              : []),
          ]
            ?.shift()
            ?.includes(moment(res).format("MM/DD/YY"));
        }),
      });
    }
  }, [
    recurringWeekOf?.outsideDates,
    formInput?.recurring,
    // formInput?.end
  ]);
  // const [scheduleInfo, setScheduleInfo] = useState<any>([]);

  useEffect(() => {
    // alert('calendar')
    // getDataDB({
    //   resource: `${dataSource}_schedule`,
    //   query: { vvvvvv: null },
    // }).then((res) => {
    //   setScheduleInfo(res);
    // });
  }, [formInput?.["utilityId"]]);

  const selectedColor = useCallback(
    (dat: any) => {
      return moment(dat?.date)?.format("MM/DD/YY") ==
        moment(formInput?.date)?.format("MM/DD/YY")
        ? "orange"
        : "rgba(0,0,0,0)";
    },
    [formInput?.date]
  );

  const [datesOnShift, setDatesOnShift] = useState<any>({});
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);
  const format = "MM/DD/YYYY";

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Shift") {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (event: any) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  console.log("isShiftPressed", isShiftPressed, datesOnShift);
  useEffect(() => {
    const allDates: any = getDateRange({
      firstDate: datesOnShift?.start,
      lastDate: datesOnShift?.end,
    });

    console.log("isShiftPressed22", allDates);

    if (
      allDates?.[0] &&
      // && datesOnShift?.start
      datesOnShift?.end &&
      (formInput?.recurring == "non" || !formInput?.recurring)
    ) {
      // alert(55)
      console.log("isShiftPressed", datesOnShift, allDates);

      const info = allDates?.map((res: any) => moment(res)?.format(format));
      setFormInput({
        ...formInput,
        days: info,
        dates: info,
      });
      // handleOnChange(allDates);
    }
  }, [datesOnShift, isShiftPressed]);

  return (
    <div
      style={{
        width: 200,
        // marginLeft: -250,
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        // padding: 20,

        // backgroundColor: myColors.red
      }}
    >
      <div
        style={{
          display: "flex",
          ...styles.table,
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <div
          onClick={() => {
            setMyStart(moment(myStart).subtract(1, "months"));
            setMyEnd(
              moment(moment(myStart).subtract(1, "months")).endOf("month")
            );
          }}
        >
          {"<"}
        </div>
        <div
          style={{
            flexDirection: "column",
            fontSize: 10,
            whiteSpace: "none",
          }}
        >
          <div
            style={{
              fontSize: 14,
              whiteSpace: "none",
            }}
          >
            {moment(myStart)?.format("MMMM") || ""}
          </div>
          <div
            style={{
              fontSize: 14,
              whiteSpace: "none",
            }}
          >
            {moment(myStart)?.format("YYYY") || ""}
          </div>
        </div>
        <div
          onClick={() => {
            setMyStart(moment(myStart).add(1, "months"));
            setMyEnd(moment(moment(myStart).add(1, "months")).endOf("month"));
          }}
        >
          {">"}
        </div>
      </div>
      <Table
        // striped
        bordered
        // hover
        style={{ ...styles.table }}
      >
        <thead>
          <tr>
            <th style={{ ...styles.th }}>M</th>
            <th style={{ ...styles.th }}>T</th>
            <th style={{ ...styles.th }}>W</th>
            <th style={{ ...styles.th }}>T</th>
            <th style={{ ...styles.th }}>F</th>
            <th style={{ ...styles.th }}>S</th>
            <th style={{ ...styles.th }}>S</th>
          </tr>
        </thead>
        <tbody>
          {calendar?.map((cal: any) => {
            return (
              <tr>
                {cal?.map((dat: any, index: number) => {
                  const startDate = formInput?.days?.[0];
                  const endDate = recurringWeekOf?.otherDates
                    ? recurringWeekOf?.otherDates?.[
                    recurringWeekOf?.otherDates?.length - 1
                    ]
                    : formInput?.days?.[formInput?.days?.length - 1];

                  // console.log('ccccct', recurringWeekOf?.otherDates, dat?.date, endDate)

                  return (
                    <td
                      key={dat?.date + index + "sdjssssjdhs"}
                      // onClick={() => {
                      //   const dall = mySelectedDates?.includes(dat?.date)
                      //     ? mySelectedDates?.filter((rat: any) => rat != dat?.date)
                      //     : [dat?.date, ...mySelectedDates]
                      //   setMySelectedDates([
                      //     ...dall
                      //   ])
                      //   onClick({ dat, mySelectedDates: dall })
                      // }}
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        // backgroundColor: moment(
                        //   moment()?.format("MM/DD/YY")
                        // ).isSame(moment(dat?.date)?.format("MM/DD/YY"))
                        //   ? myColors.red
                        //   : "white",
                      }}
                    >

                      <div
                        onClick={async () => {
                          if (formInput?.recurring == "weekly") {
                            const isSpan = checkDateSpan({
                              dateArray: [...formInput?.days, dat?.date],
                              days: 7,
                            });
                            if (isSpan) {
                              alert(
                                "Max selection reached, next select the number of weeks"
                              );
                              return;
                            }
                          } else if (formInput?.recurring == "monthly") {
                            const isSpan = checkDateSpan({
                              dateArray: formInput?.days,
                              days: 27,
                            });
                            if (isSpan) {
                              alert(
                                "Max selection reached, next select the number of months"
                              );
                              return;
                            }
                          } else if (formInput?.recurring == "yearly") {
                            const isSpan = checkDateSpan({
                              dateArray: formInput?.days,
                              days: 365,
                            });
                            if (isSpan) {
                              alert(
                                "Max selection reached, next select the number of years"
                              );
                              return;
                            }
                          }
                          setDatesOnShift({ start: dat?.date });
                          if (
                            isShiftPressed &&
                            startDate &&
                            endDate &&
                            formInput?.recurring == "non"
                          ) {
                            // console.log('myshiftDates')
                            setDatesOnShift({
                              ...datesOnShift,
                              end: dat?.date,
                            });
                          } else {
                            setDatesOnShift({ start: dat?.date });
                          }

                          console.log("formInputvvv", formInput);

                          const scheduledDates: any = [];

                          const dall = mySelectedDates?.includes(dat?.date)
                            ? mySelectedDates?.filter(
                              (rat: any) => rat != dat?.date
                            )
                            : [dat?.date, ...mySelectedDates];

                          if (
                            !recurringWeekOf?.otherDates?.[0] ||
                            formInput?.recurring == "monthly" ||
                            formInput?.recurring == "non"
                          ) {
                            onClick({ dat, mySelectedDates: dall });
                          } else {
                            alert(
                              'Please select recurring "non" to continue since you have selected dates outside a recurring range.'
                            );
                          }
                        }}
                        style={{
                          height: "100%",
                          width: "100%",
                          // backgroundColor: formInput?.days?.filter(
                          //   (res: any) =>
                          //     moment(res)?.format("MM/DD/YY") ==
                          //     moment(dat.date)?.format("MM/DD/YY")
                          // )?.[0]
                          //   ? myColors?.brown
                          //   : "rgba(0,0,0,0)",
                        }}
                      >
                        <div
                          style={{
                            borderRadius: 100,
                            backgroundColor: formInput?.days?.filter(
                              (res: any) =>
                                moment(res)?.format("MM/DD/YY") ==
                                moment(dat.date)?.format("MM/DD/YY")
                            )?.[0]
                              ? "orange"
                              : "rgba(0,0,0,0)",
                          }}
                        >
                          {dat?.number}
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {(formInput?.days?.length > 0 || formInput?.dates?.length > 0) && (
        <>
          <div
            style={{
              marginTop: 30,
              fontWeight: "bold",
            }}
          >
            Recurring Type:
          </div>
          <div
            style={{
              // marginTop: 30,
              width: 200,
            }}
          >
            <FormGroup
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 10,
                flexDirection: "column",
                width: "100%",
                margin: "20px 0px",
              }}
            >
              {["non", "weekly", "monthly", "yearly"]?.map((res) => {
                return (
                  <div
                    onClick={() => {
                      setFormInput({
                        ...formInput,
                        recurring: res,
                      });
                    }}
                    style={{
                      border: `1px solid ${myColors.red}`,
                      padding: "30px 15px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                      ...(formInput?.recurring == res
                        ? {
                          backgroundColor: myColors.red,
                          color: "white",
                        }
                        : { color: "black" }),
                    }}
                  >
                    {res}
                  </div>
                );
              })}
            </FormGroup>
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  table: {
    width: 200,
  },
  th: {
    width: 20,
  },
};
