import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { FilterList, Report } from "@mui/icons-material";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Chart,
} from "chart.js";
import Select from "react-select";
import { UserContext } from "src/App";
import { useNavigate, useParams } from "react-router-dom";
import * as xlsx from "xlsx";
import { ButtonL } from "./AppIndex";
import { InputAgenda } from "./AgendaFormComponent";
import { capitalizeFirstLetter } from "src/helpers/Utils";

// Register Chart.js components
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface DataProps {
  ques: string;
  ans: (string | number | boolean)[];
}

interface SheetConfig {
  header?: { rows: number } | undefined;
  range?: string | undefined;
  columnToKey?: { [key: string]: string } | undefined;
  includeEmptyLines?: boolean | undefined;
  sheetStubs?: boolean | undefined;
}

declare function excelToJson(
  config:
    | (({ sourceFile: string } | { source: string | Buffer }) & {
      // Either sourceFile or source should have a value
      sheets?:
      | ReadonlyArray<string | (SheetConfig & { name: string })>
      | undefined;
    } & SheetConfig) // Nested SheetConfig should be allowed // ... or just a simple config for all
    | string, // Input can also be a json-string (for cli)
  sourceFile?: string // For cli
): { [key: string]: any[] }; // Using any to provide more flexibility for downstream usages

const ImportFormReportTable = (props: any) => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<number[]>([]);
  const [modalTitle, setModalTitle] = useState("");
  const [dataSet, setDataSet] = useState<any>(dataset);
  const [formInput, setFormInput] = useState<any>({});
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    agendas,
    allMyEvents,
    // eventId,
    data = {},
    host,
    // editInfo,
    eventDetailsTabs = [],
    eventInfo = {},
    editInfo,
  } = myContext;
  const navigate = useNavigate();
  const params: any = useParams<any>();

  const [formTitle, setFormTitle] = useState<any>();
  const [eventTables, setEventTables] = useState<any>({});
  const [formType, setFormType] = useState<any>({});
  const [formIndex, setFormIndex] = useState<number>(-1);


  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);
  // console.log("eventDetailsTab", params);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const handleOpen = (data: number[], title: string) => {
    setModalData(data);
    setModalTitle(title);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const renderGraphIcon = (
    ans: (string | number | boolean)[],
    title: string
  ) => {
    const numericData = ans.filter((a) => typeof a === "number") as number[];
    const booleanData = ans.filter((a) => typeof a === "boolean") as boolean[];

    if (numericData.length > 0 || booleanData.length > 0) {
      const dataToGraph =
        numericData.length > 0
          ? numericData
          : booleanData.map((b) => (b ? 1 : 0));
      return (
        <IconButton onClick={() => handleOpen(dataToGraph, title)}>
          <Report />
        </IconButton>
      );
    }
    return null;
  };

  const getChartData = (data: number[]) => ({
    labels: Array.from({ length: data.length }, (_, i) => i + 1),
    datasets: [
      {
        label: modalTitle,
        data,
        fill: false,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const data = await e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json: any = xlsx.utils.sheet_to_json(worksheet);
        // console.log("vvvvvfjson", json);
        if (json?.[0]) {
          const info = Object.keys(json[0])?.map((key: any) => {
            return {
              ques: key,
              ans: json?.map((reg: any) => {
                return reg[key];
              }),
            };
          });
          if (info?.[0]) {
            setDataSet(info);
          }
          // console.log("vvvvvfjson33", info, dataset);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  useEffect(() => {
    // alert(editInfo)
  }, [editInfo]);



  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          width: "100%",
        }}
      >
        {Object.keys(eventTables)?.map((title: any, index: number) => {
          return (
            <ButtonL
              style={{
                backgroundColor: formTitle == title ? "red" : "",
              }}
              onClick={() => {
                // setFormTitle(parentFormFields == eventForms[title] ? '' : title);
                // setParentFormFields(parentFormFields == eventForms[title] ? {} : eventForms[title]);
                // setRefreshing(false);
                // setFormInput({});
              }}
            >
              ({index + 1}) {title}
            </ButtonL>
          );
        })}
      </div>
      {editInfo && (
        <>
          <InputAgenda
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <input
              type={"text"}
              onChange={(e: any) => {
                setFormInput({
                  ...formInput,
                  title: e.target?.value,
                });
              }}
              value={formInput?.title || ""}
              placeholder={"Add title"}
              required
              style={{
                width: "90%",
              }}
            />
          </InputAgenda>
          {formInput?.title?.length > 0 && (
            <>
              <label htmlFor="upload">Upload File</label>
              <InputAgenda>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFile}
                />
              </InputAgenda>
            </>
          )}

          {/* {dataSet && dataSet?.[0] && formInput?.title?.length > 0 && (
            <>
              <label htmlFor="upload">Upload File</label>
              <InputAgenda>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFile}
                />
              </InputAgenda>
            </>
          )} */}
        </>
      )}
      {dataSet && dataSet?.[0] && formInput?.title?.length > 0 && (
        <>
          <ButtonL
            style={{
              margin: 20,
            }}
          >
            Save
          </ButtonL>
          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  {dataSet.map((data: any, index: number) => {
                    const field = data.ques;
                    const options: any = []
                    data.ans?.map((ref: any) => {
                      if (!options?.includes(ref)) {
                        options.push({
                          value: ref,
                          label: ref
                        })
                      }
                    })
                    return (
                      <TableCell key={index} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative'
                      }}>
                        <>
                          <FilterList onClick={() => {
                            setFormIndex(formIndex == index ? -1 : index)
                          }} />
                          {formIndex == index && <div style={{
                            top: 60,
                            display: 'flex',
                            position: 'absolute',
                            width: 200
                          }}>
                            <Select
                              defaultValue={{
                                label: "Meeting",
                                value: "meeting",
                              }}
                              value={
                                formInput?.eventType
                                  ? {
                                    label: capitalizeFirstLetter(formInput?.eventType),
                                    value: formInput?.eventType,
                                  }
                                  : {
                                    label: "Meeting",
                                    value: "meeting",
                                  }
                              }
                              // value={formInput?.eventType || "meeting"}
                              onChange={(e: any) => {
                                // console.log("ccvvv", e);
                                setFormType({
                                  ...formType,
                                  [field]: e?.value,
                                });
                                setFormIndex(-1)
                              }}
                              options={[
                                {
                                  value: "text",
                                  label: "Text Field",
                                },
                                {
                                  value: "number",
                                  label: "Number Field",
                                },
                                {
                                  value: "options",
                                  label: "Options",
                                },
                                {
                                  value: "date",
                                  label: "Date",
                                },

                              ]}
                              placeholder="Event Type"
                              classNamePrefix="selectform"
                            />
                          </div>}
                          {formType?.[field] != 'options'
                            ? <Input value={field || ""} type={formType?.[field] || 'text'} />
                            : <Select
                              defaultValue={{
                                label: "Meeting",
                                value: "meeting",
                              }}
                              value={
                                formInput?.eventType
                                  ? {
                                    label: capitalizeFirstLetter(formInput?.eventType),
                                    value: formInput?.eventType,
                                  }
                                  : {
                                    label: "Meeting",
                                    value: "meeting",
                                  }
                              }
                              // value={formInput?.eventType || "meeting"}
                              onChange={(e: any) => {
                                // console.log("ccvvv", e);
                                // setFormType({
                                //   ...formType,
                                //   [field]: e?.value,
                                // });
                              }}
                              options={options}
                              placeholder="Event Type"
                              classNamePrefix="selectform"
                            />}
                        </>

                        {(typeof data.ans[0] === "number" ||
                          typeof data.ans[0] === "boolean") &&
                          renderGraphIcon(data.ans, data.ques)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 20 }, (_, i) => (
                  <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    {dataSet.map((data: any, index: number) => (
                      <TableCell
                        key={index}
                        onClick={() => console.log(typeof data.ans[i])}
                      >
                        <Input
                          value={data?.ans?.[i]?.toString() || ""}
                          type="text"
                          onChange={(e) => {
                            if (data?.ans?.[i]) data.ans[i] = e.target.value;
                            setDataSet([...data]);
                          }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <StyledModal open={open} onClose={handleClose}>
              <ModalContent>
                <Line data={getChartData(modalData)} />
              </ModalContent>
            </StyledModal>
          </StyledTableContainer>
        </>
      )}
    </>
  );
};

export default ImportFormReportTable;

// Styled components
const StyledTableContainer = styled(TableContainer)({
  margin: "20px auto",
  maxWidth: "100%",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
    overflowX: "auto",
  },
});

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Box)({
  width: "80%",
  maxWidth: "600px",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
});
export const Input = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
`;

const dataset: any = [];

