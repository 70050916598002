import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RESTCall from "../restApi";
import Select from "react-select/creatable";

import DatePicker from "react-multi-date-picker";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
  Radio,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteAgendaDB,
  deleteDataDB,
  getAgendaDB,
  getCalendarEvents,
  getChatGPTResponse,
  getDataDB,
  myColors,
  resizeImage,
  saveAgendaDB,
  saveDataDB,
  saveEventDB,
  stringifyArrayToBullets,
} from "src/api";
import { UserContext } from "src/App";
import axios from "axios";
import { CalendarCardCreate } from "./CalendarCardCreate";
import { ButtonL } from "./AppIndex";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "src/helpers/Utils";
import { ControlPoint, Facebook, Instagram, LinkedIn, RemoveCircleOutline, Twitter } from "@mui/icons-material";
import { InputAgenda } from "./MediaSlider";
import { MyFileUploader } from "./MediaSlider/MyFileUploader";
import { MyVideoUploader } from "./MediaSlider/MyVideoUploader";
import { AddSkillSection, SkillChildWrapper } from "./MediaSlider/TabListItems";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const EventFormModal = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const [usersBookedDates, setUsersBookedDates] = useState<any>([]);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const navigate = useNavigate();

  const {
    myInfo,
    myInfo: { active = "2" },
    openCreateEvent,
    data = {},
    host,
    // myFile,
    // fileImageName,
    // fileImageData,
    // fileImageMime,
    // url,
    contextFormInput,
    eventInfo
    // comments = [],
  } = myContext;
  const {
    // formInput: parentFormInput = {},
    // handleMydata=()=>{}
    // formInput,
    // setFormInput,
    // handleMydata,
    // fileImageName,
    // fileImageData,
    // fileImageMime,
    // myFile,
    // url,
  } = props;
  const [stepNumber, setStepNumber] = useState<number>(0);
  const refStartTime = useRef<any>("");
  const refEndTime = useRef<any>("");
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  const [slots, setSlots] = useState<number>(1);
  const [uploadNow, setUploadNow] = useState<boolean>(false);

  const [loadingAI, setLoadingAI] = useState<any>(false);

  const params: any = useParams<any>();
  const location = useLocation();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);


  useEffect(() => {
    (async () => {

      if (eventId) {
        await getDataDB({
          query: {
            _id: eventId,
          },
          resource: 'calendar_events',
          check: ["title"],
        }).then((res) => {
          // alert(JSON.stringify(res?.[0]))
          setMyContext((existing: any) => {
            return {
              ...existing,
              eventInfo: res?.[0],
              pageInfo: res?.[0],
            };
          });
        })
      }
    })()

  }, [
    eventId
  ])




  useEffect(() => {
    if (eventId) {
      getDataDB({
        query: { _id: eventId },
        resource: `calendar_events`,
        limit: 50,
      }).then((res: any) => {
        const parentFormInput = res?.[0];
        if (!parentFormInput) {
          return;
        }
        const days =
          typeof parentFormInput?.days == "string"
            ? JSON.parse(parentFormInput?.days)
            : parentFormInput?.days;

        const dates =
          parentFormInput?.dates == "string"
            ? JSON.parse(parentFormInput?.dates)
            : parentFormInput?.dates;

        const data = {
          ...parentFormInput,
          ...formInput,
          days: days || [],
          dates: dates || days || [],
          ...(parentFormInput?.recurring
            ? { recurring: parentFormInput?.recurring }
            : {
              recurring: "non",
            }),
          ...(parentFormInput?.type
            ? { type: parentFormInput?.type }
            : {
              type: "meeting",
            }),
        };
        console.log("allMyEvents", data);
        if (data?.slots?.length > 0) {
          setSlots(data?.slots?.length)
        }
        setFormInput(data);
      });
    } else {
      setFormInput({
        // ...formInput,
        recurring: "non",
        type: "meeting",
        // capacity: 100
      });
    }

    // setFormInput(data);
  }, [eventId, location?.pathname]);

  const myFile = useRef<any>();
  const fileImageName = useRef("");
  const fileImageData = useRef("");
  const fileImageMime = useRef("");
  const [url, setImageURL] = useState(
    "https://media.varehall.com/upload/myUpload"
  );

  const handleMydata = useCallback(async () => {
    // console.log('loaderMyData', formInput)
    // console.log("formInput", formInput);

    if (!myInfo?.handle) {
      return;
    }

    // if (!formInput?.endDate && formInput?.recurring != "non") {
    //   alert("End date required");
    //   return;
    // }

    const dataProps = {
      ...formInput,
      ...(formInput?.title ? { title: formInput?.title?.trim() } : {}),
      ...(eventInfo
        ? {
          handle: eventInfo?.handle,
          name: eventInfo?.name,
        }
        : {})
    };

    // const myInfoData: any = {
    //   ...(myInfo?.handle ? myInfo : {}),
    //   handle: eventInfo?.handle,
    //   name: eventInfo?.name,
    // }

    if (
      dataProps?.title
    ) {
      // return
      delete dataProps?._id;
      const infoData = {
        query: {
          ...dataProps,
          ...(eventId ? { eventId: eventId } : {}),
          handle: (eventInfo?.handle && eventInfo?.handle != myInfo?.handle)
            ? {
              handle: eventInfo?.handle,
              name: eventInfo?.name,
            } : {
              handle: myInfo?.handle,
              name: myInfo?.name,
            }
        },
        resource: 'calendar_events', //`calendar_skills_record`,
        check: ["handle", "eventId"],
        myInfo: myInfo,
      }
      // console.log("formDatacc", data);
      saveDataDB(infoData).then((rep: any) => {

        alert("Saved");
        setRefreshing(true)
        setTimeout(() => {
          setUploadNow(true)
          setMyContext((existing: any) => {
            return {
              ...existing,
              openCreateEvent: false,
            };
          });
          // if (eventTab == 'edit') {
          navigate(`/event/${eventId}/schedule`)
          // }
        }, 200)
      });
    } else {
      alert("Please make sure you complete all fields");
    }
  }, [
    formInput,
    eventInfo?.handle,
    myInfo?.handle
  ]);

  var openFile = async (file: any) => {
    var input = file.target;
    var reader = new FileReader();
    reader.onload = async () => {
      var dataURL = reader.result;
      var output: any = document.getElementById("output");
      output.src = dataURL;
      const successImages: any = reader.result;

      if (!url) {
        return;
      }
      // setProgress("uploading");
      try {
        const name: any = input?.files[0]?.name;

        const imageString = await successImages // resizeImage(successImages, 200, 200);
        const image =
          name &&
            (name.includes(".png") ||
              name.includes(".jpg") ||
              name.includes(".jpeg"))
            ? await [imageString]
            : successImages;

        const parts = successImages?.split(";");
        const mime = parts[0].split(":")[1];

        fileImageName.current = name;
        fileImageData.current = image[0];
        fileImageMime.current = mime;
      } catch (error) {
        console.log("error in upload", error);
      }
    };
    reader.readAsDataURL(input.files[0]);
  };

  const formatDate = (date: any, time: any, timezoneOffset = "Z") => {
    if (!(date && date?.split("/"))) {
      return null;
    }
    const [month, day, year] = date?.split("/");
    const formattedDate = `20${year}-${month}-${day}T${time}:00${timezoneOffset}`;

    const dateTime = new Date(formattedDate);
    if (isNaN(dateTime.getTime())) {
      console.error("Invalid date or time", dateTime);
      return null;
    }
    // Convert to UTC for Google Calendar
    return dateTime.toISOString().replace(/-|:|\.\d\d\d/g, "") + "Z";
  };

  const createGoogleCalendarLink = async (meetingInfo: any) => {
    const startDateTime = await formatDate(
      meetingInfo.startDate,
      meetingInfo.startTime
    );
    const endDateTime = formatDate(meetingInfo.endDate, meetingInfo.endTime);
    const details = encodeURIComponent(
      `${meetingInfo.description} Meeting link: ${meetingInfo.room}`
    );
    const title = encodeURIComponent(meetingInfo.title);

    const meetingLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDateTime}/${endDateTime}&details=${details}&location=${encodeURIComponent(
      meetingInfo.room
    )}`;

    meetingLink && window.open(`https://${meetingLink}`);
  };

  const timeSlots = useMemo(() => {
    var foo = [];
    for (var i = 1; i <= slots; i++) {
      foo.push(i);
    }
    return foo || []
  }, [
    slots
  ])


  // console.log("formInput", formInput);
  return (
    <>

      <div   style={{
            width:"100%"
          }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 400,
          }}
        >
         
          <h6 style={{
            margin: "10px 0px",
          }}>Title</h6>
          <input
            className="form-control"
            type="text"
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                title: e.target?.value,
              })
            }
            value={formInput?.title || ""}
            style={{
              marginBottom: 10,
              width: "100%",
              alignSelf: "center",
              // backgroundColor: 'gray'
            }}
            required
            placeholder="Enter Enterprise Name"
          />
          <h6 style={{
            margin: "10px 0px",
          }}>Industy (One Sentence)</h6>
          <input
            className="form-control"
            type="text"
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                industry: e.target?.value,
              })
            }
            value={formInput?.industry || ""}
            style={{
              marginBottom: 10,
              width: "100%",
              alignSelf: "center",
            }}
            required
            placeholder="2 to 3 words to describe your industry"
          />
          <h6 style={{
            margin: "10px 0px",
          }}>Enterprise Banner</h6>
          <MyFileUploader
            {...props}
            imageKey={'picture'}
            aiSearchBy={'industry'}
            resource={'calendar_events'}
            refreshing={refreshing}
            setRefreshing={setRefreshing}
            setFormInput={setFormInput}
            formInput={formInput}
            uploadNow={uploadNow}
            allowAdd={false}
            handleSaveImage={(e: any) => {
              setUploadNow(true);
            }}
          />
        </div>

        <h6 style={{
            margin: "10px 0px",
          }}>Enterprise Logo</h6>
        <InputAgenda>
          <MyFileUploader
            {...props}
            imageKey={'logo'}
            aiSearchBy={'industry'}
            resource={'calendar_events'}
            refreshing={refreshing}
            setRefreshing={setRefreshing}
            setFormInput={setFormInput}
            formInput={formInput}
            uploadNow={uploadNow}
            allowAdd={false}
            handleSaveImage={(e: any) => {
              setUploadNow(true);
            }}
          />
        </InputAgenda>


        <h6 style={{
            margin: "10px 0px",
          }}>Enterprise Video</h6>
        <InputAgenda>
          <MyVideoUploader
            {...props}
            refreshing={refreshing}
            aiSearchBy={'industry'}
            setRefreshing={setRefreshing}
            setFormInput={setFormInput}
            videoKey={'video'}
            formInput={formInput}
            uploadNow={uploadNow}
            handleSaveImage={(e: any) => {
              setUploadNow(true);
            }}
            resource={'calendar_events'}
          />
        </InputAgenda>
        {
          <>
            <FormGroup>
              {/* <Form.Label className="form-label text-dark">Location</Form.Label> */}
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
              >
                <h6 style={{
            margin: "10px 0px",
          }}>Business Type</h6>
                <Select
                  defaultValue={{
                    label: "Meeting",
                    value: "meeting",
                  }}
                  isClearable
                  onCreateOption={(e: any) => {
                    console.log('creat', e)
                    // alert(e)
                    if (e) {
                      setFormInput({
                        ...formInput,
                        eventType: e,
                      });
                    }

                  }}
                  value={
                    formInput?.eventType
                      ? {
                        label: capitalizeFirstLetter(formInput?.eventType),
                        value: formInput?.eventType,
                      }
                      : {
                        label: "Meeting",
                        value: "meeting",
                      }
                  }
                  // value={formInput?.eventType || "meeting"}
                  onChange={(e: any) => {

                    if (e?.value)
                      setFormInput({
                        ...formInput,
                        eventType: e?.value,
                      });
                  }}
                  options={[
                    {
                      value: "meeting",
                      label: "Meeting",
                    },
                    {
                      value: "event",
                      label: "Event",
                    },
                    {
                      value: "campaign",
                      label: "Campaign",
                    },
                    {
                      value: "project",
                      label: "Project",
                    },
                    {
                      value: "service",
                      label: "Service",
                    },
                    {
                      value: "company",
                      label: "Company",
                    },
                    {
                      value: "organization",
                      label: "Organization",
                    },
                  ]}
                  placeholder="Event Type"
                  classNamePrefix="selectform"
                />
              </div>


              <h6 style={{
                marginTop: 10,

              }}>Description (your story)</h6>
              <AddSkillSection>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',

                }}>
                  <div

                    onClick={() => {
                      if (!(formInput?.industry || formInput?.title)) {
                        alert('A title or industry details to generate your description')
                        return
                      }
                      setLoadingAI('description')
                      getChatGPTResponse({
                        myInfo: myInfo,
                        prompt: `generate a 3 offerinngs my company offers in our ${formInput?.industry || formInput?.title} services`
                      })
                        .then((reply: any) => {
                          setLoadingAI(false)
                          if (reply) {
                            // console.log('hhhhhhhh', reply)
                            setFormInput({
                              ...formInput,
                              description: stringifyArrayToBullets({ arr: reply }),
                              descriptionArray: reply
                            })
                          }
                        })
                    }}
                    style={{
                      cursor: 'pointer',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'green',
                      borderRadius: 100,
                      color: 'white',
                      paddingRight: 5,
                      paddingLeft: 5,
                      marginRight: 10,
                    }}>{loadingAI ? 'loading..' : 'AI'}</div> <h6 style={{
            margin: "10px 0px",
          }}>Generate, Edit or Add</h6></div>
                <SkillChildWrapper>
                  <InputAgenda onSubmit={(e: any) => { }}>
                    <textarea
                      style={{
                        width: '100%'
                      }}
                      // type={"text"}
                      rows={5}
                      onChange={(e: any) =>
                        setFormInput({
                          ...formInput,
                          description: e.target?.value,
                        })
                      }
                      value={formInput?.description || ""}
                      placeholder="Tell your guest about you and your future goals for your page"
                      required
                    />
                  </InputAgenda>
                </SkillChildWrapper>
              </AddSkillSection>



              <div style={{
                marginTop: 10
              }}><Facebook></Facebook>Facebook Page</div>
              <input
                // value={data?.title}
                className="form-control"
                type="text"
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    facebook: e.target?.value,
                  })
                }
                value={formInput?.facebook || ""}
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="facebook"
              />

              <div style={{
                marginTop: 10
              }}><LinkedIn></LinkedIn>LinkedIn Page</div>
              <input
                // value={data?.title}
                className="form-control"
                type="text"
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    linkedin: e.target?.value,
                  })
                }
                value={formInput?.linkedin || ""}
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="linkedin"
              />

              <div style={{
                marginTop: 10
              }}><Instagram></Instagram>Instagram Page</div>
              <input
                // value={data?.title}
                className="form-control"
                type="text"
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    instagram: e.target?.value,
                  })
                }
                value={formInput?.instagram || ""}
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="instagram"
              />
              <div style={{
                marginTop: 10
              }}><Twitter></Twitter> Twitter Page</div>
              <input
                // value={data?.title}
                className="form-control"
                type="text"
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    twitter: e.target?.value,
                  })
                }
                value={formInput?.twitter || ""}
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="twitter"
              />
            </FormGroup>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 20
            }}>
              {eventInfo?.handle == myInfo?.handle

                && <ButtonL
                  style={{ margin: 0, backgroundColor: 'red' }}
                  onClick={() => {
                    if (!eventInfo?._id) {
                      return
                    }
                    let text = "Sure you want to delete?";
                    if (window.confirm(text) == true) {
                      deleteDataDB({
                        id: eventInfo?._id,
                        resource: 'calendar_events'
                      }).then(ref => {
                        setRefreshing(true)
                        setTimeout(() => {
                          setUploadNow(true)
                          setMyContext((existing: any) => {
                            return {
                              ...existing,
                              openCreateEvent: false,
                            };
                          });
                          // if (eventTab == 'edit') {
                          navigate(`/event/${eventId}/schedule`)
                          // }
                        }, 200)
                      })
                      text = "You pressed OK!";
                    } else {
                      text = "You canceled!";
                    }


                  }}
                >
                  {"Delete"}
                </ButtonL>}

              <ButtonL
                style={{ margin: 0 }}
                onClick={() => {
                  handleMydata();
                }}
              >
                {"Save"}
              </ButtonL>



            </div>

          </>
        }
      </div>

    </>
  );
};
