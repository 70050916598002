import React, { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// import { socket } from 'src/components/socket'; // Assuming similar path
import { socket } from './../socket';
import "../../App.css";
// import { useStyles } from '../components/styles'
import { v4 as uuidv4 } from "uuid";

import { useSetRoomId } from 'src/common/recoil/room'; // Assuming Recoil state management
import { useModal } from 'src/modules/modal'; // Assuming custom hook for modals

import NotFoundModal from './NotFound';
import { UserContext } from 'src/App';
import RoomContextProvider from 'src/modules/room/context/Room.context';
import Board from 'src/modules/room/modules/board';
import { Add } from '@mui/icons-material';
import { InputAgenda } from '.';
import UserList from 'src/modules/room/components/UserList';
import ToolBar from 'src/modules/room/modules/toolbar';
import Chat from 'src/modules/room/modules/chat';
import { ButtonL } from '../AppIndex';

export const Brainstorm = (props: any) => {
  const { openModal } = useModal();
  const setAtomRoomId = useSetRoomId();
  const history = useNavigate(); // useHistory instead of useRouter

  const [roomId, setRoomId] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [started, setStarted] = useState<any>('');


  useEffect(() => {
    document.body.style.backgroundColor = 'white';
  }, []);

  useEffect(() => {

    socket.on('created', (roomIdFromServer: string) => {
      console.log("roomIdFromServer", roomIdFromServer);
      setStarted(roomIdFromServer)
      setAtomRoomId(roomIdFromServer);
      history(`/canvas/${roomIdFromServer}`); // Adjust the route as necessary
    });

    const handleJoinedRoom = (roomIdFromServer: string, failed?: boolean) => {
      if (!failed) {
        setAtomRoomId(roomIdFromServer);
        history(`/canvas/${roomIdFromServer}`); // Adjust the route as necessary
      } else {
        openModal(<NotFoundModal id={roomId} />);
      }
    };

    socket.on('joined', handleJoinedRoom);

    return () => {
      socket.off('created');
      socket.off('joined', handleJoinedRoom);
    };
  }, [openModal,
    roomId,
    history,
    setAtomRoomId]);

  useEffect(() => {
    socket.emit('leave_room');
    setAtomRoomId('');
  }, [setAtomRoomId]);

  const handleCreateRoom = () => {
    socket.emit('create_room', username);
  };

  const handleJoinRoom = () => {
    if (roomId) socket.emit('join_room', roomId, username);
  };

  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo = {},
    editInfo,
    eventInfo = {}
  } = myContext;
  const navigate = useNavigate();
  const params: any = useParams<any>();
  const [formInput, setFormInput] = useState<any>({});

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);


  useEffect(() => {
    if (myInfo?.handle) {
      setUsername(myInfo?.handle.slice(0, 15))
    }

  }, [
    eventId,
    myInfo?.handle
  ])

  useEffect(() => {

    if (roomId) {
      handleCreateRoom()
    }
    // if (username) {
    //   handleJoinRoom()
    // }
    // console.log('nnnnnnn', roomId, username)
  }, [
    roomId,
    username
  ])


  return (<>

    {editInfo &&
      <div style={{
        width: '100%'
      }}>

        <h1>{formInput?.title || ""}</h1>

        {started && <ButtonL onClick={() => {
          handleJoinRoom()
        }}>
          Join Meeting
        </ButtonL>}
        {!roomId && eventInfo?.handle == myInfo?.handle && <div>
          <InputAgenda onSubmit={(e: any) => { }}>
            <input
              type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  title: e.target?.value,
                })
              }
              value={formInput?.title || ""}
              placeholder={"Add title ..."}
              required
            />
            <div
              onClick={() => {
                if (!(formInput?.title?.length > 0)) {
                  alert('Title required')
                  return
                }
                setRoomId(eventId)
              }}
            >
              <Add />
            </div>
          </InputAgenda>
        </div>}
      </div >}

    {(roomId || started) && <RoomContextProvider>
      <div className="relative h-full w-full overflow-hidden" style={{ position: 'relative', height: '100%', width: '100%', overflow: 'hidden' }}>
        <UserList />
        <ToolBar />
        <Board roomId={roomId} userName={username} />
        <Chat />
      </div>
    </RoomContextProvider>}
  </>);
};

