import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import styled from "@emotion/styled";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RESTCall from "../restApi";
import Select from "react-select";

import DatePicker from "react-multi-date-picker";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
  Radio,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteAgendaDB,
  getAgendaDB,
  getCalendarEvents,
  getDataDB,
  myColors,
  resizeImage,
  saveAgendaDB,
  saveDataDB,
  saveEventDB,
} from "src/api";
import { UserContext } from "src/App";
import axios from "axios";
import { CalendarCardCreate } from "./CalendarCardCreate";
import { ButtonL } from "./AppIndex";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "src/helpers/Utils";
import { Check, ControlPoint, RemoveCircleOutline } from "@mui/icons-material";
import { MultiSelect } from "react-multi-select-component";
import { CalendarCardCreateNotification } from "./CalendarCardCreateNotification";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const ScheduleNotificationModal = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const [usersBookedDates, setUsersBookedDates] = useState<any>([]);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const navigate = useNavigate();

  const {
    myInfo,
    myInfo: { active = "2" },
    openCreateEvent,
    data = {},
    host,
    // myFile,
    // fileImageName,
    // fileImageData,
    // fileImageMime,
    // url,
    contextFormInput,
    scheduleId,
    eventTabs,
    // comments = [],
  } = myContext;
  const {
    // formInput: parentFormInput = {},
    // handleMydata=()=>{}
    // formInput,
    // setFormInput,
    // handleMydata,
    // fileImageName,
    // fileImageData,
    // fileImageMime,
    // myFile,
    // url,
    // slots = 1,
    // setSlots,
    // formInput = {},
    // setFormInput
    // scheduleId
    formInput: formInputMain = {},
    setFormInput: setFormInputMain,
    setOpenCalendar,
    scheduleInfo = {},
  } = props;
  const [stepNumber, setStepNumber] = useState<number>(0);
  const refStartTime = useRef<any>("");
  const refEndTime = useRef<any>("");
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  const [slots, setSlots] = useState<number>(1);

  const [eventDetails, setEventDetails] = useState<any>({});

  const params: any = useParams<any>();
  const location = useLocation();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);


  const myDate = useRef<any>()



  useEffect(() => {
    if (!formInput?.days && formInputMain?.notification?.days) {
      const days = formInputMain?.notification?.days?.map((ref: any) => ref
        // moment(ref).format("MM/DD/YY")
      )
      setFormInput({
        ...(formInputMain?.notification ? formInputMain?.notification : {}),
        ...(formInputMain?.notification?.endDate ? { end: formInputMain?.notification?.endDate } : {}),
        ...(formInputMain?.notification?.days ? {
          dates: days,
          days: days
        } : {}),
      })
      return
    }
    setFormInputMain({
      ...formInputMain,
      notification: {
        ...(formInputMain?.notification ? formInputMain?.notification : {}),
        ...formInput
      }
    })
  }, [
    formInput
  ])


  useEffect(() => {
    if (formInput?.endDate)
      myDate.current.value = moment(formInput?.endDate)?.format("YYYY-MM-DD") //"2014-02-09"
  }, [
    formInput?.endDate
  ])

  // console.log("formInputvvv", formInput);
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 400,
          }}
        >
          <img
            id="output"
            className="uploader__file"
            src={
              myInfo && formInput?.picture
                ? formInput?.picture
                : "https://minorityproductlaunch.com/wp-content/uploads/2023/12/20231212_135738_0000.png"
            }
            style={{
              width: 100,
              height: 100,
              borderRadius: "50%",
              margin: "auto",
              objectFit: "cover",
              marginBottom: 10,
            }}
          />

        </div>

        {
          <>
            <div
              style={{
                marginBottom: 10,
                width: "100%",
                alignSelf: "center",
                // backgroundColor: 'gray'
              }}
            ></div>

            <Tabs
              selectedIndex={stepNumber}
              onSelect={(index) => setStepNumber(index)}
            >
              <div
                style={{
                  display: "flex",
                  // marginTop: 20,
                  // marginLeft: 20
                  justifyContent: "center",
                }}
              >
                <CalendarCardCreateNotification
                  // setFormInput={setFormInput}
                  // formInput={formInput}
                  onClick={(e: any) => {
                    // console.log('itemxxx', res

                    const filterUser = usersBookedDates?.filter((reg: any) => {
                      return reg?.userId == formInput?.userId;
                    })?.[0];
                    // console.log('itemxxx-2', filterUser)

                    if (
                      filterUser?.dates?.[0] &&
                      filterUser?.dates?.includes(
                        moment(e.dat.date)?.format("MM/DD/YY")
                      )
                    ) {
                      alert(
                        `Already scheduled for another action at this time`
                      );
                      return;
                    }

                    const filterDates =
                      formInput?.days?.[0] &&
                      [...(formInput?.days || [])]?.filter(
                        (res: any) =>
                          moment(res)?.format("MM/DD/YY") !=
                          moment(e.dat.date)?.format("MM/DD/YY")
                      );
                    const record =
                      formInput?.days?.[0] &&
                        filterDates?.length != formInput?.days?.length
                        ? filterDates
                        : formInput?.days?.[0]
                          ? [...formInput?.days, e.dat.date]
                          : [e.dat.date];
                    // console.log('bbbb', filterDates, record, e.dat, record)
                    setFormInput({
                      ...formInput,
                      days: record,
                    });
                  }}
                  {...props}
                  startDay={""}
                  eventId={formInput?._id}
                  setFormInput={setFormInput}
                  formInput={formInput}
                />
              </div>


              <div>
                <label
                  htmlFor="appt"
                  style={{
                    marginLeft: 10,
                  }}
                >
                  End Date:{" "}
                </label>
                <StyledInput
                  ref={myDate}
                  type="date"
                  // value={moment(formInput?.endDate)?.format("mm/dd/yyyy")?.toString() || ""}
                  onChange={(value: any) => {
                    // console.log('endDate', value.target.value)
                    setFormInput({
                      ...formInput,
                      endDate: value.target.value?.toString(),
                    });
                  }}
                />

              </div>
            </Tabs>

            <ButtonL
              style={{ margin: 10 }}
              onClick={() => {
                if (formInput?.recurring != 'non' && !formInput?.endDate) {
                  alert('You must select end date to submit')
                  return
                }
                setOpenCalendar(false)
                // handleMydata();
              }}
            >
              {"Save"}
            </ButtonL>
          </>
        }
      </div>
    </>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled.form`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  box-shadow: 0 0 5px #8798ad;
  border-radius: 5px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  box-shadow: 0 0 5px #8798ad;
  border-radius: 5px;
  resize: none;
`;

const Field = styled.div`
  label {
    font-size: 16px;
    font-weight: bold;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background: #57b397;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background: #57b397b9;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 1rem;
  background: #57b397;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #57b39794;
  }
`;
