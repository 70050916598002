import moment from 'moment';

export const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
export const weekDaysAb = ["S", "M", "T", "W", "T", "F", "S"]


export function monthDiff(firstMonth, lastMonth) {
  let months;
  months = (lastMonth.getFullYear() - firstMonth.getFullYear()) * 12;
  months -= firstMonth.getMonth();
  months += lastMonth.getMonth();
  return months <= 0 ? 0 : months;
}

export function dayDiff(startDate, endDate) {
  const difference =
    new Date(endDate).getTime() - new Date(startDate).getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24)) + 1;
  return days;
}

export const daysOfGivenWeek = (props) => {
  const {
    current
  } = props
  if (!current) {
    return []
  }
  const startDay = current && moment(current).startOf('week')?.format('YYYY/MM/DD')
  // const endDay = moment('2024-1-12').endOf('week')?.format('YYYY/MM/DD')
  let i = 0;
  const myDays = []
  for (i = 0; i < 7; i++) {
    myDays.push(
      {
        date: moment(startDay).add(i, 'days')?.format('YYYY/MM/DD'),
        day: weekDays[i],
        dayAb: weekDaysAb[i]
      }
    )
  }
  return myDays;
}



export function getDaysInMonth(props) {
  const { year, month } = props
  console.log('vvvv', year, month)
  return new Date(year, month, 0).getDate();
}

export function getDayOfWeek(props) {
  const {
    year, month, day
  } = props
  const daysOfTheWeekArr = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const dayOfTheWeekIndex = new Date(year, month, day).getDay();
  return daysOfTheWeekArr[dayOfTheWeekIndex];
}

export function createFormattedDateFromStr(year, month, day) {
  let monthStr = month.toString();
  let dayStr = day.toString();

  if (monthStr.length === 1) {
    monthStr = `0${monthStr}`;
  }
  if (dayStr.length === 1) {
    dayStr = `0${dayStr}`;
  }
  return `${year}-${monthStr}-${dayStr}`;
}

export function createFormattedDateFromDate(date) {
  let monthStr = (date.getMonth() + 1).toString();
  let dayStr = date.getDate().toString();

  if (monthStr.length === 1) {
    monthStr = `0${monthStr}`;
  }
  if (dayStr.length === 1) {
    dayStr = `0${dayStr}`;
  }
  return `${date.getFullYear()}-${monthStr}-${dayStr}`;
}
