import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

// Styled components
const StoryContainer = styled.div<{ bgSrc: string; useImg: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #00000040;
  background-image: ${({ useImg, bgSrc }) => (useImg ? `url(${bgSrc})` : 'none')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  width: 350px;
  height: 600px;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const CaptionContainer = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  height: 180px;
  padding: 10px;
`;

const CaptionText = styled.div<{ scrollY: number; textType: string }>`
  position: relative;
  top: ${({ scrollY }) => `-${scrollY}px`};
  transition: top 0.2s ease-in-out;
  ${({ textType }) => {
    switch (textType) {
      case 'bold':
        return `font-weight: bold; font-family: Arial, sans-serif; font-size: 20px;`;
      case 'italic':
        return `font-style: italic; font-family: 'Times New Roman', serif; font-size: 18px;`;
      case 'fancy':
        return `font-family: 'Courier New', monospace; font-size: 24px; color: #ff6347;`;
      default:
        return `font-family: 'Roboto', sans-serif; font-size: 16px;`;
    }
  }}
`;

const ProgressBar = styled.div<{ progress: number }>`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  &::before {
    content: '';
    display: block;
    height: 100%;
    background: #fff;
    width: ${({ progress }) => `${progress}%`};
    transition: width 0.2s ease-in-out;
  }
`;

const ControlButton = styled.button`
  position: absolute;
  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const VideoBackground = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

const ControlIcons = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
`;

const IconButton = styled.button`
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

interface StoryProps {
  caption: string;
  audioSrc: string;
  img?: string;
  video?: string;
  useImg?: boolean;
  useAudioSrc?: boolean;
  interval?: number;
  isPlaying?: boolean;
  setIsPlaying?: () => {};
  setProgress?: () => {};
  textType?: string;
  progress?: number;
}

const MultiStoryCaption = (props: any) => {
  const {
    data = [
      {
        "caption": "The quick brown fox jumps over the lazy dog. This text is displayed in bold style.",
        "audioSrc": "https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3",
        "img": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2dV7i6tPkgxE6oWMLQB02BSL0dzWzJV5R_w&s",
        "video": "https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4", // Open-source video
        "useImg": true,
        "useAudioSrc": true,
        "interval": 12,
        "textType": "bold"
      },
      {
        "caption": "This is a test caption with italic style and a beautiful open-source video.",
        "audioSrc": "https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3",
        "img": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2dV7i6tPkgxE6oWMLQB02BSL0dzWzJV5R_w&s",
        "video": "https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4", // Open-source video
        "useImg": false, // Using video instead of image
        "useAudioSrc": false, // No custom audio, will use text-to-speech
        "interval": 15,
        "textType": "italic"
      },
      {
        "caption": "Welcome to the fancy world of fonts. This caption is styled with a fancy text type.",
        "audioSrc": "https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3",
        "img": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2dV7i6tPkgxE6oWMLQB02BSL0dzWzJV5R_w&s",
        "useImg": true, // Using image background
        "useAudioSrc": true, // Custom audio playing
        "interval": 10,
        "textType": "fancy"
      },
      {
        "caption": "Default text type is applied to this caption with background video and audio.",
        "audioSrc": "https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3",
        "img": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2dV7i6tPkgxE6oWMLQB02BSL0dzWzJV5R_w&s",
        "video": "https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4", // Open-source video
        "useImg": false, // Using video background
        "useAudioSrc": true, // Custom audio playing
        "interval": 14,
        "textType": "default"
      }
    ]
  } = props;

  const [showIndex, setShowIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [progress, setProgress] = useState(0);

  // Create audioRef and videoRef in this component
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleNext = () => {
    clearMediaRefs();
    const nextIndex = (showIndex + 1) % data.length;
    setShowIndex(nextIndex);
    setIsPlaying(true);
    setProgress(0); // Reset progress on next
  };

  const handleBack = () => {
    clearMediaRefs();
    const prevIndex = (showIndex - 1 + data.length) % data.length;
    setShowIndex(prevIndex);
    setIsPlaying(true);
    setProgress(0); // Reset progress on back
  };

  const clearMediaRefs = () => {
    setProgress(0)

    // Clear the audio and video refs
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowIndex(showIndex + 1);

  //   }, 1000)
  // }, [
  //   isPlaying
  // ])

  return (
    <div>
      <InstagramStory
        caption={data[showIndex]?.caption || ''}
        audioSrc={data[showIndex]?.audioSrc || ''}
        img={data[showIndex]?.img || ''}
        video={data[showIndex]?.video || ''}
        useImg={data[showIndex]?.useImg || true}
        useAudioSrc={data[showIndex]?.useAudioSrc || false}
        interval={data[showIndex]?.interval || 15}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        showIndex={showIndex}
        setShowIndex={setShowIndex}
        data={data}
        handleNext={handleNext}
        handleBack={handleBack}
        progress={progress}
        setProgress={setProgress}
        textType={data[showIndex]?.textType || 'default'}
        audioRef={audioRef}
        videoRef={videoRef}
        clearMediaRefs={clearMediaRefs}
      />
    </div>
  );
};

const InstagramStory: React.FC<any> = ({
  caption,
  audioSrc,
  img,
  video,
  useImg = true,
  useAudioSrc = true,
  interval = 15,
  isPlaying,
  setIsPlaying,
  showIndex,
  setShowIndex,
  data,
  handleNext,
  handleBack,
  textType,
  progress,
  setProgress,
  audioRef,  // Receive the refs as props
  videoRef,  // Receive the refs as props
  clearMediaRefs,
}) => {
  const [scrollY, setScrollY] = useState(0);
  const captionRef = useRef<HTMLDivElement>(null);
  const scrollDurationRef = useRef(0);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    // Reset progress and start a new progress interval for each story
    setProgress(0);

    if (isPlaying) {
      if (useAudioSrc && audioRef.current) {
        audioRef.current.play();
      }
      if (videoRef.current) {
        videoRef.current.play();
      }
      startScrolling();
    } else {
      if (audioRef.current) audioRef.current.pause();
      if (videoRef.current) videoRef.current.pause();
      startScrolling();
    }

    return () => {
      clearInterval(intervalRef.current!);
      clearMediaRefs();
    };
  }, [showIndex, useAudioSrc, isPlaying]);

  // useEffect(() => {
  //   if (progress == 0) {
  //     setScrollY(0)

  //   }
  // }, [
  //   progress
  // ])

  useEffect(() => {
    if (isPlaying) {
      const timeoutId = setTimeout(() => {
        handleNext();
      }, interval * 1000);

      const progressInterval = setInterval(() => {
        setProgress((prevProgress: any) => {
          const nextProgress = prevProgress + 100 / (interval * 10);
          if (nextProgress >= 100) {
            clearInterval(progressInterval);
          }
          return nextProgress;
        });
      }, 100); // Update progress every 100ms

      return () => {
        clearTimeout(timeoutId);
        clearInterval(progressInterval);
      };
    }
  }, [isPlaying]);

  const startScrolling = () => {
    const caption = captionRef.current;
    if (caption) {
      const maxScroll = caption.scrollHeight - caption.clientHeight;
      const scrollPerSecond = maxScroll / interval;
      intervalRef.current = window.setInterval(() => {
        setScrollY((prevY) => {
          const nextY = prevY + scrollPerSecond;
          return nextY > maxScroll ? maxScroll : nextY;
        });

        // Stop scrolling when the text is fully scrolled
        if (progress >= 100) {
          clearInterval(intervalRef.current!);
        }
      }, 100);
    }
  };

  useEffect(() => {
    const caption = captionRef.current;
    if (caption) {
      const maxScroll = caption.scrollHeight - caption.clientHeight;
      scrollDurationRef.current = maxScroll;
    }

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, []);

  return (
    <StoryContainer bgSrc={img || ''} useImg={useImg}>
      {!useImg && video && (
        <VideoBackground
          ref={videoRef}  // Use videoRef for video element
          src={video}
          controls
          loop
        />
      )}
      <audio ref={audioRef} src={useAudioSrc ? audioSrc : ''} loop />
      <ProgressBar progress={progress} />
      <CaptionContainer ref={captionRef} onScroll={() => setIsPlaying(false)}>
        <CaptionText scrollY={scrollY} textType={textType}>
          {caption}
        </CaptionText>
      </CaptionContainer>
      <ControlIcons>
        <IconButton onClick={handleBack}>←</IconButton>
        <IconButton onClick={handleNext}>→</IconButton>
      </ControlIcons>
      <ControlButton onClick={() => setIsPlaying(!isPlaying)}>
        {isPlaying ? 'Pause' : 'Play'}
      </ControlButton>
    </StoryContainer>
  );
};

export default MultiStoryCaption;
