import Carousel from "react-spring-3d-carousel";
import { useState, useEffect, useMemo, useContext } from "react";
import { config } from "react-spring";
import { useSpring, animated } from "react-spring";
// import './CarrousselCard.css'
// import Styles from "./CarrousselStyle.css";
import { v4 as uuidv4 } from "uuid";
import { getCalendarUsers, getDataDB, getGuestLikesDB, handleSaveLikes, myColors } from "src/api";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

export const Carroussel = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);

  const {
    eventId,
    width,
    height = 500,
    host,
    margin
  } = props;

  const [people, setPeople] = useState<any>([])

  useEffect(() => {
    // console.log('vvvvvvv', eventId, host)
    if (eventId) {
      getDataDB({
        query: {
          eventId: eventId
        },
        resource: "calendar_attendance",
      }).then(res => {
        console.log('vvvvvvvgg', res)
        setPeople(res)
      })

    } else if (host?.handle) {

      getDataDB({
        query: {
          handle: host?.handle
        },
        resource: "calendar_events",
      }).then(res => {
        console.log('vvvvvvvgg', res)
        setPeople(res)
      })

    }
  }, [
    eventId,
    host
  ])

  const cardsProps = useMemo(() => {
    // console.log('vvvvpeople', people)

    return people?.map((res: any) => {
      return {
        key: uuidv4(),
        content: (
          <Card
            {...props}
            imagen={
              res?.image ||
              res?.img ||
              "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
            }
            item={res}
            isSmall={props.isSmall}
          />
        ),
      };
    });
  }, [people]);

  const cards = useMemo(() => {
    return cardsProps?.map((element: any, index: number) => {
      return { ...element, onClick: () => setGoToSlide(index) };
    });
  }, [cardsProps]);

  const [offsetRadius, setOffsetRadius] = useState<any>(2);
  const [showArrows, setShowArrows] = useState<any>(false);
  const [goToSlide, setGoToSlide] = useState<any>(null);


  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
    console.log(cards, "<-cards");
  }, [props.offset, props.showArrows]);


  return (
    <CardsContainer isSmall={props.isSmall}>
      {people?.map((res: any, index: number) => (
        <Card
          key={index}
          {...props}
          imagen={
            res?.image ||
            res?.img ||
            "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
          }
          item={res}
        />
      ))}
    </CardsContainer>
  );
};

const Card = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    eventId,
    allMyEvents,
    // host = "",
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    searchBy = "Event",
    // comments = [],
  } = myContext;
  const { imagen, item, handleInfo, setLocalAction,
    host,
    searchKeywordAttendees
  } = props;
  const navigate = useNavigate();
  const [show, setShown] = useState(false);
  const [peopleData, setPeopleData] = useState(false);
  const [myLike, setMyLike] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);



  useEffect(() => {
    // console.log("Guest info in the card", item);
    getGuestLikesDB({
      handle: myInfo?.handle,
      itemId: item?.handle,
    }).then(res => {
      console.log('myLike', myLike)
      setMyLike(res?.[0]?.like ? true : false)
      setRefreshing(false)
    })
  }, [item, refreshing]);

  useEffect(() => {
    // console.log("Guest info in the card", item);
  }, [item]);




  return (
    <CardWrapperParent isSmall={props.isSmall}>
      <ImgContainer isSmall={props.isSmall}>

        <div
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.05)',
            borderRadius: 10,

          }}
        >
          <video
            autoPlay
            loop
            muted
            poster={imagen || "https://varefiles.s3.us-east-2.amazonaws.com/myguestnet.mp4"}
            style={{
              height: '100%',
              borderRadius: 10
            }}>
            <source src={item?.video} type='video/mp4' />
            <source src={item?.video} type="video/ogg" />
          </video>
        </div>
        <RR
          data={{
            text: item?.name,
            url: `https://www.myguestnet.com/host/${item?.handle}`,
            title: `${item?.name}: ${item?.title}`,
          }}
        >
          <i
            className="fa fa-share"
            style={{
              display: "flex",
              fontSize: 18,
              //   backgroundColor: myColors.brown,
              padding: 10,
              borderRadius: 100,
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </RR>
        <div
          onClick={() => {
            if (!myInfo?.token) {
              alert('Please login')
              return
            }
            handleSaveLikes({
              like: !myLike,
              type: 'guest',
              ...item,
              myInfo
            }).then(res => {
              alert('Saved Like')
              setMyLike(!myLike)
              setRefreshing(true)
            })
            // console.log('nnnnnn', item)
            // return
          }}
          style={{
            display: 'flex',
            right: 50,
            top: 0,
            position: "absolute",
            alignItems: "center",
          }}>
          <i
            className="fa fa-heart"
            style={{
              display: "flex",
              fontSize: 18,
              //   backgroundColor: myColors.brown,
              padding: 10,
              borderRadius: 100,
              backgroundColor: myLike ? 'orange' : 'raga(0,0,0,0)',
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          />

        </div>
      </ImgContainer>
      <CardInfo>
        <CardHead isSmall={props.isSmall}>{item?.name}</CardHead>
        <CardSubHead isSmall={props.isSmall}>{item?.title}</CardSubHead>
      </CardInfo>
      {/* <div>{item?.quote}</div> */}
      <DetailButton
        isSmall={props.isSmall}
        onClick={() => {
          // if (searchBy == "Event") {
          if (false) {
            // console.log('itemxxx', item)
            setMyContext((existing: any) => {
              return {
                ...existing,
                eventId: item?._id,
              };
            });
          } else {
            setMyContext((existing: any) => {
              return {
                ...existing,
                action: false,
              };
            });
            // handleInfo(item.handle);
            setLocalAction("detail");
            navigate(`/host/${item.handle}/about`);
          }
        }}
      >
        View Details
      </DetailButton>
    </CardWrapperParent>
  );
};


export default Carroussel;

const CardWrapperParent = styled.div<{ isSmall: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isSmall }) => (isSmall ? "5px" : " 10px")};
  font-family: "Poppins", sans-serif;
  h1,
  div {
    margin: 0;
  }
`;
const ImgContainer = styled.div<{ isSmall: boolean }>`
  position: relative;
  height: ${({ isSmall }) => (isSmall ? "200px" : " 290px")};
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
const RR = styled(RWebShare)`
  border: 12px solid green;
`;

const CardHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "16px" : "22px")};
  font-style: normal;
  font-weight: 600;
`;
const CardSubHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const DetailButton = styled.button<{ isSmall: boolean }>`
  color: #479d83;
  text-align: center;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "16px")};
  font-weight: 500;
  width: 100%;

  padding: ${({ isSmall }) => (isSmall ? "6px" : "9px")};
  border-radius: 60px;
  border: 1px solid #479d83;
  background: #fff;
  transition: background 0.3s;
  margin: 0;
  margin-top: ${({ isSmall }) => (isSmall ? "4px" : "8px")};
  &:hover {
    background: #f5fffc;
  }
`;
const CardsContainer = styled.div<{ isSmall: boolean }>`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isSmall }) =>
    isSmall ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};
  gap: ${({ isSmall }) => (isSmall ? "10px" : "24px")};
  justify-content: center;
  margin: ${({ isSmall }) => (isSmall ? "0px" : "50px 20px")};
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

// These Buttons are not necessary for the same card according to this UI design because we have a single button of view details and user can get every thing there

{
  /* <div className={"btnn"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // overflowX: 'scroll',
            width: "100%",
            // top: 70,
            // padding: 20,
            // borderRadius: 50,
            // position: 'absolute',
            // backgroundColor: myColors.gray
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: 300,
              border: "2px solid red",
              alignItems: "center",
            }}
          >
            <>
              <div
                style={{
                  border: "2px solid yellow",
                }}
              >
                <i
                  className="fa fa-briefcase"
                  style={{
                    display: "flex",
                    fontSize: 18,
                    backgroundColor: myColors.brown,
                    padding: 10,
                    borderRadius: 100,
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    // paddingRight: 5,
                    // color: 'black'
                  }}
                />
                <div
                  style={{
                    color: "white",
                    fontSize: 9,
                  }}
                >
                  Portfolio
                </div>
              </div>
              {searchBy == "Guest" && (
                <div
                  onClick={() => {
                    alert(
                      "Action not allowed at this time, please try again later"
                    );
                  }}
                >
                  <i
                    className="fa fa-comments"
                    style={{
                      display: "flex",
                      fontSize: 18,
                      backgroundColor: myColors.brown,
                      padding: 10,
                      borderRadius: 100,
                      color: "white",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                  <div
                    style={{
                      color: "white",
                      fontSize: 9,
                    }}
                  >
                    Chat
                  </div>
                </div>
              )}
            </>
            {item?.email && (
              <div
                onClick={() => {
                  alert(
                    "Action not allowed at this time, please try again later"
                  );
                }}
              >
                <i
                  className="fa fa-envelope"
                  style={{
                    display: "flex",
                    fontSize: 18,
                    backgroundColor: myColors.brown,
                    padding: 10,
                    borderRadius: 100,
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <div
                  style={{
                    color: "white",
                    fontSize: 9,
                  }}
                >
                  Email
                </div>
              </div>
            )}
            {item?.phone && (
              <div
                onClick={() => {
                  alert(
                    "Action not allowed at this time, please try again later"
                  );
                }}
              >
                <i
                  className="fa fa-phone"
                  style={{
                    display: "flex",
                    fontSize: 18,
                    backgroundColor: myColors.brown,
                    padding: 10,
                    borderRadius: 100,
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <div
                  style={{
                    fontSize: 9,
                    color: "white",
                  }}
                >
                  Call
                </div>
              </div>
            )}
            <div>
              <div
                style={{
                  fontSize: 9,
                  color: "white",
                  border: "2px sold red",
                }}
              >
                Share
              </div>
            </div>
          </div>
        </div>
            </div>*/
}
