import Carousel from "react-spring-3d-carousel";
import { useState, useEffect, useMemo, useContext } from "react";
import { config } from "react-spring";
import { useSpring, animated } from "react-spring";
// import './CarrousselCard.css'
// import Styles from "./CarrousselStyle.css";
import { v4 as uuidv4 } from "uuid";
import {
  getCalendarUsers,
  getDataDB,
  getGuestLikesDB,
  handleReaction,
  handleSaveLikes,
  myColors,
} from "src/api";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonL } from "./AppIndex";
import { AddBox, Beenhere, DeleteOutline, VerifiedUser } from "@mui/icons-material";

export const AddMemberCarroussel = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { } = myContext;
  const { people, width, height = 500, margin } = props;

  const params: any = useParams<any>();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const cardsProps = useMemo(() => {
    // console.log('vvvvpeople', people)

    return people?.map((res: any) => {
      return {
        key: uuidv4(),
        content: (
          <Card
            {...props}
            imagen={
              res?.image ||
              res?.img ||
              "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
            }
            item={res}
            isSmall={props.isSmall}
          />
        ),
      };
    });
  }, [people]);

  const cards = useMemo(() => {
    return cardsProps?.map((element: any, index: number) => {
      return { ...element, onClick: () => setGoToSlide(index) };
    });
  }, [cardsProps]);

  const [offsetRadius, setOffsetRadius] = useState<any>(2);
  const [showArrows, setShowArrows] = useState<any>(false);
  const [goToSlide, setGoToSlide] = useState<any>(null);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
    console.log(cards, "<-cards");
  }, [props.offset, props.showArrows]);

  return (
    <CardsContainer isSmall={props.isSmall}>
      {people?.map((res: any, index: number) => (
        <Card
          key={index}
          {...props}
          imagen={
            res?.image ||
            res?.img ||
            "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
          }
          item={res}
        />
      ))}
    </CardsContainer>
  );
};

const Card = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    // host = "",
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    searchBy = "Event",
    allAccess
    // comments = [],
  } = myContext;
  const {
    imagen,
    item,
    handleInfo,
    handleRemove,
    handleAdd,
    eventInfo = {},
    handleVerify,
    setLocalAction,
    host,
    searchKeywordAttendees,
    isVerified = false,
    addVerifyButton = false
  } = props;
  const navigate = useNavigate();
  const [show, setShown] = useState(false);
  const [peopleData, setPeopleData] = useState(false);
  const [myLike, setMyLike] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  useEffect(() => {
    // console.log("Guest info in the card", item);
    getGuestLikesDB({
      handle: myInfo?.handle,
      itemId: item?.handle,
    }).then((res) => {
      console.log("myLike", myLike);
      setMyLike(res?.[0]?.like ? true : false);
      setRefreshing(false);
    });
  }, [item, refreshing]);

  return (
    <CardWrapperParent isSmall={props.isSmall}>
      <ImgContainer isSmall={props.isSmall}>
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.05)",
            borderRadius: 10,
          }}
        >
          <video
            autoPlay
            loop
            muted
            poster={
              imagen ||
              "https://varefiles.s3.us-east-2.amazonaws.com/myguestnet.mp4"
            }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 10,
            }}
          >
            <source src={item?.video} type="video/mp4" />
            <source src={item?.video} type="video/ogg" />
          </video>
        </div>

        {(myInfo?.handle == item?.handle || allAccess) && <div
          style={{
            display: "flex",
            right: 50,
            top: 0,
            position: "absolute",
            alignItems: "center",
          }}
        >

          {item?.status &&
            //  <ButtonL
            //   style={{
            //     backgroundColor: 'rgba(0,0,0,0.5)',
            //     fontSize: 10
            //   }}
            //   onClick={() => {
            //     if (item?.status == 'request') {
            //       handleVerify(item)
            //     } else if (item?.status == 'request') {
            //       alert('You are already verified')
            //     }
            //   }}
            // >
            //   {item?.status == 'approved'
            //     ? 'Verified'
            //     : item?.status == 'request'
            //       ? 'Pending Verification'
            //       : 'Submit Request for  & Verification'}
            //   {/* <Beenhere /> */}
            // </ButtonL>

            <ButtonL
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)'
              }}


            >
              {item?.status == 'request'
                ? <div onClick={() => {
                  // handleRemove(item)
                }}>Pending Verification</div>
                : item?.status == 'approved'
                  ? 'Verified' : null}
              {item?.status == 'approved' && <Beenhere />}
              <DeleteOutline
                onClick={() => {
                  handleRemove(item)
                }}
              />
            </ButtonL>}
        </div>}
      </ImgContainer>
      <CardInfo>
        <CardHead isSmall={props.isSmall}>{item?.name}</CardHead>
        <CardSubHead isSmall={props.isSmall}>{item?.title}</CardSubHead>
      </CardInfo>
      {/* <div>{item?.quote}</div> */}
      <DetailButton
        isSmall={props.isSmall}
        onClick={() => {
          if (!item?.status || item?.status != 'request') {
            handleAdd(item)
          } else if (item?.status == 'approved') {
            setMyContext((existing: any) => {
              return {
                ...existing,
                action: false,
              };
            });
            navigate(`/host/${item.handle}/about`);
          }


        }}
      >
        {item?.status ? 'View Details' : 'Request Verification'}
      </DetailButton>
    </CardWrapperParent>
  );
};

const CardWrapperParent = styled.div<{ isSmall: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isSmall }) => (isSmall ? "5px" : " 10px")};
  font-family: "Poppins", sans-serif;
  h1,
  div {
    margin: 0;
  }
`;
const ImgContainer = styled.div<{ isSmall: boolean }>`
  position: relative;
  height: ${({ isSmall }) => (isSmall ? "200px" : " 290px")};
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
const RR = styled(RWebShare)`
  border: 12px solid green;
`;

const CardHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "16px" : "22px")};
  font-style: normal;
  font-weight: 600;
`;
const CardSubHead = styled.h1<{ isSmall: boolean }>`
  color: #000;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const DetailButton = styled.button<{ isSmall: boolean }>`
  color: #479d83;
  text-align: center;
  font-size: ${({ isSmall }) => (isSmall ? "12px" : "16px")};
  font-weight: 500;
  width: 100%;

  padding: ${({ isSmall }) => (isSmall ? "6px" : "9px")};
  border-radius: 60px;
  border: 1px solid #479d83;
  background: #fff;
  transition: background 0.3s;
  margin: 0;
  margin-top: ${({ isSmall }) => (isSmall ? "4px" : "8px")};
  &:hover {
    background: #f5fffc;
  }
`;
export const CardsContainer = styled.div<{ isSmall: boolean }>`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isSmall }) =>
    isSmall ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};
  gap: ${({ isSmall }) => (isSmall ? "10px" : "24px")};
  justify-content: center;
  margin: ${({ isSmall }) => (isSmall ? "0px" : "50px 20px")};
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

// These Buttons are not necessary for the same card according to this UI design because we have a single button of view details and user can get every thing there
