import React, { useMemo, useState, useEffect, useLayoutEffect, useRef, useContext } from "react";


const LoaderComponent = (props: any) => {
  const { message } = props
  const [myTime, setMyTime] = useState<boolean>(true)
  useEffect(() => {
    let controller = new AbortController();
    setTimeout(() => {
      setMyTime(false)
    }, 10000)
    return () => controller?.abort();
  }, [])
  return myTime
    ? <div className="text-center"><div className="lds-ripple"><div></div><div></div></div></div>
    : <div style={{
      display: 'flex',
      height: window.innerHeight,
      width: window.innerWidth,
      alignItems: 'center',
      justifyContent: 'center'
    }}><div onClick={() => {
      window.open('/home', '_self')
    }}>
        {message || `Please wait while we fetch data, or try again later..`}
      </div>
    </div>
}

export default LoaderComponent