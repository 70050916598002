import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'rc-slider';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import 'rc-slider/assets/index.css';

const MyVideoTrimmer = (props: any) => {
  const [url, setUrl] = useState('');
  const [duration, setDuration] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const playerRef = useRef<ReactPlayer>(null);
  const ffmpegRef = useRef(createFFmpeg({ log: true }));

  const handleStartChange = (value: number) => setStart(value);
  const handleEndChange = (value: number) => setEnd(value);

  const loadFFmpeg = async () => {
    if (!ffmpegRef.current.isLoaded()) {
      await ffmpegRef.current.load();
    }
  };

  const handleTrimAndDownload = async () => {
    await loadFFmpeg();
    const ffmpeg = ffmpegRef.current;

    // Download the video from YouTube (using a proxy service, or a backend)
    const videoFile = await fetchVideoFile(url);

    // Write the file to the virtual file system
    ffmpeg.FS('writeFile', 'input.mp4', await fetchFile(videoFile));

    // Run the ffmpeg command to trim the video
    await ffmpeg.run(
      '-i',
      'input.mp4',
      '-ss',
      `${start}`,
      '-to',
      `${end}`,
      '-c',
      'copy',
      'output.mp4'
    );

    // Read the trimmed file and create a download link
    const data = ffmpeg.FS('readFile', 'output.mp4');
    const trimmedBlob = new Blob([data.buffer], { type: 'video/mp4' });
    const downloadUrl = URL.createObjectURL(trimmedBlob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = 'trimmed-video.mp4';
    a.click();
  };

  const fetchVideoFile = async (youtubeUrl: string) => {
    // You need a backend service or a proxy to download the YouTube video.
    // Replace this with your own server URL that fetches the video.
    const response = await fetch(`/api/download?url=${youtubeUrl}`);
    return await response.blob();
  };

  return (
    <div style={{
      width: '100%'
    }}>
      <div>Trim and Download YouTube Video</div>

      {/* YouTube URL input */}
      <input
        type="text"
        placeholder="Enter YouTube URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />

      {/* Video Player */}
      <div style={{
        marginTop: '20px',
        // width: 300,
        // padding: 20
      }}>
        <ReactPlayer
          width="400px"  // Set the width in pixels
          height="300px" // Set the height in pixels
          ref={playerRef}
          url={url}
          controls
          onDuration={(dur) => setDuration(dur)}
        />
      </div>

      {/* Range Slider for Selecting Trim */}
      <div style={{ marginTop: '20px' }}>
        <div>Select Trim Range (Start: {start}s, End: {end}s)</div>
        <Slider
          range
          min={0}
          max={Math.floor(duration)}
          defaultValue={[0, Math.floor(duration)]}
          onChange={(values: any) => {
            setStart(values[0]);
            setEnd(values[1]);
          }}
        />
      </div>

      {/* Button to Trim and Download */}
      <button onClick={handleTrimAndDownload} style={{ marginTop: '20px' }}>
        Trim and Download
      </button>
    </div>
  );
};

export default MyVideoTrimmer;
