import React, { useState, useContext, useCallback } from 'react';
import {
  Link,
} from "react-router-dom";
import GoogleLogin from './Login/GoogleLogin';
// import FacebookLoginNew from './Login/FacebookLoginNew';

const SocialMedia = () => {

  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState<any>();

  const onLoginStart = useCallback(() => {
    alert('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    alert('logout success');
  }, []);

  const onLogout = useCallback(() => { }, []);


  return (
    <div className="d-flex text-center justify-content-center mb-2">
      <GoogleLogin />
      {/* <FacebookLoginNew/> */}
    </div>
  );
}

export default SocialMedia;
