import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext
} from "react";
import { Breadcrumb, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import DatePicker from "react-multi-date-picker";
import { UserContext } from '../App';
// import QRCode from "react-qr-code";
//https://www.npmjs.com/package/react-qr-code
import { gapi } from "gapi-script";
import moment from 'moment'
import Calendar from "react-calendar";
import {
  getCalendarEvents,
  getCalendarUsers,
  getCalendarSchedule,
  saveAppointment,
  handleCalendar,
  saveEventDB,
  handleRecurringDate,
  groupByNow,
  getAppointment,
  getRecurringDates,
  myColors
} from '../api'
// import { Navbar } from "react-bootstrap";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import AdvancedForms from "./AdvancedForms/AdvancedForms";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import {
  BrowserRouter,
  Link,
  Route,
  BrowserRouter as Router, Routes,
} from "react-router-dom";
import Menubar from "./Menubar";
import { EventForm } from "./EventForm";
import { TimeCard } from "./TimeCard";
import { CalendarCard } from "./CalendarCard";
import { HeaderMenu } from "./HeaderMenu";
import { SearchEventForm } from "./SearchEventForm";
import { MyLinks } from "./MyLinks";
import { SkillsForm } from "./SkillsForm";
import { SigninComponent } from "./SigninComponent";
import axios from "axios";
// import { CheckinComponent } from "./CheckinComponent";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: '#c1d8ff'
}



export const QRCodeScanner = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = '2' },
    // comments = [],
  } = myContext;
  const {
    host,
    setAction,
    formInput,
    setFormInput,
    myDates,
    eventId,
    action,
    data,
    setData,
    handleClick,
    appointments,
    setAppointments,
    timeSeries
  } = props

  const [code, setCode] = useState<any>(null);
  const [showDialog, setDiaglog] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [precScan, setPrecScan] = useState<any>("");
  const [selected, setSelected] = useState<any>("environment");
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const [value, setValue] = useState<any>("");



  // async function fetchData({ qr = "" }) {
  //   try {
  //     setProcessing(true);
  //     const result: any = await axios.get(
  //       `https://ucs-goma-backend.herokuapp.com/payement/scan?matricule=${qr}&forThisYear=1`
  //     );
  //     console.log("scanned code", qr);
  //     const { message, payement } = result.data;
  //     console.log(payement);
  //     if (!message) {
  //       setCode({
  //         text: payement?.matricule,
  //         identite: `${payement.nom} ${payement.postnom} ${payement.prenom}`,
  //         promotion: payement.auditoire,
  //         annee: payement.annee,
  //         frais: Number.parseFloat(payement.totalPayer),
  //         total: Number.parseFloat(payement.totalAPayer),
  //         recouvrement: "Premiere tranche",
  //         maxEncours: 800
  //       });
  //       // setPrecScan(null);
  //       setDiaglog(true);
  //     } else {
  //       setCode(null);
  //       setPrecScan(null);
  //       setErrorMessage(message);
  //       setDiaglog(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleScan = async (scanData: any) => {
    console.log(`loaded data data`, scanData);
    // if (scanData && scanData !== "" && !showDialog && !processing) {
    //   console.log(`loaded >>>`, scanData);
    //   // setPrecScan(scanData);
    //   await fetchData({ qr: scanData });
    // }
  };
  const handleError = (err: any) => {
    console.error(err);
  };
  return (
    <div className="App">
      <h1>Hello</h1>
      <h2>
        Scan QR Code:{precScan}
        {selected}
      </h2>
      <select onChange={(e) => setSelected(e.target.value)}>
        <option value={"environment"}>Back Camera</option>
        <option value={"user"}>Front Camera</option>
      </select>
      {showDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <div className="close">
              <button
                onClick={() => {
                  setCode(null);
                  setErrorMessage(null);
                  setDiaglog(false);
                  setProcessing(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            {errorMessage && (
              <div className="errorMessage">
                <h2>{errorMessage}</h2>
              </div>
            )}
            {/* {code && (
              <div className="description">
                <h4 className="title">Scan Result</h4>
                <div className="detail detail-first-child">
                  <h6 className="detail-header">Matricule :</h6>
                  <h6 className="detail-content green">{code.text}</h6>
                </div>
                <div className="detail">
                  <h6 className="detail-header">Identité :</h6>
                  <h6 className="detail-content">{code.identite}</h6>
                </div>
                <div className="detail">
                  <h6 className="detail-header">Pomotion :</h6>
                  <h6 className="detail-content">{code.promotion}</h6>
                </div>
                <div className="detail">
                  <h6 className="detail-header">Année Academique :</h6>
                  <h6 className="detail-content">{code.annee}</h6>
                </div>
                <div className="detail">
                  <h6 className="detail-header">Total payé :</h6>
                  <h6 className="detail-content red">
                    {code.frais} (USD,dollars americains)
                  </h6>
                </div>
                <div className="detail">
                  <h6 className="detail-header">Total prévu :</h6>
                  <h6 className="detail-content red">
                    {code.total} (USD,dollars americains)
                  </h6>
                </div>
                <div className="detail">
                  <h6 className="detail-header">Reste à payer :</h6>
                  <h6 className="detail-content red">
                    {code.total - code.frais} (USD,dollars americains)
                  </h6>
                </div>
                <div className="detail">
                  <h6 className="detail-header">Votre Situation :</h6>
                  <h6
                    className={
                      code.total <= code.frais
                        ? `detail-content green`
                        : "detail-content red small"
                    }
                  >
                    {code.total <= code.frais
                      ? "Eligible"
                      : "Vous etes en retard de payement"}
                  </h6>
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
      {/* {code && <h2>{code.text}</h2>} */}
      {/* {!showDialog && !processing && (
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={value}
          viewBox={`0 0 256 256`}
        />
      )} */}
    </div>
  );
};


