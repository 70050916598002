import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import {
  getCroppedImg,
} from "src/api";
import { ButtonL } from "../AppIndex";
// import getCroppedImg from "./cropImage";

const MyImageCropper = (props) => {
  const { imageSrc, onCropComplete } = props
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    const croppedImage = await getCroppedImg({ imageSrc, crop: croppedArea });
    onCropComplete(croppedImage); // Pass the cropped image to the parent or any handler
  };

  return (
    <div>
      <div style={{ position: "relative", width: "100%", height: 400 }}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropCompleteHandler}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => onZoomChange(Number(zoom))}
        />
        <ButtonL variant="contained" color="primary" onClick={handleCrop}>
          Crop Image
        </ButtonL>
      </div>
    </div>
  );
};

export default MyImageCropper;
