import { useState, useEffect, useRef, KeyboardEvent } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useLongPress } from "use-long-press";
import styled from "@emotion/styled";

import "../api";
import useLocalStorage from "use-local-storage";

const DirectMessageComponent = (props: any) => {
  const {
    handleSupport,
    directMsgGuest = {},
    deletDirectMessage,
    directMessages = [],
  } = props;
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");

  const { matches, myInfo = {}, urlParam } = localStore;
  const [showEdit, setShowEdit] = useState<any>(-1);
  const messageEl = useRef<any>(null);

  const fullWidth = window.screen.width;
  const fullHeight = window.screen.height;

  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event: any) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);

  const groupedDays: any = [];

  const bindPress = useLongPress((msg: any) => {
    console.log("Long pressed!");
    setShowEdit(showEdit == msg?._id ? -1 : msg?._id);
  });

  const swipe = (url: any) => {
    var largeImage: any = document.getElementById("largeImage");
    largeImage.style.display = "block";
    largeImage.style.width = 200 + "px";
    largeImage.style.height = 200 + "px";
    var url = largeImage.getAttribute("src");
    window.open(
      url,
      "Image",
      "width=largeImage.stylewidth,height=largeImage.style.height,resizable=1"
    );
  };

  return (
    <>
      <DirectMessageWrapper ref={messageEl}>
        {directMessages &&
          Array.isArray(directMessages) &&
          directMessages?.map((msg: any, i: number) => {
            const daysAgo = moment(msg?.date).fromNow();
            groupedDays.push(daysAgo);

            return (
              <div key={i + "ndsdf"}>
                <ConvoOld>
                  <span>
                    {groupedDays?.filter((res: any) => res == daysAgo)
                      ?.length == 1 &&
                      (daysAgo?.toLocaleLowerCase()?.includes("days") ||
                        daysAgo?.toLocaleLowerCase()?.includes("week") ||
                        daysAgo?.toLocaleLowerCase()?.includes("months")) &&
                      daysAgo}
                  </span>
                </ConvoOld>
                <MessageWrapper
                  isMe={msg?.handle == myInfo?.handle ? true : false}
                >
                  <MessagesWrapperChild
                    isMe={msg?.handle == myInfo?.handle ? true : false}
                  >
                    <Message
                      isMe={msg?.handle == myInfo?.handle ? true : false}
                    >
                      {showEdit == msg?._id && (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <i
                            onClick={() => {
                              let result = window.confirm(
                                "Are you sure you want to delete?"
                              );
                              let message = result
                                ? deletDirectMessage(msg?._id)
                                : "You clicked the Cancel button";
                            }}
                            onMouseLeave={() => {
                              setShowEdit(-1);
                            }}
                            className="fe fe-trash"
                          ></i>
                        </div>
                      )}
                      <span
                        onClick={() => {
                          setShowEdit(showEdit == msg?._id ? -1 : msg?._id);
                        }}
                        {...bindPress}
                      >
                        {msg?.message}
                      </span>
                      <Edge
                        isMe={msg?.handle == myInfo?.handle ? true : false}
                      />
                    </Message>
                    {msg?.picture && (
                      <div
                        className={`main-msg-wrapper p-0 ${
                          msg?.handle == myInfo?.handle ? "right" : "left"
                        }`}
                      >
                        <img
                          id="largeImage"
                          onClick={() => {
                            window.open(msg?.picture);
                          }}
                          alt=""
                          className="wd-100 ht-100 br-5"
                          src={
                            msg?.picture ||
                            `https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg`
                          }
                        />
                      </div>
                    )}

                    <MessageTime>
                      <span> {moment(msg?.date).format("hh.mm A")}</span>{" "}
                    </MessageTime>
                  </MessagesWrapperChild>
                </MessageWrapper>
              </div>
            );
          })}
      </DirectMessageWrapper>
    </>
  );
};

export default DirectMessageComponent;
const DirectMessageWrapper = styled.div`
  width: 100%;
  background-color: #dedede;
  height: 72vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;
const MessagesWrapperChild = styled.div<{ isMe: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: ${(props) => (props.isMe ? "flex-end" : "flex-start")};
`;
const ConvoOld = styled.div`
  width: 100%;
  display: flex;
  font-size: 12px;
  color: darkgray;
  justify-content: center;
  font-weight: 600;
`;

const MessageTime = styled.div`
  font-size: 10px;
  color: gray;
`;
const MessageWrapper = styled.div<{ isMe: boolean }>`
  width: 100%;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.isMe ? "flex-end" : "flex-start")};
`;
const Message = styled.div<{ isMe: boolean }>`
  max-width: 200px;
  width: fit-content;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  background-color: ${(props) => (props.isMe ? "#479d83" : "#fff")};
  color: ${(props) => (props.isMe ? "#fff" : "#000")};
  position: relative;
  span{
    width: 100%;
    height: 100%;
    text-align: start;
  }
`;
const Edge = styled.div<{ isMe: boolean }>`
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  bottom: 5px;
  background-color: ${(props) => (props.isMe ? "#479d83" : "#fff")};
  ${(props) => (props.isMe ? `right:-5px` : `left:-5px`)};
`;
