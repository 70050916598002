import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getEventLikesDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  handleSaveEventLikes,
  makeUpperCase,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";
import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  AttachMoney,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  EditCalendar,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  NewReleases,
  PlusOne,
  RemoveCircleOutline,
  RemoveRedEye,
  Share,
  ShoppingBag,
  ToggleOff,
  ToggleOn,
  Verified,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Rating, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { AdvertListItems } from "./AdvertListItems";
import { MyTabListItems } from "./MyTabListItems";
import { ButtonFilterTabs, EventEditor } from "./EventEditor";
import { HostEditor } from "./HostEditor";
import ChatBot from "./AIChatBot";

const _ = require("lodash");

// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export const UserProfileModal = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    openAds = false,
    eventInfo = {},
    openChat = false,
    allAccess,
    myLikes = [],
    editEvent,
  } = myContext;
  const { host, setOpenEventForm } = props;
  const params: any = useParams<any>();

  const navigate = useNavigate();

  const [searchKeywordAttendees, setSearchKeywordAttendees] =
    useState<string>("");
  const [searchKeywordEvents, setSearchKeywordEvents] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [hiddenTabs, setHiddenTabs] = useState<string[]>(
    host?.hiddenTabs || []
  );
  const location = useLocation();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);

  // const [activemenu, setActivemenu] = useState<any>("Team");

  const [allAds, setAllAds] = useState<any>([]);

  useEffect(() => {
    setHiddenTabs(host?.act || []);
  }, [host?.hiddenTabs]);

  const [guestProfileCategoresState, setGuestProfileCategoresState] = useState<
    string[]
  >([]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab, eventId, myInfo?.handle]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab, hostId, myInfo?.handle]);

  // function for the Tabs Filtering below
  const handleFilterCheckboxChange = (tabName: string, isChecked: boolean) => {
    const updatedActiveTabs = isChecked
      ? [...hiddenTabs, tabName]
      : hiddenTabs.filter((t) => t !== tabName);

    // Update local state immediately for UI responsiveness
    setHiddenTabs(updatedActiveTabs);

    // Prepare data for DB update
    const data = {
      query: {
        handle: host?.handle,
        // hiddenTabs: updatedActiveTabs,
        hiddenTabs: updatedActiveTabs,
      },
      resource: "calendar_users",
      check: ["handle"],
      myInfo,
    };

    // Update database in the background
    saveDataDB(data)
      .then((res) => {
        // console.log("Database update response:", res);
        setRefreshing(true);
      })
      .catch((error) => {
        console.error("Failed to update database", error);
        // Optionally revert UI changes or notify user
        setHiddenTabs(host?.hiddenTabs || []);
      });
  };

  useEffect(() => {
    if (!(host?.handle && hostTab)) {
      return;
    }
    const data = {
      query: {
        handle: host?.handle,
      },
      resource: `calendar_host_about`,
      check: ["handle"],
    };
    getDataDB(data).then((res: any) => {
      const result: any = res?.[0]?.industry && Object.keys(res?.[0]?.industry);

      if (result) {
        // console.log("hostTabsxxccvvv", result);
        setGuestProfileCategoresState(result || []);
        setSelectedGuestProfileCategory(result[0]);
      }

      setRefreshing(false);
    });
  }, [host?.handle, hostTab]);

  // Parameters are purely optional.

  // console.log("hostTabsxxccvvv", selectCount, allAds[selectCount], bannerImage);

  const [formInput, setFormInput] = useState<any>({});
  // const [selEvent, setSelEvent] = useState<any>(selectedEvent);
  const [localAction, setLocalAction] = useState<any>();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  // const [openAds, setOpenAds] = useState<boolean>(false);
  // const [viewSaved, setViewSaved] = useState<any>([]);
  // const [openChat, setOpenChat] = useState<boolean>(false);

  const [addProfCat, setAddProfCat] = useState<boolean>(false);
  const [selectedGuestProfileCategory, setSelectedGuestProfileCategory] =
    useState<string>("");

  const [isVisible, setIsVisible] = useState(false);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const model: any = useMemo(() => {
    return hostTabs?.filter((ref: any) => ref.name == eventTab)?.[0]?.model;
  }, [eventTab, hostTabs]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const resource = useMemo(() => {
    const tab = eventTab || hostTab;
    const subTab = eventSubTab || hostSubTab;
    return `calendar_${params.eventId ? "event" : "host"}${tab ? "_" + tab : ""
      }${subTab ? "_" + subTab : ""}`;
  }, [eventTab, eventSubTab, hostSubTab, hostTab, params?.eventTab]);

  const [myLike, setMyLike] = useState<boolean>(false);

  useEffect(() => {
    setMyLike(
      myLikes?.filter((res: any) => res.eventId == eventInfo?._id)?.[0]?.myLike
        ? true
        : false
    );
  }, [myLikes, eventInfo?._id, refreshing]);

  return (
    <>
      <BackDrop
        style={
          {
            // backgroundColor: 'red',
          }
        }
      >
        {openChat ? (
          <MessengerModal style={{ background: "white" }} isEvent={false}>
            <SupportersList
              {...props}
              directMsgGuest={host}
              openChat={openChat}
              setOpenChat={() => { }}
            />
          </MessengerModal>
        ) : null}
        {(eventTab || hostTab) && (
          <DetailModal isEvent={eventTab || hostTab ? true : false}>
            {openChat && (
              <MessengerModalMob>
                <SupportersList
                  {...props}
                  directMsgGuest={host}
                  openChat={openChat}
                  setOpenChat={() => { }}
                />
              </MessengerModalMob>
            )}
            {/* header */}
            <ModalHeader>
              <div>
                <ButtonFilterTabs
                  style={{
                    marginRight: 0,
                  }}
                  onClick={() => {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        // editInfo: !editInfo,
                        editEvent: "event",
                      };
                    });
                  }}
                >
                  <EditCalendar />
                </ButtonFilterTabs>
                {eventInfo?.verified ? (
                  <Verified
                    onClick={() => {
                      if (!myInfo?.handle) {
                        alert("Please login");
                        return;
                      }
                      if (!allAccess) {
                        return;
                      }
                      let text =
                        "Do you wish to request verification for this event or user";
                      if (window.confirm(text) == true) {
                        text = "You pressed OK!";
                        // setOpenSignin(true);

                        const data = {
                          query: {
                            verified: "request",
                            handle: myInfo?.handle,
                            eventId: eventId,
                          },
                          resource: `calendar_events`,
                          check: ["handle", "eventId"],
                          myInfo,
                        };
                        saveDataDB(data).then((res) => { });
                        return;
                      } else {
                        text = "You canceled!";
                        // setOpenSignin(true)
                        return;
                      }
                    }}
                    style={{
                      marginLeft: 20,
                      color: "orange",
                    }}
                  />
                ) : allAccess ? (
                  <NewReleases
                    onClick={() => {
                      let text = "";
                      if (window.confirm(text) == true) {
                        text = "Submit request for verification";
                        // setOpenSignin(true)
                        return;
                      } else {
                        text = "You canceled!";
                        // setOpenSignin(true)
                        return;
                      }
                    }}
                  />
                ) : null}
              </div>
              <span>
                {eventTab
                  ? (eventInfo?.eventType && makeUpperCase(eventInfo?.eventType)) || "Event Details" : "Guest Details"}
              </span>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <ShareButton>
                  <RR
                    data={{
                      text: "My GuestNet Profile",
                      url: `${resource}`,
                      title: `My GuestNet Profile`,
                    }}
                  >
                    <Share />
                  </RR>
                </ShareButton>

                {hostTab && (
                  <MessageIcon
                    style={{
                      color: openChat ? "#479d83" : "",
                    }}
                    onClick={() => {
                      setMyContext((existingItems: {}) => {
                        return {
                          ...existingItems,
                          openChat: true,
                          activeChatUser: host,
                        };
                      });
                    }}
                  />
                )}
                <HighlightOffIcon
                  onClick={() => {
                    navigate(-1);
                    setOpenEventForm(false);
                  }}
                />
              </div>
            </ModalHeader>

            {eventTab && (
              <EventHeaderWrapper>
                <EventBannerComponent {...props} />
                <TopEventInfoWrapper>
                  <EventTextInfo>
                    <h2>{eventInfo?.title || ""}</h2>
                    <p>
                      {eventInfo?.description || ""} This is the description to
                      test the line clamp so i am goin gto write as much text as
                      i can to check the line clamp is working or not because
                      this is the only way to look realistic and good
                    </p>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 10,
                      }}
                    >
                      <RemoveRedEye
                        style={{
                          display: "flex",
                          // fontSize: 18,
                          //   backgroundColor: myColors.brown,
                          padding: 4,
                          borderRadius: 100,
                          color: myLike ? "orange" : "gray",
                          // color: myLike ? "white" : "black",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          if (!myInfo?.handle) {
                            alert("Please login");
                            return;
                          }

                          const data = {
                            query: {
                              myLike: !myLike,
                              type: "event",
                              ...eventInfo,
                              eventId: eventInfo?._id,
                            },
                            resource: `calendar_event_likes`,
                            check: ["handle", "eventId"],
                            myInfo,
                          };
                          console.log("FormFields", data);

                          saveDataDB(data).then((res) => {
                            alert("Saved Like");
                            getEventLikesDB({
                              handle: myInfo?.handle,
                            }).then((res: any) => {
                              if (res?.[0])
                                setMyContext((existing: any) => {
                                  return {
                                    ...existing,
                                    myLikes: res,
                                  };
                                });
                              //setRefreshing(true);
                            });
                          });
                          // console.log('nnnnnn', item)
                          // return
                        }}
                      />{" "}
                      <ReviewsResult isSmall={true} {...props} />
                    </div>
                  </EventTextInfo>
                  {/* <SearhKeywordWrapper>
                    <EventKeySearch {...props} />
                  </SearhKeywordWrapper> */}
                </TopEventInfoWrapper>

                <EventEditor
                  {...props}
                  selectedEvent={selectedEvent}
                  setLocalAction={setLocalAction}
                  setSearchKeywordEvents={setSearchKeywordEvents}
                  searchKeywordAttendees={searchKeywordAttendees}
                  setSearchKeywordAttendees={setSearchKeywordAttendees}
                  searchKeywordEvents={searchKeywordEvents}
                  setOpenEventForm={setOpenEventForm}
                  // setOpenSignin={setOpenSignin}
                  selectedGuestProfileCategory={selectedGuestProfileCategory}
                  setSelectedGuestProfileCategory={
                    setSelectedGuestProfileCategory
                  }
                />
              </EventHeaderWrapper>
            )}

            {/* todo - update editor to be general for all tabs */}
            {(!eventTab && hostTab) && <HostEditor {...props} />}
          </DetailModal>
        )}
      </BackDrop>

    </>
  );
};

const ReviewsResult = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const navigate = useNavigate();

  const {
    // eventTab,
    host,
    myInfo,
    myInfo: { active = "2" },
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    openAds = false,
  } = myContext;
  const params: any = useParams<any>();
  const [dataRecord, setDataRecord] = useState<number>(0);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, hostId, myInfo?.handle]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  // const resource = useMemo(() => {
  //   return eventId ? "calendar_event_reviews" : "calendar_skills_record";
  // }, [eventId]);

  const query = eventId
    ? {
      eventId: eventId,
    }
    : {
      handle: host?.handle,
    };

  // useEffect(() => {

  //   getDataDB({
  //     query: {
  //       ...query,
  //     },
  //     resource: resource,
  //   }).then((info) => {
  //     if (info?.[0]) {
  //       const rating = _.sumBy(info, function (o: any) {
  //         return o.rating;
  //       });
  //       const avgRating = (rating * 1 * info?.length * 5) / (info?.length * 5);
  //       console.log('databasecall-1')
  //       setDataRecord(Math.round(avgRating));
  //     }
  //   });
  // }, [eventId, host?.handle]);

  // console.log("vvvvvyyy", dataRecord);

  return (
    <div
      onClick={() => {
        if (eventId) {
          navigate(`/event/${eventId}/reviews/add`);
        } else if (hostId) {
          navigate(`/event/${hostId}/about`);
        }
      }}
    >
      <Rating
        style={{
          fontSize: props.isSmall ? 14 : 30,
        }}
        name="size-large"
        defaultValue={dataRecord * 1}
        value={dataRecord}
        size="small"
        disabled={true}
        onChange={(e: any) => { }}
      />
    </div>
  );
};

const EventBannerComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);

  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    openAds = false,
  } = myContext;
  const { host, setOpenEventForm, setOpenSignin } = props;
  const [allAds, setAllAds] = useState<any>([]);
  const [viewSaved, setViewSaved] = useState<any>([]);
  const intervalId: any = useRef(null);
  const [count, setCount] = useState<number>(0);
  const [selectCount, setSelectCount] = useState<number>(0);
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const location = useLocation();

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab, hostId, myInfo?.handle]);

  useEffect(() => {
    if (!host?.handle && hostTab) {
      return;
    }
    //get my adverts
    const myAds = {
      query: {
        handle: myInfo?.handle,
      },
      resource: `calendar_event_advert_accept_package`,
      check: ["handle"],
    };
    getDataDB(myAds).then((res: any) => {
      // setAllAds
      const myAds: any = res?.map((res: any) => {
        // console.log("hostTabsxxccvvv", res);

        return {
          picture: res.picture,
          _id: res._id,
        };
      });

      setAllAds([
        {
          _id: "6640d5c06355a846e22e8e5g",
          picture:
            selectedEvent?.picture ||
            selectedEvent?.image ||
            selectedEvent?.img ||
            "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
        },
        ...myAds,
      ]);
    });
  }, [hostTab, host?.handle]);

  const [bannerImage, setBannerImage] = useState<any>({
    picture:
      selectedEvent?.picture ||
      selectedEvent?.image ||
      selectedEvent?.img ||
      "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
  });

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);

  useEffect(() => { });

  useEffect(() => {
    if (!viewSaved?.includes(allAds[selectCount]?._id)) {
      saveAds();
    }
  }, [viewSaved, selectCount, allAds]);

  const saveAds = useCallback(() => {
    const rep: any = [];
    const reg: any = myInfo?.industry;
    myInfo?.industry &&
      Object.keys(myInfo?.industry)?.map((res: any) => {
        [res, ...reg?.[res]]?.map((ret) => {
          rep.push(ret);
        });
      });
    // console.log("formDatacc2", allAds);

    if (!(allAds?.[selectCount]?._id && myInfo?.handle)) {
      return;
    }
    // console.log("selectedEventsss", viewSaved);

    const data = {
      query: {
        advertId: allAds[selectCount]?._id,
        picture: allAds[selectCount]?.picture,
        name: myInfo?.name,
        handle: myInfo?.handle,
        like: true,
        skills: rep || [],
        eventId: eventId,
        host: hostId,
      },
      resource: `calendar_advert_views`,
      check: ["handle", "advertId"],
      myInfo,
    };
    saveDataDB(data).then((res) => {
      // alert(allAds[selectCount]?._id)

      setViewSaved([allAds[selectCount]?._id, ...viewSaved]);
    });
  }, [selectCount, allAds, eventId, myInfo?.handle]);

  useEffect(() => {
    console.log("formDatacc2yy", allAds[selectCount]?._id);
    if (allAds && allAds?.length > 0) startInterval();
  }, [allAds]);

  // console.log("hostTabsxxccvvv", allAds);

  const startInterval = useCallback(() => {
    intervalId.current = setInterval(() => {
      if (count < 5) {
        setCount((count) => count + 1);
      } else {
        clearInterval(intervalId.current);
      }
    }, 2000);
  }, [count, allAds]);

  useEffect(() => {
    // console.log("hostTabsxxccvvv3", selectCount, allAds, allAds?.length);
    if (selectCount < allAds?.length) {
      setSelectCount(selectCount + 1);
    } else {
      setSelectCount(0);
    }
  }, [count]);

  useEffect(() => {
    // console.log("hostTabsxxccvvv", selectCount, allAds, allAds?.length);

    if (allAds[selectCount]?.picture) setBannerImage(allAds[selectCount]);
  }, [selectCount, allAds]);

  return (
    <EventTopBannarWrapper>
      <button
        onClick={() => {
          if (bannerImage?._id) {
            navigate(`/advert/${bannerImage?._id}`, {
              state: {
                backUrl: location?.pathname,
              },
            });
          }
        }}
      >
        <AttachMoney />
      </button>

      <EventImage src={bannerImage?.picture} />
    </EventTopBannarWrapper>
  );
};

export const EventKeySearch = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    openAds = false,
  } = myContext;
  const {
    host,
    setOpenEventForm,
    setOpenSignin,
    formQuestion,
    setFormQuestion,
    setSearchNow,
    searchNow,
  } = props;
  const [allAds, setAllAds] = useState<any>([]);
  const [viewSaved, setViewSaved] = useState<any>([]);
  const intervalId: any = useRef(null);
  const [count, setCount] = useState<number>(0);
  const [selectCount, setSelectCount] = useState<number>(0);
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const [formInput, setFormInput] = useState<any>({});
  // const [formQuestion, setFormQuestion] = useState<any>('');

  return (
    <InputAgenda>
      <input
        type={"text"}
        onChange={(e: any) => setFormQuestion(e.target?.value)}
        value={formQuestion || ""}
        placeholder={"What do you wish to do?"}
        required
      />
      <div>
        <Search
          onClick={() => {
            setSearchNow(!searchNow);
          }}
        />
      </div>
    </InputAgenda>
  );
};

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  position: relative;
  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

export const MessengerModal = styled(DetailModal)`
  @media (max-width: 756px) {
    display: none;
  }
`;
export const MessengerModalMob = styled.div`
  display: none;
  @media (max-width: 756px) {
    display: flex;
    inset: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    border-radius: 8px;
    padding: 24px;
    background-color: white;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

const EventImage = styled.img`
  height: 150px;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const EventTopBannarWrapper = styled.div`
  width: 100%;
  position: relative;

  button {
    border: none;
    background: gray;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    color: white;
    transition: color 0.3s;
    margin: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background-color: #dee2e6;
    }
  }
`;

const EventHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h2,
  p {
    margin: 0;
  }
`;
const EventTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  max-width: 200px;
  h2 {
    font-size: 22px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: gray;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  }
`;
const TopEventInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 50px;
  justify-content: space-between;
`;
export const SearhKeywordWrapper = styled.div`
  max-width: 200px;
  width: 100%;
  margin-bottom: 20px;
`;
