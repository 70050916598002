import React, { useContext } from "react";
import "./Sidebar.scss";

import { Avatar } from "@mui/material";
import { UserContext } from "../../App";
import { NavLink, useLocation } from "react-router-dom";

/**
 * Responsible for displaying the Sidebar
 */
const Menubar = (props) => {
  const { pathname } = useLocation();
  const { menuStatus } = useContext(UserContext);

  return (
    <>
      {menuStatus?.isMenuOpen && (
        <div className="sideNav-container">
          <section className="sideNav-section">
            <Avatar
              alt={menuStatus?.name || "NA"}
              src="/static/images/avatar/1.jpg"
              sx={{ width: 30, height: 30 }}
              style={{
                backgroundColor: "#3399cc",
              }}
            />
            <div
              style={{
                paddingLeft: 10,
                textAlign: "left",
              }}
            >
              <div style={{ fontSize: 15 }}>{menuStatus?.name}</div>
              <div style={{ fontSize: 10 }}>{menuStatus?.email}</div>
            </div>
          </section>
          <section className="sideNav-section">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {menuStatus?.menu.map((res, i) => {
                return (
                  <div
                    key={i + "shewwywe"}
                    onClick={res?.route}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingBottom: 20,
                    }}
                  >
                    {res?.icon}
                    <div
                      style={{
                        paddingLeft: 10,
                        textAlign: "left",
                      }}
                    >
                      <div style={{ fontSize: 15 }}>{res?.item}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      )}
    </>
  );
};
export default Menubar;
