import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { handleInfo } from ".";
// import { IconButton } from 'material-ui';




export const AdvertCarroussel = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { data } = props;
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    host,
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
  } = myContext;
  const {
    searchKeywordAttendees,
    setSearchKeywordAttendees,
    setLocalAction,
    handleDeleteItem,
  } = props;
  const params: any = useParams<any>();

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);
  // console.log('dataxxx', data)
  // const { searchKeywordAttendees, setSearchKeywordAttendees } = props
  return (
    <Col>
      <Col>
        {data?.length > 0 && <InputAgenda>
          <input
            type="text"
            value={searchKeywordAttendees}
            onChange={(e: any) => setSearchKeywordAttendees(e.target.value)}
            placeholder="Search Ads ..."
          />
          <button>
            <Search />
          </button>
        </InputAgenda>}
        <br />
        <AdvertCarrousselListCard
          {...props}
          host={host}
          eventId={eventId}
          searchKeywordAttendees={searchKeywordAttendees}
          people={data?.filter(
            (event: any) =>
              event?.title
                ?.toLowerCase()
                .includes(searchKeywordAttendees.toLowerCase()) ||
              event?.name
                ?.toLowerCase()
                .includes(searchKeywordAttendees.toLowerCase()) ||
              event?.room
                ?.toLowerCase()
                .includes(searchKeywordAttendees.toLowerCase()) ||
              event?.title
                ?.toLowerCase()
                .includes(searchKeywordAttendees.toLowerCase())
          )}
          handleDeleteItem={handleDeleteItem}
          handleInfo={(info: any) => {
            handleInfo({ info, myInfo }).then((res: any) => {
              // setRefreshing(false);
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  eventDetails: res,
                };
              });
            });
          }}
          setLocalAction={setLocalAction}
          isSmall={true}
        />
      </Col>
    </Col>
  );
};



export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;
