import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  getDataDB,
  getDataWildDB,
  getUserInfoByHandle,
  saveDataDB,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  UploadFile,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { MyFileUploader } from "./MyFileUploader";
import { AddItemCarrousselCard } from "../AddItemCarrousselCard";
import { ImportDataComponent } from "./ImportDataComponent";
// import { IconButton } from 'material-ui';
const _ = require("lodash");

export const CollaboratorComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { type, resource } = props;
  const params: any = useParams<any>();

  // const [editInfo, setEditInfo] = useState<any>({});

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { editInfo, myInfo, host, hostTabs, eventInfo = {} } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  // const resource = useMemo(() => {
  //   return `calendar_events`;
  // }, []);

  useEffect(() => {
    (async () => {
      if (!eventId) {
        return;
      }
      const eventInfoLatest: any = await getDataDB({
        query: {
          _id: eventId,
        },
        resource: 'calendar_events',
      }).then(ref => {
        if (!eventInfo?.handle) {
          setMyContext((existing: any) => {
            return {
              ...existing,
              eventInfo: eventInfoLatest
            };
          });
        }
        return ref?.[0]
      })




      const event: any = await getDataDB({
        query: {
          handle: eventInfoLatest?.handle,
        },
        resource: 'calendar_users',
      }).then(ref => ref?.[0])

      // console.log('calendar_users', eventInfo?.handle, event)
      // if (!eventInfo?.handles?.[0]) {
      //   return;
      // }
      // console.log("hostTabsxxccnnbb", eventInfo);
      // const event = [eventInfo];
      if (!event?.handles) {
        return
      }
      const peps: any = await Promise.all(
        event?.handles?.map(async (hand: any) => {
          return await getUserInfoByHandle(hand).then((user: any) => {
            console.log("hostTabsxxccnn", user);

            if (!user) {
              return {};
            }
            return {
              ...user,
              active: true,
              eventInfo: event,
              eventId: event?.[0]?._id,
              title: user?.name,
              description: event?.[0]?.title,
              handle: user?.handle,
              link: `/host/${user?.handle}/about`,
              role: "collaborator",
            };
          });
        })
      );

      setDataRecord([...peps]);
      setRefreshing(false)

    })();
  }, [
    refreshing,
    eventId,
    refreshing,
    eventTab, eventSubTab
  ]);

  const myData = useMemo(() => {
    if (!dataRecord?.[0]) {
      return [];
    }
    return [
      ...dataRecord
        // ?.filter((res: any) => res.title != formInput?.title)
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title,
            // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          };
        }),
    ];
  }, [dataRecord]);



  useEffect(() => {
    if (myData?.[0])
      setMyContext((existing: any) => {
        return {
          ...existing,
          myStory: myData
        };
      });
  },
    [myData])

  const [searchKeywordAttendees, setSearchKeywordAttendees] =
    useState<any>(null);

  useEffect(() => {
    if (!formInput?.title || !(formInput?.title?.length > 0)) {
      return;
    }
    // _.debounce(() => {
    setTimeout(() => {
      if (!(formInput?.title?.length > 0)) {
        return;
      }
      const data = {
        key: "name",
        request: "wildsearch",
        searchVal: formInput?.title,
        resource: `calendar_users`,
        limit: 400,
      };
      const data_2 = {
        key: "email",
        request: "wildsearch",
        searchVal: formInput?.title,
        resource: `calendar_users`,
        limit: 400,
      };

      getDataWildDB(data)
        .then((res: any) => {
          // console.log('formFieldxx44', res)
          if (res?.[0])
            return res?.map((person: any) => {
              return {
                link: `https://www.myguestnet.com/host/${person?.handle}`,
                img:
                  person.img ||
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                title: person.name,
                handle: person?.handle,
              };
            });
        })
        .then(async (data_by_name) => {
          const data_by_id = await getDataWildDB(data_2).then((res: any) => {
            // console.log('formFieldxx44', res)
            if (res?.[0]) {
              return res?.map((person: any) => {
                return {
                  link: `https://www.myguestnet.com/host/${person?.handle}`,
                  img:
                    person.img ||
                    "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                  title: person.name,
                  handle: person?.handle,
                };
              });
            } else {
              return [];
            }
          });

          const NewData = [...(data_by_name || []), ...(data_by_id || [])];
          // console.log("data_by_name", data_by_name);

          // console.log("names88", NewData);
          setDataRecord(NewData);
        });
    }, 500);
  }, [formInput?.title]);
  const navigate = useNavigate();

  return (
    <>
      <div>
        {editInfo && (
          <>
            <EditSkillsModalWrapper>
              <AddSkillSection>
                <h2>Add Collaborator</h2>
                <SkillChildWrapper>
                  <InputAgenda onSubmit={(e: any) => { }}>
                    <input
                      type={"text"}
                      onChange={(e: any) => {
                        // alert(e.target?.value)
                        setFormInput({
                          ...formInput,
                          title: e.target?.value,
                        });
                      }}
                      value={formInput?.title || ""}
                      placeholder={"Add collaborators ..."}
                      required
                    />
                  </InputAgenda>
                </SkillChildWrapper>
              </AddSkillSection>
              <ImportDataComponent
                {...props}
                resource={resource}
                topic={formInput?.topic}
              />
            </EditSkillsModalWrapper>
          </>
        )}

        <div
          style={{
            paddingTop: 50,
          }}
        >
          <AddItemCarrousselCard
            host={host}
            eventId={eventId}
            searchKeywordAttendees={searchKeywordAttendees}
            people={myData || []}
            handleViewDetails={(item: any) => {

              setMyContext((existing: any) => {
                return {
                  ...existing,
                  action: false,
                };
              });
              // handleInfo(item.handle);
              window.open(`/host/${item.handle}/about`, "_blank");

            }}
            handleAdd={(info: any) => {
              // return
              console.log("handleInfo", info);

              if (!(eventId && eventInfo?.handle)) {
                return;
              }

              const dataT: any = eventInfo?.handles?.[0]
                ? eventInfo?.handles?.filter((reg: any) => reg != info?.handle)
                : [];

              const addInfo = [info?.handle, ...dataT];


              saveDataDB({
                query: {
                  handle: myInfo?.handle,
                  title: eventInfo?.title,
                  handles: addInfo,
                  eventId: eventId,
                },
                resource: "calendar_events",
                check: ["handle", "eventId"],
                myInfo,
              })
                .then(async (res) => {
                  return await saveDataDB({
                    query: {
                      handle: myInfo?.handle,
                      handles: addInfo,
                    },
                    resource: "calendar_users",
                    check: ["handle", "eventId"],
                    myInfo,
                  }).then(ref => {
                    alert('Saved')
                    setRefreshing(true);
                  })
                })
                .catch((error) => {
                  console.error("Failed to update database", error);
                });
            }}
            handleRemove={(info: any) => {
              console.log("handleInfo", info);

              if (!(eventId && eventInfo?.handle)) {
                return;
              }
              const dataT: any = eventInfo?.handles?.[0]
                ? eventInfo?.handles?.filter((reg: any) => reg != info?.handle)
                : [];

              const addInfo = [...dataT];

              const data = {
                query: {
                  handle: info?.handle,
                  title: eventInfo?.title,
                  handles: addInfo,
                },
                resource: "calendar_events",
                check: ["handle", "title"],
                myInfo,
              };

              saveDataDB(data)
                .then(async (res) => {
                  return await saveDataDB({
                    query: {
                      handle: myInfo?.handle,
                      handles: addInfo,
                    },
                    resource: "calendar_users",
                    check: ["handle", "eventId"],
                    myInfo,
                  }).then(ref => {
                    alert('Saved')
                    setRefreshing(true);

                  })
                })
                .catch((error) => {
                  console.error("Failed to update database", error);
                });
            }}
            // setLocalAction={setLocalAction}
            isSmall={true}
          />
        </div>
      </div>
    </>
  );
};

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const LinkParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
`;

const AddSkillSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

const UploadedResume = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  p {
    font-size: 12px;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    width: 15px;
    height: 100%;
    color: #479d83;
  }
  button {
    border: 1px solid #479d83;
    padding: 3px 8px;
    background: #fff;
    font-size: 12px;
    transition: background 0.3s;
    margin: 0;
    &:hover {
      background: #479d832b;
    }
  }
`;

const UploadResumeFile = styled.label`
  background: #479d832b;
  border: 1px dashed gray;
  width: 100%;
  padding: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 10px;
    color: gray;
    margin: 0;
  }
  svg {
    width: 40px;
    color: #479d83;
    height: 100%;
  }
`;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
