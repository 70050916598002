import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select/creatable";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "../../App";
import {
  apiFetchData,
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  groupByNow,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  Sync,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { AddSkillSection, SkillChildWrapper, TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { AdvertListItems } from "./AdvertListItems";
import { MyTabListItems } from "./MyTabListItems";
import { AboutTabDetails } from "./AboutTabDetails";
// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export const CatalogItems = (props: any) => {
  const {
    resource
  } = props
  const { myContext, setMyContext } = useContext<any>(UserContext);
  // const { editInfo } = props
  // const { handleInfo } = props;
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [allDataRecord, setAllDataRecord] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);



  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    host,
    // eventId,
    editInfo,
    hostId,
    cart = [],
    selectedEvent,
    people,
    eventTabs = [],
    allAccess
  } = myContext;

  const location = useLocation();
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [dataTopic, setDataTopic] = useState<any>([]);

  const [products, setProducts] = useState<any>([
    // {
    //   title: "T Shirt",
    //   cost: "$1000.00",
    //   location: "online",
    //   unit: 0,
    //   img: "https://myborosil.com/cdn/shop/files/my-borosil-stainless-steel-bottles-trek-black-personalise-32329717710986.gif?v=1685518005",
    // },
  ]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // useEffect(() => {
  //   getDataDB({
  //     query: {
  //       eventId: eventId,
  //     },
  //     resource: resource
  //   }).then((info) => {
  //     const data = info?.map((res: any) => {
  //       return {
  //         ...res,
  //         // title: res?.title,
  //         // cost: res.cost,
  //         // location: res.location,
  //         img:
  //           res?.img ||
  //           "https://myborosil.com/cdn/shop/files/my-borosil-stainless-steel-bottles-trek-black-personalise-32329717710986.gif?v=1685518005",
  //       };
  //     });
  //     setProducts([...data]);
  //   });
  // }, [
  //   refreshing,
  //   eventTab,
  //   eventSubTab,
  //   hostSubTab,
  //   params?.eventTab,
  //   editInfo
  // ]);

  useEffect(() => {
    if (!resource) {
      return
    }
    setFormInput({})

    const data = {
      query: {
        ...(eventId
          ? {
            eventId: eventId,
          }
          : {}),
        handle: host?.handle,
      },
      resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
      check: ["handle"],
    };
    getDataDB(data).then(async (res) => {
      // console.log("hostTabsxxccnn", query, res);
      const apiData: any = []
      if (!res?.[0]) {
        return
      }
      await Promise.all(res?.map(async (reg: any) => {
        if (reg?.apiRoute) {
          // console.log("apiDataxxxxvvvv", reg?.apiPostType);

          return apiFetchData({
            endPoint: reg?.apiRoute,
            apiPostType: res?.apiPostType || 'GET',
            skip: 0,
            limit: 10
          }).then((reh: any) => {
            // console.log("apiDataxxxxvvvv", reh);

            return reh?.map((red: any) => {
              const temp: any = {}

              Object.keys(reg)?.map(ref => {
                if (reg[ref]) {
                  temp[ref] = reg[ref]
                }
              })
              Object.keys(red)?.map(ref => {
                if (red[ref]) {
                  temp[ref] = red[ref]
                }
              })

              apiData.push({
                ...temp,
                ...(host?.img ? { img: host?.img } : {})

              })
            })
          })
          // apiRoute
        }
      }))
      // console.log("apiDataxxxx", apiData);

      const resultData = [...(apiData || []), ...(res?.[0] ? res : [])]
      setDataRecord(resultData);
      setAllDataRecord(resultData);
      const grouped = groupByNow({
        data: resultData,
        key: "topic",
        id: 2,
      });
      const groupedInfo = grouped && Object.keys(grouped);
      // console.log("hostTabsxxccnn", groupedInfo);

      groupedInfo?.[0] && setDataTopic(groupedInfo);
      setRefreshing(false);
      // setFormInput({});
    });
  }, [
    loading,
    host?.handle,
    eventId,
    eventTab,
    eventSubTab,
    myInfo?.handle,
    params.hostId,
    refreshing,
    resource
  ]);

  const myData = useMemo(() => {
    return [
      ...dataRecord];
  }, [
    dataRecord
  ]);


  // useEffect(() => {
  //   if (!editInfo) {
  //     setLoading(false)
  //     // setFormInput({})
  //   }
  // }, [
  //   editInfo
  // ])

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: 'auto',
          gap: 5,
          width: windowWidth * .9
        }}
      >
        <Sync
          style={{
            backgroundColor: myColors?.brown,
            marginBottom: 20,
            color: 'white'
          }}
          onClick={() => {
            window.location.reload();
          }}
        />

        <ButtonL
          style={{
            backgroundColor: !formInput?.topic
              ? myColors?.brown
              : myColors?.green,
            marginBottom: 20,
          }}
          onClick={() => {
            setDataRecord(allDataRecord);
            setFormInput({
              // ...formInput,
              topic: "",
            });
          }}
        >
          All
        </ButtonL>

        {dataTopic?.[0] &&
          dataTopic?.map((topic: any, index: number) => {
            // const rep = allDataRecord?.filter((ref: any) => {
            //   return ref?.title == res?.title
            // })
            // console.log('vvvvdee', res)
            return (
              <ButtonL
                style={{
                  whiteSpace: "nowrap",       /* Prevent text wrapping */
                  // overflow: "hidden",          /* Hide overflow */
                  textOverflow: "ellipsis",    /* Add ellipsis when text overflows */
                  // whiteSpace: "none",
                  backgroundColor:
                    formInput?.topic == topic
                      ? myColors?.brown
                      : myColors?.green
                }}
              >
                <div
                  onClick={() => {
                    // setFormInput(res)
                    // setRecordKey(res?.title)
                    const data: any = [...allDataRecord]?.filter(
                      (reh) => reh.topic == topic
                    );
                    setDataRecord(data);
                    setFormInput({
                      // ...formInput,
                      topic: topic,

                    });
                  }}
                >
                  {" "}
                  {!topic || topic == "undefined" ? "Default" : topic}
                </div>
                {allAccess && topic && (
                  <RemoveCircleOutline
                    style={{
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      let text = "Sure you want to delete?";
                      const item = allDataRecord?.filter(
                        (reh: any) => reh.topic == topic
                      )?.[0];

                      if (window.confirm(text) == true) {
                        deleteDataDB({
                          id: item?._id,
                          resource: resource,
                        }).then((res) => {
                          setRefreshing(true);
                        });
                        text = "You pressed OK!";
                      } else {
                        text = "You canceled!";
                      }
                    }}
                  />
                )}
              </ButtonL>
            );
          })}
      </div>
      {editInfo && (
        <>
          <AddNewContent
            {...props}
            dataTopic={dataTopic}
            setDataTopic={setDataTopic}
            setFormInput={setFormInput}
            formInput={formInput}
            uploadNow={uploadNow}
            setUploadNow={setUploadNow}
          />
          <div>Unit Price</div>
          <InputAgenda>
            <input
              type={"number"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  price: e.target?.value,
                  cost: e.target?.value,
                })
              }
              value={formInput?.price || ""}
              placeholder={"1.10"}
              required
            />
            <div
              style={{
                width: 100,
              }}
            ></div>
          </InputAgenda>
          <div>Unit Discount</div>
          <InputAgenda>
            <input
              type={"number"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  discount: e.target?.value,
                })
              }
              value={formInput?.discount || ""}
              placeholder={"0"}
              required
            />
            <div
              style={{
                width: 100,
              }}
            ></div>
          </InputAgenda>
          <div>Quantity</div>
          <InputAgenda>
            <input
              type={"number"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  quantity: e.target?.value,
                })
              }
              value={formInput?.quantity || ""}
              placeholder={"1"}
              required
            />
            <div
              style={{
                width: 100,
              }}
            ></div>
          </InputAgenda>
          <div>Commission Rate</div>
          <InputAgenda>
            <input
              type={"number"}
              max={100}
              min={0}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  rate: e.target?.value,
                })
              }
              value={formInput?.rate || ""}
              placeholder={"0"}
              required
            />
            <div
              style={{
                width: 100,
              }}
            ></div>
          </InputAgenda>
          <div style={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'row',
            gap: 20
          }}>
            <ButtonL onClick={() => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  editInfo: false,
                };
              });
            }}>
              Close
            </ButtonL>
            {formInput?._id && (
              <ButtonL

                disabled={
                  formInput?.title
                    ? // addType?.file ||
                    // addType?.link ||
                    // formInput?.file ||
                    // formInput?.link
                    false
                    : true
                }
                style={{
                  margin: 0,
                  marginRight: 20,
                  backgroundColor: 'orange'
                }}
                onClick={() => {
                  if (!myInfo?.handle) {
                    alert("Please login");
                    return;
                  }

                  let text = "Are you sure you wand to delete";
                  if (window.confirm(text) == true) {
                    text = "You pressed OK!";
                  } else {
                    text = "You canceled!";
                    return;
                  }
                  const data = {
                    id: formInput?._id,
                    resource: resource,
                  };
                  // console.log('formData', data)
                  deleteDataDB(data).then((res) => {
                    setUploadNow(true);
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });
                  });
                }}
              >
                Delete
              </ButtonL>
            )}
            <ButtonL
              style={{
                margin: 0,
              }}
              onClick={() => {
                // setUploadNow(true);
                if (!formInput?.topic) {
                  alert("Please make sure to add a Topic");
                  return;
                }
                if (!myInfo?.handle) {
                  alert("Please login");
                  return;
                }
                if (!formInput?.title) {
                  alert("Title required");
                  return;
                }
                const data = {
                  query: {
                    ...formInput,
                    ...(!formInput?.quantity ? {
                      quantity: 1
                    } : {}),
                    ...(!formInput?.unit ? {
                      unit: 1
                    } : {}),
                    eventId: eventId || params.eventId,
                    handle: myInfo?.handle,
                  },
                  resource: resource,
                  check:
                    // formInput?._id ? ["_id"] :
                    ["handle", "title"],
                  myInfo,
                };
                // console.log('formData', data)
                saveDataDB(data).then((res) => {
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      editInfo: false,
                    };
                  });
                  setTimeout(() => {
                    setRefreshing(true)
                  }, 100);
                  // alert('Content Saved')
                });
              }}
            >
              {refreshing ? 'Loading' : "Save"}
            </ButtonL>
          </div>
        </>
      )}

      <Col
        style={{
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {myData?.[0] && myData?.map((res: any) => {
          return (
            <div
              style={{
                marginTop: 15,
                borderStyle: 'solid',
                borderColor: 'lightgray',
                borderWidth: 1,
                borderRadius: 4,
                display: "flex",
                flexDirection: "row",
                position: 'relative',
                height: "100%",
                width: "100%",
                gap: 20,
                // justifyContent: 'space-evenly',
                alignItems: "center",
              }}
            >
              <div style={{
                position: 'absolute',
                top: 20,
                right: 20
              }}> <EditNote
                  onClick={() => {
                    setFormInput({
                      ...formInput,
                      ...res
                    })
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: true,
                      };
                    });
                  }}
                /></div>
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                }}
              >
                <img
                  src={res.img}
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "cover",
                  }}
                  alt=""
                />


              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: 'center',
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{
                    fontSize: 16,
                    color: "black",
                    fontWeight: 900
                  }}>{res.title}</div>
                  <h4
                    style={{
                      fontSize: 16,
                      color: "gray",
                      fontWeight: 900,
                    }}
                  >
                    {res.cost || "TBD"} / {res.unit || 1} Unit
                  </h4>


                  <h4
                    style={{
                      fontSize: 16,
                      color: "gray",
                      fontWeight: 900,
                    }}
                  >
                    Available: {res.quantity || 1}
                  </h4>
                </div>
                {/* <ButtonL
                  onClick={() =>
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        cart: [
                          ...cart,
                          {
                            img: res.img,
                            name: res.title,
                            price: res.cost,
                          },
                        ],
                      };
                    })
                  }
                  style={{ margin: 0 }}
                >
                </ButtonL> */}
              </div>

            </div>
          );
        })}
      </Col >
    </>
  );
};

const AddNewContent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    editInfo,
    resource,
    setFormInput,
    formInput,
    dataTopic,
    setDataTopic,
    uploadNow, setUploadNow
  } = props;
  // const { handleInfo } = props;
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  // const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  // const [uploadNow, setUploadNow] = useState<boolean>(false);

  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    hostId,
    cart = [],
    selectedEvent,
    people,
    eventTabs = [],
  } = myContext;

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);


  console.log('xxformInput', formInput)


  return (
    <>
      <AddSkillSection>
        <h2>
          Topic{" "}
          <span style={{ color: "red" }}>(Required)</span>
        </h2>
        <SkillChildWrapper>
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                color: 'black'
                //  width: windowWidth * .8 
              }),
            }}
            defaultValue={formInput?.topic ?
              {
                label: formInput?.topic,
                value: formInput?.topic,
              }
              : {
                label: 'Default',
                value: 'Default',
              }}
            onChange={(e: any) => {
              // console.log("personinfo2", e);
              // setSelectedTopic(e);
              if (e?.value)
                setFormInput({
                  ...formInput,
                  topic: e.value,
                });
            }}
            isClearable
            options={
              dataTopic && dataTopic?.[0] != "undefined"
                ? dataTopic?.map((res: any) => {
                  return {
                    label: res,
                    value: res,
                  };
                })
                : []
            }
            placeholder={'Add or Select Topic'}
            classNamePrefix="selectform"
          />
        </SkillChildWrapper>
      </AddSkillSection>
      <div>Title</div>
      <div>
        <InputAgenda onSubmit={(e: any) => { }}>
          <input
            type={"text"}
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                title: e.target?.value,
              })
            }
            value={formInput?.title || ""}
            placeholder={"Add title ..."}
            required
          />
          {!(
            addType?.file ||
            addType?.link ||
            formInput?.file ||
            formInput?.link
          ) && (
              <div
                onClick={() => {
                  const data = {
                    query: {
                      ...formInput,
                      eventId: eventId,
                    },
                    resource: resource, //`calendar_${params.eventId ? "event" : "host"
                    // }_${eventTab}`,
                    check: formInput?._id ? ["_id"] : ["handle", "title"],
                    myInfo,
                  };
                  // console.log('formData', data)
                  saveDataDB(data).then((res) => {
                    // setRefreshing(true);
                  });
                }}
              >
                {/* <Add /> */}
              </div>
            )}
        </InputAgenda>
        <div>Description</div>
        <InputAgenda onSubmit={(e: any) => { }}>
          <input
            type={"text"}
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                description: e.target?.value,
              })
            }
            value={formInput?.description || ""}
            placeholder={"Add description ..."}
            required
          />
        </InputAgenda>
        <InputAgenda>
          <MyFileUploader
            {...props}
            allowAdd={false}
            defaultImage={formInput?.img}
            refreshing={refreshing}
            setRefreshing={setRefreshing}
            setFormInput={setFormInput}
            formInput={formInput}
            uploadNow={uploadNow}
            resource={resource}
            handleSaveImage={(e: any) => {
              setUploadNow(true);
            }}
          />
        </InputAgenda>
        <InputAgenda>
          <input
            type={"text"}
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                link: e.target?.value,
              })
            }
            value={formInput?.link || ""}
            placeholder={"Add Link ..."}
            required
          />
        </InputAgenda>
      </div>
    </>
  );
};

const ListingModal = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  // const { handleInfo } = props;
  const params: any = useParams<any>();
  const { host } = myContext;
  const navigate = useNavigate();

  const { item } = props;
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    hostId,
    cart = [],
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
  } = myContext;

  const location = useLocation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: 20,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 5,
          alignItems: "center",
        }}
      >
        <img
          src={item.img}
          style={{
            width: 100,
            height: 100,
            objectFit: "cover",
          }}
          alt=""
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: 'center',
            alignItems: "flex-start",
          }}
        >
          <h6 style={{ fontSize: 14 }}>{item.title}</h6>
          <h4
            style={{
              fontSize: 16,
              color: "gray",
              fontWeight: 900,
            }}
          >
            {item.cost}
          </h4>
        </div>
      </div>
      <ButtonL
        onClick={() =>
          setMyContext((existing: any) => {
            return {
              ...existing,
              cart: [
                ...cart,
                {
                  img: item.img,
                  name: item.title,
                  price: item.cost,
                },
              ],
            };
          })
        }
        style={{ margin: 0 }}
      >
        Add to Cart
      </ButtonL>
    </div>
  );
};

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;

  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const SearchWrapper = styled.div`
  max-width: 930px;
  width: 100%;
  border: 1px solid #dfe0e4;
  border-radius: 10px;
  margin-top: -120px;
  position: relative;
  z-index: 10;
  background: #fff;
`;

const SearchBarContainer = styled.div`
  padding: 28px;
`;

const ToggleMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  border-bottom: 1px solid #dfe0e4;
`;

const ToggleButtons = styled.a<{ active: boolean }>`
  text-decoration: none;
  padding: 17px 0;
  color: #000;
  min-width: 50px;
  border-bottom: ${({ active }) => (active ? "2px solid #479d83" : "none")};
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

const EventImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 2px solid #dee2e6;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;

const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "White")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const GuestImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px 14px;
  margin-top: 14px;
`;

const GuestMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px !important;
  width: 140px !important;
  background-color: black;
  border-radius: 5px;
  position: relative;
  video,
  img {
    background-color: black;
    border-radius: 5px;
  }
`;

const GuestMediaHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 12px;
    max-width: 12px;
    height: 12px;
  }
  p {
    font-size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    width: 50%;
    color: white;
    margin: 0;
  }
  div {
    display: flex;
    gap: 4px;
    span {
      padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const GuestMediaParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  max-height: 500px;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: white;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const ButtonF = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PostHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const MenuWrapperPosts = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const DropdownFilterTabsContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

const FilterChild = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    width: fit-content;
  }
`;

const DropdownFilterTabs = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  z-index: 9999;
`;

const ButtonFilterTabs = styled.button`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 8px;
  margin: 0;
  cursor: pointer;

  svg {
    color: #333;
    width: 15px;
    height: 15px;
  }
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonIndustry = styled.button`
  background-color: #ffffff;
  color: #333;
  padding: 2px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-width: 160px;
  gap: 8px;
  font-size: 14px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContentIndustry = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

const DropdownItemIndustry = styled.div<any>`
  padding: 10px;
  cursor: pointer;
  color: #333;
  font-size: 12px;
  &:hover {
    background-color: #f1f1f1;
  }

  ${(props) =>
    props.active &&
    `
    background-color: #ddd; 
    font-weight: bold;
  `}
`;

const TopHeaderWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 5px;
  margin-top: -10px;
`;

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin-top: -20px; */
`;

const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const PriceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
`;

const Img = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const SupCardHeader = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  padding: 0px 10px;
`;

const CardLabelSub = styled.h3`
  margin: 0;
  font-size: 14px;
  color: gray;
  padding: 0px 10px;
`;

const CardPriceSub = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  padding: 0px 10px;
`;

const CardSupOffer = styled.div`
  margin: 0;
  font-size: 10px;
  color: gray;
  padding: 0px 10px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const HeroBannerWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  background: #96c7b8;
  min-height: 500px;
  @media (max-width: 800px) {
    min-height: 450px;
  }
  @media (max-width: 500px) {
    min-height: 400px;
  }
`;

const HeroBannerChild = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const HeroBannerTextWrapper = styled.div`
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: white;

  h1 {
    font-size: 66px;
    line-height: 49px;
    font-weight: 900;
    margin: 0;
    text-align: start;
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: 200;
    text-align: start;
    color: white;
  }

  @media (max-width: 800px) {
    align-items: center;
    text-align: center;

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 500px) {
    max-width: 280px;

    h1 {
      font-size: 40px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
    }
  }
`;
