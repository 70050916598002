/** @jsxImportSource @emotion/react */
import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  // Card,
  Col,
  Form,
  FormGroup,
  Row,
  Table,
} from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
// import ApiCalendar from 'react-google-calendar-api';
import { Card, CardContent, Typography, MenuItem, Box } from "@mui/material";
import { Close, Google, LocalActivity } from "@mui/icons-material";
import moment from "moment";
import Calendar from "react-calendar";
import {
  getCalendarEvents,
  getCalendarUsers,
  getCalendarSchedule,
  saveAppointment,
  getAppointment,
  getAgendaDB,
  myColors,
  configGoogle,
  sendEmail,
  getDataDB,
  saveDataDB,
} from "../api";
// import { Navbar } from "react-bootstrap";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import AdvancedForms from "./AdvancedForms/AdvancedForms";

import {
  BrowserRouter,
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import Menubar from "./Menubar";
import { Checkbox, Icon, IconButton } from "@mui/material";
import { UserContext } from "src/App";
import {
  CalendarMonthOutlined,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  X,
} from "@mui/icons-material";
import { CalendarIcon } from "@mui/x-date-pickers";

import { ButtonL } from "./AppIndex";
import { gapi } from "gapi-script";

const colors = {
  lightBlue: "#c1d8ff",
};

declare global {
  interface Window {
    gapi: {
      load: (lib: string, callback: () => void) => void;
      client: {
        init: (args: any) => Promise<void>;
        calendar: {
          events: {
            list: (request: any) => Promise<any>;
          }
        };
        getToken: () => { access_token: string } | null;
        setToken: (token: any) => void;
      };
    };
    google: {
      accounts: {
        oauth2: {
          initTokenClient: (config: any) => any;
          revoke: (token: string) => void;
        }
      }
    };
  }
}

export const TimeCard = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    scheduleInfo,
    setFormInput,
    formInput,
    timeSeries = [],
  } = props
  const {
    myInfo,
    myInfo: { active = "2" },
    host,
    myDates,
    data = {},
    eventInfo,
    setMyDates,
    myEventSchedule,
    // dataInfo = {},
    action = "attend",
  } = myContext;
  const [slot, setSlot] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // const [scheduleInfo, setScheduleInfo] = useState({});



  const handleClose = () => {
    setModalOpen(false);
  };

  const handleAddToCalendar = (calendar: any) => {
    if (calendar === "google") {
      window.open("https://calendar.google.com/calendar/r/eventedit", "_blank");
    } else if (calendar === "outlook") {
      window.open(
        "https://outlook.live.com/owa/?path=/calendar/action/compose",
        "_blank"
      );
    }
  };



  const [eventData, setEventData] = useState<any>({});
  const [dataInfo, setDataInfo] = useState<any>({});

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const params: any = useParams<any>();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const myData = useMemo(() => {
    return (
      timeSeries &&
      timeSeries?.[0]?.slots?.map((res: any) => {
        return {
          ...timeSeries?.[0],
          endTime: res?.end,
          startTime: res?.start,
        };
      })
    );
  }, [timeSeries]);


  // const timeSlots = [
  //   "10:00 AM - 11:00 AM",
  //   "11:00 AM - 12:00 PM",
  //   "12:00 PM - 01:00 PM",
  //   "01:00 PM - 02:00 PM",
  // ];
  // console.log("resxxx-myEventSchedule", myData);

  useEffect(() => {
    (async () => {
      if (!timeSeries?.[0]?._id) {
        return;
      }

      // console.log("resxxx-myEventSchedule", formInput?.date, timeSeries);
      // const info = timeSeries?.[0]
      if (timeSeries?.[0]?._id)
        await getAgendaDB({
          eventId: timeSeries?.[0]?._id,
        }).then((res: any) => {
          // console.log("resxxx", res);
          setEventData({
            ...eventData,
            agenda: res,
          });
        });
    })();
  }, [formInput?.date, timeSeries?.[0]?.id, myInfo?.handle]);

  const formatDate = (date: any, time: any, timezoneOffset = "Z") => {
    const [month, day, year] = date?.split("/");
    const formattedDate = `20${year}-${month}-${day}T${time}:00${timezoneOffset}`;

    const dateTime = new Date(formattedDate);
    if (isNaN(dateTime.getTime())) {
      console.error("Invalid date or time", dateTime);
      return null;
    }
    // Convert to UTC for Google Calendar
    return dateTime.toISOString().replace(/-|:|\.\d\d\d/g, "") + "Z";
  };

  const formatDateTimeForOutlook = (date: any, time: any) => {
    const dateTime = new Date(`${date}T${time}`);
    return dateTime?.toString();
  };

  function formatDateWithoutTime(date: any) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}${month}${day}`;
  }

  function formatDateWithTime(date: any, time: any): any {
    try {
      if (typeof time !== 'string') {
        throw new Error('Time must be a string in HH:MM format');
      }

      const [hours, minutes] = time.split(':').map(Number);

      if (isNaN(hours) || isNaN(minutes)) {
        throw new Error('Invalid time format');
      }

      date.setHours(hours, minutes);
      // Google Calendar requires 'YYYYMMDDTHHmmssZ' format
      return date.toISOString().replace(/[-:]/g, '').replace(/\.\d{3}Z$/, 'Z');
    } catch (error) {
      // @ts-ignore
      console.error(error.message);
      return null;
    }
  }

  function formatDateWithTimeOutlook(date: any, time: any): any {
    try {
      if (typeof time !== 'string') {
        throw new Error('Time must be a string in HH:MM format');
      }

      const [hours, minutes] = time.split(':').map(Number);

      if (isNaN(hours) || isNaN(minutes)) {
        throw new Error('Invalid time format');
      }

      date.setHours(hours, minutes);
      // Outlook Calendar requires 'YYYY-MM-DDTHH:MM:SS' format
      return date.toISOString().replace(/\.\d{3}Z$/, '');
    } catch (error) {
      // @ts-ignore
      console.error(error.message);
      return null;
    }
  }

  function createGoogleCalendarLink(scheduleInfo: any) {
    const startDate = formatDateWithTime(new Date(scheduleInfo.date), scheduleInfo.startTime);
    const endDate = formatDateWithTime(new Date(scheduleInfo.date), scheduleInfo.endTime);
    //todo add time
    const details = encodeURIComponent(
      `${scheduleInfo.description} Meeting link: ${scheduleInfo.room}`
    );
    const title = encodeURIComponent(scheduleInfo.title);

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDate}/${endDate}&details=${details}&location=${encodeURIComponent(
      scheduleInfo.room
    )}`;
  }

  function createOutlookCalendarLink(scheduleInfo: any) {
    const startDate = formatDateWithTimeOutlook(new Date(scheduleInfo.date), scheduleInfo.startTime);
    const endDate = formatDateWithTimeOutlook(new Date(scheduleInfo.date), scheduleInfo.endTime);

    const details = encodeURIComponent(
      `${scheduleInfo.description} Meeting link: ${scheduleInfo.room}`
    );
    const title = encodeURIComponent(scheduleInfo.title);

    return `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&startdt=${startDate}&enddt=${endDate}&subject=${title}&body=${details}&location=${encodeURIComponent(
      scheduleInfo.room
    )}`;
  }

  const location = useLocation();
  const CLIENT_ID = '401139889748-0mkm7e7tomhs99hc478tnk6t7cmtgfsm.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyBSCbEWHy9WwAgsw8aDcrBE5lNU1i1e3jA';
  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

  const [isGapiLoaded, setGapiLoaded] = useState<boolean>(false);
  const [isGisLoaded, setGisLoaded] = useState<boolean>(false);
  const [tokenClient, setTokenClient] = useState<any>(null); // Consider creating a more specific type if possible
  const [existingMeetings, setExistingMeetings] = useState<boolean>(false)

  useEffect(() => {
    const loadGapiScript = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = () => {
        gapi.load('client', initializeGapiClient);
      };
      document.body.appendChild(script);
    };

    const loadGisScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => {
        const initClient: any = window.google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: '', // This should be updated to a proper callback function
        });
        setTokenClient(initClient);
        setGisLoaded(true);
      };
      document.body.appendChild(script);
    };

    loadGapiScript();
    loadGisScript();
  }, []);

  const initializeGapiClient = async () => {
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    setGapiLoaded(true);
  };

  const handleAuthClick = (props: any) => {
    const { startDate, endDates, startTime, endTime } = props
    console.log(startDate, endDates, startTime, endTime, 'dates array')

    tokenClient.callback = async (resp: any) => { // Define the type of resp more accurately if possible
      if (resp.error !== undefined) {
        throw (resp);
      }
      // document.getElementById('signout_button')!.style.visibility = 'visible';
      // (document.getElementById('authorize_button') as HTMLButtonElement).innerText = 'Refresh';
      await listUpcomingEvents(startDate, endDates, startTime, endTime);
    };

    if (gapi.client.getToken() === null) {
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      tokenClient.requestAccessToken({ prompt: '' });
    }
  };

  function convertDateTime(dateStr: any, timeStr: any) {
    const [month, day, year] = dateStr.split('/').map((num: any) => parseInt(num, 10));
    const [hours, minutes] = timeStr.split(':').map((num: any) => parseInt(num, 10));
    const fullYear = 2000 + year;
    const date = new Date(Date.UTC(fullYear, month - 1, day, hours, minutes, 0, 0));
    return date.toISOString();
  }

  const listUpcomingEvents = async (startDate: any, endDates: any, startTime: any, endTime: any) => {
    let response;
    const formattedStartDate = convertDateTime(startDate, startTime);
    const formattedEndDate = convertDateTime(endDates, endTime);

    console.log(formattedStartDate, formattedEndDate, 'asdlsdksmdk')

    try {
      const request = {
        'calendarId': 'primary',
        'timeMin': formattedStartDate,
        'timeMax': formattedEndDate,
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 100,
        'orderBy': 'startTime',
      };
      response = await gapi.client.calendar.events.list(request);
    } catch (err) { // Specify a more accurate error type if possible
      // @ts-ignore
      document.getElementById('content')!.innerText = err.message;
      return;
    }

    const events = response.result.items;
    if (!events || events.length === 0) {
      const element = document.getElementById('content');
      if (element) {
        element.innerText = 'No events found.';
      } else {
        console.error('Element not found');
        return;
      }
      // document.getElementById('content')!.innerText = 'No events found.';
    }
    const output = events.reduce(
      (str: string, event: any) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`, // Define event type more accurately if possible
      '\n'
    );
    document.getElementById('content')!.innerText = output;
    setModalOpen(true);
    // setExistingMeetings(true)
  };

  const handleRSVP = async () => {

    console.log(dataInfo, 'ansdjsjdb')

    if (dataInfo?.date && dataInfo?.startTime && dataInfo?.endTime) {
      const startDate = dataInfo?.date
      const endDates = dataInfo?.date
      const startTime = dataInfo?.startTime
      const endTime = dataInfo?.endTime

      handleAuthClick({
        "startDate": startDate,
        "endDates": endDates,
        "startTime": startTime,
        "endTime": endTime
      })

    }

    const meetingLink = await createGoogleCalendarLink(dataInfo);
    const myInfoNew = dataInfo?.myInfo

    const data = {
      to: `${formInput?.guestEmail || ''}${myInfo?.handle ? ',' + myInfo?.handle : ''}`,
      text: `Hello ${dataInfo?.myInfo?.name} - ${formInput?.guestMessage}`,
      html: "",
      subject: `Message to ${dataInfo?.myInfo?.name} from GuestNet Planner ✔`,
      data: dataInfo,
      myInfoNew,
      location: `https://www.myguestnet.com${location.pathname}`,
      calendarLink: meetingLink,
    };

    sendEmail(data).then(async (res: any) => {
      const guest = {
        query: {
          handle: host?.handle,
        },
        resource: `calendar_users`,
        check: ["handle"],
      };

      const hostProfile = await getDataDB(guest).then((res: any) => {
        return res?.[0];
      });

      const hostData = {
        to: hostProfile?.email,
        text: `Hello ${hostProfile?.name}`,
        html: "",
        subject: `${myInfo?.name} has RSVP for your meeting ${dataInfo?.title}`,
        data: eventInfo,
        myInfo,
        location: `https://www.myguestnet.com${location.pathname}`,
        calendarLink: meetingLink,
      };

      sendEmail(hostData).then((res: any) => { });
    });
  };

  const googleCalendarIcon = "/imgs/google-calendar.svg";
  const outlookCalendarIcon = "/imgs/outlook-calendar.svg";

  useEffect(() => {
    if (slot?.split("-")[1])
      setDataInfo({
        startTime: slot?.split("-")[0],
        endTime: slot?.split("-")[1],
        date: formInput?.date,
        eventId: scheduleInfo?.eventId,
        description: scheduleInfo,
        host: scheduleInfo?.handle,
        title: scheduleInfo?.title,
        room: scheduleInfo?.room,
        myInfo: myInfo
      })
  }, [
    slot,
    scheduleInfo,
    formInput
  ]);

  useEffect(() => {
    console.log('dataInfo', dataInfo)
  }, [
    dataInfo
  ])


  return (
    <RSVPCard>
      <InputWrapper>
        <InputWrapper style={{ width: "200px" }}>
          <h3>Select the Time Slot*</h3>
          <Select value={slot}
            onChange={(e: any) => {
              setSlot(e.target?.value)
            }
            }>
            <option value="" disabled>
              Select a time slot
            </option>
            {myData[0]?.slots?.map((time: any, index: any) => (
              <option key={index} value={time.start + "-" + time.end}>
                {time.start + " - " + time.end}
              </option>
            ))}
          </Select>
        </InputWrapper>

        {!myInfo?.token && <InputWrapper>
          <h3>Email</h3>
          <input
            onChange={(e: any) => {
              setFormInput({
                ...formInput,
                guestEmail: e.target?.email
              })
            }}
            name=""
            id=""
            placeholder="Add you email or login?..."
          />
        </InputWrapper>}

        <InputWrapper>
          <h3>Message</h3>
          <textarea
            onChange={(e: any) => {
              setFormInput({
                ...formInput,
                guestMessage: e.target?.message
              })
            }}
            name=""
            id=""
            rows={4}
            placeholder="Whats in your mind?..."
          ></textarea>
        </InputWrapper>
      </InputWrapper>
      <CarFooterWrapper>
        <p>
          Capacity: <Badge>1/4</Badge>
        </p>

        <ButtonL
          style={{
            margin: "20px 0",
            ...(!dataInfo?.endTime ? { backgroundColor: 'lightgray' } : {})
          }}
          disabled={dataInfo?.endTime ? false : true}
          onClick={() => {
            if (!dataInfo?.endTime) {
              alert('Please select a time slot to continue...')
              return
            }
            if (!formInput?.guestEmail && !myInfo?.email) {
              alert('Please add your email or login to continue')
              return
            }
            handleRSVP()
          }}>
          RSVP
        </ButtonL>
      </CarFooterWrapper>

      <Modal open={modalOpen}>
        <ModalContent>
          <button className="close" onClick={handleClose}>
            <Close />
          </button>
          {existingMeetings ?
            <IconContainer>
              <h3 style={{ margin: 0 }}>Add to Calendar</h3>
              <div>
                <IconLink
                  // onClick={() => handleAddToCalendar("google")}
                  href={createGoogleCalendarLink(dataInfo).toString()}
                  target="_blank"
                >
                  <img src={googleCalendarIcon} alt="Google Calendar" />
                </IconLink>
                <IconLink
                  href={createOutlookCalendarLink(dataInfo)}
                  target="_blank"
                >
                  <img src={outlookCalendarIcon} alt="Outlook Calendar" />
                </IconLink>
              </div>
            </IconContainer>
            :
            <div style={{ display: "flex", flexDirection: 'column', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
              <h1>Existing Meetings:</h1>
              <p style={{ color: 'red', fontSize: '16px', fontWeight: '400' }}>These are the meetings that already exists in your calendar:</p>
              <pre id="content" style={{ whiteSpace: 'pre-wrap' }}></pre>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                <button
                  className="continue"
                  style={{
                    border: "1px solid black",
                    borderRadius: '10px',
                    padding: "5px"
                    // margin: "20px 0",
                  }}
                  onClick={() => {
                    setExistingMeetings(true)
                    // handleRSVP()
                  }}>
                  Continue to Add Event to Calendar
                </button>
                <button
                  className="continue"
                  style={{
                    border: "1px solid black",
                    borderRadius: '10px',
                    padding: "5px"
                    // margin: "20px 0",
                  }}
                  onClick={handleClose}>
                  Exit
                </button>
              </div>
            </div>
          }
        </ModalContent>
      </Modal>
    </RSVPCard>
  );
};

const ItemList = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const location = useLocation();
  const {
    myInfo,
    myInfo: { active = "2" },
    host,
    myDates,
    data = {},
    setMyDates,
    // setDataInfo = {},
    myEventSchedule,
    eventInfo,
    action = "attend",
  } = myContext;
  const {
    // myRsvp,
    // rsvp,
    // res,
    eventId,
    // scheduleInfo,
    // rsvpArrays,
    // eventId,
    formInput,
    setOpenSignin,
    scheduleInfo,
    // dataInfo = {},
    // myInfo,
    // data = {},
    setData,
    eventData,
    setRefreshing,
    refreshing,
  } = props;
  // const [info, setInfo] = useState<any>({})
  const [openModal, setOpenModal] = useState<any>({});
  const [rsvpArrays, setRsvpArrays] = useState<any>([]);
  // const [myRsvp, setMyRsvp] = useState<any>();
  // const [rsvp, setRsvp] = useState<any>([]);

  const [dataInfo, setDataInfo] = useState<any>({});
  const [attendance, setAttendance] = useState<any>([]);

  console.log("scheduleInfo", scheduleInfo);

  const handleAttendance = async (info: any) => {
    if (!info?.handle) {
      alert("Please login to continue.");
      return;
    }
    if (!info?.eventId) {
      alert("Please select event");
      return;
    }

    const meetingLink = await createGoogleCalendarLink(scheduleInfo);
    const deal = { ...info };
    delete deal?._id;
    console.log("dealc", deal);

    const data = {
      query: {
        ...deal,
        eventId: scheduleInfo?.eventId,
        scheduleId: scheduleInfo?._id,
        location: `https://www.myguestnet.com${location.pathname}`,
        meetingLink,
      },
      resource: `calendar_attendance`,
      check: ["handle", "scheduleId"],
      myInfo,
    };
    console.log("FormFields", data);

    saveDataDB(data).then((ref) => {
      console.log("i am here");
      setRefreshing(true);

      const data = {
        to: myInfo?.email,
        text: `Hello ${myInfo?.name}`,
        html: "",
        subject: `Message to ${myInfo?.name} from GuestNet Planner ✔`,
        data: scheduleInfo,
        myInfo,
        location: `https://www.myguestnet.com${location.pathname}`,
        calendarLink: meetingLink,
      };

      sendEmail(data).then(async (res: any) => {
        const guest = {
          query: {
            handle: host?.handle,
          },
          resource: `calendar_users`,
          check: ["handle"],
        };

        const hostProfile = await getDataDB(guest).then((res: any) => {
          return res?.[0];
        });

        const hostData = {
          to: hostProfile?.email,
          text: `Hello ${hostProfile?.name}`,
          html: "",
          subject: `${myInfo?.name} has RSVP for your meeting ${info?.title}`,
          data: eventInfo,
          myInfo,
          location: `https://www.myguestnet.com${location.pathname}`,
          calendarLink: meetingLink,
        };

        sendEmail(hostData).then((res: any) => { });
      });
    });
  };

  useEffect(() => {
    if (!scheduleInfo?.capacity) {
      scheduleInfo["capacity"] = 1;
    }
    const data = {
      query: {
        scheduleId: scheduleInfo?._id,
        eventId: eventId,
      },
      resource: `calendar_attendance`,
      check: ["handle", "scheduleId"],
    };

    getDataDB(data).then((attendance: any) => {
      console.log("bbbb", attendance);
      setAttendance(attendance);
      setRefreshing(false);
    });
  }, [refreshing, myInfo?.handle, scheduleInfo?._id, formInput?.date]);

  useEffect(() => {
    // console.log("openModal", dataInfo);
  }, [dataInfo]);

  const formatDate = (date: any, time: any, timezoneOffset = "Z") => {
    const [month, day, year] = date.split("/");
    const formattedDate = `20${year}-${month}-${day}T${time}:00${timezoneOffset}`;

    const dateTime = new Date(formattedDate);
    if (isNaN(dateTime.getTime())) {
      console.error("Invalid date or time", dateTime);
      return null;
    }
    // Convert to UTC for Google Calendar
    return dateTime.toISOString().replace(/-|:|\.\d\d\d/g, "") + "Z";
  };

  const formatDateTimeForOutlook = (date: any, time: any) => {
    const dateTime = new Date(`${date}T${time}`);
    return dateTime?.toString();
  };

  async function createGoogleCalendarLink(scheduleInfo: any) {
    const startDateTime = formatDate(
      scheduleInfo.startDate,
      scheduleInfo.startTime
    );
    const endDateTime = formatDate(scheduleInfo.endDate, scheduleInfo.endTime);
    const details = encodeURIComponent(
      `${scheduleInfo.description} Meeting link: ${scheduleInfo.room}`
    );
    const title = encodeURIComponent(scheduleInfo.title);

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDateTime}/${endDateTime}&details=${details}&location=${encodeURIComponent(
      scheduleInfo.room
    )}`;
  }

  function createGoogleCalendarLink2(scheduleInfo: any) {
    const startDateTime = formatDate(
      scheduleInfo.startDate,
      scheduleInfo.startTime
    );
    const endDateTime = formatDate(scheduleInfo.endDate, scheduleInfo.endTime);
    const details = encodeURIComponent(
      `${scheduleInfo.description} Meeting link: ${scheduleInfo.room}`
    );
    const title = encodeURIComponent(scheduleInfo.title);

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDateTime}/${endDateTime}&details=${details}&location=${encodeURIComponent(
      scheduleInfo.room
    )}`;
  }

  function createOutlookCalendarLink(scheduleInfo: any) {
    const startDateTime = formatDateTimeForOutlook(
      scheduleInfo.startDate,
      scheduleInfo.startTime
    );
    const endDateTime = formatDateTimeForOutlook(
      scheduleInfo.endDate,
      scheduleInfo.endTime
    );
    const details = encodeURIComponent(
      `${scheduleInfo.description} Meeting link: ${scheduleInfo.room}`
    );
    const title = encodeURIComponent(scheduleInfo.title);

    return `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&startdt=${startDateTime}&enddt=${endDateTime}&subject=${title}&body=${details}&location=${encodeURIComponent(
      scheduleInfo.room
    )}`;
  }

  // const googleCalendarLink = useCallback(()=> {
  //   return createGoogleCalendarLink(scheduleInfo) || ''
  // }, [scheduleInfo])

  const googleCalendarIcon = "/imgs/google-calendar.svg";
  const outlookCalendarIcon = "/imgs/outlook-calendar.svg";

  const allRsvp = useMemo(() => {
    return attendance?.filter(
      (reg: any) =>
        reg.slottime == scheduleInfo?.startTime + "-" + scheduleInfo?.endTime &&
        reg?.accept
    );
  }, [attendance, scheduleInfo?.startTime]);

  const myRsvp = useMemo(() => {
    return (
      allRsvp?.filter(
        (mine: any) =>
          mine?.handle == myInfo?.handle &&
          scheduleInfo?.eventDate == mine?.eventDate
      )?.[0]?.accept || false
    );
  }, [allRsvp]);

  console.log("myRsvp", allRsvp);

  return (
    <>
      <StyledRow>
        <DataCell>
          <div
            onClick={(e) => {
              if (!scheduleInfo?.disabled) {
                if (
                  allRsvp.length >= scheduleInfo.capacity &&
                  !rsvpArrays?.includes(scheduleInfo.handle)
                ) {
                  alert("Meeting is full");
                  return;
                }
                if (!myInfo?.handle) {
                  let text = "Please login to complete this action";
                  if (window.confirm(text) == true) {
                    text = "You pressed OK!";
                    setOpenSignin(true);
                    return;
                  } else {
                    text = "You canceled!";
                    // setOpenSignin(true)
                    return;
                  }
                  alert("Please login to complete this action");
                  return;
                }
                // alert(myRsvp)

                handleAttendance({
                  ...formInput,
                  ...scheduleInfo,
                  accept: !myRsvp,
                  handle: myInfo?.handle,
                  hostHandle: scheduleInfo?.handle,
                  hostName: scheduleInfo?.name,
                  eventId: scheduleInfo?._id,
                  description: scheduleInfo.description,
                  slottime:
                    scheduleInfo?.startTime + "-" + scheduleInfo?.endTime,
                  startTime: scheduleInfo?.startTime,
                  endTime: scheduleInfo?.endTime,
                  dateTime: `${moment(scheduleInfo?.eventDate)?.format(
                    "MM/DD/YY"
                  )} ${scheduleInfo?.startTime}`,
                  // capacity: res.capacity,
                  // time: res?.time,
                });
              }
            }}
          >
            {myRsvp ? <CheckBox /> : <CheckBoxOutlineBlank />}
            {/* {myRsvp && <ButtonL
              onClick={() => {
                eventInfo?.room && window.open(eventInfo?.room)
              }}
            >
              Check In
            </ButtonL>} */}
          </div>

          <div> {!myInfo?.handle ? "Login" : ""}</div>
        </DataCell>
        <DataCell>
          <div>{scheduleInfo?.startTime || ""}</div>
          <div>{scheduleInfo?.endTime || ""}</div>
        </DataCell>
        <DataCell>
          <div>{scheduleInfo?.title || ""}</div>
        </DataCell>
        <StatusCell>
          <div
            onClick={() => { }}
            style={{
              borderRadius: 100,
              color: "white",

              backgroundColor:
                allRsvp.length == (scheduleInfo.capacity || 1)
                  ? myColors.red
                  : myColors.brown,
            }}
          >
            {`${allRsvp?.length}/${scheduleInfo.capacity || 1}`}
          </div>
        </StatusCell>

        <ButtonL>
          <CalendarMonthOutlined />
        </ButtonL>
      </StyledRow>
    </>
  );
};

const StyledTable = styled.table`
  min-width: 700px;
  overflow-x: auto;
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
`;

const StyledHeader = styled.thead`
  background-color: #dfe1e6;
  text-transform: uppercase;
`;
const IconLink = styled.a`
  margin-left: 10px;
  display: inline-block;
  cursor: pointer;

  & img {
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;
const StyledRow = styled.tr``;

const HeaderCell = styled.th`
  padding: 16px;
  border-bottom: 2px solid #dfe1e6;
  color: #5e6c84;
  font-size: 14px;
  font-weight: 500;
`;

const HeaderCellV2 = styled.th`
  padding: 16px;
  border-bottom: 2px solid #dfe1e6;
`;

const DataCell = styled.td`
  padding: 15px;
  border-bottom: 1px solid #dfe1e6;
  color: #5e6c84;
  font-size: 14px;
`;

const StatusCell = styled.td`
  padding: 15px;
  border-bottom: 1px solid #dfe1e6;
  color: #5e6c84;
  font-size: 14px;
  font-weight: bold;

  &.fulfilled {
    color: #36b37e;
  }
  &.confirmed {
    color: #0052cc;
  }
  &.partiallyShipped {
    color: #ff5630;
  }
`;
const StyledCard = styled.div`
  width: "100%";
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fff;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #1976d2;
  color: #fff;
  font-size: 0.9em;
  margin-left: 8px;
`;

const Modal = styled.div<{ open: any }>`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &.close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 5px;
    right: 5px;
    svg {
      width: 100%;
      height: 100%;
    }
    background: none;
    border: none;
    outline: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;
const RSVPCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  textarea {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #8080804a;
    outline: none;
    height: 100%;
    resize: none;
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    text-align: left;
    margin: 0;
  }
`;
const CarFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// const Icon = styled.i`
//   font-size: 2em;
//   cursor: pointer;
// `;
