import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

const fullWidth = window.screen.width;
const fullHeight = window.screen.height;
// Styled components using Emotion
const StoryContainer = styled.div<{ bgSrc: string; useImg: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #00000040;
  background-image: ${({ useImg, bgSrc }) => (useImg ? `url(${bgSrc})` : 'none')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  width: 350px;
  height: 600px;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const CaptionContainer = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  overflow-y: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  height: 180px;
  padding: 10px;
`;

const CaptionText = styled.div<{ scrollY: number }>`
  position: relative;
  top: ${({ scrollY }) => `-${scrollY}px`};
  transition: top 0.2s ease-in-out;
`;

const ProgressBar = styled.div<{ progress: number }>`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  &::before {
    content: '';
    display: block;
    height: 100%;
    background: #fff;
    width: ${({ progress }) => `${progress}%`};
    transition: width 0.2s ease-in-out;
  }
`;

const PlayButton = styled.button`
  position: absolute;
  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

interface StoryProps {
  caption: string;
  audioSrc: string;
  img?: string;
  video?: string;
  useImg?: boolean;
  useAudioSrc?: boolean;
  interval?: number;
  item?: any;
}

const InstagramStory: React.FC<StoryProps> = (props) => {
  const {
    caption,
    audioSrc,
    img,
    video,
    useImg = true,
    useAudioSrc = true,
    item,
    interval = 15, // default to 15 seconds for story duration
  } = props
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const captionRef = useRef<HTMLDivElement>(null);
  const scrollDurationRef = useRef(0); // Time to fully scroll through text
  const intervalRef = useRef<number | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  console.log('itemmmmm', img)

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      videoRef.current?.pause();
      clearInterval(intervalRef.current!); // Clear scrolling interval
    } else {
      if (useAudioSrc && audioRef.current) {
        audioRef.current.play();
      } else {
        // Trigger text-to-speech if no audioSrc
        const utterance = new SpeechSynthesisUtterance(caption);
        window.speechSynthesis.speak(utterance);
      }
      videoRef.current?.play();
      startScrolling();
    }
    setIsPlaying(!isPlaying);
  };

  const startScrolling = () => {
    const caption = captionRef.current;
    if (caption) {
      const maxScroll = caption.scrollHeight - caption.clientHeight;
      const scrollTime = scrollDurationRef.current; // Total scroll time for the text
      const scrollPerSecond = maxScroll / interval; // Scroll amount per second based on interval

      intervalRef.current = window.setInterval(() => {
        setScrollY((prevY) => {
          const nextY = prevY + scrollPerSecond;
          return nextY > maxScroll ? maxScroll : nextY;
        });
        setProgress((prevProgress) => prevProgress + (100 / (interval * 10)));

        // Stop scrolling when the text is fully scrolled
        if (progress >= 100) {
          clearInterval(intervalRef.current!);
          setIsPlaying(false);
          audioRef.current?.pause();
        }
      }, 100); // Update progress every 100ms (0.1 second)
    }
  };

  useEffect(() => {
    const caption = captionRef.current;
    if (caption) {
      const maxScroll = caption.scrollHeight - caption.clientHeight;
      scrollDurationRef.current = maxScroll;
    }

    return () => {
      clearInterval(intervalRef.current!); // Cleanup interval
    };
  }, []);

  return (
    <StoryContainer
      style={{
        width: '100%',

      }}
      bgSrc={img || ''}
      useImg={useImg}>
      {/* Conditionally render video background if useImg is false */}
      {!useImg && video && (
        <VideoBackground ref={videoRef} src={video} loop muted />
      )}

      <audio
        ref={audioRef}
        src={useAudioSrc ? audioSrc : ''}
        loop
      />
      <ProgressBar
        progress={progress}
      />
      <CaptionContainer ref={captionRef}>
        <CaptionText scrollY={scrollY}>{caption}</CaptionText>
      </CaptionContainer>
      <PlayButton onClick={togglePlay}>
        {isPlaying ? 'Pause' : 'Play'}
      </PlayButton>
    </StoryContainer>
  );
};

export default InstagramStory;
