import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";
import * as xlsx from "xlsx";
import { Report as Reporttt } from "@mui/icons-material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from "@mui/material";
import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import { deleteDataDB, getDataDB, getDataWildDB, myColors, saveDataDB } from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  UploadFile,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { MyFileUploader } from "./MyFileUploader";
import { AddItemCarrousselCard } from "../AddItemCarrousselCard";
import { AddMemberCarroussel } from "../AddMemberCarroussel";
import { capitalizeFirstLetter } from "src/helpers/Utils";
// import { IconButton } from 'material-ui';
const _ = require("lodash");

export const ImportDataComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    type,
    resource,
    topic
  } = props;
  const params: any = useParams<any>();

  // const [editInfo, setEditInfo] = useState<any>({});

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [newDataRecord, setNewDataRecord] = useState<any>([]);

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const {
    myInfo,
    host,
    hostTabs,
    eventInfo = {},
    allAccess,
    editInfo,
  } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  // const resource = useMemo(() => {
  //   return `calendar_members_record`;
  // }, []);


  // const myData = useMemo(() => {
  //   return [
  //     ...dataRecord
  //       ?.filter((res: any) => res.title != formInput?.title)
  //       ?.map((red: any) => {
  //         const info: any = {};
  //         if (red.file) {
  //           info["file"] = red.file;
  //         }
  //         if (red.link) {
  //           info["link"] = red.link;
  //         }
  //         if (red.img) {
  //           info["img"] = red.img;
  //         }
  //         return {
  //           ...red,
  //           _id: red?._id,
  //           title: red?.title,
  //           // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
  //           // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
  //           // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
  //         };
  //       }),
  //   ];
  // }, [dataRecord]);

  const [searchKeywordAttendees, setSearchKeywordAttendees] =
    useState<any>(null);

  // useEffect(
  //   _.debounce(() => {
  //     if (!(formInput?.title?.length > 0)) {
  //       setRefreshing(true);
  //       return;
  //     }
  //     const data = {
  //       key: "name",
  //       request: "wildsearch",
  //       searchVal: formInput?.title,
  //       resource: `calendar_users`,
  //       limit: 400,
  //     };
  //     // console.log('formFieldxx44', formInput?.title)

  //     getDataWildDB(data).then((res: any) => {
  //       // console.log('formFieldxx44', res)
  //       if (res?.[0]) {
  //         const result = res
  //           ?.map((person: any) => {
  //             return {
  //               active: true,
  //               link: `https://www.myguestnet.com/host/${person?.handle}`,
  //               img:
  //                 person.img ||
  //                 "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
  //               title: person.name,
  //               handle: person?.handle,
  //             };
  //           })
  //           ?.filter((ref: any) => ref?.handle != myInfo?.handle || allAccess);
  //         setDataRecord([...dataRecord, ...result]);
  //       }
  //     });
  //   }, 2000),
  //   [formInput?.title]
  // );

  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const dataset: any = [];

  const [dataSet, setDataSet] = useState<any>(dataset);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    // Define allowed file types for images and videos
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

    if (
      file &&
      (allowedImageTypes.includes(file.type) ||
        allowedVideoTypes.includes(file.type)) &&
      file.size <= 2097152
    ) {
      setFile(file);
      setFormInput({
        ...formInput,
        file,
      });
      setAddType({
        ...addType,
        file,
      });
    } else {
      alert("Please upload an image or video file smaller than 2MB.");
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];

    // Define allowed file types for images and videos
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

    if (
      file &&
      (allowedImageTypes.includes(file.type) ||
        allowedVideoTypes.includes(file.type)) &&
      file.size <= 2097152
    ) {
      setFile(file);
      setFormInput({
        ...formInput,
        file,
      });
      setAddType({
        ...addType,
        file,
      });
    } else {
      alert("Please upload an image or video file smaller than 2MB.");
    }
  };



  const ChangeFile = () => {
    setFile(null);
    setFormInput({
      ...formInput,
      file: null,
    });
    setAddType({
      ...addType,
      file: null,
    });
  };

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const data = await e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json: any = xlsx.utils.sheet_to_json(worksheet);
        // console.log("vvvvvfjson", json);
        if (json?.[0]) {
          const info = Object.keys(json[0])?.map((key: any) => {
            return {
              ques: key,
              ans: json?.map((reg: any) => {
                return reg[key];
              }),
            };
          });
          if (info?.[0]) {
            setDataSet(info);
          }
          // console.log("vvvvvfjson33", info, dataset);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const [modalData, setModalData] = useState<number[]>([]);
  const [modalTitle, setModalTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [formIndex, setFormIndex] = useState<number>(-1);
  const [formType, setFormType] = useState<any>({});
  const [dataArray, setDataArray] = useState<any>([]);
  const [dataRange, setDataRange] = useState<number>(0)



  const handleOpen = (data: number[], title: string) => {
    setModalData(data);
    setModalTitle(title);
    setOpen(true);
  };

  const renderGraphIcon = (
    ans: (string | number | boolean)[],
    title: string
  ) => {
    const numericData = ans.filter((a) => typeof a === "number") as number[];
    const booleanData = ans.filter((a) => typeof a === "boolean") as boolean[];

    if (numericData.length > 0 || booleanData.length > 0) {
      const dataToGraph =
        numericData.length > 0
          ? numericData
          : booleanData.map((b) => (b ? 1 : 0));
      return (
        <IconButton onClick={() => handleOpen(dataToGraph, title)}>
          <Reporttt />
        </IconButton>
      );
    }
    return null;
  };

  // console.log('allAccessxxx', allAccess)

  // useEffect(() => {
  //   console.log('allAccessxxx', formType, requiredFields, myData)
  // }, [
  //   requiredFields,
  //   formType
  // ])

  const myOptions = useMemo(() => {

    // console.log("vvvvvfjson33", dataSet, formType);
    const menuInfo = formType && Object.keys(formType)?.map((res: any) => {
      return formType[res]
    })


    const list = [
      {
        value: "name",
        label: "Name (required)",
      },
      {
        value: "title",
        label: "Title (required)",
      },
      {
        value: "memberId",
        label: "Member Id",
      },
      {
        value: "email",
        label: "Email",
      },
      {
        value: "phone",
        label: "Phone",
      },
      {
        value: "description",
        label: "Description",
      },
      {
        value: "link",
        label: "link",
      },
      {
        value: "img",
        label: "Picture",
      },
      {
        value: "date",
        label: "Date",
      },

    ]
    return list?.filter(res => {
      return !(menuInfo && menuInfo?.includes(res?.value))
    })

  }, [
    formType
  ])

  const requiredFields = [
    'name',
    'email'
  ]

  // const assignedHeades: any = useMemo(() => {
  //   const info = []
  //   const menuInfo = formType && Object.keys(formType)?.map((res: any) => {
  //     return formType[res]
  //   })

  //   requiredFields?.map(req => {
  //     const required = menuInfo?.includes(req)
  //     if (required) {
  //       info?.push(req)
  //     }
  //   })
  //   return info?.length >= requiredFields?.length
  // }, [
  //   formType
  // ])

  console.log("assignedHeades", dataSet, formType);

  useEffect(() => {
    // formType

    console.log("dataSetxx", formType, dataSet);

    if (!Object.keys(formType)?.[0]) {
      return
    }


    const info: any = []
    dataSet?.map((ref: any) => {
      info.push(formType[ref?.ques] || ref?.ques)
    })


    if (info?.[0]) {
      const myDataInfo: any = info?.map((res: any, index: number) => {
        return dataSet[index]?.ans?.map((ree: any, i: number) => {
          return { [res]: ree }
        })
      })

      const delay: any = []
      myDataInfo?.map((res: any) => {
        res?.map((ref: any, index: number) => {
          delay[index] = {
            ...delay[index],
            ...ref
          }
        })
      })

      console.log("assignedHeades1", delay);
      if (delay?.[0]) {
        setDataArray(delay)
      }
    }

  }, [
    formType,
    dataSet
  ])

  const handleSaveToDB = useCallback(async () => {

    const dataInfo = dataArray?.length >= dataRange
      ? dataArray?.slice(0, dataRange)
      : dataArray
    console.log('vvvvvvxxxxx1', dataArray, dataInfo, dataRange, dataArray?.slice(0, dataRange * 1))

    if (!(dataInfo?.[0] && Object.keys(dataInfo?.[0])?.includes('title'))) {
      alert('Please make sure one of your column is assigned the header "Title"')
      return
    }

    const result = await dataInfo?.map((info: any) => {
      return {
        ...info,
        email: info?.email,
        status: "request",
        handle: info?.handle || '',
        eventId: eventId,
        name: info?.name,
        title: info?.title,
        enterprise: eventInfo?.title,
        hostHandle: myInfo?.handle,
      }
    })

    console.log('vvvvvvxxxxx2', result)
    if (result?.[0]) {
      return await Promise.all(result?.map(async (info: any) => {

        const data = {
          query: {
            ...info,
            status: "request",
            name: info?.name,
            handle: info?.handle,
            eventId: eventId,
            topic: topic,
            hostHandle: myInfo?.handle,
          },
          resource: resource, //resource,
          check: ["name", "eventId"],
          myInfo,
        };
        console.log('vvvvvvxxxxx', data)
        return await saveDataDB(data)
          .then((res) => {
            return res
          })
          .catch((error) => {
            console.error("Failed to update database", error);
            return error
          });

      }))
    }

  }, [
    myInfo?.handle,
    eventInfo,
    eventId,
    dataArray,
    dataRange
  ])


  return (
    <>
      <div>
        {editInfo && (
          <>
            <AddSkillSection style={{
              marginTop: 10,
              marginBottom: -5
            }}>
              <h2>Import List</h2>
              <>
                <UploadedResume style={{
                  marginBottom: -30
                }}>
                  <label htmlFor="upload">Upload File</label>
                  <InputAgenda>
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      onChange={readUploadFile}
                    />
                  </InputAgenda>
                </UploadedResume>
                {formType && Object.keys(formType)?.length >= 3 && (
                  <div
                    style={{
                      padding: 10,
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      width: 150,
                      margin: 10
                    }}
                  >
                    <ButtonL
                      onClick={() => {
                        setDataSet([]);
                      }}
                    >
                      Cancel
                    </ButtonL>
                    <label>Row Range</label>
                    <input
                      onChange={(e: any) => {
                        setDataRange(e.target?.value);
                      }}
                      type="number"
                      id="range"
                      name="range"
                      style={{
                        width: 50,
                        color: 'white',
                        backgroundColor: myColors?.brown
                      }}
                    // value="list"
                    // {...(formInput?.view == "list"
                    //   ? { checked: true }
                    //   : {})}
                    />

                    {dataRange > 0 && <ButtonL
                      onClick={() => {
                        handleSaveToDB()

                      }}
                    >
                      Save
                    </ButtonL>}
                  </div>
                )}
                <Col
                  lg={12}
                  sm={12}
                  md={8}
                  className="mg-t-10 mg-md-t-0"
                  style={{
                    display: 'flex',
                    flex: 1,
                    // width: 700,
                    overflowX: 'auto'
                  }}>
                  {dataSet?.length > 0 && (<>

                    <Table>
                      <TableHead
                        style={{
                          marginBottom: -20,
                          marginTop: -20
                        }}
                      >
                        <TableRow>
                          <TableCell>No</TableCell >
                          {dataSet?.map((data: any, index: number) => {
                            const field = data.ques;
                            const options: any = []
                            return <TableCell >
                              <>
                                {formIndex != index && <div style={{
                                  top: 60,
                                  display: 'flex',
                                  // position: 'absolute',
                                  width: 150,
                                  // zIndex: 9999,
                                  // backgroundColor: 'red'
                                }}>
                                  <Select
                                    defaultValue={{
                                      // label: "Select Label",
                                      // value: "Select Label",
                                    }}
                                    value={
                                      formType?.[field]
                                        ? {
                                          label: capitalizeFirstLetter(formType?.[field]),
                                          value: formType?.[field],
                                        }
                                        : data?.ques
                                          ? {
                                            value: data?.ques,
                                            label: data?.ques
                                          }
                                          : {
                                            label: "Select Label",
                                            value: "Select Label",
                                          }
                                    }
                                    onChange={(e: any) => {
                                      // console.log("ccvvv", e);
                                      setFormType({
                                        ...formType,
                                        [field]: e?.value,
                                      });
                                      setFormIndex(-1)
                                    }}
                                    options={myOptions || []}
                                    placeholder="Event Type"
                                    classNamePrefix="selectform"
                                  />
                                </div>}
                              </>
                              {/* {data?.ques} */}
                            </TableCell>
                          })}

                          {dataSet.map((data: any, index: number) => {
                            if (!(data.ans && data.ques)) {
                              return
                            }
                            console.log('vvvddd', data.ans)
                            const field = data.ques;
                            const options: any = []
                            return (
                              <TableCell key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                position: 'relative'
                              }}>
                                {(typeof data.ans[0] === "number" ||
                                  typeof data.ans[0] === "boolean") &&
                                  renderGraphIcon(data.ans, data.ques)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.from({ length: 20 }, (_, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            {dataSet.map((data: any, index: number) => {
                              if (!data?.ans) {
                                return
                              }
                              return (
                                <TableCell
                                  key={index}
                                  onClick={() => console.log(typeof data.ans[i])}
                                >
                                  <Input
                                    value={data?.ans?.[i]?.toString() || ""}
                                    type="text"
                                    onChange={(e) => {
                                      if (data?.ans?.[i])
                                        data.ans[i] = e.target.value;
                                      setDataSet([...data]);
                                    }}
                                  />
                                </TableCell>
                              )
                            }
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>)}
                </Col>
              </>
            </AddSkillSection>
          </>
        )}

        {/* <div
          style={{
            paddingTop: 50,
          }}
        >
          <AddMemberCarroussel
            host={host}
            eventId={eventId}
            searchKeywordAttendees={searchKeywordAttendees}
            people={myData || []}
            isVerified={false}
            addVerifyButton={myInfo?.handle && allAccess}
            handleVerify={(info: any) => {
              console.log("handleInfo", info);

              if (!(eventId && eventInfo?.handle && allAccess)) {
                alert("You are not allowed to take this action");
                return;
              }

              const data = {
                query: {
                  status: "approved",
                  handle: info?.handle,
                  eventId: eventId,
                  name: info?.name,
                  title: eventInfo?.title,
                  hostHandle: myInfo?.handle,
                },
                resource: resource,
                check: ["handle", "eventId"],
                myInfo,
              };

              saveDataDB(data)
                .then((res) => {
                  //setRefreshing(true);
                })
                .catch((error) => {
                  console.error("Failed to update database", error);
                });
            }}
            handleAdd={(info: any) => {
              console.log("handleInfo", info);

              if (!(eventId && eventInfo?.handle)) {
                return;
              }

              if (!info?.email) {
                alert('Email Required')
                return
              }
              const data = {
                query: {
                  status: "request",
                  email: info?.email,
                  name: info?.name,
                  handle: info?.handle,
                  eventId: eventId,
                  title: eventInfo?.title,
                  hostHandle: myInfo?.handle,
                },
                resource: resource,
                check: ["handle", "eventId"],
                myInfo,
              };

              saveDataDB(data)
                .then((res) => {
                  // console.log("Databasexxx", res);
                  //setRefreshing(true);
                })
                .catch((error) => {
                  console.error("Failed to update database", error);
                });
            }}
            handleRemove={(info: any) => {
              console.log("handleInfo", info);

              if (!(eventId && eventInfo?.handle)) {
                return;
              }

              deleteDataDB({
                id: info?._id,
                resource: resource,
              }).then((rep: any) => {
                alert("Deleted");
                //setRefreshing(true);
              });
            }}
            // setLocalAction={setLocalAction}
            isSmall={true}
          />
        </div> */}
      </div>
    </>
  );
};

const Input = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const LinkParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
`;

const AddSkillSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

const UploadedResume = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  p {
    font-size: 12px;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    width: 15px;
    height: 100%;
    color: #479d83;
  }
  button {
    border: 1px solid #479d83;
    padding: 3px 8px;
    background: #fff;
    font-size: 12px;
    transition: background 0.3s;
    margin: 0;
    &:hover {
      background: #479d832b;
    }
  }
`;

const UploadResumeFile = styled.label`
  background: #479d832b;
  border: 1px dashed gray;
  width: 100%;
  padding: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 10px;
    color: gray;
    margin: 0;
  }
  svg {
    width: 40px;
    color: #479d83;
    height: 100%;
  }
`;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
