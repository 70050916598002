import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  groupByNow,
  jumpToReleventDiv,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import moment from "moment";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { SearchEventAndGuest } from "../SearchEventAndGuest";

import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  X,
} from "@mui/icons-material";

import HeroBannarImg from "./HeroBannarImg";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { UserProfileModal } from "./UserProfileModal";
import MyHeaderBar from "../MyHeader";
import MobMenu from "../MobMenu";
import StoryTemplate, { StoryButtonItem } from "./StoryTemplate";
import MobMenuSearch from "../MobMenuSearch";


export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export default function Sidebar(props: any) {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  const {
    myInfo,
    myInfo: { active = "2" },
    host,
    hostId,
    myEvents,
    openAds = false,
    eventTabs = [],
    // selectedEvent,
    people = data || [],
    openAccessControl = false,
  } = myContext;
  const { setOpenEventForm } = props;

  const [formInput, setFormInput] = useState<any>({});
  // const [selEvent, setSelEvent] = useState<any>(selectedEvent);
  // const [openAccessControl, setOpenAccessControl] = useState<boolean>(false);

  const [localAction, setLocalAction] = useState<any>();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const location = useLocation();
  // const [indexStop, setIndexStop] = useState<any>(false);

  // const [eventDetails, setEventDetails] = useState<any>({});

  const [addProfCat, setAddProfCat] = useState<boolean>(false);
  const [newCatInput, setNewCatInput] = useState<string>("");

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);


  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);


  const resource = useMemo(() => {
    const tab = eventTab || hostTab
    const subTab = eventSubTab || hostSubTab
    return `calendar_${params.eventId ? "event" : "host"
      }${tab ? '_' + tab : ''}${subTab ? '_' + subTab : ''}`
  }, [
    eventTab,
    eventSubTab,
    hostSubTab,
    hostTab,
    params?.eventTab
  ]);


  useEffect(() => {
    // console.log('vvvvvvvggv', resource)
    if (eventId) {
      getDataDB({
        query: {
          _id: eventId
        },
        resource: 'calendar_events'
      }).then(res => {
        // console.log('våvvvvvvggvvv', res)
        const info = res?.[0]

        getDataDB({
          query: {
            handle: info?.handle
          },
          resource: `calendar_users`
        }).then(res => {
          if (res?.[0]) {
            // console.log('vvvvvvvgg', res)
            setMyContext((existing: any) => {
              return {
                ...existing,
                host: res?.[0],
                eventList: info,
                // myInfo: {
                //   ...myInfo,
                //   eventList: info
                // }
              };
            });
          }
        })

      })
    }
  }, [
    eventId,
    eventTab,
    eventSubTab
  ]);

  useEffect(() => {
    (async () => {

      if (params.hostId) {
        setMode("guests");
      }

      const myHostInfo =
        params.hostId &&
        (await getCalendarUsers({
          handle: params.hostId,
        }).then((ref) => {
          return ref?.map((res: any) => {
            return {
              ...res,
              id: res._id,
            };
          })?.[0];
        }));

      const report = params.eventId
        ? { eventId: params.eventId }
        : { host: { ...myHostInfo } };

      const page: any = {
        ...report,
        ...(params.hostId ? { hostId: params.hostId } : {}),
      };
      console.log("vvvvvdddd", page);

      await handleInfo({ info: { handle: params.hostId }, myInfo }).then(
        (res: any) => {
          // setRefreshing(false);
          setMyContext((existing: any) => {
            return {
              ...existing,
              eventDetails: res,
            };
          });
        }
      );

      setMyContext((existing: any) => {
        return {
          ...existing,
          ...page,
          // eventTab: params.eventId ? "attend" : "schedule",
        };
      });
    })();
  }, [location?.pathname, params.hostId, params.eventId]);

  useEffect(() => {
    // console.log("vbbbbb", params?.advertId);
    setMyContext((existing: any) => {
      return {
        ...existing,
        openAds: params?.advertId ? true : false,
      };
    });
  }, [params?.advertId]);

  useEffect(() => {
    // console.log("paramsxx", params?.eventSubTab);


    // alert(hostTab)
    if (eventTab && eventId) {
      setMyContext((existing: any) => {
        return {
          ...existing,
          eventTab: eventTab,
        };
      });
    } else if (hostTab && hostId) {
      setMyContext((existing: any) => {
        return {
          ...existing,
          eventTab: hostTab,
        };
      });
    }
  }, [eventId, hostId, eventTab, hostTab, eventSubTab]);

  const navigate = useNavigate();
  const [index, setIndex] = useState<any>(0);
  const [mode, setMode] = useState<any>("events");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [searchKeywordGuest, setSearchKeywordGuest] = useState<string>("");
  const [searchKeywordAttendees, setSearchKeywordAttendees] =
    useState<string>("");

  const [mobMenu, setMobMenu] = useState<boolean>(false);



  const eventList = useMemo(() => {
    return myEvents?.filter((res: any) => res._id == eventId)?.[0];
  }, [eventId]);

  const advertInfo = useMemo(() => {
    // backUrl
    return params?.eventSubTab == "ads" || params?.advertId ? true : false;
  }, [params?.eventSubTab, params?.advertId]);

  // console.log("eventList", params);
  const [count, setCount] = useState(0);
  const isInitialRender = useRef(true); // Track initial render
  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the effect on the initial render
      isInitialRender.current = false;
      return;
    }
    jumpToReleventDiv("mainPage");
  }, [
    count,
    mode
  ])

  return (
    <>
      {/* {mode === "stories" && (
        <StoryTemplate
          {...props}
          page={'landing'}
          handleCloseStory={() => {
            setMode('events')
          }}
        // storyResourceProps={storyResource}
        />
      )} */}
      <Modal
        // transparency={false}
        style={{
          // backgroundColor: 'red'
        }}
        show={mobMenu}
        onHide={() => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              openAds: false,
            };
          });
        }}
      >
        <MobMenuSearch
          mobMenuColor={'mobMenuColor'}
          // setMobMenuColor={setMobMenuColor}
          eventTabs={eventTabs}
          eventTab={eventTab}
          setMobMenu={setMobMenu}
          page={'landing'}
          // setOpenMenu={setOpenMenu}
          eventId={eventId}
        />
      </Modal>
      <Modal
        show={advertInfo}
        onHide={() => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              openAds: false,
            };
          });
        }}
      >
        <AdvertDetailsCard {...props} />
      </Modal>
      <MediaContainer>
        <div
          style={{
            position: "relative",
            width: "100%",
            marginBottom: 20,

          }}
        >
          <MyHeaderBar
            {...props}
            setMode={setMode}
            mode={mode}
            setMobMenu={setMobMenu}
          />
          <HeroBannerWrapper style={{
            // ...(!mode ? { height: 100 } : {}),
            // backgroundColor: 'red',
          }}>
            <HeroBannerChild>
              <HeroBannerTextWrapper>
                <h1>Passion</h1>
                <h1
                  style={{
                    color: "#479d83",
                  }}
                >
                  meets
                </h1>
                <h1>Market Place</h1>
                <p>
                  Boost your net worth by widening your network with GuestNet.
                  Our users are integral stakeholders in our ecosystem, where
                  every action triggers a monetizable reaction.
                </p>
                <StoryButtonItem
                  style={{
                    color: 'lightcyan',
                    right: 20
                  }}
                  onClick={() => {
                    // setMode(!mode)
                    // window.open(item?.link)
                    window.open(`/guestsite/${'Guest Net'}`, '_self');

                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                  More..
                </StoryButtonItem>
              </HeroBannerTextWrapper>
              <HeroBannarImg />
            </HeroBannerChild>
          </HeroBannerWrapper>

        </div>
        <div id={'mainPage'}></div>


        {/* Profiles */}
        <div>
          <ProfileInfo
            mode={mode}
            {...props}
            handleInfo={(info: any) => {
              handleInfo({ info, myInfo }).then((res: any) => {
                // setRefreshing(false);
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    eventDetails: res,
                  };
                });
              });
            }}
            setLocalAction={setLocalAction}
            searchKeyword={searchKeyword}
            searchKeywordGuest={searchKeywordGuest}
          />
        </div>

        {openAccessControl && (
          <GuestRoleManagerModal
            {...props}
            host={host}
            eventId={eventId}
            setOpenEventForm={setOpenEventForm}
          // setOpenSignin={setOpenSignin}
          />
        )}
        {(eventTab || hostTab) && (
          <UserProfileModal
            {...props}
            host={host}
            eventId={eventId}
            setOpenEventForm={setOpenEventForm}
          // setOpenSignin={setOpenSignin}
          />
        )}
      </MediaContainer>

    </>
  );
}

const ProfileInfo = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const [formInput, setFormInput] = useState<any>({});
  const [editAgenda, setEditAgenda] = useState<any>({});
  const [index, setIndex] = useState<any>(0);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { setLocalAction, mode } = props;

  const {
    myInfo,
    host,
    // people = data,
    allMyEvents,

    searchBy,
    eventDetails = {},
    indexStop = false,
    // people = data || [],
    myEventsInfo = [],
    // pins = []

    // attendees
    // comments = [],
  } = myContext;

  const { contacts, shares } = eventDetails;
  const params: any = useParams<any>();
  const [eventList, setEventList] = useState<any>([]);
  const [initialPeople, setInitialPeople] = useState<any>([]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);

  useEffect(() => {
    (async () => {

      const pins = myInfo?.handle ? await getDataDB({
        query: {
          handle: myInfo?.handle,
        },
        resource: "calendar_event_pin",
      }).then((res) => {
        return res?.map((ref: any) => ref?.eventId) || []
      }) : []



      getDataDB({
        query: {
          handlessss: null,
        },
        resource: "calendar_events",
      }).then((res: any) => {
        if (res?.[0]) {
          const result: any = [
            ...res?.map((rat: any) => {
              rat["image"] =
                "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg";
              return rat;
            }),
          ];

          const topItems = result?.filter((res: any) => pins?.includes(res?._id))?.map((reh: any) => {
            return {
              ...reh,
              pinned: true
            }
          })
          const bottomItems = result?.filter((res: any) => !pins?.includes(res?._id))
          const report = [...topItems, ...bottomItems]?.filter(res => {
            return !res?.delete
          });

          console.log("resultsss", report);
          setMyContext((existing: any) => {
            return {
              ...existing,
              selectedEvent: report?.filter(
                (res: any) => res._id == params.eventId
              )[0],
            };
          });
          setEventList(report);
        }
      });




    })()

  }, [
    mode,
    refreshing,
    myInfo?.handle
  ]);

  useEffect(() => {

    if (!myInfo?.handle) {
      return
    }
    getDataDB({
      query: {
        handle: myInfo?.handle,
      },
      resource: "calendar_lead_funnel",
    }).then((res) => {
      const groupUtil = groupByNow({
        data: res,
        key: "itemId",
        id: 2,
      });
      // console.log("myLike", res);
      // setFunnel(groupUtil)
      setMyContext((existing: any) => {
        return {
          ...existing,
          funnel: groupUtil,
        };
      });
      // setRefreshing(false)
    });


  }, [
    myInfo?.handle
  ]);



  useLayoutEffect(() => {
    (async () => {
      const databaseUsers = await getDataDB({
        query: {
          eventIdsdsd: null,
        },
        resource: "calendar_users",
        limit: 50,
      }).then((res) => {
        return Promise.all(
          res.map(async (rat: any) => {
            const user = await getCalendarUsers({
              handle: rat?.handle,
            }).then((ref) => {
              return ref?.[0];
            });
            // console.log("sssssuserzz", user);

            return {
              id: rat?._id,
              img:
                user?.img ||
                "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
              image:
                user?.img ||
                "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
              name: rat?.name,
              title: user?.title,
              quote: user?.company,
              handle: rat?.handle,
              user: user,
            };
          })
        );
      });
      const report = [...databaseUsers];

      // console.log("reportxxx", report);
      setInitialPeople(report);
      setMyContext((existing: any) => {
        return {
          ...existing,
          people: report,
        };
      });
      setRefreshing(false)

    })();
  }, [
  ]);

  const likeInfo = useMemo(() => {
    return contacts?.filter((rex: any) => {
      return rex.item == host?.handle;
    })?.[0];
  }, [contacts, myInfo?.handle, host?.handle]);

  return (
    <div
      // This ClassName is not needed it is making design worse
      // className="section-center"
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        width: "100vw",
      }}
    >
      {/* Event Guest Toggling */}
      {/* {searchBy == "Event" || !searchBy ? ( */}
      {props.mode === "events" ? (
        <Events
          refreshing={refreshing}
          setRefreshing={setRefreshing}
          people={(eventList || []).filter(
            (event: any) =>
              event?.title
                ?.toLowerCase()
                .includes(props.searchKeyword.toLowerCase()) ||
              event?.name
                ?.toLowerCase()
                .includes(props.searchKeyword.toLowerCase()) ||
              event?.room
                ?.toLowerCase()
                .includes(props.searchKeyword.toLowerCase()) ||
              event?.title
                ?.toLowerCase()
                .includes(props.searchKeyword.toLowerCase())
          )}
          handleInfo={(info: any) => {
            handleInfo({ info, myInfo }).then((res: any) => {
              // setRefreshing(false);
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  eventDetails: res,
                };
              });
            });
          }}
          setLocalAction={setLocalAction}
        />
      ) : props.mode === "category" ? (
        <Events
          refreshing={refreshing}
          setRefreshing={setRefreshing}
          // people={eventList || []}
          people={(eventList || []).filter((event: any) =>
            props.searchKeyword
              ?.toLowerCase()
              .includes(event.category.toLowerCase())
          )}
          handleInfo={(info: any) => {
            handleInfo({ info, myInfo }).then((res: any) => {
              // setRefreshing(false);
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  eventDetails: res,
                };
              });
            });
          }}
          setLocalAction={setLocalAction}
        />
      ) : (
        <MainContainer>
          <CardsContainer>
            <CarrousselCard
              people={initialPeople.filter(
                (event: any) =>
                  event?.title
                    ?.toLowerCase()
                    .includes(props.searchKeywordGuest.toLowerCase()) ||
                  event?.name
                    ?.toLowerCase()
                    .includes(props.searchKeywordGuest.toLowerCase()) ||
                  event?.room
                    ?.toLowerCase()
                    .includes(props.searchKeywordGuest.toLowerCase()) ||
                  event?.title
                    ?.toLowerCase()
                    .includes(props.searchKeywordGuest.toLowerCase())
              )}
              isSmall={true}
            />
          </CardsContainer>
        </MainContainer>
      )}
    </div>
  );
};

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin: 40px 40px;
  font-family: "Poppins", sans-serif;
  span {
    width: 100%;
    max-width: 930px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    span {
      color: #828282;
    }
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

const EmailText = styled.span`
  font-size: 16px;
`;

const InviteesList = styled.div`
  margin-top: 20px;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;


const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;

  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const SearchWrapper = styled.div`
  max-width: 930px;
  width: 100%;
  border: 1px solid #dfe0e4;
  border-radius: 10px;
  margin-top: -120px;
  position: relative;
  z-index: 10;
  background: #fff;
`;

const SearchBarContainer = styled.div`
  padding: 28px;
`;

const ToggleMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  border-bottom: 1px solid #dfe0e4;
`;

const ToggleButtons = styled.a<{ active: boolean }>`
  text-decoration: none;
  padding: 17px 0;
  color: #000;
  min-width: 50px;
  border-bottom: ${({ active }) => (active ? "2px solid #479d83" : "none")};
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

const EventImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 2px solid #dee2e6;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;

const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "White")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const GuestImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px 14px;
  margin-top: 14px;
`;

const GuestMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px !important;
  width: 140px !important;
  background-color: black;
  border-radius: 5px;
  position: relative;
  video,
  img {
    background-color: black;
    border-radius: 5px;
  }
`;

const GuestMediaHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 12px;
    max-width: 12px;
    height: 12px;
  }
  p {
    font-size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    width: 50%;
    color: white;
    margin: 0;
  }
  div {
    display: flex;
    gap: 4px;
    span {
      padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const GuestMediaParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  max-height: 500px;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: white;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const ButtonF = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PostHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const MenuWrapperPosts = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

export const DropdownFilterTabsContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

const FilterChild = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    width: fit-content;
  }
`;

const DropdownFilterTabs = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  z-index: 9999;
`;

const ButtonFilterTabs = styled.button`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 8px;
  margin: 0;
  cursor: pointer;

  svg {
    color: #333;
    width: 15px;
    height: 15px;
  }
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonIndustry = styled.button`
  background-color: #ffffff;
  color: #333;
  padding: 2px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-width: 160px;
  gap: 8px;
  font-size: 14px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContentIndustry = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

const DropdownItemIndustry = styled.div<any>`
  padding: 10px;
  cursor: pointer;
  color: #333;
  font-size: 12px;
  &:hover {
    background-color: #f1f1f1;
  }

  ${(props) =>
    props.active &&
    `
    background-color: #ddd; 
    font-weight: bold;
  `}
`;

const TopHeaderWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 5px;
  margin-top: -10px;
`;

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin-top: -20px; */
`;

const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const PriceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
`;

const Img = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const SupCardHeader = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  padding: 0px 10px;
`;

const CardLabelSub = styled.h3`
  margin: 0;
  font-size: 14px;
  color: gray;
  padding: 0px 10px;
`;

const CardPriceSub = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  padding: 0px 10px;
`;

const CardSupOffer = styled.div`
  margin: 0;
  font-size: 10px;
  color: gray;
  padding: 0px 10px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const HeroBannerWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  background: #96c7b8;
  min-height: 500px;
  @media (max-width: 800px) {
    min-height: 350px;
  }
  @media (max-width: 500px) {
    min-height: 300px;
  }
`;

const HeroBannerChild = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const HeroBannerTextWrapper = styled.div`
  display: flex;
  max-width: 440px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: white;

  h1 {
    font-size: 66px;
    line-height: 49px;
    font-weight: 900;
    margin: 0;
    text-align: start;
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: 200;
    text-align: start;
    color: white;
  }

  @media (max-width: 800px) {
    align-items: center;
    text-align: center;

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 500px) {
    max-width: 280px;

    h1 {
      font-size: 40px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
    }
  }
`;
