import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  saveDataDB,
  getDataDB,
  getDataWildDB,
  saveFunnelLeadsDB,
  deleteDataDB,
  groupByNow,
} from "src/api";
import {
  EditSkillsModalWrapper,
  SkillChildWrapper,
  TabListItems,
} from "./TabListItems";
import moment from "moment";
import { AgendaFormComponent, InputAgenda } from "../AgendaFormComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "src/App";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Select from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";

// import { MessageCircle } from "react-feather";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { orange, green, blue } from "@mui/material/colors";
import {
  Add as AddIcon,
  ControlPoint,
  RemoveCircleOutline,
  Close,
  Add,
} from "@mui/icons-material";

const _ = require("lodash");

export const PlannerFunnelModal = (props: any) => {
  const {
    item = {
      id: uuidv4(),
      // title: "Google Adwords best practices",
      // badges: [],
      // notifications: 0,
      // avatars: [2, 3],
    },
    resource = "calendar_event_kanban",
    setRefreshing,
    refreshing,
    funnelTopic,
    plannerTopic
  } = props;

  console.log("itemxxx", item);
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
  } = myContext;
  const location = useLocation();

  // const [efreshing, setRefreshing] = useState<boolean>(false);

  const [formInput, setFormInput] = useState<any>({});

  // const [defaultForm, setDefaultForm] = useState<any>({
  //   ["Backlog"]: {
  //     title: "Backlog",
  //     description: "",
  //     items: [] //mockItems1,
  //   },
  //   ["In Progress"]: {
  //     title: "In Progress",
  //     description: "",
  //     items: [] //mockItems2,
  //   },
  //   ["Completed"]: {
  //     title: "Completed",
  //     description: "",
  //     items: [] //mockItems3,
  //   },
  // });

  // useEffect(() => {

  //   if (myInfo?.handle) {
  //     getDataDB({
  //       query: {
  //         handle: myInfo?.handle,
  //       },
  //       resource: resource,
  //     }).then((res) => {
  //       if (res?.[0]?.columns
  //       ) {
  //         console.log('funnelLeads33', res)

  //         if (res?.[0]?.columns?.[0]) {
  //           setDefaultForm(res?.[0]?.columns);
  //         }
  //       }
  //     });
  //   }

  // }, [
  //   myInfo?.handle,
  // ]);

  useEffect(() => {
    if (item?.badges) {
      delete item?.topic;
      setFormInput({
        ...(plannerTopic ? {
          topic: plannerTopic,
          plannerTopic: plannerTopic,
        } : {}),
        ...(funnelTopic ? {
          funnelTopic: funnelTopic,
        } : {}),
        ...item,
      });
    }
  }, [item?.badges]);

  // const [formTitle, setFormTitle] = useState<any>();
  // const [description, setdescription] = useState<any>();

  // const [selected, setSelected] = useState<any>([])

  const [inputFieldFunnel, setInputFieldFunnel] = useState<any>();
  // const [selectedFunnel, setSelectedFunnel] = useState<any>([])

  // const [priority, setPriority] = useState<any>();
  // const [due, setDue] = useState<any>();

  const [inputField, setInputField] = useState<any>("");
  const [options, setOptions] = useState<any>([]);
  const [funnelTopicsOptions, setFunnelTopicsOptions] = useState<any>([]);
  const [planerTopicsOptions, setPlanerTopicsOptions] = useState<any>([]);

  // const [isSaveForm, setIsSaveForm] = useState<boolean>(false)
  console.log("reportsssvv4", formInput, resource);

  const isSaveForm = useCallback(() => {
    // if (isSaveForm) {
    // alert(55)
    // const {
    // formTitle,
    // selected,
    // selectedFunnel,
    // due,
    // priority,
    // description
    // } = formInput
    // const filteId: any = defaultForm?.["Backlog"]?.items?.filter((res: any) => res?.id == item?.id)?.[0] || {}
    // const filteIdNot: any = defaultForm?.["Backlog"]?.items?.filter((res: any) => res?.id != item?.id) || []
    // const originalData = { ...item }
    const newInfo = {
      ...formInput,

      // ...filteId,
      // id: item?._id,
      // ...(selected?.[0] ? { assign: selected } : {}),
      // ...(selectedFunnel?.[0] ? { funnel: selectedFunnel } : {}),
      // ...(due ? { due: due } : {}),
      // ...(priority ? { priority: priority } : {}),
      // title: formTitle || filteId?.title,
      // // description: description || filteId?.description,
      // badges: [green[600], orange[600]],
      // notifications: 2,
      location: location?.pathname,
      // avatars: [1, 2, 3, 4],
    };
    // console.log('newInfoxxxx', newInfo)
    delete newInfo?._id;

    // const report: any = {
    //   ...defaultForm,
    //   ["Backlog"]: {
    //     title: "Backlog",
    //     description: "",
    //     items: [
    //       ...filteIdNot,
    //       newInfo
    //     ],
    //   }
    // }

    console.log("reportsssvv", formInput, newInfo, resource);
    const data = {
      query: {
        ...newInfo,
        topic: formInput?.plannerTopic,
        task: formInput?.task || "Backlog",
        badges: formInput?.badges?.[0] ? formInput?.badges : [green[600], orange[600]],
        handle: myInfo?.handle,
      },
      resource: resource,
      check: ["handle", "id"],
      myInfo,
    };

    console.log("FormFields-person", data);

    saveDataDB(data).then((ref) => {
      setMyContext((existing: any) => {
        return {
          ...existing,
          funnelItem: {},
        };
      });
      // if (setRefreshing) {
      //   setRefreshing(true)
      // }
    });
    // setDefaultForm(report)

    // setFormTitle('')
    // }
  }, [
    formInput
  ]);

  useEffect(() => {
    if (myInfo?.handle) {
      getDataDB({
        query: {
          handle: myInfo?.handle,
        },
        resource: "calendar_event_funnel",
      }).then((res) => {
        const groupUtil = groupByNow({
          data: res,
          key: "topic",
          id: 2,
        });

        if (groupUtil && Object.keys(groupUtil)?.[0]) {
          const result = Object.keys(groupUtil)?.map((ref: any) => {
            return {
              ...ref,
              label: ref,
              name: ref,
              value: ref,
            };
          });
          setFunnelTopicsOptions(result);
        }
      });

      getDataDB({
        query: {
          handle: myInfo?.handle,
        },
        resource: "calendar_event_kanban",
      }).then((res) => {
        const groupUtil = groupByNow({
          data: res,
          key: "topic",
          id: 2,
        });

        if (groupUtil && Object.keys(groupUtil)?.[0]) {
          const result = Object.keys(groupUtil)?.map((ref: any) => {
            return {
              ...ref,
              label: ref,
              name: ref,
              value: ref,
            };
          });
          setPlanerTopicsOptions(result);
        }
      });
    }
  }, [myInfo?.handle]);


  //add to funnel
  useEffect(() => {
    // console.log('lllllll', location?.pathname,)
    if (formInput?.funnels?.[0] && formInput?.assign) {
      // console.log('funnel', formInput?.funnels)
      Promise.all(
        formInput?.assign?.map(async (person: any) => {
          return await Promise.all(
            formInput?.funnels?.map(async (funnel: any) => {
              // alert(funnel?.value)
              return await saveFunnelLeadsDB({
                ...formInput,
                item: formInput,
                topic: formInput?.funnelTopic,
                eventId: eventId,
                guest: person,
                myInfo: myInfo,
                funnel: funnel?.value || 'Awareness',
              });
            })
          );
        })
      );
    }
  }, [
    formInput?.funnelTopic,
    myInfo?.handle,
    formInput?.assign,
    formInput?.funnels,
  ]);

  const params = useParams();

  useEffect(
    _.debounce(() => {
      if (!(inputField?.length > 0)) {
        return;
      }
      const data = {
        key: "name",
        request: "wildsearch",
        searchVal: inputField,
        resource: `calendar_users`,
        limit: 400,
      };
      // console.log('formFieldxx44', defaultForm?.title)

      getDataWildDB(data).then((res: any) => {
        // console.log('formFieldxx44', res)
        if (res?.[0]) {
          const result: any = res?.map((person: any) => {
            return {
              // ...person,
              // active: true,
              // link: `https://www.myguestnet.com/host/${person?.handle}`,
              // img:
              //   person.img ||
              //   "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
              // title: person.name,
              // name: person.name,
              label: person.name,
              value: person?.email,
              handle: person?.handle,
              img: person?.img,
              // handle: person?.handle,
            };
          });
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle);
          setOptions([...options, ...result]);
        }
      });
    }, 500),
    [inputField]
  );

  const apiRequestTypes = [
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Low", value: "low" },
  ];

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const [funnelData, setFunnelData] = useState<any>({});

  useEffect(() => {
    if (!formInput.funnelTopic) {
      return
    }
    console.log('vvbbbbxx', formInput.funnelTopic)

    getDataDB({
      query: {
        handle: myInfo?.handle,
        eventId: eventId,
      },
      resource: "calendar_event_funnel",
    }).then((funnels: any) => {
      if (funnels?.[0]) {
        console.log('vvbbbb', funnels?.[0])
        const temp = funnels?.filter((ref: any) => ref.topic == formInput.funnelTopic)
        setFunnelData(temp?.[0]);
        setRefreshing(false)
      }
    });
  },
    [
      refreshing,
      myInfo?.handle,
      eventId,
      formInput.funnelTopic
    ]
  );

  const filteData: any = useMemo(() => {
    return item;
  }, [item]);

  console.log('plannerTopic', plannerTopic, formInput)

  return (<>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: 'rgba(0,0,0,0.1)',
        padding: 5
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
        }}>
          <div style={{
            marginTop: 10
          }}>Add to Funnel</div>
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                display: "flex",
                position: "relative",
                width: "100%",
                marginTop: 5,
                marginBottom: 5,
              }),
            }}
            onCreateOption={(e: any) => {
              console.log('creat', e)
              // alert(e)
              if (e) {
                const data = {
                  query: {
                    topic: formInput?.topic,
                    funnel: [
                      { name: 'Awareness', value: 0 },
                      { name: 'Interest', value: 0 },
                      { name: 'Consideration', value: 0 },
                      { name: 'Evaluation', value: 0 },
                      { name: 'Commitment', value: 0 },
                      { name: 'Sale', value: 0 }
                    ],
                    handle: myInfo?.handle,
                    eventId: eventId,
                  },
                  resource: 'calendar_event_funnel',
                  check: ["handle", "topic"],
                  myInfo,
                };
                console.log('foformDatasssrmData', data)
                saveDataDB(data).then((res) => {
                  setRefreshing(true);
                });
              }

            }}
            // onInputChange={(e: any) => {
            //   // console.log('personinfobb', e)
            // }}
            value={
              formInput.funnelTopic
                ? [
                  {
                    label: formInput.funnelTopic,
                    value: formInput.funnelTopic,
                  },
                ]
                : []
            }
            onChange={(e) => {
              // alert(e?.value + 'cc')
              // console.log('personinfo', e)
              // setPriority(e?.value)
              setFormInput({
                ...formInput,
                funnelTopic: e?.value,
              });
            }}
            // isMulti
            isClearable
            options={funnelTopicsOptions || []}
            placeholder="Select Funnel"
            classNamePrefix="selectform"
          />
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                display: "flex",
                position: "relative",
                width: "100%",
                marginTop: 5,
                marginBottom: 5,
              }),
            }}
            onInputChange={(e: any) => {
              // console.log('personinfobb', e)
              setInputFieldFunnel(e);
            }}
            value={formInput?.funnels?.[0] ? formInput?.funnels : []}
            onChange={(e: any) => {
              // console.log('personinfo', e)
              // setSelectedFunnel(e);
              setFormInput({
                ...formInput,
                funnels: e,
              });
            }}
            isMulti
            isClearable
            options={
              funnelData?.funnel?.map((res: any) => {
                return {
                  label: res?.name,
                  value: res?.name,
                };
              }) || []
            }
            placeholder="Lead Stage"
            classNamePrefix="selectform"
          />
        </div>
      </div>
      <div>
        <div style={{
          marginTop: 10
        }}>Add to Planner</div>
        <Select
          styles={{
            control: (styles) => ({
              ...styles,
              display: "flex",
              position: "relative",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }),
          }}
          onCreateOption={(e: any) => {
            // console.log('creat', e)
            // alert(e)
            if (e) {
              const data = {
                query: {
                  topic: formInput?.topic,
                  // title: formInput?.title,
                  handle: myInfo?.handle,
                  eventId: eventId,
                },
                resource: 'calendar_event_kanban',
                check: ["handle", "topic"],
                myInfo,
              };
              console.log('foformDatasssrmData', data)
              saveDataDB(data).then((res) => {
                setRefreshing(true);
              });
            }

          }}
          value={formInput?.plannerTopic
            ? [
              {
                label: formInput.plannerTopic,
                value: formInput.plannerTopic,
              },
            ]
            : []}
          onChange={(e: any) => {
            console.log("personinfo", e);
            // setPriority(e?.value)
            setFormInput({
              ...formInput,
              plannerTopic: e?.value,
            });
          }}
          // isMulti
          isClearable
          options={planerTopicsOptions || []}
          placeholder="Select Planner"
          classNamePrefix="selectform"
        />
      </div>
      <div>
        <div style={{
          marginTop: 10
        }}>Lead Title</div>
        <SkillChildWrapper
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <InputAgenda onSubmit={(e) => { }}>
            <input
              type={"text"}
              placeholder={"Add title"}
              value={formInput?.title || ""}
              onChange={(e: any) => {
                // setdescription(e?.target?.value)
                setFormInput({
                  ...formInput,
                  title: e?.target?.value,
                });
              }}
              // value={defaultForm?.description || ""}
              // placeholder={"Add task ..."}
              required
            />
          </InputAgenda>

          <InputAgenda onSubmit={(e) => { }}>
            <input
              type={"text"}
              placeholder={"Add comments or details"}
              value={formInput?.description || ""}
              onChange={(e: any) => {
                // setdescription(e?.target?.value)
                setFormInput({
                  ...formInput,
                  description: e?.target?.value,
                });
                // {
                //     formTitle,
                //     selected,
                //     selectedFunnel,
                //     due,
                //     priority,
                //     description
                //   }
              }}
              // value={defaultForm?.description || ""}
              // placeholder={"Add task ..."}
              required
            />
          </InputAgenda>
          {/* <Add onClick={() => {

              setOpenForm(false)
            }} /> */}
        </SkillChildWrapper>
      </div>
      <div>
        <div style={{
          marginTop: 10
        }}>Assign Task or Viewers</div>
        <Select
          styles={{
            control: (styles) => ({
              ...styles,
              display: "flex",
              position: "relative",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }),
          }}
          onInputChange={(e) => {
            // console.log('personinfobb', e)
            setInputField(e);
          }}
          defaultValue={
            formInput?.selected?.[0]
              ? [...formInput?.selected]
              : item.assign?.[0]
                ? item.assign
                : []
          }
          onChange={(e: any) => {
            // console.log('personinfo', e)
            // setSelected(e);
            setFormInput({
              ...formInput,
              assign: e,
            });
          }}
          isMulti
          isClearable
          options={options}
          placeholder="Assign"
          classNamePrefix="selectform"
        />
      </div>
      <div>

        <div style={{
          marginTop: 10
        }}> Due Date</div>
        <SkillChildWrapper
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <InputAgenda onSubmit={(e) => { }}>
            <input
              type={"date"}
              placeholder={"Due date"}
              value={
                formInput?.due
                  ? moment(formInput?.due)?.format("YYYY-MM-DD")
                  : "due date"
              }
              onChange={(e: any) => {
                // console.log('personinfo', e?.target?.value)

                // setDue(e?.target?.value)
                setFormInput({
                  ...formInput,
                  due: e?.target?.value,
                });
              }}
              // value={defaultForm?.description || ""}
              required
            />
          </InputAgenda>
        </SkillChildWrapper>
      </div>

      <div>
        <div style={{
          marginTop: 10
        }}> Priorty Level</div>
        <Select
          styles={{
            control: (styles) => ({
              ...styles,
              display: "flex",
              position: "relative",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }),
          }}
          value={
            formInput.priority
              ? [
                {
                  label: formInput.priority,
                  value: formInput.priority,
                },
              ]
              : [
                {
                  label: "Low",
                  value: "low",
                },
              ]
          }
          onChange={(e) => {
            setFormInput({
              ...formInput,
              priority: e?.value,
            });
          }}
          // isMulti
          isClearable
          options={apiRequestTypes}
          placeholder="Priority Level"
          classNamePrefix="selectform"
        />
      </div>

    </div>
    <div style={{
      display: 'flex',
      flexDirection: "row",
      width: '100%',
      justifyContent: 'space-around'
    }}>
      <Button
        color="secondary"
        variant="contained"
        // fullWidth
        onClick={() => {
          // setIsSaveForm(true)
          isSaveForm();
        }}
      >
        Delete
      </Button>
      <Button
        color="primary"
        variant="contained"
        // fullWidth
        onClick={() => {
          // setIsSaveForm(true)
          isSaveForm();
        }}
      >
        Save
      </Button>

    </div>

    {/* {filteData && (
      <Button
        color="secondary"
        variant="contained"
        style={{
          marginTop: 15,
        }}
        onClick={() => {
          let text = "Add to lead leads";
          if (window.confirm(text) == true) {
            text = "You pressed OK!";
            deleteDataDB({
              id: item?._id,
              resource: resource,
            });
          } else {
            text = "You canceled!";
            // setOpenSignin(true)
            return;
          }
        }}
      >
        <AddIcon />
        Delete
      </Button>
    )} */}
  </>);
};

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: 10px;
  }
`;

export const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid;
  margin-bottom: 10px;
  cursor: grab;

  &:hover {
    background: gray;
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: 10px;
  }
`;

export const TaskAvatars = styled.div`
  margin-top: 10px;
`;

// const MessageCircleIcon = styled(MessageCircle)`
//   color: ${(props) => props.theme.palette.grey[500]};
//   vertical-align: middle;
// `;

const TaskBadge = styled.div`
  background: gray;
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const TaskNotificationsAmount = styled.div`
  color: gray;
  font-weight: 600;
  margin-right: 10px;
  line-height: 1.75;
`;

const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: 10px;
`;
