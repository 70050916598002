// Change YOUR_API_KEY_HERE to your apiKey
import RESTCall from "./restApi";
import moment from "moment";
import { gapi } from "gapi-script";
const Moment = require("moment");
const MomentRange = require("moment-range");
const aiAiiKey = "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"
// export const sendEmail = async (email) => {
//   const { to, text, html, subject } = email
//   if (!text || !to) {
//     return
//   }
//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   // email['from'] = from || '"VareTech" <member@vareapp.com>' // sender address
//   email['to'] = to || to?.toString() // list of receivers
//   email['subject'] = subject || "Message from Vare Portal ✔" // Subject line
//   email['text'] = text || "Hello world?" // plain text body
//   email['html'] = text || `<b>${text?.toString()}</b>` // html body
//   var raw = JSON.stringify(email);

//   var requestOptions = {
//     method: 'POST',
//     headers: myHeaders,
//     body: raw,
//     redirect: 'follow'
//   };

//   fetch(`${urlPrefix}/email`, requestOptions)
//     .then(response => response.text())
//     .then(result => console.log(result))
//     .catch(error => console.log('error', error));
// }
export const makeUpperCase = (props) => {
  if (!(props?.length > 0)) {
    return "";
  }
  return (
    props?.substring(0, 1)?.toUpperCase() + props?.substring(1, props?.length)
  );
};

export const rootDomain = window.location.origin?.includes('localhost')
  ? '/localhost:3000' : 'myguestnet.com'; // Your existing root domain


export async function getDataWildDB(props) {
  const {
    query,
    key = "description",
    request = "wildsearch",
    searchVal = "xxxx",
    resource = "vare_app_news",
    limit = 400,
  } = props;

  if (!(resource && searchVal)) {
    return;
  }

  const formData = {
    request: request,
    query: query || {
      [key]: searchVal,
    },
    resource: resource,
  };

  return await RESTCall.axiosQuery(formData)
    .then((res) => {
      console.log("Error here", res);

      if (res) {
        return res?.data;
      }
      return [];
    })
    .catch((err) => {
      console.log("Error here", err);
      return [];
    });
}

export const colors = {
  button: "#f44a25",
  green1: "#edfbef",
  gray0: "#f1f3f4",
  gray1: "#f9fbfa",
  gray2: "#ececec",
  gray3: "#202C33",
  textinput: "#2C2C2E",
  // header: '#e8eef4',
  header: "#76D780",
  // success: '#327480',
  success: "rgba(34,36,40,1)",
  support: "#39cab4",
  reject: "#f74f75",
  democrat: "#0015bd",
  republican: "#f70000",
  common: "#327480",
  orange: "#F64911",
};

export const getUserInfoByHandle = async (handle) => {
  return await getDataDB({
    query: {
      handle: handle,
    },
    resource: "calendar_users", //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
    check: ["handle"],
  })?.then((rag) => rag?.[0]);
};

export const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export async function getDataWildArrayDB(props) {
  const {
    query,
    key = "dates",
    request = "wildsearchArray",
    searchVal = "04/15/24",
    resource = "",
    limit = 10000,
  } = props;

  if (!(resource && searchVal)) {
    return;
  }

  const formData = {
    request: request,
    query: query || {
      [key]: searchVal,
    },
    resource: resource,
    // limit: 400,
    // id: ""
  };
  if (limit) {
    formData["limit"] = limit;
  }
  // console.log('xxxxsformData', formData)

  return await RESTCall.axiosQuery(formData)
    .then((res) => {
      // console.log('xxxxsearchdata', res?.data)
      return res?.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export const getRecurringDates = (props) => {
  const { start = "2018-11-07", end = "2018-12-01" } = props;
  const moment = MomentRange.extendMoment(Moment);
  const range = moment.range(moment(start), moment(end));
  console.log(Array.from(range.by("week")));
  return Array.from(range.by("week"));
};


export const resizeImage = async (
  base64Str,
  maxWidth = 1920,
  maxHeight = 1080,
) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      // Calculate the ratio to maintain aspect ratio.
      const widthRatio = maxWidth / width;
      const heightRatio = maxHeight / height;
      const bestRatio = Math.min(widthRatio, heightRatio);

      // Adjust width and height based on the best ratio for scaling down or up
      width = width * bestRatio;
      height = height * bestRatio;

      // Ensure the scaled dimensions do not fall below the minimum required dimensions
      // This logic assumes that you want to scale up only if both dimensions are smaller than max dimensions
      if (width < maxWidth && height < maxHeight) {
        const upscaleRatio = Math.max(maxWidth / width, maxHeight / height);
        width *= upscaleRatio;
        height *= upscaleRatio;
      }

      canvas.width = Math.round(width);
      canvas.height = Math.round(height);
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

function sortDates(dates) {
  return dates.sort((a, b) => moment(a).diff(moment(b)));
}

export const checkDateSpan = (props) => {
  const {
    dateArray: info,
    days = 7
  } = props

  const dateArray = sortDates(info)
  if (dateArray.length === 0) return false;

  let minDate = moment(dateArray[0]);
  let maxDate = moment(dateArray[dateArray?.length - 1]);

  dateArray.forEach(date => {
    const momentDate = moment(date);
    if (momentDate.isBefore(minDate)) {
      minDate = momentDate;
    }
    if (momentDate.isAfter(maxDate)) {
      maxDate = momentDate;
    }
  });

  const startOfWeek = minDate.startOf('week').format('YYYY-MM-DD');


  // Calculate the difference in days
  const diffDays = maxDate.diff(startOfWeek, 'days');
  console.log("diffDays", dateArray, startOfWeek, maxDate, diffDays)
  // Check if the difference is greater than 7 days
  return diffDays > days;
};

export const getDateRange = (props) => {
  const { firstDate, lastDate } = props;
  if (
    moment(firstDate, "YYYY-MM-DD").isSame(
      moment(lastDate, "YYYY-MM-DD"),
      "day"
    )
  )
    return [lastDate];
  let date = firstDate;
  const dates = [date];
  do {
    date = moment(date).add(1, "day");
    dates.push(date.format("YYYY-MM-DD"));
  } while (moment(date).isBefore(lastDate));
  return dates?.map((date) => {
    return moment(date).format("MM/DD/YY");
  });
};


export const myColors = {
  brown: "#479d83", //'#ba5d2c',
  red: "#479d83",
  gray: "#617d98",
  green: "#c4e3da",
  darkGreen: "#479D83",
};
// import { createClient } from 'pexels';

var CLIENT_ID =
  "736539225825-mo62ta57dtlpr8luak2mdt9ieo1iqrpr.apps.googleusercontent.com";
var API_KEY = "AIzaSyA2ynyIIS__xCDklZgWEoZcDZMsCml9ruQ";
var DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
var SCOPES = "https://www.googleapis.com/auth/calendar.events";
const request = "";
// const client = createClient('tPOcC3re4a1Ks8UKrbh02pYptuGSKxZLTNoFzKHuk9mC8XyRjDyHddz4');

const urlPrefix = "/";

export const configGoogle = {
  apiKey: API_KEY,
  clientId: CLIENT_ID,
  discoveryDocs: DISCOVERY_DOCS,
  scope: SCOPES,
};

export function doesKeyExist(props) {
  const { obj, key } = props
  return obj.hasOwnProperty(key);
}

export const getCroppedImg = async (props) => {
  const {
    imageSrc, crop
  } = props
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        console.error("Canvas is empty");
        return reject();
      }
      blob.name = "croppedImage.jpeg";
      const fileUrl = window.URL.createObjectURL(blob);
      resolve(fileUrl);
    }, "image/jpeg");
  });
};

const createImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.setAttribute("crossOrigin", "anonymous"); // Avoid CORS issues
    img.src = url;
  });
};


//load add from API 
export const apiFetchData = async (props) => {
  const {
    endPoint = '',
    apiPostType = 'GET',
    skip = 0,
    limit = 10
  } = props

  console.log('xxxxresult33', props);

  const raw = "";

  const requestOptions = {
    method: "GET",
    body: raw,
    redirect: "follow"
  };


  const url = `https://uhvjtsxaivz3vc62iqzq7hxfqi0kyfwy.lambda-url.us-east-2.on.aws/varevotes?skip=${skip}&limit=${limit}`
  const searchResponse = await fetch(url);
  const reply = await searchResponse.json();
  console.log('xxxxresult', reply);

  return reply?.data || []

  // return await fetch(url)
  //   .then((response) => response.text())
  //   .then((result) => {
  //     alert(5)
  //     console.log('xxxxresult', result);
  //     return JSON.parse(result)?.body;
  //   })
  //   .catch((error) => console.error(error));
}


export const copyText = async (props) => {
  const { textToCopy } = props
  try {
    await navigator.clipboard.writeText(textToCopy);
    alert("Text copied to clipboard!");
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}

export function filterDuplicates(props) {
  const {
    array, key
  } = props
  const seen = new Set();
  return array.filter(item => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
}

//AI search
export const handleAISearch = async (props) => {
  const {
    responseType = "array",
    question = "I want to create an advertisement",
    input = [
      "Details",
      "Schedule",
      "Media",
      "People",
      "Catalog",
      "Markets",
      "Sponsor",
      "Planner",
      "Resource",
      "Reviews",
      "Comments",
      "Event Forms",
      "Frequently Asked Questions",
      "Asset/Item Tracker",
      "Email Campaign",
      "Valuation",
    ],
    responseLength = 5,
  } = props;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    question: question,
    responseType: responseType,
    responseLength: responseLength,
    input: input,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  const url =
    "https://52hygzg23ayywzsyx5kv7zhnjy0jmsty.lambda-url.us-east-2.on.aws/aigenerator/generateAnswers";
  // const url = "http://localhost:8000/aigeneration/generateAnswers
  return await fetch(url, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return JSON.parse(result)?.body;
    })
    .catch((error) => console.error(error));
};

export const getChatGPTResponse = async (props) => {
  const { prompt, myInfo } = props
  const apiKey = aiAiiKey;  // Replace with your OpenAI API key
  const apiUrl = 'https://api.openai.com/v1/chat/completions'; // OpenAI API endpoint

  const dbResult = await getDataDB({
    query: {
      query: prompt
    },
    resource: `vare_AIQuery_search_result`,
  })

  // console.log('querysssszzz', prompt)
  if (dbResult?.[0]?.result) {
    return dbResult?.[0]?.result
  }

  const requestBody = {
    model: "gpt-4",  // or "gpt-3.5-turbo" depending on your subscription
    messages: [
      { role: "system", content: "You are ChatGPT, a helpful AI assistant." },
      { role: "user", content: prompt + ` return results with list seperated with || and title and description seperated by -- withoout repeating the question` }
    ],
    max_tokens: 150,  // Adjust the number of tokens as needed
    temperature: 0.7  // Adjust the temperature to control the randomness
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw false;
    }

    const data = await response.json();
    // console.log('data.....', data)
    // console.log('data....22.', data.choices[0].message.content)
    const report = data?.choices?.[0]?.message?.content?.split('||')?.map(reply => {
      const item = reply?.split('--')
      return {
        title: item?.[0]?.replace(/[^\w\s]/gi, '')?.replace('1 ', '')?.replace(' \n\n2 ', '')?.replace(' \n\n3 ', '').trim(),
        description: item?.[1]?.replace(/[^\w\s]/gi, '')?.replace('1 ', '')?.replace(' \n\n2 ', '')?.replace(' \n\n3 ', '').trim(),
      }
    })
    // console.log('data.....ee', report)

    await saveDataDB({
      query: {
        query: prompt,
        result: report,
        date: moment()
      },
      myInfo,
      resource: `vare_AIQuery_search_result`,
      check: ["query"],
    })

    return report; // Return the AI's response
  } catch (error) {
    console.error('Error:', error);
    return 'An error occurred while processing your request.';
  }
}

export function stringifyArrayToBullets(props) {
  const { arr } = props
  return arr.map(item => `• ${item.title}: ${item.description}`).join('\n');
}

export function removeDuplicateObjects(props) {
  const {
    arr, key
  } = props
  const seen = new Set();
  return arr.filter(item => {
    const value = item[key];
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
}

export const handleCalendar = async (props) => {
  const { myAppointment } = props;
  const data = myAppointment;
  const {
    eventId = "",
    date = new Date(),
    room = "",
    host = "",
    guest = "",
    time = "",
    description = "",
    dateTime = "",
  } = data;

  // console.log("123", data);

  delete data?._id;

  if (
    eventId == "" ||
    room === "" ||
    room == "" ||
    host == "" ||
    description === "" ||
    time === "" ||
    date === ""
  ) {
    alert("Enter all the  fields");
  } else {
    gapi.load("client:auth2", () => {
      console.log("loaded client");

      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      gapi.client.load("calendar", "v3", () => console.log("bam!"));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          var event = {
            summary: "Affle Meeting Room",
            location: "India, Gurgaon",
            description: "",
            start: {
              dateTime: `${dateTime}`,
              timeZone: "Asia/Kolkata",
            },
            end: {
              dateTime: `${dateTime}`,
              timeZone: "Asia/Kolkata",
            },
            recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
            attendees: [
              { email: "lpage@example.com" },
              { email: "sbrin@example.com" },
            ],
            reminders: {
              useDefault: false,
              overrides: [
                { method: "email", minutes: 24 * 60 },
                { method: "popup", minutes: 10 },
              ],
            },
          };

          var request = gapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event,
          });

          request.execute((event) => {
            // console.log(event);
            window.open(`https://${event.htmlLink}`);
          });

          // get events
          return gapi.client.calendar.events
            .list({
              calendarId: "primary",
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: "startTime",
            })
            .then((response) => {
              const events = response.result.items;
              console.log("EVENTS: ", events);
              return events;
            });
        });
    });
  }
};

export const relatedPict = [
  "https://images.pexels.com/photos/3246665/pexels-photo-3246665.jpeg",
  "https://images.pexels.com/photos/102127/pexels-photo-102127.jpeg",
  "https://images.pexels.com/photos/1193743/pexels-photo-1193743.jpeg",
  "https://images.pexels.com/photos/962312/pexels-photo-962312.jpeg",
  "https://images.pexels.com/photos/1585325/pexels-photo-1585325.jpeg",
  "https://images.pexels.com/photos/743986/pexels-photo-743986.jpeg",
  "https://images.pexels.com/photos/1646953/pexels-photo-1646953.jpeg",
  "https://images.pexels.com/photos/1570264/pexels-photo-1570264.jpeg",
  "https://images.pexels.com/photos/1209843/pexels-photo-1209843.jpeg",
  "https://images.pexels.com/photos/886521/pexels-photo-886521.jpeg",
  "https://images.pexels.com/photos/699466/pexels-photo-699466.jpeg",
  "https://images.pexels.com/photos/889839/pexels-photo-889839.jpeg",
  "https://images.pexels.com/photos/1762851/pexels-photo-1762851.jpeg",
  "https://images.pexels.com/photos/1572386/pexels-photo-1572386.jpeg",
  "https://images.pexels.com/photos/1918290/pexels-photo-1918290.jpeg",
  "https://images.pexels.com/photos/159862/pexels-photo-159862.jpeg",
  "https://images.pexels.com/photos/1170576/pexels-photo-1170576.jpeg",
  "https://images.pexels.com/photos/1183992/pexels-photo-1183992.jpeg",
  "https://images.pexels.com/photos/1145720/pexels-photo-1145720.jpeg",
  "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg",
  "https://images.pexels.com/photos/20967/pexels-photo-20967.jpeg",
  "https://images.pexels.com/photos/1109354/pexels-photo-1109354.jpeg",
  "https://images.pexels.com/photos/942317/pexels-photo-942317.jpeg",
  "https://images.pexels.com/photos/1616403/pexels-photo-1616403.jpeg",
  "https://images.pexels.com/photos/542556/pexels-photo-542556.jpeg",
  "https://images.pexels.com/photos/587958/pexels-photo-587958.jpeg",
  "https://images.pexels.com/photos/1037993/pexels-photo-1037993.jpeg",
  "https://images.pexels.com/photos/1194420/pexels-photo-1194420.jpeg",
  "https://images.pexels.com/photos/1081685/pexels-photo-1081685.jpeg",
  "https://images.pexels.com/photos/1939485/pexels-photo-1939485.jpeg",
  "https://images.pexels.com/photos/354939/pexels-photo-354939.jpeg",
  "https://images.pexels.com/photos/39348/pexels-photo-39348.jpeg",
  "https://images.pexels.com/photos/1047540/pexels-photo-1047540.jpeg",
  "https://images.pexels.com/photos/1690351/pexels-photo-1690351.jpeg",
  "https://images.pexels.com/photos/164455/pexels-photo-164455.jpeg",
  "https://images.pexels.com/photos/2832382/pexels-photo-2832382.jpeg",
  "https://images.pexels.com/photos/1477166/pexels-photo-1477166.jpeg",
  "https://images.pexels.com/photos/925743/pexels-photo-925743.jpeg",
  "https://images.pexels.com/photos/1292241/pexels-photo-1292241.jpeg",
  "https://images.pexels.com/photos/804269/pexels-photo-804269.jpeg",
  "https://images.pexels.com/photos/311458/pexels-photo-311458.jpeg",
  "https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg",
  "https://images.pexels.com/photos/166277/pexels-photo-166277.jpeg",
  "https://images.pexels.com/photos/1084510/pexels-photo-1084510.jpeg",
  "https://images.pexels.com/photos/1266808/pexels-photo-1266808.jpeg",
  "https://images.pexels.com/photos/2086361/pexels-photo-2086361.jpeg",
  "https://images.pexels.com/photos/206064/pexels-photo-206064.jpeg",
  "https://images.pexels.com/photos/626163/pexels-photo-626163.jpeg",
  "https://images.pexels.com/photos/288099/pexels-photo-288099.jpeg",
  "https://images.pexels.com/photos/1109352/pexels-photo-1109352.jpeg",
];

export function groupByNow({ data, key, id }) {
  if (!(data && data?.length > 0 && key) || !key) {
    return {};
  }
  let group =
    key &&
    data &&
    Array.isArray(data) &&
    data.reduce((r, a) => {
      r[a[key]] = [...(r[a[key]] || []), a];
      return r;
    }, {});
  return group;
}

export async function saveCalendarUsersDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_users",
    check: ["email"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function cleanAndTrimText(props) {
  const { inputText, maxLength } = props
  // Remove special characters and trim the text
  let cleanedText = inputText.replace(/[^\w\s]/gi, '').trim();

  // If the cleaned text exceeds the maxLength, trim it
  if (cleanedText.length > maxLength) {
    cleanedText = cleanedText.substring(0, maxLength).trim();
  }

  return cleanedText;
}


//AI search
export const handleAIQuerySearch = async (props) => {
  const { query, myInfo } = props
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  // if (!myInfo?.handle) {
  //   // alert("Please Login");
  //   return;
  // }


  const result = await getDataDB({
    query: {
      query: query,
    },
    resource: `vare_AIQuery_search_result`,
  }).then(res => {
    console.log('querysssszzz44old', res)

    return res?.[0]?.result
  })

  // console.log('querysssszzz', result)
  if (result?.length > 0) {
    // console.log('queryssssresult', result)
    return result?.[0]
  }

  const raw = JSON.stringify({
    question: query,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    "https://52hygzg23ayywzsyx5kv7zhnjy0jmsty.lambda-url.us-east-2.on.aws/aigenerator/generalPrompt",
    requestOptions
  )
    .then((response) => {
      // console.log('cccccww', response);
      return response.text()
    })
    .then(async (result) => {
      await saveDataDB({
        query: {
          query: query,
          result: JSON.parse(result)?.body,
          date: moment()
        },
        myInfo,
        resource: `vare_AIQuery_search_result`,
        check: ["query"],
      })
      return JSON.parse(result)?.body
    })
    .catch((error) => console.error(error));
};

export async function getCalendarUsers(props) {
  const formData = {
    request: "search",
    query: {
      ...props,
    },
    resource: "calendar_users",
    id: "",
    limit: 200,
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      // console.log('xxxxxhhhhxx', props, response)
      return response?.data?.map((res) => {
        delete res.phone;
        delete res.email;
        delete res.age;
        return res;
      });
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}
// resource: "calendar_appointments",

export async function getAppointment(props) {
  const formData = {
    request: "search",
    query: {
      ...props,
    },
    resource: "calendar_appointments",
    id: "",
    limit: 20,
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      // console.log('xxxxxhhhhxx', props, response)
      return response?.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export async function getCalendarEvents(props) {
  const formData = {
    request: "search",
    query: {
      ...props,
    },
    resource: "calendar_events",
    id: "",
    limit: 20,
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      // console.log('xxxxxhhhhxx', props, response)
      return response?.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export async function getCalendarSchedule(props) {
  const formData = {
    request: "search",
    query: {
      ...props,
    },
    resource: "calendar_schedule",
    id: "",
    limit: 20,
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      // console.log('xxxxxhhhhxx', props, response)
      return response?.data?.map((res) => {
        return res;
      });
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export async function searchRunnerUser(props) {
  const formData = {
    request: "search",
    query: {
      ...props,
    },
    resource: "news_runner_users",
    id: "",
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      // console.log('xxxxxhhhhxx', props, response)
      return response?.data?.map((res) => {
        delete res.phone;
        delete res.email;
        delete res.age;
        return res;
      });
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export const handleRecurringDate = (props) => {
  const { start, end } = props;
  const dateFormatTemplate = "DD MMM YYYY";

  const initialDate = start
    ? moment(start, dateFormatTemplate)
    : moment("06 Jun 2024", dateFormatTemplate);
  const endDate = end
    ? moment(end, dateFormatTemplate)
    : moment(initialDate).add(2, "month");

  const MONDAY = 1; // moment().day() return a value from 0 to 6(Sunday-to-Saturday)
  const TUESDAY = 2;
  const WEDNESDAY = 3;
  const THURSDAY = 4;
  const FRIDAY = 5;
  const SATURDAY = 6;
  const SUNDAY = 7;

  const mondayArray = [];
  const tuesdayArray = [];
  const wednesdayArray = [];
  const thursdayArray = [];
  const fridayArray = [];
  const saturdayArray = [];
  const sundayArray = [];

  while (initialDate.isSameOrBefore(endDate)) {
    if (initialDate.day() === MONDAY)
      mondayArray.push(initialDate.format(dateFormatTemplate));
    else if (initialDate.day() === TUESDAY)
      tuesdayArray.push(initialDate.format(dateFormatTemplate));
    else if (initialDate.day() === WEDNESDAY)
      wednesdayArray.push(initialDate.format(dateFormatTemplate));
    else if (initialDate.day() === THURSDAY)
      thursdayArray.push(initialDate.format(dateFormatTemplate));
    else if (initialDate.day() === FRIDAY)
      fridayArray.push(initialDate.format(dateFormatTemplate));
    else if (initialDate.day() === SATURDAY)
      saturdayArray.push(initialDate.format(dateFormatTemplate));
    else if (initialDate.day() === SUNDAY)
      sundayArray.push(initialDate.format(dateFormatTemplate));

    initialDate.add(1, "day");
  }
  return {
    mondayArray,
    tuesdayArray,
    wednesdayArray,
    thursdayArray,
    fridayArray,
    saturdayArray,
    sundayArray,
  };
};

//----links
export async function deleteLinkDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_links",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getLinksDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_links",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export const handleReaction = async (item) => {
  const { myInfo, source, _id, eventId } = item;

  if (!(myInfo?.handle && source && _id)) {
    alert("Please login to continue");
    return;
  }

  const formData = {
    ...item,
    // reaction: item?.reaction,
    source,
    itemId: item?._id,
    eventId: item?.eventId || "",
    handle: item?.myInfo?.handle,
  };

  if (source == "surveys") {
    formData["reply"] = item?.reply;
  }

  delete formData?._id;
  return await saveReactionDB(formData).then(async (rep) => {
    alert("Saved");
    return await getReactionDB({
      eventId: item?.eventId, // '65934efd0b5d57a5b7857eae',
      // handle: myInfo?.handle
    });
  });
};

export const handleSaveEventLikes = async (item) => {
  const { myInfo, eventId } = item;

  if (!(myInfo?.handle && item?.handle)) {
    alert("Please login to continue");
    return;
  }

  const formData = {
    ...item,
    itemId: item?.handle,
    // eventId: item?.eventId || "",
    handle: item?.myInfo?.handle,
  };

  delete formData?._id;
  return await saveEventLikesDB(formData).then((rep) => {
    return rep;
  });
};

export async function saveEventLikesDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
    },
    resource: "calendar_event_likes",
    check: ["itemId", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getEventLikesDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_event_likes",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export const handleSaveLikes = async (item) => {
  const { myInfo, eventId } = item;

  if (!(myInfo?.handle && item?.handle)) {
    alert("Please login to continue");
    return;
  }

  const formData = {
    ...item,
    itemId: item?.handle,
    eventId: item?.eventId || "",
    handle: item?.myInfo?.handle,
  };

  delete formData?._id;
  return await saveGuestLikesDB(formData).then((rep) => {
    return rep;
  });
};

export async function saveGuestLikesDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
    },
    resource: "calendar_guest_likes",
    check: ["itemId", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getGuestLikesDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_guest_likes",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveReactionDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
    },
    resource: "calendar_reactions",
    check: ["itemId", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getReactionDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_reactions",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveLinkDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_links",
    check: ["date", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//----calendar_contacts
export async function deleteFollowupDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_followups",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getFollowupDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_followups",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveFollowupDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_followups",
    check: ["id", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//----calendar_contacts
export async function deleteContactsDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_contacts",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getContactsDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_contacts",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveContactsDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_contacts",
    check: ["item", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//----calendar_contacts
export async function deleteShareDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_share",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getActionsDB(props) {

  const formData = {
    request: 'search',
    resource: 'guestnet_story_actions',
    query: {
      ...props
    },
  };
  // f'xxxxx', formData)
  return await RESTCall.axiosQuery(formData)
    .then(response => {
      return response?.data;
    })
    .catch(err => {
      return []
    })
}

export async function saveActionsDB(props) {

  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment()
    },
    resource: "guestnet_story_actions",
    check: ["handle", "content_id"]
  }
  // f'xxxxx', formData)
  return await RESTCall.axiosQuery(formData)
    .then(response => {
      // f'xxxxx', response)
      return response?.data;
    })
    .catch(err => {
      return []
    })
}


export async function getShareDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_share",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveShareDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_share",
    check: ["item", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
//------

//----calendar_contacts
export async function deleteLeadsDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_leads",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getLeadsDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_leads",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveLeadsDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_leads",
    check: ["item", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
//------

//----participants
export async function deleteParticipantsDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_participants",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getParticipantsDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_participants",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveParticipantsDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_participants",
    check: ["date", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
//------

export const saveFunnelLeadsDB = async (props) => {
  const {
    item,
    topic,
    eventId,
    guest,
    myInfo,
    funnel,
    _id
  } = props

  if (eventId && guest && myInfo && funnel) {
    const data = {
      query: {
        topic: item?.funnelTopic,
        itemId: _id,
        topic: topic,
        funnel: funnel,
        eventId: eventId,
        guestName: guest?.label,
        guest: {
          name: guest?.label,
          ...guest
        },
        handle: myInfo?.handle,
      },
      resource: "calendar_funnel_leads",
      check: ["handle", "itemId"],
      myInfo,
    };

    saveDataDB(data).then((ref) => {
      return ref
    });
  } else {
    return
  }
}

export const capitalizeInfo = (word) => {
  if (!word) {
    return "";
  }
  // const { word } = props
  const firstLetter = word.substring(0, 1);

  const firstLetterCap = firstLetter.toUpperCase();

  const remainingLetters = word.substring(1, word?.length);
  return firstLetterCap + remainingLetters;
};


export const jumpToReleventDiv = (id) => {
  const releventDiv = document.getElementById(id);
  // behavior: "smooth" parameter for smooth movement
  releventDiv?.scrollIntoView({ behavior: "smooth" });
};

//search google
export const searchGoogleApi = async (props) => {
  const { query, imageType = "university" } = props;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  if (!query || query?.length <= 7) {
    return [];
  }

  // console.log('photosxxx', query)
  var raw = JSON.stringify({
    query: query,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await getGoogleSearchDB({
    query,
  }).then(async (rep) => {
    if (rep?.[0]) {
      // console.log('oldsearch', rep)
      return rep;
    }

    const relatedPict = await fetch(
      `https://api.pexels.com/v1/search?page=1&per_page=50&query=${imageType}`,
      {
        headers: {
          Authorization:
            "tPOcC3re4a1Ks8UKrbh02pYptuGSKxZLTNoFzKHuk9mC8XyRjDyHddz4",
        },
      }
    )
      .then((resp) => {
        return resp.json();
      })
      .then((pics) => {
        // const info = 'https://www.pexels.com/photo/photo-of-stream-during-daytime-3225517/'
        return pics?.photos?.map((real) => {
          const info = real?.url;
          const build = info && info?.split("-");
          const build2 = build && build[build?.length - 1];
          const repo = build2.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            ""
          );
          console.log("photo5555", repo);
          return (
            (repo &&
              `https://images.pexels.com/photos/${repo}/pexels-photo-${repo}.jpeg`) ||
            ""
          );
        });
      });

    // console.log('photosxxx', relatedPict)
    //search from DB by date and key before going to google site
    // return []
    // return await fetch(`https://g6xl5tp6k6ztadgrf6gngx4wvy0qwxbc.lambda-url.us-east-2.on.aws/google`, requestOptions)
    return (
      (await relatedPict) &&
      fetch(`https://vare-middleware.herokuapp.com/google`, requestOptions)
        // return await fetch("http://localhost:8000/google", requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          const data =
            result && typeof result == "string"
              ? JSON.parse(result)?.data
              : result?.data;
          return await Promise.all(
            data?.map(async (rat, i) => {
              const rex = { ...rat };
              rex["image"] =
                relatedPict?.[i] ||
                "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg";
              rex["description"] = rex.snippet;
              rex["urlLink"] = rex.link;

              return await saveGoogleSearchDB({
                ...props,
                ...rex,
              });
            })
          );
        })
        .catch((error) => {
          console.log("error-google", error);
          return [];
        })
    );
  });
};

export async function getGoogleSearchDB(props) {
  const formData = {
    request: "search",
    query: {
      query: props?.query,
    },
    resource: "google_search_results",
    id: "",
    limit: 20,
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response?.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export async function saveGoogleSearchDB(props) {
  const { key } = props;
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }

  const formData = {
    request: "insert",
    query: {
      ...props,
      location: "google",
      date: moment().format(),
      month: moment().format("MMMM"),
    },
    resource: "google_search_results",
    check: [key || "query"],
  };

  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

//scrapper
export const scrapLink = async (link) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    link: link?.toString(),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `https://4ez6ag4lcfau2rw43dlw6legca0ggxzo.lambda-url.us-east-2.on.aws/scraper`,
    requestOptions
  )
    // return await fetch(`http://localhost:5003/scraper`, requestOptions)

    .then((response) => response.text())
    .then((result) => {
      // console.log('scrapLink', result)
      if (result && result?.includes("<!DOCTYPE html>") == false) {
        return JSON.parse(result)?.data ? JSON.parse(result)?.data : {};
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("errorxx", error);
      return {};
    });
};

//----

//----skills
export async function deleteAttendanceDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_attendance",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

const sendMyEmail = async ({
  name,
  email,
  eventInfo = {},
  data = {},
  calendarLink,
  location,
  tab,
  query = [],
}) => {
  if (!email) {
    // alert('No email')
    return;
  }
  const emailObject = {
    to: email,
    subject: "Message from GuestNet Planner ✔", // Subject line
    text: "", // plain text body
    // email["html"] = text || `<b>${text?.toString()}</b>`; // html body
    html: `
<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>${"Action Event on your event"}</title>
</head>
<body style="margin: 0; padding: 0; background-color: #f4f4f4; font-family: Arial, sans-serif;">
<table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
<tr>
    <td align="center" bgcolor="#ffffff" style="padding: 40px 0 30px 0;">
        <img style="width: 350px" src=${eventInfo?.img ||
      eventInfo?.picture ||
      eventInfo?.iamge ||
      "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
      } alt="Event Logo"  />
    </td>
</tr>
<tr>
    <td bgcolor="#ffffff" style="padding: 40px 30px 40px 30px;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
            <tr>
                <td style="color: #153643; font-size: 24px; font-weight: bold; text-align: center;">
                    Balance your Work/Live with GuestNet Planner!
                </td>
            </tr>
            <tr>
                <td style="padding: 20px 0 30px 0; color: #153643; font-size: 16px; line-height: 20px; text-align: center;">
                ${name} has just completed an action on your ${tab} page for event ${eventInfo?.title
      }.
                </td>
            </tr>
            <tr>
                <td align="center" style="">
                    <a href="${location}" style="font-size: 18px; font-weight: bold; color: #ffffff; text-decoration: none; background-color: #1a75ff; padding: 15px 25px; border-radius: 5px; display: inline-block;">
                        View Event
                    </a>
                    
                </td>
            </tr>
        </table>
    </td>
</tr>
<tr>

</tr>
</table>
</body>
</html>
`,
    query,
  };
  // console.log("emailObject", emailObject);
  return sendEmail(emailObject);
};

export async function saveDataDB(props) {
  const {
    query = {},
    // limit = 10,
    resource,
    check = ["date"],
    myInfo = {},
  } = props;

  // alert(JSON.stringify(myInfo));


  const { eventInfo } = myInfo;

  if (!myInfo?.handle) {
    alert("Please Login to continue");
    return;
  }

  if (!resource) {
    // alert("Missing resource");
    return;
  }

  if (!Object.keys(query)?.[0]) {
    alert("Missing query");
    return;
  }
  if (resource == 'calendar_events' && !(myInfo?.handle && query?.title)) {
    alert('Login and alert is required')
    return
  }

  if (!check?.includes('_id')) delete query?._id;
  const formData = {
    request: "insert",
    query: {
      ...query,
      handle: myInfo?.handle,
      date: moment().format(),
    },
    resource,
    check: resource == 'calendar_events' ? ['title', 'handle'] : check,
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      sendAllEmails({
        myInfo,
        eventInfo,
        resource,
        query,
      });

      return (response && response) || {};
    })
    .catch((error) => {
      return error;
    });
}

const sendAllEmails = async ({ myInfo, eventInfo, resource, query }) => {
  if (!myInfo?.handle) {
    return;
  }
  if (!(eventInfo?.handle && eventInfo?.handle == myInfo?.handle)) {
    return;
  }
  await getDataDB({
    query: {
      handle: myInfo?.handle,
    },
    resource: "calendar_users",
  }).then((info) => {
    // console.log("infosssss", info);
    const host = info?.[0];
    sendMyEmail({
      name: host?.name,
      email: host?.email,
      eventInfo,
      tab: resource?.split("_")?.[resource?.split("_")?.length - 1],
      location: `https://www.myguestnet.com/event/${resource}`,
      query: query,
    });
  });

  await getDataDB({
    query: {
      handle: eventInfo?.handle,
    },
    resource: "calendar_users",
  }).then((info) => {
    const host = info?.[0];
    sendMyEmail({
      name: host?.name,
      email: host?.email,
      eventInfo,
      tab: resource?.split("_")?.[resource?.split("_")?.length - 1],
      location: `https://www.myguestnet.com/event/${resource}`,
    });
  });
  // }
};

export const getVideoByDescription = async (props) => {
  const {
    description,
    myInfo
  } = props

  if (!myInfo?.handle) {
    return
  }

  const apiKey = 'tPOcC3re4a1Ks8UKrbh02pYptuGSKxZLTNoFzKHuk9mC8XyRjDyHddz4';  // Replace with your actual Pexels API key
  const endpoint = `https://api.pexels.com/videos/search?query=${description}&per_page=10`;

  const result = await getDataDB({
    query: {
      description,
    },
    resource: `vare_pexels_search_video_result`,
  })

  if (result?.[0]) {
    return result
  }


  try {
    const response = await fetch(endpoint, {
      headers: {
        Authorization: apiKey
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    await saveDataDB({
      query: {
        description,
        videos: data.videos,
        date: moment()
      },
      myInfo: myInfo,
      resource: `vare_pexels_search_result`,
      check: ["description"],
    })
    return data.videos; // Return the array of video results

  } catch (error) {
    console.error('Error fetching videos from Pexels:', error);
    return [];
  }
}


// Example picture pexels api
export const getPictureByDescription = async (props) => {
  const {
    description,
    myInfo
  } = props

  if (!myInfo?.handle) {
    return
  }
  const url = `https://api.pexels.com/v1/search?query=${encodeURIComponent(description)}&per_page=10`;

  const result = await getDataDB({
    query: {
      description,
    },
    resource: `vare_pexels_search_result`,
  })

  if (result?.[0]) {
    return result
  }

  return await fetch(url, {
    headers: {
      Authorization: 'tPOcC3re4a1Ks8UKrbh02pYptuGSKxZLTNoFzKHuk9mC8XyRjDyHddz4'
    }
  }).then((resp) => {
    return resp.json();
  })
    .then(async (pics) => {
      // console.log('picsxxxxxxxxxx', pics)
      await saveDataDB({
        query: {
          description,
          pictures: pics?.photos,
          date: moment()
        },
        myInfo: myInfo,
        resource: `vare_pexels_search_result`,
        check: ["description"],
      })
      return pics?.photos

    })

}

//vare feeds from admin
export async function getContent(props) {
  const { pageNumber = 0, pageSize = 400, mySearch } = props
  const queryInfo = {
    reply: false,
    mediaType: null
  };

  let limit = pageSize || 10;

  const formData = {
    request: "search",
    query: { ...queryInfo },
    resource: "vare_contents",
    // vare_user_contents
    id: "",
    // pageNumber: pageNumber ? pageNumber : 0,
    limit: limit,
    skip: limit * pageNumber,
    //orderBy: "dsc",
    //sortBy: "date"
  };

  if (mySearch) {
    formData["search"] = mySearch
    formData["key"] = ["title", "bill_id", "views"]
  }

  return await RESTCall.axiosQuery(formData)
    .then(async (response) => {
      const data =
        response && response.data && response.data.length > 0
          ? response.data.map(rep => {
            rep['table'] = 'vare_contents'
            if (rep.likes) rep["likes"] = JSON.parse(rep.likes);
            if (rep.views) rep["views"] = JSON.parse(rep.views);
            if (!rep.handle) rep["handle"] = rep.handle || "Vare Admin"
            if (!rep.img) rep["img"] = rep.img || rep.urlToImage ? rep.urlToImage : rep.picture;
            return rep;
          })
          : [];

      const result = await data?.map(res => {

        return {
          ...res,
          urlToImage: res.urlToImage ? res.urlToImage : res.picture,
          links: [
            {
              name: "",
              value: res.video
                ? res.url
                : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`
            }
          ],
          videoUrl: res.video
            ? res.video
            : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`,
          videoInfo: {
            title: res.title,
            description: {
              views: res.views ? res.views.length : 1,
              uploadDate: moment(res.date).fromNow()
            },
            videoLength: "4:58",
            videoThumbnailUrl: res.picture
              ? res.picture
              : `https://varefiles.s3.us-east-2.amazonaws.com/bill-image/hr482.jpg`
          },
          channelInfo: {
            channelName: "Vare Admin",
            channelAvatarImage: res.picture
          },

        };
      });

      return result;
    });
}

//vare save bill feeds
export async function saveVareFeedContent(props) {
  // const {
  //   formInputs = {}
  // } = props
  // setLoaded(false);
  // const filelink = `https://varefiles.s3.us-east-2.amazonaws.com/`;
  // if (
  //   !formInputs?.title ||
  //   !formInputs?.imageFileName ||
  //   !formInputs?.item_id
  // ) {
  //   alert("You are missing key fields");
  //   return;
  // } else if (
  //   formInputs?.videoFileName?.includes(formInputs?.item_id) == false
  // ) {
  //   alert("Image must be named after Item ID / Bill ID");
  //   return;
  // }
  // setLoading(true);
  // const dbVote = {
  //   user_id: "vare_admin",
  //   title: formInputs?.title,
  //   story: formInputs?.title,
  //   name: "vare_admin", //formInputs?.name,
  //   img: `${filelink}${imageFile?.current?.name}`,
  //   picture: `${filelink}${formInputs?.imageFileName}`,
  //   video: `${filelink}${formInputs?.videoFileName}`,
  //   reply: false,
  //   item_id: formInputs?.item_id,
  //   date: moment().format(),
  //   bill_id: formInputs?.item_id
  // };

  // const formData = {
  //   request: "insert",
  //   query: dbVote,
  //   resource: "vare_contents",
  //   check: ["title", "name"]
  // };

  // const result = await RESTCall.axiosQuery(formData)
  //   .then(response => {
  //     console.log("xxxformData", response);
  //     postPicture();

  //     // setFormInputs({});
  //     setLoaded({ video: true, picture: true });
  //     setLoading(false);
  //   })
  //   .catch(error => {
  //     setLoading(false);
  //     return error;
  //   });
};


export function reduceVareContents(props) {
  const {
    rep
  } = props

  return {
    ...rep,
    // _id: rep?._id || 'ljk',
    author: rep.bill_id,
    // item_id: rep.bill_id,
    name: rep.fullName,
    channel: rep.channelInfo,
    picture: rep.picture,
    description: rep.title,
    hashtags: 'hashtags',
    createdAt: moment(rep.createdAt).fromNow() || moment().fromNow(),
    updateAt: moment(rep.date).fromNow() || moment().fromNow(),
    likes: 10,
    totalComments: 123,
    video: rep.video,
    videoInfo: rep.videoInfo,
    source: 'allFeeds',
    sourceCaller: 'getContent',
    handle: rep.handle,
  };

}

//search google 
export const youtubeSearchApi = async (props) => {
  const {
    query,
    imageType = 'university',
    bill_id
  } = props

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "query": query
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const relatedPict = [
    "https://images.pexels.com/photos/3246665/pexels-photo-3246665.jpeg",
    "https://images.pexels.com/photos/102127/pexels-photo-102127.jpeg",
    "https://images.pexels.com/photos/1193743/pexels-photo-1193743.jpeg",
    "https://images.pexels.com/photos/962312/pexels-photo-962312.jpeg",
    "https://images.pexels.com/photos/1585325/pexels-photo-1585325.jpeg",
    "https://images.pexels.com/photos/743986/pexels-photo-743986.jpeg",
    "https://images.pexels.com/photos/1646953/pexels-photo-1646953.jpeg",
    "https://images.pexels.com/photos/1570264/pexels-photo-1570264.jpeg",
    "https://images.pexels.com/photos/1209843/pexels-photo-1209843.jpeg",
    "https://images.pexels.com/photos/886521/pexels-photo-886521.jpeg",
    "https://images.pexels.com/photos/699466/pexels-photo-699466.jpeg",
    "https://images.pexels.com/photos/889839/pexels-photo-889839.jpeg",
    "https://images.pexels.com/photos/1762851/pexels-photo-1762851.jpeg",
    "https://images.pexels.com/photos/1572386/pexels-photo-1572386.jpeg",
    "https://images.pexels.com/photos/1918290/pexels-photo-1918290.jpeg",
    "https://images.pexels.com/photos/159862/pexels-photo-159862.jpeg",
    "https://images.pexels.com/photos/1170576/pexels-photo-1170576.jpeg",
    "https://images.pexels.com/photos/1183992/pexels-photo-1183992.jpeg",
    "https://images.pexels.com/photos/1145720/pexels-photo-1145720.jpeg",
    "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg",
    "https://images.pexels.com/photos/20967/pexels-photo-20967.jpeg",
    "https://images.pexels.com/photos/1109354/pexels-photo-1109354.jpeg",
    "https://images.pexels.com/photos/942317/pexels-photo-942317.jpeg",
    "https://images.pexels.com/photos/1616403/pexels-photo-1616403.jpeg",
    "https://images.pexels.com/photos/542556/pexels-photo-542556.jpeg",
    "https://images.pexels.com/photos/587958/pexels-photo-587958.jpeg",
    "https://images.pexels.com/photos/1037993/pexels-photo-1037993.jpeg",
    "https://images.pexels.com/photos/1194420/pexels-photo-1194420.jpeg",
    "https://images.pexels.com/photos/1081685/pexels-photo-1081685.jpeg",
    "https://images.pexels.com/photos/1939485/pexels-photo-1939485.jpeg",
    "https://images.pexels.com/photos/354939/pexels-photo-354939.jpeg",
    "https://images.pexels.com/photos/39348/pexels-photo-39348.jpeg",
    "https://images.pexels.com/photos/1047540/pexels-photo-1047540.jpeg",
    "https://images.pexels.com/photos/1690351/pexels-photo-1690351.jpeg",
    "https://images.pexels.com/photos/164455/pexels-photo-164455.jpeg",
    "https://images.pexels.com/photos/2832382/pexels-photo-2832382.jpeg",
    "https://images.pexels.com/photos/1477166/pexels-photo-1477166.jpeg",
    "https://images.pexels.com/photos/925743/pexels-photo-925743.jpeg",
    "https://images.pexels.com/photos/1292241/pexels-photo-1292241.jpeg",
    "https://images.pexels.com/photos/804269/pexels-photo-804269.jpeg",
    "https://images.pexels.com/photos/311458/pexels-photo-311458.jpeg",
    "https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg",
    "https://images.pexels.com/photos/166277/pexels-photo-166277.jpeg",
    "https://images.pexels.com/photos/1084510/pexels-photo-1084510.jpeg",
    "https://images.pexels.com/photos/1266808/pexels-photo-1266808.jpeg",
    "https://images.pexels.com/photos/2086361/pexels-photo-2086361.jpeg",
    "https://images.pexels.com/photos/206064/pexels-photo-206064.jpeg",
    "https://images.pexels.com/photos/626163/pexels-photo-626163.jpeg",
    "https://images.pexels.com/photos/288099/pexels-photo-288099.jpeg",
    "https://images.pexels.com/photos/1109352/pexels-photo-1109352.jpeg"
  ]
  // f'photosxxx', relatedPict)
  const result = await getDataDB({
    query: {
      query: query
    },
    resource: `vare_google_search_result`,
  })

  // console.log('queryssss', query)
  if (result?.length > 0) {
    // console.log('queryssssresult', result)
    return result?.[0]
  }

  const youtubeVideos = await youtubeSearch({ query, maxResults: 10 })
    .then(links => {
      // console.log('youtubeSearch', links);
      return links?.map(res => {
        res['image'] = res?.poster || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'
        res['bill_id'] = bill_id
        res['video'] = res?.link
        return res
      })
    });

  const google = await fetch(`https://vare-middleware.herokuapp.com/google`, requestOptions)
    // return await fetch("http://localhost:5003/google", requestOptions)
    .then(response => response.text())
    .then(async (result) => {
      const data = JSON.parse(result)?.data

      // f'replyssss', data)
      return data?.map((rex, i) => {
        rex['image'] = relatedPict[i] || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'
        rex['description'] = rex.snippet
        rex['bill_id'] = bill_id
        console.log('nnnnnnrex', rex)
        return rex
      })
    })
    .catch(error => console.error('error', error));

  await saveDataDB({
    query: {
      query: query,
      google: google,
      youtube: youtubeVideos,
      bill_id: bill_id,
      date: moment()
    },
    resource: `vare_google_search_result`,
    check: ["query"],
  })

  return {
    google: google,
    youtube: youtubeVideos
  }
}

export const youtubeSearch = async (props) => {
  const { query, myInfo, maxResults = 10 } = props
  const apiKey = 'AIzaSyDGql5DgxxAWyT3HXGhhv2q-zk4IhR5XEE'; // Replace with your API key
  const searchUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${encodeURIComponent(query)}&maxResults=${maxResults}&key=${apiKey}`;

  const result = await getDataDB({
    query: {
      query: query
    },
    resource: `vare_youtube_search_result`,
  }).then(ref => {
    return ref?.[0]?.result
  })

  if (result?.length > 0) {
    return result
  }


  try {
    // First API call to search for videos
    const searchResponse = await fetch(searchUrl);
    const searchData = await searchResponse.json();

    // Extract video IDs from the search results
    const videoIds = searchData.items
      .filter(item => item.id.kind === 'youtube#video')
      .map(item => item.id.videoId)
      .join(',');

    if (!videoIds) {
      return [];
    }

    // Second API call to get video details
    const detailsUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${videoIds}&key=${apiKey}`;
    const detailsResponse = await fetch(detailsUrl);
    const detailsData = await detailsResponse.json();

    // Extract and return the desired information
    const videoDetails = detailsData.items.map(item => {
      return {
        link: `https://www.youtube.com/watch?v=${item.id}`,
        description: item.snippet.description,
        duration: item.contentDetails.duration,
        author: item.snippet.channelTitle,
        channel: item.snippet.channelId,
        poster: item.snippet.thumbnails.high.url
      };
    });

    await saveDataDB({
      query: {
        query: query,
        result: videoDetails,
        date: moment(),
        myInfo
      },
      resource: `vare_youtube_search_result`,
      check: ["query"],
    })


    return videoDetails;
  } catch (error) {
    console.error('Error fetching YouTube API:', error);
    return [];
  }
}


export async function getNewsRunnerContent(props) {

  const { pageNumber = 0, pageSize = 400, mySearch } = props
  const queryInfo = {
    replysss: null,
  };

  let limit = pageSize || 10;

  const formData = {
    request: "search",
    query: { ...queryInfo },
    // resource: 'vare_app_news', 
    resource: 'vare_contents',
    // resource: 'vare_news_ai', 


    // vare_user_contents
    id: "",
    // pageNumber: pageNumber ? pageNumber : 0,
    limit: limit,
    skip: limit * pageNumber,
    //orderBy: "dsc",
    //sortBy: "date"
  };

  if (mySearch) {
    formData["search"] = mySearch
    formData["key"] = ["title", "bill_id", "views"]
  }

  return await RESTCall.axiosQuery(formData)
    .then(async (response) => {

      const data =
        response && response.data && response.data.length > 0
          ? response.data.map(rep => {
            rep['table'] = 'vare_app_news'
            // if (rep.likes) rep["likes"] = JSON.parse(rep.likes);
            // if (rep.views) rep["views"] = JSON.parse(rep.views);
            if (!rep.handle) rep["handle"] = rep.handle || "Vare Admin"
            if (!rep.img) rep["img"] = rep.img || rep.urlToImage ? rep.urlToImage : rep.picture;
            return rep;
          })
          : [];



      const result = data?.map(rep => {
        return {
          ...rep,
          isRunners: true,
          author: rep.channelName,
          // item_id: rep.bill_id,
          name: rep.fullName,
          channel: rep.channelInfo,
          picture: rep.image || rep.picture || rep.avatar,
          description: rep.description,
          hashtags: 'hashtags',
          createdAt: moment(rep.createdAt).fromNow() || moment().fromNow(),
          updateAt: moment(rep.date).fromNow() || moment().fromNow(),
          likes: 10,
          totalComments: 123,
          video: rep.video,
          videoInfo: rep.videoInfo,
          source: 'allFeeds',
          sourceCaller: 'getNewsRunnerContent',
          handle: rep.handle,

        };
      });

      return result;
    });
}

export async function deleteDataDB(props) {
  const { id, resource } = props;

  if (!resource) {
    alert("Missing resource");
    return;
  }

  if (!id) {
    alert("Missing query");
    return;
  }

  const formData = {
    request: "delete",
    query: {},
    resource,
    id: id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveEmailDB(props) {
  const {
    query = {},
    resource,
    check = ["eventId", "handle"],
    myInfo = {},
  } = props;

  const { eventInfo } = myInfo;

  // if (!myInfo?.handle) {
  //   alert("Please Login");
  //   return;
  // }

  // if (!resource) {
  //   alert("Missing resource");
  //   return;
  // }

  if (!Object.keys(query)?.[0]) {
    // alert("Missing query");
    return;
  }

  delete query?._id;
  const formData = {
    request: "insert",
    query: {
      ...query,
      handle: myInfo?.handle,
      date: moment().format(),
    },
    resource: "calendar_email_records",
    check,
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      sendAllEmails({
        myInfo,
        eventInfo,
        resource,
      });

      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getEmailDB(props) {
  const { query = {}, limit = 500, resource } = props;

  if (!resource) {
    // alert("Missing resource");
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...query,
    },
    limit: limit,
    resource: "calendar_email_records",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getDataDB(props) {
  const { query = {}, limit = 100, resource } = props;

  if (!resource) {
    // alert("Missing resource");
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...query,
    },
    ...(limit ? { limit: limit, skip: 0 } : {}),
    resource,
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getAttendanceDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_attendance",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getEventPicsDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_pictures",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveAttendanceDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }

  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_attendance",
    check: ["eventId", "handle"],
  };
  // alert(4)
  console.log("saveAttendanceDB", formData);
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
//----

//----surveys
export async function deleteSurveyDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_surveys",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getSurveysDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_surveys",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveSurveysDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }

  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_surveys",
    check: ["handle", "title"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
//----

//----skills
export async function deleteSkillDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_skills",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getSkillsDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_skills",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveSkillDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_skills",
    check: ["title", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
//----

//----Porfolio
export async function deletePortfolioDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_portfolio",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getPortfolioDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_portfolio",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function savePortfolioDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_portfolio",
    check: ["title", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//---

//----links
export async function deleteMediaDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_media",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getMediaDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_media",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveMediaDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_media",
    check: ["title", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//---

//----links
export async function deleteMessageDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_messages",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getMessagesDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_messages",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveMessageDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_messages",
    check: ["date", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//---

//----files
export async function deleteFileDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_files",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getFilesDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_files",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveFileDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_files",
    check: ["title", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//---

//----comments
export async function deleteCommentsDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_comments",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getCommentsDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_comments",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveCommentsDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_comments",
    check: ["title", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//---

//---

export async function deleteAgendaDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_agenda",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getAgendaDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_agenda",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveAgendaDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_agenda",
    check: ["title", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//---general form DB ----

export async function deleteCalanedarFormDB(info) {
  if (!info) {
    return;
  }
  const formData = {
    request: "delete",
    query: {
      // ...search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_agenda",
    id: info?.id,
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function getCalanedarFormDB(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      ...search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_agenda",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    return (response && response.data) || [];
  });
}

export async function saveCalanedarFormDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }
  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_agenda",
    check: ["date", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//----general form db

export async function saveEventDB(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }

  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_events",
    check: ["title", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function saveAppointment(props) {
  if (!props?.handle) {
    alert("Please login to complete this action");
    return;
  }

  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
      // blocked,
      // item_id,
      // handle
    },
    resource: "calendar_appointments",
    check: ["eventId", "handle"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export const handleLogin = async (props) => {
  const { email = "", password = "", externalUrl } = props;

  const hashedPassword = password; //bcrypt.hashSync(password.current.value, salt) // hash created previously created upon sign up
  if (email && password) {
    const formData = {
      request: "login",
      query: {
        email: email.toLowerCase(),
        password: hashedPassword,
      },
      resource: externalUrl || "calendar_users",
      id: "",
    };
    return RESTCall.axiosQuery(formData)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return (
          (err && err.response && err.response.data) ||
          ([] && err.response.data) ||
          [].message
        );
      });
  }
};

// const sampleDataModel = {
//   _id: '64739b2e67f770834bb18e2a',
//   author: 'Fox News',
//   avatar:
//     'https://static.foxnews.com/foxnews.com/content/uploads/2022/08/transgender-sports-utah.jpg',
//   categories: [
//     'International',
//     'Weather',
//     'Politics',
//     'Health & Sports',
//     'Education',
//     'Technology',
//   ],
//   category:
//     '["International","Weather","Politics","Health & Sports","Education","Technology"]',
//   channelAvatarImage:
//     'https://static.foxnews.com/foxnews.com/content/uploads/2022/08/transgender-sports-utah.jpg',
//   channelName: 'Fox News',
//   committee: '',
//   createdAt: 'a few seconds ago',
//   date: 'a few seconds ago',
//   description:
//     "States advance bills protecting girls' sports as Biden says no at federal level",
//   fullName: 'Fox News',
//   group: 'saved',
//   handle: 'Fox News',
//   hashtags: 'hashtags',
//   image:
//     'https://static.foxnews.com/foxnews.com/content/uploads/2022/08/transgender-sports-utah.jpg',
//   installId: '[object Object]',
//   item_id: 'Fox News',
//   name: 'Fox News',
//   picture:
//     'https://static.foxnews.com/foxnews.com/content/uploads/2022/08/transgender-sports-utah.jpg',
//   source:
//     'https://www.foxnews.com/politics/states-advance-bills-protecting-girls-sports-biden-says-no-federal-level',
//   sourceCaller: 'getAllNews',
//   title:
//     "States advance bills protecting girls' sports as Biden says no at federal level",
//   updateAt: '2023-06-27T18:52:07.895Z',
//   urlLink:
//     'https://www.foxnews.com/politics/states-advance-bills-protecting-girls-sports-biden-says-no-federal-level',
// };

export const saveContent = async (props) => {
  const { linkMeta } = props
  linkMeta['created'] = moment();
  const formData = {
    request: 'insert',
    query: linkMeta,
    resource: 'vare_app_news',
    check: ['urlLink'],
  };
  await RESTCall.axiosQuery(formData).then(res => {
    const response = res ? res?.data?.filter((ref) => ref.channelName != 'NA') : []

    const data = response['data']?.[0];
    if (data) {

      // navigation.navigate('NewsRunners', {
      //   reload: true
      // });
      // alert('Saved categories ' + JSON.stringify(linkMeta))
    }
  });
};

export const handleForgotPassword = async (props) => {
  const { email = "" } = props;

  if (email) {
    const formData = {
      request: "forgetlogin",
      query: {
        email: email.toLowerCase(),
      },
      // resource: "calendar_users",
      resource: "calendar_users",
      id: "",
    };
    return RESTCall.axiosQuery(formData)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return (
          (err && err.response && err.response.data) ||
          ([] && err.response.data) ||
          [].message
        );
      });
  }
};

export const handleResetPassword = async (props) => {
  const { email = "", password = "", tempPassword = "" } = props;

  const hashedPassword = password; //bcrypt.hashSync(password.current.value, salt) // hash created previously created upon sign up
  if (email && password) {
    const formData = {
      request: "forgetlogin",
      query: {
        email: email.toLowerCase(),
        tempPassword: tempPassword,
        password: hashedPassword,
      },
      resource: "calendar_users",
      id: "",
    };
    return RESTCall.axiosQuery(formData)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return (
          (err && err.response && err.response.data) ||
          ([] && err.response.data) ||
          [].message
        );
      });
  }
};

export async function handleUpdateUser(props) {
  if (!props?.handle) {
    alert("Please add you handle to complete this action");
    return;
  }

  const formData = {
    request: "insert",
    query: {
      ...props,
      date: moment().format(),
    },
    resource: "calendar_users",
    check: ["email"],
  };
  return await RESTCall.axiosQuery(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function handleRegisterUser(props) {
  const { name, handle, email, password, verifyPassword } = props;
  const info = { ...props };
  delete info?.verifyPassword;

  const query = {
    ...info,
    name: name,
    date: moment(),
  };
  // if (query?.phone) {
  //   query['phone'] = phone?.replace(/^(\d{3})(\d{3})(\d+)$/, "$1-$2-$3") || ''
  // }

  const formData = {
    request: "register",
    query: query,
    resource: "calendar_users",
    check: ["email"],
  };
  // console.log('xxxxx', formData)
  delete formData?.query?.verifyPassword;
  return await RESTCall.axiosQuery(formData)
    .then(async (response) => {
      // console.log('xxxxx44', response?.message)
      return response;
      // return await searchRunnerUser({
      //   handle, email
      // })
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export async function getUserEmail(id) {
  const formData = {
    request: "search",
    query: {
      user_id: id,
    },
    resource: "calendar_users",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    const data = (response && response.data) || ([] && response.data) || [][0];
    return data && data.email;
  });
}

export async function getUserByStringSearch(search) {
  if (!search) {
    return;
  }
  const formData = {
    request: "search",
    query: {
      handle: search,
    },
    // check: ["handle", "fullName"],
    resource: "calendar_users",
    id: "",
  };
  return await RESTCall.axiosQuery(formData).then((response) => {
    const data = (response && response.data) || ([] && response.data) || [][0];
    return data;
  });
}

export function stringifyEmails(props) {
  const { emailArray } = props
  if (!Array.isArray(emailArray)) {
    throw new Error('Expected an array of emails');
  }

  // Convert the array into a string, separated by the specified separator
  return emailArray.join(', ');
}


export const sendEmail = async (emailInfo) => {
  const {
    to,
    text,
    html,
    subject,
    location,
    myInfo,
    data,
    calendarLink,
    query,
  } = emailInfo;
  const email = {
    to,
    text,
    html,
    subject,
  };
  if (!to) {
    return;
  }
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const finalImage =
    data?.img ||
    data?.picture ||
    "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg";

  const extension = finalImage.split(".").pop();
  // email['from'] = from || '"VareTech" <member@vareapp.com>' // sender address
  email["to"] = to || to?.toString(); // list of receivers
  email["subject"] = subject ? subject : "Message from GuestNet Planner ✔"; // Subject line
  email["text"] = text ? text : "Message from GuestNet?"; // plain text body
  // email["html"] = text || `<b>${text?.toString()}</b>`; // html body
  email["html"] = html
    ? html
    : `
  <!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>${"Event Registration Confirmation"}</title>
</head>
<body style="margin: 0; padding: 0; background-color: #f4f4f4; font-family: Arial, sans-serif;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
        <tr>
            <td align="center" bgcolor="#ffffff" style="padding: 40px 0 30px 0;">
                <img style="width: 350px;"  src=${extension === "jpg"
      ? finalImage
      : "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
    } alt="Event Logo"/>
            </td>
        </tr>
        <tr>
            <td bgcolor="#ffffff" style="padding: 40px 30px 40px 30px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                    <tr>
                        <td style="color: #153643; font-size: 24px; font-weight: bold; text-align: center;">
                            You're all set!
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 20px 0 30px 0; color: #153643; font-size: 16px; line-height: 20px; text-align: center;">
                            Your event registration for ${data?.title
    } was successfully processed! If you have any further questions, please contact the event host.
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="">
                            <a href="${location}" style="font-size: 18px; margin-top:10px; font-weight: bold; color: #ffffff; text-decoration: none; background-color: #1a75ff; padding: 15px 25px; border-radius: 5px; display: inline-block;">
                                View Event
                            </a>
                            <a href="${calendarLink}" style="font-size: 18px; margin-top:10px; font-weight: bold; color: #ffffff; text-decoration: none; background-color: #1a75ff; padding: 15px 25px; border-radius: 5px; display: inline-block;">
                                Add to Calendar
                            </a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
        
        </tr>
    </table>
</body>
</html>
`;

  {
    /* <td bgcolor="#ffffff" style="padding: 30px; text-align: center; font-size: 14px;">
Add to Calendar: <a href="https://example.com/add-to-apple-calendar" style="color: #666666; text-decoration: none;">Apple</a> | 
<a href="https://example.com/add-to-google-calendar" style="color: #666666; text-decoration: none;">Google</a> | 
<a href="https://example.com/add-to-office365-calendar" style="color: #666666; text-decoration: none;">Office 365</a> | 
<a href="https://example.com/add-to-outlook-calendar" style="color: #666666; text-decoration: none;">Outlook</a> | 
<a href="https://example.com/add-to-yahoo-calendar" style="color: #666666; text-decoration: none;">Yahoo</a>
</td> */
  }

  var raw = JSON.stringify(email);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${"https://ytkpw57tnna3kbppvoqr4rwixa0kxkgv.lambda-url.us-east-2.on.aws"}/email`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      const data = {
        query: query,
        // resource: `calendar_event_advert_accept_package`,
        // check: ["handle", "eventId"],
        myInfo,
      };
      saveEmailDB(data);
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const getAgreement = async (props) => {
  const { agreementType } = props
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    question: `tell me the questions required to generate the the ${agreementType} agreement.\n\nThe result should be inside this format:\n[\n  {\n    question: \"Parties Involved\",\n    description:\n      \"Identify the parties entering into the joint venture, including their legal names, addresses, and roles in the venture.\",\n    fields: ['partyA', 'partyB', 'cartyC']\n  }\n];\n\n\nGive me the result so that it can be JSON parsed`,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    "https://52hygzg23ayywzsyx5kv7zhnjy0jmsty.lambda-url.us-east-2.on.aws/aigenerator/generalPrompt",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      // console.log('vvbbbbbb', JSON.parse(result)?.body);
      return JSON.parse(result)?.body
    })
    .catch((error) => console.error(error));
};
