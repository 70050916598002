import React, { useLayoutEffect } from "react";
import data from "./MediaSlider/data";
import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "./MyLinks";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { v4 as uuid } from 'uuid';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarUsers,
  getContactsDB,
  getFollowupDB,
  getGuestLikesDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveFollowupDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
} from "src/api";
import { Breadcrumb, Button, Card, Col, Row, Modal } from "react-bootstrap";
import { ListItemsMain } from "./ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "./SearchEventForm";
import { ListItems } from "./ListItems";
import { RWebShare } from "react-web-share";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "./ListItemsMedia";
import { SkillsForm } from "./SkillsForm";
import MyKanbanBoard from "./Kanban";
import { StyledInput, TitleInput } from "./Kanban/TitleInput";
import { Checkbox } from "material-ui";
import { Category } from "@mui/icons-material";
// import { IconButton } from 'material-ui';

/**
 * Responsible for displaying the Sidebar
 */


export const LikesComponet = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    host,
    // people = data,
    allMyEvents,
    eventId,
    eventDetails = {},
    indexStop = false,
    contacts,
    followups,
    likedme,
    myLeads,
    allSelected = [],
    editInfo
    // people = data || [],
    // likes = []
    // attendees
    // comments = [],
  } = myContext;
  const {
    isSidebarOpen,
    setIsSidebarOpen,
    setOpenEventForm,
    setOpenSignin,
    userinfo = ["title", "name", "handle", "company"],
  } = props;
  // const [likes, setLikes] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  // const [editInfo, setEditInfo] = useState<boolean>(false);
  const [review, setReview] = useState<any>('business');
  const [categories, setCategories] = useState<any>([
    'Client',
    'Mentor',
    'Refer',
    'Employ',
    'Employer',
    'Frie'
  ]);
  const [items, setItems] = React.useState<any[]>([])
  const [editItem, setEditItem] = React.useState<any>({})
  const [searchField, setSearchField] = useState<any>('');





  const [selected, setSelected] = useState([]);

  useLayoutEffect(() => {

    // console.log('vvvv', followers, result)
    (async () => {
      if (!myInfo?.handle) {
        return
      }

      const guestHandle: any = []

      const contactMe = await getContactsDB({
        item: myInfo?.handle
      })


      const contactILike = await getGuestLikesDB({
        handle: myInfo?.handle
      })


      const contactByMeInfo = await getContactsDB({
        handle: myInfo?.handle
      })


      const contactMeInfo: any = []
      await Promise.all(contactMe?.map(async (reply: any) => {
        const info = await getCalendarUsers({
          handle: reply?.handle
        })
        const reg = info?.[0]
        if (!guestHandle?.includes(reply?.handle)) {
          guestHandle.push(reply?.handle)
        }
        delete reg.title
        contactMeInfo.push({
          ...reg,
          item: reply?.handle,
          handle: myInfo?.handle,
        })
      }))
      const followers: any = await getFollowupDB({
        handle: myInfo?.handle
      })

      const match: any = []
      const guestMutualInterest: any = []
      followers?.map((tree: any) => {
        if (!match?.includes(tree.item)) {
          guestMutualInterest.push({
            ...tree,
            mutualInterest: guestHandle?.includes(tree.item)
          })
          match?.push(tree.item)
        }
      })


      contactByMeInfo?.map((tree: any) => {
        if (!match?.includes(tree.item)) {
          if (!tree.column) {
            tree.column = null
          }
          delete tree.title
          guestMutualInterest.push({
            ...tree,
            mutualInterest: guestHandle?.includes(tree.item)
          })
          match?.push(tree.item)
        }
      })


      contactMeInfo?.map((tree: any) => {
        if (!match?.includes(tree.item)) {
          if (!tree.column) {
            tree.column = null
          }
          delete tree.title
          guestMutualInterest.push({
            ...tree,
            mutualInterest: guestHandle?.includes(tree.item)
          })
          match?.push(tree.item)
        }
      })

      // console.log('contactsresult', guestHandle, contactMeInfo, contactByMeInfo, followers)

      console.log('contactsresult', guestMutualInterest)

      setItems([
        ...[...guestMutualInterest]?.map((reg: any) => {
          return {
            ...reg,
            id: reg?._id,
            // column: null,
          }
        })
      ])

      setRefreshing(false)
    })()

  }, [
    myInfo?.handle,
    refreshing
  ]);


  return <>
    <Modal show={true} onHide={() => {
      setMyContext((existing: any) => {
        return {
          ...existing,
          openLeadManage: false
        }
      })
    }}>
      <Modal.Header closeButton>
        <Modal.Title>MyGuestNet</Modal.Title>
      </Modal.Header>
      <Modal.Body> Connect and Manage Contacts</Modal.Body>
      <Modal.Footer style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',

      }}>

        <div>sdsds</div>

      </Modal.Footer>
    </Modal>
    <div style={{
      width: '100%',
    }}>
      <div
        className="text"
        style={{
          marginTop: -20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10
        }}>
        You can add contacts by liking them on the home page or, you can start adding contacts to track and manage engagements below.
      </div>

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div
          className={`nav-link ${"show"}`}
          // onClick={() => {
          //   setShowMenu(!showMenu)
          // }}
          // href="#"
          id="navbarDropdownMenuLink"
          role="button"
          data-mdb-toggle="dropdown"
          aria-expanded="false"
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid gray",
            borderRadius: 20,
            margin: 10,
            width: 300,
            alignSelf: "center",
            justifyContent: "center",
            backgroundColor: "white",
            gap: "10px",
            padding: "5px 10px 5px 15px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "fit-content",

            }}
            onClick={() => {
              // setShowMenu(showMenu == "search" ? "" : "search");
            }}
          >
            <div onClick={() => {
              if (searchField?.length > 0) {
                const searchItems = [...items]?.filter(rep => JSON.stringify(rep)?.toLowerCase()?.includes(searchField?.toLowerCase()))
                setItems([...searchItems])
              } else {
                setRefreshing(true)
              }
            }}>
              <SearchIcon />
            </div>
            <input
              // className="form-control"
              type="text"
              // value={data?.title}
              onChange={(e: any) => {
                setSearchField(e.target?.value)
                if (!(e.target?.value?.length > 0)) {
                  setRefreshing(true)
                }
              }}
              placeholder={searchField || "Search your contacts"}
              style={{
                outlineWidth: 0,
                border: "none",
                backgroundColor: "transparent",
              }}
              required
            />
          </div>
        </div>
      </div>
      <div style={{
        margin: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>
        <div style={{
          margin: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '80%',
          alignItems: 'center'
        }}>
          <div onClick={() => {
            setReview('business')
          }}><i

              className="fa fa-business-time"
              style={{
                display: 'flex',
                fontSize: 12,
                justifyContent: 'center',
                alignItems: 'center',
                paddingRight: 5,
                color: review == 'business' ? myColors?.red : 'black'
              }} />
            <div style={{
              color: review == 'business' ? myColors?.red : 'black',
              fontSize: 9
            }}>Business</div>
          </div>
          <div onClick={() => {
            setReview('social')
          }}>
            <i

              className="fa fa-handshake"
              style={{
                display: 'flex',
                fontSize: 12,
                justifyContent: 'center',
                alignItems: 'center',
                paddingRight: 5,
                color: review == 'social' ? myColors?.red : 'black'

              }} />
            <div style={{
              fontSize: 9,
              color: review == 'social' ? myColors?.red : 'black'

            }}>Social</div>
          </div>
          <div onClick={() => {
            setReview('personal')
          }}>
            <i

              className="fa fa-network-wired"
              style={{
                display: 'flex',
                fontSize: 12,
                justifyContent: 'center',
                alignItems: 'center',
                paddingRight: 5,
                color: review == 'personal' ? myColors?.red : 'black'

              }} />
            <div style={{
              fontSize: 9,
              color: review == 'personal' ? myColors?.red : 'black'

            }}>Personal</div>
          </div>
        </div>
      </div>
      <div style={{
        display: 'flex',
        // width: '100%',
        flexDirection: 'column',
        overflowX: 'scroll',
        // backgroundColor: 'red',
        // justifyContent: "center",
        // alignItems: "center",
      }}>
        <MyKanbanBoard
          {...props}
          setItems={setItems}
          items={items}
          editItem={editItem}
          setEditItem={setEditItem}
          children={(e: any) => {
            console.log('lllll', e)
            const data = formInput?.name ? formInput : e?.name ? e : {}


            return <div style={{
              display: "flex",
              position: 'relative',
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 20
              // backgroundColor: "rgba(0,0,0,0.8)",
            }}>
              <i
                onClick={() => {
                  // setOpenMenu(!openMenu)
                  setEditItem({})
                }}
                style={{
                  position: 'absolute',
                  top: -25,
                  right: -25,
                  display: 'flex',
                  fontSize: 18,
                  padding: 10,
                  borderRadius: 100,
                  justifyContent: 'center',
                  alignItems: 'center',
                  // paddingRight: 5,
                  backgroundColor: myColors.gray,
                  color: 'white'

                }}
                className={`fas fa-times`} ></i>
              <StyledInput
                style={{
                  margin: 10,
                }}
                type="text"
                name="name"
                onChange={(e: any) =>
                  setFormInput({
                    ...(data),
                    name: e.target?.value,
                  })
                }
                // value={title}
                placeholder={data?.name || "Contact Name (optional)"}
              />
              <StyledInput
                style={{
                  margin: 10,
                }}
                type="text"
                name="email"
                onChange={(e: any) =>
                  setFormInput({
                    ...data,
                    email: e.target?.value,
                  })
                }
                // value={title}
                placeholder={data?.email || "Email (optiona)"}
              />
              <StyledInput
                style={{
                  margin: 10,
                }}
                type="text"
                name="email"
                onChange={(e: any) =>
                  setFormInput({
                    ...data,
                    phone: e.target?.value,
                  })
                }
                // value={title}
                placeholder={data?.phone || "Phone# (optional)"}
              />
              <StyledInput
                style={{
                  margin: 10,
                }}
                type="text"
                name="title"
                onChange={(e: any) =>
                  setFormInput({
                    ...data,
                    title: e.target?.value,
                  })
                }
                placeholder={data?.title || "Action (required"}
              />
              <StyledInput
                style={{
                  margin: 10,
                }}
                type="text"
                name="link"
                onChange={(e: any) =>
                  setFormInput({
                    ...data,
                    link: e.target?.value,
                  })
                }
                // value={title}
                placeholder={data?.link || "Link (optional)"}
              />
              <SelectOptionField
                {...props}
                categories={categories}
                setCategories={setCategories}
                onSubmit={(e: any) => {
                  // console.log('vvvvvvv', e)
                  setFormInput({
                    ...data,
                    selected: e
                  })
                }}
                // formInput={formInput}
                setFormInput={setFormInput}
              />

              <div
                onClick={() => {
                  if (!myInfo?.handle) {
                    alert('You must login to continue')
                    return
                  }
                  if (!(data?.title && data?.name)) {
                    alert('Please complete required fields..')
                    return
                  }
                  console.log('formInputccc', data)

                  if (data?.column == null) {
                    data.column = null
                  }

                  delete data?._id
                  saveFollowupDB({
                    ...data,
                    type: review,
                    handle: myInfo?.handle,
                  }).then((ref: any) => {
                    alert('saved!')
                    setRefreshing(true)
                    setFormInput({})
                  })
                }}
                style={{
                  alignSelf: 'center',
                  display: 'flex',
                  height: 40,
                  width: 150,
                  color: 'white',
                  backgroundColor: myColors.red,
                  borderRadius: 10,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                {'Save'}
              </div>
            </div>
          }}
        />
      </div>
    </div >

  </>
}


export const SelectOptionField = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    host,
    categories: mainCategories,
  } = myContext;
  const {
    // formInput = {},
    // setFormInput: setFormInputMain,
    // formInput: formInputMain
    onSubmit
    // openOptions = false
  } = props;
  // const [likes, setLikes] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  const [openOptions, setOpenOptions] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>([]);
  const refField = useRef<any>()
  const [categories, setCategories] = useState<any>([
    'Client',
    'Mentor',
    'Refer',
    'Employ',
    'Employer',
    'Frie'
  ]);


  useEffect(() => {
    if (mainCategories?.length > 0) {
      setCategories(mainCategories)
    }
  }, [
    mainCategories
  ])





  useEffect(() => {
    console.log('vvvvxxxz', selected, categories)

  }, [selected, categories])

  return <div style={{
    display: 'flex',
    margin: 10,
    position: 'relative'
    // flexDirection: 'row'
  }}>
    <div
      // onMouseEnter={() => {
      //   setOpenOptions(!openOptions)
      // }}
      onTouchStart={() => {
        setOpenOptions(!openOptions)
      }}
      onMouseDown={() => {
        setOpenOptions(!openOptions)
      }}
      style={{
        display: 'flex',
        // margin: 10,
        width: '100%',

        // flexDirection: 'row'
      }}>
      <StyledInput
        // style={{
        // }}
        ref={refField}
        type="text"
        name="category"
        onChange={(e: any) => {
          setFormInput({
            ...formInput,
            category: e.target?.value,
          })
        }}
        // value={title}
        placeholder={selected && selected?.length > 0 ? JSON.stringify(selected) : "category "}
      />
      {(formInput?.category?.length > 0
        && ![...categories]?.filter((res: any) => res?.toLowerCase()?.includes(formInput?.category?.toLowerCase()))?.[0])
        ? <i
          onClick={() => {
            setCategories(
              [
                formInput?.category,
                ...categories
              ]
            )
            refField.current.value = ''
            setFormInput({})
          }}
          className="fa fa-plus"
          style={{
            display: 'flex',
            fontSize: 12,
            zIndex: 2,
            top: 15,
            position: 'absolute',
            right: 10
            // justifyContent: 'center',
            // alignItems: 'center',
            // paddingRight: 5
          }} />
        : <i className="fa fa-chevron-down"
          style={{
            display: 'flex',
            fontSize: 12,
            zIndex: 2,
            top: 15,
            position: 'absolute',
            right: 10
            // justifyContent: 'center',
            // alignItems: 'center',
            // paddingRight: 5
          }} />}
    </div>
    {openOptions && <div style={{
      top: 30,
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 100,
      overflowY: 'scroll',
      justifyContent: 'start',
      alignItems: 'start',
      backgroundColor: 'white',
      width: '100%',
      padding: 10,
      borderWidth: 2,
      borderColor: 'gray',
      borderBlockStyle: 'solid'
    }}>
      {selected?.length > 0 && <div onClick={() => {
        onSubmit(selected)
        // setFormInputMain({
        //   ...formInputMain,
        //   selected: selected
        // })
        setOpenOptions(false)
      }} className="title">Save</div>}
      {(formInput?.category?.length > 0
        ? [...categories]?.filter((res: any) => res?.toLowerCase()?.includes(formInput?.category?.toLowerCase()))
        : categories?.length > 0
          ? categories
          : [])?.map((res: any) => {
            return <div style={{
              display: 'flex',
              position: 'relative',
              flexDirection: 'row',
              // justifyContent: 'space-evenly',
              // alignItems: 'center',
              fontSize: 16,
              height: 50,
              // width: '100%'
            }}>
              <input
                {...(selected && selected?.includes(res) ? { checked: true } : {})}
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value={res}
                onChange={(e: any) => {
                  const data = [...selected]
                  if (!e.target.checked) {
                    const info = data?.filter((ref: any) => ref != res)
                    setSelected(info)
                  } else {
                    data.push(res)
                    setSelected(data)
                  }
                }}
                style={{
                  margin: 10
                }}
              />
              <div>
                {res}
              </div>
              <div
                onClick={() => {
                  const info = [...categories]?.filter((ref: any) => ref != res)
                  setCategories(info)
                }}
                style={{
                  display: 'flex',
                  position: 'absolute',
                  top: -3,
                  right: -50,
                  // padding: 10,
                  fontSize: 12
                }}>
                <i className="fa fa-times"
                  style={{
                    display: 'flex',
                    margin: 10,
                    fontSize: 12,
                    // zIndex: 2,
                    // top: 15,
                    // position: 'absolute',
                    // right: 10
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    // paddingRight: 5
                  }} />
              </div>
            </div>
          })
      }
    </div>
    }
  </div >
}


