import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import styled from "@emotion/styled";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ExternalLink } from "react-external-link";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import ThumbUp from "@mui/icons-material/ThumbUp";
import DatePicker from "react-multi-date-picker";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
  Checkbox,
  Radio,
  Rating,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteFileDB,
  getFilesDB,
  getCalendarEvents,
  saveFileDB,
  saveEventDB,
  saveReactionDB,
  getReactionDB,
  myColors,
  handleReaction,
  handleSaveLikes,
  getGuestLikesDB,
} from "src/api";
import { UserContext } from "src/App";
import { colors } from "./MyLinks";
import { Link, useNavigate } from "react-router-dom";
import { Star } from "@mui/icons-material";

export const MenuContext: any = createContext({});

// const colors = {
//   lightBlue: '#c1d8ff'
// }

export const ListItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    eventId,
    reactions,
    // comments = [],
  } = myContext;
  const {
    editButton = false,
    handleSetItem,
    handleDeleteItem,
    source,
    children,
    items = [],
    setTagType,
    formInput,
    tagType,
    display = "title",
    subDisplay = ''
    // formInput,
    // setFormInput
  } = props;
  const navigate = useNavigate();

  const [openTag, setOpenTag] = useState<any>("");
  const [myLikes, setMyLikes] = useState<any>([]);






  console.log("editButton", reactions);

  return (
    <>
      {items?.map((item: any, index: number) => {
        console.log("hostTabsxxccnngg", item);


        return (<>
          <ItemCard {...props} item={item} index={index} />
        </>
        );
      })}
    </>
  );
};

const ItemCard = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    eventId,
    reactions,
    allAccess
    // comments = [],
  } = myContext;
  const {
    children,
    editButton = false,
    item = {},
    index = 0,
    display = "title",
    subDisplay = '',
    source,
    handleSetItem,
    handleDeleteItem,

  } = props;
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [myLike, setMyLike] = useState<boolean>(false);
  const [myReaction, setMyReaction] = useState<any>({});


  useEffect(() => {
    getGuestLikesDB({
      handle: myInfo?.handle,
      title: item?.title
    }).then((res) => {
      setMyLike(res?.[0]?.like || false);
      setRefreshing(false);
    });
  }, [myInfo?.handle]);

  useEffect(() => {
    getReactionDB({
      eventId: eventId, // '65934efd0b5d57a5b7857eae',
      // handle: myInfo?.handle
    }).then((res: any) => {
      setMyReaction(res)
    });
  }, [eventId]);


  return <AgendaRow
    style={{
      backgroundColor: '#c4e3da'
      // formInput?.title == item?.title
      //   ? colors.lightBlue
      //   : "rgba(0,0,0,0)",
    }}
  >
    <div>
      <img
        src={
          item?.img ||
          "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
        }
        style={{
          padding: 4,
          justifyContent: "center",
          width: 40,
          height: 40,
          borderRadius: 100,
        }}
      />
      {item?.link || item?.file ? (
        <a
          target="_blank"
          onClick={() => {
            if (item?.link?.includes('http')) {
              window.open(
                `https://${item?.link || item?.file}`,
                "_blank"
              );
            } else {
              window.open((item?.link ? `https://www.myguestnet.com${item?.link}`
                : `https://www.myguestnet.com/${item.handle}/about`), "_blank")

            }
          }}
        >
          <div
            style={{
              color: colors.brown,
              // fontSize: 12,
              fontWeight: 100,
            }}
          >
            {`${item?.[display]} ${item?.[subDisplay]
              ? '(' + item?.[subDisplay] + ')' : ''}`}
          </div>

          <i className="fa fa-paperclip" style={{ fontSize: 10 }}></i>
        </a>
      ) : (
        <div style={{
          ...(item?.status ? { textDecoration: 'line-through' } : {}),
          fontSize: '0.8em'

        }}>{`${item?.[display]} ${item?.[subDisplay]
          ? '(' + item?.[subDisplay] + ')' : ''}`}</div>
      )}
    </div>
    <div style={{
      fontSize: '0.8em'
    }}>
      {source == "surveys" && (
        <div
          style={{
            border: "1px solid #dee2e6",
            padding: 5,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              gap: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <label htmlFor="yes">Yes</label>
              <input
                type="radio"
                onChange={(e) => {
                  console.log("xxxxvvv", e.target.value);
                  handleReaction({
                    ...item,
                    reply: e.target.value,
                    source,
                    myInfo,
                  }).then((res: any) => {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        reactions: res,
                      };
                    });
                  });
                }}
                checked={myReaction?.reply ? true : false}
                name={item._id}
                value="yes"
                id="yes"
              />
            </div>
            <div>
              <label htmlFor="no">No</label>
              <input
                type="radio"
                id="no"
                checked={myReaction?.reply ? true : false}
                onChange={(e) => {
                  console.log("xxxxvvv", e.target.value);
                  handleReaction({
                    ...item,
                    reaction: "survey",
                    reply: e.target.value,
                    source,
                    myInfo,
                  }).then((res: any) => {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        reactions: res,
                      };
                    });
                  });
                }}
                name={item._id}
                value="no"
              />
            </div>
          </div>
        </div>
      )}
      {(children && children({ item, index })) || <div
        onClick={() => {
          handleSaveLikes({
            like: !myLike,
            type: "guest",
            ...item,
            myInfo,
          }).then((res) => {
            alert("Saved Like");
            setRefreshing(true);
          });

        }}
      >
        <ThumbUp
          style={{
            color: myReaction?.like ? "#479d83" : "gray",
          }}
        />
      </div>}
      {editButton && allAccess && <div
        onClick={() => {
          setMyContext((existing: any) => {
            return {
              ...existing,
              editInfo: true,
            };
          });
          handleSetItem(item);
        }}
      >
        <EditNote style={{
          color: 'gray'
        }} />
      </div>}
      {allAccess && <div
        onClick={() => {
          handleDeleteItem(item);
        }}
      >
        <Delete style={{
          color: 'gray'
        }} />
      </div>}
      {item?.rating && <div style={{
        color: 'orange',
        borderRadius: 5,
        backgroundColor: '#479D83',
        paddingLeft: 3,
        paddingRight: 3,
        fontWeight: 'bold'
      }}>
        {item?.rating}<Star />
      </div>}
    </div>
  </AgendaRow>
}

const InputAgenda = styled.form`
  display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 5px;
                    background: #fff;
                    border: 1px solid #dee2e6;
                    border-radius: 5px;
                    input {
                      outline: none;
                    border: none;
                    font-size: 14px;
                    width: 100%;
  }
                    button {
                      border: none;
                    background: transparent;
                    outline: none;
                    color: gray;
                    transition: color 0.3s;
                    svg {
                      width: 20px;
                    height: 20px;
    }
                    &:hover {
                      color: #dee2e6;
    }
  }
                    `;
const AgendaRow = styled.form`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 5px;
                    border: 1px solid #dee2e6;
                    border-radius: 5px;
                    background:white;
                    margin: 10px 0;
                    img {
                      width: 20px;
                    height: 20px;
                    object-fit: cover;
                    border-radius: 50%;
  }
                    p,
                    a {
                      color: gray;
                    text-decoration: none;
                    margin: 0;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    i {
                      width: 15px;
                    height: 15px;
    }
  }
                    div {
                      display: flex;
                    gap: 5px;
                    align-items: center;
  }
                    button {
                      border: none;
                    background: transparent;
                    outline: none;
                    color: gray;
                    transition: color 0.3s;
                    svg {
                      width: 20px;
                    height: 20px;
    }
                    &:hover {
                      color: #dee2e6;
    }
  }
                    label {
                      font - size: 12px;
  }
                    `;
