import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, CardContent, CardMedia, Typography, Rating, Grid } from '@mui/material';
import {
  deleteDataDB,
  jumpToReleventDiv,
  saveDataDB,
  myColors,
  getDataDB
} from "src/api";
import { UserContext } from "src/App";
import { useParams } from "react-router-dom";
import { ButtonL } from "./AppIndex";

const dataTemp = [
  {
    _id: 1,
    name: "John Doe",
    message: "This product is amazing! I highly recommend it.",
    rating: 4.5,
    thumbnail: "https://via.placeholder.com/100",
    handle: '@johndDoe'
  },
  {
    _id: 2,
    name: "Jane Smith",
    message: "Good quality, but delivery was a bit late.",
    rating: 3.5,
    thumbnail: "https://via.placeholder.com/100",
    handle: '@janesmith'
  },
  {
    _id: 3,
    name: "Michael Johnson",
    message: "Satisfied with the purchase, will buy again!",
    rating: 4,
    thumbnail: "https://via.placeholder.com/100",
    handle: '@michaeljohnson'
  },
];

const ListReviewItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    handleEditRating = () => { },
    dataRecord = []
  } = props

  const {
    myInfo,
    eventInfo,
    hostTabs
  } = myContext;
  const [data, setData] = useState<any>(dataTemp);

  useEffect(() => {
    if (dataRecord?.[0]) {
      setData([
        ...dataRecord,
        ...dataTemp
      ])
    }

  }, [
    dataRecord
  ])


  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      {data.map((item: any) => (
        <Grid item xs={12} sm={6} md={4} key={item.id} onClick={() => {
          if (item?.handle == myInfo?.handle) {
            handleEditRating(item)
          }
        }}>

          <Card style={{ display: 'flex', alignItems: 'center', padding: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardMedia
              component="img"
              image={item.thumbnail || item.img}
              alt={item.name}
              style={{ width: '100px', height: '100px', borderRadius: '10px', marginRight: '20px' }}
            />
            <CardContent style={{
              // flex: '1 0 auto'
            }}>
              <Rating
                name={`rating-${item.id}`}
                value={item.rating}
                precision={0.5}
                readOnly
                style={{ marginTop: '10px' }}
              />
              <Typography variant="h6" component="div">
                {item.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.message}
              </Typography>

            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ListReviewItems;
