import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { ButtonL } from "./AppIndex";

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

const ResizableDiv = styled.div<{
  top: number;
  left: number;
  width: number;
  height: number;
  opacity: number;
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border: 2px solid #000;
  background-color: rgba(255, 255, 255, ${({ opacity }) => opacity});
  border-radius: 100%;
  cursor: move;
`;

const Resizer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  background: #000;
  cursor: se-resize;
`;

const ResizableDraggableDiv = (props:any) => {
  const {
    imgLink,
    opacity=0, 
    setOpacity

  } = props
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [size, setSize] = useState({ width: 100, height: 100 });
  const [dragging, setDragging] = useState(false);
  const [resizing, setResizing] = useState(false);
  // const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [initialSize, setInitialSize] = useState({ width: 100, height: 100 });
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
      if (dragging) {
        if (e instanceof MouseEvent) {
          setPosition({
            x: e.clientX - offset.x,
            y: e.clientY - offset.y,
          });
        } else if (e instanceof TouchEvent) {
          const touch = e.touches[0];
          setPosition({
            x: touch.clientX - offset.x,
            y: touch.clientY - offset.y,
          });
        }
      } else if (resizing && divRef.current) {
        if (e instanceof MouseEvent) {
          const newWidth = initialSize.width + (e.clientX - offset.x);
          const newHeight = initialSize.height + (e.clientY - offset.y);
          setSize({
            width: newWidth > 0 ? newWidth : 0,
            height: newHeight > 0 ? newHeight : 0,
          });
        } else if (e instanceof TouchEvent) {
          const touch = e.touches[0];
          const newWidth = initialSize.width + (touch.clientX - offset.x);
          const newHeight = initialSize.height + (touch.clientY - offset.y);
          setSize({
            width: newWidth > 0 ? newWidth : 0,
            height: newHeight > 0 ? newHeight : 0,
          });
        }
      }
    };

    const handleMouseUp = () => {
      setDragging(false);
      setResizing(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [dragging, resizing, offset, initialSize]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    setDragging(true);
    setOffset({ x: touch.clientX - position.x, y: touch.clientY - position.y });
  };

  const handleResizeMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setResizing(true);
    setOffset({ x: e.clientX, y: e.clientY });
    setInitialSize({ width: size.width, height: size.height });
  };

  const handleResizeTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const touch = e.touches[0];
    setResizing(true);
    setOffset({ x: touch.clientX, y: touch.clientY });
    setInitialSize({ width: size.width, height: size.height });
  };

  return (
    <Container>
      <Image src={imgLink} alt="Example" 
       style={{ height: 300 }}
        />
      <ResizableDiv
        ref={divRef}
        top={position.y}
        left={position.x}
        width={size.width}
        height={size.height}
        opacity={opacity}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <Resizer
          onMouseDown={handleResizeMouseDown}
          onTouchStart={handleResizeTouchStart}
        />
      </ResizableDiv>
      <div style={{ display: "flex", gap: 10 }}>
        <ButtonL
          onClick={() => {
            if (opacity < 0.8) {
              setOpacity(opacity + 0.1);
            }
          }}
          style={{ margin: 0 }}
        >
          +
        </ButtonL>
        <ButtonL
          onClick={() => {
            if (opacity > 0.2) {
              setOpacity(opacity - 0.1);
            }
          }}
          style={{ margin: 0 }}
        >
          -
        </ButtonL>
      </div>
    </Container>
  );
};

export default ResizableDraggableDiv;
