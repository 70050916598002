import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  DeleteOutline,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { AdvertListItems } from "./AdvertListItems";
import { MyTabListItems } from "./MyTabListItems";
import { AboutTabDetails } from "./AboutTabDetails";
import { AddNewContent } from "./CatalogListings";
import { DiscreteSliderMarks } from "./Rangesliderdata";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";

// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

const prodArray: any = [];
// https://www.npmjs.com/package/react-email-editor

export const EmailEditorComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { resource } = props;
  // const { handleInfo } = props;
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [uploadNow, setUploadNow] = useState<boolean>(false);

  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    editInfo,
    hostId,
    cart = [],
    selectedEvent,
    people,
    eventTabs = [],
  } = myContext;

  const location = useLocation();

  const [products, setProducts] = useState<any>([
    {
      _id: "samplproduct",
      title: "Demo Product only",
      price: "100.00",
      unit: "1",
      location: "online",
      img: "https://myborosil.com/cdn/shop/files/my-borosil-stainless-steel-bottles-trek-black-personalise-32329717710986.gif?v=1685518005",
    },
    // {
    //   title: "Bottle",
    //   cost: "$1000.00",
    //   location: "online",
    //   img: "https://myborosil.com/cdn/shop/files/my-borosil-stainless-steel-bottles-trek-black-personalise-32329717710986.gif?v=1685518005",
    // },
    // {
    //   title: "Laptop",
    //   cost: "$1000.00",
    //   location: "online",
    //   img: "https://myborosil.com/cdn/shop/files/my-borosil-stainless-steel-bottles-trek-black-personalise-32329717710986.gif?v=1685518005",
    // },
    // {
    //   title: "Course",
    //   cost: "$1000.00",
    //   location: "online",
    //   img: "https://myborosil.com/cdn/shop/files/my-borosil-stainless-steel-bottles-trek-black-personalise-32329717710986.gif?v=1685518005",
    // },
  ]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  useEffect(() => {
    getDataDB({
      query: {
        eventId: eventId,
      },
      resource: resource,
    }).then((info) => {
      const data = info?.map((res: any) => {
        return {
          ...res,
          title: res?.title,
          price: res.cost,
          unit: res.unit || 1,
          location: res.location,
          img:
            res?.img ||
            "https://myborosil.com/cdn/shop/files/my-borosil-stainless-steel-bottles-trek-black-personalise-32329717710986.gif?v=1685518005",
        };
      });
      const oldProds = [...data, ...products];
      const newprods = [];
      oldProds?.map((res) => {
        if (!prodArray?.includes(res._id)) {
          newprods.push(res);
          prodArray.push(res._id);
        }
      });
      console.log("oldProds", oldProds);
      setProducts(oldProds);
    });
  }, [refreshing, eventTab, eventSubTab, hostSubTab, params?.eventTab]);

  const emailEditorRef = useRef<EditorRef>(null);
  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data: any) => {
      const { design, html } = data;
      Promise.all(
        email.split(",").map(async (e) => {
          return await sendEmail({
            html: html,
            to: e,
            subject: "Test Email",
            text: "test Text",
          });
        })
      );
      console.log("exportHtml", html);
    });
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer: any) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };
  const emails = [
    "email1@gmail.com",
    "email2@gmail.com",
    "email3@gmail.com",
    "email4@gmail.com",
    "email5@gmail.com",
    "email6@gmail.com",
    "email7@gmail.com",
    "email8@gmail.com",
    "email9@gmail.com",
  ];
  const [email, setEmail] = useState("");
  const [openEmailMenu, setOpenEmailMenu] = useState(false);
  return (
    <>
      {editInfo && (
        <div
          style={{
            width: "100%",
            padding: 20,
          }}
        >
          <div>
            <div>
              <ButtonL onClick={exportHtml}>Export HTML</ButtonL>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Input
                type="text"
                placeholder="Email, comma separated"
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target?.value);
                }}
              />
              <ButtonL
                style={{ margin: 0, whiteSpace: "nowrap" }}
                // onClick={() => {
                // const emails =
                //   formInput?.emails && formInput?.emails?.split(",");
                // if (emails?.length > 0) {
                //   const newFormInput: any = {
                //     emails: emails,
                //     handle: myInfo?.handle,
                //   };
                //   const data = {
                //     query: {
                //       ...newFormInput,
                //     },
                //     resource: `calendar_user_invites`,
                //     check: ["handle"],
                //     myInfo,
                //   };
                //   console.log("formDatacc", data);
                //   saveDataDB(data).then((res) => {
                //     //setRefreshing(true);
                //     setFormInput({});
                //   });
                // }
                // }}
                onClick={exportHtml}
              >
                Send Email
              </ButtonL>
            </div>
            <EmailEditor ref={emailEditorRef} onReady={onReady} />
          </div>
        </div>
      )}
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {[]?.map((res: any) => {
          return (
            <ListingModal
              {...props}
              item={res}
              setRefreshing={setRefreshing}
              setFormInput={setFormInput}
              formInput={formInput}
              resource={resource}
            />
          );
        })}
      </Col>
    </>
  );
};

const ListingModal = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { setFormInput, formInput, resource, setRefreshing } = props;
  const params: any = useParams<any>();
  const { host } = myContext;
  const navigate = useNavigate();

  const { item } = props;
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    hostId,
    cart = [],
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
  } = myContext;

  const location = useLocation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: 20,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 5,
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={item.img}
            style={{
              borderRadius: 5,
              width: 100,
              height: 100,
              objectFit: "cover",
            }}
            alt=""
          />
          {myInfo?.handle == item?.handle && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // position: 'absolute',
                // top: 5,
                // right: 5,
                // color: 'red',
                borderRadius: 5,
                backgroundColor: "rgba(0,0,0,0.03)",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <EditNote
                onClick={() => {
                  setFormInput(item);
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      editInfo: true,
                    };
                  });
                }}
              />
              <DeleteOutline
                onClick={() => {
                  deleteDataDB({
                    id: item?._id,
                    resource: resource,
                  }).then((rep: any) => {
                    alert("Deleted");
                    //setRefreshing(true);
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });
                  });
                }}
              />
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: 'center',
            alignItems: "flex-start",
          }}
        >
          <h6 style={{ fontSize: 14 }}>{item.title}</h6>
          <h4
            style={{
              fontSize: 16,
              color: "gray",
              fontWeight: 900,
            }}
          >
            ${item.price} / {item?.unit} Unit
          </h4>
        </div>
      </div>
      <ButtonL
        onClick={() =>
          setMyContext((existing: any) => {
            return {
              ...existing,
              cart: [
                ...cart,
                {
                  img: item.img,
                  name: item.title,
                  price: item.cost,
                },
              ],
            };
          })
        }
        style={{ margin: 0 }}
      >
        Add to Cart
      </ButtonL>
    </div>
  );
};

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;

  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const SearchWrapper = styled.div`
  max-width: 930px;
  width: 100%;
  border: 1px solid #dfe0e4;
  border-radius: 10px;
  margin-top: -120px;
  position: relative;
  z-index: 10;
  background: #fff;
`;

const SearchBarContainer = styled.div`
  padding: 28px;
`;

const ToggleMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  border-bottom: 1px solid #dfe0e4;
`;

const ToggleButtons = styled.a<{ active: boolean }>`
  text-decoration: none;
  padding: 17px 0;
  color: #000;
  min-width: 50px;
  border-bottom: ${({ active }) => (active ? "2px solid #479d83" : "none")};
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

const EventImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 2px solid #dee2e6;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;

const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "White")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const GuestImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px 14px;
  margin-top: 14px;
`;

const GuestMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px !important;
  width: 140px !important;
  background-color: black;
  border-radius: 5px;
  position: relative;
  video,
  img {
    background-color: black;
    border-radius: 5px;
  }
`;

const GuestMediaHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 12px;
    max-width: 12px;
    height: 12px;
  }
  p {
    font-size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    width: 50%;
    color: white;
    margin: 0;
  }
  div {
    display: flex;
    gap: 4px;
    span {
      padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const GuestMediaParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  max-height: 500px;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: white;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const ButtonF = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PostHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const MenuWrapperPosts = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const DropdownFilterTabsContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

const FilterChild = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    width: fit-content;
  }
`;

const DropdownFilterTabs = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  z-index: 9999;
`;

const ButtonFilterTabs = styled.button`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 8px;
  margin: 0;
  cursor: pointer;

  svg {
    color: #333;
    width: 15px;
    height: 15px;
  }
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonIndustry = styled.button`
  background-color: #ffffff;
  color: #333;
  padding: 2px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-width: 160px;
  gap: 8px;
  font-size: 14px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DropdownContentIndustry = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

const DropdownItemIndustry = styled.div<any>`
  padding: 10px;
  cursor: pointer;
  color: #333;
  font-size: 12px;
  &:hover {
    background-color: #f1f1f1;
  }

  ${(props) =>
    props.active &&
    `
    background-color: #ddd; 
    font-weight: bold;
  `}
`;

const TopHeaderWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 5px;
  margin-top: -10px;
`;

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin-top: -20px; */
`;

const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const PriceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
`;

const Img = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const SupCardHeader = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  padding: 0px 10px;
`;

const CardLabelSub = styled.h3`
  margin: 0;
  font-size: 14px;
  color: gray;
  padding: 0px 10px;
`;

const CardPriceSub = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  padding: 0px 10px;
`;

const CardSupOffer = styled.div`
  margin: 0;
  font-size: 10px;
  color: gray;
  padding: 0px 10px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const HeroBannerWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  background: #96c7b8;
  min-height: 500px;
  @media (max-width: 800px) {
    min-height: 450px;
  }
  @media (max-width: 500px) {
    min-height: 400px;
  }
`;

const EmailInputWrapper = styled.div`
  position: relative;
`;
const InputWrapperEmail = styled.div`
  width: 100%;
  outline: none;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: white;
  margin-bottom: 20px;
  input {
    width: 100%;
    outline: none;
    border: none;
  }
  svg {
    width: 20px;
    height: auto;
  }
`;
const EmailOptionWrapper = styled.div`
  position: absolute;
  border: 1px solid gray;
  border-radius: 5px;
  top: 40px;
  left: 0;
  right: 0;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: white;
  max-height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #376fd0;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background-color: #dfe8f6;
  }
  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const HeroBannerTextWrapper = styled.div`
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: white;

  h1 {
    font-size: 66px;
    line-height: 49px;
    font-weight: 900;
    margin: 0;
    text-align: start;
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: 200;
    text-align: start;
    color: white;
  }

  @media (max-width: 800px) {
    align-items: center;
    text-align: center;

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 500px) {
    max-width: 280px;

    h1 {
      font-size: 40px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
    }
  }
`;
