// Change YOUR_API_KEY_HERE to your apiKey
import RESTCall from "./restApi";
import moment from "moment";
import {
  groupBy
} from "./helpers/Utils";
import { io } from "socket.io-client";
import Axios from "axios";

// const bcrypt = require('bcrypt');
// const salt = bcrypt.genSaltSync(10)

const urlPrefix = window.location.hostname?.includes('localhost')
  ? "http://localhost:5002"
  : "https://www.varevoice.com";
// const urlPrefix = "http://localhost:5002";

const request = "";
const Constants = {
  installationId: 'TBD'
}

let _ = require('lodash')


export const wordWrap = (str, max, br = '\n') => str?.replace(
  new RegExp(`(?![^\\n]{ 1, ${max}}$) ([^\\n]{ 1, ${max} }) \\s`, 'g'), '$1' + br
);




// todo -used in Ligislation component and others.  Refactor to call function rom here
export async function databaseSaveVote(props) {
  const {
    itemInfo,
    myVote,
    influencer_id,
    item_id,
    bill_id,
    myInfo,
    share,
    host,
    description,
    memberVotes
  } = props;

  const itemKey = itemInfo || item_id || bill_id;


  // let myInfo = false //await LocalStorage.getItem.get("myInfo");
  let myOldVote = false //await LocalStorage.getItem.get("myVote");

  if (myInfo && myInfo.handle) {

    const influencerInfo =
      !influencer_id || influencer_id == "NA"
        ? {}
        : { influencer_id: influencer_id };
    const dbVote = {
      ...influencerInfo,
      facebook: myInfo.facebook,
      instagram: myInfo.instagram,
      name: myInfo.fullName,
      img: myInfo.img,
      date: moment().format(),
      handle: myInfo.handle ? myInfo.handle : myInfo.email,
      item_id: itemKey,
      bill_id: itemKey,
      title: props["title"],
      vote: props["vote"] || false, //props?.vote,
      state: myInfo.state,
      sex: myInfo.age,
      age: myInfo.age,
      race: myInfo.race,
      party: myInfo.party,
      handle: myInfo.handle,
      share: share,
      host: host,
      description: description,
      memberVotes: memberVotes
    };

    const formData = {
      request: "insert",
      query: dbVote,
      resource: "calendar_vote",
      check: ["item_id", "handle"]
    };
    return await RESTCall.axiosQuery(formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  } else {
    this.props.navigation.navigate("ProfileScreen", {
      profile: "Contact Information"
    });
  }
}

export async function databaseSaveRSVP({ meeting_id, author_id }) {
  let myInfo = false //.getItem.get("myInfo");
  // console.log('xxxx',myInfo.address, myInfo.email,itemKey,myInfo,myVote)
  if (myInfo && myInfo.address && myInfo.email) {
    const dbVote = {
      name: myInfo.fullName,
      meeting_id: meeting_id,
      author_id: author_id,
      handle: myInfo.handle,
      img: myInfo.img,
      attending: "yes",
      publishedAt: new Date(),
      state: myInfo.state,
      sex: myInfo.age,
      age: myInfo.age,
      race: myInfo.race,
      party: myInfo.party
    };
    const formData = {
      request: "insert",
      query: dbVote,
      resource: "calendar_meeting_rsvp",
      check: ["meeting_id", "handle"]
    };
    return await RESTCall.axiosQuery(formData)
      .then(response => {
        // console.log('databaseSaveRSVP',response)
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}


const vareMemberBillsReducer = (result) => {
  const billKey = result.bill_id?.toLowerCase();
  const billId = result.bill_id
    ?.toLowerCase()?.
    replace(/[&-\/\\#,+()$~%. '":*?<>{}]/g, "");
  const video = false //calendar_videos.includes(billId);

  return {
    id: result.bill_id,
    cursor: `${result.bill_id}`,
    member_id: result.bioguide,
    description: result.description
      ? result.description?.replace(/[&-\/\\#,+()$~%. '":*?<>{}]/g, " ")
      : "",
    introduced_date: result.Introduced_at
      ? result.Introduced_at
      : result.voted_at
        ? result.voted_at
        : result.Date
          ? result.Date
          : "",
    time: result.Introduced_at
      ? result.Introduced_at
      : result.voted_at
        ? result.voted_at
        : result.Date
          ? result.Date
          : "",
    position: result.vote,
    result: result.result,
    bill_id: result.bill_id,
    latest_action: result.Introduced_at
      ? result.Introduced_at
      : result.voted_at
        ? result.voted_at
        : result.Date
          ? result.Date
          : "",
    sponsor_id: result.sponsor,
    title: result.description
      ? result.description?.replace(/[&-\/\\#,+()$~%. '":*?<>{}]/g, " ")
      : result.Description
        ? result.Description?.replace(/[&-\/\\#,+()$~%. '":*?<>{}]/g, " ")
        : "",
    committees: result.Committees
      ? result?.Committees?.replace(/[&-\/\\#,+()$~%. '":*?<>{}]/g, " ")
      : "",
    video: ""
    // billId && calendar_videos?.includes(billId)
    //   ? "https://varefiles.s3.us-east-2.amazonaws.com/bills/" +
    //   billId +
    //   ".mp4"
    //   : "",
    // title: result.description
    //   ? result.description.replace(/[&-\/\\#,+()$~%. '":*?<>{}]/g, " ")
    //   : result.Description
    //     ? result.Description.replace(/[&-\/\\#,+()$~%. '":*?<>{}]/g, " ")
    //     : "",
    // committees: result.Committees
    //   ? billDetials?.Committees.replace(/[&-\/\\#,+()$~%. '":*?<>{}]/g, " ")
    //   : "",
    // video:
    //   billId && calendar_videos.includes(billId)
    //     ? "https://varefiles.s3.us-east-2.amazonaws.com/bills/" +
    //     billId +
    //     ".mp4"
    //     : ""
  };
}

const vareLocalRepReducer = (result) => {
  // console.log('result',result.bill_id && result.bill_id)
  return {
    id: `${result.bill_id && result.bill_id}`,
    member_id: `${result.member_id
      ? result.member_id
      : result.bill_id
        ? result.bill_id
        : ""
      }`,
    cursor: `${result.bill_id && result.bill_id}`,
    address: result.address ? result.address : "",
    city: result.city ? result.city : "",
    state: result.state,
    zip: result.zip ? result.zip : "",
    role: result.role ? result.role : "",
    name: result.name ? result.name : "",
    party: result.party ? result.party : "",
    fullName: result.fullName ? result.fullName : "",
    title: result.title ? result.title : "",
    phones: result.phones ? result.phones : "",
    urls: result.urls ? result.urls : "",
    photoUrl: result.photoUrl ? result.photoUrl : "",
    emails: result.emails ? result.emails : "",
    position: result.position ? result.position : "",
    divisionId: result.divisionId ? result.divisionId : "",
    district: result.district ? result.district : "",
    bill_id: result.bill_id ? result.bill_id : "",
    congress: "118",
    typename:
      result.role && result.role.includes("Senator")
        ? "us_senator"
        : result.role && result.role.includes("Representative")
          ? "us_representative"
          : "other",
    chamber: result.chamber ? result.chamber : "",
    picture: result.picture ? result.picture : "",
    phone: result.phones ? result.phones : "",
    url: result.url ? result.url : "",
    email: result.email ? result.email : "",
    committees: result.committees ? result.committees : "",
    office: result.office ? result.office : "",
    facebook_account: result.facebook_account ? result.facebook_account : "",
    youtube_account: result.youtube_account ? result.youtube_account : "",
    rank_in_party: result.rank_in_party ? result.rank_in_party : ""
  };
}

export async function getStateStateRepresentatives({ offset, address, recordSize, state }) {
  const range = offset && offset == 0 ? 0 : offset * recordSize;
  const start = 0;
  const end = recordSize;

  const formData = {
    request: "search",
    query: { state: state },
    resource: "calendar_state_reps",
    id: ""
  };

  const calendar_state_reps = await RESTCall.axiosQuery(formData).then(
    response => {
      const data = response?.data && response?.data?.length > 0 && response.data
      data?.map(rex => {
        // rex['chamber'] = rex?.role?.toLowerCase()?.includes('senat')
        //   ? 'senate'
        //   : 'house'
        const memberId =
          rex.pictureUrl && rex.pictureUrl.split("=")
            ? rex.pictureUrl.split("=")[2]
            : "";
        rex['member_id'] = memberId
        const chamberType = rex.chamber == "Senator" ? "Senate" : "House";
        rex['typename'] = rex.chamber && rex.chamber.includes("Senator")
          ? "state_senator"
          : rex.chamber && rex.chamber.includes("House")
            ? "state_representative"
            : "other"
        const newPicture = data && "https://legdir.capitol.texas.gov/FlashCardDocs/images/" +
          chamberType +
          "/large/" +
          memberId +
          ".jpg";
        rex['picture'] = newPicture
        return rex
      })

      return response && response.data ? response.data : [];
    }
  );
  // const existingData = await calendar_state_reps?.map((result, index) => {

  //   const newPicture = "https://legdir.capitol.texas.gov/FlashCardDocs/images/" +
  //     chamberType +
  //     "/large/" +
  //     memberId +
  //     ".jpg";


  // const temp = {
  //   member_id: memberId,
  //   bill_id: memberId,
  //   role: result.chamber ? result.chamber : "",
  //   name: result.name ? result.name : "",
  //   party: result.party ? result.party : "",
  //   fullName: result.name ? result.name : "",
  //   title: result.chamber ? result.chamber : "",
  //   phones: result["District Phone"] ? result["District Phone"] : "",
  //   phone: result["District Phone"] ? result["District Phone"] : "",
  //   urls: result.urls ? result.urls : "",
  //   photoUrl: result.pictureUrl ? result.pictureUrl : "",
  //   emails: result.emails ? result.emails : "",
  //   position: result.position ? result.position : "",
  //   divisionId: result.district ? result.district : "",
  //   district: result.district ? result.district : "",
  //   congress: "118",
  //   typename:
  //     result.chamber && result.chamber.includes("Senator")
  //       ? "state_senator"
  //       : result.chamber && result.chamber.includes("House")
  //         ? "state_representative"
  //         : "other",
  //   chamber: result.chamber ? result.chamber : "",
  //   picture: newPicture,
  //   url: result.url ? result.url : "",
  //   email: result.email ? result.email : "",
  //   committees: result.committees ? result.committees : "",
  //   office: result.office ? result.office : "",
  //   facebook_account: result.facebook_account
  //     ? result.facebook_account
  //     : "",
  //   youtube_account: result.youtube_account ? result.youtube_account : ""
  // };
  // return vareLocalRepReducer(temp);
  // });

  // const existing = existingData ||
  //   // && existingData.length > 0
  //   //   ? existingData.slice(start + range, end + range)
  //   [];
  // console.log("existingData", existingData);


  return calendar_state_reps && {
    data: calendar_state_reps,
    length: calendar_state_reps ? calendar_state_reps?.length : 0
  };
}


//--------------------start voting records====
export const getSearch = async (props) => {
  return await RESTCall.axiosQuery(props).then(
    response => {
      const allResponse = response.data;
      return allResponse && allResponse.length > 0 ? allResponse : [];
    }
  ).then(data => {
    return data
  })
}

//--------------------start voting records====
const getMemberVoteRecordIvotedOn = async ({
  offset,
  member,
  member: { member_id, role },
  status,
  mySearch,
  myVote,
  state
}) => {

  const range = offset && offset == 0 ? 0 : offset * 700;
  const start = 0;
  const end = 700;
  const searchKeys = {}
  const memberVotesThatMatchMine = await Promise.all(Object.keys(myVote)?.map(async (billId) => {
    // console.log('memberVotesThatMatchMine', billId, member_id)

    if (role?.toLowerCase()?.includes('senat')) {

      const formDataSenate = {
        request: "search",
        query: {
          bioguide: member_id,
          bill_id: billId,
          state: state
        },
        resource: "calendar_senate_votes",
        id: ""
      };

      if (mySearch) {
        formDataSenate["search"] = mySearch
        formDataSenate["key"] = ["description", "bill_id", "bioguide"]
      }
      // console.log('mySearch-1', mySearch, formDataSenate)

      const appendSenateData = await RESTCall.axiosQuery(formDataSenate).then(
        response => {
          const allResponse = response.data;

          return allResponse && allResponse.length > 0 ? allResponse : [];
        }
      ).then(data => {
        return data?.map(rex => {

          rex['name'] = rex.fullName || rex.name
          rex['member_id'] = rex.bioguide
          rex['title'] = rex.item_id
          rex['item_id'] = rex.item_id || rex.bill_id
          rex['bill_id'] = rex.item_id || rex.bill_id
          rex['description'] = rex.Description || rex.description || rex.title
          rex['title'] = rex.Description || rex.description || rex.title
          rex['date'] = rex.Date || rex.date || rex.voted_at
          rex['voted_at'] = rex.Date || rex.date || rex.voted_at
          rex['vote'] = rex.vote == "�Nay" ? 'no' : rex.vote?.toLowerCase()?.includes("yea") ? 'yes' : rex.vote?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          rex['position'] = rex.vote == "�Nay" ? 'no' : rex.vote?.toLowerCase()?.includes("yea") ? 'yes' : rex.vote?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")

          return rex
        })
      })
      const memberVote = {
        // ...(appendSenateData?.[0] || {})
      }
      memberVote['item_id'] = billId
      memberVote['bill_id'] = billId
      memberVote['member_id'] = member?.member_id
      memberVote['vote'] = appendSenateData?.[0]?.vote
      return memberVote
    } else {

      const formDataHouse = {
        request: "search",
        query: {
          bioguide: member_id,
          bill_id: billId
        },
        resource: "calendar_house_votes",
        id: ""
      };
      if (mySearch) {
        formDataHouse["search"] = mySearch
        formDataHouse["key"] = ["description", "bill_id", "bioguide"]
      }
      // console.log('mySearch-2', mySearch, formDataHouse)


      const appendHouseData = await RESTCall.axiosQuery(formDataHouse).then(
        response => {
          // console.log("appendHouseDataxx", response.data);
          const allResponse = response.data;
          return allResponse && allResponse.length > 0 ? allResponse : [];
        }
      ).then(data => {
        return data?.map(rex => {
          rex['name'] = rex.fullName || rex.name
          rex['position'] = rex.vote
          rex['member_id'] = rex.bioguide
          rex['title'] = rex.item_id
          rex['item_id'] = rex.item_id || rex.bill_id
          rex['bill_id'] = rex.item_id || rex.bill_id
          rex['description'] = rex.Description || rex.description || rex.title
          rex['title'] = rex.Description || rex.description || rex.title
          rex['date'] = rex.Date || rex.date || rex.voted_at
          rex['voted_at'] = rex.Date || rex.date || rex.voted_at
          rex['vote'] = rex.vote == "�Nay" ? 'no' : rex.vote?.toLowerCase()?.includes("yea") ? 'yes' : rex.vote?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          rex['position'] = rex.vote == "�Nay" ? 'no' : rex.vote?.toLowerCase()?.includes("yea") ? 'yes' : rex.vote?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          return rex
        })
      })
      const memberVote = {
        // ...(appendHouseData?.[0] || {})
      }
      memberVote['item_id'] = billId
      memberVote['item_id'] = billId
      memberVote['bill_id'] = billId
      memberVote['member_id'] = member?.member_id
      memberVote['vote'] = appendHouseData?.[0]?.vote
      return memberVote
    }
  }))
  const filterNonVotes = memberVotesThatMatchMine?.filter(rep => rep.vote)
  return filterNonVotes && {
    // keywords: searchKeys ? searchKeys.toString() : "",
    data: filterNonVotes,
    length: filterNonVotes ? filterNonVotes.length : 0
  };

}

//get state votes that I voted on
export async function getStateMemberVoteRecordIvotedOn(props) {
  const {
    district,
    offset,
    state,
    myVote = {},
    searchKeys,
    member,
    mySearch = ""
  } = props

  const memberVotesThatMatchMine = await Promise.all(Object?.keys(myVote)?.map(async (billId) => {
    const formDataHouse = {
      request: "search",
      query: {
        district: district?.toString(),
        bill_id: billId
      },
      id: "",
      resource: "calendar_state_house_votes",
    };

    if (member?.chamber?.toLowerCase()?.includes('house')) {

      // TODO - //filter by member type, district
      const myHouse = await RESTCall.axiosQuery(formDataHouse)
        .then(response => {
          return response?.data?.map(rex => {
            rex['member_id'] = rex.district
            if (!rex?.item_id) {
              rex['item_id'] = rex.bill_id
            }
            if (rex.position) {
              rex['vote'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
              rex['position'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
            }
            return rex
          })
        })
      const appendData = [...(myHouse ? myHouse : [])]
      const memberVote = {
        ...(appendData?.[0] || {})
      }
      memberVote['item_id'] = billId
      memberVote['bill_id'] = billId
      memberVote['member_id'] = member?.member_id
      memberVote['vote'] = appendData?.[0]?.vote
      return memberVote
    } else {

      const formDataSenate = {
        request: "search",
        query: {
          district: district?.toString(),
          bill_id: billId
        },
        id: "",
        resource: "calendar_state_senate_votes",
      };
      if (mySearch) {
        formDataSenate["search"] = mySearch
        formDataSenate["key"] = ["title", "bill_id", "fullName"]
      }
      const mySanate = await RESTCall.axiosQuery(formDataSenate)
        .then(response => {

          return response?.data?.map(rex => {
            rex['member_id'] = rex.district
            if (!rex?.item_id) {
              rex['item_id'] = rex.bill_id
            }
            if (rex.position) {
              rex['vote'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
              rex['position'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
            }
            return rex
          })
        })

      const appendData = [...(mySanate ? mySanate : [])]

      const memberVote = {
        ...(appendData?.[0] || {})
      }
      memberVote['item_id'] = billId
      memberVote['bill_id'] = billId
      memberVote['member_id'] = member?.member_id
      memberVote['vote'] = appendData?.[0]?.vote
      return memberVote

    }
  }))
  const filterNonVotes = memberVotesThatMatchMine?.filter(rep => rep.vote)
  return filterNonVotes && {
    // keywords: searchKeys ? searchKeys.toString() : "",
    data: filterNonVotes,
    length: filterNonVotes ? filterNonVotes.length : 0
  };
}

//get all state votes
export async function getStateMemberVoteRecordByBill(props) {
  const {
    district,
    offset,
    state,
    mySearch,
    searchKeys,
    member,
    bill_id
  } = props


  if (member?.chamber?.toLowerCase()?.includes('house')) {
    const formDataHouse = {
      request: "search",
      query: {
        bill_id: bill_id,
        district, district,
        state: state
      },
      id: "",
      resource: "calendar_state_house_votes",
    };

    // TODO - //filter by member type, district
    const myHouse = await RESTCall.axiosQuery(formDataHouse)
      .then(response => {
        // console.log('vvvvvvvv-member-nnn', bill_id, state, response)

        return response?.data?.map(rex => {
          rex['member_id'] = rex.district
          if (!rex?.item_id) {
            rex['item_id'] = rex.bill_id
          }
          rex['vote'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          rex['position'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          return rex
        })
      })
    const appendData = [...(myHouse ? myHouse : [])]

    return {
      // keywords: searchKeys ? searchKeys.toString() : "",
      data: appendData,
      length: appendData ? appendData.length : 0
    };

  } else {

    const formDataSenate = {
      request: "search",
      query: {
        // district: district,
        bill_id: bill_id,
        district: district?.toString(),
        state: state
      },
      id: "",
      resource: "calendar_state_senate_votes",
    };
    if (mySearch) {
      formDataSenate["search"] = mySearch
      formDataSenate["key"] = ["title", "bill_id", "fullName"]
    }
    const mySanate = await RESTCall.axiosQuery(formDataSenate)
      .then(response => {

        return response?.data?.map(rex => {
          rex['member_id'] = rex.district
          if (!rex?.item_id) {
            rex['item_id'] = rex.bill_id
          }
          rex['vote'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          rex['position'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          return rex
        })
      })

    const appendData = [...(mySanate ? mySanate : [])]
    return {
      keywords: searchKeys ? searchKeys.toString() : "",
      data: appendData,
      length: appendData ? appendData.length : 0
    };
  }
}

//get all state votes
export async function getStateMemberVoteRecord(props) {
  const {
    district,
    offset,
    state,
    mySearch,
    searchKeys,
    member
  } = props

  if (member?.chamber?.toLowerCase()?.includes('house')) {
    const formDataHouse = {
      request: "search",
      query: {
        district: district?.toString(),
        state: state
      },
      id: "",
      resource: "calendar_state_house_votes",
    };
    // console.log('mystatesxxbsssss', member, formDataHouse)

    // TODO - //filter by member type, district
    const myHouse = await RESTCall.axiosQuery(formDataHouse)
      .then(response => {
        // console.log('mystatesxxbsssss', response)
        return response?.data?.map(rex => {
          rex['member_id'] = rex.district
          if (!rex?.item_id) {
            rex['item_id'] = rex.bill_id
          }
          rex['vote'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          rex['position'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          return rex
        })
      })
    const appendData = [...(myHouse ? myHouse : [])]

    return {
      keywords: searchKeys ? searchKeys.toString() : "",
      data: appendData,
      length: appendData ? appendData.length : 0
    };

  } else {

    const formDataSenate = {
      request: "search",
      query: {
        district: district?.toString(),
        state: state
      },
      id: "",
      resource: "calendar_state_senate_votes",
    };
    if (mySearch) {
      formDataSenate["search"] = mySearch
      formDataSenate["key"] = ["title", "bill_id", "fullName"]
    }
    const mySanate = await RESTCall.axiosQuery(formDataSenate)
      .then(response => {

        return response?.data?.map(rex => {
          rex['member_id'] = rex.district
          if (!rex?.item_id) {
            rex['item_id'] = rex.bill_id
          }
          rex['vote'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          rex['position'] = rex.position == "�Nay" ? 'no' : rex.position?.toLowerCase()?.includes("yea") ? 'yes' : rex.position?.replace(/\s/g, "")?.toLowerCase()?.replace('�', "")
          return rex
        })
      })

    const appendData = [...(mySanate ? mySanate : [])]
    return {
      keywords: searchKeys ? searchKeys.toString() : "",
      data: appendData,
      length: appendData ? appendData.length : 0
    };
  }
}






export const getVotesById = async (props) => {
  const { commentInfo } = props
  if (!(commentInfo && commentInfo?.item_id)) {
    console.log("No Bill ID available for this content");
    return;
  }
  const formData = {
    request: "search",
    resource: "calendar_vote",
    query: { item_id: commentInfo.item_id }
  };
  return await RESTCall.axiosQuery(formData).then(res => {
    return res && res.data ? res.data : [];
  });
}


export const getNewsRoom = async (props) => {
  const formData = {
    request: "search",
    query: {
      ...props
    },
    resource: "calendar_news_room",
    id: ""
  };
  return RESTCall.axiosQuery(formData)
    .then(response => {
      return response?.data;
    })
    .catch(err => {
      return err &&
        err.response &&
        err.response.data &&
        err.response.data.message

    });
}


export const getMemberVotesByBill = async (props) => {
  const {
    status,
    offset,
    bill_id
  } = props

  if (status == 'my state') {
    const formDataHouse = {
      request: "search",
      query: {
        bill_id: bill_id,
      },
      id: "",
      //orderBy: "dsc",
      //sortBy: "voted_at",
      resource: "calendar_state_house_votes",
    };
    const myHouse = await RESTCall.axiosQuery(formDataHouse)
      .then(response => {
        return response?.data?.map(rex => {
          rex['position'] = rex.vote
          rex['member_id'] = rex.bioguide
          rex['item_id'] = rex.item_id || rex.bill_id
          rex['bill_id'] = rex.item_id || rex.bill_id
          return rex
        })
      })

    const formDataSenate = {
      request: "search",
      query: {
        bill_id: bill_id,
      },
      id: "",
      //orderBy: "dsc",
      //sortBy: "voted_at",
      resource: "calendar_state_senate_votes",
    };
    const mySanate = await RESTCall.axiosQuery(formDataSenate)
      .then(response => {
        return response?.data?.map(rex => {
          rex['position'] = rex.vote
          rex['member_id'] = rex.bioguide
          return rex
        })
      })

    return { data: [...(mySanate ? mySanate : []), ...(myHouse ? myHouse : [])] }

  } else if (status == 'federal') {

    const formDataHouse = {
      request: "search",
      query: {
        bill_id: bill_id,
      },
      id: "",
      //orderBy: "dsc",
      //sortBy: "voted_at",
      resource: "calendar_house_votes",
    };
    const myHouse = await RESTCall.axiosQuery(formDataHouse)
      .then(response => {
        return response?.data?.map(rex => {
          rex['position'] = rex.vote
          rex['member_id'] = rex.bioguide
          rex['item_id'] = rex.item_id || rex.bill_id
          rex['bill_id'] = rex.item_id || rex.bill_id
          return rex
        })
      })

    const formDataSenate = {
      request: "search",
      query: {
        bill_id: bill_id,
      },
      id: "",
      //orderBy: "dsc",
      //sortBy: "voted_at",
      resource: "calendar_senate_votes",
    };
    const mySanate = await RESTCall.axiosQuery(formDataSenate)
      .then(response => {

        return response?.data?.map(rex => {
          rex['position'] = rex.vote
          rex['member_id'] = rex.bioguide
          rex['item_id'] = rex.item_id || rex.bill_id
          rex['bill_id'] = rex.item_id || rex.bill_id
          return rex
        })
      })

    return { data: [...(mySanate ? mySanate : []), ...(myHouse ? myHouse : [])] }

  }
}



export const loginGoogleFacebookUser = async (info) => {
  const { email, name } = info;
  if (!email) {
    return;
  }
  const formData = {
    request: "vareapilogin",
    query: {
      email: email,
      fullName: name
    },
    resource: "calendar_user",
    id: ""
  };
  return RESTCall.axiosQuery(formData)

};

const validateEmail = mail => {
  if (mail?.includes('@')) {
    return true;
  }
  alert("You have entered an invalid email address!");
  return false;
};

const isValidPhone = phoneNumber => {
  var found = phoneNumber.search(
    /^[\+]?\d{2,}?[(]?\d{2,}[)]?[-\s\.]?\d{2,}?[-\s\.]?\d{2,}[-\s\.]?\d{0,9}$/im
  );
  if (found > -1) {
    return true;
  } else {
    return false;
  }
};


export const handleRegister = async (props) => {
  const { data: {
    fullName,
    email,
    password
  } } = props

  if (
    fullName == "" ||
    email == "" ||
    password == "" ||
    // myPWRef.current.value == "" ||
    // isValidPhone(myPhoneRef.current.value) == false ||
    validateEmail(email) == false
  ) {
    alert("Please be sure to complete all fields");
    return;
  }
  return addRegister(props);
};



export const addRegister = async (props) => {
  const {
    data: {
      fullName,
      email,
      password,
    },
    media: {
      fileImageName,
      fileImageData,
      fileImageMime,
      url
    }
  } = props

  const formData = {
    request: "register",
    query: {
      ...props.data,
    },
    resource: "calendar_user",
    check: ["handle", "email"],
  };

  // if (fileImageName) {
  //   const fileName = `tube_${fileImageName.toLowerCase()}`;

  //   const imageUploader = await Axios.post(url, {
  //     mime: fileImageMime,
  //     name: fileName,
  //     image: fileImageData
  //   })
  //     .then(img => {
  //       formData.query[
  //         "img"
  //       ] = `https://varefiles.s3.us-east-2.amazonaws.com/${img.data && img.data.key ? img.data.key : fileName
  //       }`;
  //       return true;
  //     })
  //     .catch(err => {
  //       return true;
  //     });

  // }

  // if (imageUploader)
  return await RESTCall.axiosQuery(formData)
    .then(response => {
      // history.push("/login");
      // setRefreshing(false);
      // setLoginUser("login");
      // history.push("/login");
      return response
      // handleRegisterUser
    })
    .catch(error => {
      console.log("error", error);
      return error;
    });


};


export const verifyHandle = async (props) => {
  const {
    handle
  } = props

  if (handle) {
    const formData = {
      request: "search",
      query: {
        handle: handle,
      },
      resource: "calendar_user",
      id: ""
    };
    return RESTCall.axiosQuery(formData)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        return err &&
          err.response &&
          err.response.data &&
          err.response.data.message

      });
  } else {
    return null
  }
};


export const getSubscription = async (props) => {
  const formData = {
    request: "search",
    query: {
      ...props
    },
    resource: "calendar_subscription",
    id: ""
  };
  return RESTCall.axiosQuery(formData)
    .then(response => {
      return response?.data?.[0]?.status == "on" ? 'active' : null;
    })
    .catch(err => {
      return err &&
        err.response &&
        err.response.data &&
        err.response.data.message

    });
};


export const handleLogin = async (props) => {
  const {
    email = "",
    password = ""
  } = props

  const hashedPassword = password //bcrypt.hashSync(password.current.value, salt) // hash created previously created upon sign up
  if (email && password) {
    const formData = {
      request: "login",
      query: {
        email: email.toLowerCase(),
        password: hashedPassword
      },
      resource: "calendar_user",
      id: ""
    };
    return RESTCall.axiosQuery(formData)
      .then(response => {
        return response;
      })
      .catch(err => {
        return err &&
          err.response &&
          err.response.data &&
          err.response.data.message
      });
  }
};


export const handleForgotPassword = async (props) => {
  const {
    email = "",
  } = props
  // alert(email)
  if (email) {
    const formData = {
      request: "forgetlogin",
      query: {
        email: email.toLowerCase(),
      },
      // resource: "calendar_user",
      resource: "calendar_user",
      id: ""
    };
    return RESTCall.axiosQuery(formData)
      .then(response => {
        return response;
      })
      .catch(err => {
        return err &&
          err.response &&
          err.response.data &&
          err.response.data.message
      });
  }
};

export const handleResetPassword = async (props) => {
  const {
    email = "",
    password = "",
    tempPassword = ""
  } = props

  const hashedPassword = password //bcrypt.hashSync(password.current.value, salt) // hash created previously created upon sign up
  if (email && password) {
    const formData = {
      request: "forgetlogin",
      query: {
        email: email.toLowerCase(),
        tempPassword: tempPassword,
        password: hashedPassword
      },
      resource: "calendar_user",
      id: ""
    };
    return RESTCall.axiosQuery(formData)
      .then(response => {
        return response;
      })
      .catch(err => {
        return err &&
          err.response &&
          err.response.data &&
          err.response.data.message
      });
  }
};


export async function getUserEmail(id) {
  const formData = {
    request: "search",
    query: {
      handle: id
    },
    resource: "calendar_user",
    id: ""
  };
  return await RESTCall.axiosQuery(formData).then(response => {
    const data = response && response.data && response.data[0];
    return data && data.email
  });
};



export async function getUserByStringSearch(search) {

  if (!search) {
    return
  }
  const formData = {
    request: "search",
    query: {
      handle: search
    },
    // check: ["handle", "fullName"],
    resource: "calendar_user",
    id: ""
  };
  return await RESTCall.axiosQuery(formData).then(response => {
    const data = response && response.data && response.data[0];
    return data
  });
};


export async function getInfluencers(props) {
  const {
    offset,
    recordSize,
  } = props
  const formData = {
    request: "search",
    resource: "calendar_user",
    query: {
      influencer: true
      // handle: "varetech123"
    }
  };
  const allInfo = await RESTCall.axiosQuery(formData)
    .then(result => {
      return result.data?.map(res => {
        return {
          ...res,
          handle: res.handle,
          user_image: res.img,
          user_name: res.fullName
        };
      });
    })
    .catch(err => {
      // console.log("ddderr", err);
    });
  return allInfo;
  // console.log("xxxinfluencers", allInfo);
}

export async function getInfluencerStories(props) {
  const {
    pageNumber,
    handle
  } = props
  const limit = 10;
  const formData = {
    request: "search",
    resource: "calendar_user",
    id: "",
    limit: limit,
    skip: limit * (pageNumber || 0),
    limit: 50,
    //orderBy: "dsc",
    //sortBy: "date",
    query: {
      influencer: true,
      handle: handle
    }
  };
  const allInfo = await RESTCall.axiosQuery(formData)
    .then(rep => {
      const result = rep.data?.map((res, index) => {
        res["img"] =
          res.img ||
          `https://varefiles.s3.us-east-2.amazonaws.com/0${index < 10 && index != 1
            ? index + 1
            : index != 1
              ? parseInt(index.toString().substring(0, 1)) + 1
              : "8"
          }.jpg`;

        return {
          // ...res,
          _id: res._id,
          hashtags: res.hashtags,
          img: res.img,
          fullName: res.fullName,
          handle: res.handle || res._id,
          item_id: res.handle || res._id,
          // bill_id: res.handle || res._id,
          createdAt: res.Timestamp,
          urlToImage: res.img,
          video: res.video,
          links: [
            {
              name: "",
              value: res.video
                ? res.url
                : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`
            }
          ],
          videoUrl: res.video
            ? res.url
            : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`,
          videoInfo: {
            title: res.fullName || "Incorgnito",
            description: {
              views: res.views ? res.views.length : 1,
              uploadDate: res.Timestamp && moment(res.Timestamp).fromNow()
            },
            videoLength: "4:58",
            videoThumbnailUrl: res.img
          },
          channelInfo: {
            channelName: `${res.fullName} (Vare Influencer)`,
            channelAvatarImage: res.img
          }
        };
      });
      return result;
    })
    .catch(err => {
      // console.log("ddderr", err);
    });
  return allInfo;
  // console.log("xxxinfluencers", allInfo);
}

export async function getVoterStories(props) {
  const {
    pageNumber,
    handle
  } = props
  const limit = 10;
  const formData = {
    request: "search",
    resource: "calendar_user",
    id: "",
    limit: limit,
    skip: limit * (pageNumber || 0),
    limit: 50,
    //orderBy: "dsc",
    //sortBy: "date",
    query: {
      handle: handle
    }
  };
  const allInfo = await RESTCall.axiosQuery(formData)
    .then(rep => {
      const result = rep.data?.map((res, index) => {
        res["img"] =
          res.img ||
          `https://varefiles.s3.us-east-2.amazonaws.com/0${index < 10 && index != 1
            ? index + 1
            : index != 1
              ? parseInt(index.toString().substring(0, 1)) + 1
              : "8"
          }.jpg`;

        return {
          // ...res,
          _id: res._id,
          hashtags: res.hashtags,
          img: res.img,
          fullName: res.fullName,
          handle: res.handle || res._id,
          item_id: res.handle || res._id,
          // bill_id: res.handle || res._id,
          createdAt: res.Timestamp,
          urlToImage: res.img,
          video: res.video,
          links: [
            {
              name: "",
              value: res.video
                ? res.url
                : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`
            }
          ],
          videoUrl: res.video
            ? res.url
            : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`,
          videoInfo: {
            title: res.fullName || "Incorgnito",
            description: {
              views: res.views ? res.views.length : 1,
              uploadDate: res.Timestamp && moment(res.Timestamp).fromNow()
            },
            videoLength: "4:58",
            videoThumbnailUrl: res.img
          },
          channelInfo: {
            channelName: `${res.fullName} (Vare Influencer)`,
            channelAvatarImage: res.img
          }
        };
      });
      return result;
    })
    .catch(err => {
      // console.log("ddderr", err);
    });
  return allInfo;
  // console.log("xxxinfluencers", allInfo);
}

export async function calculatVote(result) {
  const finteredArray =
    result &&
    result.length > 0 &&
    result.filter(info => {
      return request && request.length > 0 && request.includes(info._id);
    });

  const finalResult =
    finteredArray && finteredArray.length > 0
      ? finteredArray.map(info => {
        const tempObject = {};
        tempObject["_id"] = info._id;
        tempObject["votes"] = info.calendar_vote.reduce(
          (a, c) => ((a[c] = (a[c] || 0) + 1), a),
          Object.create({})
        );
        tempObject["age"] = info.age.reduce(
          (a, c) => ((a[c] = (a[c] || 0) + 1), a),
          Object.create({})
        );
        tempObject["race"] = info.race.reduce(
          (a, c) => ((a[c] = (a[c] || 0) + 1), a),
          Object.create({})
        );
        tempObject["party"] = info.party.reduce(
          (a, c) => ((a[c] = (a[c] || 0) + 1), a),
          Object.create({})
        );
        tempObject["gender"] = info.gender.reduce(
          (a, c) => ((a[c] = (a[c] || 0) + 1), a),
          Object.create(null)
        );
        tempObject["count"] = info.count;
        return tempObject;
      })
      : [];
}



export async function updateState(record) {
  const { itemInfo, value, influencer, myVote, myInfo = {} } = record;
  const itemKey = itemInfo
    ? itemInfo
    : record.bill_id
      ? record.bill_id
      : record.item_id;

  if (myInfo && myInfo.address && myInfo.email) {
    return await databaseSaveVote(record);
  } else {
    return null;
  }
}

export async function getStories({ pageNumber, influencer }) {
  const queryInfo = {
    // reply: false,
    name: null
  };

  let limit = 100;

  const formData = {
    request: "search",
    query: { ...queryInfo },
    resource: "calendar_news_feeds",
    id: "",
    // pageNumber: pageNumber ? pageNumber : 0,
    limit: limit,
    skip: limit * pageNumber,
    //orderBy: "dsc",
    //sortBy: "date"
  };
  return await RESTCall.axiosQuery(formData).then(response => {
    const result = response?.data?.map(res => {
      return {
        ...res,
        story_id: 1,
        story_image:
          res.picture ||
          res.urlToImage ||
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjORKvjcbMRGYPR3QIs3MofoWkD4wHzRd_eg&usqp=CAU",
        swipeText: "Swipe",
        onPress: () => {
          // console.log("story 1 swiped")
        }
      };
      // console.log("xxxresult333", response?.data);
    });

    // console.log("zzz", data);
    return result;
  });
}



export async function getContent(props) {
  const { pageNumber, pageSize, mySearch } = props
  const queryInfo = {
    reply: false,
    mediaType: null
  };

  let limit = pageSize || 10;

  const formData = {
    request: "search",
    query: { ...queryInfo },
    resource: "calendar_contents",
    id: "",
    // pageNumber: pageNumber ? pageNumber : 0,
    limit: limit,
    skip: limit * pageNumber,
    //orderBy: "dsc",
    //sortBy: "date"
  };

  if (mySearch) {
    formData["search"] = mySearch
    formData["key"] = ["title", "bill_id", "views"]
  }

  return await RESTCall.axiosQuery(formData).then(response => {
    const data =
      response && response.data && response.data.length > 0
        ? response.data.map(rep => {
          if (rep.likes) rep["likes"] = JSON.parse(rep.likes);
          if (rep.views) rep["views"] = JSON.parse(rep.views);
          return rep;
        })
        : [];

    const result = data?.map(res => {

      return {
        ...res,
        urlToImage: res.urlToImage ? res.urlToImage : res.picture,
        links: [
          {
            name: "",
            value: res.video
              ? res.url
              : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`
          }
        ],
        videoUrl: res.video
          ? res.video
          : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`,
        videoInfo: {
          title: res.title,
          description: {
            views: res.views ? res.views.length : 1,
            uploadDate: moment(res.date).fromNow()
          },
          videoLength: "4:58",
          videoThumbnailUrl: res.picture
            ? res.picture
            : `https://varefiles.s3.us-east-2.amazonaws.com/bill-image/hr482.jpg`
        },
        channelInfo: {
          channelName: "Vare Admin",
          channelAvatarImage: res.picture
        }
      };
    });
    return result;
  });
}

export async function getContentById(props) {
  // const { pageNumber, pageSize, mySearch } = props


  // let limit = pageSize || 10;

  const formData = {
    request: "search",
    query: { ...props },
    resource: "calendar_contents",
    id: "",
    // pageNumber: pageNumber ? pageNumber : 0,
    limit: 500,
    // skip: limit * pageNumber,
    //orderBy: "dsc",
    //sortBy: "date"
  };

  // if (mySearch) {
  //   formData["search"] = mySearch
  //   formData["key"] = ["title", "bill_id", "views"]
  // }

  return await RESTCall.axiosQuery(formData).then(response => {
    const data =
      response && response.data && response.data.length > 0
        ? response.data.map(rep => {
          if (rep.likes) rep["likes"] = JSON.parse(rep.likes);
          if (rep.views) rep["views"] = JSON.parse(rep.views);
          return rep;
        })
        : [];

    const result = data?.map(res => {

      return {
        ...res,
        urlToImage: res.urlToImage ? res.urlToImage : res.picture,
        links: [
          {
            name: "",
            value: res.video
              ? res.url
              : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`
          }
        ],
        videoUrl: res.video
          ? res.video
          : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`,
        videoInfo: {
          title: res.title,
          description: {
            views: res.views ? res.views.length : 1,
            uploadDate: moment(res.date).fromNow()
          },
          videoLength: "4:58",
          videoThumbnailUrl: res.picture
            ? res.picture
            : `https://varefiles.s3.us-east-2.amazonaws.com/bill-image/hr482.jpg`
        },
        channelInfo: {
          channelName: "Vare Admin",
          channelAvatarImage: res.picture
        }
      };
    });
    return result;
  });
}



export async function getDirectMessage(props) {
  const { handle } = props
  let limit = 400;
  let pageNumber = 0

  const formData = {
    request: "search",
    query: { handle: handle },
    resource: "calendar_direct_messages",
    id: "",
    limit: limit,
    skip: limit * pageNumber,
  };

  const fromMe = await RESTCall.axiosQuery(formData).then(response => {
    return response && response.data && response.data.length > 0
      ? response.data
      : [];
  });

  const formDataB = {
    request: "search",
    query: { item_id: handle },
    resource: "calendar_direct_messages",
    id: "",
    limit: limit,
    skip: limit * pageNumber,
  };

  const toMe = await RESTCall.axiosQuery(formDataB).then(response => {
    return response && response.data && response.data.length > 0
      ? response.data
      : [];
  });
  // console.log('bbbbb', fromMe, toMe)
  return [...fromMe, ...toMe]
}



export async function saveDirectMessageDB(props) {
  const dbVote = {
    ...props
  };

  const formData = {
    request: "insert",
    query: dbVote,
    resource: "calendar_direct_messages",
    check: ["date", "handle"]
  }


  return await RESTCall.axiosQuery(formData)
    .then(response => {
      // console.log("postInfo", response?.data[0]);
      return response?.data;
    })
    .catch(error => {
      return error;
    });
}

export async function getComments(props) {

  const { pageNumber, pageSize, content_id } = props
  const queryInfo = {
    content_id: content_id
  };

  let limit = pageSize || 10;

  const formData = {
    request: "search",
    query: { ...queryInfo },
    resource: "calendar_portal_comments",
    id: "",
    // pageNumber: pageNumber ? pageNumber : 0,
    limit: limit,
    skip: limit * pageNumber,
    //orderBy: "dsc",
    //sortBy: "date"
  };
  // const formDataB = {
  //   request: "get",
  //   resource: "tube_comments",
  // }

  return await RESTCall.axiosQuery(formData).then(response => {
    return response && response.data && response.data.length > 0
      ? response.data
      : [];
  });
}

// export async function saveBlockUserDB(props) {
//   const {
//     myInfo,
//     block
//   } = props;

//   if (!myInfo?.handle) {
//     alert('Please login to take this action..')
//     return
//   }

//   const dbVote = {
//     "date": moment().format(),
//     "handle": myInfo?.handle,
//     "block": block
//   };
//   const formData = {
//     request: "insert",
//     query: dbVote,
//     resource: "calendar_blocked_users", databaseSaveBlockedUser
//     check: ["handle", "block"]
//   };
//   return await RESTCall.axiosQuery(formData)
//     .then(response => {
//       return response?.data;
//     })
//     .catch(error => {
//       return error;
//     });
// }


export async function saveCommentDB(props) {
  const {
    myInfo,
    comment,
    replyContact,
    commentInfo,
  } = props;
  // console.log('comment-DB', props)

  if (!myInfo?.handle) {
    alert('Please login to add comments..')
    return
  }

  const dbVote = {
    "content_id": commentInfo.item_id,
    "comment_id": replyContact.comment_id || null,
    "reply": replyContact?.comment_id ? true : false,
    "date": moment().format(),
    "comment": comment,
    "createdAt": moment().format(),
    "img": myInfo?.img,
    "name": myInfo?.name,
    "text": comment,
    "handle": myInfo?.handle,
    "handle": myInfo?.handle,
    "sex": myInfo.age,
    "age": myInfo.age,
    "race": myInfo.race,
    "party": myInfo.party,
    "influencer_id": myInfo.influencer_id,
    "source": commentInfo.source,
    "sourceCaller": commentInfo.sourceCaller
  };
  const formData = {
    request: "insert",
    query: dbVote,
    resource: "calendar_portal_comments",
    check: ["handle", "date"]
  };
  return await RESTCall.axiosQuery(formData)
    .then(response => {
      // console.log("postInfo", response?.data[0]);
      return response?.data;
    })
    .catch(error => {
      return error;
    });
}

export async function deleteCommentDB(id) {
  // console.log('comment-DB-id', id)


  if (!id) {
    return
  }
  const formData = {
    request: "delete",
    query: {},
    resource: "calendar_portal_comments",
    id: id
  };
  return await RESTCall.axiosQuery(formData)
    .then(response => {
      // console.log("postInfo", response);
      return response;
    })
    .catch(error => {
      return error;
    });
}


export async function deleteDirectMessageDB(id) {
  // console.log('comment-DB-id', id)

  if (!id) {
    return
  }
  const formData = {
    request: "delete",
    query: {},
    resource: "calendar_direct_messages",
    id: id
  };
  return await RESTCall.axiosQuery(formData)
    .then(response => {
      // console.log("postInfo", response);
      return response;
    })
    .catch(error => {
      return error;
    });
}





export async function getChats(props) {

  // const socketRef = io.connect("http://localhost:5002", {
  //   cors: {
  //     origin: "*"
  //   }
  // });
  // socketRef.emit("chat message", [
  //   {
  //     content_id: "60e7352fed2411b4d91796c6"
  //   }
  // ]);
  // socketRef.on("chat message", chat => {
  //   console.log("chat message", chat);
  //   return chat;
  // });

  const { pageNumber, pageSize, content_id, handle } = props
  const queryInfo = {
    // reply: false,
    handle: handle
  };

  let limit = pageSize || 10;

  const formData = {
    request: "search",
    query: { ...queryInfo },
    resource: "calendar_portal_chats",
    id: "",
    // pageNumber: pageNumber ? pageNumber : 0,
    limit: limit,
    skip: limit * pageNumber,
    //orderBy: "dsc",
    //sortBy: "date"
  };

  return await RESTCall.axiosQuery(formData).then(response => {
    return response && response.data && response.data.length > 0
      ? response.data
      : [];
  });
}



export const handleShare = async (shareData) => {
  // if ('Android' in window) {
  //   Android.nativeShare(shareData.title, shareData.text, shareData.url)
  //   return
  // }
  // try {
  //   await navigator.share(shareData)
  //   console.log('shared successfully')
  // } catch (err) {
  //   console.log('Error: ' + err)
  // }
}

export const detectChrome = async () => {
  var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  return isChrome ? true : false
}

export const detectAppMobile = async () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    return 'isApp'
  }
  return 'isNotApp'
  // var userAgent = window.navigator.userAgent.toLowerCase(),
  //   safari = /safari/.test(userAgent),
  //   ios = /iphone|ipod|ipad/.test(userAgent);
  // if (ios) {
  //   if (safari) {
  //     //browser
  //   } else if (!safari) {
  //     //webview
  //   };
  // } else {
  //   //not iOS

  // };
}

//TODO
export const sendEmail = async (email) => {
  const { to, text, html, subject } = email
  if (!text || !to) {
    return
  }
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");


  // email['from'] = from || '"VareTech" <member@vareapp.com>' // sender address
  email['to'] = to || to?.toString() // list of receivers
  email['subject'] = subject || "Message from Vare Portal ✔" // Subject line
  email['text'] = text || "Hello world?" // plain text body
  email['html'] = text || `<b>${text?.toString()}</b>` // html body
  var raw = JSON.stringify(email);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${urlPrefix}/email`, requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}


export const saveMessageLikes = async message => {
  const temp = { ...message };
  delete temp["_id"];
  const formData = {
    request: "insert",
    query: {
      ...temp
    }, //add table key value to edit
    resource: "calendar_news_feeds", //add table name
    check: ["author", "publishedAt"]
  };
  return await RESTCall.axiosQuery(formData).then(contents => {
    // getNews();
    return contents;
  });
};

// export async function getNews({ pageNumber, pageSize }) {
//   const queryInfo = {
//     name: null
//   };
//   let limit = 10;
//   const formData = {
//     request: "search",
//     query: { ...queryInfo },
//     resource: "calendar_news_feeds",
//     id: "",
//     limit: limit,
//     skip: limit * pageNumber,
//     limit: 10,
//     //orderBy: "dsc",
//     //sortBy: "publishedAt"
//   };

//   return await RESTCall.axiosQuery(formData).then(response => {
//     const result = response?.data?.map(res => {
//       return {
//         ...res,
//         item_id: `${res.source.name} | ${res.author}`,
//         urlToImage: res.urlToImage,
//         links: [
//           {
//             name: "",
//             value: res.url
//               ? res.url
//               : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`
//           }
//         ],
//         videoUrl: res.video
//           ? res.url
//           : `https://varefiles.s3.us-east-2.amazonaws.com/how-to-use-vare-v2.mp4`,
//         videoInfo: {
//           title: res.title,
//           description: {
//             views: res.views ? res.views.length : 1,
//             uploadDate: moment(res.date).fromNow()
//           },
//           videoLength: "4:58",
//           videoThumbnailUrl: res.urlToImage
//             ? res.urlToImage
//             : `https://varefiles.s3.us-east-2.amazonaws.com/bill-image/hr482.jpg`
//         },
//         channelInfo: {
//           channelName: `${res.source.name} | ${res.author}`,
//           channelAvatarImage: res.picture
//         }
//       };
//     });
//     return result;
//   });
// }

export function groupByCount({ data, key }) {
  const info = {};
  let group = data.reduce((r, a) => {
    r[a[key]] = [...(r[a[key]] || []), a];

    if (
      !(
        key == "sex" &&
        (a[key] == "20" ||
          a[key] == "30" ||
          a[key] == "40" ||
          a[key] == "50" ||
          a[key] == "60" ||
          a[key] == "70" ||
          a[key] == "80")
      )
    )
      info[a[key]] = [...(r[a[key]] || []), a].length;
    return r;
  }, {});
  // console.log("ddd", info);
  return info;
}

export async function saveChatVotes(props) {
  const { message, contentId, myInfo = {}, myVote } = props

  const responseInfo = message && message[0];

  if (responseInfo) {
    const dbVote = {
      item_id: responseInfo.messageId,
      content_id: contentId,
      handle: myInfo.handle,
      age: myInfo.age,
      date: moment().format(),
      installId: Constants.installationId,
      name: myInfo.fullName,
      party: myInfo.party,
      race: myInfo.race,
      sex: myInfo.sex,
      vote: responseInfo.value
      // title: origin.text,
      // img: origin.image,
      // video: origin.video
    };
    // console.log("dbVote", contentId, originalMsg);
    const formData = {
      request: "insert",
      query: dbVote,
      resource: "calendar_chat_vote",
      check: ["item_id", "handle"]
    };
    const result = await RESTCall.axiosQuery(formData)
      .then(response => {
        // console.log("response", response);
        myVote[responseInfo.messageId] = responseInfo.value;
        return response;
      })
      .catch(error => {
        // console.log("error", error);

        return error;
      });
  }
}

export async function getEngagementRecord(props) {
  const formData = {
    request: "search",
    resource: "calendar_engagement",
    query: { ...props }
  };
  return await RESTCall.axiosQuery(formData)
    .then(res => {
      return res && res.data;
    })
    .catch(err => {
      return [];
    });
}

export async function getEngagementAndPrivacy(props) {
  const { item_id } = props
  const formDataA = {
    request: "search",
    resource: "calendar_user",
    query: {
      handle: item_id
    }
  };
  const userPrivacy = await RESTCall.axiosQuery(formDataA)
    .then(res => {
      return res && res.data?.[0]?.privacy;
    })
    .catch(err => {
      return '';
    });

  const formData = {
    request: "search",
    resource: "calendar_engagement",
    query: { ...props }
  };
  const engagement = await RESTCall.axiosQuery(formData)
    .then(res => {
      return res && res.data;
    })
    .catch(err => {
      return false;
    });
  return {
    privacy: userPrivacy,
    engagement
  }
}

export async function saveBlockedGuest(props) {
  const {
    myInfo = {},
    name,
    group,
    handle,
    picture
  } = props;

  if (myInfo && myInfo.address && myInfo.email) {

    const guestInfo = {
      name,
      group,
      handle,
      picture
    }
    const dbVote = {
      name: myInfo.fullName,
      img: myInfo.img,
      date: moment().format(),
      item_id: handle,
      handle: myInfo?.handle,
      guest: JSON.stringify(guestInfo)
    };

    const formData = {
      request: "insert",
      query: dbVote,
      resource: "calendar_blocked_engagements",
      check: ["item_id", "handle"]
    };
    //todo implement block
    // console.log('mmmmm', formData)
    return
    return await RESTCall.axiosQuery(formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}

export async function getBlockedRecord(props) {
  const formData = {
    request: "search",
    resource: "calendar_blocked_engagements",
    query: { ...props }
  };
  return await RESTCall.axiosQuery(formData)
    .then(res => {
      return res && res.data;
    })
    .catch(err => {
      return [];
    });
}


export async function getVoterVoteByHandleAndBill(props) {
  const {
    item_id,
    handle,
    offset = 0,
    recordSize = 100,
  } = props

  const formData = {
    request: "search",
    resource: "calendar_vote",
    // query: { share: handle }
    query: {
      handle,
      item_id
    }
  };
  return await RESTCall.axiosQuery(formData)
    .then(res => {
      return res && res.data;
    })
    .catch(err => {
      return [];
    });
}

export async function getVotingRecord(props) {

  const formData = {
    request: "search",
    resource: "calendar_vote",
    // query: { share: handle }
    query: { ...props }

  };
  return await RESTCall.axiosQuery(formData)
    .then(res => {
      return res && res.data;
      // return res && res.data;
    })
    .catch(err => {
      return [];
    });
}

export async function getVotersRecord(props) {
  const {
    handle,
    offset = 0,
    recordSize = 100,
    myInfo
  } = props

  const action = handle == 'all' ? {
    handle: myInfo.handle
  } : { handle: handle }


  const formData = {
    request: "search",
    resource: "calendar_vote",
    // query: { share: handle }
    query: { ...action }

  };
  return await RESTCall.axiosQuery(formData)
    .then(res => {
      return res && res.data;
      // return res && res.data;
    })
    .catch(err => {
      return [];
    });
}

export async function getVotesByContentId({ content_id }) {
  // console.log("contentIdxx", content_id);
  //
  const formData = {
    request: "search",
    resource: "calendar_chat_vote",
    query: { content_id: content_id }
  };
  return await RESTCall.axiosQuery(formData)
    .then(res => {
      // console.log("contentIdyy", res);
      return res && res.data;
      // return res && res.data;
    })
    .catch(err => {
      return [];
    });
}

export async function getVotesByMessageId({ item_id }) {
  // console.log("contentIdxx", content_id);
  //
  const formData = {
    request: "search",
    resource: "calendar_chat_vote",
    query: { item_id: item_id }
  };
  return await RESTCall.axiosQuery(formData)
    .then(res => {
      // console.log("contentIdyy", res);
      return res && res.data;
      // return res && res.data;
    })
    .catch(err => {
      return [];
    });
}

export async function getPresidentialCongressBills(props) {
  const { candidateId, myWatch } = props

  if (candidateId === "P60012143") {
    const formData = {
      request: "get",
      resource: "calendar_bills_joe"
    };
    const existingData = await RESTCall.axiosQuery(formData) //Check if data already exist
      .then((results, index) => {
        // console.log('biiidresults',results)
        return (
          results &&
          results.map(result => {
            let resultObject = {
              member_id: result.member_id,
              description: result.description + " | " + result.title,
              introduced_date: result.introduced_date,
              time: result.time,
              position: result.position,
              result: result.result,
              bill_id: result.bill_id,
              latest_action: result.latest_action,
              sponsor_id: result.bill_id,
              // "title": result.title && result.title,
              title: result.description + " | " + result.title,
              committees: result.committees
            };
            return resultObject;
          })
        );
      })
      .catch(err => {
        return false;
      });
    return existingData;
  } else if (candidateId === "P80001571") {
    const formData = {
      request: "get",
      resource: "calendar_exec"
    };
    const existingData = await RESTCall.axiosQuery(formData) //Check if data already exist
      .then((results, index) => {
        // console.log('resultsdonald',results)
        return (
          results &&
          results.map(result => {
            let resultObject = {
              member_id: candidateId,
              description:
                result.title +
                " | citation: " +
                result.citation +
                " | executive_order_number: " +
                result.executive_order_number,
              introduced_date: result.signing_date,
              time: result.signing_date,
              position: "Yes",
              result: result.result,
              bill_id: result.executive_order_number,
              latest_action: result.bill && result.bill.latest_action,
              sponsor_id: result.document_number,
              title: result.title,
              committees: result.subtype
            };
            return resultObject;
          })
        );
      })
      .catch(err => {
        return false;
      });
    return existingData;
  }
}

export async function getPresidentialCandidate() {
  const votingRecord = {};
  votingRecord["P60012143"] = {
    challenger: "C",
    chamber: "Presidential",
    congress: "",
    date_of_birth: "",
    election: "President",
    first_name: "JOE R",
    gender: "M",
    id: 551,
    last_name: "BIDEN",
    member_id: "P60012143", //"DE - D"
    missed_votes: null,
    name: "BIDEN, JOE R",
    party: "D",
    state: "US",
    total_votes: null,
    watch: true
  };

  votingRecord["P80001571"] = {
    challenger: "I",
    chamber: "Presidential",
    congress: "",
    date_of_birth: "",
    election: "President",
    first_name: "Donald",
    gender: "M",
    id: 551,
    last_name: "Trump",
    member_id: "P80001571",
    missed_votes: null,
    name: "Donald Trump",
    party: "R",
    state: "US",
    total_votes: null,
    watch: true
  };

  votingRecord["H001075"] = {
    challenger: "C",
    chamber: "Vice Presidential",
    congress: "",
    date_of_birth: "",
    election: "Vice President",
    first_name: "Kamala",
    gender: "F",
    id: 551,
    last_name: "Harris",
    member_id: "H001075", //"DE - D"
    missed_votes: 266,
    missed_votes_pct: 48.28,
    name: "Kamala Harris",
    party: "D",
    picture: "https://theunitedstates.io/images/congress/225x275/H001075.jpg",
    watch: true,
    state: "CA",
    total_votes: 551,
    votes_against_party_pct: 22.91,
    votes_with_party_pct: 77.1
  };

  votingRecord["MAYOR01"] = {
    message: [
      {
        type: "youtube",
        link:
          "https://www.youtube.com/embed/4-1Mm1rnCwU?rel=0&autoplay=0&showinfo=0&controls=0"
      },
      {
        type: "youtube",
        link:
          "https://www.youtube.com/embed/c0UKpm8ObgA?rel=0&autoplay=0&showinfo=0&controls=0"
      },
      {
        type: "image",
        link: "https://vareapp.com/images/vare-walk.jpeg"
      },
      {
        type: "image",
        link: "https://vareapp.com/images/debate.JPEG"
      },
      {
        type: "youtube",
        link:
          "https://www.youtube.com/embed/xXJa0XhVRfU?rel=0&autoplay=0&showinfo=0&controls=0"
      }
    ],
    date_of_birth: "1983-03-23",
    election: "Mayoral",
    email: "JLDade@gmail.com",
    facebook_account: "https://www.facebook.com/groups/jonathandadecampaign/",
    challenger: "C",
    first_name: "Jonathan",
    chamber: "Mayor",
    gender: "M",
    id: "MAYOR01",
    member_id: "MAYOR01",
    last_name: "Dade",
    candidate_id: "",
    name: "Jonathan Dade",
    office: "107 Cannon House Office Building",
    offset: 0,
    party: "R",
    phone: "512-525-0736",
    picture:
      "https://rlbechad.org/wp-content/uploads/2018/02/Jonathan-Business.jpg",
    state: "TX",
    url: "https://rlbechad.org/jonathan-dade-campaign/",
    youtube_account: "https://www.youtube.com/jonathandade"
  };

  return votingRecord;
}

export const loadMessages = async (contentId) => {
  if (!contentId) {
    return null
  }

  return await getComments({
    content_id: contentId,
    pageNumber: 0,
    pageSize: 500
  }).then(rep => {
    const sample = {
      "_id": "619161b7ffa5f5c37d95a643",
      "content_id": "f6ee5340-f761-4a23-b50f-da95079a591a",
      "date": "2021-11-14T13:21:26-06:00",
      "comment": "Gghh",
      "createdAt": "2021-11-14T19:21:26.811Z",
      "group": "[{\"name\":\"Wood\",\"number\":\"2813871628\"},{\"name\":\"Miko Dixson\",\"number\":\"2816761908\"}]",
      "img": "https://placeimg.com/140/140/any",
      "name": "Wood",
      "text": "Gghh",
      "user": {
        "_id": 1
      },
      "handle": "f2afae4abbe6"
    }
    const chatList = rep && rep.length > 0 && rep?.map(rex => {
      return {
        ...rex,
        userId: rex.handle,
        comId: rex.content_id,
        fullName: rex.name,
        userProfile: '',
        text: rex.comment,// 'I think you have a point🤔',
        avatarUrl: rep.handle ? `https://varefiles.s3.us-east-2.amazonaws.com/${rex.handle?.replace('.com', '.jpg')}`
          : `https://ui-avatars.com/api/name=${rex.name}&background=random`,
        replies: []
      }
    })?.sort((a, b) => {
      var dateA = new Date(a.date).getTime();
      var dateB = new Date(b.date).getTime();
      return dateA > dateB ? -1 : 1;
    })
    // console.log('cccc', contentId, chatList)
    return chatList
  })
}


export const getBillSupportVoteMatches = async (props) => {
  const { item_id, bill_id } = props
  const formData = {
    request: "search",
    resource: "calendar_vote",
    query: {
      item_id: item_id || bill_id
    }
  };
  const infVotes = await RESTCall.axiosQuery(formData).then(res => {
    return res && res.data;
  });
  const votes = infVotes;
  // console.log('xxxxnn', votes)
  if (votes && votes.length == 0) {
    return;
  }
  const voteGroup = await groupBy({ data: votes, key: "vote" });
  if (voteGroup && Object.keys(voteGroup).length > 0) {
    const newVoteMatch =
      voteGroup["yes"] && !voteGroup["no"]
        ? 100
        : !voteGroup["yes"] && voteGroup["no"]
          ? 0
          : voteGroup["yes"] && voteGroup["no"]
            ? Math.round((voteGroup["yes"].length / votes.length) * 100)
            : 0;
    // console.log('xxxxnn', votes, newVoteMatch)

    return {
      value: newVoteMatch,
      noCount: voteGroup["no"] && voteGroup["no"].length > 0 ? voteGroup["no"].length : 0,
      yesCount: voteGroup["yes"] && voteGroup["yes"].length > 0 ? voteGroup["yes"].length : 0,
      count: (voteGroup["yes"] ? voteGroup["yes"].length : 0) + (voteGroup["no"] ? voteGroup["no"].length : 0),
      item_id: item_id || bill_id,
      bill_id: item_id || bill_id
    }
  }
};

export const getMeeting = async (props) => {
  let limit = 10;

  const formData = {
    request: "search",
    query: { ...props },
    resource: "calendar_townhall_meetings",
    id: "",
    //orderBy: "dsc",
    //sortBy: "date"
  };
  return await RESTCall.axiosQuery(formData).then(response => {
    return response?.data
  });
};

export const getMyVote = async (props) => {
  const formData = {
    request: "search",
    resource: "calendar_vote",
    query: {
      ...props
    }
  };
  return await RESTCall.axiosQuery(formData).then(res => {
    return res && res.data;
  });

};


export const getMeetings = async (props) => {
  const formData = {
    request: "search",
    resource: "calendar_townhall_meetings",
    query: {
      ...props
    }
  };
  return await RESTCall.axiosQuery(formData).then(res => {
    return res && res.data;
  });

};




// export const getMyEngagements = async (props) => {
//   const { item_id, handle } = props
//   const formData = {
//     request: "search",
//     resource: "calendar_engagements",
//     query: {
//       // item_id: item_id,
//       handle: handle
//     }
//   };
//   return await RESTCall.axiosQuery(formData).then(res => {
//     return res && res.data;
//   });
// };

// save Engagement
export async function databaseSaveEngagementAccept(props) {
  const {
    host_accepted,
    item_id,
    handle
  } = props;

  // if (myInfo && myInfo.address && myInfo.email) {
  const dbVote = {
    host_accepted,
    item_id,
    handle
  };
  const formData = {
    request: "insert",
    query: dbVote,
    resource: "calendar_engagement",
    check: ["item_id", "handle"]
  };
  return await RESTCall.axiosQuery(formData)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}
// }


export const getBlockedUser = async (props) => {
  const formData = {
    request: "search",
    resource: "calendar_blocked_users",
    query: {
      ...props
    }
  };
  return await RESTCall.axiosQuery(formData).then(res => {
    return res && res.data;
  });

};


// save Blocked Users
export async function databaseSaveBlockedUser(props) {
  const {
    blocked,
    item_id,
    handle
  } = props;

  const formData = {
    request: "insert",
    query: {
      ...props,
      "date": moment().format(),
      blocked,
      item_id,
      handle
    },
    resource: "calendar_blocked_users",
    check: ["item_id", "handle"]
  };
  return await RESTCall.axiosQuery(formData)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

// save Engagement
export async function databaseSaveEngagement(props) {
  const { itemInfo,
    vote,
    item_id,
    myInfo,
    group,
    picture,
    name
  } = props;

  if (myInfo && myInfo.address && myInfo.email) {


    const hostInfo = {
      name,
      group,
      handle: item_id,
      picture
    }

    // console.log('hostInfo', hostInfo)
    const dbVote = {
      name: myInfo.fullName,
      img: myInfo.img,
      date: moment().format(),
      item_id: item_id,
      vote: vote,
      group: group || 'voter',
      handle: myInfo.handle,
      host: JSON.stringify(hostInfo)
    };

    const formData = {
      request: "insert",
      query: dbVote,
      resource: "calendar_engagement",
      check: ["item_id", "handle"]
    };

    return await RESTCall.axiosQuery(formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}


export const getMyViews = async (props) => {
  const { item_id, handle } = props
  const formData = {
    request: "search",
    resource: "calendar_views",
    query: {
      // item_id: item_id,
      handle: handle
    }
  };
  return await RESTCall.axiosQuery(formData).then(res => {
    return res && res.data;
  });
};

// save Engagement
export async function databaseSaveViews(props) {
  const {
    itemInfo, vote,
    influencer_id,
    item_id,
    bill_id,
    myInfo,
    share,
    type
  } = props;

  const itemKey = itemInfo || item_id || bill_id;

  // let myInfo = false //await LocalStorage.getItem.get("myInfo");
  let myOldVote = false //await LocalStorage.getItem.get("myVote");

  if (myInfo && myInfo.address && myInfo.email) {
    const influencerInfo =
      !influencer_id || influencer_id == "NA"
        ? {}
        : { influencer_id: influencer_id };
    const dbVote = {
      ...influencerInfo,
      name: myInfo.fullName,
      img: myInfo.img,
      date: moment().format(),
      handle: myInfo.handle ? myInfo.handle : myInfo.email,
      item_id: itemKey,
      type: type,
      vote: props["vote"],
      handle: myInfo.handle,
      share: share
    };
    // console.log("xxxxdbVote", dbVote);

    const formData = {
      request: "insert",
      query: dbVote,
      resource: "calendar_views",
      check: ["item_id", "handle"]
    };
    return await RESTCall.axiosQuery(formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}


export const getVoteLinks = async (props) => {

  const formData = {
    request: "search",
    query: {
      ...props
    },
    resource: "calendar_news_ai",
    id: "",
    // //orderBy: "dsc",
    // //sortBy: "voted_at"
    limit: 400,
    // skip: limit * offset
  };
  return await RESTCall.axiosQuery(formData)
    .then(rep => {
      return rep?.data;
    })
    .catch(err => {
      return err;
    });
};


export const getAllLikes = async (props) => {
  const { contentId } = props
  const myQuery = contentId
    ? {
      item_id: contentId
    }
    : {
      name: null
    };

  const formData = {
    request: "search",
    query: {
      ...myQuery
    },
    resource: "calendar_user_likes",
    id: "",
    //orderBy: "dsc",
    //sortBy: "date"
    // limit: limit,
    // skip: limit * offset
  };
  await RESTCall.axiosQuery(formData)
    .then(rep => {
      // if (rep && rep.data && rep.data.length > 0) setAllLikes(rep.data);
      return rep;
    })
    .catch(err => {
      // if (rep && rep.data && rep.data.length > 0) setAllLikes(rep.data);
      return err;
    });
};

export async function getVoteMatchPct(props) {
  const { member_id, myVote, congress } = props
  const matchInfo = congress.map(res => {
    return res.record.filter(resb => {
      return Object.keys(myVote).includes(resb.bill.bill_id) == true;
    });
  });
}

export async function insertDatabase(props) {
  const { formData } = props
  const result = await RESTCall.axiosQuery(formData)
    .then(response => {
      return response && response.data;
    })
    .catch(error => {
      return error;
    });
  return result;
}

export async function handleKeywordSearch(props) {
  const {
    query,
    key = 'description',
    request = 'wildsearch',
    searchVal,
    // resource = "calendar_house_votes"
  } = props
  // const searchVal = searcKeyhRef.current?.value
  // console.log('query', props)
  if (searchVal || query) {
    // calendar_state_house_votes
    // calendar_senate_votes
    // calendar_house_votes
    const federalHouseVotes = await RESTCall.axiosQuery({
      request: [request],
      query: query || {
        [key]: searchVal
      },
      resource: "calendar_house_votes",
      id: ""
    }).then(res => {
      const dups = []
      const data = []
      res?.data?.map((rex) => {
        if (!dups?.includes(rex.bill_id)) {
          dups.push(rex.bill_id)
          data.push(rex)
        }
      })
      // console.log('data', data)
      return data
    })


    const federalSenateVotes = await RESTCall.axiosQuery({
      request: "wildsearch",
      query: query || {
        [key]: searchVal
      },
      resource: "calendar_senate_votes",
      id: ""
    }).then(res => {
      const dups = []
      const data = []
      res?.data?.map((rex) => {
        if (!dups?.includes(rex.bill_id)) {
          dups.push(rex.bill_id)
          data.push(rex)
        }
      })
      return data
    })


    const stateVotes = await RESTCall.axiosQuery({
      request: "wildsearch",
      query: query || {
        [key]: searchVal
      },
      resource: "calendar_state_house_votes",
      id: ""
    }).then(res => {
      const dups = []
      const data = []
      res?.data?.map((rex) => {
        if (!dups?.includes(rex.bill_id)) {
          dups.push(rex.bill_id)
          data.push(rex)
        }
      })
      // console.log('data', data)
      return data
    })

    const contentVotesDesc = await RESTCall.axiosQuery({
      request: "wildsearch",
      query: query || {
        description: searchVal
      },
      resource: "calendar_contents",
      id: ""
    }).then(res => {
      const dups = []
      const data = []
      res?.data?.map((rex) => {
        if (!dups?.includes(rex.bill_id)) {
          dups.push(rex.bill_id)
          data.push(rex)
        }
      })
      return data
    })


    const contentVotesTitle = await RESTCall.axiosQuery({
      request: "wildsearch",
      query: query || {
        title: searchVal
      },
      resource: "calendar_contents",
      id: ""
    }).then(res => {
      const dups = []
      const data = []
      res?.data?.map((rex) => {
        if (!dups?.includes(rex.bill_id)) {
          dups.push(rex.bill_id)
          data.push(rex)
        }
      })
      return data
    })

    const contentFeeds = [
      ...contentVotesDesc,
      ...contentVotesTitle
    ]

    const contentVotes = contentFeeds?.map((rep, i) => {
      if (!rep?.item_id) {
        rep['item_id'] = rep.bill_id
      }
      if (!rep?.bill_id) {
        rep['bill_id'] = rep.item_id
      }
      return {
        ...rep,
        author: rep.bill_id,
        // item_id: rep.bill_id,
        name: rep.fullName,
        channel: rep.channelInfo,
        picture: rep.picture,
        description: rep.title,
        hashtags: 'hashtags',
        createdAt: moment(rep.createdAt).fromNow() || moment().fromNow(),
        updateAt: moment(rep.date).fromNow() || moment().fromNow(),
        likes: 10,
        totalComments: 123,
        video: rep.video,
        videoInfo: rep.videoInfo,
        source: 'allFeeds',
        sourceCaller: 'getContent',
        handle: rep.handle
      }
    })
    // })

    // console.log('xxxxx',
    //   federalHouseVotes,
    //   federalSenateVotes,
    //   stateVotes,
    //   contentVotes
    // )
    const feedsData = [
      ...(federalHouseVotes && Array.isArray(federalHouseVotes) ? federalHouseVotes : []),
      ...(federalSenateVotes && Array.isArray(federalSenateVotes) ? federalSenateVotes : []),
      ...(stateVotes && Array.isArray(stateVotes) ? stateVotes : []),
      ...(contentVotes && Array.isArray(contentVotes) ? contentVotes : [])
    ]
    // }).then((feeds) => {
    // console.log('feeds', feeds)
    const feeds = feedsData?.map((rep, i) => {
      if (!rep?.item_id) {
        rep['item_id'] = rep.bill_id
      }
      if (!rep?.bill_id) {
        rep['bill_id'] = rep.item_id
      }
      return {
        ...rep,
        _id: i,
        author: rep.bill_id,
        // item_id: rep.bill_id,
        handle: '@vareadmin',
        name: rep.chamber,
        channel: rep.channelInfo,
        member_id: rep.bioguide,
        // picture: rep.picture,
        description: rep.description,
        hashtags: 'hashtags',
        createdAt: moment(rep.createdAt).fromNow() || moment().fromNow(),
        updateAt: moment(rep.date).fromNow() || moment().fromNow(),
        likes: 10,
        totalComments: 123,
        video: rep.video,
        videoInfo: rep.videoInfo,
        source: 'allFeeds',
        sourceCaller: 'getContent',
        // handle: rep.handle
      }
    })
    if (feeds) {
      return feeds
    } else {
      return false
    }
  }
}