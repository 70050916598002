import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getAgreement, getDataDB, saveDataDB } from "src/api";
import { useParams } from "react-router-dom";
import { UserContext } from "src/App";
import { ButtonL } from "../AppIndex";
import {
  AddSkillSection,
  EditSkillsModalWrapper,
  InputAgenda,
  SkillChildWrapper,
} from "./TabListItems";
import { Add, HighlightOff } from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";

// const faqs = [
//   {
//     question: "Parties Involved",
//     description:
//       "Identify the parties entering into the joint venture, including their legal names, addresses, and roles in the venture.",
//     fields: ["partyA", "partyB", "cartyC"],
//   },
//   {
//     question: "Purpose of the Joint Venture",
//     description:
//       "Clearly state the purpose, objectives, and goals of the joint venture. This could include specific projects, business activities, or ventures the parties will undertake together.",
//     fields: ["purpose"],
//   },
//   {
//     question: "Contribution of Parties",
//     description:
//       "Specify each party's contributions to the joint venture, such as financial investments, assets, intellectual property, expertise, resources, or services.",
//     fields: ["contribution"],
//   },
//   {
//     question: "Management and Decision-Making",
//     description:
//       "Define how the joint venture will be managed, including decision-making processes, responsibilities of each party, voting rights, and the appointment of key personnel or managers.",
//     fields: [
//       {
//         management: "DecisionMaking",
//       },
//     ],
//   },
//   {
//     question: "Financial Arrangements:",
//     description:
//       "Outline how profits, losses, expenses, and liabilities will be shared among the parties. Include details about capital contributions, distributions, accounting procedures, and financial reporting.",
//     fields: ["agreement"],
//   },
//   {
//     question: "Duration and Termination",
//     description:
//       "Specify the duration of the joint venture, including start and end dates or conditions for termination. Outline procedures for dissolution, exit strategies, and handling of assets or liabilities upon termination.",
//     fields: ["duration", "termination"],
//   },
//   {
//     question: "Confidentiality and Non-Disclosure",
//     description:
//       "Include provisions to protect confidential information, trade secrets, intellectual property, and proprietary data shared or generated during the joint venture.",
//   },
//   {
//     question: "Dispute Resolution",
//     description:
//       "Define mechanisms for resolving disputes or conflicts that may arise during the course of the joint venture, such as mediation, arbitration, or litigation.",
//   },
//   {
//     question: "Governing Law",
//     description:
//       "Determine the jurisdiction and applicable laws governing the joint venture contract, including any international or cross-border considerations.",
//   },
//   {
//     question: "Miscellaneous Provisions",
//     description:
//       "Include clauses related to indemnification, insurance, compliance with laws and regulations, amendments to the contract, force majeure events, and other relevant terms.",
//   },
// ];

export const Agreements = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, type } = props;
  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [faqs, setFaqs] = useState<any>([])

  const { myInfo, host, eventId, hostTabs, allAccess } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const resource = useMemo(() => {
    const tab = eventTab || hostTab;
    const subTab = eventSubTab || hostSubTab;
    return `calendar_${params.eventId ? "event" : "host"}${tab ? "_" + tab : ""
      }${subTab ? "_" + subTab : ""}`;
  }, [eventTab, eventSubTab, hostSubTab, hostTab, params?.eventTab]);

  useEffect(() => {
    if (!eventId) {
      return;
    }
    const data = {
      query: {
        eventId: eventId,
      },
      resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
      check: ["handle"],
    };
    getDataDB(data).then((res: any) => {
      console.log("hostTabsxxccnnttt", res);
      if (res?.[0]?.faq) {
        setDataRecord([
          ...res?.[0]?.faq,
          // ...dataRecord
        ]);
      }
    });
  }, [eventId, refreshing]);

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [eventForms, setEventForms] = useState<any>(["JV Agreement", "MOU"]);
  const [formTitle, setFormTitle] = useState<any>("JV Agreement");
  const [parentFormFields, setParentFormFields] = useState<any>({});


  useEffect(() => {
    getAgreement({ "agreementType": formTitle }).then((response) => {
      console.log(response, 'myresp')
      setFaqs(response)
    })
  }, [formTitle])

  return (
    <>
      {/* {editInfo && (
        <>
          <EditSkillsModalWrapper>
            <AddSkillSection>
              <h2>Add Faq</h2>
              <SkillChildWrapper>
                <InputAgenda onSubmit={(e: any) => { }}>
                  <input
                    type={"text"}
                    onChange={(e: any) =>
                      setFormInput({
                        ...formInput,
                        question: e.target?.value,
                      })
                    }
                    value={formInput?.question || ""}
                    placeholder={"Add question ..."}
                    required
                  />
                </InputAgenda>
              </SkillChildWrapper>
              <SkillChildWrapper>
                <InputAgenda onSubmit={(e: any) => { }}>
                  <input
                    type={"text"}
                    onChange={(e: any) =>
                      setFormInput({
                        ...formInput,
                        description: e.target?.value,
                      })
                    }
                    value={formInput?.answer || ""}
                    placeholder={"Add answer ..."}
                    required
                  />
                </InputAgenda>
              </SkillChildWrapper>
            </AddSkillSection>

            <ButtonL
              disabled={
                !(formInput?.question && formInput?.answer)
                  ? // addType?.file ||
                  // addType?.link ||
                  // formInput?.file ||
                  // formInput?.link
                  true
                  : false
              }
              style={{ margin: 0 }}
              onClick={() => {
                if (!myInfo?.handle) {
                  alert("Please login");
                  return;
                }
                if (!(formInput?.question && formInput?.answer)) {
                  alert("Quest and Answer required");
                  return;
                }
                const data = {
                  query: {
                    faq: [
                      {
                        question: formInput?.question,
                        description: formInput?.answer,
                      },
                      ...dataRecord?.filter(
                        (ref: any) => ref.question != formInput?.question
                      ),
                    ],
                    handle: myInfo?.handle,
                    eventId: eventId,
                  },
                  resource: resource,
                  check: ["handle", "eventId"],
                  myInfo,
                };
                // console.log('formData', data)
                saveDataDB(data).then((res) => {
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      editInfo: false,
                    };
                  });
                });
              }}
            >
              Add
            </ButtonL>
          </EditSkillsModalWrapper>
        </>
      )} */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          width: "100%",
        }}
      >
        {eventForms?.map((title: any, index: number) => {
          return (
            <ButtonL
              style={{
                backgroundColor: formTitle == title ? "red" : "",
              }}
              onClick={() => {
                setFormTitle(
                  parentFormFields == eventForms[title] ? "" : title
                );
                setParentFormFields(
                  parentFormFields == eventForms[title] ? {} : eventForms[title]
                );
                setRefreshing(false);
                setFormInput({});
              }}
            >
              {title}
            </ButtonL>
          );
        })}
      </div>
      <FAQContainer>
        {(dataRecord?.[0] ? dataRecord : faqs)?.map(
          (faq: any, index: number) => (
            <FAQItem key={index}>
              <Question onClick={() => toggleFAQ(index)}>
                <div>{faq.question}</div>
                <div>
                  {" "}
                  {openIndex === index ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </div>
              </Question>
              <Answer isOpen={openIndex === index}>
                {/* {faq.answer} */}
                <Col>
                  <Row className="media d-flex mb-4">
                    <Col>
                      <img
                        alt=""
                        className="main-img-user br-5"
                        src={
                          "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                        }
                        style={{
                          width: 400,
                          height: "auto",
                          borderRadius: 10,
                        }}
                      />
                    </Col>
                    <Col>
                      <div className="media-body">
                        <span className="d-block">{faq?.fullName}</span>
                        <p className="mb-0 font-weight-semibold">
                          {faq?.description || "NA"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {faq?.fields &&
                      faq?.fields?.map((fieldInfo: any) => {
                        const field =
                          fieldInfo && typeof fieldInfo == "object"
                            ? Object.keys(fieldInfo)
                            : fieldInfo;
                        const sideFied =
                          fieldInfo &&
                          typeof fieldInfo == "object" &&
                          fieldInfo[field];
                        return (
                          <>
                            {editInfo ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <InputAgenda
                                  onSubmit={(e: any) => { }}
                                  style={{
                                    margin: 3,
                                  }}
                                >
                                  <input
                                    type={"text"}
                                    onChange={(e: any) =>
                                      setFormInput({
                                        ...formInput,
                                        [field]: e.target?.value,
                                      })
                                    }
                                    value={formInput?.[field] || ""}
                                    placeholder={`Add ${field}`}
                                    required
                                  />
                                  {/* <Add /> */}
                                </InputAgenda>
                                {sideFied && (
                                  <InputAgenda
                                    style={{
                                      margin: 3,
                                    }}
                                  >
                                    <input
                                      type={"text"}
                                      onChange={(e: any) =>
                                        setFormInput({
                                          ...formInput,
                                          [sideFied]: e.target?.value,
                                        })
                                      }
                                      value={formInput?.[sideFied] || ""}
                                      placeholder={`Add ${sideFied}`}
                                      required
                                    />
                                    {/* <Add /> */}
                                  </InputAgenda>
                                )}
                                <Add onClick={() => { }} />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  margin: 3,
                                  flexDirection: "row",
                                }}
                              >
                                <div>
                                  {" "}
                                  {field}
                                  {sideFied ? ":" : ""}
                                </div>
                                {sideFied && (
                                  <div
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    {" "}
                                    {sideFied}
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        );
                      })}
                  </Row>
                </Col>
              </Answer>
            </FAQItem>
          )
        )}
      </FAQContainer>

      {/* Need to generate the pdf from the responses  */}
    </>
  );
};

const FAQContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const FAQItem = styled.div`
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
`;

const Question = styled.div`
  background: #f7f7f7;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background: #e0e0e0;
  }
`;

const Answer = styled.p<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  overflow: hidden;
  text-align: left;
  margin: 0;
  transition: max-height 0.3s ease;
  padding: ${({ isOpen }) => (isOpen ? "15px" : "0 15px")};
  background: #fff;
`;
