import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../App";
// import FFmpegEditor from "./FFmpegEditor";
import YouTube from 'react-youtube';
import { Card } from "react-bootstrap";
import { MyFileUploader } from "./MyFileUploader";
import { AddSkillSection } from "./TabListItems";

export const VideoEditor = (props) => {
  const { myContext, setMyContext } = useContext(UserContext);
  const { editInfo, resource } = props;
  const params = useParams();
  const { myInfo, host, hostTabs, eventInfo, allAccess } = myContext;

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const [formInput, setFormInput] = useState({});

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  };

  const opts = {
    height: '200',
    width: '200',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };


  return (
    <div >
      <header >
        <h1>Video and Image Editor</h1>
      </header>
      {/* <main style={{
        display: 'flex',
        flexDirection: 'row'
      }}>
   
        <FFmpegEditor />

      </main> */}
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 20
      }}>
        <Card style={{
          width: 250,
          height: 250,
        }}>
          <div role={'text'}>Add Image (optional)</div>
          <MyFileUploader
            {...props}
            allowAdd={false}
            defaultImage={formInput?.img}
            refreshing={false}
            setRefreshing={() => { }}
            setFormInput={setFormInput}
            formInput={formInput}
            uploadNow={() => { }}
            handleSaveImage={(e) => {
              // setUploadNow(true);
            }}
          />
        </Card>
        <Card style={{
          width: 250,
          height: 250,
        }}>
          <video
            autoPlay
            loop
            muted
            poster={
              "https://varefiles.s3.us-east-2.amazonaws.com/myguestnet.mp4"
            }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 10,
            }}
          >
            <source
              src={"https://varefiles.s3.us-east-2.amazonaws.com/guestnet.mp4"}
            />
          </video>
        </Card>
        <Card style={{
          width: 300,
          height: 250,
        }}>
          <YouTube
            videoId={'dQw4w9WgXcQ'}
            opts={opts}
            onReady={onPlayerReady}
          />
        </Card>
      </div>
    </div>
  );
};



