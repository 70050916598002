import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { Report } from "@mui/icons-material";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Chart,
} from "chart.js";

// Register Chart.js components
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const dataset = [
  {
    ques: "What is your favorite color?",
    ans: [
      "Red",
      "Blue",
      "Green",
      "Yellow",
      "Black",
      "White",
      "Purple",
      "Orange",
      "Pink",
      "Gray",
      "Red",
      "Blue",
      "Green",
      "Yellow",
      "Black",
      "White",
      "Purple",
      "Orange",
      "Pink",
      "Gray",
    ],
  },
  {
    ques: "What is your favorite movie?",
    ans: [
      "Inception",
      "The Matrix",
      "Titanic",
      "Star Wars",
      "The Godfather",
      "Pulp Fiction",
      "Forrest Gump",
      "Fight Club",
      "Avatar",
      "The Dark Knight",
      "Inception",
      "The Matrix",
      "Titanic",
      "Star Wars",
      "The Godfather",
      "Pulp Fiction",
      "Forrest Gump",
      "Fight Club",
      "Avatar",
      "The Dark Knight",
    ],
  },
  {
    ques: "How many countries have you visited?",
    ans: [5, 10, 15, 2, 3, 8, 12, 7, 6, 20, 5, 10, 15, 2, 3, 8, 12, 7, 6, 20],
  },
  {
    ques: "How many siblings do you have?",
    ans: [1, 2, 0, 3, 4, 1, 2, 1, 0, 5, 1, 2, 0, 3, 4, 1, 2, 1, 0, 5],
  },
  {
    ques: "Do you work for the army?",
    ans: [
      false,
      true,
      true,
      false,
      true,
      false,
      true,
      false,
      true,
      true,
      false,
      true,
      true,
      false,
      true,
      false,
      true,
      false,
      true,
      true,
    ],
  },
];

interface DataProps {
  ques: string;
  ans: (string | number | boolean)[];
}

const FormReportTable: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<number[]>([]);
  const [modalTitle, setModalTitle] = useState("");

  const handleOpen = (data: number[], title: string) => {
    setModalData(data);
    setModalTitle(title);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const renderGraphIcon = (
    ans: (string | number | boolean)[],
    title: string
  ) => {
    const numericData = ans.filter((a) => typeof a === "number") as number[];
    const booleanData = ans.filter((a) => typeof a === "boolean") as boolean[];

    if (numericData.length > 0 || booleanData.length > 0) {
      const dataToGraph =
        numericData.length > 0
          ? numericData
          : booleanData.map((b) => (b ? 1 : 0));
      return (
        <IconButton onClick={() => handleOpen(dataToGraph, title)}>
          <Report />
        </IconButton>
      );
    }
    return null;
  };

  const getChartData = (data: number[]) => ({
    labels: Array.from({ length: data.length }, (_, i) => i + 1),
    datasets: [
      {
        label: modalTitle,
        data,
        fill: false,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No</TableCell>
            {dataset.map((data, index) => (
              <TableCell key={index}>
                {data.ques}{" "}
                {(typeof data.ans[0] === "number" ||
                  typeof data.ans[0] === "boolean") &&
                  renderGraphIcon(data.ans, data.ques)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 20 }, (_, i) => (
            <TableRow key={i}>
              <TableCell>{i + 1}</TableCell>
              {dataset.map((data, index) => (
                <TableCell
                  key={index}
                  onClick={() => console.log(typeof data.ans[i])}
                >
                  {data.ans[i].toString()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StyledModal open={open} onClose={handleClose}>
        <ModalContent>
          <Line data={getChartData(modalData)} />
        </ModalContent>
      </StyledModal>
    </StyledTableContainer>
  );
};

export default FormReportTable;

// Styled components
const StyledTableContainer = styled(TableContainer)({
  margin: "20px auto",
  maxWidth: "100%",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
    overflowX: "auto",
  },
});

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Box)({
  width: "80%",
  maxWidth: "600px",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
});
