import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";

import { FunnelChart } from 'react-funnel-pipeline'
import 'react-funnel-pipeline/dist/index.css'
import Select from "react-select/creatable";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RESTCall from "../restApi";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import DatePicker from "react-multi-date-picker";
import styled from "@emotion/styled";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
  AvatarGroup,
  Avatar,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  capitalizeInfo,
  deleteAgendaDB,
  deleteDataDB,
  deleteLinkDB,
  getAgendaDB,
  getCalendarEvents,
  getDataDB,
  getDataWildDB,
  getLinksDB,
  groupByNow,
  myColors,
  saveAgendaDB,
  saveDataDB,
  saveEventDB,
} from "src/api";
import { UserContext } from "src/App";
import { AgendaFormComponent, InputAgenda } from "./AgendaFormComponent";
import { CommentsFormComponent } from "./CommentsFormComponent.";
import { FileFormComponent } from "./FileFormComponent";
import { ModalBox } from "./ModalBox";
import { TaggingComponent } from "./TaggingComponent";
import { ScheduleFormComponent } from "./ScheduleFormComponent";
import { ParticipantFormComponent } from "./ParticipantFormComponent";
import { SurveyFormComponent } from "./SurveyFormComponent";
import axios from "axios";
import { ListItemsMedia } from "./ListItemsMedia";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddSkillSection,
  EditSkillsModalWrapper,
  SkillChildWrapper,
  TabListItems,
} from "./MediaSlider/TabListItems";
import {
  AddCircleOutline,
  ControlPoint,
  EditNote,
  ExpandLess,
  ExpandMore,
  ModeEdit,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { SubTabListItems } from "./MediaSlider/SubTabListItems";
import { EventFormModal } from "./EventFormModal";
import { DetailModal, ModalIntro } from "./MediaSlider";
import { FormTypeItem } from "./FormTypeItem";
import { ButtonL } from "./AppIndex";
import { FormTypeCreate } from "./FormTypeCreate";
import { AddNewContent } from "./MediaSlider/CatalogListings";
import { TaskAvatars } from "./MediaSlider/KanbanTask";
const _ = require("lodash");


export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const HostFunnel = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    agendas,
    allMyEvents,
    // eventId,
    data = {},
    host,
    // editInfo,
    eventDetailsTabs = [],
    eventInfo = {},
    editInfo,
    funnelGroup = {},
    allAccess
  } = myContext;
  const navigate = useNavigate();

  const { selectedEvent,
    resource
  } = props;
  const params: any = useParams<any>();
  // const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [myGroupedFunnel, setMyGroupedFunnel] = useState<any>([]);
  // const [dataRecord, setDataRecord] = useState<any>([]);


  const [formInput, setFormInput] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [funnelData, setFunnelData] = useState<any>([
    { name: 'Awareness', value: 0 },
    { name: 'Interest', value: 0 },
    { name: 'Consideration', value: 0 },
    { name: 'Evaluation', value: 0 },
    { name: 'Commitment', value: 0 },
    { name: 'Sale', value: 0 }
  ]);



  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);
  // console.log("eventDetailsTab", params);

  const valuetext = (value: number) => {
    return `${value}°C`;
  };

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  console.log("eventDetailsTabhh", eventInfo);
  const [category, setCategory] = useState<any>()
  const [allDataRecord, setAllDataRecord] = useState<any>([])
  const [dataTopic, setDataTopic] = useState<any>([])
  const [options, setOptions] = useState<any>([])


  useEffect(() => {

    if (myInfo?.handle) {
      getDataDB({
        query: {
          handle: myInfo?.handle,
        },
        resource: 'calendar_event_funnel',
      }).then((res) => {
        const groupTopic = groupByNow({
          data: res,
          key: "topic",
          id: 2,
        });
        // console.log('vvvvrr', res, groupTopic)
        if (groupTopic && Object.keys(groupTopic)?.[0]) {

          // alert(3)
          setDataTopic(Object.keys(groupTopic))
        }
      })
    }


  }, [
    myInfo?.handle,
    refreshing
  ]);


  useEffect(() => {
    (async () => {

      if (myInfo?.handle && formInput?.topic) {
        // alert(formInput?.topic + resource)


        const funnelTemplate = await getDataDB({
          query: {
            handle: myInfo?.handle,
            topic: formInput?.topic
          },
          resource: 'calendar_event_funnel',
        }).then((res) => {
          return res?.[0]
        })

        //get users associated with funnel
        const funnelLeads = await getDataDB({
          query: {
            handle: myInfo?.handle,
            topic: formInput?.topic
          },
          resource: 'calendar_funnel_leads',
        }).then((res) => {
          return res
        });

        const groupUserFunnel = groupByNow({
          data: funnelLeads,
          key: "funnel",
          id: 2,
        });

        setMyGroupedFunnel(groupUserFunnel)

        // const groupTopic = groupByNow({
        //   data: funnelTemplate,
        //   key: "topic",
        //   id: 2,
        // });

        const result = funnelTemplate?.funnel && funnelTemplate?.funnel?.map((res: any) => {
          return {
            ...res,
            value: groupUserFunnel && groupUserFunnel[res?.name] ? groupUserFunnel[res?.name]?.length : 0
          }
        })

        console.log("funnelTemplate", funnelLeads, funnelTemplate);
        // console.log("funnelLeads", funnelLeads);
        // console.log("result", result);

        console.log("myLike", result);
        // setFunnel(groupUtil)
        // if (result?.[0]) {
        setFormInput({
          ...formInput,
          ...(funnelTemplate || {}),
          funnel: result
        });
        setRefreshing(false)

        // }
        // else {
        //   setFormInput({
        //     ...formInput,
        //     funnel: funnelData
        //   })
        //   setRefreshing(false)
        // }
      }
    })()
  }, [
    refreshing,
    myInfo?.handle,
    formInput?.topic,
  ]);


  useEffect(
    _.debounce(() => {
      if (!(formInput?.guest?.length > 0)) {
        return;
      }
      const data = {
        key: "name",
        request: "wildsearch",
        searchVal: formInput?.guest,
        resource: `calendar_users`,
        limit: 400,
      };
      // console.log('formFieldxx44', formInput?.title)

      getDataWildDB(data).then((res: any) => {
        // console.log('formFieldxx44', res)
        if (res?.[0]) {
          const result = res
            ?.map((person: any) => {
              return {
                // ...person,
                // active: true,
                // link: `https://www.myguestnet.com/host/${person?.handle}`,
                // img:
                //   person.img ||
                //   "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
                // title: person.name,
                // name: person.name,
                label: person.name,
                value: person?.email,
                // handle: person?.handle,
              };
            })
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle);
          setOptions([...options, ...result]);
        }
      });
    }, 500),
    [formInput?.guest]
  );
  console.log('xxformInput', formInput)

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 5
      }}>
        <ButtonL
          style={{
            backgroundColor: !formInput?.topic ? myColors?.brown : myColors?.green,
            marginBottom: 20
          }}
          onClick={() => {
            // setDataRecord(allDataRecord)
            setFormInput({
              // ...formInput,
              topic: ''
            })
          }}>
          All
        </ButtonL>
        {dataTopic?.[0] && dataTopic?.map((topic: any, index: number) => {
          // const rep = allDataRecord?.filter((ref: any) => {
          //   return ref?.title == res?.title
          // })
          // console.log('vvvvdee', res)
          return <ButtonL
            style={{
              whiteSpace: 'none',
              backgroundColor: formInput?.topic == topic ? myColors?.brown : myColors?.green
            }}
          >
            <div onClick={() => {
              // setFormInput(res)
              // setRecordKey(res?.title)
              // const data = [...allDataRecord]?.filter(reh => reh.topic == topic)
              // setDataRecord(data)
              setFormInput({
                ...formInput,
                topic: topic
              })
            }}> {(!topic || topic == 'undefined') ? 'Default' : topic}</div>
            {allAccess && topic && <RemoveCircleOutline
              style={{
                marginLeft: 10
              }}
              onClick={() => {
                let text = "Sure you want to delete?";
                // const item = allDataRecord?.filter((reh: any) => reh.topic == topic)?.[0]

                if (!formInput?._id) {
                  return
                }

                if (window.confirm(text) == true) {
                  deleteDataDB({
                    id: formInput?._id,
                    resource: resource
                  }).then((res: any) => {
                    // setFormInput({})
                    // setDataTopic([])
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });
                    setRefreshing(true)
                  })
                  text = "You pressed OK!";
                } else {
                  text = "You canceled!";
                }
              }} />}
          </ButtonL>
        })}
      </div>
      {editInfo && (
        <>
          <EditSkillsModalWrapper id={'formInput'}>
            <AddSkillSection>
              <h2>Create or Select Topic <span style={{ color: 'red' }}>(Required)</span></h2>
              <SkillChildWrapper>

                <Select
                  styles={{
                    control: styles => ({ ...styles, width: 400 }),
                  }}
                  // onInputChange={(e: any) => {
                  //   console.log('personinfoz', e)
                  //   setFormInput({
                  //     ...formInput,
                  //     topic: e?.value,
                  //   })
                  //   // setSelectedTopic(e)
                  // }}
                  defaultValue={formInput?.topic
                    ? [
                      {
                        label: formInput?.topic,
                        value: formInput?.topic,
                      }
                    ]
                    : [{
                      label: 'Default',
                      value: 'Default'
                    }]}
                  onChange={(e: any) => {
                    // console.log('personinfo2', e)
                    // setSelectedTopic(e);
                    if (e?.value)
                      setFormInput({
                        ...formInput,
                        topic: e.value,
                      })
                  }}
                  isClearable
                  options={(dataTopic && dataTopic?.[0] != 'undefined')
                    ? dataTopic?.map((res: any) => {
                      return {
                        label: res,
                        value: res
                      }
                    })
                    : []}
                  placeholder="Select or Add"
                  classNamePrefix="selectform"
                />
              </SkillChildWrapper>
            </AddSkillSection>
            {/* <AddSkillSection>
              <h2>Add Title</h2>
              <SkillChildWrapper>
                <InputAgenda onSubmit={(e: any) => { }}>
                  <input
                    type={"text"}
                    onChange={(e: any) =>
                      setFormInput({
                        ...formInput,
                        title: e.target?.value,
                      })
                    }
                    value={formInput?.title || ""}
                    placeholder={"Add title ..."}
                    required
                  />
                </InputAgenda>
              </SkillChildWrapper>
            </AddSkillSection> */}
            {formInput?.topic && (<>
              <AddSkillSection>
                <h2>Add Description / Details (Optional)</h2>
                <SkillChildWrapper>
                  <InputAgenda onSubmit={(e: any) => { }}>
                    <input
                      type={"text"}
                      onChange={(e: any) =>
                        setFormInput({
                          ...formInput,
                          description: e.target?.value,
                        })
                      }
                      value={formInput?.description || ""}
                      placeholder={"Add title ..."}
                      required
                    />
                  </InputAgenda>
                </SkillChildWrapper>
              </AddSkillSection>
              <AddSkillSection>
                <h2>Add Viewers (optional)</h2>
                <Select
                  styles={{
                    control: styles => ({ ...styles, width: 400 }),
                  }}
                  onInputChange={(e: any) => {
                    // console.log('personinfobb', e)
                    // setInputField(e)
                    setFormInput({
                      ...formInput,
                      guest: e?.value
                    })
                  }}
                  defaultValue={formInput?.guest
                    ? [
                      {
                        label: formInput?.guest,
                        value: formInput?.guest,
                      }
                    ]
                    : [{
                      label: 'Default',
                      value: 'Default'
                    }]}

                  onChange={(e: any) => {
                    // console.log('personinfo', e)
                    // setSelected(e);
                    setFormInput({
                      ...formInput,
                      guest: e?.value
                    })
                  }}
                  isMulti
                  isClearable
                  options={options}
                  placeholder="Select or Invite by Email"
                  classNamePrefix="selectform"
                />
              </AddSkillSection>
            </>)}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {formInput?._id && <ButtonL
                disabled={
                  formInput?.title
                    ? // addType?.file ||
                    // addType?.link ||
                    // formInput?.file ||
                    // formInput?.link
                    false
                    : true
                }
                style={{ margin: 0, marginRight: 20 }}
                onClick={() => {
                  if (!myInfo?.handle) {
                    alert("Please login");
                    return;
                  }

                  let text = "Are you sure you wand to delete";
                  if (window.confirm(text) == true) {
                    text = "You pressed OK!";
                    const data = {
                      id: formInput?._id,
                      resource: resource,
                    };
                    deleteDataDB(data).then((res) => {
                      setFormInput({})
                      setRefreshing(true);
                    });
                  } else {
                    text = "You canceled!";
                    return
                  }


                  // console.log('formData', data)

                }}
              >
                Delete
              </ButtonL>}
              <ButtonL
                disabled={
                  (formInput?.topic)
                    ? false
                    : true
                }
                style={{ margin: 0 }}
                onClick={() => {
                  if (!formInput?.topic) {
                    alert('Please make sure to add a Topic')
                    return
                  }
                  if (!myInfo?.handle) {
                    alert("Please login");
                    return;
                  }
                  // if (!formInput?.topic) {
                  //   alert("Topic required");
                  //   return;
                  // }
                  // console.log('formDatasssxx', formInput)

                  // return
                  const data = {
                    query: {
                      ...formInput,
                      funnel: formInput?.funnel || funnelData,
                      handle: myInfo?.handle,
                      eventId: eventId,
                    },
                    resource: resource,
                    check: ["handle", "topic"],
                    myInfo,
                  };
                  console.log('foformDatasssrmData', data)
                  saveDataDB(data).then((res) => {
                    setRefreshing(true);
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });

                  });
                }}
              >
                Save
              </ButtonL>
            </div>
          </EditSkillsModalWrapper>
        </>
      )}


      {formInput?.topic && <FunnelChart
        data={formInput?.funnel}
        onRowClick={(e: any) => {
          console.log(e)
          setCategory(e?.name)
        }}
      />}
      {category &&
        <Modal
          show={category}
          onHide={() => {
            setCategory('')
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>MyGuestNet</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`${capitalizeInfo(category)} Leads`}</Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              width: "80% !important",
              // justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
            <div>
              <TaskAvatars>
                <AvatarGroup max={3}>
                  {myGroupedFunnel?.[category] &&
                    myGroupedFunnel[category].map((info: any, i: number) => {
                      const avatar = info?.guest
                      return <div
                        onClick={() => {
                          if (avatar?.location)
                            navigate(`${avatar?.location}`);

                        }}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: 3,
                          margin: 10,
                          borderRadius: 5,
                          backgroundColor: 'rgba(0,0,0,0.2)'
                        }}>
                        <Avatar
                          style={{
                            alignSelf: 'center'
                          }}
                          src={avatar?.img
                            || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'}
                          key={i}
                        />
                        <div style={{
                          fontSize: '0.4em'
                        }}>
                          {avatar?.name}
                        </div>
                      </div>
                    })}
                </AvatarGroup>
              </TaskAvatars>

            </div>
          </Modal.Footer>
        </Modal>}

    </>
  );
};

const EditSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: -40px;
`;
const EditSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 100%;
  font-size: 14px;
  background: #479d83;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  p {
    color: #fff;
    margin: 0;
  }
  &:hover {
    background-color: #57b397;
  }
`;

const TabListEvent = styled.div`
  padding-bottom: 10px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;

  flex-wrap: wrap;
`;
const TabEvent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? "#479d83" : "rgba(0,0,0,0.05)")};
  cursor: pointer;
  p,
  i {
    margin: 0;
    font-size: 12px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
  transition: background 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
  &:hover {
    p,
    i {
      color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
`;

const EventFormWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  gap: 10px;
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const LableSameAsLable = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  input {
    max-width: 20px;
    width: 100%;
  }
`;
const LableSameAsLableWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
`;
