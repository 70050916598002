import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
  useLayoutEffect
} from "react";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Modal, Row, } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import DatePicker from "react-multi-date-picker";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from './styles';
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon
} from "@mui/material";
import { daysOfGivenWeek, getDayOfWeek, getDaysInMonth, weekDays } from "src/helpers/dateFunctions";
import { deleteCommentsDB, getLinksDB, getCalendarEvents, saveEventDB, saveLinkDB, scrapLink, myColors } from "src/api";
import { UserContext } from "src/App";
import { useParams } from "react-router-dom";

export const MenuContext: any = createContext({});

export const colors = {
  lightBlue: '#c1d8ff',
  brown: '#ba5d2c'
}


export const MyLinks = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = '2' },
    host,
    refEvent,
    links
    // links = [],
  } = myContext;
  const {
    tagType,
    setTagType,
    setOpenEventForm
    // formInput,
    // setFormInput
  } = props

  const params: any = useParams<any>();


  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);
  const [editLink, setEditLink] = useState<any>('')
  const [formInput, setFormInput] = useState<any>({})
  const [openTag, setOpenTag] = useState<any>('')
  // const [links, setLinks] = useState<any>([])

  const [showMenu, setShowMenu] = useState<boolean>(false)

  const [refreshing, setRefreshing] = useState<boolean>(false)


  const [editMode, setEditMode] = useState<boolean>(false)


  // useEffect(() => {
  //   if (eventId) {
  //     getLinksDB({
  //       eventId: eventId,
  //     }).then((res: any) => {
  //       console.log('vvvvv22', res)
  //       setLinks([...(res || [])])
  //     })
  //   } else if (host?.handle) {
  //     getLinksDB({
  //       handle: host?.handle,
  //     }).then((res: any) => {
  //       console.log('vvvvv22', res)
  //       setLinks([...(res || [])])
  //     })
  //   } else if (!host?.handle && myInfo?.handle) {
  //     getLinksDB({
  //       handle: myInfo?.handle,
  //     }).then((res: any) => {
  //       console.log('vvvvv22', res)
  //       setLinks([...(res || [])])
  //     })
  //   }
  // }, [
  //   host?.handle,
  //   myInfo?.handle,
  // ])


  // useLayoutEffect(() => {
  //   handleRefresh()
  // }, [])

  useLayoutEffect(() => {
    if (eventId) {
      getLinksDB({
        eventId: eventId,
      }).then(async (res: any) => {
        const linkData = await Promise.all(res?.map(async (ref: any) => {
          return await scrapLink(ref?.link)?.then((rex: any) => {
            return {
              ...ref,
              picture: rex?.favicon
            }
          })
        }))
        setMyContext((existing: any) => {
          return {
            ...existing,
            links: [...linkData]
          }
        })
        setRefreshing(false)

      })
    } else if (host?.handle) {
      getLinksDB({
        handle: host?.handle,
      }).then(async (res: any) => {
        const linkData = await Promise.all(res?.map(async (ref: any) => {
          return await scrapLink(ref?.link)?.then((rex: any) => {
            return {
              ...ref,
              picture: rex?.favicon
            }
          })
        }))
        setMyContext((existing: any) => {
          return {
            ...existing,
            links: [...linkData]
          }
        })
        setRefreshing(false)

      })
    } else if (!host?.handle && myInfo?.handle) {
      getLinksDB({
        handle: myInfo?.handle,
      }).then(async (res: any) => {
        const linkData = await Promise.all(res?.map(async (ref: any) => {
          return await scrapLink(ref?.link)?.then((rex: any) => {
            return {
              ...ref,
              picture: rex?.favicon
            }
          })
        }))
        setMyContext((existing: any) => {
          return {
            ...existing,
            links: [...linkData]
          }
        })
        setRefreshing(false)
      })
    }
  }, [
    refreshing,
    eventId,
    host?.handle,
    myInfo?.handle,
  ])

  const handleMyAgendaData = (info: any) => {


    if (!(myInfo?.handle && info?.link && info?.title)) {
      alert('Please make sure you complete all fields')
      return
    }

    const dataProps = {
      ...info,
      'handle': myInfo?.handle,
      'name': myInfo?.name,
      eventId: eventId
    }
    delete dataProps?._id
    saveLinkDB(dataProps).then((rep: any) => {
      alert('Saved')
      setRefreshing(true)
    })
  }

  useEffect(() => {

    if (editMode) {
      setMyContext((existing: any) => {
        return {
          ...existing,
          host: {},
          myEventSchedule: [],
          myEvents: [],
          eventId: ''
        }
      })
    }
    // !(host?.handle && eventId) && myInfo?.handle


  }, [
    editMode
  ])



  const dragEnded = (param: any) => {
    const { source, destination } = param;
    let _arr = [...links];
    //extracting the source item from the list
    const _item = _arr.splice(source.index, 1)[0];
    //inserting it at the destination index.
    _arr.splice(destination.index, 0, _item);
    // setLinks(_arr);
  };

  // useEffect(() => {
  //   console.log('loaderMyData', refEvent?.current.style)

  //   refEvent.current.style.borderStyle = 'solid'
  //   refEvent.current.style.borderColor = myColors.red
  // }, [
  //   refEvent.current
  // ])


  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <div
      onTouchEnd={() => {
        setShowMenu(false)

      }}
      onMouseLeave={() => {
        setShowMenu(false)

      }}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>

      <div
        style={{
          position: 'relative',
          zIndex: 33,
          marginLeft: 20,
          display: 'flex',
          // backgroundColor: myColors.brown,
          height: 40,
          width: 40,
          borderRadius: 100,
          alignItems: 'center',
          justifyContent: 'center',
          color: myColors.red
        }}>
        <nav
          // onTouchEnd={() => {
          //   setShowMenu(false)

          // }}
          // onMouseLeave={() => {
          //   setShowMenu(false)

          // }}
          className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <div
                  className={`nav-link dropdown-toggle ${showMenu && 'show'}`}
                  onClick={() => {
                    setShowMenu(!showMenu)
                  }}
                  // href="#" 
                  id="navbarDropdownMenuLink" role="button"
                  data-mdb-toggle="dropdown" aria-expanded="false">
                  Add Links by Event or to your profile
                </div>
                <ul className={`dropdown-menu ${showMenu && 'show'}`} aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <div className="dropdown-item"
                      onClick={() => {
                        setOpenEventForm(true)
                      }}
                    >Add Links by Event</div>
                  </li>
                  <li>
                    <div className="dropdown-item"
                      onClick={() => {
                        setEditMode(!editMode)
                        setShowMenu(!showMenu)

                      }}
                    >Add Link to my page</div>
                  </li>

                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div >
    </div >


    <div style={{
      marginTop: 20
    }}>
      {(editMode || editLink?._id)
        ? <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            flexDirection: 'column',
            width: 300,
            padding: 30
          }}>
            <div>

            </div>
            <div style={{
              position: 'relative',
              zIndex: 3
            }}>
              <input
                // className="form-control"
                // {...(editLink?.link ? { value: editLink?.link } : {})}
                type="text"

                onChange={(e: any) => {
                  setFormInput({
                    ...formInput,
                    link: e.target?.value
                  })
                }}
                style={{
                  margin: 10,
                  width: 300,
                  alignSelf: 'center',
                  borderBottomWidth: 0.5,
                  borderBottomStyle: 'solid',
                  borderBottomColor: 'gray',
                  color: 'black'

                  // backgroundColor: 'gray'
                }}
                value={editLink?.link || "Add link url here"}
                placeholder={"Add link url here"}
              />
              <input
                // className="form-control"
                type="text"
                // {...(editLink?.title ? { placeholder: editLink?.title } : {})}
                onChange={(e: any) => {
                  setFormInput({
                    ...formInput,
                    title: e.target?.value
                  })
                }}
                style={{
                  margin: 10,
                  width: 300,
                  alignSelf: 'center',
                  borderBottomWidth: 0.5,
                  borderBottomStyle: 'solid',
                  borderBottomColor: 'gray',
                  color: 'black'

                  // backgroundColor: 'gray'
                }}
                value={editLink?.title || ''}
                placeholder={"Add Link Title"}
              />
            </div>
          </div>

          <div style={{
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <div
              onClick={(e: any) => {
                console.log('ssss', e)
                if (!(formInput?.title?.length > 0)) {
                  alert('Please complete all fields')
                  return
                }
                handleMyAgendaData({
                  link: formInput?.link,
                  title: formInput?.title,
                })

              }}
              className="title"
              style={{
                alignSelf: 'center',
                display: 'flex',
                height: 40,
                width: 250,
                color: 'white',
                backgroundColor: myColors.red,
                borderRadius: 10,
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {'Save Link'}
            </div>
            <div
              onClick={() => {
                setEditMode(false)
                setEditLink({})
              }}
              style={{
                alignSelf: 'center',
                display: 'flex',
                height: 40,
                width: 250,
                color: 'white',
                backgroundColor: myColors.gray,
                borderRadius: 10,
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {'Cancel'}

            </div>
          </div>
        </div> : <div
          style={{
            display: 'flex',
            position: 'relative',
            zIndex: 33,
            flexDirection: 'row'
          }}
          className="title" onClick={() => {
            setMyContext((existing: any) => {
              return {
                ...existing,
                action: 'signin',
              }
            })
          }}>
          {/* <div>Please</div>
          <div style={{ color: myColors.red, marginLeft: 5, marginRight: 5 }}>{` login `}</div>
          <div>to continue</div> */}
        </div>}
    </div>


    {links?.length > 0
      ? links?.map((link: any, index: number) => {
        return <>
          <Card
            key={link._id + index + 'sdjsjjjsjdhs'}
            className={`comment-${link._id}`}
            style={{
              marginTop: 20,
              flexDirection: 'column',
              justifyContent: 'center',
              width: 350,
              height: 50,
              // borderRadius: 25,
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              backgroundColor: 'rgba(0,0,0,0.02)'
            }}>
            <div style={{
              position: 'absolute',
              left: 0
            }}>
              <a href={`https://${link?.link}`}>
                <img
                  src={link?.picture || link?.img || 'https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg'}
                  style={{
                    padding: 4,
                    justifyContent: 'center',
                    width: 50,
                    height: 50,
                    borderRadius: 100,
                  }}
                />
              </a>
            </div>
            <a href={`https://${link?.link}`}>
              <div
                style={{
                  paddingLeft: 20,
                  color: colors.brown
                }}>
                {link?.title}
              </div>
            </a>

          </Card>
          {(myInfo?.handle == link?.handle) && <div style={{
            marginTop: -4,
            width: 350,
            // right: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.lightBlue,
            borderBottomLeftRadius: 25,
            borderBottomRightRadius: 25,

          }}>
            <div
              onClick={() => {
                setEditLink({
                  ...link
                })
              }}
              style={{
                margin: 10,
                color: myColors.brown,
                // position: 'absolute',
                // right: 0
              }}>
              {'Edit'}
            </div>
            <div
              onClick={() => {
                // setLinks([
                //   ...links?.filter((ref: any) => ref._id != agenda?._id)
                // ])
                deleteCommentsDB({
                  id: link?._id
                }).then((rep: any) => {
                  alert('Deleted')
                  getLinksDB({
                    eventId: eventId,
                    handle: myInfo?.handle,
                  }).then((res: any) => {
                    // console.log('vvvvv', res)
                    // handleRefresh()
                    setRefreshing(true)
                  })
                })
              }}
              style={{
                margin: 10,
                color: 'gray',
                // position: 'absolute',
                // right: 0
              }}>
              {'Delete'}
            </div>

          </div>}
        </>
      })
      : null
    }
  </div >
}