import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "../MediaSlider/data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import Select from "react-select";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getEventLikesDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  handleSaveEventLikes,
  jumpToReleventDiv,
  makeUpperCase,
  myColors,
  relatedPict,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";
import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  ArrowBack,
  ArrowForward,
  Attachment,
  AttachMoney,
  Camera,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  EditCalendar,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  Fullscreen,
  FullscreenExit,
  Home,
  InsertDriveFile,
  NewReleases,
  PlusOne,
  RemoveCircleOutline,
  RemoveRedEye,
  Settings,
  Share,
  ShoppingBag,
  ToggleOff,
  ToggleOn,
  Verified,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Rating, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "../MediaSlider/TabListItems";
import { AboutTabListItems } from "../MediaSlider/AboutTabListItems";
import { MyFileUploader } from "../MediaSlider/MyFileUploader";
import { EventSkillsTabList } from "../MediaSlider/EventSkillsTabList";
import HeroBannarImg from "../MediaSlider/HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "../MediaSlider/ItemListInfo";
import { AdvertCarroussel } from "../MediaSlider/AdvertCarroussel";
import { GuestRoleManagerModal } from "../MediaSlider/GuestRoleManagerModal";
import { AdvertListItems } from "../MediaSlider/AdvertListItems";
import { MyTabListItems } from "../MediaSlider/MyTabListItems";
import {
  ButtonFilterTabs,
  EventEditor,
  TabsCardInfo,
} from "../MediaSlider/EventEditor";
import { HostEditor } from "../MediaSlider/HostEditor";
import TabSlider from "./TabSlider";
import { Typewriter } from "react-simple-typewriter";
import { isMobile } from "react-device-detect";
import Edit from "@mui/icons-material/Edit";
import { EventFormModal } from "../EventFormModal";
import StoryTemplate from "../MediaSlider/StoryTemplate";

const _ = require("lodash");

// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export const UserProfileModal = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    openAds = false,
    eventInfo = {},
    openChat = false,
    allAccess,
    myLikes = [],
    editEvent,
  } = myContext;
  const { host, setOpenEventForm, setOpenSignin } = props;
  const params: any = useParams<any>();

  const navigate = useNavigate();

  const [searchKeywordAttendees, setSearchKeywordAttendees] =
    useState<string>("");
  const [searchKeywordEvents, setSearchKeywordEvents] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [hiddenTabs, setHiddenTabs] = useState<string[]>(
    host?.hiddenTabs || []
  );
  const location = useLocation();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);

  // const [activemenu, setActivemenu] = useState<any>("Team");

  const [allAds, setAllAds] = useState<any>([]);

  useEffect(() => {
    setHiddenTabs(host?.act || []);
  }, [host?.hiddenTabs]);

  const [guestProfileCategoresState, setGuestProfileCategoresState] = useState<
    string[]
  >([]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab, eventId, myInfo?.handle]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab, hostId, myInfo?.handle]);

  // function for the Tabs Filtering below
  const handleFilterCheckboxChange = (tabName: string, isChecked: boolean) => {
    const updatedActiveTabs = isChecked
      ? [...hiddenTabs, tabName]
      : hiddenTabs.filter((t) => t !== tabName);

    // Update local state immediately for UI responsiveness
    setHiddenTabs(updatedActiveTabs);

    // Prepare data for DB update
    const data = {
      query: {
        handle: host?.handle,
        // hiddenTabs: updatedActiveTabs,
        hiddenTabs: updatedActiveTabs,
      },
      resource: "calendar_users",
      check: ["handle"],
      myInfo,
    };

    // Update database in the background
    saveDataDB(data)
      .then((res) => {
        // console.log("Database update response:", res);
        setRefreshing(true);
      })
      .catch((error) => {
        console.error("Failed to update database", error);
        // Optionally revert UI changes or notify user
        setHiddenTabs(host?.hiddenTabs || []);
      });
  };

  useEffect(() => {
    if (!(host?.handle && hostTab)) {
      return;
    }
    const data = {
      query: {
        handle: host?.handle,
      },
      resource: `calendar_host_about`,
      check: ["handle"],
    };
    getDataDB(data).then((res: any) => {
      const result: any = res?.[0]?.industry && Object.keys(res?.[0]?.industry);

      if (result) {
        // console.log("hostTabsxxccvvv", result);
        setGuestProfileCategoresState(result || []);
        setSelectedGuestProfileCategory(result[0]);
      }

      setRefreshing(false);
    });
  }, [host?.handle, hostTab]);

  // Parameters are purely optional.

  // console.log("hostTabsxxccvvv", selectCount, allAds[selectCount], bannerImage);

  const [formInput, setFormInput] = useState<any>({});
  // const [selEvent, setSelEvent] = useState<any>(selectedEvent);
  const [localAction, setLocalAction] = useState<any>();
  const [refreshing, setRefreshing] = useState<boolean>(false);

  // const [openAds, setOpenAds] = useState<boolean>(false);
  // const [viewSaved, setViewSaved] = useState<any>([]);
  // const [openChat, setOpenChat] = useState<boolean>(false);

  const [addProfCat, setAddProfCat] = useState<boolean>(false);
  const [selectedGuestProfileCategory, setSelectedGuestProfileCategory] =
    useState<string>("");

  const [isVisible, setIsVisible] = useState(false);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const model: any = useMemo(() => {
    return hostTabs?.filter((ref: any) => ref.name == eventTab)?.[0]?.model;
  }, [eventTab, hostTabs]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const resource = useMemo(() => {
    const tab = eventTab || hostTab;
    const subTab = eventSubTab || hostSubTab;
    return `calendar_${params.eventId ? "event" : "host"}${tab ? "_" + tab : ""
      }${subTab ? "_" + subTab : ""}`;
  }, [eventTab, eventSubTab, hostSubTab, hostTab, params?.eventTab]);

  const [myLike, setMyLike] = useState<boolean>(false);

  useEffect(() => {
    setMyLike(
      myLikes?.filter((res: any) => res.eventId == eventInfo?._id)?.[0]?.myLike
        ? true
        : false
    );
  }, [myLikes, eventInfo?._id, refreshing]);
  const adData = [
    {
      id: 1,
      title: "Github Bootcamp",
      description: "Lest Guthub Basics and become familiar to the github",
      imageUrl:
        "https://images.pexels.com/photos/92628/pexels-photo-92628.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      linkUrl: "https://example.com/special-offers",
    },
    {
      id: 2,
      title: "MPL",
      description: "Minority Product Launch - Test Description",
      imageUrl:
        "https://images.pexels.com/photos/92628/pexels-photo-92628.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      linkUrl: "https://example.com/new-arrivals",
    },
    {
      id: 3,
      title: "Hexagon",
      description: "A App to manage the alot of facilties for the employees",
      imageUrl:
        "https://images.pexels.com/photos/92628/pexels-photo-92628.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      linkUrl: "https://example.com/back-to-school",
    },
    {
      id: 2,
      title: "MPL",
      description: "Minority Product Launch - Test Description",
      imageUrl:
        "https://images.pexels.com/photos/92628/pexels-photo-92628.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      linkUrl: "https://example.com/new-arrivals",
    },
    {
      id: 3,
      title: "Hexagon",
      description: "A App to manage the alot of facilties for the employees",
      imageUrl:
        "https://images.pexels.com/photos/92628/pexels-photo-92628.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      linkUrl: "https://example.com/back-to-school",
    },
    // Add more ad objects as needed
  ];

  useEffect(() => {
    if (!isMobile) {
      setShowBanner(true);
    }
  }, [isMobile]);

  const [showBanner, setShowBanner] = useState(false);
  const [mobMenu, setMobMenu] = useState<boolean>(false);

  return (
    <>
      {openChat ? (
        <MessengerModal style={{ background: "white" }} isEvent={false}>
          <SupportersList
            {...props}
            directMsgGuest={host}
            openChat={openChat}
            setOpenChat={() => { }}
          />
        </MessengerModal>
      ) : null}
      {(eventTab || hostTab) && (
        // isEvent={eventTab || hostTab ? true : false}
        <div>
          {openChat && (
            <MessengerModalMob>
              <SupportersList
                {...props}
                directMsgGuest={host}
                openChat={openChat}
                setOpenChat={() => { }}
              />
            </MessengerModalMob>
          )}
          {/* header */}

          {eventTab && (
            <EventHeaderWrapper>
              <EventBannerComponent
                hero={true}
                {...props}
                setMobMenu={setMobMenu}
                mobMenu={mobMenu}
                allAccess={allAccess}
                eventTab={eventTab}
                resource={resource}
                showBanner={showBanner}
                setShowBanner={setShowBanner}
              />

              <ParentAdsWrapper id="event-tabs-menu">
                <EventEditor
                  {...props}
                  setMobMenu={setMobMenu}
                  mobMenu={mobMenu}
                  selectedEvent={selectedEvent}
                  setLocalAction={setLocalAction}
                  setSearchKeywordEvents={setSearchKeywordEvents}
                  searchKeywordAttendees={searchKeywordAttendees}
                  setSearchKeywordAttendees={setSearchKeywordAttendees}
                  searchKeywordEvents={searchKeywordEvents}
                  setOpenEventForm={setOpenEventForm}
                  setOpenSignin={setOpenSignin}
                  selectedGuestProfileCategory={selectedGuestProfileCategory}
                  setSelectedGuestProfileCategory={
                    setSelectedGuestProfileCategory
                  }
                  showBanner={showBanner}
                  setShowBanner={setShowBanner}
                />
                <AdWrapper>
                  <h1 style={{ textAlign: "left" }}>Related Events</h1>
                  <Ads>
                    {adData.map((ad) => (
                      <Ad key={ad.id} className="ad-card">
                        <img src={ad.imageUrl} alt={ad.title} />
                        <AdTextInfo className="ad-info">
                          <h3>{ad.title}</h3>
                          <p>{ad.description}</p>
                          <a
                            href={ad.linkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </a>
                        </AdTextInfo>
                      </Ad>
                    ))}
                  </Ads>
                </AdWrapper>
              </ParentAdsWrapper>
            </EventHeaderWrapper>
          )}

          {/* todo - update editor to be general for all tabs */}
          {!eventTab && hostTab && <HostEditor {...props} />}
        </div>
      )}
    </>
  );
};

const ReviewsResult = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const navigate = useNavigate();

  const {
    // eventTab,
    host,
    myInfo,
    myInfo: { active = "2" },
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    openAds = false,
  } = myContext;
  const params: any = useParams<any>();
  const [dataRecord, setDataRecord] = useState<number>(0);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, hostId, myInfo?.handle]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const resource = useMemo(() => {
    return eventId ? "calendar_event_reviews" : "calendar_skills_record";
  }, [eventId]);

  const query = eventId
    ? {
      eventId: eventId,
    }
    : {
      handle: host?.handle,
    };

  // useEffect(() => {
  //   getDataDB({
  //     query: {
  //       ...query,
  //     },
  //     resource: resource,
  //   }).then((info) => {
  //     if (info?.[0]) {
  //       const rating = _.sumBy(info, function (o: any) {
  //         return o.rating;
  //       });
  //       const avgRating = (rating * 1 * info?.length * 5) / (info?.length * 5);
  //       // console.log('vvvvvyyy', data, avgRating)
  //       setDataRecord(Math.round(avgRating));
  //     }
  //   });
  // }, [eventId, host?.handle]);

  // console.log("vvvvvyyy", dataRecord);

  return (
    <div
      onClick={() => {
        if (eventId) {
          navigate(`/event/${eventId}/reviews/add`);
        } else if (hostId) {
          navigate(`/event/${hostId}/about`);
        }
      }}
    >
      <Rating
        style={{
          fontSize: props.isSmall ? 14 : 30,
        }}
        name="size-large"
        defaultValue={dataRecord * 1}
        value={dataRecord}
        size="small"
        disabled={true}
        onChange={(e: any) => { }}
      />
    </div>
  );
};

export const EventBannerComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    allAccess,
    eventTab,
    resource,
    showBanner,
    setShowBanner,
    setMobMenu,
    mobMenu,
  } = props;
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    openAds = false,
    eventInfo = {},
    fullScreen = true,
    mobMenuColor,
    openCreateEvent,
    serviceInfo = {}
  } = myContext;
  const { host, setOpenEventForm, setOpenSignin, sm } = props;
  const [allAds, setAllAds] = useState<any>([]);
  const [viewSaved, setViewSaved] = useState<any>([]);
  const intervalId: any = useRef(null);
  const [count, setCount] = useState<number>(0);
  const [selectCount, setSelectCount] = useState<number>(0);
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const location = useLocation();
  // const [serviceInfo, setServiceInfo] = useState<any>({});

  // const TabsCardInfo = [
  //   {
  //     id: "development",
  //     label: "Development",
  //     title: `${eventInfo?.title} Guest Calendar & Planner`,
  //     description:
  //       "Share your availability with others. Manage contacts and todo list in your GuestNet Funnel",
  //     color: "red",
  //     img: `url("${relatedPict[8]}")`,
  //   },
  //   {
  //     id: "marketing",
  //     label: "Marketing",
  //     title: `${eventInfo?.title} Business / Organization Portfolio`,
  //     description:
  //       "Create contents along with your link trees to share with others. Share websites, articles and contents on your GuestNet Story",
  //     color: "green",
  //     img: `url("${relatedPict[10]}")`,
  //   },
  //   {
  //     id: "operation",
  //     label: "Operation",
  //     title: `${eventInfo?.title} Advanced Tools`,
  //     description:
  //       "Leverage tools liks Document Signing, Ticketing, Product Listing, Asset Tracking, Collaboration Agreements, and other Templates etc",
  //     color: "brown",
  //     img: `url("${relatedPict[12]}")`,
  //   },
  // ];
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab, hostId, myInfo?.handle]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);


  useEffect(() => {
    if (!host?.handle && hostTab) {
      return;
    }

    //get my services
    getDataDB({
      query: {
        ...(eventId ? {
          "eventId": eventId
        } : {
          handle: myInfo?.handle
        }),
      },
      resource: `calendar_services`,
      check: ["eventId"],
    }).then((res: any) => {
      setMyContext((existing: any) => {
        return {
          ...existing,
          serviceInfo: res?.[0] || {},
        };
      });
    })

    //get my adverts
    const myAds = {
      query: {
        handle: myInfo?.handle,
      },
      resource: `calendar_event_advert_accept_package`,
      check: ["handle"],
    };
    getDataDB(myAds).then((res: any) => {
      // setAllAds
      const myAds: any = res?.map((res: any) => {
        // console.log("hostTabsxxccvvv", res);

        return {
          picture: res.picture,
          _id: res._id,
        };
      });

      setAllAds([
        {
          _id: "6640d5c06355a846e22e8e5g",
          picture:
            selectedEvent?.picture ||
            selectedEvent?.image ||
            selectedEvent?.img ||
            "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
        },
        ...myAds,
      ]);
    });
  }, [hostTab, host?.handle]);

  const [bannerImage, setBannerImage] = useState<any>({
    picture:
      selectedEvent?.picture ||
      selectedEvent?.image ||
      selectedEvent?.img ||
      "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
  });


  useEffect(() => {
    setMyContext((existing: any) => {
      return {
        ...existing,
        showBanner: true,
      };
    });
  }, []);

  useEffect(() => {
    if (!viewSaved?.includes(allAds[selectCount]?._id)) {
      saveAds();
    }
  }, [viewSaved, selectCount, allAds]);

  const saveAds = useCallback(() => {
    const rep: any = [];
    const reg: any = myInfo?.industry;
    myInfo?.industry &&
      Object.keys(myInfo?.industry)?.map((res: any) => {
        [res, ...reg?.[res]]?.map((ret) => {
          rep.push(ret);
        });
      });
    // console.log("formDatacc2", allAds);

    if (!(allAds?.[selectCount]?._id && myInfo?.handle)) {
      return;
    }
    // console.log("selectedEventsss", viewSaved);

    const data = {
      query: {
        advertId: allAds[selectCount]?._id,
        picture: allAds[selectCount]?.picture,
        name: myInfo?.name,
        handle: myInfo?.handle,
        like: true,
        skills: rep || [],
        eventId: eventId,
        host: hostId,
      },
      resource: `calendar_advert_views`,
      check: ["handle", "advertId"],
      myInfo,
    };
    saveDataDB(data).then((res: any) => {
      // alert(allAds[selectCount]?._id)

      setViewSaved([allAds[selectCount]?._id, ...viewSaved]);
    });
  }, [selectCount, allAds, eventId, myInfo?.handle]);

  useEffect(() => {
    console.log("formDatacc2yy", allAds, allAds[selectCount]?._id);
    if (allAds && allAds?.length > 0) startInterval();
  }, [allAds]);

  // console.log("hostTabsxxccvvv", allAds);

  const startInterval = useCallback(() => {
    intervalId.current = setInterval(() => {
      if (count < 5) {
        console.log("formDatacc2yyxx", count);
        setCount((count) => count + 1);
        // setMyContext((existing: any) => {
        //   return {
        //     ...existing,
        //     fullScreen: false,
        //   };
        // });
      } else {
        clearInterval(intervalId.current);
      }
    }, 2000);
  }, [count, allAds]);

  useEffect(() => {
    if (selectCount < allAds?.length) {
      setSelectCount(selectCount + 1);
    } else {
      setSelectCount(0);
    }
  }, [count]);

  useEffect(() => {
    // console.log("hostTabsxxccvvv", selectCount, allAds, allAds?.length);

    if (allAds[selectCount]?.picture) setBannerImage(allAds[selectCount]);
  }, [selectCount, allAds]);
  const [openTabModal, setOpenTabModal] = useState(false);
  const [mode, setMode] = useState<any>(false);

  useEffect(() => {
    if (mode == "stories") {
      setMode(false)
      navigate(`/event/${eventId}/${eventTab}/story`)
    }

    // <StoryTemplate
    //   {...props}
    //   page={'landing'}
    //   handleCloseStory={() => {
    //     setMode(false)
    //   }}
    // // storyResourceProps={storyResource}
    // />
    // )}

  }, [
    mode
  ])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <div>
        {props.banner && (
          <EventTopBannarWrapper sm={sm}>
            <span
              onClick={() => {
                if (bannerImage?._id) {
                  navigate(`/advert/${bannerImage?._id}`, {
                    state: {
                      backUrl: location?.pathname,
                    },
                  });
                }
              }}
              style={{
                position: "absolute",
                top: 20,
                right: 20,
              }}
            >
              {selectCount === 0 && <AttachMoney />}
            </span>
            <div style={{}}>
              <div
                style={{
                  height: 150,
                }}
              ></div>
            </div>

            <EventImage src={bannerImage?.picture} sm={sm} />
          </EventTopBannarWrapper>
        )}
        {props.hero && (
          <EventHero
            style={{
              minHeight: showBanner ? 500 : 150,

              // height: showBanner ? 500 : 150,
            }}
          >
            <Modal
              show={openCreateEvent}
              onHide={() => {
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    openCreateEvent: false,
                  };
                });
                if (eventTab == 'edit') {
                  navigate(`${location?.pathname?.replace('/edit', '/schedule')}`)
                }
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>MyGuestNet</Modal.Title>
              </Modal.Header>
              <Modal.Body> Create or Update Enterprise (Dev)</Modal.Body>
              <Modal.Footer
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EventFormModal {...props} />
              </Modal.Footer>
            </Modal>
            <div
              style={{
                // height: 100
                display: "flex",
                maxWidth: windowWidth,
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {/* <ActionIconsWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: 'red',
                  width: "100%",
                }}
              >
                <ActionIcon
                  style={{
                    top: window.innerHeight * 0.3,
                  }}
                  onClick={() => {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        mobMenuColor: "",
                      };
                    });
                    setMobMenu(true);
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        mobMenu: true,
                      };
                    });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                </ActionIcon>



                <ActionIcon
                  onClick={() => {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        openCreateEvent: true,
                      };
                    });
                  }}
                >
                  <Edit />
                </ActionIcon>


                <ActionIcon
                  onClick={() => {
                    // setOpenTabModal(true);
                    // setMode("stories");
                    window.open(`/story/${eventId}/${eventTab}`, "_self")


                  }}
                >
                  <Camera />
                </ActionIcon>

                <ActionIcon>
                  <RR
                    data={{
                      text: "My GuestNet Profile",
                      url: `${resource}`,
                      title: `My GuestNet Profile`,
                    }}
                  >
                    <Share />
                  </RR>
                </ActionIcon>
                <ActionIcon>
                  <Home
                    onClick={() => {
                      navigate(`/`);
                      setOpenEventForm && setOpenEventForm(false);
                    }}
                  />
                </ActionIcon>

              </ActionIconsWrapper> */}
              {openTabModal && <TabSlider setOpenTabModal={setOpenTabModal} />}
              <TextInfo>
                <div
                  style={{
                    fontSize: isMobile ? "20px" : "3.9vh",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {eventInfo?.verified ? (
                    <Verified
                      onClick={() => {
                        if (!myInfo?.handle) {
                          alert("Please login");
                          return;
                        }
                        if (!allAccess) {
                          return;
                        }
                        let text =
                          "Do you wish to request verification for this event or user";
                        if (window.confirm(text) == true) {
                          text = "You pressed OK!";

                          const data = {
                            query: {
                              verified: "request",
                              handle: myInfo?.handle,
                              eventId: eventId,
                            },
                            resource: `calendar_events`,
                            check: ["handle", "eventId"],
                            myInfo,
                          };
                          saveDataDB(data).then((res) => { });
                          return;
                        } else {
                          text = "You canceled!";
                          return;
                        }
                      }}
                      style={{
                        marginLeft: 20,
                        color: "orange",
                      }}
                    />
                  ) : allAccess ? (
                    <NewReleases
                      onClick={() => {
                        let text = "";
                        if (window.confirm(text) == true) {
                          text = "Submit request for verification";
                          // setOpenSignin(true)
                          return;
                        } else {
                          text = "You canceled!";
                          // setOpenSignin(true)
                          return;
                        }
                      }}
                    />
                  ) : null}
                  {eventInfo?.title || "THIS IS EVENT NAME"}
                </div>
                <p
                  style={{
                    marginTop: -20,
                  }}
                >
                  <TypewriterComponent
                    fontSize={isMobile ? "15px" : "2.9vh"}
                    textArray={[
                      // res?.title,
                      eventInfo?.description || "Welcome",
                    ]}
                  />
                </p>
              </TextInfo>
              <div style={{
                alignSelf: "center",
                display: 'flex',
                flexDirection: 'row'
              }}>
                {/* <ExploreButton
                  style={{
                    backgroundColor: "#479d83",
                    alignSelf: "center",
                    margin: 15,
                    marginBottom: -15,
                    width: 100,
                  }}
                  onClick={() => {
                    navigate(`/guestsite/${eventInfo?.title || ''}`)
                    // setShowBanner((prev: any) => !prev);
                  }}
                >
                  {"Our Guest Site"}
                </ExploreButton> */}
                <ExploreButton
                  style={{
                    backgroundColor: !showBanner ? "#479d83" : "red",
                    alignSelf: "center",
                    margin: 15,
                    marginBottom: -15,
                    width: 100,
                  }}
                  onClick={() => {
                    setShowBanner((prev: any) => !prev);
                  }}
                >
                  {!showBanner ? "Explore More" : "Explore Less"}
                </ExploreButton>
              </div>
            </div>
            {showBanner && (
              <TabCardSlider>
                {TabsCardInfo?.map((reg, i) => (
                  <TebCardContainer
                    style={{
                      backgroundColor: reg?.color,
                      backgroundImage: reg?.img,
                    }}
                  >
                    <BadgeWrapper>
                      <span />
                      {reg?.label}
                    </BadgeWrapper>
                    <TabHeadInfo>
                      <h6>
                        {eventInfo?.title} {reg?.title}
                      </h6>

                      <p
                        style={{
                          color: "white",
                        }}
                      >
                        {reg?.description}
                      </p>
                    </TabHeadInfo>
                    <button
                      onClick={() => {
                        setMyContext((existing: any) => {
                          return {
                            ...existing,
                            mobMenuColor: reg,
                          };
                        });
                        setShowBanner(false);
                        // jumpToReleventDiv("event-tabs-menu");
                      }}
                    >
                      Get Started
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </button>
                  </TebCardContainer>
                ))}
              </TabCardSlider>
            )}
          </EventHero>
        )}
      </div>
    </>
  );
};

export const EventKeySearch = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    openAds = false,
  } = myContext;
  const {
    host,
    setOpenEventForm,
    setOpenSignin,
    formQuestion,
    setFormQuestion,
    setSearchNow,
    searchNow,
  } = props;
  const [allAds, setAllAds] = useState<any>([]);
  const [viewSaved, setViewSaved] = useState<any>([]);
  const intervalId: any = useRef(null);
  const [count, setCount] = useState<number>(0);
  const [selectCount, setSelectCount] = useState<number>(0);
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const [formInput, setFormInput] = useState<any>({});
  // const [formQuestion, setFormQuestion] = useState<any>('');

  return (
    <InputAgenda>
      <input
        type={"text"}
        onChange={(e: any) => setFormQuestion(e.target?.value)}
        value={formQuestion || ""}
        placeholder={"What do you wish to do?"}
        required
      />
      <div>
        <Search
          onClick={() => {
            setSearchNow(!searchNow);
          }}
        />
      </div>
    </InputAgenda>
  );
};

const TypewriterComponent = (props: any) => {
  const {
    textArray = ["Hello World!", "Welcome to React!", "Enjoy coding!"],
    fontSize = "24px",
  } = props;
  return (
    <div style={{ fontSize: fontSize, fontWeight: "bold" }}>
      <Typewriter
        words={textArray}
        loop={1}
        cursor
        cursorStyle="_"
        typeSpeed={70}
        deleteSpeed={50}
        delaySpeed={1000}
      />
    </div>
  );
};

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  position: relative;
  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

export const MessengerModal = styled(DetailModal)`
  @media (max-width: 756px) {
    display: none;
  }
`;
export const MessengerModalMob = styled.div`
  display: none;
  @media (max-width: 756px) {
    display: flex;
    inset: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    border-radius: 8px;
    padding: 24px;
    background-color: white;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
  background: #fff;
  h2 {
    color: #262527;
    font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  p {
    margin: 0;
    color: #6a6a70;
    text-align: center;
    font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
    font-weight: 300;
    line-height: 15px;
    padding-bottom: 14px;
  }
`;

export const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

export const EventImage = styled.img<{ sm: boolean }>`
  position: absolute;
  inset: 0;
  z-index: -10;
  border-radius: 10px;
  height: 150px;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
`;

export const TextWrapperHero = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ sm }) => (sm ? "5px" : "10px")};
  button {
    font-size: 24px;
    font-style: uppercase;
  }
  @media (max-width: 768px) {
    gap: 10px;
    h1 {
      font-size: 22px;
      letter-spacing: 10;
    }
    p {
      font-size: 14px;
    }
    button {
      font-size: 14px;
    }
  }
`;

export const EventTopBannarWrapper = styled.div<{ sm: boolean }>`
  width: 100%;
  min-height: 150px;
  margin-bottom: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 10;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ sm }) => (sm ? "10px" : "0px")};
`;

export const EventHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h2,
  p {
    margin: 0;
  }
`;
export const EventTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  max-width: 200px;
  h2 {
    font-size: 22px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: gray;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  }
`;
const TopEventInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 50px;
  justify-content: space-between;
`;
export const SearhKeywordWrapper = styled.div`
  max-width: 200px;
  width: 100%;
`;
export const AdWrapper = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
`;
export const Ad = styled.div`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 5px;
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
`;
export const AdTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 15px 10px;
  h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    text-align: left;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: gray;
    text-align: left;
  }
  a {
    color: gray;
    cursor: pointer;
    text-align: left;
  }
`;
export const Ads = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: normal;
  }
`;
export const ParentAdsWrapper = styled.div`
  max-width: 90vw;
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  gap: 30;
`;
export const EventHero = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 100px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* background: #479d83; */
  background: url(https://images.pexels.com/photos/66869/green-leaf-natural-wallpaper-royalty-free-66869.jpeg?cs=srgb&dl=pexels-pixabay-66869.jpg&fm=jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 40px;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 500px) {
    padding: 10px;
  }
`;
const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1200px) {
    align-items: center;
  }
  h1 {
    font-size: 54px;
    font-weight: 900;
    color: white;
    margin: 0;
    text-align: left;
    @media (max-width: 1200px) {
      text-align: center;
    }
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin: 0;
    max-width: 500px;
    text-align: left;
    @media (max-width: 1200px) {
      text-align: center;
    }
  }
`;

const TebCardContainer = styled.div`
  max-width: 300px;
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px;
  gap: 40px;
  color: white;
  @media (max-width: 756px) {
    width: 210px;
    min-width: 210px;
  }
  button {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    margin: 0;
    background-color: none;
    border: none;
    outline: none;
    svg {
      height: auto;
      width: 20px;
      color: white;
    }
  }
`;
const TabCardSlider = styled.div`
  display: flex;
  gap: 10px;

  padding: 20px 0;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: orange;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darkorange;
  }
  scrollbar-color: orange #f1f1f1;
  scrollbar-width: thin;
  @media (max-width: 756px) {
    width: 210px;
    min-width: 210px;
    width: 90vw;
    overflow: auto;
  }
`;
const TabHeadInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: white;
  h6 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    text-align: left;
  }
`;
const BadgeWrapper = styled.div`
  background: #00000039;
  display: flex;
  gap: 10px;
  color: white;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top: 30px;
  left: 30px;
  align-items: center;
  span {
    width: 8px;
    height: 8px;
    background-color: #04ff04;
    border-radius: 50%;
  }
  font-size: 12px;
  color: white;
`;
const ExploreButton = styled.button`
  border-radius: 50px;

  padding: 0px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  border: none;
  svg {
    color: white;
    height: auto;
    width: auto;
  }

  background-color: #479d83;

  font-size: 12px;
  color: white;
`;
export const ActionIconsWrapper = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 1200px) {
    position: static;
  }
`;
export const ActionIcon = styled.button`
  background: transparent;
  border: none;
  background: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  svg {
    width: 20px;
    height: 20px;
    color: #479d83;
  }
`;
