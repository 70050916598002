import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import { Tooltip as ReactTooltip } from "react-tooltip";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";
import { ShareWrapper } from "../Share";

import Select from "react-select/creatable";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";

import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  Close,
  CloudUpload,
  Description,
  Edit,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  Handshake,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Save,
  Share,
  ShoppingBag,
  StarBorder,
  Visibility,
  X,
} from "@mui/icons-material";
import { BasicMutipleSelect } from "./BasicMutipleSelect";
import { PopUpModal } from "../PopUpModal";
import { RatingPopUpModal } from "../RatingPopUpModal";
import {
  GuestDetailIndustryWrapper,
  SkillAction,
  SkillActions,
  SkillContainer,
  SkillTabContainer,
} from "./AboutTabListItems";
import { MultiSelect } from "react-multi-select-component";
import { ButtonL } from "../AppIndex";
import { MyVideoUploader } from "./MyVideoUploader";
import { MyFileUploader } from "./MyFileUploader";
// import { IconButton } from 'material-ui';

export const TrainingSkillsTabList = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, resource } = props;
  const params: any = useParams<any>();
  const [uploadNow, setUploadNow] = useState<boolean>(false);

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [addSkill, setAddSkill] = useState<any>("");
  const [allLikes, setAllLikes] = useState<any>([]);
  const [allShares, setAllShares] = useState<any>([]);
  const [allMySkills, setAllMySkills] = useState<any>({});
  const [editSkills, setEditSkills] = useState<any>('');

  const [Singleselect, setSingleselect] = useState("");

  // const [formInput, setFormInput] = useState<any>([]);
  const [schedules, setSchedules] = useState<any>([]);
  const [subscription, setSubscription] = useState<any>([]);



  const navigate = useNavigate();


  const [skillsIndustry, setSkillsIndustry] = useState<any>({});

  useEffect(() => {
    if (skills_industry)
      setSkillsIndustry(skills_industry);
  }, [skills_industry]);

  const options: any = useMemo(() => {
    return Object.keys(skills_industry)?.map((res: any) => {
      return {
        value: res,
        label: res,
      };
    });
  }, [skills_industry]);

  const { myInfo, host, hostTabs } = myContext;

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const model: any = useMemo(() => {
    return hostTabs?.filter((ref: any) => ref.name == eventTab)?.[0]?.model;
  }, [eventTab, hostTabs]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);



  useEffect(() => {
    const data = {
      query: {
        eventId: eventId,
      },
      resource: resource,
      check: ["eventId"],
    };
    getDataDB(data).then((res) => {
      // console.log("hostTabsxxggg", res?.[0]?.industry);
      if (res && res?.[0]?.industry) {
        setDataRecord(res?.[0] ? res : []);
      }
      setFormInput({
        // _id: res?.[0]?._id,
        eventId: eventId,
      });
      setRefreshing(false);
    });

    ///get my likes
    const dataLikes = {
      query: {
        eventId: eventId,
      },
      resource: resource,
      check: ["handle"],
    };

    // console.log('formDatacc', data)
    getDataDB(dataLikes).then((res) => {
      // console.log("hostTabsxx", res?.[0]);
      setAllLikes(res);
    });

    const dataShares = {
      query: {
        eventId: eventId,
      },
      resource: resource,
      check: ["handle"],
    };

    getDataDB(dataShares).then((res) => {
      console.log("hostTabsxx", res?.[0]?.industry);
      if (res?.[0]?.industry) {
        setAllShares(res);
      }
    });

    const allMy = {
      query: {
        handle: myInfo?.handle,
      },
      resource: `calendar_skills_record`,
      check: ["handle"],
    };

    getDataDB(allMy).then((res) => {
      console.log("setAllMySkillsxx", res?.[0]?.industry);
      if (res?.[0]?.industry) {
        setAllMySkills(res?.[0]?.industry);
      }
    });

    getDataDB({
      query: {
        eventId: eventId,
      },
      resource: `calendar_event_schedule`,
      check: ["handle"],
    }).then((res) => {
      setSchedules(res)
    });


    getDataDB({
      query: {
        eventId: eventId,
      },
      resource: `calendar_event_subscription`,
      check: ["handle"],
    }).then((res) => {
      setSubscription(res)

    });



    // setSchedules



  }, [
    eventId,
    refreshing,
    resource
  ]);

  const myLike = useMemo(() => {
    return allLikes?.filter((res: any) => res?.handle == myInfo?.handle)?.[0];
  }, [allLikes, myInfo?.handle]);

  const myShare = useMemo(() => {
    return allLikes?.filter((res: any) => res?.handle == myInfo?.handle)?.[0];
  }, [allLikes, myInfo?.handle]);



  // useEffect(() => {
  //   // console.log("setFormInput", formInput);
  //   if (formInput?.industry) {
  //     const newFormInput: any = {
  //       ...formInput,
  //       eventId: eventId,
  //       industry: {
  //         ...(dataRecord || {}),
  //         // [formInput?.industry]: [...(formInput?.skills || [])],
  //       },
  //     };
  //     newFormInput["industry"][formInput?.industry] = [
  //       ...(formInput?.skills || []),
  //     ];

  //     if (!(newFormInput && formInput?.industry)) {
  //       return;
  //     }

  //     const data = {
  //       query: {
  //         ...(eventTab ? { eventId: eventId } : { handle: host?.handle }),
  //         ...newFormInput,
  //       },
  //       resource: `calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
  //       check: ["handle", "eventId"],
  //       myInfo,
  //     };
  //     // console.log('formDatacc', data)
  //     saveDataDB(data).then((res) => {
  //       setRefreshing(true);
  //     });
  //   } else if (formInput?.details) {
  //     const newFormInput: any = {
  //       ...formInput,
  //       eventId: eventId,
  //       industry: {
  //         ...(dataRecord || {}),
  //         // [formInput?.industry]: [...(formInput?.skills || [])],
  //       },
  //     };
  //     newFormInput["industry"][formInput?.industry] = [
  //       ...(formInput?.skills || []),
  //     ];

  //     if (!(newFormInput && formInput?.industry)) {
  //       return;
  //     }

  //     const data = {
  //       query: {
  //         ...(eventTab ? { eventId: eventId } : { handle: host?.handle }),
  //         ...newFormInput,
  //       },
  //       resource: `calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
  //       check: ["handle", "eventId"],
  //       myInfo,
  //     };
  //     // console.log('formDatacc', data)
  //     saveDataDB(data).then((res) => {
  //       setRefreshing(true);
  //     });
  //   }
  // }, [formInput]);

  const [file, setFile] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf" && file.size <= 2097152) {
      setFile(file);
      // Uploading Logic Here PLease
    } else {
      alert("Please upload a PDF file smaller than 2MB.");
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file && file.type === "application/pdf" && file.size <= 2097152) {
      setFile(file);
      // Upload logic here
    } else {
      alert("Please upload a PDF file smaller than 2MB.");
    }
  };



  useEffect(() => {
    if (editSkills) {
      setMyContext((existing: any) => {
        return {
          ...existing,
          editInfo: true
        };
      });
    }
    setFormInput(editSkills)

  }, [
    editSkills
  ])

  return (
    <>
      <div
        style={
          {
            // paddingBottom: 400
          }
        }
      >
        {editInfo && (
          <>
            <EditSkillsModalWrapper>
              {/* <AddSkillSection>
                <h2>Add from Resume</h2>
                {file ? (
                  <UploadedResume>
                    <div>
                      <InsertDriveFile />
                      <p>{file?.name}</p>
                    </div>
                    <div>
                      <button onClick={() => setFile(null)}>Change File</button>
                      <button>Upload</button>
                    </div>
                  </UploadedResume>
                ) : (
                  <UploadResumeFile
                    onDragOver={(e: any) => e.preventDefault()}
                    onDrop={handleDrop}
                    htmlFor="fileInput"
                  >
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <CloudUpload />
                    <h3>Click Here or Drop Your Resume In here</h3>
                    <p
                      style={{
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      English Resumes in <b> PDF </b> only. <b>Max 2mb</b> file
                      size
                    </p>
                  </UploadResumeFile>
                )}
              </AddSkillSection> */}
              <AddSkillSection>
                <SkillChildWrapper
                  style={{
                    // zIndex: 10,
                  }}
                >

                  <AddSkillSection style={{
                    marginTop: 30
                  }}>
                    <h2>Industy</h2>
                    <SkillAddWrapper style={{
                      display: 'flex',
                      position: 'relative',
                      flexDirection: 'column',
                      zIndex: 9999
                    }}>

                      <Select
                        defaultValue={Singleselect}
                        value={formInput?.industry ? {
                          value: formInput?.industry,
                          label: formInput?.industry
                        } : ''}
                        onCreateOption={(e: any) => {
                          console.log('creat', e)
                          // alert(e)
                          if (e) {
                            const data = {
                              ...formInput,
                              industry: e,
                            };
                            const tep = { ...skillsIndustry }
                            tep[e] = ['TBD']
                            // setSkillsIndustry(tep)
                            // setFormInput(data);
                            setFormInput({
                              ...data
                            })
                          }
                        }}
                        onChange={(e: any) => {
                          const data = {
                            ...formInput,
                            industry: e?.value,
                          };
                          setFormInput({
                            ...data
                          })

                          // console.log("nnnnn", data);

                          // setFormInput(data);
                        }}
                        // isClearable
                        options={options}
                        placeholder="Select Industy"
                        classNamePrefix="selectform"
                      />


                      {skillsIndustry?.[formInput?.industry] && (
                        <AddSkillSection>
                          <h2>Training Caouses </h2>
                          <MultiSelect
                            {...props}
                            style={{
                              with: '100%'
                            }}
                            options={skillsIndustry?.[formInput?.industry]?.map(
                              (res: any) => {
                                return {
                                  value: res,
                                  label: res,
                                };
                              }
                            )}
                            isCreatable
                            value={formInput?.skills?.map((res: any) => {
                              return {
                                value: res,
                                label: res
                              }
                            }) || []}
                            setFormInput={setFormInput}
                            onChange={(e: any) => {
                              console.log("handleChange", e);
                              const data = {
                                ...formInput,
                                skills: e?.map((res: any) => res.value),
                              };
                              // console.log("nnnnn", data);
                              setFormInput({
                                ...data
                              })
                              // setFormInput(data);
                            }}
                          />
                        </AddSkillSection>
                      )}
                    </SkillAddWrapper>
                  </AddSkillSection>

                  <AddSkillSection>
                    <h2>Description</h2>
                    <InputAgenda onSubmit={(e: any) => { }}>

                      <input
                        type={"text"}
                        onChange={(e: any) => {
                          const data = {
                            ...formInput,
                            description: e.target.value
                          }
                          setFormInput({
                            ...data
                          })

                        }}
                        value={formInput?.description || ""}
                        placeholder={"Description..."}
                        required
                      />

                    </InputAgenda>
                  </AddSkillSection>
                  {formInput?.industry
                    && <>
                      <AddSkillSection>
                        <h2>Benefits of Training.</h2>
                        <InputAgenda onSubmit={(e: any) => { }}>
                          <MultiSelect
                            {...props}
                            options={courseBenefits?.map(
                              (res: any) => {
                                return {
                                  value: res?.benefit,
                                  label: res?.benefit,
                                };
                              }
                            )}
                            isCreatable
                            value={formInput?.benefits?.map((res: any) => {
                              return {
                                value: res,
                                label: res
                              }
                            }) || []}
                            placeholder={'Select or Add Offerings'}
                            setFormInput={setFormInput}
                            onChange={(e: any) => {
                              console.log("handleChange", e);
                              const data = {
                                ...formInput,
                                benefits: e?.map((res: any) => res.value),
                              };
                              setFormInput({
                                ...data
                              })
                              // console.log("nnnnn", data);
                              // setFormInput(data);
                            }}
                          />
                        </InputAgenda>
                      </AddSkillSection>
                      <AddSkillSection>
                        <h2>Picture Pitch on Opportunity</h2>
                        <MyFileUploader
                          {...props}
                          allowAdd={false}
                          defaultImage={formInput?.img}
                          refreshing={refreshing}
                          setRefreshing={setRefreshing}
                          setFormInput={setFormInput}
                          formInput={{
                            ...formInput,
                            title: formInput?.title || formInput?.industry
                          }}
                          uploadNow={uploadNow}
                          resource={resource}
                          handleSaveImage={(e: any) => {
                            setUploadNow(true);
                          }}
                        />
                      </AddSkillSection>
                      <AddSkillSection>
                        <h2>Video Pitch on Opportunity (optional)</h2>
                        <MyVideoUploader
                          {...props}
                          defaultImage={formInput?.video || ""}
                          refreshing={refreshing}
                          setRefreshing={setRefreshing}
                          setFormInput={setFormInput}
                          videoKey={'video'}
                          formInput={{
                            ...formInput,
                            title: formInput?.title || formInput?.industry
                          }}
                          uploadNow={uploadNow}
                          handleSaveImage={(e: any) => {
                            setUploadNow(true);
                          }}
                          resource={resource}
                        />
                      </AddSkillSection>
                    </>}


                  <AddSkillSection>
                    <h2>Asssign Schedule  <i className="fa fa-calendar"
                      style={{
                        marginLeft: 10
                      }}
                      onClick={() => {
                        navigate(
                          `/event/${eventId}/schedule`
                        );
                      }}>
                    </i>
                    </h2>
                    <Select
                      // defaultValue={Singleselect}
                      value={formInput?.schedule ? {
                        value: formInput?.schedule,
                        label: formInput?.schedule
                      } : ''}

                      onChange={(e: any) => {
                        const data = {
                          ...formInput,
                          schedule: e?.value,
                        };
                        setFormInput({
                          ...data
                        })

                      }}
                      // isClearable
                      options={schedules?.map(
                        (res: any) => {
                          return {
                            value: res?.title,
                            label: res?.title,
                          };
                        }
                      )}
                      placeholder="Select Industy"
                      classNamePrefix="selectform"
                    />
                  </AddSkillSection>

                  <AddSkillSection>
                    <h2>Asssign Subscription (Optional) <i className="fa fa-calendar"
                      style={{
                        marginLeft: 10
                      }}
                      onClick={() => {
                        navigate(
                          `/event/${eventId}/subscription`
                        );
                      }}>
                    </i></h2>
                    <Select
                      // defaultValue={Singleselect}
                      value={formInput?.subscription ? {
                        value: formInput?.subscription,
                        label: formInput?.subscription
                      } : ''}

                      onChange={(e: any) => {
                        const data = {
                          ...formInput,
                          subscription: e?.value,
                        };
                        setFormInput({
                          ...data
                        })

                      }}
                      // isClearable
                      options={subscription?.map(
                        (res: any) => {
                          return {
                            value: res?.title,
                            label: res?.title,
                          };
                        }
                      )}
                      placeholder="Select Industy"
                      classNamePrefix="selectform"
                    />
                  </AddSkillSection>

                  <AddSkillSection>
                    <h2>Training Cost (Optional)</h2>
                    <InputAgenda onSubmit={(e: any) => { }}>

                      <input
                        type={"number"}
                        onChange={(e: any) => {
                          const data = {
                            ...formInput,
                            cost: e.target.value
                          }
                          setFormInput({
                            ...data
                          })

                        }}
                        value={formInput?.cost || ""}
                        placeholder={"Training Cost..."}
                      />

                    </InputAgenda>
                  </AddSkillSection>



                  {formInput?.skills?.[0] && formInput?.industry &&
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 20
                    }}>
                      <ButtonL
                        onClick={() => {
                          setEditSkills(false)
                          setMyContext((existing: any) => {
                            return {
                              ...existing,
                              editInfo: false
                            };
                          });
                          setRefreshing(true);
                          setFormInput({})
                          setFormInput({})

                        }}
                      >
                        Cancel
                      </ButtonL>
                      <ButtonL
                        style={{
                          backgroundColor: 'orange'
                        }}
                        onClick={() => {
                          if (!(formInput?._id)) {
                            return;
                          }
                          let text = "Sure you want to delete?";
                          if (window.confirm(text) == true) {
                            deleteDataDB({
                              id: formInput?._id,
                              resource: resource
                            }).then(res => {
                              alert('Deleted')
                              setRefreshing(true);
                              setFormInput({})
                              setFormInput({})
                            })
                            text = "You pressed OK!";
                          } else {
                            text = "You canceled!";
                          }

                        }}
                      >
                        Delete
                      </ButtonL>
                      <ButtonL
                        onClick={() => {
                          if (!(formInput?.description)) {
                            alert("Please add description");
                            return;
                          }
                          delete formInput?._id
                          const data = {
                            query: {
                              ...formInput,
                              description: formInput?.description,
                              industry: formInput?.industry,
                              // skill: formInput?.skills,
                              eventId: eventId,
                              handle: myInfo?.handle,
                            },
                            resource: resource, // `calendar_event_resource_description`,
                            check: ["industry", "handle"],
                            myInfo,
                          }
                          saveDataDB(data).then((res) => {
                            setUploadNow(true)
                            setTimeout(() => {
                              setRefreshing(true);
                              setFormInput({})
                              setFormInput({})
                              setMyContext((existing: any) => {
                                return {
                                  ...existing,
                                  editInfo: false
                                };
                              });
                            }, 100);

                            // setEditSkillIndex(-1);
                          });
                        }}
                      >
                        Save
                      </ButtonL>
                    </div>}
                </SkillChildWrapper>
              </AddSkillSection>
            </EditSkillsModalWrapper>
          </>
        )
        }

        <SkillTags
          {...props}
          editSkills={editSkills}
          setEditSkills={setEditSkills}
          dataRecord={dataRecord}
          editInfo={editInfo}
          formInput={formInput}
          myLike={myLike}
          myShare={myShare}
          allShares={allShares}
          allLikes={allLikes}
          allMySkills={allMySkills}
        />
      </div >
    </>
  );
};

const SkillTags = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    dataRecord,
    editInfo,
    myLike,
    myShare,
    allShares,
    allLikes,
    allMySkills,
    resource,
    editSkills, setEditSkills
  } = props;
  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [editSkillIndex, setEditSkillIndex] = useState<any>(-1);

  const [skillDetails, setSkillDetails] = useState<any>({});

  const { myInfo, host, hostTabs, eventInfo } = myContext;

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  useEffect(() => {
    if (editSkillIndex >= 0 && eventId && eventInfo?.handle) {
      const allMy = {
        query: {
          eventId: eventId,
          // handle: host?.handle,
        },
        resource: resource, // `calendar_event_resource_description`,
        check: ["handle", "eventId"],
      };

      getDataDB(allMy).then((res) => {
        console.log("setAllMySkills", res?.[0]);
        setSkillDetails(res?.[0]);
      });
    }
  }, [
    eventId,
    editSkillIndex,
    eventInfo?.handle
  ]);

  console.log('dataRecord', dataRecord)

  return (
    <>
      {dataRecord?.[0] &&
        dataRecord?.map((item: any) => {
          const industry = item?.industry
          if (
            !industry
          ) {
            return;
          }


          return (
            <GuestDetailsWrapper onClick={() => console.log(host)}>
              <GuestDetailSection>
                <GuestDetailIndustryWrapper>
                  <h1> <Edit
                    style={{
                      color: "black",
                    }}
                    onClick={() => {
                      setEditSkills(item);
                    }}
                  />{industry}</h1>
                  {myInfo?.handle == formInput?.handle && editInfo ? (
                    <RemoveCircleOutline
                      onClick={() => {
                        if (item?._id) {

                          let text = "Sure you want to delete?";
                          if (window.confirm(text) == true) {
                            deleteDataDB({
                              id: item?._id,
                              resource: resource
                            }).then(res => {
                              alert('Deleted')
                              setRefreshing(true)
                            })
                            text = "You pressed OK!";
                          } else {
                            text = "You canceled!";
                          }
                        }


                      }}
                    />
                  ) : null}
                </GuestDetailIndustryWrapper>
                <IteraterWrapper style={{
                  display: 'flex',
                  position: 'relative',

                }}>
                  {dataRecord &&
                    industry &&
                    item?.skills?.[0] &&
                    item?.skills?.map((skill: any, index: any) => {
                      const likeInfo: any =
                        myLike && myLike?.skill == skill ? myLike?.like : false;
                      const likeList: any = allLikes?.filter((like: any) => {
                        return like.skill == skill;
                      });

                      const shareInfo: any =
                        myShare && myShare?.skill == skill
                          ? myShare?.like
                          : false;
                      const shareList: any = allShares?.filter((like: any) => {
                        return like.skill == skill;
                      });

                      const matchInfo: any =
                        industry && allMySkills && allMySkills?.[industry];

                      return (
                        <SkillContainer
                          data-tooltip-id="my-desc-tooltip"
                          key={index + "nghsssdsgfgf"}
                        >
                          {myInfo?.handle &&
                            myInfo?.handle == host?.handle ? null : (
                            <SkillToolKit
                              id="my-desc-tooltip"
                              place="bottom"
                              variant="light"
                              border={"1px solid gray"}
                              style={{
                                maxWidth: 220,
                                fontSize: 12,
                                padding: 5,
                                textAlign: "left",
                              }}
                            >
                              <h2>Skill Description: </h2>
                              <p>
                                {skillDetails?.description ||
                                  " Host didn't add any description for this skill."}
                              </p>
                            </SkillToolKit>
                          )}

                          <SkillActions >
                            <SkillAction
                              onClick={() => {
                                const newFormInput: any = {
                                  share: !shareInfo,
                                  industry,
                                  skill,
                                  eventId: eventId,
                                  handle: myInfo?.handle,
                                };
                                const data = {
                                  query: {
                                    ...(eventTab
                                      ? { eventId: eventId }
                                      : { handle: host?.handle }),
                                    ...newFormInput,
                                  },
                                  resource: `calendar_${params.eventId ? "event" : "host"
                                    }_${eventTab}_shares`,
                                  check: ["handle", "skill"],
                                  myInfo,
                                };
                              }}

                            >
                              <ShareButton>
                                <ShareWrapper
                                  color={shareInfo || ""}
                                  message={"Refer this requirement to a friend"}
                                  eventLink={`https://www.myguestnet.com/event/${hostTab
                                    ? hostTab +
                                    (hostSubTab ? "/" + hostSubTab : " ")
                                    : " "
                                    }`}
                                  users={[]}
                                >
                                  <Share
                                    style={{
                                      color: shareInfo ? "green" : "black",
                                    }}
                                  />
                                </ShareWrapper>
                              </ShareButton>
                            </SkillAction>

                            {/* {myInfo?.handle &&
                              myInfo?.handle == host?.handle && (
                                <SkillAction>
                                  <Edit
                                    style={{
                                      color: likeInfo ? "green" : "black",
                                    }}
                                    onClick={() => {
                                      setEditSkillIndex(
                                        editSkillIndex == index + industry ? -1 : index + industry
                                      );
                                    }}
                                  />
                                </SkillAction>
                              )} */}
                          </SkillActions>
                          {/* {editSkillIndex == index + industry &&
                            myInfo?.handle &&
                            myInfo?.handle == host?.handle ? (
                            <SkillEditDescriptionWrapper style={{
                              position: 'absolute',
                              zIndex: 9999999999,
                              backgroundColor: 'red'
                            }}>
                              <Save
                                onClick={() => {
                                  if (!formInput?.description) {
                                    alert("Please add description");
                                    return;
                                  }
                                  const data = {
                                    query: {
                                      description: formInput?.description,
                                      industry: industry,
                                      skill: skill,
                                      eventId: eventId,
                                      handle: myInfo?.handle,
                                    },
                                    resource: resource, // `calendar_event_resource_description`,
                                    check: ["handle", "eventId"],
                                    myInfo,
                                  };

                                  saveDataDB(data).then((res) => {
                                    //setRefreshing(true);
                                    setEditSkillIndex(-1);
                                  });
                                }}
                              />
                              <h5>Skill Description</h5>
                              <textarea
                                style={{
                                  width: "100%",
                                }}
                                maxLength={1000}
                                onChange={(e: any) => {
                                  const txt = e?.target?.value;
                                  setFormInput({
                                    ...formInput,
                                    description: txt,
                                  });
                                }}
                                placeholder={
                                  item?.description ||
                                  `Description not available at this time.`
                                }
                                rows={5}
                              />
                            </SkillEditDescriptionWrapper>
                          ) : null} */}
                          <SkillTabContainer
                            onMouseOver={() => { }}
                            {...props}
                            match={
                              matchInfo && matchInfo?.includes(skill)
                                ? true
                                : false
                            }
                            skill={skill}
                          />
                        </SkillContainer>
                      );
                    })}
                </IteraterWrapper>
              </GuestDetailSection>
            </GuestDetailsWrapper>
          );
        })}
    </>
  );
};

const ShareButton = styled.div`
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;

const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const UploadedResume = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  p {
    font-size: 12px;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    width: 15px;
    height: 100%;
    color: #479d83;
  }
  button {
    border: 1px solid #479d83;
    padding: 3px 8px;
    background: #fff;
    font-size: 12px;
    transition: background 0.3s;
    margin: 0;
    &:hover {
      background: #479d832b;
    }
  }
`;

const UploadResumeFile = styled.label`
  background: #479d832b;
  border: 1px dashed gray;
  width: 100%;
  padding: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 10px;
    color: gray;
    margin: 0;
  }
  svg {
    width: 40px;
    color: #479d83;
    height: 100%;
  }
`;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const GuestDetailsWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const GuestDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  h1 {
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    color: #475063;
    margin: 0;
    text-align: left;
  }
`;

const IteraterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  span {
    padding: 4px 10px;
    background: white;
    border-radius: 40px;
    border: 1px solid #e4e7eb;
    color: #212121;
  }
`;

const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
  margin-top: -20px;
`;

const AddSkillSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

const SkillToolKit = styled(ReactTooltip)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  color: #000;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-size: 12px;
    margin: 0;
    color: #000;
  }
`;

const SkillEditDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  min-width: 220px;
  width: 100%;
  border-radius: 2px;
  z-index: -1;
  position: absolute;
  bottom: -112px;
  left: 0px;
  h5 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
  textarea {
    resize: none;
    font-size: 12px;
    width: 100%;
    height: 70px;
    border: 1px solid gray;
    border-radius: 2px;
    padding: 5px;
    outline: none;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #479d83;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
      border-radius: 4px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
  }
  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000;
    width: 15px;
    height: 15px;
  }
`;

// const benefits = [
//   "Equity or ownership stake",
//   "Revenue share or profit-sharing agreement",
//   "Discounted or free access to products/services",
//   "Exclusive access to beta features or new product launches",
//   "Marketing and branding partnerships",
//   "Public recognition or endorsement",
//   "Joint product development opportunities",
//   "Shared intellectual property rights",
//   "Networking opportunities",
//   "Mentorship and advisory roles",
//   "Skill or knowledge exchange",
//   "Co-branded events or initiatives",
//   "Access to data or insights",
//   "Flexible payment terms",
//   "Referral incentives or commissions",
//   "In-kind resources (e.g., office space, equipment)"
// ]

const courseBenefits = [
  { id: 1, benefit: "Gain in-depth knowledge of the subject matter." },
  { id: 2, benefit: "Enhance your practical skills with hands-on experience." },
  { id: 3, benefit: "Receive a certification recognized by industry experts." },
  { id: 4, benefit: "Improve career prospects and open new job opportunities." },
  { id: 5, benefit: "Build a professional network through interaction with peers." },
  { id: 6, benefit: "Access to course materials and resources for continuous learning." },
  { id: 7, benefit: "Learn from experienced instructors with real-world expertise." },
  { id: 8, benefit: "Develop critical thinking and problem-solving skills." },
  { id: 9, benefit: "Flexible learning schedules that fit around your commitments." },
  { id: 10, benefit: "Increase earning potential with advanced skills and qualifications." }
];

