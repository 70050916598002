import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import styled from "@emotion/styled";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RESTCall from "../restApi";
import Select from "react-select";

import DatePicker from "react-multi-date-picker";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
  Radio,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteAgendaDB,
  getAgendaDB,
  getCalendarEvents,
  getDataDB,
  myColors,
  resizeImage,
  saveAgendaDB,
  saveDataDB,
  saveEventDB,
} from "src/api";
import { UserContext } from "src/App";
import axios from "axios";
import { CalendarCardCreate } from "./CalendarCardCreate";
import { ButtonL } from "./AppIndex";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "src/helpers/Utils";
import { Check, ControlPoint, RemoveCircleOutline } from "@mui/icons-material";
import { MultiSelect } from "react-multi-select-component";
import { MyAlert as myAlertFunction } from "./MyAlert";
import { AddSkillSection, SkillChildWrapper } from "./MediaSlider/TabListItems";
import { MyFileUploader } from "./MediaSlider/MyFileUploader";
import { MyVideoUploader } from "./MediaSlider/MyVideoUploader";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};


export const ScheduleFormModal = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const [usersBookedDates, setUsersBookedDates] = useState<any>([]);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const navigate = useNavigate();
  const [uploadNow, setUploadNow] = useState<boolean>(false);

  const {
    myInfo,
    myInfo: { active = "2" },
    openCreateEvent,
    data = {},
    host,
    // myFile,
    // fileImageName,
    // fileImageData,
    // fileImageMime,
    // url,
    contextFormInput,
    scheduleId,
    eventTabs,
    // comments = [],
  } = myContext;
  const {
    refreshing, setRefreshing,
    // formInput: parentFormInput = {},
    // handleMydata=()=>{}
    // formInput,
    // setFormInput,
    // handleMydata,
    // fileImageName,
    // fileImageData,
    // fileImageMime,
    // myFile,
    // url,
    // slots = 1,
    // setSlots,
    // formInput = {},
    // setFormInput
    // scheduleId
    dataTopic = [],
    scheduleInfo = {},
  } = props;
  const [stepNumber, setStepNumber] = useState<number>(0);
  const refStartTime = useRef<any>("");
  const refEndTime = useRef<any>("");
  // const [refreshing, setRefreshing] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  const [slots, setSlots] = useState<number>(1);

  const [eventDetails, setEventDetails] = useState<any>({});

  const params: any = useParams<any>();
  const location = useLocation();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  // useEffect(() => {
  //   if (scheduleId) {

  //     getDataDB({
  //       query: { _id: scheduleId },
  //       resource: `calendar_event_schedule`,
  //       limit: 50,
  //     }).then((res: any) => {
  //       const parentFormInput = res?.[0];
  //       if (!parentFormInput) {
  //         return;
  //       }
  //       const days =
  //         typeof parentFormInput?.days == "string"
  //           ? JSON.parse(parentFormInput?.days)
  //           : parentFormInput?.days;

  //       const dates =
  //         parentFormInput?.dates == "string"
  //           ? JSON.parse(parentFormInput?.dates)
  //           : parentFormInput?.dates;

  //       const data = {
  //         ...parentFormInput,
  //         ...formInput,
  //         days: days || [],
  //         dates: dates || days || [],
  //         ...(parentFormInput?.recurring
  //           ? { recurring: parentFormInput?.recurring }
  //           : {
  //             recurring: "non",
  //           }),
  //         ...(parentFormInput?.type
  //           ? { type: parentFormInput?.type }
  //           : {
  //             type: "meeting",
  //           }),
  //       };
  //       console.log("allMyEvents", data);
  //       if (data?.slots?.length > 0) {
  //         setSlots(data?.slots?.length)
  //       }
  //       setFormInput(data);
  //     });
  //   } else {
  //     setFormInput({
  //       ...formInput,
  //       recurring: "non",
  //       type: "meeting",
  //       // capacity: 100
  //     });
  //   }

  //   // setFormInput(data);
  // }, [scheduleId, location?.pathname]);

  useEffect(() => {
    if (scheduleInfo?.dates || scheduleInfo?.days) {
      // console.log('scheduleInfo', scheduleInfo)
      // formInput?.slots && formInput?.slots?.length)
      // ? formInput?.slots?.length
      // : slots
      scheduleInfo?.slots && setSlots(scheduleInfo?.slots?.length);
      setFormInput(scheduleInfo);
    } else {
      setFormInput({
        ...formInput,
        recurring: "non",
        type: "meeting",
        // capacity: 100
      });
    }
  }, [scheduleInfo]);

  const myFile = useRef<any>();
  const fileImageName = useRef("");
  const fileImageData = useRef("");
  const fileImageMime = useRef("");
  const [url, setImageURL] = useState(
    "https://media.varehall.com/upload/myUpload"
  );

  const handleMydata = useCallback(async () => {
    // console.log('loaderMyData', formInput)

    if (!myInfo?.handle) {
      return;
    }

    if (!formInput?.endDate && formInput?.recurring != "non") {
      alert("End date required");
      return;
    }

    const dataProps = {
      ...formInput,
      handle: myInfo?.handle,
      name: myInfo?.name,
      eventId: eventId,
    };
    const dates = formInput?.dates?.sort((a: any, b: any) =>
      moment(a).diff(moment(b))
    );

    if (dates?.length > 0) {
      dataProps["startDate"] = dates?.[0];
      dataProps["endDate"] = dates?.[dates?.length - 1];
    }
    if (!dataProps["days"] && dataProps?.dates) {
      dataProps["days"] = dataProps?.dates;
    } else if (!dataProps["dates"] && dataProps?.days) {
      dataProps["dates"] = dataProps?.days;
    }

    if (!dataProps["topic"] && dataProps?.title) {
      dataProps["topic"] = dataProps?.title;
    }

    // if (!dataProps?.slots?.[0]?.start || !dataProps?.slots?.[0]?.end) {
    //   alert("Start and End times required");
    //   return;
    // }

    if (!formInput?.endDate) {
      alert("You must select a end date.");
      return;
    }

    const fileName = `calendar_app_picture_${fileImageName.current.toLowerCase()}`;

    if (fileImageData.current) {
      const imageUploader = await axios
        .post(url, {
          mime: fileImageMime.current,
          name: fileName,
          image: fileImageData.current,
        })
        .then((img: any) => {
          dataProps[
            "picture"
          ] = `https://varefiles.s3.us-east-2.amazonaws.com/${img.data && img.data.key ? img.data.key : fileName
          }`;
          return true;
        })
        .catch((err: any) => {
          return true;
        });
      if (imageUploader) {
        RESTCall.axiosQuery(dataProps)
          .then((response: any) => {
            console.log(response, "asdsda");
            // window.location.reload();
            fileImageMime.current = "";
            fileImageData.current = "";
            fileImageName.current = "";
            setMyContext((existing: any) => {
              return {
                ...existing,
                openCreateEvent: false,
                editEvent: false,
                editInfo: false,
              };
            });

            setShowCalendarModal(true);
            setRefreshing(true)
          })
          .catch((error: any) => {
            console.log("error", error);
            return error;
          });
      }
    }

    // console.log('dataProps', dataProps)
    if (dataProps?.title
      // dataProps?.startDate &&
      // && (dataProps?.recurring == 'non' && dataProps?.endDate)
      // dataProps?.slots?.[0]?.start &&
      // dataProps?.slots?.[0]?.end
    ) {
      if (dataProps?.days)
        dataProps["days"] =
          typeof dataProps?.days == "string"
            ? dataProps?.days
            : JSON.stringify(dataProps?.days);

      // return
      delete dataProps?._id;

      const data = {
        query: {
          ...dataProps,
        },
        resource: "calendar_event_schedule",
        check: ["handle", "title"],
        myInfo,
      };
      // Update database in the background
      saveDataDB(data)
        // saveEventDB(dataProps)
        .then((rep: any) => {
          alert("Saved");
          setShowCalendarModal(true);
          getCalendarEvents({ handlssse: null }).then((res) => {
            setMyContext((existing: any) => {
              return {
                ...existing,
                allMyEvents: [...(res || [])] || [],
                openCreateEvent: false,
                editEvent: false,
                editInfo: false,
              };
            });
            setRefreshing(true)
            // navigate(location?.state?.backUrl || `/event/${eventId}/schedule`);
          });
        });
    } else {
      alert("Please make sure you complete all fields");
    }
  }, [formInput]);

  var openFile = async (file: any) => {
    var input = file.target;
    var reader = new FileReader();
    reader.onload = async () => {
      var dataURL = reader.result;
      var output: any = document.getElementById("output");
      output.src = dataURL;

      // console.log('fileImageName', input?.files[0]?.name)

      const successImages: any = reader.result;

      if (!url) {
        // setErrorMessage("missing a url to upload to");
        // setProgress("uploadError");
        return;
      }
      // setProgress("uploading");
      try {
        const name: any = input?.files[0]?.name;

        const imageString = await successImages // resizeImage(successImages, 200, 200);
        const image =
          name &&
            (name.includes(".png") ||
              name.includes(".jpg") ||
              name.includes(".jpeg"))
            ? await [imageString]
            : successImages;

        const parts = successImages?.split(";");
        const mime = parts[0].split(":")[1];

        // console.log('successImages', parts, mime)
        fileImageName.current = name;
        fileImageData.current = image[0];
        fileImageMime.current = mime;
        // setProgress("uploaded");
      } catch (error) {
        console.log("error in upload", error);
        // setErrorMessage(error.message);
        // setProgress("uploadError");
      }
    };
    reader.readAsDataURL(input.files[0]);
  };

  const formatDate = (date: any, time: any, timezoneOffset = "Z") => {
    if (!(date && date?.split("/"))) {
      return null;
    }
    const [month, day, year] = date?.split("/");
    const formattedDate = `20${year}-${month}-${day}T${time}:00${timezoneOffset}`;

    const dateTime = new Date(formattedDate);
    if (isNaN(dateTime.getTime())) {
      console.error("Invalid date or time", dateTime);
      return null;
    }
    // Convert to UTC for Google Calendar
    return dateTime.toISOString().replace(/-|:|\.\d\d\d/g, "") + "Z";
  };

  const createGoogleCalendarLink = async (meetingInfo: any) => {
    const startDateTime = await formatDate(
      meetingInfo.startDate,
      meetingInfo.startTime
    );
    const endDateTime = formatDate(meetingInfo.endDate, meetingInfo.endTime);
    const details = encodeURIComponent(
      `${meetingInfo.description} Meeting link: ${meetingInfo.room}`
    );
    const title = encodeURIComponent(meetingInfo.title);

    const meetingLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDateTime}/${endDateTime}&details=${details}&location=${encodeURIComponent(
      meetingInfo.room
    )}`;

    meetingLink && window.open(`https://${meetingLink}`);
  };

  const timeSlots = useMemo(() => {
    var foo = [];
    for (var i = 1; i <= slots; i++) {
      foo.push(i);
    }
    return foo || [];
  }, [slots]);

  const myDate = useRef<any>()

  useEffect(() => {
    if (formInput?.endDate)
      myDate.current.value = moment(formInput?.endDate)?.format("YYYY-MM-DD") //"2014-02-09"
  }, [
    formInput?.endDate
  ])

  console.log("formInput", formInput);
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 400,
          }}
        >
          <img
            id="output"
            className="uploader__file"
            src={
              myInfo && formInput?.picture
                ? formInput?.picture
                : "https://minorityproductlaunch.com/wp-content/uploads/2023/12/20231212_135738_0000.png"
            }
            style={{
              width: 100,
              height: 100,
              borderRadius: "50%",
              margin: "auto",
              objectFit: "cover",
              marginBottom: 10,
            }}
          />
          {/* <div>Schedule Media (optional)</div> */}

          {/* <StyledInput
            ref={myFile}
            className="form-control"
            type="file"
            name={"file"}
            onChange={(e: any) => {
              openFile(e);
            }}
            style={{
              marginBottom: 10,
              width: "100%",
            }}
          /> */}
          <AddSkillSection>
            <h2>Add Image (optional)</h2>
            <MyFileUploader
              {...props}
              allowAdd={false}
              defaultImage={formInput?.img}
              refreshing={refreshing}
              setRefreshing={setRefreshing}
              setFormInput={setFormInput}
              formInput={formInput}
              uploadNow={uploadNow}
              resource={'calendar_event_schedule'}
              handleSaveImage={(e: any) => {
                setUploadNow(true);
              }}
            />
          </AddSkillSection>
          <AddSkillSection>
            <h2>Add Video (optional)</h2>
            <MyVideoUploader
              {...props}
              defaultImage={formInput?.video || ""}
              refreshing={refreshing}
              setRefreshing={setRefreshing}
              setFormInput={setFormInput}
              videoKey={'video'}
              formInput={formInput}
              uploadNow={uploadNow}
              handleSaveImage={(e: any) => {
                setUploadNow(true);
              }}
              resource={'calendar_event_schedule'}
            />
          </AddSkillSection>
        </div>

        {
          <>
            <FormGroup>
              {/* <Form.Label className="form-label text-dark">Location</Form.Label> */}
              <div
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
              ></div>
              {/* <AddSkillSection>
                <h2>
                  Topic{" "}
                  <span style={{ color: "red" }}>(Required)</span>
                </h2>
                <SkillChildWrapper>
                  <Select
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        color: 'black'
                        //  width: windowWidth * .8 
                      }),
                    }}
                    defaultValue={formInput?.topic ?
                      {
                        label: formInput?.topic,
                        value: formInput?.topic,
                      }
                      : {
                        label: 'Default',
                        value: 'Default',
                      }}
                    onChange={(e: any) => {
                      // console.log("personinfo2", e);
                      // setSelectedTopic(e);
                      if (e?.value)
                        setFormInput({
                          ...formInput,
                          topic: e.value,
                        });
                    }}
                    isClearable
                    options={
                      dataTopic && dataTopic?.[0] != "undefined"
                        ? dataTopic?.map((res: any) => {
                          return {
                            label: res,
                            value: res,
                          };
                        })
                        : []
                    }
                    placeholder={'Add or Select Topic'}
                    classNamePrefix="selectform"
                  />
                </SkillChildWrapper>
              </AddSkillSection> */}
              <div>Title</div>
              <StyledInput
                // value={data?.title}
                className="form-control"
                type="text"
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    title: e.target?.value,
                  })
                }
                value={formInput?.title || ""}
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="Enter Meeting Title"
              />
              <div>Room</div>
              <StyledInput
                className="form-control"
                type="text"
                // value={data?.title}
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    room: e.target?.value,
                  })
                }
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                value={formInput?.room || ""}
                required
                placeholder="Meeting Link"
              />
            </FormGroup>
            <div>Link Tabs</div>
            {eventTabs && (
              <MultiSelect
                {...props}
                options={
                  eventTabs
                    ? eventTabs?.map((res: any) => {
                      // console.log('skills_industryxx', res)
                      return {
                        value: res?.anme,
                        label: res?.name,
                      };
                    })
                    : []
                }
                onMenuToggle={(e: any) => {
                  // setIsOpen(e)
                }}
                // label={'bvbvbvbvbv'}
                // selected={selected}
                // value={formInput?.value || ''}
                // style={{
                //   display: 'flex',
                //   position: 'absolute',
                //   zIndex: 100
                // }}
                onChange={(e: any) => {
                  if (!myInfo?.handle) {
                    return;
                  }
                  // handleInput(e)
                }}
              />
            )}

            <div>Access Code (Optional)</div>
            <FormGroup>
              {/* <Form.Label className="form-label text-dark">Description</Form.Label> */}
              <StyledInput
                // value={data?.title}
                className="form-control"
                type="text"
                // value={data?.title}
                // value={data?.title}
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    code: e.target?.value,
                  })
                }
                value={formInput?.code || ""}
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="Secret Access Code"
              />
              <div>Capacity</div>
              <StyledInput
                // value={data?.title}
                className="form-control"
                type="number"
                min={1}
                // value={data?.title}
                // value={data?.title}
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    capacity: e.target?.value,
                  })
                }
                value={formInput?.capacity || ""}
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="Event Capacity"
              />
              <div>Description (Optional)</div>
              <StyledInput
                // value={data?.title}
                className="form-control"
                type="text"
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    description: e.target?.value,
                  })
                }
                value={formInput?.description || ""}
                style={{
                  marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="Enter Meeting Description"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // paddingLeft: "10px",
                  gap: 10,
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <input
                  type="radio"
                  name={"radio5"}
                  value="private"
                  id="private"
                  onChange={(e: any) => {
                    setFormInput({
                      ...formInput,
                      private: e.target?.value == "private" ? true : false,
                    });
                  }}
                  style={{ width: "10%" }}
                />
                <label htmlFor="private">Private</label>
                <br></br>
                <input
                  type="radio"
                  name={"radio5"}
                  value="public"
                  id="public"
                  onChange={(e: any) => {
                    setFormInput({
                      ...formInput,
                      private: e.target?.value == "private" ? true : false,
                    });
                  }}
                  style={{ width: "10%" }}
                />
                <label htmlFor="public">Public</label>
                <br></br>
              </div>
            </FormGroup>

            <Tabs
              selectedIndex={stepNumber}
              onSelect={(index) => setStepNumber(index)}
            >
              <div
                style={{
                  display: "flex",
                  // marginTop: 20,
                  // marginLeft: 20
                  justifyContent: "center",
                }}
              >
                <CalendarCardCreate
                  // setFormInput={setFormInput}
                  // formInput={formInput}
                  onClick={(e: any) => {
                    // console.log('itemxxx', res

                    const filterUser = usersBookedDates?.filter((reg: any) => {
                      return reg?.userId == formInput?.userId;
                    })?.[0];
                    // console.log('itemxxx-2', filterUser)

                    if (
                      filterUser?.dates?.[0] &&
                      filterUser?.dates?.includes(
                        moment(e.dat.date)?.format("MM/DD/YY")
                      )
                    ) {
                      alert(
                        `Already scheduled for another action at this time`
                      );
                      return;
                    }

                    const filterDates =
                      formInput?.days?.[0] &&
                      [...(formInput?.days || [])]?.filter(
                        (res: any) =>
                          moment(res)?.format("MM/DD/YY") !=
                          moment(e.dat.date)?.format("MM/DD/YY")
                      );
                    const record =
                      formInput?.days?.[0] &&
                        filterDates?.length != formInput?.days?.length
                        ? filterDates
                        : formInput?.days?.[0]
                          ? [...formInput?.days, e.dat.date]
                          : [e.dat.date];
                    // console.log('bbbb', filterDates, record, e.dat, record)
                    setFormInput({
                      ...formInput,
                      days: record,
                    });
                  }}
                  {...props}
                  startDay={""}
                  eventId={formInput?._id}
                  setFormInput={setFormInput}
                  formInput={formInput}
                />
              </div>


              <div>
                <label
                  htmlFor="appt"
                  style={{
                    marginLeft: 10,
                  }}
                >
                  End Date:{" "}
                </label>
                <StyledInput
                  ref={myDate}
                  type="date"
                  // value={moment(formInput?.endDate)?.format("mm/dd/yyyy")?.toString() || ""}
                  onChange={(value: any) => {
                    setFormInput({
                      ...formInput,
                      endDate: value.target.value?.toString(),
                    });
                  }}
                />

              </div>

              <div>
                <label style={{ marginBottom: 5 }}>Event Time Slots:</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <label
                    htmlFor="appt"
                    style={{
                      fontSize: 10,
                      width: "100%",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Start Time:
                  </label>
                  <label
                    htmlFor="appt1"
                    style={{
                      fontSize: 10,
                      width: "100%",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    End Time:
                  </label>
                </div>
                {timeSlots?.map((slot: any, index: number) => {
                  // if (index == 0) {
                  //   return
                  // }
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <StyledInput
                        type="time"
                        id="appt"
                        name="appt"
                        className="form-control"
                        style={{
                          marginBottom: 10,
                          width: "100%",
                        }}
                        // ref={refStartTime}
                        onChange={(e: any) => {
                          const info = formInput?.slots || [];
                          info[index] = {
                            ...(info[index] ? info[index] : {}),
                            start: e?.target?.value,
                          };
                          console.log("formInput33", e?.target?.value);

                          setFormInput({
                            ...formInput,
                            slots: info,
                          });
                        }}
                        value={formInput?.slots?.[index]?.start || ""}
                      />

                      <StyledInput
                        type="time"
                        id="appt1"
                        name="appt1"
                        className="form-control"
                        style={{
                          marginBottom: 10,
                          marginLeft: 10,
                          width: "100%",
                        }}
                        // ref={refEndTime}
                        onChange={(e: any) => {
                          const info = formInput?.slots || [];
                          info[index] = {
                            ...(info[index] ? info[index] : {}),
                            end: e?.target?.value,
                          };
                          console.log("cvvvvvv", info);
                          setFormInput({
                            ...formInput,
                            slots: info,
                          });
                        }}
                        value={formInput?.slots?.[index]?.end || ""}
                      />

                      {index >= 0 && (
                        <RemoveCircleOutline
                          style={{
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            // alert(index)
                            // return
                            setFormInput({
                              ...formInput,
                              slots: formInput?.slots?.filter(
                                (e: any, i: number) => i != index
                              ),
                            });
                            setSlots(slots - 1);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  padding: 10,
                  borderRadius: 10,
                  background: "#ebebeb",
                  textAlign: "center",
                  width: "100%",
                  gap: 5,
                  margin: 0,
                }}
                onClick={() => {
                  setSlots(slots + 1);
                }}
              >
                <ControlPoint />
                Add Time Slots
              </button>
            </Tabs>

            <ButtonL
              style={{ margin: 10 }}
              onClick={() => {
                handleMydata();
              }}
            >
              {"Save"}
            </ButtonL>
          </>
        }
      </div>
    </>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled.form`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  box-shadow: 0 0 5px #8798ad;
  border-radius: 5px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  box-shadow: 0 0 5px #8798ad;
  border-radius: 5px;
  resize: none;
`;

const Field = styled.div`
  label {
    font-size: 16px;
    font-weight: bold;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background: #57b397;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background: #57b397b9;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 1rem;
  background: #57b397;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #57b39794;
  }
`;
