import React, { useState, useEffect, useRef } from "react";
import {
  Link,
  useSearchParams,
  useParams,
  useNavigate,
} from "react-router-dom";
import moment from "moment";
import { groupBy, removeDuplicates, removeDup } from "../helpers/Utils";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "./socket";

import {
  Pagination,
  Breadcrumb,
  Col,
  Row,
  Card,
  Button,
  ProgressBar,
  Modal,
  Dropdown,
  Nav,
  Tab,
  FormGroup,
  Form,
  Carousel,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { saveDirectMessageDB } from "../apiComment";
// import { Container, Content } from './styles';
import { AssistWalkerTwoTone } from "@mui/icons-material";
import useLocalStorage from "use-local-storage";
import { ButtonL } from "./AppIndex";

const DMInputField = (props: any) => {
  const {
    loading = false,
    saveComment,
    comment,
    setComment = () => { },
    replyContact,
    setLoading,
    directMsgGuest,
    setDirectMessages,
    commentInfo,
  } = props;
  const textChatInput = useRef<any>();
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const { myInfo = {} } = localStore;

  const textInput = useRef<any>();
  const [formInputs, setFormInputs] = useState<any>({});
  const [selectedFile, setSelectedFile] = useState<any>();
  const [myMessage, setMyMessage] = useState<any>(false);

  const fileImgInputRef = useRef<any>();
  const fileVideoInputRef = useRef<any>();
  const imgDisplay = useRef<any>();
  var inputImg: any = document.getElementById("inputFile");

  const uploadVideoUrl =
    "https://vare-middleware.herokuapp.com/upload/myAppVideoUpload";
  const uploadImgUrl =
    "https://vare-middleware.herokuapp.com/upload/myAppImageUpload";

  // useEffect(() => {
  //   if (replyContact && textInput.current) {
  //     textInput.current.value = `@${replyContact?.fullName} `
  //   }
  // }, [replyContact])

  // useEffect(() => {
  //   if (!saveComment) {
  //     setComment(false)
  //     if (textInput.current) {
  //       textInput.current.value = ''
  //     }
  //   }
  // }, [saveComment])

  const [isConnected, setIsConnected] = useState<any>(socket.connected);
  const socketNewRef = useRef<any>();

  var inputImg: any = document.getElementById("inputFile");

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    socketNewRef.current = socket;
    socketNewRef.current.on("connect", onConnect);

    // socketNewRef.current.emit("varevoicedm read", [{
    //   handle: myInfo.handle,
    //   item_id: directMsgGuest?.handle,
    // }]);
    // socketNewRef.current.on("varevoicedm read", (msgs: any) => {
    //   console.log('chatMessages', msgs)
    // })

    return () => {
      socketNewRef.current.off("connect", onConnect);
      socketNewRef.current.off("disconnect", onDisconnect);
    };
  }, []);

  const submitForm = () => {
    setLoading(true);
    const filelink = `https://varefiles.s3.us-east-2.amazonaws.com/`;
    const info: any = {
      // ...directMsgGuest,
      name: myInfo.name,
      handle: myInfo.handle,
      item_id: directMsgGuest?.handle,
      img: myInfo.img,
      date: moment().format(),
      message: myMessage,
      picture: "",
      // host: JSON.stringify({
      //   ...directMsgGuest,
      // }),
      // group: JSON.stringify([
      //   myInfo.handle, directMsgGuest?.handle
      // ]),
      // handle: '@handle'
    };

    if (formInputs?.imageFileName && selectedFile) {
      info["picture"] = `${filelink}${formInputs?.imageFileName}`;
    }
    if (formInputs?.videoFileName) {
      info["video"] = `${filelink}${formInputs?.videoFileName}`;
    }
    // console.log('info', info)
    if (
      !info?.name ||
      !info?.handle ||
      !info?.message ||
      // !info?.host ||
      !info?.item_id
    ) {
      alert("Message not sent");
      return;
    }
    saveDirectMessageDB({
      ...info,
    }).then((res: any) => {
      socketNewRef.current.emit("varevoicedm save", { ...info });
      socketNewRef.current.on("varevoicedm read", (res: any) => {
        console.log("retrunmsg", res);
        setMyMessage("");
        if (textInput.current.value) textInput.current.value = "";

        if (inputImg?.files[0] && selectedFile) {
          postPicture(res);
        } else {
          setDirectMessages((existing: any) => {
            return removeDuplicates([...existing, ...res]);
          });
          setLoading(false);
        }
      });
    });
    // .catch(error => {
    //   return error;
    // });
  };

  const postPicture = async (data: any) => {
    if (inputImg?.files[0] && selectedFile) {
      var fReader = new FileReader();
      fReader.readAsDataURL(inputImg.files[0]);
      fReader.onloadend = async function (event: any) {
        if (!event.target.result) return;
        const successImages = event.target.result;
        const imageString = await resizeImage({
          base64Str: successImages,
          maxWidth: 1200,
          maxHeight: 6300,
        });
        const image = [imageString];

        const parts = successImages[0].split(";");
        const mime = parts[0].split(":")[1];
        const tempName = parts[1]?.split("=")[1]; //fileImgInputRef?.current?.value?.split(".com")?.[0] + ".jpg"; //parts[1].split("=")[1];

        const formData = {
          mime,
          name: formInputs.imageFileName || "testname.jpg",
          image: image[0],
        };

        return await Axios.post(uploadImgUrl, formData)
          .then((res: any) => {
            setDirectMessages((existing: any) => {
              return removeDuplicates([...existing, ...data]);
            });
            setSelectedFile(false);
            setLoading(false);
            if (inputImg.files[0]) inputImg.files[0] = "";

            if (imgDisplay.current.src) imgDisplay.current.src = "";

            return "done";
          })
          .catch((err) => {
            console.log("ImageError", err);
          });
      };
    }
  };

  const resizeImage = ({ base64Str, maxWidth = 400, maxHeight = 350 }: any) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  };
  // var inputVideo: any = document.getElementById("inputVideoFile");

  useEffect(() => {
    if (inputImg?.files[0]) {
      var fReader = new FileReader();
      fReader.readAsDataURL(inputImg.files[0]);
      fReader.onloadend = function (event: any) {
        var img: any = document.getElementById("yourImgTag");
        img.src = event.target.result;
      };
      if (inputImg?.files[0]?.name) {
        const name = `vare_direct_messages_${myInfo?.handle}_${Date.now()}_${inputImg?.files[0]?.name
          }`;
        setFormInputs({
          ...formInputs,
          imageFileName: name,
        });
      }
    }
  }, [inputImg?.files[0]]);

  return (
    <div style={{}}>
      <div
        style={{
          margin: 2,
          borderRadius: 10,
          backgroundColor: "#f2f3f5",
          flexDirection: "row",
          display: "flex",
          width: "100%",
          paddingBottom: 0,
          position: "fixed",
          left: 0,
          bottom: 0,
          // marginBottom:20
          // height: '90%',
        }}
      >
        <div
          className="main-chat-footer"
          style={{
            display: "flex",
            bottom: 0,
            position: "fixed",
            width: "100%",
            paddingBottom: 0,
          }}
        >
          <span>
            <span>
              {selectedFile && (
                <i
                  onClick={() => {
                    setSelectedFile(false);
                  }}
                  className="fe fe-trash"
                  style={{
                    paddingRight: 4,
                  }}
                ></i>
              )}
              <img
                style={{
                  display: selectedFile ? "block" : "none",
                }}
                ref={imgDisplay}
                id="yourImgTag"
                src={""}
                className="sign-favicon ht-40"
                alt="logo"
              />
            </span>
            {myInfo?.img ? (
              <img
                src={myInfo?.img}
                height={40}
                width={40}
                style={{
                  borderRadius: 100,
                }}
              />
            ) : (
              <svg
                width={30}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="#ffd43e"
              >
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM96.8 314.1c-3.8-13.7 7.4-26.1 21.6-26.1H393.6c14.2 0 25.5 12.4 21.6 26.1C396.2 382 332.1 432 256 432s-140.2-50-159.2-117.9zM144.4 192a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
              </svg>
            )}
          </span>
          <input
            ref={textInput}
            onChange={(e) => {
              setMyMessage(e.target.value);
            }}
            className="form-control"
            placeholder="Type your message here..."
            type="text"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loading && <div>Loading...</div>}
            <input
              id="inputFile"
              ref={fileImgInputRef}
              type="file"
              name="file"
              style={{
                display: "none",
              }}
              accept="image/*"
              onChange={(event: any) => {
                const file = event.target.files[0];
                setSelectedFile(file);
              }}
            />
          </div>
          <i
            onClick={() => {
              fileImgInputRef.current.click();
            }}
            className="fe fe-paperclip"
            style={{
              paddingRight: 4,
            }}
          ></i>

          <ButtonL
            className="main-msg-send"
            onClick={() => {
              submitForm();
            }}
          >
            Send
          </ButtonL>
        </div>
      </div>
    </div>
  );
};

export default DMInputField;
