import React, { ReactComponentElement, ReactElement } from 'react';
import { useDrop } from 'react-dnd';
import { Card } from './Card';
import { styled } from './stitches.config';
import { TitleInput } from './TitleInput';

type ColumnProps = {
  id: string | number | null;
  title: string;
  items: any[];
  onColumnChange: Function;
  onNewItem?: Function;
  // children?: any;
};

export const Column = (props: any) => {
  const { id, title, items, onColumnChange, onNewItem, children,
    editItem,
    setEditItem } = props;

  console.log('vvvv', items)

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'card',
    drop: (item: any, monitor) => {
      onColumnChange(item.id, id);
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <StyledColumn
      ref={drop}
      layout={{
        '@initial': 'sm',
        '@xs': 'sm',
      }}
    >
      <StyledColumnTitle style={{
        fontSize: 14
      }}>
        {title} ({items.length})
      </StyledColumnTitle>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {items.map((item: any) => {
          return (<>
            <Card item={item} key={item.id}
              column={id}
              editItem={editItem}
              setEditItem={setEditItem}
            />
            {item?.column == id && item?.id == editItem?.id && <div style={{
              display: 'flex',
              borderWidth: 2,
              borderColor: 'red',
              borderStyle: 'solid'
            }}>
              {/* <TitleInput onSubmit={(title: string) => onNewItem?.(title)} /> */}
              {children({ ...item, column: id })}
            </div>}
          </>
          )
        }
        )}
      </div>
      {canDrop && `Drop it ${isOver ? 'here!' : ''}!`}
      {id == null && <>

        <div style={{
          display: 'flex',
          borderWidth: 2,
          borderColor: 'red',
          borderStyle: 'solid'
        }}>
          {/* <TitleInput onSubmit={(title: string) => onNewItem?.(title)} /> */}
          {children}
        </div></>}
    </StyledColumn>
  );
};


export const StyledColumn = styled('div', {
  border: '1px dashed gray',
  margin: '1rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 250,

  variants: {
    layout: {
      sm: {
        minWidth: 250,
      },
    },
  },
});

export const StyledColumnTitle = styled('h2', {
  marginTop: 0,
});