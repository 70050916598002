import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RESTCall from "../restApi";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

import DatePicker from "react-multi-date-picker";

// import { useStyles } from '../components/styles'

// const classes = useStyles()
import classes from "./styles";
import moment from "moment";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
  Radio,
} from "@mui/material";
import {
  daysOfGivenWeek,
  getDayOfWeek,
  getDaysInMonth,
  weekDays,
} from "src/helpers/dateFunctions";
import {
  deleteAgendaDB,
  getAgendaDB,
  getCalendarEvents,
  getDataDB,
  myColors,
  resizeImage,
  saveAgendaDB,
  saveEventDB,
} from "src/api";
import { UserContext } from "src/App";
import axios from "axios";
import { CalendarCardCreate } from "./CalendarCardCreate";
import { ButtonL } from "./AppIndex";
import { useParams } from "react-router-dom";
import {
  AddSkillSection,
  InputAgenda,
  SkillChildWrapper,
} from "./MediaSlider/TabListItems";
import {
  Delete,
  ExpandMore,
  RemoveCircleOutline,
  Sync,
} from "@mui/icons-material";
import { Direction } from "react-toastify/dist/utils";
import styled from "@emotion/styled";
import FormTypeCreationOptions from "./FormTypeCreationOptions";

export const MenuContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const FormTypeCreate = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const [usersBookedDates, setUsersBookedDates] = useState<any>([]);
  const {
    myInfo,
    myInfo: { active = "2" },
  } = myContext;
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const {
    handleInfo,
    formEditType,
    formEntities,
    setFormEntities,
    formInput = {},
    setFormInput,
  } = props;
  // const [formInput, setFormInput] = useState<any>([]);

  useEffect(() => {
    const formData = {
      label: "",
      placeholder: "",
      required: false,
      type: "text",
      name: "NA",
      value: "NA",
      ...(formEditType == "radio" ||
        formEditType == "select" ||
        formEditType == "multiple"
        ? {
          options: [
            {
              label: "add option1",
            },
            {
              label: "add option2",
            },
            {
              label: "add option3",
            },
            {
              label: "add option4",
            },
            {
              label: "add option5",
            },
          ],
        }
        : {}),
    };
    setFormInput(formData);
    handleInfo(formData);
    setRefreshing(false);
  }, []);
  // }, [formEditType, refreshing]);

  // useEffect(() => {
  //   handleInfo(formInput);
  // }, [formInput]);

  const removeFormEntity = (index: number) => {
    setFormEntities((prevFormEntities: any) => {
      const newFormEntities = [...prevFormEntities];
      newFormEntities.splice(index, 1);
      return newFormEntities;
    });
  };

  const handlePlaceholderChange = (index: any, newValue: any) => {
    setFormEntities((prevFormTypes: any) => {
      const newFormTypes = [...prevFormTypes];
      newFormTypes[index].placeholder = newValue;
      return newFormTypes;
    });
  };
  const handleLabelChange = (index: any, newValue: any) => {
    setFormEntities((prevFormTypes: any) => {
      const newFormTypes = [...prevFormTypes];
      newFormTypes[index].label = newValue;
      return newFormTypes;
    });
  };
  const handleOptionChange = (index: any, newValue: any) => {
    setFormEntities((prevFormTypes: any) => {
      const newFormTypes = [...prevFormTypes];
      newFormTypes[index].options = [...newFormTypes[index].options, newValue];
      return newFormTypes;
    });
  };
  const handleOptionRemove = (index: any, optIndex: any) => {
    setFormEntities((prevFormTypes: any) => {
      const newFormTypes = [...prevFormTypes];
      newFormTypes[index].options = newFormTypes[index].options.filter(
        (_: any, indexParam: any) => indexParam !== optIndex
      );
      return newFormTypes;
    });
  };

  const handleOptionEdit = (index: any, newValue: any, optionIndex: any) => {
    setFormEntities((prevFormTypes: any) => {
      const newFormTypes = [...prevFormTypes];
      newFormTypes[index].options[optionIndex] = newValue;
      return newFormTypes;
    });
  };

  const handleIsRequiredChange = (index: any, newValue: any) => {
    setFormEntities((prevFormTypes: any) => {
      const newFormTypes = [...prevFormTypes];
      newFormTypes[index].isRequired = newValue;
      return newFormTypes;
    });
  };
  const handleSameAsLabelChange = (index: any, newValue: any) => {
    setFormEntities((prevFormTypes: any) => {
      const newFormTypes = [...prevFormTypes];
      newFormTypes[index].sameAsLabel = newValue;
      return newFormTypes;
    });
  };

  return (
    <>
      <FormWrapper
        style={{ display: "flex", flexDirection: "column", gap: 10 }}
        onSubmit={(e) => e.preventDefault()}
      >
        {formEntities?.map((formEntity: any, index: any) => (
          <div key={index}>
            {(formEntity.name == "input" ||
              formEntity.name == "number" ||
              formEntity.name == "file" ||
              formEntity.name == "geolocation" ||
              formEntity.name == "date") && (
                <InputWrapperParent>
                  <InputWrapper>
                    <InputLabel
                      type="text"
                      onChange={(e: any) => {
                        if (formEntity.sameAsLabel) {
                          handlePlaceholderChange(index, e.target.value);
                        }
                        handleLabelChange(index, e.target.value);
                      }}
                      placeholder={"Click and add Lable*"}
                    />

                    <InputPlaceHolder
                      type={
                        formEntity?.name == "input" ||
                          formEntity?.name == "geolocation"
                          ? "text"
                          : formEntity?.name == "number"
                            ? "number"
                            : formEntity?.name == "file"
                              ? "file"
                              : "date"
                      }
                      value={
                        formEntity.sameAsLabel
                          ? formEntity.label
                          : formEntity.placeholder
                      }
                      onChange={(e: any) => {
                        if (formEntity?.name == "input") {
                          if (formEntity.sameAsLabel) {
                            handlePlaceholderChange(index, formEntity.label);
                          } else {
                            handlePlaceholderChange(index, e.target.value);
                          }
                        }
                      }}
                      placeholder={"Add Place holder (Optional)"}
                    />

                    <LableSameAsLableWrapper>
                      {formEntity?.name == "input" && (
                        <LableSameAsLable htmlFor={`same_as_lable_${index}_`}>
                          Same as Lable
                          <input
                            type="checkbox"
                            name={`same_as_lable_${index}_`}
                            id={`same_as_lable_${index}_`}
                            style={{ width: 30 }}
                            onChange={(e: any) =>
                              handleSameAsLabelChange(index, e.target.checked)
                            }
                            checked={formEntity.sameAsLabel}
                          />
                        </LableSameAsLable>
                      )}
                      <LableSameAsLable htmlFor={`required_${index}_`}>
                        Required
                        <input
                          type="checkbox"
                          name={`required_${index}_`}
                          id={`required_${index}_`}
                          style={{ width: 30 }}
                          onChange={(e) =>
                            handleIsRequiredChange(index, e.target.checked)
                          }
                          checked={formEntity.isRequired}
                        />
                      </LableSameAsLable>
                    </LableSameAsLableWrapper>
                  </InputWrapper>
                  <ButtonL
                    onClick={() => removeFormEntity(index)}
                    style={{ margin: 0, maxWidth: 60 }}
                  >
                    <Delete />
                  </ButtonL>
                </InputWrapperParent>
              )}
            {(formEntity?.name == "radio" ||
              formEntity?.name == "select" ||
              formEntity?.name == "multiple") && (
                <InputWrapperParent>
                  <InputWrapper>
                    <InputLabel
                      type="text"
                      onChange={(e: any) => {
                        if (formEntity.sameAsLabel) {
                          handlePlaceholderChange(index, e.target.value);
                        }
                        handleLabelChange(index, e.target.value);
                      }}
                      placeholder={"Click and add Lable*"}
                    />
                    <FormTypeCreationOptions
                      formInput={formEntity}
                      handleOptionChange={handleOptionChange}
                      handleOptionEdit={handleOptionEdit}
                      index={index}
                      handlePlaceholderChange={handlePlaceholderChange}
                      name={formEntity?.name}
                      sameAsLable={formEntity.sameAsLabel}
                      handleOptionRemove={handleOptionRemove}
                    />

                    <LableSameAsLableWrapper>
                      {formEntity?.name !== "radio" && (
                        <LableSameAsLable htmlFor={`same_as_lable_${index}_`}>
                          Same as Lable
                          <input
                            type="checkbox"
                            name={`same_as_lable_${index}_`}
                            id={`same_as_lable_${index}_`}
                            style={{ width: 30 }}
                            onChange={(e: any) =>
                              handleSameAsLabelChange(index, e.target.checked)
                            }
                            checked={formEntity.sameAsLabel}
                          />
                        </LableSameAsLable>
                      )}
                      <LableSameAsLable htmlFor={`required_${index}_`}>
                        Required
                        <input
                          type="checkbox"
                          name={`required_${index}_`}
                          id={`required_${index}_`}
                          style={{ width: 30 }}
                          onChange={(e) =>
                            handleIsRequiredChange(index, e.target.checked)
                          }
                          checked={formEntity.isRequired}
                        />
                      </LableSameAsLable>
                    </LableSameAsLableWrapper>
                  </InputWrapper>
                  <ButtonL
                    onClick={() => removeFormEntity(index)}
                    style={{ margin: 0, maxWidth: 60 }}
                  >
                    <Delete />
                  </ButtonL>
                </InputWrapperParent>
              )}

          </div>
        ))}
      </FormWrapper>
    </>
  );
};

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputLabel = styled.input`
  border: none;
  background: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  &::placeholder {
    color: black;
  }
`;
const InputPlaceHolder = styled.input`
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  outline: none;
  background-color: white;
  width: 100%;
`;
const InputWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const LableSameAsLable = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  input {
    max-width: 20px;
    width: 100%;
  }
`;
const LableSameAsLableWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
`;
const InputWrapperParent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
