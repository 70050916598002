import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getDataWildArrayDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  getUserInfoByHandle,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  UploadFile,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { MyFileUploader } from "./MyFileUploader";
// import { IconButton } from 'material-ui';

// const myEventColabs = async ({ handle, resource }: any) => {
//   return await getDataDB({
//     query: {
//       handle: handle
//     },
//     resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
//     check: ["handle"],
//   })
//     .then(async (res) => {
//       // console.log("hostTabsxxccnn", res);
//       if (res?.[0]?.handles) {
//         return await Promise.all(res?.[0]?.handles?.map(async (hand: any) => {
//           return await getUserInfoByHandle(hand)
//         }))
//       }
//     })
// }

export const MyColabTabListItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo, type, resource, editButton } = props;
  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { myInfo, host, eventInfo, eventId, hostTabs, allAccess } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const hostId = useMemo(() => {
    return params?.hostId;
  }, [params?.hostId]);

  useEffect(() => {
    (async () => {
      // setFormInput({
      //   ...formInput,
      //   ...(eventTab
      //     ? {
      //       eventId: eventId,
      //     }
      //     : { handle: host?.handle }),
      // });

      //events I am admin to
      const collaborators: any = await getDataDB({
        query: {
          handle: host?.handle,
        },
        resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
        check: ["handle"],
      }).then(async (event) => {
        // console.log("hostTabsxxccnn", res);
        if (!event?.[0]?.handles) {
          return;
        }
        return await Promise.all(
          event?.[0]?.handles?.map(async (hand: any) => {
            return await getUserInfoByHandle(hand).then((user: any) => {
              console.log("hostTabsxxccnn", user);

              if (!user) {
                return {};
              }
              return {
                ...user,
                eventInfo: event,
                eventId: event?.[0]?._id,
                title: user?.name,
                description: event?.[0]?.title,
                handle: user?.handle,
                link: `/host/${user?.handle}/about`,
                role: "collaborator",
              };
            });
          })
        );
      });

      // const report = collaborators

      //events I am a collaborator
      const myColabs: any = await getDataWildArrayDB({
        key: "handles",
        request: "wildsearchArray",
        searchVal: host?.handle,
        resource: resource,
      }).then(async (event) => {
        if (!event?.[0]?.handles) {
          return;
        }
        return await Promise.all(
          event?.[0]?.handles?.map(async (hand: any) => {
            return await getUserInfoByHandle(hand).then((user: any) => {
              if (!user) {
                return {};
              }
              return {
                ...user,
                eventInfo: event,
                eventId: event?.[0]?._id,
                title: user?.name,
                description: event?.[0]?.title,
                handle: user?.handle,
                link: `/host/${user?.handle}/about`,
                role: "collaborator",
              };
            });
          })
        );
      });

      // return [
      //   {
      //     ...host,
      //     role: 'admin',
      //   },
      //   ...result
      // ] || []
      console.log("myColabs", collaborators, myColabs);

      const result = [...(collaborators || []), ...(myColabs || [])];
      setDataRecord(result?.[0] ? result : []);
      setRefreshing(false);
      setFormInput({});
    })();
  }, [
    eventTab,
    myInfo?.handle,
    host?.handle,
    params.eventId,
    params.hostId,
    refreshing,
  ]);

  useEffect(() => {
    // console.log("addType", addType);
  }, [addType]);

  const myData = useMemo(() => {
    return [
      ...dataRecord
        ?.filter((res: any) => res.title != formInput?.title)
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title,
            // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          };
        }),
    ];
  }, [dataRecord]);

  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    // Define allowed file types for images and videos
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

    if (
      file &&
      (allowedImageTypes.includes(file.type) ||
        allowedVideoTypes.includes(file.type)) &&
      file.size <= 2097152
    ) {
      setFile(file);
      setFormInput({
        ...formInput,
        file,
      });
      setAddType({
        ...addType,
        file,
      });
    } else {
      alert("Please upload an image or video file smaller than 2MB.");
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];

    // Define allowed file types for images and videos
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

    if (
      file &&
      (allowedImageTypes.includes(file.type) ||
        allowedVideoTypes.includes(file.type)) &&
      file.size <= 2097152
    ) {
      setFile(file);
      setFormInput({
        ...formInput,
        file,
      });
      setAddType({
        ...addType,
        file,
      });
    } else {
      alert("Please upload an image or video file smaller than 2MB.");
    }
  };

  console.log("editButton", myData);

  return (
    <>
      {/* {editInfo && <>
        <AddNewContent
          {...props}
          setFormInput={setFormInput}
          formInput={formInput}
          setRefreshing={setRefreshing}
          refreshing={refreshing}
        />
        <ButtonL
          style={{
            margin: 0,
            marginTop: 20
          }}
          onClick={() => {
            setUploadNow(true);
            const data = {
              query: {
                ...formInput,
                eventId: eventId || params.eventId,
                handle: myInfo?.handle
              },
              resource: resource,
              check:
                // formInput?._id ? ["_id"] :
                ["handle", "title"],
              myInfo,
            };
            // console.log('formData', data)
            saveDataDB(data).then((res) => {
 //setRefreshing(true);
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  editInfo: false,
                };
              });
            });
          }}
        >
          {"Save"}
        </ButtonL>
      </>} */}
      <div>
        <div
          style={{
            paddingTop: 50,
          }}
        >
          {formInput?.title ||
            (myData?.length > 0 && (
              <InputAgenda>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: 300,
                  }}
                >
                  <input
                    onClick={() => {
                      setFormInput({
                        ...formInput,
                        view: "list",
                      });
                    }}
                    type="radio"
                    id="view1"
                    name="view"
                    value="list"
                    {...(formInput?.view == "list" || !formInput?.view
                      ? { checked: true }
                      : {})}
                  />
                  <label>List</label>
                  <br />
                  <input
                    onClick={() => {
                      setFormInput({
                        ...formInput,
                        view: "card",
                      });
                    }}
                    type="radio"
                    id="view2"
                    name="view"
                    value="card"
                  />
                  <label>Cards</label>
                  <br />
                  <input
                    onClick={() => {
                      setFormInput({
                        ...formInput,
                        view: "carroussel",
                      });
                    }}
                    type="radio"
                    id="view2"
                    name="view"
                    value="card"
                  />
                  <label>Carroussel</label>
                  <br />
                </form>
              </InputAgenda>
            ))}
          {formInput?.view == "card" ? (
            <LinkParent>
              {myData?.map((res) => {
                return (
                  <LinkC
                    href={"#"}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <svg
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_55_8225)">
                        <rect width="80" height="80" rx="6" fill="#404040" />
                        <path
                          d="M60.8334 40C60.8334 28.5 51.5001 19.1667 40.0001 19.1667C28.5001 19.1667 19.1667 28.5 19.1667 40C19.1667 50.0833 26.3334 58.4792 35.8334 60.4167V46.25H31.6667V40H35.8334V34.7917C35.8334 30.7708 39.1042 27.5 43.1251 27.5H48.3334V33.75H44.1667C43.0209 33.75 42.0834 34.6875 42.0834 35.8333V40H48.3334V46.25H42.0834V60.7292C52.6043 59.6875 60.8334 50.8125 60.8334 40Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_55_8225">
                          <rect width="80" height="80" rx="6" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div>{res?.title}</div>
                  </LinkC>
                );
              })}
            </LinkParent>
          ) : formInput?.view == "carroussel" ? (
            <CarrousselCard
              onViewClick={(item: any) => {
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    action: false,
                    storyResource: {
                      ...props?.tab,
                      myNews: [item]

                    }
                  };
                });
              }}
              // host={host}
              // eventId={eventId}
              subDisplay={"description"}
              // searchKeywordAttendees={searchKeywordAttendees}
              people={myData}
              // handleInfo={(info: any) => { }}
              // setLocalAction={setLocalAction}
              isSmall={true}
            />
          ) : (
            <ListItems
              editButton={
                // allAccess ? true :
                false
              }
              {...props}
              display={"title"}
              subDisplay={"description"}
              source={
                //"files"
                eventTab
              }
              // model={hostTabs['model']}
              formInput={formInput}
              items={myData || []}
              // setTagType={setTagType}
              // tagType={tagType}
              handleSetItem={(item: any) => {
                // setEditFile({
                //   ...item,
                // });
                // alert(item?._id)
                setFormInput({
                  ...formInput,
                  ...item,
                });
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    editInfo: true,
                  };
                });
              }}
              handleDeleteItem={(item: any) => {
                deleteDataDB({
                  id: item?._id,
                  resource: resource,
                  // resource: `calendar_${params.eventId ? "event" : "host"
                  // }_${eventTab}`,
                }).then((rep: any) => {
                  alert("Deleted");
                  //setRefreshing(true);
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      editInfo: false,
                    };
                  });
                });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const AddNewContent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    editInfo,
    resource,
    setFormInput,
    formInput,
    setRefreshing,
    refreshing,
  } = props;
  // const { handleInfo } = props;
  const params: any = useParams<any>();
  const navigate = useNavigate();
  // const [refreshing, setRefreshing] = useState<boolean>(false);
  // const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [uploadNow, setUploadNow] = useState<boolean>(false);

  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    hostId,
    cart = [],
    selectedEvent,
    people,
    eventTabs = [],
  } = myContext;

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);
  return (
    <>
      <div>Title</div>
      <div>
        <InputAgenda onSubmit={(e: any) => { }}>
          <input
            type={"text"}
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                title: e.target?.value,
              })
            }
            value={formInput?.title || ""}
            placeholder={"Add title ..."}
            required
          />
          {!(
            addType?.file ||
            addType?.link ||
            formInput?.file ||
            formInput?.link
          ) && (
              <div
                onClick={() => {
                  const data = {
                    query: {
                      ...formInput,
                      eventId: eventId,
                    },
                    resource: resource,
                    check: formInput?._id ? ["_id"] : ["handle", "title"],
                    myInfo,
                  };
                  // console.log('formData', data)
                  saveDataDB(data).then((res) => {
                    //setRefreshing(true);
                  });
                }}
              >
                <Add />
              </div>
            )}
        </InputAgenda>
        <div>Description</div>
        <InputAgenda onSubmit={(e: any) => { }}>
          <input
            type={"text"}
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                description: e.target?.value,
              })
            }
            value={formInput?.description || ""}
            placeholder={"Add description ..."}
            required
          />
        </InputAgenda>
        <InputAgenda>
          <MyFileUploader
            {...props}
            refreshing={refreshing}
            setRefreshing={setRefreshing}
            setFormInput={setFormInput}
            formInput={formInput}
            uploadNow={uploadNow}
            handleSaveImage={(e: any) => {
              setUploadNow(true);
            }}
          />
        </InputAgenda>
        <InputAgenda>
          <input
            type={"text"}
            onChange={(e: any) =>
              setFormInput({
                ...formInput,
                link: e.target?.value,
              })
            }
            value={formInput?.link || ""}
            placeholder={"Add Link ..."}
            required
          />
        </InputAgenda>
      </div>
    </>
  );
};

export const InputAgenda = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
  input {
    outline: none;
    border: none;
    font-size: 14px;
    width: 100%;
  }
  button {
    border: none;
    background: transparent;
    outline: none;
    color: gray;
    transition: color 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #dee2e6;
    }
  }
`;

const LinkParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
`;

const AddSkillSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

const UploadedResume = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  p {
    font-size: 12px;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    width: 15px;
    height: 100%;
    color: #479d83;
  }
  button {
    border: 1px solid #479d83;
    padding: 3px 8px;
    background: #fff;
    font-size: 12px;
    transition: background 0.3s;
    margin: 0;
    &:hover {
      background: #479d832b;
    }
  }
`;

const UploadResumeFile = styled.label`
  background: #479d832b;
  border: 1px dashed gray;
  width: 100%;
  padding: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 10px;
    color: gray;
    margin: 0;
  }
  svg {
    width: 40px;
    color: #479d83;
    height: 100%;
  }
`;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
