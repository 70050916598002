import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";
import QRCode from "qrcode.react";
import ReactTooltip from "react-tooltip";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Select from "react-select";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Slider,
  SvgIcon,
  Icon,
  Radio,
  Avatar,
  Grid,
  Menu,
  CardActionArea,
} from "@mui/material";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  capitalizeInfo,
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  getUserInfoByHandle,
  handleAISearch,
  jumpToReleventDiv,
  myColors,
  relatedPict,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events, { RR } from "../Events";
import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  ArrowBack,
  ArrowBackIos,
  ArrowDownward,
  AssignmentInd,
  Attachment,
  Camera,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  Edit,
  EditCalendar,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  Fullscreen,
  FullscreenExit,
  GroupAdd,
  HighlightOff,
  Home,
  InsertDriveFile,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  LockOpen,
  LockPerson,
  NewReleases,
  PlusOne,
  Preview,
  QrCode as QRIcon,
  Quiz,
  RadioButtonUnchecked,
  RemoveCircleOutline,
  Settings,
  Share,
  ShoppingBag,
  Sync,
  WebSharp,
  WebStories,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { AddSkillSection, TabListItems } from "./TabListItems";
import { AboutTabListItems, SpecialListings } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
import { AdvertCarroussel } from "./AdvertCarroussel";
import { GuestRoleManagerModal } from "./GuestRoleManagerModal";
import { AdvertListItems } from "./AdvertListItems";
import { MyTabListItems } from "./MyTabListItems";
import { SubscriptionListItems } from "./SubscriptionListItems";
import { BenchmarkListItem } from "./BenchmarkListItem";
import { CollaboratorComponent } from "./CollaboratorComponent";
import { EventFormList } from "../EventFormList";
import { EventSkillsDetails } from "./EventSkillsDetails";
import { EventFormCreator } from "../EventFormCreator";
import WorldMap from "../WorldMap";
import { CatalogItems } from "./CatalogItems";
import { EndorsementEdit } from "../EndorsementEdit";
import { EndorsementReview } from "../EndorsementReview";
import { CatalogServices } from "./CatalogServices";
import { CatalogListings } from "./CatalogListings";
import { CatalogProducts } from "./CatalogProducts";
import { CatalogRequest } from "./CatalogRequest";
import { MembersComponent } from "./MembersComponent";
import { AttendeesComponent } from "./AttendeesComponent";
import { FAQ } from "./Faqs";
import { AssetTracker } from "./AssetTracker";
import { EmailEditorComponent } from "./EmailEditorComponent";
import { EventKeySearch, SearhKeywordWrapper } from "./UserProfileModal";
import { WatchersComponent } from "./WatchersComponent";
import { ResourceTree } from "../ResourceTree";
import { Brainstorm } from "./Brainstorm";
import { EventFunnel } from "../EventFunnel";
import FormReportTable from "../FormReportTable";
import ImportFormReportTable from "../ImportFormReportTable";
import Tasks from "./Tasks";
import KanbanTask from "./KanbanTask";
import { SubscriptionItems } from "./SubscriptionItems";
import { UserJourney } from "./UserJourney";
import { UserStoriesCreate } from "./UserStoriesCreate";
import { SignDocumentsItems } from "./SignDocumentsItems";
import { Agreements } from "./Agreements";
import DigitalContract from "./DocusigAgrement";
import { Policies } from "./Policies";
import MobMenu from "../MobMenu";
import { ActionIcon, ActionIconsWrapper, EventBannerComponent } from "../GuestPage/UserProfileModal";
import { UserStoriesGeneral } from "./UserStoriesGeneral";
// import { UserStories } from "./UserStories";
import { HostFunnel } from "../HostFunnel";
import Background from "src/modules/room/modules/board/components/Background";
import { PlanningKanbanComponent } from "../PlanningKanbanComponent";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import StoryTemplate from "./StoryTemplate";
import { VideoEditor } from "./VideoEditor";
import { WebsiteListItems } from "./WebsiteListItems";
import Guestsite from "../Guestsite";
import MobMenuSearch from "../MobMenuSearch";
import { title } from "process";
import PaymentSetupPage from "../PaymentSetupPage";
import Kiosk from "../Kiosk";
import { TrainingSkillsTabList } from "./TrainingSkillsTabList";
// import { Settings } from "@mui/material";

// import { SignedDocumentsItems } from "./SignedDocumentsItems";
// import { IconButton } from 'material-ui';
export const handleInfo = async (props: any) => {
  const { info, myInfo } = props;
  // const info = data; //?.handle ? host : myInfo;
  // console.log("info in guest", info);
  if (!info) {
    return;
  }
  const links = await getLinksDB({
    handle: info?.handle,
  });

  const skills = await getSkillsDB({
    handle: info?.handle,
  });

  const contacts = await getContactsDB({
    handle: myInfo?.handle,
  });

  const shares = await getShareDB({
    handle: myInfo?.handle,
  });

  return {
    links,
    skills,
    user: info,
    contacts,
    shares,
  };
};

export const EventEditor = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    eventInfo = {},
    allAccess,
    fullScreen = false,
    openCreateEvent
  } = myContext;
  // const { handleInfo } = props;
  const params: any = useParams<any>();
  const { host } = myContext;
  const navigate = useNavigate();

  const [showDropdownAssign, setShowDropdownAssign] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [members, setMembers] = useState<any>([]);

  const [formQuestion, setFormQuestion] = useState<any>("");
  const [searchNow, setSearchNow] = useState<boolean>(false);

  // function for the Tabs Filtering below

  // const [editInfo, setEditInfo] = useState<boolean>(false);
  const [formInput, setFormInput] = useState<any>({});
  const {
    searchKeywordEvents,
    setSearchKeywordEvents,
    searchKeywordAttendees,
    setSearchKeywordAttendees,
    setLocalAction,
    showBanner,
    setShowBanner,
  } = props;

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);


  const location = useLocation();

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const resource = useMemo(() => {
    const tab = eventTab || hostTab;
    const subTab = eventSubTab || hostSubTab;
    return `calendar_${params.eventId ? "event" : "event"}${tab ? "_" + tab : ""
      }${subTab ? "_" + subTab : ""}`;
  }, [eventTab, eventSubTab, hostSubTab, hostTab, params?.eventTab]);

  // console.log('eventDetailsTabhh', host)
  const [newArray, setNewArray] = useState<any>([]);
  const [newArrayAssign, setNewArrayAssign] = useState<any>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [menuOptions, setMenuOptions] = useState<any>([]);

  function removeScript(id: any) {
    const script: any = document.getElementById(id);
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  useEffect(() => {
    for (let i = 0; i < 19; i++) {
      removeScript(`script${i}`)
    }
    setMyContext((existing: any) => {
      return {
        ...existing,
        editInfo: false,
      };
    });
    getInfo();
  }, [eventId]);

  const getInfo = useCallback(() => {
    if (eventId) {
      getDataDB({
        query: {
          _id: eventId,
        },
        resource: "calendar_events",
      }).then((res) => {
        // console.log('våvvvvvvggvvv', res)
        const info = res?.[0];

        getDataDB({
          query: {
            handle: info?.handle,
          },
          resource: `calendar_users`,
        }).then((res) => {
          if (res?.[0]) {
            // console.log("vvvvvvvgg", info);
            setNewArray(info?.hiddenTabs);
            setMyContext((existing: any) => {
              return {
                ...existing,
                host: res?.[0],
                eventInfo: info,
                // myInfo: {
                //   ...myInfo,
                //   eventInfo: info
                // }
              };
            });
          }
        });
      });

      getDataDB({
        query: {
          eventId: eventId,
        },
        resource: "calendar_members_record",
      }).then(async (res) => {
        const users = await Promise.all(
          res?.map((rag: any) => {
            return getUserInfoByHandle(rag?.handle);
          })
        );
        // console.log("våvvvvvvggvvveventId", users);
        setMembers(users || []);
      });
    }
    if (eventTab == "detail" && !eventSubTab) {
      navigate(`/event/${eventId}/${eventTab}/event`);
    }
  }, [host, eventTab, eventSubTab, eventId, refreshing]);

  // console.log("vvvvvvvgg", eventInfo);

  const loadFilter = ({ newArray, eventId, eventInfo }: any) => {
    if (eventId && myInfo?.handle && allAccess) {
      // console.log("newArray", newArray);
      const newFormInput: any = {
        hiddenTabs: newArray,
        eventId: eventId,
        handle: myInfo?.handle,
      };
      const data = {
        query: {
          ...newFormInput,
        },
        resource: `calendar_events`,
        check: ["handle", "eventId"],
        myInfo,
      };
      // console.log("FormFields", data);
      saveDataDB(data).then((res) => {
        // setRefreshing(true);
        getInfo();
      });
    }
  };

  useEffect(() => {
    if (formQuestion?.length > 0) {
      navigate(`/event/${eventId}/schedule`);

      setOpenMenu(true);
      const menuList = eventTabs?.map((res: any) => res.name);
      const tempMatch = menuList?.filter((res: any) =>
        res?.toLowerCase()?.includes(formQuestion?.toLowerCase())
      );
      setMenuOptions(tempMatch);
    } else {
      setMenuOptions([]);
    }
    if (formQuestion?.length > 0 && searchNow) {
      handleAISearch({ question: "I want to create an advertisement" }).then(
        (res: any) => {
          // console.log("mmmmnbbbbb", res);
        }
      );
    }
  }, [searchNow, formQuestion]);

  //define all access code
  useLayoutEffect(() => {
    const access =
      (myInfo?.handle && myInfo?.handle == eventInfo?.handle) ||
      myInfo?.handle == host?.handle ||
      eventInfo?.handles?.includes(myInfo?.handle) || myInfo?.handle == "@mplaunch";
    // console.log("vvvvxxbbbzzz", host, eventInfo, access);
    setMyContext((existing: any) => {
      return {
        ...existing,
        allAccess: access ? true : false,
      };
    });
  }, [host?.handle, eventInfo?.handle, eventInfo?.handles, myInfo?.handle]);

  useEffect(() => {

    if (eventInfo?.handle == myInfo?.handle && eventTab == 'edit') {
      setMyContext((existing: any) => {
        return {
          ...existing,
          openCreateEvent: true,
        };
      });
    }

  }, [
    myInfo?.handle,
    eventInfo?.handle,
    eventTab
  ])





  return (
    <>
      {eventInfo?.private &&
        !eventInfo?.handles?.includes(myInfo?.handle) &&
        eventInfo?.handle != myInfo?.handle ? (
        <div>
          Sorry but this event is Private. Please contact the event host to add
          you as a collaborator.
        </div>
      ) : (
        <>
          {fullScreen ? (
            <Modal
              show={fullScreen}
              fullscreen={true}
              onHide={() => {
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    fullScreen: false,
                  };
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  msFlexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "black",
                  padding: 4,
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    msFlexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    width: "100%",
                    // backgroundColor: 'red',
                  }}
                >
                  <h4>{capitalizeInfo(eventTab)}</h4>
                </div>

                <ButtonL
                  style={{
                    fontSize: "0.7em",
                    whiteSpace: "none",
                  }}
                  onClick={() => {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        fullScreen: false,
                      };
                    });
                  }}
                >
                  <FullscreenExit />
                  <div
                    style={{
                      whiteSpace: "none",
                    }}
                  >
                    {" "}
                    Show Banner
                  </div>
                </ButtonL>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 40,
                }}
              >
                <MainComponent
                  {...props}
                  setLocalAction={setLocalAction}
                  // localAction={localAction}
                  searchKeywordEvents={searchKeywordEvents}
                  setSearchKeywordEvents={setSearchKeywordEvents}
                  setFormInput={setFormInput}
                  formInput={formInput}
                  searchKeywordAttendees={searchKeywordAttendees}
                  setSearchKeywordAttendees={setSearchKeywordAttendees}
                  setMembers={setMembers}
                  members={members}
                  newArrayAssign={newArrayAssign}
                  setNewArrayAssign={setNewArrayAssign}
                  showDropdownAssign={showDropdownAssign}
                  setShowDropdownAssign={setShowDropdownAssign}
                  setOpenMenu={setOpenMenu}
                  menuOptions={menuOptions}
                  setNewArray={setNewArray}
                  newArray={newArray}
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
                  openMenu={openMenu}
                  eventTab={eventTab}
                  eventTabs={eventTabs}
                  eventInfo={eventInfo}
                  loadFilter={loadFilter}
                  eventId={eventId}
                  showBanner={showBanner}
                  setShowBanner={setShowBanner}
                />
              </div>
            </Modal>
          ) : (
            <MainComponent
              {...props}
              showBanner={showBanner}
              setShowBanner={setShowBanner}
              setLocalAction={setLocalAction}
              searchKeywordEvents={searchKeywordEvents}
              setSearchKeywordEvents={setSearchKeywordEvents}
              setFormInput={setFormInput}
              formInput={formInput}
              searchKeywordAttendees={searchKeywordAttendees}
              setSearchKeywordAttendees={setSearchKeywordAttendees}
              setMembers={setMembers}
              members={members}
              newArrayAssign={newArrayAssign}
              setNewArrayAssign={setNewArrayAssign}
              showDropdownAssign={showDropdownAssign}
              setShowDropdownAssign={setShowDropdownAssign}
              setOpenMenu={setOpenMenu}
              menuOptions={menuOptions}
              setNewArray={setNewArray}
              newArray={newArray}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              openMenu={openMenu}
              eventTab={eventTab}
              eventTabs={eventTabs}
              eventInfo={eventInfo}
              loadFilter={loadFilter}
              eventId={eventId}
            />
          )}
        </>
      )}
    </>
  );
};

const MainComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  const { host } = myContext;
  const navigate = useNavigate();
  const {
    localAction,
    setLocalAction,
    searchKeywordEvents,
    setSearchKeywordEvents,
    formInput,
    setFormInput,
    searchKeywordAttendees,
    setSearchKeywordAttendees,
    setMembers,
    members,
    setNewArrayAssign,
    newArrayAssign,
    showDropdownAssign,
    setShowDropdownAssign,
    setOpenMenu,
    menuOptions,
    openMenu,
    eventTab,
    showDropdown,
    loadFilter,
    setShowDropdown,
    setNewArray,
    newArray,
    showBanner = false,
    setShowBanner,
    mobMenu,
    setMobMenu = () => { },
  } = props;
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
    // host,
    // eventId,
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    eventInfo = {},
    allAccess,
    fullScreen = false,
    openMainMenu = false,
    storyResource,
    advanceMenu = false,
    mobMenuColor = {
      id: "development",
      label: "Development",
      title: "My Guest Calendar & Planner",
      description:
        "Share your availability with others. Manage contacts and todo list in your GuestNet Funnel",
      color: "red",
      img: `url("${relatedPict[8]}")`,
    },
  } = myContext;

  const location = useLocation();

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const [selectedItem, setSelectedItem] = useState<any>("");

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const adData = [
    {
      id: 1,
      title: "Summer Sale!",
      description: "Save big on summer essentials. Limited-time offers!",
      imageUrl:
        "https://images.pexels.com/photos/802024/pexels-photo-802024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      linkUrl: "https://example.com/special-offers",
    },
    {
      id: 2,
      title: "New Arrivals",
      description:
        "Explore our latest collection. Fresh arrivals just for you!",
      imageUrl:
        "https://images.pexels.com/photos/802024/pexels-photo-802024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      linkUrl: "https://example.com/new-arrivals",
    },
    {
      id: 3,
      title: "Back to School",
      description: "Get ready for school with our back-to-school deals.",
      imageUrl:
        "https://images.pexels.com/photos/802024/pexels-photo-802024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      linkUrl: "https://example.com/back-to-school",
    },
    // Add more ad objects as needed
  ];

  // const resource = useMemo(() => {
  //   const tab = eventTab || hostTab;
  //   const subTab = eventSubTab || hostSubTab;
  //   return `calendar_${params.eventId ? "event" : "event"}${tab ? "_" + tab : ""
  //     }${subTab ? "_" + subTab : ""}`;
  // }, [eventTab, eventSubTab, hostSubTab, hostTab, params?.eventTab]);

  // return null;
  // const [mobMenu, setMobMenu] = useState<boolean>(false);
  // const [openQRCode, setOpenQRCode] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<any>(false);


  // const [mobMenuColor, setMobMenuColor] = useState<any>();

  useEffect(() => {
    setMobMenu(openMainMenu);
    setMyContext((existing: any) => {
      return {
        ...existing,
        mobMenu: openMainMenu,
        mobMenuColor: {
          id: "development",
          label: "Development",
          title: "My Guest Calendar & Planner",
          description:
            "Share your availability with others. Manage contacts and todo list in your GuestNet Funnel",
          color: "red",
          img: `url("${relatedPict[8]}")`,
        },
      };
    });
  }, [openMainMenu]);

  useEffect(() => {
    console.log("storyResource", storyResource);
  }, [storyResource]);

  // useEffect(() => {
  //   // console.log("storyResource", storyResource);
  //   if (eventSubTab == "story") {
  //     const item = eventTabs?.filter((tab: any) => tab?.name == eventTab)?.[0];
  //     // alert(item?.name)
  //     if (item?.name) {
  //       setMyContext((existing: any) => {
  //         return {
  //           ...existing,
  //           storyResource: item,
  //         };
  //       });
  //      navigate(`/story/${eventId}`);
  //   }
  //   }
  // }, [eventSubTab, eventTabs]);

  useEffect(() => {
    if (editInfo) jumpToReleventDiv("editor");
  }, [editInfo]);

  useEffect(() => {
    if (mobMenuColor?.id) {

      if (mobMenuColor?.id == "development" && eventSubTab != "story") {
        if (eventId && eventTab) {
          return
        }
        navigate(`/event/${eventId}/schedule`);
      }
      // else if (mobMenuColor?.id == "marketing" && eventSubTab != "story") {
      //   navigate(`/event/${eventId}/userjourney`);
      // } else if (mobMenuColor?.id == "operation" && eventSubTab != "story") {
      //   navigate(`/event/${eventId}/catalog`);
      // }

      if (isMobile) {
        // setMobMenu(true);
      }
    }
  }, [eventSubTab,
    mobMenuColor?.id
  ]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const appMenu: any = eventTabs?.filter((res: any) => res.name == eventTab)?.[0]
    if (appMenu) {
      const categoryMenu = TabsCardInfo?.filter(reg => reg.id == appMenu?.category)?.[0]
      setMyContext((existing: any) => {
        return {
          ...existing,
          mobMenuColor: categoryMenu,
        };
      });
    }
  }, [eventTab, eventTabs])

  return (
    <>
      {/* {storyResource && (
        <StoryTemplate
          {...props}
        // storyResourceProps={storyResource}
        />
      )} */}
      {mobMenu && (
        <Modal show={mobMenu} >
          <MobMenuSearch
            mobMenuColor={mobMenuColor}
            // setMobMenuColor={setMobMenuColor}
            eventTabs={eventTabs}
            eventTab={eventTab}
            setMobMenu={setMobMenu}
            setOpenMenu={setOpenMenu}
            eventId={eventId}
          />
        </Modal>
      )}
      {openModal == 'openQRCode' && (
        <Modal
          show={true}
          onHide={() => {
            // setOpenForm(false)
            setOpenModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>MyGuestNet</Modal.Title>
          </Modal.Header>
          <Modal.Body> Download Tab QR Code</Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QACodeWrapper style={{ marginBottom: 20 }}>
              <QRCodeGenerator
                style={{
                  maxWidth: 400,
                }}
                url={`https://www.myguestnet.com/event/${eventId +
                  (eventTab ? "/" + eventTab : "") +
                  (eventSubTab ? "/" + eventSubTab : " ")
                  }`}
              />
            </QACodeWrapper>
            <RR
              data={{
                text: "My Guestnet Link",
                url: `https://www.myguestnet.com/event/${eventId +
                  (eventTab ? "/" + eventTab : "") +
                  (eventSubTab ? "/" + eventSubTab : " ")
                  }`,
              }}
            >
              <ButtonL style={{ margin: 0 }}>
                <Share /> Share Guestnet Link
              </ButtonL>
            </RR>
          </Modal.Footer>
        </Modal>
      )}

      {openModal == 'openQuiz' && (
        <Modal
          show={true}
          onHide={() => {
            // setOpenForm(false)
            setOpenModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>MyGuestNet</Modal.Title>
          </Modal.Header>
          <Modal.Body> Create Quiz for App Items</Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QuizComponent {...props}
              setOpenModal={setOpenModal}

            />
          </Modal.Footer>
        </Modal>
      )}

      <div>
        {!isMobile && (
          <TabListEvent
            style={{
              backgroundColor: showBanner ? "" : "lightgray",
              // padding: showBanner ? "20px 0px" : 20,
              padding: 5,
              borderRadius: showBanner ? 0 : 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // backgroundColor: 'red',
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowBackIos
                onClick={() => {
                  navigate(`/`);
                }}
                style={{
                  fontSize: "1.9em",
                  marginRight: 5,
                  marginLeft: 5,
                  color: "gray",
                }}
              />
              {myInfo?.handle == eventInfo?.handle && allAccess && (
                <DropdownFilterTabsContainer>
                  <ButtonFilterTabs
                    onClick={() => setShowDropdown(!showDropdown)}
                    style={{
                      // height: 10,
                      padding: 0,
                      marginRight: 20,
                    }}
                  >
                    <FilterList />
                  </ButtonFilterTabs>

                  {showDropdown && (
                    <DropdownFilterTabs>
                      <div>Filter Visible Tabs</div>
                      <FilterChild
                        onClick={() => {
                          loadFilter({
                            newArray: [],
                            eventId,
                            eventInfo,
                          });
                          setNewArray([]);
                          setShowDropdown(false);
                        }}
                      >
                        <Sync />
                        <span>Clear All</span>
                      </FilterChild>
                      {eventTabs?.map((tab: any) => (
                        <FilterChild key={tab.name}>
                          <input
                            type="checkbox"
                            {...(newArray?.includes(tab.name)
                              ? { checked: true }
                              : {})}
                            onChange={(e) => {
                              const newI = [...newArray];

                              if (e?.target.checked) {
                                loadFilter({
                                  newArray: [...newI, tab.name],
                                  eventId,
                                  eventInfo,
                                });
                                setNewArray([...newI, tab.name]);
                              } else {
                                loadFilter({
                                  newArray: newI?.filter(
                                    (res: any) => res != tab.name
                                  ),
                                  eventId,
                                  eventInfo,
                                });
                                setNewArray(
                                  newI?.filter((res: any) => res != tab.name)
                                );
                              }
                            }}
                          />
                          <span>{tab.name}</span>
                        </FilterChild>
                      ))}
                    </DropdownFilterTabs>
                  )}
                </DropdownFilterTabsContainer>
              )}



              <div
                style={{
                  padding: "5px 0px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 20,
                  // backgroundColor: 'red'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 10,

                    // backgroundColor: 'red',
                    flexDirection: "row",
                    overflowX: "auto",
                    width: windowWidth * 0.6,
                    paddingRight: 100,
                    // overflowX: 'hidden'
                  }}
                >
                  {TabsCardInfo?.map((item, key) => (
                    <div
                      onClick={() => {
                        setMyContext((existing: any) => {
                          return {
                            ...existing,
                            mobMenuColor: item,
                          };
                        });
                        if (isMobile) {
                          setMobMenu(true);
                          setMyContext((existing: any) => {
                            return {
                              ...existing,
                              mobMenu: true,
                            };
                          });
                        }
                        setShowBanner(false);
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          borderStyle: "none",
                          backgroundColor: "lightgray",
                          flexDirection: "row",
                          alignItems: "center",
                          color:
                            mobMenuColor?.img == item?.img ? "white" : "black",
                          backgroundImage:
                            mobMenuColor?.img == item?.img ? item?.img : "",
                        }}
                        key={key}
                      >
                        {item.label}
                        {mobMenuColor?.img == item?.img && (
                          <ArrowDropDownIcon
                            style={{
                              display: "flex",
                              // position: 'absolute',
                              // top: -5,
                              fontSize: 30,
                              // color:
                              // backgroundImage: item?.img,
                            }}
                          />
                        )}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <EventTabsDesktopMenu
              style={{
                backgroundImage: mobMenuColor?.img,
                padding: 20,
                borderRadius: 10,
              }}
            >
              {eventTab &&
                eventTabs?.map((res: any) => {

                  if (newArray?.includes(res.name)) {
                    return;
                  }
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {
                        <TabEvent
                          style={{
                            ...(eventTab == res.name
                              ? {
                                backgroundColor:
                                  eventTab == res.name
                                    ? myColors?.brown
                                    : mobMenuColor?.id == res?.category
                                      ? mobMenuColor?.color
                                      : "transparent",
                              }
                              : {
                                backgroundColor: mobMenuColor?.id != res?.category
                                  ? "rgba(0,0,0,0.05)"
                                  : menuOptions?.includes(
                                    res.name
                                  )
                                    ? myColors?.green
                                    : "",
                                // backgroundImage: menuOptions?.includes(res.name) ? 'red' : ""
                              }),
                          }}
                          selected={
                            eventTab == res.name
                            // ||
                            //   (!eventTab && res.name == "schedule")
                            //   ? true
                            //   : false
                          }
                          onClick={() => {
                            // setAction('schedule')

                            setOpenMenu(false);
                            navigate(
                              `/event/${eventId}/${res?.subTab?.[0]?.name
                                ? res.name + "/" + res?.subTab?.[0]?.name
                                : res.name
                              }`
                            );
                            setMyContext((existing: any) => {
                              return {
                                ...existing,
                                editInfo: false,
                              };
                            });

                          }}
                        >
                          <p
                            className="title"
                            style={{
                              color:
                                mobMenuColor?.id == res?.category
                                  ? "black"
                                  : "white",
                            }}
                          >
                            {res.icon && (
                              <i
                                className={res.icon}
                                style={{
                                  display: "flex",
                                  fontSize: 12,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingRight: 5,
                                }}
                              />
                            )}{" "}
                            {res.title}
                          </p>
                        </TabEvent>
                      }
                      {myInfo?.handle && allAccess && (
                        <div
                          onClick={() => {
                            const temp = eventInfo?.hideTabs?.length > 0 || [];

                            const newFormInput: any = {
                              hideTabs: [],
                              eventId: eventId,
                              handle: myInfo?.handle,
                            };
                            const data = {
                              query: {
                                ...newFormInput,
                              },
                              resource: `calendar_event_form`,
                              check: ["handle", "label"],
                              myInfo,
                            };
                            console.log("FormFields", data);

                            saveDataDB(data).then((res) => {
                              // setRefreshing(true);
                            });
                          }}
                        ></div>
                      )}
                    </div>
                  );
                })}
            </EventTabsDesktopMenu>
          </TabListEvent>
        )}
        <ActionIconsWrapper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: 'red',
            width: "100%",
          }}
        >
          <ActionIcon
            style={{
              top: window.innerHeight * 0.3,
            }}
            onClick={() => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  mobMenuColor: "",
                };
              });
              setMobMenu(true);
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  mobMenu: true,
                };
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </ActionIcon>


          <ActionIcon
            onClick={() => {
              navigate(`/guestsite/${eventInfo?.title || ''}`)

            }}
          >
            <WebSharp />
          </ActionIcon>


          {myInfo?.handle == eventInfo?.handle && <ActionIcon
            onClick={() => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  openCreateEvent: true,
                };
              });
            }}
          >
            <Edit />
          </ActionIcon>}


          <ActionIcon
            onClick={() => {
              // setOpenTabModal(true);
              // setMode("stories");
              window.open(`/story/${eventId}/${eventTab}`, "_self")
            }}
          >
            <Camera />
          </ActionIcon>

          <ActionIcon>
            <RR
              data={{
                text: "My GuestNet Profile",
                url: `https://myguestnet.com/${location?.pathname}`,
                title: `My GuestNet Profile`,
              }}
            >
              <Share />
            </RR>
          </ActionIcon>
          <ActionIcon>
            <Home
              onClick={() => {
                navigate(`/`);
                // setOpenEventForm && setOpenEventForm(false);
              }}
            />
          </ActionIcon>

        </ActionIconsWrapper>
        <ParentAdsWrapper>
          <Grid container spacing={6}>
            <Grid item xs={12} md={8} xl={8}>
              <EventBannerComponent banner={true} {...props} />
              <div>
                {eventTab &&
                  eventTabs?.map((res: any) => {
                    return (
                      <>
                        {res.name == eventTab &&
                          eventSubTab &&
                          res.subTab?.[0] && (
                            <TabListEvent>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: 400,
                                  overflowX: "auto",
                                }}
                              >
                                {res.subTab?.map((sub: any) => {
                                  return (
                                    <TabEvent
                                      selected={
                                        eventSubTab == sub.name ? true : false
                                      }
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: 0,
                                        fontSize: 12,
                                        padding: 3,
                                        marginLeft: 10,
                                        whiteSpace: "nowrap",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          `/event/${eventId}/${eventTab}/${sub.name}`,
                                          {
                                            state: {
                                              backUrl: location?.pathname,
                                            },
                                          }
                                        );
                                        setMyContext((existing: any) => {
                                          return {
                                            ...existing,
                                            editInfo: false,
                                          };
                                        });
                                      }}
                                    >
                                      {sub.icon && (
                                        <i
                                          className={sub.icon}
                                          style={{
                                            display: "flex",
                                            fontSize: 12,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingRight: 5,
                                          }}
                                        />
                                      )}
                                      {sub?.title || ""}
                                    </TabEvent>
                                  );
                                })}
                              </div>
                            </TabListEvent>
                          )}
                        <div>
                          {res.name == eventTab && eventId && (
                            <>
                              <ModalHeader
                                style={{
                                  marginBottom: 10,
                                  backgroundColor: "rgba(0,0,0,0.09)",
                                  borderRadius: 25,
                                  padding: 5,
                                }}
                              >
                                <div style={{
                                  display: 'flex',
                                  gap: 20
                                }}>
                                  <QRIcon
                                    style={{
                                      color: "#e54a4d",
                                    }}
                                    onClick={() => {
                                      // setOpenQRCode(true);
                                      setOpenModal('openModal')
                                      // handleReset();
                                    }}
                                  />
                                  <Quiz
                                    style={{
                                      color: "#e54a4d",
                                    }}
                                    onClick={() => {
                                      // setOpenQRCode(true);
                                      setOpenModal('openQuiz')
                                      // handleReset();
                                    }}
                                  />
                                </div>
                                <span
                                  style={{
                                    color: "#e54a4d",
                                  }}
                                >
                                  {(res.subTab?.[0] &&
                                    res.subTab?.filter(
                                      (sub: any) => sub.name == eventSubTab
                                    )?.[0]?.description) ||
                                    res?.description ||
                                    ""}
                                </span>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <Camera
                                    style={{
                                      color: "#e54a4d",
                                      // marginRight: 20
                                    }}
                                    onClick={() => {
                                      navigate(`/story/${eventId}/${eventTab}`);

                                      // setMyContext((existing: any) => {
                                      //   return {
                                      //     ...existing,
                                      //     storyResource: res,
                                      //   };
                                      // });
                                    }}
                                  />
                                </div>
                              </ModalHeader>
                            </>
                          )}
                          {false ? (
                            <div>
                              Please contact the host of this Enterprise Page
                              for access
                            </div>
                          ) : (
                            <>
                              {" "}
                              {res.name == eventTab &&
                                (myInfo?.handle == "@mplaunch" || ((res?.isAdminRequired || false) ==
                                  (myInfo?.handle &&
                                    myInfo?.handle == eventInfo?.handle) &&
                                  res?.editButton &&
                                  allAccess) || //subtab checkete
                                  ((res?.isAdminRequired || false) ==
                                    (myInfo?.handle &&
                                      eventInfo?.handle &&
                                      myInfo?.handle == eventInfo?.handle) &&
                                    (eventSubTab &&
                                      res.subTab?.filter(
                                        (sub: any) => sub.name == eventSubTab
                                      )?.[0]?.editButton
                                      ? true
                                      : false))) && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginBottom: 10,
                                    }}
                                  >
                                    <DropdownFilterTabsContainer
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <ButtonFilterTabs
                                        onClick={() =>
                                          setShowDropdownAssign(
                                            !showDropdownAssign
                                          )
                                        }
                                      >
                                        <AssignmentInd />
                                      </ButtonFilterTabs>
                                      {showDropdownAssign && (
                                        <DropdownFilterTabs>
                                          <div
                                            onClick={() => {
                                              navigate(
                                                `/event/${eventId}/people/members`
                                              );

                                              // window.open(
                                              //   `/event/${eventId}/people/members`,
                                              //   "_blank"
                                              // );
                                            }}
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            Assign action to member
                                            <GroupAdd
                                              style={{
                                                marginLeft: 5,
                                              }}
                                            />
                                          </div>
                                          <FilterChild
                                            onClick={() => {
                                              loadFilter({
                                                newArray: [],
                                                eventId,
                                                eventInfo,
                                              });
                                              setNewArrayAssign([]);
                                              setShowDropdownAssign(false);
                                            }}
                                          >
                                            <Sync />
                                            <span>Clear All</span>
                                          </FilterChild>
                                          {members.map((tab: any) => {
                                            console.log("bbbbbbbb", tab);
                                            return (
                                              <FilterChild key={tab.name}>
                                                <input
                                                  type="checkbox"
                                                  // checked={activeTabs.includes(tab.name) ? true : false}
                                                  {...(newArray?.includes(
                                                    tab.name
                                                  )
                                                    ? { checked: true }
                                                    : {})}
                                                  onChange={(e) => {
                                                    const newI = [...newArray];

                                                    if (e?.target.checked) {
                                                      loadFilter({
                                                        newArray: [
                                                          ...newI,
                                                          tab.name,
                                                        ],
                                                        eventId,
                                                        eventInfo,
                                                      });
                                                      setNewArrayAssign([
                                                        ...newI,
                                                        tab.name,
                                                      ]);
                                                    } else {
                                                      loadFilter({
                                                        newArray: newI?.filter(
                                                          (res: any) =>
                                                            res != tab.name
                                                        ),
                                                        eventId,
                                                        eventInfo,
                                                      });
                                                      setNewArrayAssign(
                                                        newI?.filter(
                                                          (res: any) =>
                                                            res != tab.name
                                                        )
                                                      );
                                                    }
                                                  }}
                                                />
                                                <span>{tab.name}</span>
                                              </FilterChild>
                                            );
                                          })}
                                        </DropdownFilterTabs>
                                      )}
                                    </DropdownFilterTabsContainer>

                                    <EditSectionWrapper id={"editor"}>
                                      <EditSectionButton
                                        style={
                                          editInfo
                                            ? {
                                              background: "#ed2939",
                                            }
                                            : {}
                                        }
                                        onClick={() => {
                                          // setEditInfo(!editInfo)
                                          setMyContext((existing: any) => {
                                            return {
                                              ...existing,
                                              editInfo: !editInfo,
                                            };
                                          });
                                        }}
                                      >
                                        <p>{`${editInfo ? "Start Creating" : "Add"
                                          } ${(res.subTab?.[0] &&
                                            res.subTab?.filter(
                                              (sub: any) =>
                                                sub.name == eventSubTab
                                            )?.[0]?.title) ||
                                          res.title
                                          }`}</p>
                                        {!editInfo ? (
                                          <ExpandMore />
                                        ) : (
                                          <ExpandLess />
                                        )}
                                      </EditSectionButton>
                                    </EditSectionWrapper>
                                  </div>
                                )}
                              {res.name == eventTab && eventId && (
                                <>
                                  {/* <h5
                              style={{
                                marginTop: 20,
                              }}
                            >
                              {(res.subTab?.[0] &&
                                res.subTab?.filter(
                                  (sub: any) => sub.name == eventSubTab
                                )?.[0]?.description) ||
                                res?.description ||
                                "todo menu"}
                            </h5> */}

                                  {eventTab == "ebenchmark" ? (
                                    <div>
                                      <BenchmarkListItem
                                        {...props}
                                        editInfo={editInfo}
                                        tab={res}
                                        resource={res?.resource}
                                      />
                                    </div>
                                  ) : eventTab == "userjourney" ? (
                                    <div>
                                      <UserJourney
                                        {...props}
                                        editInfo={editInfo}
                                        tab={res}
                                        resource={res?.resource}
                                      />
                                    </div>
                                  ) : eventTab == "userStories" ? (
                                    <>
                                      <UserStoriesCreate
                                        {...props}
                                        editInfo={editInfo}
                                        tab={res}
                                        resource={res?.resource}
                                      />
                                    </>
                                  ) : eventTab == "ticket" ? (
                                    <CatalogItems  //Kiosk
                                      {...props}
                                      editInfo={editInfo}
                                      tab={res}
                                      resource={res?.resource}
                                    />
                                  ) : eventTab == "subscription" ? (
                                    <SubscriptionItems
                                      {...props}
                                      editInfo={editInfo}
                                      resource={res?.resource}
                                      item={res}
                                      tab={res}
                                    />
                                  )
                                    : eventTab == "coupon" ? (
                                      <SubscriptionItems
                                        {...props}
                                        editInfo={editInfo}
                                        resource={res?.resource}
                                        item={res}
                                        tab={res}
                                      />
                                    ) : // : res?.editButton && res.defaultEditor ? (
                                      //   <TabListItems
                                      //     {...props}
                                      //     editInfo={editInfo}
                                      //     type={"event"}
                                      //   />
                                      // )
                                      eventTab == "sponsor" ? (
                                        <>
                                          {eventSubTab ?
                                            res?.subTab?.map((sub: any) => {
                                              return (
                                                <>
                                                  {" "}
                                                  {!eventSubTab ||
                                                    (sub.name == "accept" &&
                                                      eventSubTab == "accept") ? (
                                                    <AdvertDetailsCard
                                                      {...props}
                                                      tab={sub}
                                                      editInfo={editInfo}
                                                      resource={res?.resource}
                                                      searchKeywordAttendees={
                                                        searchKeywordAttendees
                                                      }
                                                      setSearchKeywordAttendees={
                                                        setSearchKeywordAttendees
                                                      }
                                                      type={"event"}
                                                    />
                                                  ) : sub.name == "create" &&
                                                    eventSubTab == "create" ? (
                                                    <AdvertListItems
                                                      {...props}
                                                      editInfo={editInfo}
                                                      tab={sub}
                                                      resource={sub?.resource}
                                                    />
                                                  ) : null}
                                                </>
                                              );
                                            }) : null}
                                        </>
                                      ) : eventTab == "reviews" ? (
                                        <EndorsementReview
                                          {...props}
                                          editInfo={editInfo}
                                          tab={res}
                                          resource={res?.resource}
                                        />
                                      )
                                        : eventTab == "interest"
                                          ?
                                          <EventSkillsTabList
                                            {...props}
                                            editInfo={editInfo}
                                            tab={res}
                                            resource={res?.resource}
                                          />
                                          : eventTab == "training"
                                            ?
                                            <TrainingSkillsTabList
                                              {...props}
                                              editInfo={editInfo}
                                              tab={res}
                                              resource={res?.resource}
                                            />


                                            : eventTab == "ourSkills"
                                              ?
                                              <EventSkillsTabList
                                                {...props}
                                                editInfo={editInfo}
                                                tab={res}
                                                resource={res?.resource}
                                              />
                                              : eventTab == "faq" ? (

                                                <FAQ
                                                  {...props}
                                                  editInfo={editInfo}
                                                  tab={res}
                                                  resource="calendar_catalog_products"
                                                />

                                              ) : eventTab == "policy" ? (
                                                <>
                                                  <Policies
                                                    {...props}
                                                    editInfo={editInfo}
                                                    tab={res}
                                                    resource="calendar_catalog_products"
                                                  />
                                                </>
                                              ) : eventTab == "comments" ? (
                                                <CommentsPlayerCard
                                                  {...props}
                                                  editInfo={editInfo}
                                                  tab={res}
                                                  resource={res?.resource}
                                                />
                                              ) : eventTab == "payment" ? (
                                                <PaymentSetupPage
                                                  {...props}
                                                  editInfo={editInfo}
                                                  tab={res}
                                                  resource={res?.resource}
                                                />
                                              )
                                                : eventTab == "catalog" ?
                                                  <CatalogItems  //Kiosk
                                                    {...props}
                                                    editInfo={editInfo}
                                                    tab={res}
                                                    resource={res?.resource}
                                                  />
                                                  : eventSubTab == "request"
                                                    ? <CatalogRequest
                                                      {...props}
                                                      editInfo={editInfo}
                                                      tab={res}
                                                      resource="calendar_detail_request"
                                                    />
                                                    : eventTab == "detail" ? (
                                                      <Col>
                                                        {/* schedule event*/}
                                                        <div>
                                                          {eventInfo &&
                                                            eventInfo?.name &&
                                                            Object.keys(eventInfo)?.map(
                                                              (res: any) => {
                                                                if (
                                                                  ![
                                                                    "title",
                                                                    "category",
                                                                    "capacity",
                                                                    "description",
                                                                    // 'name'
                                                                  ]?.includes(res)
                                                                ) {
                                                                  return;
                                                                }
                                                                return (
                                                                  <div
                                                                    style={{
                                                                      display: "flex",
                                                                      flexDirection: "row",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: 12,
                                                                      }}
                                                                    >
                                                                      {res?.toUpperCase()}
                                                                    </div>{" "}
                                                                    <div
                                                                      style={{
                                                                        marginLeft: 20,
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      {eventInfo[res]}
                                                                    </div>
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                        </div>
                                                      </Col>
                                                    ) : eventTab == "signdocument" ? (
                                                      <>
                                                        {eventSubTab
                                                          ? res?.subTab?.map((sub: any) => {
                                                            return (
                                                              <>
                                                                {!eventSubTab ||
                                                                  (eventSubTab == "jvagreement" &&
                                                                    sub?.name == eventSubTab) ? (
                                                                  <Agreements
                                                                    {...props}
                                                                    editInfo={editInfo}
                                                                    editButton={sub?.editButton}
                                                                    type={"event"}
                                                                    resource={sub.resource}
                                                                  />
                                                                ) : sub?.name == eventSubTab &&
                                                                  eventSubTab == "documentsign" ? (
                                                                  <DigitalContract
                                                                    {...props}
                                                                    editInfo={editInfo}
                                                                    editButton={sub?.editButton}
                                                                    type={"event"}
                                                                    resource={sub.resource}
                                                                  />
                                                                ) : null}
                                                              </>
                                                            );
                                                          }) : null}
                                                      </>
                                                    ) : eventTab == "schedule" ? (
                                                      <EventForm
                                                        {...props}
                                                        myInfo={myInfo}
                                                        eventId={eventId}
                                                        setFormInput={setFormInput}
                                                        formInput={formInput}
                                                        data={formInput}
                                                        editInfo={editInfo}
                                                        resource={"calendar_events"}
                                                      />
                                                    ) : eventTab == "checkin" ? (
                                                      <WorldMap
                                                        {...props}
                                                        resource={res.resource}
                                                      />
                                                    ) : eventTab == "emailer" ? (
                                                      <EmailEditorComponent
                                                        {...props}
                                                        tab={res}
                                                        editInfo={editInfo}
                                                        resource={res.resource}
                                                        type={"event"}
                                                      />
                                                    ) : eventTab == "incident" ? (
                                                      <AssetTracker
                                                        {...props}
                                                        tab={res}
                                                        editInfo={editInfo}
                                                        resource={res.resource}
                                                        type={"event"}
                                                      />
                                                    ) : eventTab == "media" ? ( //event
                                                      <TabListItems
                                                        {...props}
                                                        tab={res}
                                                        editInfo={editInfo}
                                                        resource={res.resource}
                                                        type={"event"}
                                                      />
                                                    )
                                                      : eventTab == "services"
                                                        ?
                                                        <>
                                                          <TabListItems
                                                            {...props}
                                                            selectedItem={selectedItem}
                                                            setSelectedItem={setSelectedItem}
                                                            editInfo={editInfo}
                                                            editButton={res?.editButton}
                                                            type={"event"}
                                                            tab={res}
                                                            resource={res.resource}
                                                          />
                                                        </>
                                                        : eventTab == "reports" ? (
                                                          <>
                                                            {eventSubTab
                                                              ? res?.subTab?.map((sub: any) => {
                                                                return (
                                                                  <>
                                                                    {!eventSubTab ||
                                                                      (sub?.name == "forms" &&
                                                                        eventSubTab == "forms") ? (
                                                                      <EventFormCreator
                                                                        {...props}
                                                                        editInfo={editInfo}
                                                                        tab={sub}
                                                                        resource={sub?.resource}
                                                                      />
                                                                    ) : sub?.name == "data" &&
                                                                      eventSubTab == "data" ? (
                                                                      <FormReportTable
                                                                        {...props}
                                                                        editInfo={editInfo}
                                                                        tab={sub}
                                                                        resource={sub?.resource}
                                                                      />
                                                                    ) : sub?.name == "import" &&
                                                                      eventSubTab == "import" ? (
                                                                      <ImportFormReportTable
                                                                        {...props}
                                                                        editInfo={editInfo}
                                                                        tab={sub}
                                                                        resource={sub?.resource}
                                                                      />
                                                                    ) : null}
                                                                  </>
                                                                );
                                                              }) : null}
                                                          </>
                                                        ) : eventTab == "watchers"
                                                          ? (
                                                            <div>
                                                              <WatchersComponent
                                                                {...props}
                                                                tab={res}
                                                                editInfo={editInfo}
                                                                resource={res.resource}
                                                                type={"event"}
                                                              />
                                                            </div>
                                                          ) : eventTab == "collaborators"
                                                            ? <div>
                                                              <CollaboratorComponent
                                                                {...props}
                                                                tab={res}
                                                                editInfo={editInfo}
                                                                resource={res.resource}
                                                                type={"event"}
                                                              />
                                                            </div>
                                                            : eventTab == "members" ? (
                                                              <MembersComponent
                                                                {...props}
                                                                tab={res}
                                                                editInfo={editInfo}
                                                                resource={res.resource}
                                                                type={"event"}
                                                              />
                                                            ) : eventTab == "customers" ? (
                                                              <MembersComponent
                                                                {...props}
                                                                tab={res}
                                                                editInfo={editInfo}
                                                                resource={res.resource}
                                                                type={"event"}
                                                              />

                                                            ) : eventTab == "signdocument" ? (
                                                              <>
                                                                {eventSubTab
                                                                  ? res?.subTab?.map((sub: any) => {
                                                                    return (
                                                                      <>
                                                                        {!eventSubTab ||
                                                                          (eventSubTab == "jvagreement" &&
                                                                            sub?.name == eventSubTab) ? (
                                                                          <Agreements
                                                                            {...props}
                                                                            editInfo={editInfo}
                                                                            editButton={sub?.editButton}
                                                                            type={"event"}
                                                                            tab={sub}
                                                                            resource={sub.resource}
                                                                          />
                                                                        ) : sub?.name == eventSubTab &&
                                                                          eventSubTab == "documentsign" ? (
                                                                          <DigitalContract
                                                                            {...props}
                                                                            editInfo={editInfo}
                                                                            editButton={sub?.editButton}
                                                                            type={"event"}
                                                                            tab={sub}
                                                                            resource={sub.resource}
                                                                          />
                                                                        ) : null}
                                                                      </>
                                                                    );
                                                                  }) : null}
                                                              </>
                                                            ) : eventTab == "todo" ? (
                                                              <TabListItems
                                                                {...props}
                                                                selectedItem={selectedItem}
                                                                setSelectedItem={setSelectedItem}
                                                                editInfo={editInfo}
                                                                editButton={res?.editButton}
                                                                type={"event"}
                                                                tab={res}
                                                                resource={res.resource}
                                                              />
                                                            ) : eventTab == "website" ? (
                                                              <WebsiteListItems
                                                                {...props}
                                                                selectedItem={selectedItem}
                                                                setSelectedItem={setSelectedItem}
                                                                editInfo={editInfo}
                                                                editButton={res?.editButton}
                                                                type={"event"}
                                                                tab={res}
                                                                resource={res.resource}
                                                              />
                                                            )
                                                              : eventTab == "funnel" ? (
                                                                <HostFunnel
                                                                  {...props}
                                                                  editInfo={editInfo}
                                                                  tab={res}
                                                                  resource={res?.resource}
                                                                />
                                                              ) : eventTab == "planner" ? (
                                                                <PlanningKanbanComponent
                                                                  {...props}
                                                                  editInfo={editInfo}
                                                                  editButton={res?.editButton}
                                                                  type={"event"}
                                                                  tab={res}
                                                                  resource={res.resource}
                                                                />
                                                              ) : eventTab == "brainstorm" ? (
                                                                <div>
                                                                  <Brainstorm
                                                                    {...props}
                                                                    editInfo={editInfo}
                                                                    tab={res}
                                                                    resource={res?.resource}
                                                                  />
                                                                </div>
                                                              ) : eventTab == "contentCreator" ? (
                                                                <div>
                                                                  <VideoEditor
                                                                    {...props}
                                                                    editInfo={editInfo}
                                                                    tab={res}
                                                                    resource={res?.resource}
                                                                  />
                                                                </div>
                                                              ) : eventTab == "benchmark" ? (
                                                                <BenchmarkListItem
                                                                  {...props}
                                                                  editInfo={editInfo}
                                                                  tab={res}
                                                                  resource={res?.resource}
                                                                />
                                                              ) : eventTab == "portfolio" ? (
                                                                <TabListItems
                                                                  {...props}
                                                                  editInfo={editInfo}
                                                                  type={"event"}
                                                                  resource={res?.resource}

                                                                />
                                                              ) : eventTab == "myEvents" ? (
                                                                <Col>
                                                                  <Col>
                                                                    <InputAgenda>
                                                                      <input
                                                                        type="text"
                                                                        value={searchKeywordEvents}
                                                                        onChange={(e: any) =>
                                                                          setSearchKeywordEvents(
                                                                            e.target.value
                                                                          )
                                                                        }
                                                                        placeholder="Search Events ..."
                                                                      />
                                                                      <button>
                                                                        <Search />
                                                                      </button>
                                                                    </InputAgenda>
                                                                    <br />
                                                                    <CarrousselCardApi
                                                                      // host={host}
                                                                      // eventId={eventId}
                                                                      searchKeywordAttendees={
                                                                        searchKeywordEvents
                                                                      }
                                                                      people={people?.filter(
                                                                        (event: any) =>
                                                                          event?.title
                                                                            ?.toLowerCase()
                                                                            .includes(
                                                                              searchKeywordEvents.toLowerCase()
                                                                            ) ||
                                                                          event?.name
                                                                            ?.toLowerCase()
                                                                            .includes(
                                                                              searchKeywordEvents.toLowerCase()
                                                                            ) ||
                                                                          event?.room
                                                                            ?.toLowerCase()
                                                                            .includes(
                                                                              searchKeywordEvents.toLowerCase()
                                                                            ) ||
                                                                          event?.title
                                                                            ?.toLowerCase()
                                                                            .includes(
                                                                              searchKeywordEvents.toLowerCase()
                                                                            )
                                                                      )}
                                                                      handleInfo={(info: any) => {
                                                                        handleInfo({ info, myInfo }).then(
                                                                          (res: any) => {
                                                                            // setRefreshing(false);
                                                                            setMyContext(
                                                                              (existing: any) => {
                                                                                return {
                                                                                  ...existing,
                                                                                  eventDetails: res,
                                                                                };
                                                                              }
                                                                            );
                                                                          }
                                                                        );
                                                                      }}
                                                                      setLocalAction={setLocalAction}
                                                                      isSmall={true}
                                                                    />
                                                                  </Col>
                                                                </Col>
                                                              ) : (
                                                                <TabListItems
                                                                  {...props}
                                                                  editInfo={editInfo}
                                                                  type={"event"}
                                                                  resource={res?.resource}
                                                                />
                                                              )}
                                </>
                              )}
                            </>
                          )}
                        </div >
                      </>
                    );
                  })}
              </div >
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              {fullScreen && (
                <div style={{ marginBottom: 20, borderRadius: 10 }}>
                  {/* <EventBannerComponent {...props} sm={true} /> */}
                </div>
              )}
              <div>
                <h5
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  Suggestions
                </h5>
                {adData.map((ad) => (
                  <Ad
                    key={ad.id}
                    className="ad-card"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <img src={ad.imageUrl} alt={ad.title} />
                    <AdTextInfo className="ad-info">
                      <h3>{ad.title}</h3>
                      <p>{ad.description}</p>
                      <a
                        href={ad.linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn More
                      </a>
                    </AdTextInfo>
                  </Ad>
                ))}
              </div>
            </Grid>
          </Grid>
        </ParentAdsWrapper>
      </div >
    </>
  );
};

const QRCodeGenerator = (props: any) => {
  const { url } = props;
  return (
    <div style={{ textAlign: "center", margin: "20px" }}>
      <QRCode value={url} size={256} />
      <div style={{ marginTop: "10px" }}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </div>
    </div>
  );
};


export const QuizComponent = (props: any) => {
  const {
    setOpenModal
  } = props
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },

  } = myContext;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<any>(0);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [score, setScore] = useState<any>(0);
  // const [formInput, setFormInput] = useState<any>({});
  const [questionInfo, setQuestionInfo] = useState<any>({});
  const [question, setQuestion] = useState<any>('');
  const [answer, setAnswer] = useState<any>('');
  const [options, setOptions] = useState<any>({});
  const [quizIndex, setQuizIndex] = useState<number>(0);
  const [formInput, setFormInput] = useState<any>({
    title: 'Who knows me best',
    description: 'Answer the following questions about the individual to prove you know them',
    quiz: [
      {
        question: 'What is my middle name?',
        options: ['John', 'Larry', 'Fred'],
        answer: 'Larry'
      },
      {
        question: 'What do I like doing for fun?',
        options: ['Movies', 'Eating Out', 'Clubing'],
        answer: 'Movies'
      },
      {
        question: 'Who is my favorite artist?',
        options: ['JZ', 'DMX', 'MJ'],
        answer: 'DMX'
      }
    ]
  });




  const params: any = useParams<any>();

  const location = useLocation();
  const navigate = useNavigate();

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const hostId = useMemo(() => {
    return params?.hostId;
  }, [params?.hostId]);



  const quizObject: any = {
    title: 'Who knows me best',
    description: 'Answer the following questions about the individual to prove you know them',
    quiz: [
      {
        question: 'What is my middle name?',
        options: ['John', 'Larry', 'Fred'],
        answer: 'Larry'
      },
      {
        question: 'What do I like doing for fun?',
        options: ['Movies', 'Eating Out', 'Clubing'],
        answer: 'Movies'
      },
      {
        question: 'Who is my favorite artist?',
        options: ['JZ', 'DMX', 'MJ'],
        answer: 'DMX'
      }
    ]
  };

  useEffect(() => {

    getDataDB({
      query: {
        eventId: eventId,
        eventTab: eventTab
      },
      resource: "calendar_match_quiz",
    }).then(async (res) => {

      if (res?.[0]) {
        setQuestionInfo({
          title: res?.[0]?.title,
          description: res?.[0]?.description
        })
        setFormInput(res?.[0])
      }
    })

  }, [

  ])

  console.log('formInputddd', formInput, questionInfo)

  return (
    <div style={{ padding: '20px', width: '100%' }}>
      <div style={{ fontSize: '24px', marginBottom: '10px' }}>
        {'Title'}
        <InputAgenda>
          <input
            type="text"
            value={formInput?.title || ''}
            onChange={(e: any) => {
              // setQuestionInfo({
              //   ...(questionInfo || {}),
              //   title: e.target.value,
              // })
            }}
            placeholder="Quiz Title ..."
          />
        </InputAgenda>
      </div>
      <div style={{ fontSize: '16px', marginBottom: '20px' }}>
        {'Description'}
        <InputAgenda>
          <input
            type="text"
            value={formInput?.description || ''}
            onChange={(e: any) => {
              // setQuestionInfo({
              //   ...(questionInfo || {}),
              //   description: e.target.value,
              // })
            }}
            placeholder="Quiz description ..."
          />
        </InputAgenda>
      </div>

      {formInput?.quiz?.[0] && formInput?.quiz?.map((quizItem: any, quizNumber: number) => {
        if (quizIndex != quizNumber) {
          return
        }
        return <Card style={{ padding: '20px', width: '100%' }}>
          <div>
            <div style={{
              fontSize: '20px',
              marginBottom: '10px'
            }}>
              {`Question #${(quizNumber || 0) * 1 + 1}`}
              <InputAgenda>
                <input
                  type="text"
                  value={quizItem?.question || ''}
                  onChange={(e: any) => {
                    // const info = { ...quizItem }
                    formInput.quiz[quizNumber].question = e?.target?.value
                    setFormInput({
                      ...formInput,
                    })
                  }}
                  placeholder="Search Events ..."
                />
              </InputAgenda>
            </div>
            <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
              {quizItem?.options?.map((option: any, index: any) => {
                return <div>
                  {`${'option'}${index + 1}`}
                  <InputAgenda>

                    <input
                      type="text"
                      value={option || ''}
                      onChange={(e: any) => {
                        formInput.quiz[quizNumber].options[index] = e?.target?.value
                        setFormInput({
                          ...formInput
                        })
                      }}
                      placeholder="Add question..."
                    />
                  </InputAgenda>

                </div>
              })}
              <div style={{
                marginTop: 10
              }}>
                {`${'Answer'}`}
                <Select
                  value={formInput?.quiz?.[quizNumber]?.answer
                    ? {
                      value: formInput?.quiz?.[quizNumber]?.answer,
                      label: formInput?.quiz?.[quizNumber]?.answer
                    } :
                    ''}
                  onChange={(e: any) => {
                    // alert(e.value)
                    // setAnswer(e.value)
                    formInput.quiz[quizNumber].answer = e?.value
                    setFormInput({
                      ...formInput,
                    })
                  }}
                  options={formInput?.quiz?.[quizNumber]?.options?.map((res: any) => {
                    return {
                      value: res,
                      label: res,
                    }
                  })}
                  placeholder="Select Answer"
                  classNamePrefix="selectform"
                />
              </div>

            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10
            }}>

              {quizIndex > 0 && <button
                onClick={() => {
                  const result = {
                    question: question,
                    answer: answer,
                    options: Object.keys(options)?.map(res => options[res]),
                  }
                  if (formInput?.quiz?.length - 1 >= 0) {
                    const quizIndexInfo = quizIndex - 1
                    // setAnswer(formInput?.quiz?.[quizIndexInfo]?.answer || '')
                    // setQuestion(formInput?.quiz?.[quizIndexInfo]?.question || '')
                    // setOptions(formInput?.quiz?.[quizIndexInfo]?.options || '')
                    setQuizIndex(quizIndexInfo)
                  }
                }}
                style={{
                  padding: '10px 20px',
                  backgroundColor: 'gray',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                Back
              </button>}
              {quizIndex < formInput?.quiz?.length - 2
                ? <button
                  onClick={() => {
                    const quizIndexInfo = quizIndex + 1
                    if (question && answer) {
                      const result = {
                        question: question,
                        answer: answer,
                        options: Object.keys(options)?.map(res => options[res]),
                      }
                      setFormInput({
                        ...questionInfo,
                        quiz: [
                          ...(formInput?.quiz || []),
                          result
                        ]
                      })
                    } else {
                      setAnswer('')
                      setQuestion('')
                      setOptions('')
                    }
                    setQuizIndex(quizIndexInfo)
                    // setQuestionInfo({})
                  }}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: 'gray',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}
                >
                  Next
                </button>
                : <button
                  onClick={() => {
                    const result = {
                      question: question,
                      answer: answer,
                      options: Object.keys(options)?.map(res => options[res]),
                    }
                    const report: any = {
                      ...questionInfo,
                      quiz: [
                        ...(formInput?.quiz || []),
                        result
                      ]
                    }
                    if (!(formInput?.title && formInput?.description)) {
                      alert('Please add title or description to your quiz')
                      return
                    }
                    let text = 'Ready to save your quiz'
                    if (window.confirm(text) == true) {
                      saveDataDB({
                        query: {
                          ...report,
                          eventTab: eventTab,
                          eventId: eventId,
                          handle: myInfo?.handle,
                          date: moment()
                        },
                        myInfo: myInfo,
                        resource: `calendar_match_quiz`,
                        check: ["title", "handle"],
                      }).then(res => {
                        setOpenModal(false)
                      })
                      text = "You pressed OK!";
                    }

                    // console.log('reportsss', report)

                  }}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}
                >
                  Done
                </button>}
            </div>
            {formInput?._id && <button

              onClick={() => {
                deleteDataDB({
                  id: formInput?._id,
                  resource: 'calendar_match_quiz',
                }).then((rep: any) => {
                  alert("Deleted");
                  setOpenModal(false)

                });
              }}
              style={{
                padding: '10px 20px',
                backgroundColor: 'orange',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              Delete
            </button>}
          </div>
        </Card>
      })}
    </div>
  );
};



// const QuizCreator = (props: any) => {
//   const { url } = props;
//   const { myContext, setMyContext } = useContext<any>(UserContext);
//   const {
//     // eventTab,
//     myInfo,
//     myInfo: { active = "2" },

//   } = myContext;

//   const dataInfo = [
//     {
//       title: 'Who knows me best',
//       description: 'Answer the following questions about the individual to prove you know them',
//       quiz: [
//         {
//           question: 'what is my middle name',
//           options: ['John', 'Larry', 'Fred'],
//           answer: 'Larry'
//         },
//         {
//           question: 'what do i like doing for fun',
//           options: ['Movies', 'Eating Out', 'Clubing'],
//           answer: 'Movies'
//         },
//         {
//           question: 'Who is my favorite artist',
//           options: ['JZ', 'DMX', 'MJ'],
//           answer: 'DMX'
//         }
//       ]
//     },
//     {
//       title: 'Who knows me best ssss',
//       description: 'Answer the following questions about the individual to prove you know them',
//       quiz: [
//         {
//           question: 'what is my middle name',
//           options: ['John', 'Larry', 'Fred'],
//           answer: 'Larry'
//         },
//         {
//           question: 'what do i like doing for fun',
//           options: ['Movies', 'Eating Out', 'Clubing'],
//           answer: 'Movies'
//         },
//         {
//           question: 'Who is my favorite artist',
//           options: ['JZ', 'DMX', 'MJ'],
//           answer: 'DMX'
//         }
//       ]
//     }
//   ]

//   const [formInput, setFormInput] = useState<any>({
//     title: 'Who knows me best',
//     description: 'Answer the following questions about the individual to prove you know them',
//     quiz: [
//       {
//         question: 'what is my middle name',
//         options: ['John', 'Larry', 'Fred'],
//         answer: 'Larry'
//       },
//       {
//         question: 'what do i like doing for fun',
//         options: ['Movies', 'Eating Out', 'Clubing'],
//         answer: 'Movies'
//       },
//       {
//         question: 'Who is my favorite artist',
//         options: ['JZ', 'DMX', 'MJ'],
//         answer: 'DMX'
//       }
//     ]
//   })
//   const [questionNumber, setQuestionNumber] = useState<any>(1)

//   console.log('formInput', formInput)


//   return <div style={{
//     width: '100%'
//   }}>
//     <h2 onClick={() => {
//       // setQuestionNumber(questionNumber + 1)
//       // setFormInput({
//       //   ...formInput,
//       //   [`${'question'}${Object.keys(formInput)?.length + 1}`]: {
//       //   }
//       // })
//     }}>
//       Add Quiz
//       <Add />
//     </h2>

//     {Object.keys(formInput)?.map((res: any, index: number) => <Card style={{
//       width: '100%'
//     }}>
//       {index > 0 && <Close onClick={() => {
//         const ref: any = { ...formInput }
//         delete ref?.[`${'question'}${index}`]
//         setFormInput({
//           ...ref
//         })
//       }} />} Question:{index + 1}
//       <InputAgenda>
//         <input
//           type="text"
//           // value={searchKeywordEvents}
//           onChange={(e: any) => {
//             setFormInput({
//               ...formInput,
//               [`${'question'}${index}`]: {
//                 ...(formInput?.[`${'question'}${index}`] || {}),
//                 question: e.target.value,
//               }
//             })
//           }}
//           placeholder="Search Events ..."
//         />
//       </InputAgenda>
//       <div>
//         {formInput?.quiz?.map((ref: any) => {
//           return <div>
//             {ref?.question}
//             <InputAgenda>
//               <input
//                 type="text"
//                 value={ref?.}
//                 onChange={(e: any) => {
//                   setFormInput({
//                     ...formInput,

//                   })
//                 }}
//                 placeholder="Search Events ..."
//               />
//             </InputAgenda>
//           </div>
//         })}

//       </div>
//     </Card>)}
//     {formInput?.question1?.question && <div style={{
//       display: 'flex',
//       width: '100%',
//       marginTop: 20,
//       justifyContent: 'right'
//     }}>
//       <ButtonL onClick={() => {
//         saveDataDB({
//           query: {
//             ...formInput,
//             handle: myInfo?.handle,
//             date: moment()
//           },
//           myInfo: myInfo,
//           resource: `vare_pexels_search_result`,
//           check: ["description"],
//         })
//       }}>
//         Save
//       </ButtonL>
//     </div>}
//   </div>
// };


export const TabsCardInfo = [
  {
    id: "development",
    label: "Network",
    title: ` Guest Calendar & Planner`,
    description:
      "Share your availability with others. Manage contacts and todo list in your GuestNet Funnel",
    color: "#524547",
    img: `url("${relatedPict[8]}")`,
    bgColor: "#6c5462", //#0d2442  //#dcae22
  },
  {
    id: "marketing",
    label: "Market",
    title: `Business / Organization Portfolio`,
    description:
      "Create contents along with your link trees to share with others. Share websites, articles and contents on your GuestNet Story",
    color: "#070a2b",
    img: `url("${relatedPict[10]}")`,
    bgColor: "#0d2442", //#dcae22
  },
  {
    id: "operation",
    label: "Operation",
    title: `Advanced Tools`,
    description:
      "Leverage tools liks Document Signing, Ticketing, Product Listing, Asset Tracking, Collaboration Agreements, and other Templates etc",
    color: "brown",
    img: `url("${relatedPict[12]}")`,
    bgColor: "#dcae22",
  },
];

const AdWrapper = styled.div`
      width: 20%;
      display: flex;
      padding: 30px;
      flex-direction: column;
      gap: 20px;
      @media (max-width: 768px) {
        width: 100%;
  }
      `;
const Ad = styled.div`
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 5px;
      img {
        width: 100%;
      height: 100px;
      object-fit: cover;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
  }
      `;
const AdTextInfo = styled.div`
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      padding: 15px 10px;
      h3 {
        font - size: 18px;
      font-weight: 700;
      margin: 0;
      text-align: left;
  }
      p {
        font - size: 14px;
      font-weight: 400;
      margin: 0;
      color: gray;
      text-align: left;
  }
      a {
        color: gray;
      cursor: pointer;
      text-align: left;
  }
      `;
const MediaContainer = styled.div`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      `;

const BackDrop = styled.div`
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.194);
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      `;

export const DetailModal = styled.div<{ isEvent: boolean }>`
      max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
      margin: 0 10px;
      width: 100%;
      height: 90vh;
      background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
      border-radius: 8px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: scroll;

      /* Scrollbar Styles */
      &::-webkit-scrollbar {
        width: 8px;
  }

      &::-webkit-scrollbar-thumb {
        background - color: #479d83;
      border-radius: 4px;
  }

      &::-webkit-scrollbar-track {
        background - color: #f9f9f9;
      border-radius: 4px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
      `;

const SearchWrapper = styled.div`
      max-width: 930px;
      width: 100%;
      border: 1px solid #dfe0e4;
      border-radius: 10px;
      margin-top: -120px;
      position: relative;
      z-index: 10;
      background: #fff;
      `;

const SearchBarContainer = styled.div`
      padding: 28px;
      `;

const ToggleMenuWrapper = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;

      border-bottom: 1px solid #dfe0e4;
      `;

const ToggleButtons = styled.a<{ active: boolean }>`
      text-decoration: none;
      padding: 17px 0;
      color: #000;
      min-width: 50px;
      border-bottom: ${({ active }) => (active ? "2px solid #479d83" : "none")};
      cursor: pointer;
      `;

const ModalHeader = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: #262527;
      font-size: 18px;
      font-weight: 600;
  }
      svg {
        width: 23px;
      height: 23px;
      color: #aaabab;
  }
      `;

export const ModalIntro = styled.div<{ isEvent: boolean }>`
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: ${({ isEvent }) => (isEvent ? "flex-start" : "center")};
      background: #fff;
      h2 {
        color: #262527;
      font-size: ${({ isEvent }) => (isEvent ? "24px" : "18px")};
      font-weight: 600;
      line-height: 140%;
      margin: 0;
  }
      p {
        margin: 0;
      color: #6a6a70;
      text-align: center;
      font-size: ${({ isEvent }) => (isEvent ? "16px" : "14px")};
      font-weight: 300;
      line-height: 15px;
      padding-bottom: 14px;
  }
      `;

const EventImage = styled.img`
      height: 150px;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
      `;

const TabListEvent = styled.div`
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 2px solid #dee2e6;
      display: flex;
      gap: 10px;

      flex-wrap: wrap;
      `;

const TabEvent = styled.div<{ selected: boolean }>`
      background: ${({ selected }) => (selected ? "#479d83" : "White")};
      cursor: pointer;
      color: ${({ selected }) => (selected ? "white" : "black")};
      p,
      i {
        margin: 0;
      font-size: 12px;
      color: ${({ selected }) => (selected ? "white" : "black")};
  }
      transition: background 0.3s;
      padding: 3px 8px;
      border-radius: 5px;
      &:hover {
        p,
        i {
        color: ${({ selected }) => (selected ? "white" : "#57b397")};
    }
  }
      `;

const SubTabEvent = styled.div<{ selected: boolean }>`
      background: ${({ selected }) => (selected ? "#617d98" : "White")};
      cursor: pointer;
      color: ${({ selected }) => (selected ? "white" : "black")};
      p,
      i {
        margin: 0;
      font-size: 12px;
      color: ${({ selected }) => (selected ? "white" : "black")};
  }
      transition: background 0.3s;
      padding: 3px 8px;
      border-radius: 5px;
      &:hover {
        p,
        i {
        color: ${({ selected }) => (selected ? "white" : "#617d98")};
    }
  }
      `;

const GuestImage = styled.img`
      height: 96px;
      width: 96px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0px 14px;
      margin-top: 14px;
      `;

const GuestMediaWrapper = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 140px !important;
      width: 140px !important;
      background-color: black;
      border-radius: 5px;
      position: relative;
      video,
      img {
        background - color: black;
      border-radius: 5px;
  }
      `;

const GuestMediaHeader = styled.div`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      font-size: 12px;
      color: white;
      background: rgba(0, 0, 0, 0.15);
      position: absolute;
      top: 0;
      left: 0;
      svg {
        width: 12px;
      max-width: 12px;
      height: 12px;
  }
      p {
        font - size: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      width: 50%;
      color: white;
      margin: 0;
  }
      div {
        display: flex;
      gap: 4px;
      span {
        padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
      `;

const GuestMediaParent = styled.div`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 8px;
      max-height: 500px;
      overflow-y: auto;
      `;

const ModalContainer = styled.div`
      position: absolute;
      top: 20px;
      right: 10px;
      background-color: white;
      width: 250px;
      height: 250px;
      border-radius: 8px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 99999;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      `;

const CloseButton = styled.button`
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 16px;
      cursor: pointer;
      `;

const Form = styled.form`
      display: flex;
      flex-direction: column;
      `;

const Input = styled.input`
      margin-bottom: 10px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
      `;

const ButtonF = styled.button`
      background-color: #4caf50;
      color: white;
      padding: 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      `;

const FileInputLabel = styled.label`
      background-color: #4caf50;
      color: white;
      padding: 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      `;

const HiddenFileInput = styled.input`
      display: none;
      `;

const PostHeader = styled.div`
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      h2 {
        margin: 0;
      font-weight: 900;
      font-size: 14px;
      text-transform: uppercase;
  }
      svg {
        width: 20px;
      height: 20px;
      cursor: pointer;
  }
      `;

const MenuWrapperPosts = styled.div`
      position: relative;
      svg {
        width: 20px;
      height: 20px;
  }
      `;

const LinkC = styled.a`
      width: 80px;
      height: 80px;
      svg {
        width: 100%;
      height: 100%;
  }
      `;

const ShareButton = styled.div`
      svg {
        width: 22px;
      height: 22px;
      color: #479d83;
  }
      `;

export const InputAgenda = styled.form`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 5px;
      border: 1px solid #dee2e6;
      border-radius: 5px;
      background: #fff;
      input {
        outline: none;
      border: none;
      font-size: 14px;
      width: 100%;
  }
      button {
        border: none;
      background: transparent;
      outline: none;
      color: gray;
      transition: color 0.3s;
      svg {
        width: 20px;
      height: 20px;
    }
      &:hover {
        color: #dee2e6;
    }
  }
      `;

const DropdownFilterTabsContainer = styled.div`
      position: relative;
      width: fit-content;
      height: fit-content;
      `;

const FilterChild = styled.label`
      display: flex;
      align-items: center;
      gap: 10px;
      input {
        width: fit-content;
  }
      `;

const DropdownFilterTabs = styled.div`
      position: absolute;
      background: white;
      border: 1px solid #ccc;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      width: 200px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 12px;
      z-index: 9999;
      `;

export const ButtonFilterTabs = styled.button`
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px 8px;
      margin: 0;
      cursor: pointer;

      svg {
        color: #333;
      width: 15px;
      height: 15px;
  }
      &:hover {
        background - color: #f9f9f9;
  }
      `;

const DropdownContainer = styled.div`
      position: relative;
      display: inline-block;
      `;

const ButtonIndustry = styled.button`
      background-color: #ffffff;
      color: #333;
      padding: 2px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      min-width: 160px;
      gap: 8px;
      font-size: 14px;
      &:hover {
        background - color: #f9f9f9;
  }
      `;

const DropdownContentIndustry = styled.div`
      position: absolute;
      background-color: #f9f9f9;
      width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      border-radius: 4px;
      overflow: hidden;
      `;

const DropdownItemIndustry = styled.div<any>`
        padding: 10px;
        cursor: pointer;
        color: #333;
        font-size: 12px;
        &:hover {
          background - color: #f1f1f1;
  }

        ${(props) =>
    props.active &&
    `
    background-color: #ddd; 
    font-weight: bold;
  `}
        `;

const TopHeaderWrapper = styled.div<any>`
          display: flex;
          justify-content: space-between;
          background: transparent;
          border-radius: 5px;
          margin-top: -10px;
          `;

const EditSectionWrapper = styled.div`
          position: relative;
          width: 100%;
          /* margin-top: -20px; */
          `;

const EditSectionButton = styled.button`
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          padding: 5px 10px;
          border: none;
          width: 100%;
          font-size: 14px;
          background: #479d83;
          border-radius: 5px;
          cursor: pointer;
          margin: 0;
          svg {
            color: #fff;
          width: 20px;
          height: 20px;
  }
          p {
            color: #fff;
          margin: 0;
  }
          &:hover {
            background - color: #57b397;
  }
          `;

const EventActionsWrapperHeader = styled.div`
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: flex-end;
          margin-top: -32px;
          `;
const ParentAdsWrapper = styled.div`
          display: flex;
          gap: 30;
          margin: 30px 0;
          @media (max-width: 768px) {
            flex - direction: column;
  }
          `;
const MainModalComponent = styled.div`
          width: 80%;
          border-right: 1px solid gray;
          padding-right: 30px;
          @media (max-width: 768px) {
            width: 100%;
          border-right: none;
          padding-right: 0px;
  }
          `;
const EventTabsDesktopMenu = styled.div`
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          width: 100%;
          @media (max-width: 755px) {
            display: none;
  }
          `;
const ButtonBanner = styled.button`
          border: none;
          outline: none;
          font-size: 16px;
          font-weight: 400;
          padding: 10px 15px;
          color: white;
          background: #fdbd34;
          display: flex;
          align-items: center;
          gap: 5px;
          border-radius: 8px;
          margin: 0;
          svg {
            width: 20px;
          height: 20px;
          color: white;
  }
          `;
const QACodeWrapper = styled.div`
          a {
            display: none;
  }
          `;
const EventTabsMobMenu = styled.div`
          display: none;

          @media (max-width: 755px) {
            padding: 10px;
          background-color: #479d83;
          display: block;
          position: fixed;
          right: 5px;
          z-index: 9999;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
          svg {
            width: 35px;
          height: 35px;
          color: white;
    }
  }
          `;
