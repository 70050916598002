import "../App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
  Table,
} from "react-bootstrap";
// import DatePicker from "react-multi-date-picker";
import DatePicker from "react-multi-date-picker";

import { gapi } from "gapi-script";
import moment from "moment";
// import Calendar from "react-calendar";
import {
  getCalendarEvents,
  getCalendarSchedule,
  getCalendarUsers,
  myColors,
  saveAppointment,
} from "../api";
// import { Navbar } from "react-bootstrap";
import AdvancedForms from "./AdvancedForms/AdvancedForms";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";

import {
  BrowserRouter,
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Menubar from "./Menubar";

const colors = {
  lightBlue: "#c1d8ff",
};

export const CalendarCard = (props: any) => {
  const {
    setData,
    data,
    startDay = "1-7-24",
    myDates = [], //MM/DD/YY
    onClick = (e: any) => { },
    formInput = {},
    setFormInput,
    // endDay = '1-27-24'
  } = props;

  const refDate = useRef<any>();
  const [calendar, setCalendar] = useState<any>([]);
  const [myYear, setMyYear] = useState<any>();
  const [myDate, setMyDate] = useState<any>();
  const [myStart, setMyStart] = useState<any>();
  const [myEnd, setMyEnd] = useState<any>(
    moment().clone().endOf("month").endOf("week")
  );
  const [mySelectedDates, setMySelectedDates] = useState<any>(myDates);

  useEffect(() => {
    console.log("scheduleInfoxxx", myDates);

    setMySelectedDates(myDates)
  }, [
    myDates
  ])

  useEffect(() => {
    setMyStart(startDay);
  }, [startDay]);

  useEffect(() => {
    console.log("myDates", myDates);

    if (myDates?.includes(moment()?.format("MM/DD/YY"))) {
      setFormInput({
        ...formInput,
        date: moment()?.format("MM/DD/YY"),
      });
    }

    if (myDates?.length > 0) {
      setMyStart(moment(myDate));
      setMyEnd(moment(myDate).endOf("month"));
    }
  }, [myDate]);

  useEffect(() => {
    if (!(myStart && myEnd && myDates?.length > 0)) {
      return;
    }
    var calend = [];
    var index = moment(myStart)
      .clone()
      .startOf("month")
      .startOf("week")
      .clone();
    // console.log('index', index)

    while (
      index.isBefore(moment(myEnd).clone().endOf("month").endOf("week"), "day")
    ) {
      calend.push(
        new Array(7).fill(0).map(function (n, i) {
          const temp = index.add(1, "day");
          return {
            number: temp.date(),
            date: temp?.format("MM/DD/YY"),
            day: index.format("dddd"),
          };
        })
      );
    }
    const month = moment(myStart)?.format("MMMM");
    const year = moment(myStart)?.year();
    setCalendar(calend);
  }, [myStart, myDates]);

  const selectedColor = useCallback(
    (dat: any) => {
      return moment(dat?.date)?.format("MM/DD/YY") ==
        moment(formInput?.date)?.format("MM/DD/YY")
        ? 'rgb(230,230,230)'
        : "rgba(0,0,0,0)";
    },
    [formInput?.date]
  );

  return (
    <div
      style={{
        width: "100%",
        // marginLeft: -250,
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",

        // backgroundColor: myColors.red
      }}
    >
      <div
        style={{
          display: "flex",
          ...styles.table,
          flexDirection: "row",
          justifyContent: "space-evenly",
          border: "1px solid #8080804a",
        }}
      >
        <div
          onClick={() => {
            setMyStart(moment(myStart).subtract(1, "months"));
            setMyEnd(
              moment(moment(myStart).subtract(1, "months")).endOf("month")
            );
          }}
        >
          {"<"}
        </div>
        <div
          style={{
            flexDirection: "column",
          }}
        >
          <div>{moment(myStart)?.format("MMMM") || ""}</div>
          <div>{moment(myStart)?.format("YYYY") || ""}</div>
        </div>
        <div
          onClick={() => {
            setMyStart(moment(myStart).add(1, "months"));
            setMyEnd(moment(moment(myStart).add(1, "months")).endOf("month"));
          }}
        >
          {">"}
        </div>
      </div>
      <Table
        // striped
        bordered
        // hover
        style={{ ...styles.table }}
      >
        <thead>
          <tr>
            <th style={{ ...styles.th }}>M</th>
            <th style={{ ...styles.th }}>T</th>
            <th style={{ ...styles.th }}>W</th>
            <th style={{ ...styles.th }}>T</th>
            <th style={{ ...styles.th }}>F</th>
            <th style={{ ...styles.th }}>S</th>
            <th style={{ ...styles.th }}>S</th>
          </tr>
        </thead>
        <tbody>
          {calendar?.map((cal: any) => {
            return (
              <tr>
                {cal?.map((dat: any, index: number) => {
                  // console.log('mmmmmmm', myDates, dat?.date)

                  return (
                    <td
                      key={dat?.date + index + "sdjssssjdhs"}
                      style={{
                        backgroundColor: (myDates?.includes(moment(dat?.date)?.format("MM/DD/YYYY"))
                          || myDates?.includes(moment(dat?.date)?.format("MM/DD/YY")))
                          ? myColors?.brown
                          : "white"
                      }}
                    >
                      <div
                        onClick={() => {
                          const dall = mySelectedDates?.includes(dat?.date)
                            ? mySelectedDates?.filter(
                              (rat: any) => rat != dat?.date
                            )
                            : [dat?.date, ...mySelectedDates];
                          setMySelectedDates([...dall]);
                          onClick({ dat, mySelectedDates: dall });
                        }}
                        style={{
                          backgroundColor: selectedColor(dat),
                          color: (myDates?.includes(moment(dat?.date)?.format("MM/DD/YYYY"))
                            || myDates?.includes(moment(dat?.date)?.format("MM/DD/YY")))
                            ? 'white' : 'black',
                          borderRadius: 100,
                          width:"28px",
                          height:"28px",
                          display:"flex",
                          justifyContent:"center",
                          alignItems:"center"

                        }}
                      >
                        {dat?.number}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const styles = {
  table: {
    width: "30px !important",
    marginTop:10
  },
  th: {
    width: 20,
  },
};