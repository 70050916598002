import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import skills_industry from "src/assets/data/skills_industry";
import { InputAgenda } from "../AgendaFormComponent";
import Select from "react-select";
import { colors } from "../MyLinks";
import { useEffect, useMemo, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { UserContext } from "src/App";
import { getDataDB, saveDataDB } from "src/api";
import {
  Add,
  CloudUpload,
  Edit,
  Favorite,
  Handshake,
  InsertDriveFile,
  RemoveCircleOutline,
  Save,
  Share,
  StarBorder,
  X,
} from "@mui/icons-material";
import { ShareWrapper } from "../Share";
import { BasicMutipleSelect } from "./BasicMutipleSelect";
import { RatingPopUpModal } from "../RatingPopUpModal";

export const AboutTabDetails = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { editInfo } = props;
  const params: any = useParams<any>();
  const { myInfo, host, eventTab, hostTabs } = myContext;

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [addSkill, setAddSkill] = useState<any>("");
  const [allLikes, setAllLikes] = useState<any>([]);
  const [allShares, setAllShares] = useState<any>([]);
  const [allMySkills, setAllMySkills] = useState<any>([]);
  const [Singleselect, setSingleselect] = useState("");
  const [selected, setSelected] = useState<any>([]);

  const skillsIndustry: any = useMemo(() => {
    return skills_industry;
  }, [skills_industry]);

  const options: any = useMemo(() => {
    return Object.keys(skills_industry)?.map((res: any) => {
      return {
        value: res,
        label: res,
      };
    });
  }, [skills_industry]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  useEffect(() => {
    const data = {
      query: {
        handle: host?.handle,
      },
      resource: `calendar_skills_record`,
      check: ["handle"],
    };
    getDataDB(data).then((res) => {
      console.log("hostTabsxxggg", res);

      setDataRecord(res?.[0]?.industry ? res?.[0]?.industry : {});
      setFormInput({
        // _id: res?.[0]?._id,
        handle: res?.[0]?.handle,
      });
      setRefreshing(false);
    });

    ///get my likes
    const dataLikes = {
      query: {
        handle: host?.handle,
      },
      resource: `calendar_${
        params.eventId ? "event" : "host"
      }_${hostTab}_likes`,
      check: ["handle"],
    };

    // console.log('formDatacc', data)
    getDataDB(dataLikes).then((res) => {
      console.log("hostTabsxx", res?.[0]?.industry);
      setAllLikes(res);
    });

    // console.log('formDatacc', data)
    getDataDB(dataLikes).then((res) => {
      console.log("hostTabsxx", res?.[0]?.industry);
      setAllLikes(res);
    });

    const dataShares = {
      query: {
        handle: host?.handle,
      },
      resource: `calendar_${
        params.eventId ? "event" : "host"
      }_${hostTab}_shares`,
      check: ["handle"],
    };

    getDataDB(dataShares).then((res) => {
      console.log("hostTabsxx", res?.[0]?.industry);
      setAllShares(res);
    });

    const allMy = {
      query: {
        handle: myInfo?.handle,
      },
      resource: `calendar_skills_record`,
      check: ["handle"],
    };

    getDataDB(allMy).then((res) => {
      console.log("setAllMySkills", res?.[0]?.industry);
      setAllMySkills(res?.[0]?.industry);
    });
  }, [refreshing, host?.handle]);

  const myLike = useMemo(() => {
    return allLikes?.filter((res: any) => res?.handle == myInfo?.handle)?.[0];
  }, [allLikes, myInfo?.handle]);

  const myShare = useMemo(() => {
    return allLikes?.filter((res: any) => res?.handle == myInfo?.handle)?.[0];
  }, [allLikes, myInfo?.handle]);

  useEffect(() => {
    console.log("addType", addType);
  }, [addType]);

  useEffect(() => {
    if (!selected?.industry) {
      return;
    }

    const newFormInput: any = {
      ...formInput,
      industry: {
        ...(dataRecord || {}),
      },
    };
    // console.log("setSelected11", newFormInput);

    newFormInput["industry"][selected?.industry] = [
      ...(selected?.skills || []),
    ];
    if (!(newFormInput && selected?.industry)) {
      return;
    }

    console.log("setSelected22", newFormInput);

    const data = {
      query: {
        ...newFormInput,
      },
      resource: `calendar_skills_record`,
      check: ["handle"],
      myInfo,
    };
    // console.log("formDatacc", data);

    saveDataDB(data).then((res) => {
      // setRefreshing(true);
    });
  }, [selected]);

  const [file, setFile] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf" && file.size <= 2097152) {
      setFile(file);
      // Uploading Logic Here PLease
    } else {
      alert("Please upload a PDF file smaller than 2MB.");
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file && file.type === "application/pdf" && file.size <= 2097152) {
      setFile(file);
      // Upload logic here
    } else {
      alert("Please upload a PDF file smaller than 2MB.");
    }
  };

  return (
    <>
      <div>
        {editInfo && (
          <>
            <EditSkillsModalWrapper>
              <AddSkillSection>
                <h2>Add from Resume</h2>
                {file ? (
                  <UploadedResume>
                    <div>
                      <InsertDriveFile />
                      <p>{file?.name}</p>
                    </div>
                    <div>
                      <button onClick={() => setFile(null)}>Change File</button>
                      <button>Upload</button>
                    </div>
                  </UploadedResume>
                ) : (
                  <UploadResumeFile
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                    htmlFor="fileInput"
                  >
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <CloudUpload />
                    <h3>Click Here or Drop Your Resume In here</h3>
                    <p
                      style={{
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      English Resumes in <b> PDF </b> only. <b>Max 2mb</b> file
                      size
                    </p>
                  </UploadResumeFile>
                )}
              </AddSkillSection>
              <AddSkillSection>
                <h2>Add Skills Manually</h2>
                <SkillChildWrapper>
                  <SkillAddWrapper>
                    <Select
                      defaultValue={Singleselect}
                      onChange={(e: any) => {
                        const data = {
                          ...selected,
                          industry: e?.value,
                          skills: selected?.skills || [],
                        };

                        console.log("nnnnn", data);

                        setSelected(data);
                      }}
                      options={options}
                      placeholder="Select Industy"
                      classNamePrefix="selectform"
                    />

                    {skillsIndustry?.[selected?.industry] && (
                      <BasicMutipleSelect
                        {...props}
                        options={skillsIndustry?.[selected?.industry]?.map(
                          (res: any) => {
                            return {
                              value: res,
                              label: res,
                            };
                          }
                        )}
                        selected={selected}
                        setFormInput={setFormInput}
                        handleChange={(e: any) => {
                          console.log("handleChange", e);
                          const data = {
                            ...selected,
                            skills: e?.map((res: any) => res.value),
                          };
                          console.log("nnnnn", data);
                          setSelected(data);
                        }}
                      />
                    )}
                  </SkillAddWrapper>
                  {skillsIndustry?.[selected?.industry] && (
                    <InputAgenda onSubmit={(e: any) => {}}>
                      <input
                        type={"text"}
                        onChange={(e: any) => {
                          console.log("nnnnn", data);
                          setAddSkill(e.target.value);
                        }}
                        value={addSkill || ""}
                        placeholder={"Add Skill ..."}
                        required
                      />

                      {addSkill?.length > 0 && (
                        <div
                          onClick={() => {
                            if (!addSkill) {
                              return;
                            }
                            const data = {
                              ...selected,
                              skills: [...(selected?.skills || []), addSkill],
                            };
                            console.log("bvbvbv", data);
                            setSelected(data);
                          }}
                        >
                          <Add />
                        </div>
                      )}
                    </InputAgenda>
                  )}
                </SkillChildWrapper>
              </AddSkillSection>
            </EditSkillsModalWrapper>
          </>
        )}
        <SkillDetails
          {...props}
          dataRecord={dataRecord}
          editInfo={editInfo}
          formInput={formInput}
          myLike={myLike}
          myShare={myShare}
          allShares={allShares}
          allLikes={allLikes}
          allMySkills={allMySkills}
        />
      </div>
    </>
  );
};

export const SkillDetails = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    dataRecord,
    editInfo,
    formInput = {},
    setFormInput,
    myLike,
    myShare,
    allShares,
    allLikes,
    allMySkills,
  } = props;
  const params: any = useParams<any>();

  // const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { myInfo, host, hostTabs } = myContext;

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);
  const [editSkillIndex, setEditSkillIndex] = useState<any>(-1);
  console.log("dataRecord", dataRecord);

  return (
    <>
      {dataRecord &&
        Object.keys(dataRecord) &&
        Array.isArray(Object.keys(dataRecord)) &&
        Object.keys(dataRecord)?.length > 0 &&
        Object.keys(dataRecord)?.map((industry: any) => {
          if (!(industry && dataRecord?.[industry]?.length > 0)) {
            return;
          }
          return (
            <div
              style={{
                display: "flex",
                width: "100%",
                // backgroundColor: 'red',
                flexDirection: "column",
                justifyContent: "flex-start",
                // alignItems:'left'
              }}
            >
              <div>
                <h4>{industry}</h4>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {dataRecord &&
                  industry &&
                  dataRecord?.[industry] &&
                  Array.isArray(dataRecord?.[industry]) &&
                  dataRecord?.[industry]?.map((skill: any, index: any) => {
                    return (
                      <div key={index + "nghsssdsgfgf"}>
                        <h5>{skill}: </h5>
                        <p>
                          {
                            // skillDetails?.description
                            // ||
                            " Host didn't add any description for this skill."
                          }
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </>
  );
};

export const SkillTabContainer = (props: any) => {
  const { match, skill, views, children } = props;
  return (
    <SkillHolder
      isMatched={match}
      style={{
        whiteSpace: "nowrap",
      }}
    >
      {children || <Handshake />}
      {skill}
      {props.myInfo?.handle && props.myInfo?.handle == props.host?.handle && (
        <Edit
          onClick={() => {
            props.setEditSkillIndex(
              props.editSkillIndex == props.index ? -1 : props.index
            );
          }}
        />
      )}
      {views && (
        <div
          style={{
            backgroundColor: "gray",
            borderRadius: 100,
            color: "white",
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          {views}
        </div>
      )}
    </SkillHolder>
  );
};

const UploadedResume = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  p {
    font-size: 12px;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    width: 15px;
    height: 100%;
    color: #479d83;
  }
  button {
    border: 1px solid #479d83;
    padding: 3px 8px;
    background: #fff;
    font-size: 12px;
    transition: background 0.3s;
    margin: 0;
    &:hover {
      background: #479d832b;
    }
  }
`;

const UploadResumeFile = styled.label`
  background: #479d832b;
  border: 1px dashed gray;
  width: 100%;
  padding: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 10px;
    color: gray;
    margin: 0;
  }
  svg {
    width: 40px;
    color: #479d83;
    height: 100%;
  }
`;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const GuestDetailsWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const GuestDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const GuestDetailIndustryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  h1 {
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    color: #475063;
    margin: 0;
    text-align: left;
  }
  svg {
    width: 15px;
    height: 15px;
    cursor: pointer;
    color: red;
  }
`;

const IteraterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
`;

const AddSkillSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

export const ShareButton = styled.div`
  svg {
    width: 15px;
    height: 15px;
    color: #479d83;
  }
`;

export const SkillContainer = styled.button<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  border: none;
  background: transparent;
  z-index: ${(props: any) => (props.editDescription ? "none" : "9")};
  &:hover {
    form {
      top: -31px;
    }
  }
  cursor: pointer;
`;

export const SkillHolder = styled.div<{ isMatched: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 12px;
  color: black;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => (props.isMatched ? "#c4e3da" : "#fff")};
  border: ${(props) => (props.isMatched ? "none" : "1px solid gray")};
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const SkillActions = styled.form`
  display: flex;
  padding: 5px;
  padding-bottom: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  gap: 20px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  top: 0px;
  z-index: -1;
  transition: all 0.3s;
`;
export const SkillAction = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  svg {
    width: 100%;
    height: 100%;
  }
  p {
    margin: 0;
    position: absolute;
    width: 15px !important;
    max-width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: 600;
    background-color: white;
    border: 1px solid gray;
    border-radius: 50%;
    top: -3px;
    right: -8px;
  }
`;
const SkillToolKit = styled(ReactTooltip)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  color: #000;
  h2 {
    text-align: start;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-size: 12px;
    margin: 0;
    color: #000;
  }
`;
const SkillEditDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  min-width: 180px;
  width: 100%;
  border-radius: 2px;
  z-index: 999;
  position: absolute;
  bottom: -112px;
  left: 50%;
  transform: translateX(-50%);
  h5 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
  textarea {
    resize: none;
    font-size: 12px;
    width: 100%;
    height: 70px;
    border: 1px solid gray;
    border-radius: 2px;
    padding: 5px;
    outline: none;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #479d83;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
      border-radius: 4px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
  }
  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000;
    width: 15px;
    height: 15px;
  }
`;
