import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";

import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getAttendanceDB,
  getCalendarEvents,
  getCalendarUsers,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import { Checkbox } from "material-ui";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon, Stack, Switch, Typography } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { TabListItems } from "./TabListItems";
import { AboutTabListItems } from "./AboutTabListItems";
import { MyFileUploader } from "./MyFileUploader";
import { EventSkillsTabList } from "./EventSkillsTabList";
import HeroBannarImg from "./HeroBannarImg";
import CommentsPlayerCard from "../CommentsPlayerCard";
import AdvertDetailsCard from "../AdvertDetailsCard";
import { ItemListInfo } from "./ItemListInfo";
// import { IconButton } from 'material-ui';

export const GuestRoleManagerModal = (props: any) => {
  const { host, setOpenEventForm } = props;

  const navigate = useNavigate();
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  const {
    // eventTab,
    myInfo,
    // host,
    // eventId,
    hostId,
    selectedEvent,
    people,
    editInfo,
    hostTabs = [],
    eventTabs = [],
    dataRecordAccess = {},
  } = myContext;

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);

  // const [guestProfileCategoresState, setGuestProfileCategoresState] = useState<
  //   string[]
  // >([]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab, eventId, myInfo?.handle]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab, hostId, myInfo?.handle]);

  useEffect(() => {
    // if (host?.handle) {
    //   console.log('cccc', myInfo)
    //   const data = {
    //     query: {
    //       handle: host?.handle,
    //     },
    //     resource: `calendar_host_about`,
    //     check: ["handle"],
    //   };
    //   getDataDB(data).then((res: any) => {
    //     const result: any = res?.[0]?.industry && Object.keys(res?.[0]?.industry);
    //     if (result) {
    //       // console.log("hostTabsxxccvvv", result);
    //       setGuestProfileCategoresState(result || []);
    //       setSelectedGuestProfileCategory(result[0]);
    //     }
    //     setRefreshing(false);
    //     // setFormInput({})
    //   });
    // }
  }, [hostTab, host?.handle]);

  const [formInput, setFormInput] = useState<any>({});
  const [selEvent, setSelEvent] = useState<any>(selectedEvent);
  const [localAction, setLocalAction] = useState<any>();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  // const [dataRecord, setDataRecord] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState<any>("");
  const [accesInvites, setAccesInvites] = useState<any>([]);

  const [addProfCat, setAddProfCat] = useState<boolean>(false);
  const [selectedGuestProfileCategory, setSelectedGuestProfileCategory] =
    useState<string>("");

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!myInfo?.handle) {
      return;
    }
    console.log("cccc", myInfo);

    const data = {
      query: {
        handle: myInfo?.handle,
      },
      resource: `calendar_user_access`,
      check: ["handle"],
    };
    getDataDB(data).then((res) => {
      // console.log("hostTabsxxggg22", res);

      setMyContext((existing: any) => {
        return {
          ...existing,
          dataRecordAccess: res?.[0]
            ? res?.[0]
            : {
              handle: myInfo?.handle,
            },
        };
      });

      // setDataRecord(res?.[0] ? res?.[0] : {
      //   handle: myInfo?.handle
      // });
      setRefreshing(false);
    });

    const dataB = {
      query: {
        handle: myInfo?.handle,
      },
      resource: `calendar_user_invites`,
      check: ["handle"],
    };
    getDataDB(dataB).then((res) => {
      // console.log("hostTabsxxggg22-fggg", res);

      setAccesInvites(res?.[0]?.emails ? res?.[0]?.emails : []);
      // setRefreshing(false);
    });
  }, [myInfo?.handle, refreshing, host?.handle]);

  return (
    <>
      <BackDrop style={{}}>
        <DetailModal isEvent={eventId ? true : false} style={{}}>
          {/* header */}
          <ModalHeader>
            <span>Manage Guest Access</span>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <HighlightOffIcon
                onClick={() => {
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      openAccessControl: false,
                    };
                  });
                  // navigate(`/`);
                  // setMyContext((existing: any) => {
                  //   return {
                  //     ...existing,
                  //     host: {},
                  //     eventId: "",
                  //     allMyEvents: [],
                  //     selectedEvent: {},
                  //     eventTab: false,
                  //   };
                  // });
                  // setOpenEventForm(false);
                }}
              />
            </div>
          </ModalHeader>

          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10
              }}
            >
              <Input
                type="text"
                placeholder="Email, comma separated"
                value={formInput?.emails || ""}
                onChange={(e: any) => {
                  setFormInput({
                    ...formInput,
                    emails: e.target?.value,
                  });
                }}
              />
              <ButtonL
                style={{ margin: 0 }}
                onClick={() => {
                  const emails =
                    formInput?.emails && formInput?.emails?.split(",");
                  if (emails?.length > 0) {
                    const newFormInput: any = {
                      emails: emails,
                      handle: myInfo?.handle,
                    };
                    const data = {
                      query: {
                        ...newFormInput,
                      },
                      resource: `calendar_user_invites`,
                      check: ["handle"],
                      myInfo,
                    };
                    console.log("formDatacc", data);
                    saveDataDB(data).then((res) => {
                      //setRefreshing(true);
                      setFormInput({});
                    });
                  }
                }}
              >
                Invite
              </ButtonL>
            </div>

            <div>
              <div>Events Tab Access</div>
              {eventTabs.length > 0 ? (
                <ItemListInfo
                  label={"My Event Tabs"}
                  data={eventTabs}
                  type={"event"}
                  // selectedTab={selectedTab}
                  accesInvites={accesInvites}
                // setSelectedTab={setSelectedTab}
                />
              ) : null}
            </div>

            <div>
              <div>Host Account Tab Access</div>
              <ItemListInfo
                label={"My Host Tabs"}
                data={hostTabs}
                type={"host"}
                // selectedTab={selectedTab}
                accesInvites={accesInvites}
              // setSelectedTab={setSelectedTab}
              />
            </div>

            <div>
              <div>Industry Tab Access</div>
              {myInfo?.industry && Object.keys(myInfo?.industry)?.length > 0 ? (
                <ItemListInfo
                  label={"My Industy"}
                  data={Object.keys(myInfo?.industry)?.map((res) => {
                    return {
                      name: res,
                      title: res,
                      editButton: true,
                      defaultEditor: true,
                    };
                  })}
                  type={"industy"}
                  // selectedTab={selectedTab}
                  accesInvites={accesInvites}
                // setSelectedTab={setSelectedTab}
                />
              ) : null}
            </div>
          </>
        </DetailModal>
      </BackDrop>
    </>
  );
};

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #262527;
    font-size: 18px;
    font-weight: 600;
  }
  svg {
    width: 23px;
    height: 23px;
    color: #aaabab;
  }
`;

const BackDrop = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.194);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailModal = styled.div<{ isEvent: boolean }>`
  max-width: ${({ isEvent }) => (isEvent ? "800px" : "500px")};
  margin: 0 10px;
  width: 100%;
  height: 90vh;
  background-color: ${({ isEvent }) => (isEvent ? "white" : "#f9f9f9")};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;

  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;
