import Carousel from "react-spring-3d-carousel";
import { useState, useEffect, useMemo, useContext, useRef } from "react";
import { config } from "react-spring";
import { useSpring, animated } from "react-spring";
// import './CarrousselCard.css'
// import Styles from "./CarrousselStyle.css";
import { Progress } from "reactstrap";
import {
  getCalendarUsers,
  getDataDB,
  getGuestLikesDB,
  handleReaction,
  handleSaveLikes,
  myColors,
  saveDataDB,
} from "src/api";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";
import styled from "@emotion/styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { useAsyncMemo } from "use-async-memo";
import {
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { socket } from "./socket";
import moment from "moment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Select from "react-select";
import { capitalizeFirstLetter, groupByAsync } from "src/helpers/Utils";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { BasicMutipleSelect } from "./MediaSlider/BasicMutipleSelect";
import skills_industry from "src/assets/data/skills_industry";
import { MultiSelect } from "react-multi-select-component";
import {
  Add,
  AttachMoney,
  CheckBox,
  Description,
  Favorite,
  Handshake,
  HighlightOff,
  MonetizationOn,
  MoneyOff,
  MoreVert,
  Person,
  PersonAdd,
  PersonOff,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  StarBorder,
  Visibility,
} from "@mui/icons-material";
import { colors } from "./MyLinks";
import { InputAgenda } from "./MediaSlider";
import { ButtonL } from "./AppIndex";
import {
  GuestDetailIndustryWrapper,
  GuestDetailSection,
  GuestDetailsWrapper,
  SkillAction,
  SkillActions,
  SkillContainer,
  SkillTabContainer,
} from "./MediaSlider/AboutTabListItems";
import { ShareWrapper } from "./Share";
import { RatingPopUpModal } from "./RatingPopUpModal";
import { SponsorEndorsementModal } from "./SponsorEndorsementModal";
import LineChart from "./charts/ApexCharts/LineChart";
import BarChart from "./charts/ApexCharts/BarChart";
import AdvertChart from "./AdvertChart";
// import { SkillTags } from "./MediaSlider/AboutTabListItems";

const _ = require("lodash");

export const ShareButton = styled.div`
  svg {
    width: 15px;
    height: 15px;
    color: #479d83;
  }
`;

export const AdvertDetailsCard = (props: any) => {
  const location = useLocation();

  const {
    openModal,
    // commentInfo = {},
    setOpenModal,
    // setCommentInfo,
    selectAvatar,
    setSelectAvatar,
    // msgInfo = [],
    openBill = false,
    setSupporters,
    supporters,
  } = props;
  const contextData: any = useContext(UserContext);
  const fullWidth = window.screen.width;
  const fullHeight = window.screen.height;

  const handle = useParams();
  const urlParam = handle;
  let navigate = useNavigate();
  const {
    feed,
    policy,
    bill,
    member,
    share,
    influencer,
    comment: commentUrl,
  } = handle;
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const { myInfo = {} } = localStore;
  const [replyContact, setReplyContact] = useState<any>(false);
  const [comment, setComment] = useState<any>("jjjj");
  const [saveComment, setSaveComment] = useState<boolean>(false);
  const [openComment, setOpenComment] = useState<any>(false);
  const [openMeeting, setOpenMeeting] = useState<boolean>(false);
  const [tooltipMsg, setTooltipMsg] = useState<any>(-1);
  const [billLinks, setBillLinks] = useState<any>([]);
  const [newsLinks, setNewsLinks] = useState<any>([]);
  const [relatedNews, setRelatedNews] = useState<any>(true);

  const [joinRoom, setJoinRoom] = useState<any>(false);
  const { feedsById } = localStore;
  const {
    myContext,
    myContext: { msgInfo = {}, myBlockedUsers, isApp, host, hostTabs },
    setMyContext,
  } = contextData;
  const [openNews, setOpenNews] = useState<any>(false);
  const [showMoreComments, setShowMoreComments] = useState<boolean>(false);
  const [seeBlockedUsers, setSeeBlockedUsers] = useState<boolean>(false);
  // const [commentInfo, setCommentInfo] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [skillsIndustry, setSkillsIndustry] = useState<any>([]);
  const [myEvents, setMyEvents] = useState<any>([]);

  useEffect(() => {
    const rep: any = [];
    const reg: any = skills_industry;
    skills_industry &&
      Object.keys(skills_industry)?.map((res: any) => {
        [res, ...reg?.[res]]?.map((ret) => {
          rep.push(ret);
        });
        return rep;
      });
    setSkillsIndustry(rep);
  }, [skills_industry]);

  const params: any = useParams<any>();

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const model: any = useMemo(() => {
    return hostTabs?.filter((ref: any) => ref.name == eventTab)?.[0]?.model;
  }, [eventTab, hostTabs]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const commentsTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.commentsTab]);

  const resource = useMemo(() => {
    return "calendar_event_advert";
  }, []);

  console.log("hostTabsxxc22c", resource);

  useEffect(() => {
    // console.log("hostTabsxxc22c");

    (async () => {
      //get my events
      const dataE = {
        query: {
          handle: myInfo?.handle,
        },
        resource: "calendar_events",
        check: ["handle"],
      };
      getDataDB(dataE).then((res) => {
        console.log("hostTabsxxc88", res);
        setMyEvents(res || []);
      });

      const dataA = {
        query: {
          handle: myInfo?.handle,
        },
        resource: "calendar_event_advert_accept_package",
        check: ["handle"],
      };
      const myRequest = await getDataDB(dataA).then((res) => {
        console.log("hostTabsxxc88", res);
        return res;
      });

      // console.log("hostTabsxxcc44", myRequest);
      // alert(resource)
      const data = {
        query:
        // params?.advertId && params?.advertId != 'na'
        //     ? {
        //         _id: params?.advertId
        //     }
        //     :
        {
          hhhhh: null,
        },
        resource: resource,
        check: ["handle"],
      };
      getDataDB(data).then((res) => {
        console.log("hostTabsxxcc44", res);

        myRequest &&
          setDataRecord(
            res?.[0]
              ? res?.map((ref: any) => {
                const myReq = myRequest?.filter(
                  (reg: any) => ref.eventId == reg?.eventId
                )?.[0];

                const info: any = {
                  ...ref,
                  guest: myReq,
                  id: ref._id,
                  _id: ref._id,
                  targets: ref?.targets?.[0]
                    ? ref?.targets?.map((reg: any) => reg.value)
                    : [],
                  // rate: res?.rate || "0.021",
                  // description: 'per user/day',
                  about:
                    ref?.description ||
                    "we are dedicated to solving world hunger",
                  location: "online",
                  picture:
                    ref?.image ||
                    ref?.img ||
                    "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg",
                };

                if (myReq) {
                  info["request"] = myReq?.request || false;
                  info["approved"] = myReq?.approved || false;
                }
                return info;
              })
              : []
          );
      });
    })();
  }, [
    params?.advertId,
    host?.handle,
    eventTab,
    myInfo?.handle,
    params.eventId,
    params.hostId,
    resource,
  ]);

  const [myLike, setMyLike] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [openIndex, setOpenIndex] = useState<number>(-1);
  const [action, setAction] = useState<any>("sponsor");
  const { state } = useLocation();

  useEffect(() => {
    // console.log("Guest info in the card", item);
    getGuestLikesDB({
      handle: myInfo?.handle,
    }).then((res) => {
      // console.log('myLike', myLike)
      setMyLike(res?.[0]?.like ? true : false);
    });
  }, [myInfo?.handle, refreshing]);

  return (
    <div>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Title>Sponsored Media</Modal.Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <ShareButton
            style={{
              marginRight: 20,
            }}
          >
            <RR
              data={{
                text: "My GuestNet Profile",
                url: `${resource}`,
                title: `My GuestNet Profile`,
              }}
            >
              <Share />
            </RR>
          </ShareButton>
          <HighlightOffIcon
            onClick={() => {
              if (state?.backUrl) {
                navigate(`${state?.backUrl}`);
                return;
              }
              const backRout = location?.pathname?.split("/advert")?.[0];
              navigate(`${!backRout ? "/" : backRout}`);
            }}
          />
        </div>
      </Modal.Header>

      <MultiSelect
        {...props}
        options={
          skillsIndustry && skillsIndustry?.[0]
            ? skillsIndustry?.map((res: any) => {
              // console.log('skills_industryxx', res)
              return {
                value: res,
                label: res,
              };
            })
            : []
        }
        onMenuToggle={(e: any) => {
          // setIsOpen(e)
        }}
        label={"bvbvbvbvbv"}
        // selected={selected}
        // setFormInput={setFormInput}
        value={selected || []}
        style={{
          display: "flex",
          position: "absolute",
          zIndex: 100,
        }}
        onChange={(e: any) => {
          console.log("handleChange", e);
          if (!myInfo?.handle) {
            alert("Please login");
            return;
          }
          setSelected(e);
        }}
      />
      <Modal.Body>
        <div
          style={{
            paddingBottom: 20,
          }}
        >
          {
            "  Select Sponsors for you event..  The more we know about your event and guest the more sponsors we'll send to your notification "
          }
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {[...dataRecord]?.map((res: any, i: number) => {
            // console.log("cccv", res);
            if (openIndex > -1 && openIndex != i) {
              return;
            }

            const mySelected = selected?.map((ref: any) => ref.value);
            const myAdvert = res?.targets;

            const reply = myAdvert?.filter((rg: any) =>
              mySelected?.includes(rg)
            );
            // if ((mySelected?.length)) {
            //     return
            // }
            // console.log('selected', reply)
            if (mySelected?.length > 0 && !(reply?.length > 0)) {
              return;
            }

            return (
              <>
                <AdvertChildren
                  {...props}
                  advert={res}
                  setOpenIndex={setOpenIndex}
                  setDataRecord={setDataRecord}
                  openIndex={openIndex}
                  index={i}
                  dataRecord={dataRecord}
                  setRefreshing={setRefreshing}
                />
              </>
            );
          })}
        </div>
      </Modal.Body>
    </div>
  );
};

const AdvertChildren = (props: any) => {
  const contextData: any = useContext(UserContext);
  const {
    myContext,
    myContext: { msgInfo = {}, myBlockedUsers, isApp, myInfo = {}, hostTabs },
    setMyContext,
  } = contextData;
  let navigate = useNavigate();
  const location = useLocation();

  const [joinRoom, setJoinRoom] = useState<any>(false);
  const {
    index,
    advert,
    setOpenIndex,
    setDataRecord,
    openIndex,
    dataRecord,
    setRefreshing,
  } = props;
  const params: any = useParams<any>();

  const link = useMemo(() => {
    return advert?.eventId && `event/${advert.eventId}/detail/event`;
  }, [advert.eventId]);

  const creditErned = useMemo(() => {
    return "$0.00";
  }, []);

  const [allMySkills, setAllMySkills] = useState<any>([]);
  useEffect(() => {
    console.log("commentInfo");
    if (!myInfo?.handle) {
      return;
    }

    const data = {
      query: {
        handle: myInfo?.handle,
      },
      resource: `calendar_skills_record`,
      check: ["handle"],
    };
    getDataDB(data).then((res: any) => {
      // console.log("vvvvvhostTabsxxggg", res?.[0]?.industry);

      const skillsInfo: any = []; //res?.[0]?.industry
      Object.keys(res?.[0]?.industry)?.map((rek) => {
        skillsInfo.push(rek);
        res?.[0]?.industry?.[rek]?.map((ref: any) => {
          skillsInfo.push(ref);
        });
      });
      // console.log("vvvvvbbb", skillsInfo);
      setAllMySkills(skillsInfo);
      setRefreshing(false);
    });
  }, [myInfo?.handle]);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          // justifyContent: 'center',
          alignItems: "center",
          // backgroundColor: 'red',
          marginBottom: 15,
          backgroundColor: "rgba(0,0,0,0.05)",
          borderRadius: 10,
          // marginTop: 15,
          padding: 15,
          // paddingTop: 20
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: 30,
            flexDirection: "row",
            // width: 60
          }}
        >
          <div
            style={{
              marginRight: 15,
            }}
          >
            {" "}
            <SponsorEndorsementModal
              resource={`calendar_advert_endorsement`}
              title={"Sponsor Endorsement"}
              eventLink={`https://www.myguestnet.com/advert/${params?.advertId}`}
              company={advert.title}
            >
              <StarBorder
                style={
                  {
                    // color: shareInfo ? "green" : "black",
                  }
                }
              />
            </SponsorEndorsementModal>
          </div>
          <div
            onClick={() => {
              setOpenIndex(openIndex == index ? -1 : index);
            }}
          >
            {openIndex == index ? <HighlightOff /> : <MoreVert />}
          </div>
        </div>
        <div
          style={{
            fontSize: 17,
            width: "100%",
            fontWeight: "bold",
          }}
        >
          {advert.title}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <ImgContainer
            isSmall={props.isSmall}
            style={{
              height: 200,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              position: "relative",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRadius: 10,
              }}
            >
              <video
                autoPlay
                muted
                poster={advert.picture}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: 10,
                }}
              >
                <source src={advert?.video} type="video/mp4" />
                <source src={advert?.video} type="video/ogg" />
              </video>
            </div>
            <HostButton
              onClick={() => {
                if (link) navigate(link);
                setTimeout(() => {
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      openAds: false,
                    };
                  });
                }, 300);
              }}
            >
              <PersonOff />
              <div>{advert?.company || "Host"}</div>
            </HostButton>
          </ImgContainer>
          <InfoHostWrapper>
            <div>
              <p>Per:</p>
              <span>{advert?.rateType?.value || ""}</span>
            </div>
            <div>
              <p>Rate:</p>
              <span>${advert.rate?.toString()}</span>
            </div>
          </InfoHostWrapper>
        </div>

        <div
          style={{
            width: "100%",
            marginTop: 10,
            fontWeight: "bold",
          }}
        >
          {" "}
          Target Markets:
        </div>
        <SkillHolderContainer>
          {advert?.targets?.map((skill: any, index: any) => {
            return (
              <IteraterWrapper>
                <SkillContainer key={index + "nghsssdsgfgf"}>
                  <SkillHolder
                    isMatched={
                      [...allMySkills, "soil science"]?.includes(skill)
                        ? true
                        : false
                    }
                  >
                    <Handshake />
                    {skill}
                    <SkillInfoStats>
                      <Stats>
                        <Person />
                        <em>6</em>
                      </Stats>
                      <Stats>
                        <Visibility />

                        <em>1</em>
                      </Stats>
                      <Stats>
                        <MoneyOff />
                        <em> {creditErned}</em>
                      </Stats>
                    </SkillInfoStats>
                  </SkillHolder>
                </SkillContainer>
              </IteraterWrapper>
            );
          })}
        </SkillHolderContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: 10,
            justifyContent: "space-between",
          }}
        >
          <ButtonL
            {...(!myInfo?.handle ? { disabled: true } : {})}
            style={
              advert?.request
                ? {
                  margin: 0,
                  width: "45%",
                  backgroundColor: "red",
                }
                : {
                  margin: 0,
                  width: "45%",
                }
            }
            {...(advert.request ? { checked: true } : {})}
            onClick={(e) => {
              if (!myInfo?.handle) {
                alert("Add Pricing");
                return;
              }
              const record = dataRecord?.includes(advert?.id)
                ? dataRecord?.filter((reg: any) => reg != advert?.id)
                : [...dataRecord, advert?.id];
              const data = {
                query: {
                  request: !advert?.request,
                  handle: myInfo?.handle,
                  host: advert?.handle,
                  eventId: advert?.eventId,
                  packages: record,
                  picture: advert.picture,
                  video: advert.video,
                },
                resource: `calendar_event_advert_accept_package`,
                check: ["handle", "eventId"],
                myInfo,
              };
              setDataRecord(record);
              // console.log('formDatacc', data)
              saveDataDB(data).then((res) => {
                // setRefreshing(true);
              });
            }}
          >
            {" Add to my Events"}
          </ButtonL>

          <ButtonL
            style={
              advert?.approved
                ? {
                  margin: 0,
                  width: "45%",
                  backgroundColor: "red",
                }
                : {
                  margin: 0,
                  width: "45%",
                }
            }
            {...(!myInfo?.handle || advert.host != myInfo?.handle
              ? { disabled: true }
              : {})}
            onClick={() => {
              if (!myInfo?.handle) {
                alert("Add Pricing");
                return;
              }
              const record = dataRecord?.includes(advert?._id)
                ? dataRecord?.filter((reg: any) => reg != advert?._id)
                : [...dataRecord, advert?._id];
              const data = {
                query: {
                  approved: !advert?.approved,
                  handle: myInfo?.handle,
                  host: advert?.handle,
                  eventId: advert?.eventId,
                  packages: record,
                  picture: advert.picture,
                  video: advert.video,
                },
                resource: `calendar_event_advert_accept_package`,
                check: ["handle", "eventId"],
                myInfo,
              };
              setDataRecord(record);
              // console.log('formDatacc', data)
              saveDataDB(data).then((res) => {
                // setRefreshing(true);
              });
            }}
          >
            {!advert?.approved ? "Pending Approval" : "Approve Request"}
          </ButtonL>
        </div>

        {openIndex == index && (
          <div
            style={{
              marginTop: 20,
              padding: 10,
            }}
          >
            <div
              style={{
                padding: 10,
                // position: "absolute",
              }}
            >
              {advert?.description}
            </div>
            <div
              style={{
                // position: "absolute",
                left: 20,
                top: 15,
              }}
            >
              {/* Activity Report */}
              {/* {startdate ? `${startdate} to ${period}` : `${period}`} */}
            </div>

            {myInfo.handle && myInfo.handle == advert?.handle ? (
              <GuestKPICard {...props} commentInfo={advert} />
            ) : (
              <HostKPICard
                {...props}
                commentInfo={advert}
                guestHandle={myInfo?.handle}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

const GuestKPICard = (props: any) => {
  const {
    cardClass = "h-100",
    backMenu,
    title = "",
    subTitle = "",
    setOpenDemography,
    commentInfo = {},
    //--
    editInfo,
    // formInput = {},
    myLike,
    myShare,
    allShares,
    allLikes,
  } = props;
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    eventId,
    searchBy = "Event",
    host,
    // comments = [],
  } = myContext;
  // const [data, setData] = useState<any>(partyData);
  const [sortedBuildData, setSortedBuildData] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [period, setPeriod] = useState("11/10/23");
  const [demoOption, setDemoOption] = useState<any>([]);
  const [demography, setDemography] = useState<any>({});
  const [dataRequest, setDataRequest] = useState<any>([]);
  const [formInput, setFormInput] = useState<any>({});
  const [refreshing, setRefreshing] = useState<any>({});
  const [allMySkills, setAllMySkills] = useState<any>([]);

  useEffect(() => {
    console.log("commentInfo", commentInfo);
    if (!commentInfo?.handle) {
      return;
    }
    const data = {
      query: {
        eventId: commentInfo?.eventId,
      },
      resource: `calendar_event_advert_accept_package`,
      check: ["handle"],
    };
    getDataDB(data).then((res: any) => {
      // console.log("vvvvvhostTabsxxggg", res?.[0]);

      setDataRequest(res?.[0] ? res : []);

      setRefreshing(false);
    });
  }, [myInfo?.handle, commentInfo]);

  return (
    <div
      style={{
        display: "flex",
        overflowY: "scroll",
        flexDirection: "column",

        width: "100%",
      }}
    >
      {dataRequest?.map((res: any) => {
        return (
          <HostKPICard
            {...props}
            commentInfo={{
              ...commentInfo,
              ...res,
            }}
            guestHandle={res?.handle}
          />
        );
      })}
    </div>
  );
};

const HostKPICard = (props: any) => {
  const {
    cardClass = "h-100",
    backMenu,
    title = "",
    subTitle = "",
    setOpenDemography,
    commentInfo = {},
    //--
    editInfo,
    // formInput = {},
    myLike,
    myShare,
    allShares,
    allLikes,
    guestHandle = "",
  } = props;
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
    eventId,
    searchBy = "Event",
    host,
    // comments = [],
  } = myContext;
  // const [data, setData] = useState<any>(partyData);
  const [sortedBuildData, setSortedBuildData] = useState<any>([]);
  const [guestInfo, setGuestInfo] = useState<any>({});
  const [period, setPeriod] = useState("11/10/23");
  const [demoOption, setDemoOption] = useState<any>([]);
  const [demography, setDemography] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [formInput, setFormInput] = useState<any>({});
  const [refreshing, setRefreshing] = useState<any>({});
  const [allMySkills, setAllMySkills] = useState<any>([]);

  useEffect(() => {
    console.log("commentInfo", commentInfo);
    if (!guestHandle) {
      return;
    }

    const guest = {
      query: {
        handle: guestHandle,
      },
      resource: `calendar_users`,
      check: ["handle"],
    };
    getDataDB(guest).then((res: any) => {
      setGuestInfo(res?.[0] ? res?.[0] : {});
    });

    const data = {
      query: {
        handle: guestHandle,
      },
      resource: `calendar_skills_record`,
      check: ["handle"],
    };
    getDataDB(data).then((res: any) => {
      // console.log("vvvvvhostTabsxxggg", res?.[0]?.industry);

      setDataRecord(res?.[0]?.industry ? res?.[0]?.industry : {});
      setFormInput({
        // _id: res?.[0]?._id,
        handle: res?.[0]?.handle,
      });
      const skillsInfo: any = []; //res?.[0]?.industry
      // console.log("vvvvvhostTabsxxggg", res?.[0]?.industry);

      Object.keys(res?.[0]?.industry)?.map((rek) => {
        skillsInfo.push(rek);
        res?.[0]?.industry?.[rek]?.map((ref: any) => {
          skillsInfo.push(ref);
        });
      });
      // console.log("vvvvvbbb", skillsInfo);

      setAllMySkills(skillsInfo);
      setRefreshing(false);
    });
  }, [myInfo?.handle, commentInfo, guestHandle]);

  // console.log("enddate", demography, demoOption, demography[demoOption]);

  return (
    <div
      style={{
        display: "flex",
        overflowY: "scroll",
        flexDirection: "column",
        // height: 450,
        // marginBottom: 100,
        width: "100%",
      }}
    >
      <div
        className={cardClass}
        style={{
          color: "green",
        }}
      >
        Target Audience for this Advertisment vs. your contacts. Report on
        number of views and credits earned.
      </div>

      <SkillTags
        {...props}
        dataRecord={dataRecord}
        editInfo={editInfo}
        formInput={formInput}
        myLike={myLike}
        myShare={myShare}
        allShares={allShares}
        allLikes={allLikes}
        allMySkills={allMySkills}
        guestInfo={guestInfo}
      />
      <AdvertChart {...props} />
    </div>
  );
};

export const SkillTags = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    dataRecord,
    editInfo,
    formInput = {},
    myLike,
    myShare,
    allShares,
    allLikes,
    commentInfo,
    allMySkills = [],
    guestInfo = {},
  } = props;
  const params: any = useParams<any>();
  const navigate = useNavigate();

  // const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { myInfo, host, hostTabs } = myContext;

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  // console.log("vvvvv", dataRecord);

  const creditErned = useMemo(() => {
    return "$2.10";
  }, []);

  return (
    <>
      {dataRecord &&
        Object.keys(dataRecord) &&
        Array.isArray(Object.keys(dataRecord)) &&
        Object.keys(dataRecord)?.length > 0 &&
        Object.keys(dataRecord)?.map((industry: any) => {
          if (!(industry && dataRecord?.[industry]?.length > 0)) {
            return;
          }
          return (
            <GuestDetailsWrapper onClick={() => console.log(host)}>
              <GuestDetailSection>
                <GuestDetailIndustryWrapper
                  onClick={() => {
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        openAds: false,
                      };
                    });
                    navigate(`/host/${guestInfo.handle}/about`);
                  }}
                >
                  <h1>{industry}</h1> ({guestInfo?.name})
                  {myInfo?.handle == formInput?.handle && editInfo ? (
                    <RemoveCircleOutline onClick={() => { }} />
                  ) : null}
                </GuestDetailIndustryWrapper>
                <IteraterWrapper>
                  {commentInfo?.targets?.map((skill: any, index: any) => {
                    return (
                      <SkillContainer key={index + "nghsssdsgfgf"}>
                        <SkillHolder
                          isMatched={
                            [...allMySkills, "soil science"]?.includes(skill)
                              ? true
                              : false
                          }
                        >
                          <Handshake />
                          {skill}
                          <SkillInfoStats>
                            <Stats>
                              <Person />
                              <em>6</em>
                            </Stats>
                            <Stats>
                              <Visibility />

                              <em>1</em>
                            </Stats>
                            <Stats>
                              <MoneyOff />
                              <em> {creditErned}</em>
                            </Stats>
                          </SkillInfoStats>
                        </SkillHolder>
                      </SkillContainer>
                    );
                  })}
                </IteraterWrapper>
              </GuestDetailSection>
            </GuestDetailsWrapper>
          );
        })}
    </>
  );
};

export default AdvertDetailsCard;

const IteraterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  span {
    padding: 4px 10px;
    background: white;
    border-radius: 40px;
    border: 1px solid #e4e7eb;
    color: #212121;
  }
`;

const ImgContainer = styled.div<{ isSmall: boolean }>`
  position: relative;
  height: ${({ isSmall }) => (isSmall ? "200px" : " 290px")};
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
const RR = styled(RWebShare)`
  border: 12px solid green;
`;

export const HostButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid white;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #00000078;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 5px;
  svg {
    width: 15px;
    height: 15px;
    color: white;
  }
`;
const InfoHostWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 900;
    }
    span {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
const SkillInfoStats = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const Stats = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  svg {
    width: 12px;
    height: 12px;
  }
`;
const SkillHolder = styled.div<{ isMatched: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 12px;
  color: black;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => (props.isMatched ? "#c4e3da" : "#fff")};
  border: ${(props) => (props.isMatched ? "none" : "1px solid   gray")};
  white-space: nowrap;
  svg {
    width: 14px;
    height: 14px;
  }
`;
const SkillHolderContainer = styled.div`
  display: flex;
  gap: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin-top: 5px;
  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

// These Buttons are not necessary for the same card according to this UI design because we have a single button of view details and user can get every thing there
