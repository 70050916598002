import React, { useState, useEffect, useContext } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useCookies } from "react-cookie";
import useLocalStorage from "use-local-storage";
import GoogleIcon from "@mui/icons-material/Google";
import { UserContext } from "src/App";

import {
  getCalendarUsers,
  handleRegisterUser,
  handleUpdateUser,
} from "src/api";
import { v4 as uuid } from "uuid";

function GoogleLogin() {
  const { myContext, setMyContext } = useContext<any>(UserContext);

  const [user, setUser] = useState<any>([]);
  const [profile, setProfile] = useState<any>([]);
  const [cookies, setCookie] = useCookies<any>(["vare"]);
  const [localStore, setLocalStore] = useLocalStorage<any>("name", "");
  const [myStore, setMyStore] = useLocalStorage<any>("name", {});
  const {
    myInfo,
    myInfo: { active = '2' },
    // comments = [],
  } = myContext;
  const login = useGoogleLogin({
    onSuccess: (codeResponse: any) => setUser(codeResponse),
    onError: (error: any) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const response = res.data;
          const handle = uuid().substring(0, 6);

          getCalendarUsers({
            email: response?.email,
          }).then((calendarResponse) => {
            console.log(calendarResponse, "calendarResponse");
            if (
              !calendarResponse?.[0]?.handle &&
              response?.email
            ) {
              console.log(handle, "handle");
              handleUpdateUser({
                email: response?.email,
                handle,
              }).then((res) => {
                console.log(res, "in update function");
              });

              return;
            }
            const result = {
              token: user.access_token,
              handle: handle,
              email: response?.email,
              fullName: response?.name,
              name: response?.name,
              admin: response?.admin,
              loginType: "google",
            };
            console.log(result, "result register");
            handleUpdateUser({
              ...result,
            }).then((res: any) => {
              console.log(res, "added or updated user");
              alert("Welcome.");
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  myInfo: res?.data?.[0]
                }
              })
              setMyStore({
                ...myStore,
                myInfo: {
                  ...res?.data?.[0],
                  admin: response?.admin
                },
              });
              // window.location.reload()
            });
          });

          // if (response?.name && user.access_token) {
          //   const result = {
          //     ...response,
          //     token: user.access_token,
          //     handle: response?.email,
          //     fullName: response?.name,
          //     name: response?.name,
          //     loginType: 'google'
          //   }

          //   setCookie('vare', result, { path: "/" });
          //   setCookie('vareApp', result, { path: "/" });
          //   setLocalStore((existingItems: any) => {
          //     return {
          //       ...existingItems,
          //       myInfo: result
          //     }
          //   })
          //   if (window.confirm("Login Successful")) {
          //     window.open(`http://localhost:3000`, '_self')
          //   }
          // }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  return (
    <div>
      <button className="btn btn-icon me-3" onClick={() => login()}>
        <span className="btn-inner--icon">
          {" "}
          {/* <i className="bx bxl-google tx-18 tx-prime"></i>{" "} */}
          <GoogleIcon />
        </span>
      </button>
    </div>
  );
}
export default GoogleLogin;
