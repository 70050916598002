/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #eef1f5;
`;

const FormContainer = styled.div`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #479d83;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

const StyledField = styled(Field)`
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  &:focus {
    border-color: #479d83;
  }
`;

const ErrorText = styled.div`
  color: red;
  margin-bottom: 10px;
  width: 100%;
  text-align: right;
  margin-top: -13px;
  font-size: 12px;
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: #479d83;
  color: white;
  padding: 12px 0;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #357a65;
  }
`;

const CheckboxContainer = styled.div`
  margin-bottom: 20px;
`;

// Validation Schema
const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .matches(/^\+?\d+$/, "Phone number is not valid")
    .required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  cardNumber: Yup.string()
    .required("Required")
    .matches(/^\d{16}$/, "Card number is not valid"),
  cpc: Yup.string()
    .required("Required")
    .matches(/^\d{3,4}$/, "CPC is not valid"),
  expiry: Yup.date()
    .min(new Date(), "Expiry date must be in the future")
    .required("Required"),
  // billingAddress: Yup.string().when("sameAsDelivery", {
  //   is: false,
  //   then: Yup.string().required("Billing address is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // billingCity: Yup.string().when("sameAsDelivery", {
  //   is: false,
  //   then: Yup.string().required("Billing city is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // billingState: Yup.string().when("sameAsDelivery", {
  //   is: false,
  //   then: Yup.string().required("Billing state is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // billingZip: Yup.string().when("sameAsDelivery", {
  //   is: false,
  //   then: Yup.string().required("Billing zip is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
});

const CheckoutPage = () => {
  return (
    <Container>
      <FormContainer>
        <h2>Checkout</h2>
        <Formik
          initialValues={{
            name: "",
            phoneNumber: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            cardNumber: "",
            cpc: "",
            expiry: "",
            sameAsDelivery: true,
            billingAddress: "",
            billingCity: "",
            billingState: "",
            billingZip: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            console.log("Form Values:", values);
            alert("Form submitted! Check the console for details.");
          }}
        >
          {({ errors, touched, setFieldValue, values }: any) => (
            <Form>
              <StyledField name="name" type="text" placeholder="Full Name" />
              <ErrorMessage component={ErrorText} name="name" />
              <StyledField
                name="phoneNumber"
                type="text"
                placeholder="Phone Number"
              />
              <ErrorMessage component={ErrorText} name="phoneNumber" />
              <StyledField name="address" type="text" placeholder="Address" />
              <ErrorMessage component={ErrorText} name="address" />
              <StyledField name="city" type="text" placeholder="City" />
              <ErrorMessage component={ErrorText} name="city" />
              <StyledField name="state" type="text" placeholder="State" />
              <ErrorMessage component={ErrorText} name="state" />
              <StyledField name="zip" type="text" placeholder="ZIP Code" />
              <ErrorMessage component={ErrorText} name="zip" />
              <StyledField
                name="cardNumber"
                type="text"
                placeholder="Card Number"
              />
              <ErrorMessage component={ErrorText} name="cardNumber" />
              <StyledField name="cpc" type="text" placeholder="CPC" />
              <ErrorMessage component={ErrorText} name="cpc" />
              <StyledField name="expiry" type="date" />
              <ErrorMessage component={ErrorText} name="expiry" />

              <CheckboxContainer>
                <label>
                  <Field type="checkbox" name="sameAsDelivery" />
                  Billing address same as delivery address
                </label>
              </CheckboxContainer>

              {!values.sameAsDelivery && (
                <>
                  <StyledField
                    name="billingAddress"
                    type="text"
                    placeholder="Billing Address"
                  />
                  <ErrorMessage component={ErrorText} name="billingAddress" />
                  <StyledField
                    name="billingCity"
                    type="text"
                    placeholder="Billing City"
                  />
                  <ErrorMessage component={ErrorText} name="billingCity" />
                  <StyledField
                    name="billingState"
                    type="text"
                    placeholder="Billing State"
                  />
                  <ErrorMessage component={ErrorText} name="billingState" />
                  <StyledField
                    name="billingZip"
                    type="text"
                    placeholder="Billing ZIP Code"
                  />
                  <ErrorMessage component={ErrorText} name="billingZip" />
                </>
              )}

              <StyledButton type="submit">Submit</StyledButton>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
};

export default CheckoutPage;
