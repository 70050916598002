import styled from "@emotion/styled";
import { Close, Person } from "@mui/icons-material";
import React, { useContext } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";


export const TawkMessengerReactInfo = (props) => {

  return (
    <TawkMessengerReact
      {...props}
    // propertyId="property_id" widgetId="default"
    />

  );
}

