import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import { IconButtonProps } from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { eventTabs, UserContext } from "src/App";
import YouTube from 'react-youtube';
import ReactPlayer from "react-player";
import { v4 as uuidv4 } from "uuid";


import {
  cleanAndTrimText,
  getDataDB,
  getGuestLikesDB,
  handleAIQuerySearch,
  handleSaveLikes,
  jumpToReleventDiv,
  myColors,
  saveDataDB,
} from "src/api";
import moment from "moment";
import {
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { RR } from "./FunnelComponent";
import { ButtonL } from "./AppIndex";
import { Modal } from "react-bootstrap";
import { PptViewer } from "./MediaSlider/PptViewer";
import ExcelViewer from "./ExcelViewer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Camera } from "@mui/icons-material";
import { PlannerFunnelModal } from "./MediaSlider/PlannerFunnelModal";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CarrousselListCard(props: any) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { myInfo, allAccess, funnelItem = {} } = myContext;
  const {
    isSmall = false,
    people,
    subDisplay,
    width,
    height = 500,
    children,
    imageIcon,
    setFormInput,
    formInput,
    margin,
    item,
    menuProps,
    resource
  } = props;

  const params: any = useParams<any>();

  const location = useLocation();

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  // const item: any = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [myLike, setMyLike] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("Guesmyinfo", myInfo);


  useEffect(() => {
    // console.log("Guest info in the card", item);
    getGuestLikesDB({
      handle: myInfo?.handle,
      itemId: item?.handle,
    }).then((res) => {
      console.log("myLike", myLike);
      setMyLike(res?.[0]?.like ? true : false);
      setRefreshing(false);
    });
  }, [item, myInfo?.handle]);

  // console.log("itemxxxxxx", item);

  const menuInfo = useMemo(() => {
    const data: any = [
      {
        label: "Share",
        icon: "share",
        onClick: () => { },
      },
      {
        label: "Like",
        icon: "heart",
        bgColor: myLike ? "orange" : "",
        onClick: () => {
          if (!myInfo?.token) {
            alert("Please login");
            return;
          }
          handleSaveLikes({
            like: !myLike,
            type: "guest",
            ...item,
            myInfo,
          }).then((res) => {
            alert("Saved Like");
            setMyLike(!myLike);
            //setRefreshing(true);
          });
          // console.log('nnnnnn', item)
          // return
        },
      },
      {
        label: "Funnel",
        icon: "filter",
        // bgColor: myLike ? 'orange' : '',
        onClick: () => {
          if (!myInfo?.token) {
            alert("Please login");
            return;
          }
          setMyContext((existing: any) => {
            return {
              ...existing,
              funnelItem: item,
            };
          });
        },
      },
    ];

    if (allAccess) {
      data.push({
        label: "Edit",
        icon: "edit",
        onClick: () => {
          console.log("mmmmmm", item);
          setFormInput({
            // ...formInput,
            ...item,
            view: "carroussel",
          });

          setTimeout(() => {
            jumpToReleventDiv("formInput");
            setMyContext((existing: any) => {
              return {
                ...existing,
                editInfo: true,
              };
            });
          }, 100);
        },
      });
    }

    if (myInfo?.handle == '@dafolo' || myInfo?.handle == '@mplaunch') {
      data.push({
        label: "Vare",
        // icon: "plus",
        onClick: () => {
          setOpenVare(item?._id)
        },
      });
    }



    if (menuProps?.[0]) {
      menuProps?.map(async (res: any) => {
        await data.push(res);
      })

    }

    return data;
  }, [allAccess, myLike, item, menuProps]);

  const [openMedia, setOpenMedia] = useState<boolean>(false);
  const [openVare, setOpenVare] = useState<any>(false);
  const opts = {
    height: '300',
    // width: '200',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };

  const onPlayerReady = (event: any) => {
    event.target.pauseVideo();
  };

  const youtubeKey = useMemo(() => {
    const info = item?.link?.split('=')
    return info?.[info?.length - 1]
  }, [
    item?.link
  ])


  useEffect(() => {
    setFormInput({
      ...item,
    })
  }, [
    item?._id
  ])


  useEffect(() => {


    if (openVare == item?._id && item?.description) {
      handleAIQuerySearch({
        query: `return JSON formated result with the following attributes {bill_id: '',description:'', source:''}, of US Bill ID that best fits the following description ${cleanAndTrimText({ inputText: item?.description, maxLength: 200 })}`,
        myInfo: myInfo
      })
        .then((reply: any) => {
          console.log('handleAIQuerySearch', reply)
          setFormInput({
            ...formInput,
            ...reply,
            url: item?.link,
            urlToImage: item?.poster,
            title: reply?.description,
            content: item?.description,
            description: item?.description
            // url: item?.link,
            // urlToImage: item?.poster,
          })

        })
    }

  }, [
    openVare,
    item?.description
  ])


  return (
    <>
      <Card style={{
        width: "100%",
        // backgroundColor: 'red',
        minWidth: 325
      }}>
        <Modal
          size={"lg"}
          style={{
            top: 10,
            display: "flex",
            position: `absolute`,
            // overflowY: "scroll",
          }}
          show={openVare}
          onHide={() => {
            setOpenVare(false)
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: 'black',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <YouTube
              videoId={youtubeKey}
              opts={opts}
              onReady={onPlayerReady}
            />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 20,
              padding: 20,
              color: 'white'
            }}>
              title: {formInput?.title}
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 20,
              padding: 20,
              color: 'white'
            }}>
              description: {formInput?.description}
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 20,
              padding: 20
            }}>
              <div
                style={{ display: "flex", gap: 10, alignItems: "center" }}
              >
                <input
                  onClick={() => {
                  }}
                  type="text"
                  name="view"
                  placeholder="Bill ID"
                  value={formInput?.bill_id || ''}
                  onChange={(e: any) =>
                    setFormInput({
                      ...formInput,
                      bill_id: e.target?.value,
                    })
                  }
                // value={res?.value}
                // checked={typeFile == res?.value || !typeFile}
                />
              </div>
              <ButtonL onClick={() => {
                setOpenVare(false)

              }}>
                Close
              </ButtonL>
              <ButtonL onClick={() => {
                if (!formInput?.bill_id) {
                  alert('Bill Id required')
                  return
                }

                delete formInput?._id
                const data = {
                  query: {
                    ...formInput,
                    ...((!formInput?.video && formInput?.url?.includes('youtube')) ? { video: formInput?.url } : {}),
                    reply: false,
                    handle: myInfo?.handle,
                  },
                  // resource: 'vare_news_feeds',
                  resource: 'vare_contents',
                  check: ["title", "bill_id"],
                  myInfo,
                };
                // console.log('formData8944', data)
                // alert(resource)
                // console.log('formData', data)

                saveDataDB(data)
                  .then(async (res: any) => {

                    // saveDataDB({
                    //   query: {
                    //     vare: res?._id,
                    //     handle: myInfo?.handle,
                    //   },
                    //   resource: resource,
                    //   check: ["handle", "description"],
                    //   myInfo,
                    // }).then((res) => {


                    alert('Saved to Vare App')
                    setTimeout(() => {
                      setRefreshing(true)
                    }, 100);
                    // })


                  })
              }}>
                Save
              </ButtonL>
            </div>
          </div>

        </Modal>
        <Modal
          size={"lg"}
          style={{
            top: 10,
            display: "flex",
            position: item?.doc ? "absolute" : "relative",
            overflowY: "scroll",
          }}
          show={openMedia}
          onHide={() => {
            setMyContext((existing: any) => {
              return {
                ...existing,
                openLeadManage: false,
              };
            });
          }}
        >
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: 30,
              right: 30,
              zIndex: 2,
            }}
          >
            <i
              className={`fa fa-times`}
              onClick={() => {
                setOpenMedia(false);
              }}
              style={{
                display: "flex",
                fontSize: 18,
                padding: 10,
                borderRadius: 100,
                backgroundColor: myColors?.brown,
                color: "white",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
          {item?.doc ? (
            <>
              {item?.doc?.includes("pdf") ? (
                <div>
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                  >
                    <Viewer fileUrl={item?.doc} />
                  </Worker>
                </div>
              ) : item?.doc?.includes("xls") ? (
                <div>
                  {/* {window.open("https://www.w3schools.com")} */}
                  {/* <ExcelViewer fileUrl={item?.doc} /> */}
                </div>
              ) : (
                <PptViewer pptUrl={item?.doc} />
              )}
            </>
          ) : resource?.includes('website')
            ? <ButtonL
              style={{
                display: "flex",
                position: "absolute",
                bottom: 20,
                right: 20,
                zIndex: 2,
              }}
              onClick={() => {
                navigate(
                  `/site/${myInfo?.handle}`
                );
              }}
            >
              Go to Link
            </ButtonL>
            : (<div style={{
              display: 'flex',
              position: 'relative'
            }}>
              <video
                autoPlay
                loop
                muted
                poster={
                  item?.picture ||
                  item?.img ||
                  item?.image ||
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                }
                style={{
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: 10,
                }}
              >
                <source src={item?.video} type="video/mp4" />
                <source src={item?.video} type="video/ogg" />
              </video>
            </div>
            )}

          {item?.link && (
            <ButtonL
              style={{
                display: "flex",
                position: "absolute",
                bottom: 20,
                right: 20,
                zIndex: 2,
              }}
              onClick={() => {
                window.open(item?.link);
              }}
            >
              Go to Link
            </ButtonL>
          )}
        </Modal>
        <CardHeader
          avatar={
            <Avatar
              src={
                item?.picture ||
                item?.img ||
                item?.image ||
                "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
              }
              sx={{ bgcolor: red[500] }}
              aria-label="recipe"
            >
              {item?.name}
            </Avatar>
          }
          action={
            <IconButton
              aria-label="settings"
              onClick={() => {
                navigate(`/story/${eventId}/${eventTab}`);
              }}
            >
              <Camera
                style={
                  {
                    // marginRight: 20
                  }
                }
              />
            </IconButton>
          }
          title={item?.title || "NA"}
          subheader={moment(item?.date)?.fromNow() || ""}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            backgroundColor: "black",
            justifyContent: "center",
          }}
        >
          <video
            onClick={() => {
              setOpenMedia(true);
            }}
            style={{
              height: 150,
              alignSelf: "center",
            }}
            src={item?.video}
            poster={
              item?.img ||
              item?.image ||
              item?.picture ||
              "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
            }
          />
        </div>
        <CardContent>
          <Typography variant="body2" color="text.secondary" style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            {item?.name && <h6>{item?.name}</h6>}
            {item?.description?.length > 200
              ? item?.description?.substring(0, 200) + '...'
              : (item?.description || "")}
          </Typography>
        </CardContent>
        <CardActions disableSpacing style={{
          display: 'flex',
          overflowX: 'auto'
        }}>
          {menuInfo?.map((res: any) => (
            <IconButton aria-label="add to favorites">
              {res?.icon == "share" ? (
                <RR
                  data={{
                    text: `${item?.description || ''}`,
                    url: item?.link || `https://www.myguestnet.com${location.pathname}`,
                    title: `${item?.title || ''}`,
                  }}

                >
                  <i
                    onClick={() => {
                      res?.onClick();
                    }}
                    className={`fa fa-${res?.icon}`}
                    style={{
                      display: "flex",
                      fontSize: 18,
                      //   backgroundColor: myColors.brown,
                      padding: 10,
                      borderRadius: 100,
                      backgroundColor: res?.bgColor || "#479d83",
                      color: "white",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />

                </RR>
              ) : (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <i
                    onClick={() => {
                      res?.onClick();
                    }}
                    className={`fa fa-${res?.icon}`}
                    style={{
                      display: "flex",
                      fontSize: res?.iconSize || 18,
                      //   backgroundColor: myColors.brown,
                      padding: 10,
                      borderRadius: 100,
                      backgroundColor: res?.bgColor || myColors?.brown,
                      color: "white",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >{res?.icon ? '' : res?.label}</i>
                  {res?.iconText && <div style={{
                    fontSize: '0.3em',
                    textAlign: 'center',
                    color: 'black'
                  }}>
                    {res?.iconText}
                  </div>}
                </div>
              )}
            </IconButton>
          ))}
          {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>More Details:</Typography>
            <Typography paragraph>
              {item?.description}
            </Typography>
            <Typography paragraph>
              {/* Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet
              over medium-high heat. Add chicken, shrimp and chorizo, and cook,
              stirring occasionally until lightly browned, 6 to 8 minutes.
              Transfer shrimp to a large plate and set aside, leaving chicken
              and chorizo in the pan. Add pimentón, bay leaves, garlic,
              tomatoes, onion, salt and pepper, and cook, stirring often until
              thickened and fragrant, about 10 minutes. Add saffron broth and
              remaining 4 1/2 cups chicken broth; bring to a boil. */}
            </Typography>
            <Typography paragraph>
              {/* Add rice and stir very gently to distribute. Top with artichokes
              and peppers, and cook without stirring, until most of the liquid
              is absorbed, 15 to 18 minutes. Reduce heat to medium-low, add
              reserved shrimp and mussels, tucking them down into the rice, and
              cook again without stirring, until mussels have opened and rice is
              just tender, 5 to 7 minutes more. (Discard any mussels that
              don&apos;t open.) */}
            </Typography>
            <Typography>
              {/* Set aside off of the heat to let rest for 10 minutes, and then
              serve. */}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      {funnelItem?._id == item?._id && (
        <Modal
          show={Object.keys(funnelItem)?.[0] ? true : false}
          onHide={() => {
            setMyContext((existing: any) => {
              return {
                ...existing,
                funnelItem: {},
              };
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>MyGuestNet</Modal.Title>
          </Modal.Header>
          <Modal.Body> Add {item?.title || "Title"} to funnel</Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlannerFunnelModal
              item={{
                id: uuidv4(),
                title: "Google Adwords best practices",
                badges: [],
                notifications: 0,
                // avatars: [2, 3],
                ...(funnelItem || {}),
              }}
            // funnelTopic={funnelItem?.topic}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
