import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";
import YouTube from 'react-youtube';
import { v4 as uuidv4 } from "uuid";

import Select from "react-select/creatable";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  apiFetchData,
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getChatGPTResponse,
  getContactsDB,
  getDataDB,
  getDataWildDB,
  getGuestLikesDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  groupByNow,
  handleAIQuerySearch,
  handleAISearch,
  handleSaveLikes,
  jumpToReleventDiv,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
  stringifyArrayToBullets,
  youtubeSearch,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { SearchEventForm } from "../SearchEventForm";
import { ListItems } from "../ListItems";
import { MultiSelect } from "react-multi-select-component";
import { ListItemsMedia } from "../ListItemsMedia";
import { SkillsForm } from "../SkillsForm";
import MyKanbanBoard from "../Kanban";
import { StyledInput, TitleInput } from "../Kanban/TitleInput";
import CarrousselCard from "../CarrousselCard";
import Events from "../Events";
import { ScheduleFormComponent } from "../ScheduleFormComponent";

import { ButtonL, ShareHost } from "../AppIndex";
import { EventForm } from "../EventForm";
import { SearchGuestForm } from "../SearchGuestForm";
import { SearchEventAndGuest } from "../SearchEventAndGuest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";

import {
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Add,
  AttachFile,
  Attachment,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Description,
  Edit,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterAlt,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Share,
  ShoppingBag,
  Sync,
  UploadFile,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import { MyFileUploader } from "./MyFileUploader";
import { MyAllFileTypeUploader } from "./MyAllFileTypeUploader";
import { MyVideoUploader } from "./MyVideoUploader";
import { ImportDataComponent } from "./ImportDataComponent";
import { BiPlusCircle } from "react-icons/bi";
import { PptViewer } from "./PptViewer";
import CarrousselListCard from "../CarrousselListCard";
import { ListCard } from "../ListCard";
import { PlannerFunnelModal } from "./PlannerFunnelModal";
// import { Calendar } from "react-multi-date-picker";
import { ScheduleFormModal } from "../ScheduleFormModal";
import { ScheduleNotificationModal } from "../ScheduleNotificationModal";
// import { IconButton } from 'material-ui';
const _ = require("lodash");

export const TabListItems = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { type, resource, item, query } = props;
  const params: any = useParams<any>();

  const [formInput, setFormInput] = useState<any>({});
  const [addType, setAddType] = useState<any>({});
  const [dataRecord, setDataRecord] = useState<any>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [allDataRecord, setAllDataRecord] = useState<any>([]);
  const navigate = useNavigate();

  const {
    myInfo,
    host,
    hostTabs,
    allAccess,
    funnel = {},
    funnelItem = {},
    editInfo
  } = myContext;

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // console.log("hostTabs", hostTabs, eventTab, model);
  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);
  const [dataTopic, setDataTopic] = useState<any>([]);

  // const myInfo = useMemo(() => {
  //   return tempMyInfo
  // }, [tempMyInfo])

  // console.log('xxxxxzzz', myInfo)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    if (!resource) {
      return
    }
    setFormInput({})

    const data = {
      query: query || {
        ...(eventId
          ? {
            eventId: eventId,
          }
          : {}),
        handle: host?.handle,
      },
      resource: resource, //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
      check: ["handle"],
    };
    getDataDB(data).then(async (res) => {
      // console.log("hostTabsxxccnn", query, res);
      const apiData: any = []
      if (!res?.[0]) {
        return
      }
      await Promise.all(res?.map(async (reg: any) => {
        if (reg?.apiRoute) {
          // console.log("apiDataxxxxvvvv", reg?.apiPostType);

          return apiFetchData({
            endPoint: reg?.apiRoute,
            apiPostType: res?.apiPostType || 'GET',
            skip: 0,
            limit: 10
          }).then((reh: any) => {
            // console.log("apiDataxxxxvvvv", reh);

            return reh?.map((red: any) => {
              const temp: any = {}

              Object.keys(reg)?.map(ref => {
                if (reg[ref]) {
                  temp[ref] = reg[ref]
                }
              })
              Object.keys(red)?.map(ref => {
                if (red[ref]) {
                  temp[ref] = red[ref]
                }
              })

              apiData.push({
                ...temp,
                ...(host?.img ? { img: host?.img } : {})

              })
            })
          })
          // apiRoute
        }
      }))
      // console.log("apiDataxxxx", apiData);

      const resultData = [...(apiData || []), ...(res?.[0] ? res : [])]
      setDataRecord(resultData);
      setAllDataRecord(resultData);
      const grouped = groupByNow({
        data: resultData,
        key: "topic",
        id: 2,
      });
      const groupedInfo = grouped && Object.keys(grouped);
      // console.log("hostTabsxxccnn", groupedInfo);

      groupedInfo?.[0] && setDataTopic(groupedInfo);
      setRefreshing(false);
      // setFormInput({});
    });
  }, [
    // editInfo,
    host?.handle,
    eventId,
    eventTab,
    eventSubTab,
    myInfo?.handle,
    params.hostId,
    refreshing,
    resource
  ]);

  // useEffect(() => {
  //   setFormInput({
  //     ...formInput,
  //     topic: 'default',
  //   });
  // }, []);

  const myData = useMemo(() => {
    return [
      ...(formInput?.title
        ? [
          {
            file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            ...formInput,
          },
        ]
        : []),
      ...dataRecord
        ?.filter((res: any) => res.title != formInput?.title)
        ?.map((red: any) => {
          const info: any = {};
          if (red.file) {
            info["file"] = red.file;
          }
          if (red.link) {
            info["link"] = red.link;
          }
          if (red.img) {
            info["img"] = red.img;
          }
          return {
            ...red,
            _id: red?._id,
            title: red?.title,
            // file: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // link: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
            // img: "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
          };
        }),
    ];
  }, [
    dataRecord
  ]);

  // useEffect(() => {
  //   if (myData?.[0])
  //     setMyContext((existing: any) => {
  //       return {
  //         ...existing,
  //         myStory: myData
  //       };
  //     });
  // },
  //   [
  //     myData,
  //     editInfo
  //   ])

  // const [uploadNow, setUploadNow] = useState<boolean>(false);
  // const [file, setFile] = useState<any>(null);
  // const [typeFile, setTypeFile] = useState<any>("upload");
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);



  // const handleFileChange = (event: any) => {
  //   const file = event.target.files[0];

  //   // Define allowed file types for images and videos
  //   const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
  //   const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

  //   if (
  //     file &&
  //     (allowedImageTypes.includes(file.type) ||
  //       allowedVideoTypes.includes(file.type)) &&
  //     file.size <= 2097152
  //   ) {
  //     setFile(file);
  //     setFormInput({
  //       ...formInput,
  //       file,
  //     });
  //     setAddType({
  //       ...addType,
  //       file,
  //     });
  //   } else {
  //     alert("Please upload an image or video file smaller than 2MB.");
  //   }
  // };

  // const handleDrop = (event: any) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   const file = event.dataTransfer.files[0];

  //   // Define allowed file types for images and videos
  //   const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
  //   const allowedVideoTypes = ["video/mp4", "video/avi", "video/quicktime"]; // Quicktime for MOV files

  //   if (
  //     file &&
  //     (allowedImageTypes.includes(file.type) ||
  //       allowedVideoTypes.includes(file.type)) &&
  //     file.size <= 2097152
  //   ) {
  //     setFile(file);
  //     setFormInput({
  //       ...formInput,
  //       file,
  //     });
  //     setAddType({
  //       ...addType,
  //       file,
  //     });
  //   } else {
  //     alert("Please upload an image or video file smaller than 2MB.");
  //   }
  // };

  // const ChangeFile = () => {
  //   setFile(null);
  //   setFormInput({
  //     ...formInput,
  //     file: null,
  //   });
  //   setAddType({
  //     ...addType,
  //     file: null,
  //   });
  // };

  // const [selectedTopic, setSelectedTopic] = useState<any>("");

  const [options, setOptions] = useState<any>([]);
  const [subOptions, setSubOptions] = useState<any>([]);


  // const [selected, setSelected] = useState<any>([]);
  // const [selectedGuest, setSelectedGuest] = useState<any>([]);
  // const [selectedSub, setSelectedSub] = useState<any>([]);



  const [inputField, setInputField] = useState<any>("");
  // const [subInputField, setSubInputField] = useState<any>("");


  const [isList, setIsList] = useState<boolean>(false);
  const [loadingAI, setLoadingAI] = useState<any>(false);

  // useEffect(() => {
  //   if (selectedGuest?.handle) {
  //     setFormInput({
  //       ...formInput,
  //       notification: {
  //         ...(formInput?.notification ? formInput?.notification : {}),
  //         guests: selectedGuest
  //       }
  //     })
  //   }
  // }, [
  //   selectedGuest
  // ])


  // useEffect(() => {
  //   if (selectedSub)
  //     setFormInput({
  //       ...formInput,
  //       selectedSubscription: selectedSub
  //     })
  // }, [
  //   selectedSub
  // ])



  useEffect(
    _.debounce(() => {
      if (!(inputField?.length > 0)) {
        return;
      }
      const data = {
        key: "name",
        request: "wildsearch",
        searchVal: inputField,
        resource: `calendar_users`,
        limit: 400,
      };
      // console.log('formFieldxx44', formInput?.title)

      getDataWildDB(data).then((res: any) => {
        // console.log("formFieldxx44", res);
        if (res?.[0]) {
          const result = res?.map((person: any) => {
            return {
              // ...person,
              // active: true,
              // link: `https://www.myguestnet.com/host/${person?.handle}`,
              // img:
              //   person.img ||
              //   "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg",
              // title: person.name,
              // name: person.name,
              label: person.name,
              value: person?.email,
              // handle: person?.handle,
            };
          });
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle);
          setOptions([...options, ...result]);
        }
      });
    }, 500),
    [inputField]
  );

  useEffect(() => {
    if (eventId)
      getDataDB({
        query: {
          eventId: eventId,
        },
        myInfo,
        resource: 'calendar_event_subscription', //`calendar_${params.eventId ? "event" : "host"}_${eventTab}`,
        check: ["handle"],
      }).then((res: any) => {
        // console.log("formFieldxx44", res);
        if (res?.[0]) {
          const result = res?.[0]?.subscription?.map((subs: any) => {
            return {
              price: subs?.price,
              subId: subs?._id,
              label: subs.label,
              value: subs?.label,
            };
          });
          // ?.filter((ref: any) => ref?.handle != myInfo?.handle);
          setSubOptions([...options, ...result]);
        }
      });

  }, [
    eventId
  ])




  // useEffect(() => {
  //   if (selected?.[0]) {
  //     setFormInput({
  //       ...formInput,
  //       guest: selected, //todo- send emails
  //     });
  //   }
  // }, [selected]);

  // useEffect(() => {
  //   if (typeFile?.length > 0) {
  //     setFormInput({
  //       ...formInput,
  //       typeFile: typeFile, //todo- send emails
  //     });
  //   }
  // }, [typeFile]);

  // useEffect(() => {
  //   if (formInput?.typeFile) {
  //     setTypeFile(formInput?.typeFile)
  //   }
  // }, [
  //   formInput?.typeFile
  // ])



  // console.log("groupdataTopicedInfo", formInput);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: 'auto',
          gap: 5,
          width: windowWidth * .9
        }}
      >
        <Sync
          style={{
            backgroundColor: myColors?.brown,
            marginBottom: 20,
            color: 'white'
          }}
          onClick={() => {
            window.location.reload();
          }}
        />

        <ButtonL
          style={{
            backgroundColor: !formInput?.topic
              ? myColors?.brown
              : myColors?.green,
            marginBottom: 20,
          }}
          onClick={() => {
            setDataRecord(allDataRecord);
            setFormInput({
              // ...formInput,
              topic: "",
            });
          }}
        >
          All
        </ButtonL>

        {dataTopic?.[0] &&
          dataTopic?.map((topic: any, index: number) => {
            // const rep = allDataRecord?.filter((ref: any) => {
            //   return ref?.title == res?.title
            // })
            // console.log('vvvvdee', res)
            return (
              <ButtonL
                style={{
                  whiteSpace: "nowrap",       /* Prevent text wrapping */
                  // overflow: "hidden",          /* Hide overflow */
                  textOverflow: "ellipsis",    /* Add ellipsis when text overflows */
                  // whiteSpace: "none",
                  backgroundColor:
                    formInput?.topic == topic
                      ? myColors?.brown
                      : myColors?.green
                }}
              >
                <div
                  onClick={() => {
                    // setFormInput(res)
                    // setRecordKey(res?.title)
                    const data: any = [...allDataRecord]?.filter(
                      (reh) => reh.topic == topic
                    );
                    setDataRecord(data);
                    setFormInput({
                      // ...formInput,
                      topic: topic,

                    });
                  }}
                >
                  {" "}
                  {!topic || topic == "undefined" ? "Default" : topic}
                </div>
                {allAccess && topic && (
                  <RemoveCircleOutline
                    style={{
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      let text = "Sure you want to delete?";
                      const item = allDataRecord?.filter(
                        (reh: any) => reh.topic == topic
                      )?.[0];

                      if (window.confirm(text) == true) {
                        deleteDataDB({
                          id: item?._id,
                          resource: resource,
                        }).then((res) => {
                          setRefreshing(true);
                        });
                        text = "You pressed OK!";
                      } else {
                        text = "You canceled!";
                      }
                    }}
                  />
                )}
              </ButtonL>
            );
          })}
      </div>
      <div>
        <MediaForm
          {...props}
          editInfo={editInfo}
          dataTopic={dataTopic}
          setDataTopic={setDataTopic}
          setFormInput={setFormInput}
          formInput={formInput}
          resource={resource}
          setInputField={setInputField}
          refreshing={refreshing}
          setRefreshing={setRefreshing}
          openCalendar={openCalendar}
          setOpenCalendar={setOpenCalendar}
        />
        <div
          style={{
            paddingTop: 50,
            paddingBottom: 20,
          }}
        >
          {formInput?.title ||
            (myData?.length > 0 && (
              <form
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 50,
                  border: "1px solid gray",
                  padding: 10,
                  marginBottom: 50,
                }}
              >
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <input
                    onClick={() => {
                      setIsList(true);
                    }}
                    type="radio"
                    id="view1"
                    name="view"
                    value="list"
                    {...(formInput?.view == "list" ? { checked: true } : {})}
                  />
                  <label>List</label>
                </div>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <input
                    onClick={() => {
                      setIsList(false);
                      // setFormInput({
                      //   ...formInput,
                      //   view: "carroussel",
                      // });
                    }}
                    type="radio"
                    id="view2"
                    name="view"
                    value="card"
                  />
                  <label>Carroussel</label>
                </div>
              </form>
            ))}
        </div>
        <div style={{
          // width: 400,
        }}>
          {!isList ? (
            <Row>
              {myData?.map((item) => {
                return (<Col lg={6} md={6}>
                  <CarrousselListCard
                    {...props}
                    {...item}
                    item={item}
                    setFormInput={setFormInput}
                    formInput={formInput}
                    resource={resource}
                  />
                </Col>
                );
              })}
            </Row>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 20,
              }}
            >
              {myData?.map((item) => {
                return (
                  <div
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <ListCard
                      {...props}
                      {...item}
                      item={item}
                      setFormInput={setFormInput}
                      formInput={formInput}
                      refreshing={refreshing}
                      setRefreshing={setRefreshing}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>


      {openCalendar && (
        <Modal
          show={openCalendar ? true : false}
          onHide={() => {
            setOpenCalendar(false)
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Calendar</Modal.Title>
          </Modal.Header>
          <Modal.Body> Notification Dates</Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ScheduleNotificationModal
              {...props}
              formInput={formInput}
              setFormInput={setFormInput}
              setOpenCalendar={setOpenCalendar}
              scheduleInfo={{}}
            />
          </Modal.Footer>
        </Modal >
      )}

      {
        funnelItem?._id == item?._id && (
          <Modal
            show={Object.keys(funnelItem)?.[0] ? true : false}
            onHide={() => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  funnelItem: {},
                };
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>MyGuestNet</Modal.Title>
            </Modal.Header>
            <Modal.Body> Add {item?.title || "Title"} to funnel</Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PlannerFunnelModal
                item={{
                  id: uuidv4(),
                  title: "Google Adwords best practices",
                  badges: [],
                  notifications: 0,
                  ...(funnelItem || {}),
                }}
              // funnelTopic={funnelItem?.topic}
              />
            </Modal.Footer>
          </Modal>
        )
      }
    </>
  );
};

export const MediaForm = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    host,
    myInfo
  } = myContext;
  const navigate = useNavigate();

  const params: any = useParams<any>();
  const {
    dataTopic,
    setDataTopic,
    setFormInput,
    formInput,
    resource,
    setInputField,
    refreshing,
    setRefreshing,
    setOpenCalendar,
    openCalendar,
    editInfo
  } = props
  // const { setFormInput, formInput, setInputField, resource } = props;

  const [typeFile, setTypeFile] = useState<any>("upload");
  const [selectedTopic, setSelectedTopic] = useState<any>("");

  const [options, setOptions] = useState<any>([]);
  const [subOptions, setSubOptions] = useState<any>([]);


  const [selected, setSelected] = useState<any>([]);
  const [selectedGuest, setSelectedGuest] = useState<any>([]);
  const [selectedSub, setSelectedSub] = useState<any>([]);

  const [uploadNow, setUploadNow] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    if (selectedGuest?.handle) {
      setFormInput({
        ...formInput,
        notification: {
          ...(formInput?.notification ? formInput?.notification : {}),
          guests: selectedGuest
        }
      })
    }
  }, [
    selectedGuest
  ])


  useEffect(() => {
    if (selectedSub)
      setFormInput({
        ...formInput,
        selectedSubscription: selectedSub
      })
  }, [
    selectedSub
  ])


  useEffect(() => {
    if (selected?.[0]) {
      setFormInput({
        ...formInput,
        guest: selected, //todo- send emails
      });
    }
  }, [selected]);

  useEffect(() => {
    if (typeFile?.length > 0) {
      setFormInput({
        ...formInput,
        typeFile: typeFile, //todo- send emails
      });
    }
  }, [typeFile]);

  useEffect(() => {
    if (formInput?.typeFile) {
      setTypeFile(formInput?.typeFile)
    }
  }, [
    formInput?.typeFile
  ])


  // useEffect(() => {
  //   if (!editInfo) {
  //     setFormInput({})
  //   }
  // }, [
  //   editInfo
  // ])

  const uploadTypes = [
    {
      label: "Upload",
      value: "upload",
      onclick: (type: any) => {
        setTypeFile(type);
      },
    },
    {
      label: "link",
      value: "link",
      onclick: (type: any) => {
        setTypeFile(type);
      },
    },
    ...[myInfo?.email == 'deeajibola@gmail.com'
      ? {
        label: "API",
        value: "api",
        onclick: (type: any) => {
          setTypeFile(type);
        }
      } : {}],
    {
      label: "Import",
      value: "import",
      onclick: (type: any) => {
        setTypeFile(type);
      },
    },
  ];
  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);


  const [loadingAI, setLoadingAI] = useState<any>();
  const [savedLink, setSavedLink] = useState<any>([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>
    {editInfo && (
      <div style={{
        width: '100%'
      }}>
        {!window.location?.pathname?.includes('story') && <div>
          <form
            style={{
              width: '100%',
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              padding: 10,
              margin: 10,
              gap: 5,
              border: "1px solid gray",
            }}
          >
            {uploadTypes?.map((res: any) => {
              if (!res?.label) {
                return
              }
              return (
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    flexDirection: "row",
                    // alignItems: "center"
                  }}
                >
                  <input
                    onClick={() => {
                      res?.onclick(res?.value);
                    }}
                    type="radio"
                    id="view2"
                    name="view"
                    value={res?.value}
                    checked={typeFile == res?.value || !typeFile}
                  />
                  <label>{res?.label}</label>
                </div>
              );
            })}
          </form>
        </div>}
        {typeFile != "import" ? (
          <>
            <EditSkillsModalWrapper id={"formInput"}>

              <AddSkillSection>
                <h2>
                  Topic{" "}
                  <span style={{ color: "red" }}>(Required)</span>
                </h2>
                <SkillChildWrapper>
                  <Select
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        color: 'black'
                        //  width: windowWidth * .8 
                      }),
                    }}
                    defaultValue={formInput?.topic ?
                      {
                        label: formInput?.topic,
                        value: formInput?.topic,
                      }
                      : ''}
                    onChange={(e: any) => {
                      console.log("personinfo2", e);
                      // setSelectedTopic(e);
                      if (e?.value)
                        setFormInput({
                          ...formInput,
                          topic: e.value,
                        });
                    }}
                    isClearable
                    options={
                      dataTopic && dataTopic?.[0] != "undefined"
                        ? dataTopic?.map((res: any) => {
                          return {
                            label: res,
                            value: res,
                          };
                        })
                        : []
                    }
                    placeholder={'Add or Select Topic'}
                    classNamePrefix="selectform"
                  />
                </SkillChildWrapper>
              </AddSkillSection>

              {typeFile != "ai" && <AddSkillSection>
                <h2>Add Name or Title</h2>
                <SkillChildWrapper>
                  <InputAgenda onSubmit={(e: any) => { }}>
                    <input
                      style={{
                        padding: 5
                      }}
                      type={"text"}
                      onChange={(e: any) =>
                        setFormInput({
                          ...formInput,
                          title: e.target?.value,
                        })
                      }
                      value={formInput?.title || ""}
                      placeholder={"Add title ..."}
                      required
                    />
                  </InputAgenda>
                </SkillChildWrapper>
              </AddSkillSection>}
              {formInput?.topic && (
                <>
                  {typeFile != "ai" &&
                    <AddSkillSection>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                        <div
                          onClick={() => {
                            if (!formInput?.title) {
                              alert('A title is needed to generate your description')
                              return
                            }
                            setLoadingAI('description')
                            getChatGPTResponse({
                              myInfo: myInfo,
                              prompt: `generate a 3 offerinngs my company offers in our ${formInput?.title} services`
                            })
                              .then((reply: any) => {
                                setLoadingAI(false)
                                if (reply) {
                                  // console.log('hhhhhhhh', reply)
                                  setFormInput({
                                    ...formInput,
                                    description: stringifyArrayToBullets({ arr: reply }),
                                    descriptionArray: reply
                                  })
                                }
                              })
                          }}
                          style={{
                            cursor: 'pointer',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'green',
                            borderRadius: 100,
                            color: 'white',
                            paddingRight: 5,
                            paddingLeft: 5,
                            marginRight: 10,
                          }}>{loadingAI ? 'loading..' : 'AI'}</div> <h2>Add Description / Details (Optional)</h2>
                      </div>
                      <SkillChildWrapper>
                        <InputAgenda onSubmit={(e: any) => { }}>
                          <textarea
                            style={{
                              width: '100%'
                            }}
                            // type={"text"}
                            rows={5}
                            onChange={(e: any) =>
                              setFormInput({
                                ...formInput,
                                description: e.target?.value,
                              })
                            }
                            value={formInput?.description || ""}
                            placeholder={"Add Description ..."}
                            required
                          />
                        </InputAgenda>
                      </SkillChildWrapper>


                      <AddSkillSection style={{
                        padding: 5,
                        width: '1005'
                      }}>
                        <h2>Add Link (optional)</h2>
                        <InputAgenda onSubmit={(e: any) => { }}>
                          <input
                            style={{
                              padding: 5,
                              width: '100%'
                            }}
                            type={"text"}
                            onChange={(e: any) =>
                              setFormInput({
                                ...formInput,
                                link: e.target?.value,
                              })
                            }
                            value={formInput?.link || ""}
                            placeholder={"Add link ..."}
                            required
                          />
                        </InputAgenda>
                      </AddSkillSection>
                    </AddSkillSection>}




                  {typeFile == "link" ? (
                    <PostViaLinkComponent
                      {...props}
                      setFormInput={setFormInput}
                      formInput={formInput}
                      myInfo={myInfo}
                    />

                  ) : typeFile == "upload" ? (
                    <>
                      <AddSkillSection>
                        <h2>Add Image (optional)</h2>
                        <MyFileUploader
                          {...props}
                          allowAdd={false}
                          defaultImage={formInput?.img}
                          refreshing={refreshing}
                          setRefreshing={setRefreshing}
                          setFormInput={setFormInput}
                          formInput={formInput}
                          uploadNow={uploadNow}
                          resource={resource}
                          handleSaveImage={(e: any) => {
                            setUploadNow(true);
                          }}
                        />
                      </AddSkillSection>
                      <AddSkillSection>
                        <h2>Add Video (optional)</h2>
                        <MyVideoUploader
                          {...props}
                          defaultImage={formInput?.video || ""}
                          refreshing={refreshing}
                          setRefreshing={setRefreshing}
                          setFormInput={setFormInput}
                          videoKey={'video'}
                          formInput={formInput}
                          uploadNow={uploadNow}
                          handleSaveImage={(e: any) => {
                            setUploadNow(true);
                          }}
                          resource={resource}
                        />
                      </AddSkillSection>
                    </>
                  ) : typeFile == "api" ? (
                    <PostViaAPIComponent {...props}
                      setFormInput={setFormInput}
                      formInput={formInput}
                    />
                  ) : typeFile == "ai" ? (
                    <PostViaAIComponent {...props}
                      resource={resource}
                      setFormInput={setFormInput}
                      formInput={formInput}
                      setRefreshing={setRefreshing}
                    />
                  ) : null}
                  <AddSkillSection>
                    <h2>Add Viewers (optional)</h2>
                    <Select
                      styles={{
                        control: (styles) => ({ ...styles, width: 400 }),
                      }}
                      onInputChange={(e: any) => {
                        // console.log('personinfobb', e)
                        setInputField(e);
                      }}
                      defaultValue={
                        formInput?.guest || selected
                          ? [...(formInput?.guest || []), ...selected]
                          : [
                            {
                              label: "Default",
                              value: "Default",
                            },
                          ]
                      }
                      onChange={(e: any) => {
                        console.log("personinfo", e);
                        setSelected(e);
                      }}
                      isMulti
                      isClearable
                      options={options}
                      placeholder="Select or Invite by Email"
                      classNamePrefix="selectform"
                    />
                  </AddSkillSection>
                  <AddSkillSection>
                    <h2 onClick={() => {
                      setOpenCalendar(true)
                    }}>Schedule Notification <i className='fa fa-calendar'></i> </h2>
                    {formInput?.notification?.endDate && <div>Active till: {formInput?.notification?.endDate}</div>}
                    <Select
                      styles={{
                        control: (styles) => ({ ...styles, width: 400 }),
                      }}
                      onInputChange={(e: any) => {
                        console.log('personinfobb', e)
                        setInputField(e);
                      }}
                      defaultValue={
                        formInput?.notification?.guests || selected
                          ? [...(formInput?.notification?.guests || []), ...selected]
                          : [
                            {
                              label: "Default",
                              value: "Default",
                            },
                          ]
                      }
                      onChange={(e: any) => {
                        console.log("personinfo", e);
                        setSelectedGuest(e);

                      }}
                      isMulti
                      isClearable
                      options={options}
                      placeholder="Select or Invite by Email"
                      classNamePrefix="selectform"
                    />
                  </AddSkillSection>
                  {typeFile != "ai" && <AddSkillSection>
                    <h2 style={{
                      // justifyContent:'center',
                      alignItems: 'center',
                      display: 'flex'
                    }}>Subscription Required <Switch
                        checked={formInput?.subscription == 'on' ? true : false}
                        onChange={(e: any) => {
                          setFormInput({
                            ...formInput,
                            subscription: e.target?.value
                          })
                        }} /></h2>
                    {formInput?.subscription == 'on' &&
                      <>
                        {subOptions?.[0]
                          ? <AddSkillSection>
                            <InputAgenda onSubmit={(e: any) => { }} style={{
                              flexDirection: 'column',
                              display: 'flex'
                            }}>
                              <Select
                                styles={{
                                  control: (styles) => ({ ...styles, width: 400 }),
                                }}
                                defaultValue={
                                  formInput?.selectedSubscription?.value
                                    ? [
                                      {
                                        label: formInput?.selectedSubscription?.label,
                                        value: formInput?.selectedSubscription?.value,
                                      },
                                    ]
                                    : [
                                      {
                                        label: "Default",
                                        value: "Default",
                                      },
                                    ]
                                }
                                onChange={(e: any) => {
                                  // console.log("personinfo2", e);
                                  // setSelectedTopic(e);
                                  if (e?.value)
                                    setFormInput({
                                      ...formInput,
                                      selectedSubscription: e,
                                    });
                                }}
                                // isClearable
                                options={subOptions || []}
                                placeholder="Select or Subscription Level"
                                classNamePrefix="selectform"
                              />
                              {/* <Select
                              styles={{
                                control: (styles) => ({ ...styles, width: 400 }),
                              }}
                              onInputChange={(e: any) => {
                                // console.log('personinfobb', e)
                                setSubInputField(e);
                              }}
                              defaultValue={
                                formInput?.selectedSubscription || selectedSub
                                  ? [...(formInput?.selectedSubscription || []), ...selectedSub]
                                  : [
                                    {
                                      label: "Default",
                                      value: "Default",
                                    },
                                  ]
                              }
                              onChange={(e: any) => {
                                console.log("personinfo", e);
                                setSelectedSub(e);
                              }}
                              isMulti
                              isClearable
                              options={options}
                              placeholder="Select or Invite by Email"
                              classNamePrefix="selectform"
                            /> */}

                              <p style={{
                                flexWrap: 'wrap',
                                width: 200
                              }}>When turned on, only Subscribed Guest can view this Section</p>

                            </InputAgenda>
                          </AddSkillSection>
                          : <ButtonL onClick={() => {
                            navigate(`/event/${eventId}/subscription`)
                          }}>
                            Add Subscription Packages
                          </ButtonL>}
                      </>
                    }
                  </AddSkillSection>}


                </>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {formInput?._id && (
                  <ButtonL
                    disabled={
                      formInput?.title
                        ? // addType?.file ||
                        // addType?.link ||
                        // formInput?.file ||
                        // formInput?.link
                        false
                        : true
                    }
                    style={{ margin: 0, marginRight: 20 }}
                    onClick={() => {
                      if (!myInfo?.handle) {
                        alert("Please login");
                        return;
                      }

                      let text = "Are you sure you wand to delete";
                      if (window.confirm(text) == true) {
                        text = "You pressed OK!";
                      } else {
                        text = "You canceled!";
                        return;
                      }
                      const data = {
                        id: formInput?._id,
                        resource: resource,
                      };
                      // console.log('formData', data)
                      deleteDataDB(data).then((res) => {
                        setUploadNow(true);
                        setMyContext((existing: any) => {
                          return {
                            ...existing,
                            editInfo: false,
                          };
                        });
                      });
                    }}
                  >
                    Delete
                  </ButtonL>
                )}
                {typeFile != "ai" && <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 20
                }}>
                  <ButtonL

                    style={{
                      margin: 0,
                    }}
                    onClick={() => {
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          editInfo: false,
                        };
                      });

                    }}
                  >
                    Close
                  </ButtonL>
                  {!formInput?.linkArray?.[0] && <ButtonL

                    style={{
                      margin: 0,
                      ...(!formInput?.topic ? {
                        backgroundColor: 'rgba(0,0,0,0.1)'
                      } : {})

                    }}
                    onClick={() => {
                      console.log('formInputxx', formInput)
                      if (formInput?.notification?.days?.[0] && !formInput?.notification?.guests?.[0]) {
                        alert('Please select guest to send notifications to')
                        return
                      }

                      if (!formInput?.topic) {
                        alert("Please make sure to add a Topic");
                        return;
                      }
                      if (!myInfo?.handle) {
                        alert("Please login");
                        return;
                      }
                      if (!formInput?.title) {
                        alert("Title required");
                        return;
                      }
                      const data = {
                        query: {
                          ...formInput,
                          ...(formInput?.notification?.guests?.[0] ? {
                            notify: true
                          } : {}),
                          handle: myInfo?.handle,
                          host: {
                            img: myInfo?.img,
                            name: myInfo?.name || myInfo?.fullName,
                            handle: myInfo?.handle,
                          },
                          eventId: eventId,
                        },
                        resource: resource,
                        check: ["handle", "title"],
                        myInfo,
                      };
                      // alert(resource)
                      // console.log('formData', data)
                      saveDataDB(data).then((res) => {
                        setUploadNow(true);
                        if (!formInput?.isFile) {

                          setMyContext((existing: any) => {
                            return {
                              ...existing,
                              editInfo: false,
                            };
                          });
                        }
                        setTimeout(() => {
                          setRefreshing(true)
                        }, 100);

                      });
                    }}
                  >
                    Add
                  </ButtonL>}
                </div>}
              </div>
            </EditSkillsModalWrapper>
          </>
        ) : typeFile == "import" ? (
          <div
            style={{
              margin: 10,
            }}
          >
            <ImportDataComponent
              {...props}
              resource={resource}
              topic={formInput?.topic}
              setFormInput={setFormInput}
              formInput={formInput}
            />
          </div>
        ) : null}
      </div>
    )}
  </>

}

export const PostViaLinkComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();

  // console.log("hostTabs", hostTabs, eventTab, model);

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const {
    myInfo,
    host,
  } = myContext;
  const { setFormInput, formInput, setInputField, resource } = props;
  const [loadingAI, setLoadingAI] = useState<any>();
  const [savedLink, setSavedLink] = useState<any>([]);

  useEffect(() => {
    // if (myInfo?.handle) {
    //   alert(myInfo?.handle)
    // } else {
    //   alert(myInfo?.handle)
    // }
  }, [
    myInfo
  ])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const opts = {
    height: '200',
    width: '200',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };


  return (
    <>
      <AddSkillSection>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div
            onClick={() => {
              if (!formInput?.description) {
                alert('A description is needed to generate your links')
                return
              }
              setLoadingAI('description')
              if (!myInfo?.handle) {
                alert('Please login...')
                return
              }
              youtubeSearch({
                query: formInput?.description,
                myInfo: myInfo
              })
                .then((reply: any) => {
                  setLoadingAI(false)
                  if (reply) {
                    // console.log('hhhhhhhh', reply)
                    setFormInput({
                      ...formInput,
                      queryId: reply?._id,
                      linkArray: reply,
                    })
                  }
                })
            }}
            style={{
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'green',
              borderRadius: 100,
              color: 'white',
              paddingRight: 5,
              paddingLeft: 5,
              marginRight: 10,
            }}>
            {loadingAI ? 'loading..' : 'AI'}
          </div> <h2>Add Link (Optional)</h2>
        </div>
        <SkillChildWrapper>
          {formInput?.linkArray?.[0]
            ? <div style={{
              display: 'flex',
              padding: 20,
              width: windowWidth * .7,
              overflowX: 'auto',
              flexDirection: 'row',
              gap: 10
            }}>
              {formInput?.linkArray?.map((media: any, index: number) => {
                const info = media?.link?.split('=')
                const youtubeKey = info?.[info?.length - 1]

                const onPlayerReady = (event: any) => {
                  event.target.pauseVideo();
                };

                function setUploadNow(arg0: boolean) {
                  throw new Error("Function not implemented.");
                }

                return <div style={{
                  display: 'flex',
                  flexDirection: "column",
                  width: 210
                }}>
                  <YouTube
                    videoId={youtubeKey}
                    opts={opts}
                    onReady={onPlayerReady}
                  />
                  <ButtonL
                    style={{
                      margin: 0,
                      ...(!formInput?.topic ? {
                        backgroundColor: 'rgba(0,0,0,0.1)'
                      } : {})

                    }}
                    onClick={() => {
                      if (!formInput?.topic) {
                        alert("Please make sure to add a Topic");
                        return;
                      }
                      if (!myInfo?.handle) {
                        alert("Please login");
                        return;
                      }
                      if (!formInput?.title) {
                        alert("Title required");
                        return;
                      }
                      const data = {
                        query: {
                          ...media,
                          topic: formInput?.topic,
                          title: formInput?.title,
                          query: formInput?.description,
                          img: media?.poster,
                          video: media.link,
                          handle: myInfo?.handle,
                          host: {
                            img: myInfo?.img,
                            name: myInfo?.name || myInfo?.fullName,
                            handle: myInfo?.handle,
                          },
                          eventId: eventId,
                        },
                        resource: resource,
                        check: ["handle", "link"],
                        myInfo,
                      };
                      saveDataDB(data).then((res) => {
                        setSavedLink([
                          ...savedLink,
                          index?.toString()
                        ])
                        alert('Saved')
                      });
                    }}
                  >
                    {savedLink?.includes(index) ? 'Saved' : 'Add+'}
                  </ButtonL>
                </div>
              })}
            </div>
            : <InputAgenda onSubmit={(e: any) => { }}>
              <input
                type={"text"}
                onChange={(e: any) =>
                  setFormInput({
                    ...formInput,
                    link: e.target?.value,
                  })
                }
                value={formInput?.link || ""}
                placeholder={"Add Link ..."}
                required
              />
            </InputAgenda>}
        </SkillChildWrapper>
      </AddSkillSection >
    </>
  );
};

const PostViaAIComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const params: any = useParams<any>();
  const {
    myInfo,
  } = myContext;
  const { setFormInput, formInput, setInputField, resource,
    setRefreshing
  } = props;
  const [myListItems, setMyListItems] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);


  const apiRequestTypes = [
    { label: "article", value: "article" },
    { label: "news", value: "news" },
    { label: "story", value: "story" },
  ];
  return (
    <>
      <AddSkillSection>
        <h2>Select AI Category (Optional)</h2>
        <Select
          styles={{
            control: (styles) => ({ ...styles, width: 400 }),
          }}
          // onInputChange={(e: any) => {
          //   // console.log('personinfobb', e)
          // }}
          defaultValue={
            formInput?.topic
              ? [
                {
                  label: formInput?.topic,
                  value: formInput?.topic,
                },
              ]
              : formInput?.aiCategory
                ? [
                  {
                    label: formInput?.aiCategory,
                    value: formInput?.aiCategory,
                  },
                ]
                : [
                  {
                    label: "News",
                    value: "news",
                  },
                ]
          }
          onChange={(e: any) => {
            // console.log('personinfo', e)
            setFormInput({
              ...formInput,
              aiCategory: e.target?.value,
            });
          }}
          // isMulti
          isClearable
          options={apiRequestTypes}
          placeholder="Select or Invite by Email"
          classNamePrefix="selectform"
        />
      </AddSkillSection>
      <AddSkillSection>
        <h2>Add AI Request (Required)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <textarea
              rows={4}
              style={{
                width: '100%'
              }}
              // type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  aiRequest: e.target?.value,
                })
              }
              value={formInput?.aiRequest || ""}
              placeholder={"Type of paste your notes in here to generate a list of cards"}
              required
            />
          </InputAgenda>
          <div style={{
            display: 'flex',
            gap: 10, flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}>
            {myListItems?.map((res: any, index: number) => {
              return <div style={{

              }}>
                ({index + 1}) {res?.title}
              </div>
            })}
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10
            }}>
              {!myListItems?.[0]
                ? <ButtonL onClick={() => {
                  if (formInput?.aiRequest?.length < 9) {
                    alert('You must add more than 9 characters')
                    return
                  }
                  setLoading(true)
                  handleAIQuerySearch({ query: `convert the following to array of object and return the data in JSON format with the following attributes {topic: '', title:'',description:'', img:'', source:''}" ${formInput?.aiRequest}` })
                    .then((reply: any) => {
                      const report: any = reply?.map((res: any) => {
                        return {
                          ...res,
                          topic: formInput?.topic || 'default'
                        }
                      })
                      setMyListItems(report)
                    })

                }}>
                  {loading ? 'loading...' : 'Add'}
                </ButtonL>
                : <>
                  <ButtonL onClick={() => {
                    setMyListItems([])
                    setMyContext((existing: any) => {
                      return {
                        ...existing,
                        editInfo: false,
                      };
                    });
                  }}>
                    Cancel
                  </ButtonL>
                  <ButtonL onClick={async () => {

                    await Promise.all(myListItems?.map(async (res: any) => {
                      const data = {
                        query: {
                          ...formInput,
                          ...(formInput?.title ? { title: formInput?.title?.trim() } : {}),
                          ...(formInput?.topic ? { topic: formInput?.topic?.trim() } : {}),
                          ...res,
                          handle: myInfo?.handle,
                          host: {
                            img: myInfo?.img,
                            name: myInfo?.name || myInfo?.fullName,
                            handle: myInfo?.handle,
                          },
                          eventId: eventId,
                        },
                        resource: resource,
                        check: ["handle", "title"],
                        myInfo,
                      };
                      // console.log('formData', data)
                      return await saveDataDB(data)
                    })).then((rec: any) => {
                      // console.log('donnnnnn')
                      setMyContext((existing: any) => {
                        return {
                          ...existing,
                          editInfo: false,
                        };
                      });
                      setTimeout(() => {
                        setRefreshing(true)
                      }, 100);
                    })

                  }} >
                    Save All Task
                  </ButtonL>
                </>}
            </div>
          </div>
        </SkillChildWrapper>
      </AddSkillSection>
    </>
  );
};

const PostViaAPIComponent = (props: any) => {
  const { setFormInput, formInput, setInputField } = props;

  const apiRequestTypes = [
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "DELETE", value: "DELETE" },
    { label: "PATCH", value: "PATCH" },
    { label: "OPTIONS", value: "OPTIONS" },
    { label: "HEAD", value: "HEAD" },
  ];
  useEffect(() => {
    setFormInput({
      ...formInput,
      apiPostType: 'GET'
    })
  }, [
  ])

  return (
    <>
      <AddSkillSection>
        <h2>Add API Request Type (Required)</h2>
        <Select
          styles={{
            control: (styles) => ({ ...styles, width: 400 }),
          }}
          // onInputChange={(e: any) => {
          //   // console.log('personinfobb', e)
          // }}
          defaultValue={
            formInput?.apiPostType
              ? [
                {
                  label: formInput?.apiPostType,
                  value: formInput?.apiPostType,
                },
              ]
              : [
                {
                  label: "Get",
                  value: "get",
                },
              ]
          }
          onChange={(e: any) => {
            // console.log('personinfo', e)
            setFormInput({
              ...formInput,
              apiPostType: e.target?.value,
            });
          }}
          // isMulti
          isClearable
          options={apiRequestTypes}
          placeholder="Select or Invite by Email"
          classNamePrefix="selectform"
        />
      </AddSkillSection>
      <AddSkillSection>
        <h2>Add API Route(Required)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <input
              type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  apiRoute: e.target?.value,
                })
              }
              value={formInput?.apiRoute || ""}
              placeholder={"Add End Point ..."}
              required
            />
          </InputAgenda>
        </SkillChildWrapper>
      </AddSkillSection>
      <AddSkillSection>
        <h2>Add API Token (Optional)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <input
              type={"text"}
              onPaste={(e: any) => {
                setFormInput({
                  ...formInput,
                  apiKey: e.target?.value,
                })
              }}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  apiKey: e.target?.value,
                })
              }
              value={formInput?.link || ""}
              placeholder={"Add Link ..."}
              required
            />
          </InputAgenda>
        </SkillChildWrapper>
      </AddSkillSection>
      <AddSkillSection>
        <h2>Add API Query (Optional)</h2>
        <SkillChildWrapper>
          <InputAgenda onSubmit={(e: any) => { }}>
            <input
              type={"text"}
              onChange={(e: any) =>
                setFormInput({
                  ...formInput,
                  apiQuery: e.target?.value,
                })
              }
              value={formInput?.link || ""}
              placeholder={"Add Link ..."}
              required
            />
          </InputAgenda>
        </SkillChildWrapper>
      </AddSkillSection>
    </>
  );
};


export const RR = styled(RWebShare)`
  border: 12px solid green;
`;

export const InputAgendaList = styled.form`
                display: flex;
                align - items: center;
                justify-content: space-between;
                max-width:100px;
                padding: 5px;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                background: #fff;
                input {
                  outline: none;
                border: none;
                font-size: 14px;
                width: 100%;
  }
                button {
                  border: none;
                background: transparent;
                outline: none;
                color: gray;
                transition: color 0.3s;
                svg {
                  width: 20px;
                height: 20px;
    }
                &:hover {
                  color: #dee2e6;
    }
  }
                `;

export const InputAgenda = styled.form`
                display: flex;
                align - items: center;
                justify-content: space-between;
                width: 100%;
                padding: 5px;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                background: #fff;
                input {
                  outline: none;
                border: none;
                font-size: 14px;
                width: 100%;
  }
                button {
                  border: none;
                background: transparent;
                outline: none;
                color: gray;
                transition: color 0.3s;
                svg {
                  width: 20px;
                height: 20px;
    }
                &:hover {
                  color: #dee2e6;
    }
  }
                `;

export const LinkParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

export const LinkC = styled.a`
  width: 80px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
export const EditSkillsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
`;

export const AddSkillSection = styled.div`
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: flex-start;
                h2 {
                  text - align: start;
                font-size: 14px;
                font-weight: bold;
                margin: 0;
  }
                `;

const UploadedResume = styled.div`
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                padding: 10px 0px;
                p {
                  font - size: 12px;
                margin: 0;
  }
                div {
                  display: flex;
                align-items: center;
                gap: 5px;
  }
                svg {
                  width: 15px;
                height: 100%;
                color: #479d83;
  }
                button {
                  border: 1px solid #479d83;
                padding: 3px 8px;
                background: #fff;
                font-size: 12px;
                transition: background 0.3s;
                margin: 0;
                &:hover {
                  background: #479d832b;
    }
  }
                `;

const UploadResumeFile = styled.label`
                background: #479d832b;
                border: 1px dashed gray;
                width: 100%;
                padding: 8px;
                padding-bottom: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;
                h3 {
                  font - size: 12px;
                font-weight: bold;
                color: #000;
                margin: 0;
  }
                p {
                  font - size: 10px;
                color: gray;
                margin: 0;
  }
                svg {
                  width: 40px;
                color: #479d83;
                height: 100%;
  }
                `;

const SkillAddWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const SkillChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
export const MediaCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
