import { useState, useEffect, useMemo, useContext } from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import DateRange from "@mui/icons-material/DateRange";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Share from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { ButtonL } from "./AppIndex";
import {
  deleteDataDB,
  getAttendanceDB,
  getDataDB,
  getEventLikesDB,
  getUserInfoByHandle,
  groupByNow,
  handleSaveEventLikes,
  relatedPict,
  saveDataDB,
} from "src/api";
// import { Avatar } from "@mui/material";
import {
  Edit,
  FilterAlt,
  LockOpen,
  LockPerson,
  PushPin,
  RemoveRedEye,
  Verified,
} from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

export const FunnelComponent = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const navigate = useNavigate();
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    host = {},
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    searchBy = "Event",
    allAccess,
    funnelType = {},
    hostFunnelType = {},
    eventFunnelType = {},
    funnel = {},
    pins = [],
    myLikes,
    mySchEvents = [],
    eventInfo
  } = myContext;
  const {
    imagen,
    index,
    item,
    handleInfo,
    setLocalAction,
    event,
    refreshing,
    setRefreshing,
  } = props;
  const location = useLocation();

  const params = useParams();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);


  function formatTime(timeString: any) {
    const [hours, minutes] = timeString && timeString?.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  }

  // const [myLike, setMyLike] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);
  const [myFunnels, setMyFunnels] = useState<any>([
    { name: 'Awareness', value: 252 },
    { name: 'Interest', value: 105 },
    { name: 'Consideration', value: 84 },
    { name: 'Evaluation', value: 72 },
    { name: 'Commitment', value: 19 },
    { name: 'Sale', value: 10 }
  ]);
  const [eventPin, setEventPin] = useState<boolean>(false);
  const [myIndex, setMyIndex] = useState<number>(-1);

  useEffect(() => {
    console.log('ttttkhgfffunnel',)
    // if (myInfo?.handle == item?.handle) {
    // if (eventFunnelType?.funnel){
    setMyFunnels(eventFunnelType)
    // }
    // } else {
    //   setMyFunnels(hostFunnelType)
    // }


  }, [
  ])



  return (<DropDownFilter >
    <FilterAlt

      onClick={() => {
        setMyIndex(myIndex == index ? -1 : index)
      }}
      style={{
        display: "flex",
        // left: 10,
        // top: 10,
        // color: 'orange',
        // position: "absolute",
        alignItems: "center",
        color: !funnel?.[item?._id] ? "gray" : "orange",
      }}
    />
    {index == myIndex &&
      <>
        <ul
          style={{
            fontSize: 9
          }}
          onMouseLeave={() => {
            setMyIndex(-1)
          }}>
          <li
            onClick={() => {
              navigate(`/host/${myInfo?.handle}/planner/funnel`,
                {
                  state: {
                    backUrl: location?.pathname,
                  }
                }
              );
            }}
            style={{
              backgroundColor: 'white',
              borderRadius: 10,
              fontSize: 10,
            }}>
            Add lead as: <Edit />
          </li>
          {myFunnels?.funnel?.map((res: any, index: number) => {
            return <li>
              {res?.name}
              <input
                type="radio"
                name={res?.name}
                id=""
                {...(funnel?.[item?.handle]?.funnelType ? { checked: true } : {})}
                onChange={() => {
                  if (!myInfo?.handle) {
                    alert('Please login')
                    return
                  }

                  let text = "Add to lead funnel";
                  if (window.confirm(text) == true) {
                    text = "You pressed OK!";

                    const data: any = {
                      query: {
                        funnelType: res?.name,
                        mySchEvents: mySchEvents,
                        itemId: item?._id,
                        item: item,
                        handle: myInfo?.handle,
                      },
                      resource: `calendar_lead_funnel`,
                      check: ["handle", "itemId"],
                      myInfo,
                    };
                    if (funnel?.[item?.handle]?.funnelType == res?.name) {
                      delete data?.funnelType
                    }
                    console.log("FormFields-person", data);
                    saveDataDB(data).then((ref: any) => {
                    });

                  } else {
                    text = "You canceled!";
                    // setOpenSignin(true)
                    return;
                  }
                }} />
            </li>
          })}
        </ul>
      </>}

  </DropDownFilter>
  );
};




const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin: 20px 20px;
  font-family: "Poppins", sans-serif;
  span {
    width: 100%;
    max-width: 930px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    span {
      color: #828282;
    }
  }
`;
const CardWrapperParent = styled.div`
  max-width: 930px;
  height: 220px;
  width: 100%;
  padding: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  border-radius: 16px;
  gap: 16px;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 756px) {
    flex-direction: column;
    height: auto; /* Reset height to auto for vertical stacking */
  }
`;
const ImgContainer = styled.div`
  position: relative;
  height: 100%;
  max-width: 280px;
  width: 100%;
  @media screen and (max-width: 756px) {
    max-width: none;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
export const RR = styled(RWebShare)`
  border: 12px solid green;
`;

const CardHead = styled.h1`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;
const HeadIcons = styled.h1`
  display: flex;
  gap: 5px;
`;
const CardSubHead = styled.h1`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 350px;
  text-align: left;
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;
const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;
  button {
    border: none;
    background: none;
    color: #bcbcbc;
    transition: all 0.3s;
  }
`;

const PageNumber = styled.div<{ active: boolean; isPage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: ${(props) => (props.active ? "#fff" : "#000")};
  background: ${(props) => (props.active ? "#000" : "#fff")};
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: ${(props) =>
    props.active ? "#000" : props.isPage ? "#fff" : "#dbdbdb"};
  }
`;
const CardInfoHead = styled.div`
  width: 100%;
  text-align: left;
`;
const EventDate = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    color: #717171;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 756px) {
      font-size: 12px;
      text-align: left;
    }
  }
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;
const EventLocation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    b {
      color: #2b2e39;
      font-size: 18px;
      font-weight: 700;
    }
    a {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;
const ButtonE = styled.button`
  display: flex;
  gap: 12px;
  border: none;
  background: none;
  outline: none;
  color: #2b2e39;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s;
  &:hover {
    text-decoration: underline;
  }
`;
const CardHeadChild = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  svg {
    width: 32px;
    height: 32px;
    color: gray;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: black;
    }
  }
`;
const ButtonC = styled.div`
  border: none;
  background-color: #479d83;
  padding: 5px 10px;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: #57b397;
  }
`;

export const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);



const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: 10px;
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: 10px;
  }
`;

const TaskAvatars = styled.div`
  margin-top: 10px;
`;
const DropDownFilter = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  ul {
    background: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-decoration: none;
    margin: 0;
    position: absolute;
    top: 15px;
    left: 15px;
    max-height: 150px;
    min-width: 150px;
    padding: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #479d83;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
      border-radius: 4px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      text-decoration: none;
      padding: 5px 10px;
      cursor: pointer;
      background: white;
      transition: background 0.3s;
      justify-content: space-between;
      &:hover {
        background-color: #80808071;
      }
      input {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
